export const styles = {
  style: {
    flex: 1,
    width: 40,
    height: 40
  },
  imageContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  image: {},
  loading: {
    position: "absolute",
    zIndex: 10
  },
  icon: {
    position: "absolute",
    backgroundColor: "#bdbdbd",
    padding: 5,
    zIndex: 50,
    right: -5,
    bottom: 0,
    shadowOffset: {
      width: 0.5,
      height: 0.5
    },
    shadowColor: "#333",
    shadowOpacity: 0.5,
    shadowRadius: 3
  }
};
