import { stateController } from "../controllers/state-controller";
import { UIText } from "../../config/lang-config";
import { clientController } from "../controllers/client-controller";
import { apiService } from "../services/api-service";
import { endpointConfig, serverConfig } from "../../config/api-config";
import { apiController } from "../controllers/api-controller";
import {
  capitalize,
  getDisplayNameEng,
  getQueryParameters,
  isEmpty,
  isEqual,
  randomString,
  validateEmail,
  validatePassword
} from "../../utils/helpers";
import { action, observable } from "mobx";
import NavigationService from "../../utils/navigation-service";
import { mcbEndpointConfig } from "../../custom/mcb/config/api-config";
import { groupTypeIds, typeClassIds } from "../../config/variable-config";
import { formService } from "../services/form-service";
import { openCAF } from "../../custom/mcb/utils/helper";

class TypeClassLibrary {
  buttonsLoading = [];

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.title,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm
    });
  };

  doneLoading = button => button && button.loading && (button.loading = false);

  openProfileScreen = button => {
    const {
      profileId,
      backButtonOverride,
      rightButtonOverride,
      topButton,
      bottomButton,
      renderFlags
    } = button.value;

    if (!profileId) return;

    const screenId = randomString();

    !isEmpty(backButtonOverride) &&
      (stateController.viewProfileState.backButtonOverride = backButtonOverride);
    !isEmpty(rightButtonOverride) &&
      (stateController.viewProfileState.rightButtonOverride = rightButtonOverride);
    !isEmpty(topButton) &&
      (stateController.viewProfileState.topButton = topButton);
    !isEmpty(bottomButton) &&
      (stateController.viewProfileState.bottomButton = bottomButton);
    !isEmpty(renderFlags) &&
      (stateController.viewProfileState.backButtonOverride = renderFlags);

    NavigationService.navigate("Profile", {
      profile: profileId,
      screenId
    });

    this.doneLoading(button);
  };

  showUsername = button => {
    this.doneLoading(button);
    return stateController.showPopup({
      title: capitalize(UIText.registrationFieldNames.username),
      content: clientController.client.user["userName"] || "",
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  changePassword = button => {
    this.doneLoading(button);

    const error = {
      oldPassword: "",
      password: "",
      repeat: ""
    };

    const fieldData = observable({
      oldPassword: "",
      password: "",
      repeat: ""
    });

    const execPasswordChange = async () => {
      const oauth = clientController.client.oauth || {};
      const data = {
        authToken: oauth["access_token"],
        newPass: fieldData.password,
        password: fieldData.oldPassword
      };

      return stateController
        .dismissPopup()
        .then(() =>
          stateController.showPopup({
            title: UIText.changePassword,
            content: UIText.pleaseWait
          })
        )
        .then(() =>
          apiService.async("POST", {
            headers: serverConfig.defaultHeaders,
            endpoint: endpointConfig.reset_pass_exec,
            data,
            noKickOut: true
          })
        )
        .then(stateController.dismissPopup)
        .then(() =>
          stateController.showPopup({
            title: UIText.changePassword,
            content: UIText.resetPasswordSuccessful,
            leftButtonText: UIText.generalConfirm
          })
        )
        .catch(err => {
          stateController.dismissPopup().then(() => {
            if (err.response && err.response.status === 401) {
              return this._showError({
                message: UIText.loginWrongCurrentPassword
              });
            }
            return this._showError(err);
          });
        });
    };

    const validateInput = () => {
      stateController.popup.rightButtonDisabled =
        !fieldData.oldPassword ||
        !fieldData.password ||
        !fieldData.repeat ||
        !isEmpty(error);
    };

    const blurPassword = args => {
      validatePassword(...args);
      stateController.popup.inputSet[0].errorMessage = error.oldPassword;
      stateController.popup.inputSet[1].errorMessage = error.password;
      stateController.popup.inputSet[2].errorMessage = error.repeat;
      validateInput();
    };

    return stateController.showPopup({
      title: UIText.changePassword,
      content: UIText.changePassword,
      leftButtonText: UIText.generalCancel,
      leftButtonPress: stateController.dismissPopup,
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: execPasswordChange,
      rightButtonDisabled: true,
      inputSet: [
        {
          title: capitalize(UIText.oldPassword),
          placeholder: capitalize(UIText.oldPassword),
          secureTextEntry: true,
          onChange: e => {
            fieldData.oldPassword = e.nativeEvent.text;
            validateInput();
          },
          onBlur: () =>
            blurPassword([fieldData, "oldPassword", error, false, UIText]),
          onEndEditing: () =>
            blurPassword([fieldData, "oldPassword", error, false, UIText])
        },
        {
          title: capitalize(UIText.resetPasswordNewPassword),
          placeholder: capitalize(UIText.resetPasswordNewPassword),
          secureTextEntry: true,
          onChange: e => {
            fieldData.password = e.nativeEvent.text;
            validatePassword(fieldData, "password", error, true, UIText);
            validateInput();
          },
          onBlur: () =>
            blurPassword([fieldData, "password", error, false, UIText]),
          onEndEditing: () =>
            blurPassword([fieldData, "password", error, false, UIText])
        },
        {
          title: capitalize(UIText.registrationFieldNames.repeat),
          placeholder: capitalize(UIText.registrationFieldNames.repeat),
          secureTextEntry: true,
          onChange: e => {
            fieldData.repeat = e.nativeEvent.text;
            validatePassword(fieldData, "repeat", error, true, UIText);
            validateInput();
          },
          onBlur: () =>
            blurPassword([fieldData, "repeat", error, false, UIText]),
          onEndEditing: () =>
            blurPassword([fieldData, "repeat", error, false, UIText])
        }
      ]
    });
  };

  changeEmailUser = button => {
    this.doneLoading(button);

    const userEmail = clientController.client.user.email;

    let password,
      email,
      validEmail = false;
    let validateTimeout;

    const getSelfMemberInGroup = group =>
      group.members.find(m => m.userId === clientController.userId);

    const validateInput = () => {
      stateController.popup.rightButtonDisabled =
        !password || !validateEmail(email) || !validEmail;
      if (!validateEmail(email) && (email || "").match(/\+/))
        stateController.popup.inputSet[1].errorMessage =
          UIText.registrationEmailNoPlusSign;
    };

    const validateEmailAddress = () => {
      stateController.popup.inputSet[1].errorMessage = "";
      clearTimeout(validateTimeout);
      validateTimeout = setTimeout(async () => {
        const selfMembers = clientController
          .findVisibleGroups()
          .map(getSelfMemberInGroup)
          .concat(clientController.client.user.defaultMember);
        if (
          selfMembers.some(m => m.email.toLowerCase() === email.toLowerCase())
        ) {
          validEmail = true;
        } else {
          await apiController.checkExist("email", email).then(result => {
            if (result.exist) {
              if (stateController.popup.inputSet) {
                stateController.popup.inputSet[1].errorMessage = `${capitalize(
                  UIText.registrationUniqueFields.email
                )} ${UIText.registrationUniqueExists}`;
              }
              return (validEmail = false);
            }
            validEmail = true;
          });
        }
        return validateInput();
      }, 500);
    };

    const validatePassword = () => {
      const groups = clientController.findVisibleGroups();
      stateController.dismissPopup().then(() =>
        apiController
          .login(
            {
              username: clientController.client.user.userName,
              password
            },
            true
          )
          .then(groups.length > 0 ? popupPropagation : execEmailChange)
          .catch(() =>
            stateController.showPopup({
              title: UIText.changeEmail,
              content: UIText.loginWrongCurrentPassword,
              leftButtonText: UIText.generalConfirm,
              leftButtonPress: () =>
                stateController.dismissPopup().then(popupFields)
            })
          )
      );
    };

    const popupFields = () =>
      stateController.showPopup({
        title: UIText.changeEmail,
        leftButtonText: UIText.generalCancel,
        leftButtonPress: stateController.dismissPopup,
        rightButtonText: UIText.generalConfirm,
        rightButtonPress: validatePassword,
        rightButtonDisabled: true,
        inputSet: [
          {
            title: capitalize(UIText.oldPassword),
            placeholder: capitalize(UIText.oldPassword),
            secureTextEntry: true,
            onChange: e => {
              password = e.nativeEvent.text;
              validateInput();
            }
          },
          {
            title: capitalize(UIText.changeEmailNewEmailAddress),
            placeholder: capitalize(UIText.changeEmailNewEmailAddress),
            defaultValue: email,
            onChange: e => {
              validEmail = false;
              email = e.nativeEvent.text;
              validateInput();
              validateEmailAddress();
            }
          }
        ]
      });

    const popupPropagation = () =>
      stateController.showPopup({
        title: UIText.changeEmail,
        content: UIText.changeEmailPropagation(userEmail, email),
        buttonSet: [
          {
            title: UIText.generalNo,
            onPress: () => execEmailChange()
          },
          {
            title: UIText.generalYes,
            onPress: popupSelectGroupMembers,
            showDivider: true
          },
          {
            title: UIText.generalCancel,
            onPress: () => stateController.dismissPopup().then(popupFields)
          }
        ].map(b => ({
          ...b,
          align: "center"
        }))
      });

    const popupSelectGroupMembers = () =>
      stateController.dismissPopup().then(() => {
        const groups = clientController.findVisibleGroups();
        const options = observable(
          groups.map(g => ({
            placeholder: `${g.groupTypeName}: ${getDisplayNameEng(
              g.profile.data
            )}`,
            value: getSelfMemberInGroup(g).id
          }))
        );
        return stateController.showPopup({
          title: UIText.changeEmail,
          multiSelect: {
            title: UIText.changeEmailApplyToGroups,
            options,
            onCheckboxChange: member => {
              member.checked = !member.checked;
              const hasUnchecked = options.some(o => !o.checked);
              stateController.popup.buttonSet[0].title = hasUnchecked
                ? UIText.generalSelectAll
                : UIText.generalDeselectAll;
            }
          },
          buttonSet: [
            {
              title: UIText.generalSelectAll,
              onPress: () => {
                const hasUnchecked = options.some(o => !o.checked);
                for (let option of options) option.checked = hasUnchecked;
                stateController.popup.buttonSet[0].title = hasUnchecked
                  ? UIText.generalDeselectAll
                  : UIText.generalSelectAll;
              },
              showDivider: true
            },
            {
              title: UIText.generalSubmit,
              onPress: () =>
                execEmailChange(
                  options.map(o => o.checked && o.value).filter(Boolean)
                )
            },
            {
              title: UIText.generalCancel,
              onPress: () => stateController.dismissPopup().then(popupFields)
            }
          ].map(b => ({
            ...b,
            align: "center"
          }))
        });
      });

    const execEmailChange = async memberIds => {
      const data = {
        username: clientController.client.user.userName,
        password,
        email,
        includedMembers: memberIds
      };

      return stateController
        .showPopup({
          title: UIText.changeEmail,
          content: UIText.pleaseWait
        })
        .then(() =>
          apiService.async("POST", {
            headers: serverConfig.defaultHeaders,
            endpoint: endpointConfig.reset_email_options,
            data
          })
        )
        .then(stateController.dismissPopup)
        .then(() =>
          stateController.showPopup({
            title: UIText.changeEmail,
            content: UIText.changeEmailRequestSuccess,
            leftButtonText: UIText.generalConfirm,
            leftButtonPress: () =>
              stateController
                .dismissPopup()
                .then(
                  () =>
                    stateController.viewProfileState.refresh &&
                    stateController.viewProfileState.refresh()
                )
          })
        )
        .catch(this._showError);
    };

    return stateController
      .showPopup({
        title: UIText.changeEmail,
        content: `${UIText.updating}, ${UIText.pleaseWait.toLowerCase()}`
      })
      .then(clientController.getUserGroupsAndMembers)
      .then(stateController.dismissPopup)
      .then(popupFields);
  };

  changeEmailMember = () => {};

  checkPendingEmailChangeUser = button => {
    button.loading = true;
    button.loaded = true;

    if (this.buttonsLoading.includes(button.name)) return;
    this.buttonsLoading.push(button.name);

    const hasPendingChange = changeRequest =>
      !changeRequest.isCompleted &&
      !changeRequest.isCancelled &&
      !changeRequest.isReverted;

    return apiController
      .getEmailChangeRequest()
      .then(changeRequest => {
        if (isEmpty(changeRequest) || !hasPendingChange(changeRequest)) {
          return (button.hidden = true);
        }
        button.value = changeRequest;
        button.loading = false;
        button.enabled = true;
      })
      .catch(err => {
        console.warn(err);
        return (button.hidden = true);
      })
      .finally(
        () =>
          (this.buttonsLoading = this.buttonsLoading.filter(
            b => b !== button.name
          ))
      );
  };

  openPendingEmailChangeUser = button => {
    button.loading = true;
    const changeRequest = button.value;

    const popupConfirmCancel = () =>
      stateController.dismissPopup().then(() =>
        stateController.showPopup({
          title: UIText.changeEmailPending,
          content: UIText.changeEmailCancelConfirm,
          leftButtonText: UIText.generalNo,
          leftButtonPress: () =>
            stateController
              .dismissPopup()
              .then(() => this.openPendingEmailChangeUser(button)),
          rightButtonText: UIText.generalYes,
          rightButtonPress: execCancel
        })
      );

    const execCancel = async () => {
      const oauth = clientController.client.oauth || {};
      const data = {
        authToken: oauth["access_token"],
        email: changeRequest.newEmail
      };
      return (
        stateController
          .dismissPopup()
          .then(() =>
            apiService.async("POST", {
              headers: serverConfig.defaultHeaders,
              endpoint: endpointConfig.cancel_email_change,
              data
            })
          )
          // .then(console.log)
          .then(stateController.dismissPopup)
          .then(() =>
            stateController.showPopup({
              title: UIText.changeEmailPending,
              content: UIText.changeEmailCancelSuccess,
              leftButtonText: UIText.generalConfirm
            })
          )
          .then(() => (button.hidden = true))
          .catch(this._showError)
          .finally(() => {
            button.loading = false;
            button.value = null;
          })
      );
    };

    return stateController.showPopup({
      title: UIText.changeEmailPending,
      leftButtonText: UIText.changeEmailCancel,
      leftButtonPress: popupConfirmCancel,
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: () => {
        button.loading = false;
        return stateController.dismissPopup();
      },
      dismissOnBackPress: true,
      input: {
        defaultValue: changeRequest.newEmail,
        title: UIText.changeEmailPendingEmail,
        disabled: true
      }
    });
  };

  checkRecentEmailChangeUser = button => {
    button.loading = true;
    button.loaded = true;

    if (this.buttonsLoading.includes(button.name)) return;
    this.buttonsLoading.push(button.name);

    const undoValidTill = async changeRequest => {
      if (!changeRequest) return [true];
      return apiService
        .async("GET", {
          headers: serverConfig.defaultHeaders,
          endpoint: endpointConfig.get_revert_email_expiry(changeRequest.id)
        })
        .then(response => {
          const deadline = response.data;
          return [deadline, changeRequest];
        });
    };

    const hasRecentChange = changeRequest =>
      changeRequest.isCompleted &&
      !changeRequest.isCancelled &&
      !changeRequest.isReverted;

    return apiController
      .getEmailChangeRequest()
      .then(changeRequest => {
        if (isEmpty(changeRequest) || !hasRecentChange(changeRequest)) return;
        return changeRequest;
      })
      .then(undoValidTill)
      .then(([deadline, changeRequest]) => {
        if (!deadline || isEmpty(changeRequest)) return;
        changeRequest.deadline = new Date(deadline);
        button.value = changeRequest;
        button.loading = false;
        button.enabled = true;
        button.hidden = false;
      })
      .catch(err => {
        console.warn(err);
        return (button.hidden = true);
      })
      .finally(
        () =>
          (this.buttonsLoading = this.buttonsLoading.filter(
            b => b !== button.name
          ))
      );
  };

  openRecentEmailChangeUser = button => {
    button.loading = true;
    const changeRequest = button.value;

    const popupConfirmRevert = () =>
      stateController.dismissPopup().then(() =>
        stateController.showPopup({
          title: UIText.changeEmailRecent,
          content: UIText.changeEmailRevertConfirm,
          leftButtonText: UIText.generalNo,
          leftButtonPress: () =>
            stateController
              .dismissPopup()
              .then(() => this.openRecentEmailChangeUser(button)),
          rightButtonText: UIText.generalYes,
          rightButtonPress: execRevertRequest
        })
      );

    const execRevertRequest = async () => {
      const oauth = clientController.client.oauth || {};
      const data = {
        authToken: oauth["access_token"]
      };
      return (
        stateController
          .dismissPopup()
          .then(() =>
            stateController.showPopup({
              title: UIText.changeEmailRevert,
              content: UIText.pleaseWait
            })
          )
          .then(() =>
            apiService.async("POST", {
              headers: serverConfig.defaultHeaders,
              endpoint: endpointConfig.revert_email_change_request,
              data
            })
          )
          // .then(console.log)
          .then(stateController.dismissPopup)
          .then(() =>
            stateController.showPopup({
              title: UIText.changeEmailRecent,
              content: UIText.changeEmailRevertRequestSuccess,
              leftButtonText: UIText.generalConfirm,
              leftButtonPress: () =>
                stateController
                  .dismissPopup()
                  .then(stateController.viewProfileState.refresh)
            })
          )
          .catch(this._showError)
          .finally(() => {
            button.loading = false;
            button.value = null;
          })
      );
    };

    const getRevertDeadline = changeRequest => {
      const { deadline } = changeRequest;
      if (deadline) {
        return UIText.changeEmailRevertDeadline(deadline.toLocaleString());
      }
    };

    return stateController.showPopup({
      title: UIText.changeEmailRecent,
      leftButtonText: UIText.changeEmailRevertButton,
      leftButtonPress: popupConfirmRevert,
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: () => {
        button.loading = false;
        return stateController.dismissPopup();
      },
      dismissOnBackPress: true,
      inputSet: [
        {
          defaultValue: changeRequest.currentEmail,
          title: UIText.changeEmailRecentOld,
          disabled: true
        },
        {
          defaultValue: changeRequest.newEmail,
          title: UIText.changeEmailRecentNew,
          disabled: true,
          footer: getRevertDeadline(changeRequest)
        }
      ]
    });
  };

  /**
   * Auto select English and lock field when no other languages are selected;
   * Usage: ProfileScreen, RegistrationScreen, GroupMarketPage, various setups.
   */
  autoSelectEnglish = form => {
    if (isEmpty(form)) return;
    const noLangSelected = !form.some(
      f =>
        f.set &&
        f.set.match(/needLanguages|ableLanguages/g) &&
        f.name !== "needLanguages00" &&
        f.name !== "ableLanguages00" &&
        !!f.value
    );
    const English = form.find(f =>
      f.name.match(/needLanguages00|ableLanguages00/g)
    );
    if (!English) return;

    if (noLangSelected) {
      English.value = true;
      English.disabled = true;
    } else {
      English.disabled = false;
    }
  };

  generalEmptyPress = button => console.log(button);

  /**
   * Function for resending service provider onboarding emails in the Admin Centre additional form insert;
   */
  adminResendCandidateEmail = async field => {
    const { profileId, isPointerPro } = field;
    if (!profileId) return;
    const type =
      field.name === "resendRequestLink"
        ? "caregiver_onboard_resend_info_request"
        : field.name === "resendProfileLink"
        ? isPointerPro
          ? "caregiver_onboard_resend_profile_complete_old"
          : "caregiver_onboard_resend_profile_complete"
        : field.name === "resendQuestionnaireLink"
        ? "caregiver_onboard_resend_questionnaire"
        : undefined;
    if (!type) return;
    return stateController
      .showPopup({
        title: UIText.adminCentre,
        content: UIText.pleaseWait
      })
      .then(() =>
        apiService.async("GET", {
          endpoint: mcbEndpointConfig[type](profileId)
        })
      )
      .then(stateController.dismissPopup)
      .then(() =>
        stateController.showPopup({
          title: UIText.adminCentre,
          content:
            field.name === "resendRequestLink"
              ? UIText.adminResendInfoRequestSent
              : field.name === "resendQuestionnaireLink"
              ? UIText.adminResendQuestionnaireSent
              : UIText.adminResendProfileCompleteSent,
          leftButtonText: UIText.generalConfirm,
          dismissOnBackPress: true
        })
      )
      .catch(this._showError);
  };

  /**
   * Set hourly rate field to mandatory for cleaning service providers;
   */
  providerFieldMandatoryAssist = (form, groupTypeId) => {
    if (isEmpty(form)) return;
    const groupTypeIdField = form.find(f => f.name === "groupTypeId");
    if (!groupTypeIdField && !groupTypeId) return;
    // Required Hourly Rate conditional required
    const requiredHourlyRateField = form.find(
      f => f.name === "requiredHourlyRate"
    );
    if (!requiredHourlyRateField) return;
    requiredHourlyRateField.required =
      (groupTypeId || Number(groupTypeIdField.value)) === groupTypeIds.cleaner;
  };

  /**
   * Remove value for fields that does not match groupTypeId (mainly used during registration)
   */
  groupTypeIdDevalueFieldsAssist = form => {
    if (isEmpty(form)) return;
    const groupTypeIdField = form.find(f => f.name === "groupTypeId");
    if (!groupTypeIdField) return;
    for (const field of form) {
      const groupTypeId = Number(groupTypeIdField.value);
      if (isNaN(groupTypeId)) continue;
      if (!field.groupTypeIds) continue;
      const shouldDevalue = !field.groupTypeIds.includes(
        Number(groupTypeIdField.value)
      );
      field.hidden = shouldDevalue;
      if (shouldDevalue) field.value = false;
    }
  };

  /**
   * Helper function to validate and throw error message for service provider municipality fields.
   * At least one municipality is required.
   * Usage: ProfileScreen, RegistrationScreen, various service provider setups.
   */
  mandatoryAbleMunicipalityValidate = (form, isCaregiver) => {
    if (isEmpty(form) || isCaregiver) return true;
    const ableTerritoryFields = form.filter(f => f.set === "ableTerritories");
    const ableMunicipalityFields = form.filter(
      f => f.set === "ableMunicipalities"
    );
    if (isEmpty(ableTerritoryFields) || isEmpty(ableMunicipalityFields))
      return true;
    const pass =
      ableTerritoryFields.some(f => !!f.value) &&
      ableMunicipalityFields.some(f => !!f.value);
    if (!pass) {
      const territoryTitle = (ableTerritoryFields.find(f => !!f.title) || {})
        .title;
      if (territoryTitle) {
        const fieldElm = Array.from(document.querySelectorAll("div")).find(
          elm => elm.innerHTML === territoryTitle
        );
        if (fieldElm)
          fieldElm.scrollIntoView({
            behavior: "smooth"
          });
      }
      throw new Error(UIText.profileMandatoryAbleMunicipality);
    }
    return true;
  };

  /**
   * CAF open availability finder button for caregiver profiles and save municipalities on button click
   */
  openAvailabilityFinder = async button => {
    const profileId = (getQueryParameters(window.location.search) || {})
      .profile;
    if (!profileId)
      return console.warn(
        "Cannot determine current profile ID. Cannot auto save municipalities."
      );
    button.loading = true;
    const currentProfileForm = stateController.viewProfileState.getProfile();
    const unmodifiedProfileForm = stateController.viewProfileState.getProfileCopy();
    let dirtyFields = [];
    const saveAbleMunicipalities = async () => {
      // Use currentProfileForm,
      if (isEmpty(currentProfileForm) || isEmpty(unmodifiedProfileForm)) return;
      // Check if any of the ableMunicipalities are selected, if not then return.
      const hasMunicipalities = currentProfileForm.some(
        f =>
          f.name.match(/ableMunicipalities/g) &&
          f.name !== "ableMunicipalitiesText" &&
          !!f.value
      );
      console.log("hasMunicipalities", hasMunicipalities);
      if (!hasMunicipalities) return;
      // Filter out currentProfileForm to only keep ableTerritories and ableMunicipalities fields.
      const fieldsToKeep = [...currentProfileForm].filter(
        f =>
          f.name.match(/ableMunicipalities|ableTerritories/g) &&
          f.name !== "ableMunicipalitiesText"
      );
      dirtyFields = [...currentProfileForm].filter(
        f =>
          !fieldsToKeep.some(ftk => ftk.name === f.name) &&
          !isEqual(
            (unmodifiedProfileForm.find(uf => uf.name === f.name) || {}).value,
            f.value
          )
      );
      console.log("dirtyFields", dirtyFields);
      // Extract current profile data from the filtered form fields.
      const dataToApply = formService.disassembleFormData(fieldsToKeep);
      console.log("dataToApply", dataToApply);
      // Extract original profile data.
      const profileData = formService.disassembleFormData(
        unmodifiedProfileForm
      );
      console.log("profileData", profileData);
      // Assign current profile data to the original profile data.
      const newProfileData = {
        ...profileData,
        ...dataToApply
      };
      const unchanged = isEqual(newProfileData, profileData);
      console.log("newProfileData", newProfileData);
      console.log("isEqual", unchanged);
      if (unchanged) return;
      // API patch onto Caregiver's profile, then open the CAF URL.
      return apiService.async("PATCH", {
        endpoint: endpointConfig.profile_by_id(profileId),
        data: { data: JSON.stringify(newProfileData) }
      });
    };
    const restoreDirtyFields = action(() => {
      if (isEmpty(dirtyFields)) return;
      const { setField } = stateController.viewProfileState;
      dirtyFields.forEach(action(f => setField(f.name, f.value)));
    });
    return saveAbleMunicipalities()
      .then(openCAF)
      .then(stateController.viewProfileState.refresh)
      .then(restoreDirtyFields)
      .catch(this._showError)
      .finally(() => (button.loading = false));
  };

  /**
   * CAF auto hide edit availability button
   */
  autoHideEditAvailabilityButton = (form, groupTypeId) => {
    if (isEmpty(form) || !Array.isArray(form)) return;
    const ableMunicipalitySelected = form.some(
      f =>
        f.name.match(/ableMunicipalities/g) &&
        f.name !== "ableMunicipalitiesText" &&
        !!f.value
    );
    const isGreaterVancouver = form.some(
      f => f.placeholder === "Greater Vancouver" && !!f.value
    );
    const editAvailability = form.find(
      f => f.name === "openAvailabilityFinder"
    );
    if (!editAvailability) return;

    const isHiddenByGroupTypeId =
      editAvailability.groupTypeIds &&
      !editAvailability.groupTypeIds.includes(groupTypeId);

    editAvailability.hidden =
      !isGreaterVancouver || !ableMunicipalitySelected || isHiddenByGroupTypeId;
  };

  /**
   * Caregiver introduction auto hide old introduction field when no value.
   */
  autoHideOldIntroductionField = (form, groupTypeId) => {
    if (isEmpty(form) || !Array.isArray(form)) return;
    const oldIntroduction = form.find(f => f.name.match(/oldIntroduction/g));
    if (!oldIntroduction) return;
    oldIntroduction.hidden = !(oldIntroduction.value || "").trim();
  };

  /**
   * Auto open CAF if no availability set upon saving profile
   */
  caregiverSaveProfileReminder = form => {
    // const neighbourhoodSetsEmpty = form.some(
    //   f => f.name === "neighbourhoodSets" && isEmpty(f.value)
    // );
    // const availabilityLocationEmpty = form.some(
    //   f => f.name === "availabilityLocations" && isEmpty(f.value)
    // );
    // const availabilityEmpty = form.some(
    //   f =>
    //     f.name === "availability" &&
    //     isEmpty(f.value) &&
    //     Object.keys(f.value).length === 0
    // );
    const profileAvatarEmpty = form.some(
      f => f.name === "avatar" && isEmpty(f.value)
    );
    const userAvatarEmpty = !(
      (clientController.defaultMember.profile || {}).data || {}
    ).avatar;
    const missingAvatar = profileAvatarEmpty && userAvatarEmpty;
    // const missingAvailability = neighbourhoodSetsEmpty || availabilityEmpty;
    // const missingAvailability = availabilityLocationEmpty || availabilityEmpty;
    console.log("avatarEmpty", missingAvatar);
    console.log("userAvatarEmpty", userAvatarEmpty);
    // console.log("neighbourhoodSetsEmpty", neighbourhoodSetsEmpty);
    // console.log("availabilityLocationEmpty", availabilityLocationEmpty);
    // console.log("availabilityEmpty", availabilityEmpty);
    if (missingAvatar) {
      // if (!missingAvailability) {
      return stateController.showPopup({
        title: capitalize(UIText.profile),
        content: UIText.caregiverProfileSaveReminder(false, true),
        leftButtonText: UIText.generalConfirm
      });
      // }
      // return window.open(
      //   `${window.location.hostname}?profileSave=true${
      //     missingAvatar ? "&missingAvatar=true" : ""
      //   }${missingAvailability ? "&missingAvailability=true" : ""}`
      // );
    }
  };

  /**
   * Use "Nickname" instead of "First name" for legacy Caregiver profile screen
   */
  caregiverNicknameModifier = (form, typeClassId, groupTypeId) => {
    if (typeClassId !== typeClassIds.caregiverProfile) return;
    if (groupTypeId !== groupTypeIds.caregiver) return;
    const field = (form || []).find(f => f.name === "firstName");
    if (!field) return;
    field.placeholder = "Nickname";
    field.required = false;
  };

  /**
   * Automatically disable default profile name editing for onboarding or approved caregivers
   */
  shouldDisableDefaultProfileNameEditing = async (form, typeClassId) => {
    if (typeClassId !== 0) return;
    const groups = clientController.findVisibleGroups();
    if (
      groups.length !== 1 ||
      (groups[0] || {}).typeId !== groupTypeIds.caregiver
    )
      return;
    const caregiverGroup = groups[0];
    if (!caregiverGroup) return;
    const isApproved = !!((caregiverGroup.profile || {}).data || {})[
      "approvalOfCandidateConfirmedByReference"
    ];
    const onboarding = await apiService
      .async("GET", {
        endpoint: `${mcbEndpointConfig.caregiver_onboarding}?tokenData=true`
      })
      .then(response => response.data);
    if (isEmpty(onboarding) && !isApproved) return;
    const nameFields = form.filter(f =>
      ["firstName", "lastName"].includes(f.name)
    );
    if (isEmpty(nameFields)) return;
    nameFields.forEach(field => (field.disabled = true));
  };
}

const typeClassLibrary = new TypeClassLibrary();

export { typeClassLibrary };
