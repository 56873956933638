import React from "react";
import { Observer, observer } from "mobx-react";
import { ActivityIndicator, Linking, Platform, View } from "react-native";
import { computed, observable } from "mobx";
import { theme } from "../../../../config/style-configs/theme";
import { Text } from "react-native-elements";
import { fileService } from "../../../../cdm/services/file-service";
import { isEmpty } from "../../../../utils/helpers";
import { apiController } from "../../../../cdm/controllers/api-controller";

@observer
class AdminBackgroundCheckFilesView extends React.Component {
  @observable loading = true;
  @observable fileLoading = [];
  @observable onboardingUpload = {};

  @computed get files() {
    return this.onboardingUpload.files || [];
  }

  constructor(props) {
    super(props);
    this.getOnboardingUpload()
      .catch(props.showError)
      .finally(() => (this.loading = false));
  }

  getOnboardingUpload = async () => {
    const { onboarding } = this.props;
    return apiController
      .getAdminOnboardingUploadDataByOnboarding(onboarding)
      .then(
        onboardingUpload => (this.onboardingUpload = onboardingUpload || {})
      );
  };

  handleOpenFile = file => {
    this.fileLoading.push(file);
    return fileService
      .downloadAndGetFileBlobUrlById(file.id)
      .then(url => {
        if (Platform.OS === "web") {
          return fileService.invokeWebDownload(url, file.fileName);
        } else {
          return Linking.openURL(url);
        }
      })
      .catch(this.props.showError)
      .finally(() => this.fileLoading.remove(file));
  };

  renderFile = file => (
    <Observer key={file.id}>
      {() => (
        <View style={this.props.styles.item}>
          <Text
            style={this.props.styles.linkHeavy}
            onPress={() => this.handleOpenFile(file)}
          >
            {file.fileName}
          </Text>
          {this.fileLoading.includes(file) && (
            <ActivityIndicator size="small" color={theme.color} />
          )}
        </View>
      )}
    </Observer>
  );

  render() {
    return this.loading ? (
      <View style={this.props.styles.loading}>
        <ActivityIndicator size="small" color={theme.color} />
      </View>
    ) : (
      <View style={this.props.styles.style}>
        {isEmpty(this.files) ? (
          <Text style={this.props.styles.errorMessage}>
            No files uploaded yet
          </Text>
        ) : (
          this.files.map(this.renderFile)
        )}
      </View>
    );
  }
}

export { AdminBackgroundCheckFilesView };
