import React from "react";
import {
  ActivityIndicator,
  Platform,
  RefreshControl,
  ScrollView,
  View
} from "react-native";
import { observer, Observer } from "mobx-react";
import { theme } from "../../../../config/style-configs/theme";
import { ThemedButton } from "../../../../components/ThemedButton/index";
import { UIText } from "../../../../config/lang-config";
import { TopicCard } from "../../../../components/TopicCard";
import {
  capitalize,
  getDisplayNameEng,
  isEmpty,
  safeParseJSON
} from "../../../../utils/helpers";
import { Text } from "react-native-elements";
import { ListFilter } from "../../../../components/ListFilter";
import { ListFilterIndicator } from "../../../../components/ListFilterIndicator";
import {
  getTopicAssignedMembers,
  getTopicCompletionMember,
  getTopicCompletionState
} from "../../../../cdm/lib/topic-utilities";
import { CollapsibleAccordionTitle } from "../../../../components/CollapsibleAccordionTitle";
import Collapsible from "react-native-collapsible";
import VizSensor from "react-visibility-sensor";

@observer
class CareReceiverIssueListView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  renderTopics = (topics, timezone) => {
    if (!Array.isArray(topics) || topics.length === 0)
      return (
        <Text style={this.props.styles.errorMessage}>
          {UIText.issuesNoIssueToShow}
        </Text>
      );

    let newTopics = topics.filter(t => getTopicCompletionState(t) === "new");
    let clockedInTopics = topics.filter(
      t => getTopicCompletionState(t) === "clocked-in"
    );
    let clockedOutTopics = topics.filter(
      t => getTopicCompletionState(t) === "clocked-out"
    );

    newTopics = newTopics.length > 0 && this.controller.sortIssues(newTopics);
    clockedInTopics =
      clockedInTopics.length > 0 && this.controller.sortIssues(clockedInTopics);
    clockedOutTopics =
      clockedOutTopics.length > 0 &&
      this.controller.sortIssues(clockedOutTopics);

    return (
      <View style={this.props.styles.scrollViewCollapsible}>
        {newTopics &&
          this.renderTopicGroups(newTopics, UIText.issuesOpenIssue, timezone)}
        {clockedInTopics &&
          this.renderTopicGroups(
            clockedInTopics,
            UIText.issuesAcceptedIssue,
            timezone
          )}
        {clockedOutTopics &&
          this.renderTopicGroups(
            clockedOutTopics,
            UIText.issuesResolvedIssue,
            timezone
          )}
      </View>
    );
  };

  renderTopicGroups = (topics, type, timezone) => {
    return (
      <Observer>
        {() => (
          <View style={this.props.styles.scrollViewCollapsibleCards}>
            <CollapsibleAccordionTitle
              title={type}
              onTitlePress={() => this.controller.handleCollapse(type)}
              collapsed={this.controller.collapsible[type]}
            />
            <Collapsible
              style={this.props.styles.scrollViewCard}
              collapsed={!!this.controller.collapsible[type]}
            >
              {topics.map(topic => this.renderTopic(topic, timezone))}
            </Collapsible>
          </View>
        )}
      </Observer>
    );
  };

  renderTopic = (topic, timezone) => {
    const data = (topic.data && safeParseJSON(topic.data)) || {};
    const endTime =
      topic.endTime &&
      new Date(topic.endTime).toLocaleString("en-CA", {
        timeZone: timezone
      });
    const execEndTime =
      topic.execEndTime &&
      new Date(topic.execEndTime).toLocaleString("en-CA", {
        timeZone: timezone
      });
    const completionState = getTopicCompletionState(topic);
    const resolutionsState = topic["isCompleted"]
      ? UIText.issuesResolvedIssue
      : UIText.issuesOpenIssue;
    const assigneeDisplayName = getDisplayNameEng(
      (getTopicCompletionMember(topic) || {}).profile
    );
    const assigneeDisplayNameList = getTopicAssignedMembers(topic)
      .map(m => getDisplayNameEng(m.profile))
      .join(", ");

    const iconBackgroundColor = topic["isCompleted"] ? "" : theme.color;

    const showMenuIcon =
      this.controller.getTopicSummaryEditFlags(topic).length > 0;

    const children = (
      <View style={this.props.styles.cardContent}>
        <Text style={this.props.styles.cardContentMedium}>
          {capitalize(resolutionsState)}
        </Text>
        {completionState === "new" ? (
          <Text
            style={{
              ...this.props.styles.cardContentMedium,
              fontWeight: "normal"
            }}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {`${UIText.assignedTo}: ${assigneeDisplayNameList || ""}`}
            {isEmpty(assigneeDisplayNameList) && (
              <ActivityIndicator
                size={theme.FONT_SIZE_SMALL}
                color={theme.color}
              />
            )}
          </Text>
        ) : null}
        {topic["isCompleted"] ? (
          <View>
            <Text
              style={{
                ...this.props.styles.cardContentMedium,
                fontWeight: "normal"
              }}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {`${UIText.issueResolvedBy}: ${assigneeDisplayName || ""}`}
              {!assigneeDisplayName && (
                <ActivityIndicator
                  size={theme.FONT_SIZE_SMALL}
                  color={theme.color}
                />
              )}
            </Text>
            <Text
              style={{
                ...this.props.styles.cardContentSmall,
                fontWeight: "normal"
              }}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {`${UIText.issueResolvedDate}: ${execEndTime}`}
            </Text>
          </View>
        ) : (
          <View>
            {completionState !== "new" ? (
              <Text
                style={{
                  ...this.props.styles.cardContentMedium,
                  fontWeight: "normal"
                }}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {`${UIText.issueAcceptedBy}: ${assigneeDisplayName || ""}`}
                {!assigneeDisplayName && (
                  <ActivityIndicator
                    size={theme.FONT_SIZE_SMALL}
                    color={theme.color}
                  />
                )}
              </Text>
            ) : null}
            <Text
              style={{
                ...this.props.styles.cardContentSmall,
                fontWeight: "normal"
              }}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {`${UIText.issueDueDate}: ${endTime || UIText.topicTimeNotSet}`}
            </Text>
          </View>
        )}
      </View>
    );

    return (
      <Observer key={topic.id}>
        {() => (
          <View style={this.props.styles.card}>
            <VizSensor
              partialVisibility
              onChange={visible =>
                visible && this.controller.checkCompleteTopicData(topic)
              }
            >
              <TopicCard
                compact={true}
                title={topic.description || data.title || ""}
                icon="assignment-late"
                iconBackgroundColor={iconBackgroundColor}
                // iconType="material-community"
                showMenuIcon={showMenuIcon}
                menuPending={topic.pending}
                onMenuPress={e => this.controller.editTopic(e, topic)}
                onPress={e => this.controller.handleTopicPress(topic)}
                children={children}
              />
            </VizSensor>
          </View>
        )}
      </Observer>
    );
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        {(this.controller.loading || this.controller.refreshing) &&
        Platform.OS === "web" ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          <ScrollView
            contentContainerStyle={this.props.styles.scrollView}
            refreshControl={
              <RefreshControl
                refreshing={
                  this.controller.refreshing || this.controller.loading
                }
                onRefresh={this.controller.onRefresh}
              />
            }
          >
            <View style={this.props.styles.scrollViewContent}>
              <View style={this.props.styles.buttonContainer}>
                <ListFilter
                  data={this.controller.topics}
                  categories={this.controller.filterCategories}
                  filterSetter={this.controller.setFilter}
                />
                <ThemedButton
                  title={UIText.issuesAddIssue}
                  onPress={this.controller.addIssue}
                />
                {/*<ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />*/}
              </View>
              <ListFilterIndicator
                length={this.controller.filteredTopics.length}
                categories={this.controller.filterCategories}
              />
              {this.renderTopics(
                this.controller.filteredTopics,
                this.controller.timezone
              )}
            </View>
          </ScrollView>
        )}
      </View>
    );
  }
}

export { CareReceiverIssueListView };
