import React from "react";
import { ActivityIndicator, ScrollView, Text, View } from "react-native";
import { Icon } from "react-native-elements";
import { Observer, observer } from "mobx-react";
import { Header } from "../../../components/Header";
import {
  capitalize,
  getTimeZoneLongName,
  isEmpty
} from "../../../utils/helpers";
import { TabHeader } from "../../../components/TabHeader";
import { ThemedListItem } from "../../../components/ThemedListItem";
import { theme } from "../../../config/style-configs/theme";
import { Form } from "../../../components/Form";
import { UIText } from "../../../config/lang-config";
import { topicTypeIds } from "../../../config/variable-config";
import { ThemedButton } from "../../../components/ThemedButton";
import { TopicCard } from "../../../components/TopicCard";
import { ListFilter } from "../../../components/ListFilter";
import { ListFilterIndicator } from "../../../components/ListFilterIndicator";
import { TopicTaskList } from "../../../custom/mcb/components/TopicTaskList";
import {
  getTopicCompletionState,
  getTopicDescription
} from "../../../cdm/lib/topic-utilities";
import { computedFn } from "mobx-utils";
import { action } from "mobx";

@observer
class TopicScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller || props.screenProps.controller;
    this.formFields = [];
    this.summaryFormFieldProps = {
      showLabel: true,
      showTitle: true,
      enabledGetter: computedFn(() => false),
      valueGetter: computedFn(field => (field.value ? field.value : "—")),
      refSetter: () => {}
    };
    this.detailsFormFieldProps = {
      showLabel: true,
      showTitle: true,
      enabledGetter: computedFn(
        field => this.controller.fieldEditable() && field.name !== "email"
      ),
      valueGetter: computedFn(field =>
        field.value
          ? field.value
          : this.controller.dataLocked
          ? "—"
          : this.controller.fieldHasEditFlag(field)
          ? ""
          : // : this.controller.fieldEditable(field)
            // ? ""
            "—"
      ),
      onInputChange: action((e, field) => {
        field.value = e.nativeEvent.text;
        this.controller.shiftSilentlyClockIn();
      }),
      onCheckboxChange: action(field => {
        field.value = !field.value;
        this.controller.shiftSilentlyClockIn();
      }),
      onPickerChange: action((value, field) => {
        field.value = value;
        this.controller.shiftSilentlyClockIn();
      }),
      onSubmitEditing: action((form, field) => {
        this.controller.helpFocusNextField(field, this.formFields, form);
      }),
      refSetter: (form, field, f) => {
        this.formFields[form.indexOf(field)] = f;
      }
    };
    this.clockInOutFormFieldProps = {
      showLabel: true,
      showTitle: true,
      enabledGetter: computedFn(() => this.controller.isShiftClockFormEditable),
      valueGetter: computedFn(field => field.value),
      onDateTimeChange: this.controller.handleShiftClockFormChange,
      onSubmitEditing: action((form, field) => {
        this.controller.helpFocusNextField(field, this.formFields, form);
      })
    };
  }

  componentDidMount() {
    return this.controller.componentDidMount();
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  getTopicTypeName = topic => {
    return `${capitalize(topic.topicTypeName || " ")} ${
      topic.onCalendar ? UIText.topicCreatedViaCalendar.toLowerCase() : ""
    }`;
  };

  getTabComponent = tabKey => {
    if (tabKey === "summary") {
      return this.summaryTab();
    } else if (tabKey === "detail") {
      return this.detailTab();
    } else {
      return this.subTopicsTab();
    }
  };

  getTabHelpNotes = () => (
    <View>
      {this.controller.topic.typeId === topicTypeIds.task &&
      !this.controller.topic.isTemplate ? (
        <View style={this.props.styles.buttonReminder}>
          <Text style={this.props.styles.errorMessage}>
            {UIText.shiftTaskChatNote}
          </Text>
        </View>
      ) : null}

      {this.controller.topic.typeId === topicTypeIds.issue &&
        (this.controller.topicDetailButtonFlags.some(
          b => b.type === "takeover"
        ) ||
          this.controller.topicDetailButtonFlags.some(
            b => b.type === "start"
          )) && (
          <View style={this.props.styles.buttonReminder}>
            <Text style={this.props.styles.errorMessage}>
              {UIText.issueResolutionNote}
            </Text>
          </View>
        )}

      {this.controller.isShiftTopic &&
        !this.controller.topic.isTemplate &&
        getTopicCompletionState(this.controller.topic) !== "clocked-out" && (
          <View style={this.props.styles.buttonReminder}>
            <Text style={this.props.styles.errorMessage}>
              {this.controller.isShiftAssignedToSelf
                ? UIText.shiftSubmitHelp
                : UIText.shiftHelpNonAssigned}
            </Text>
          </View>
        )}
    </View>
  );

  summaryTab = () =>
    this.controller.loading ? (
      <View style={this.props.styles.loading}>
        <ActivityIndicator size="large" color={theme.color} />
      </View>
    ) : (
      <ScrollView contentContainerStyle={this.props.styles.scrollView}>
        <View style={this.props.styles.scrollViewContent}>
          <View style={this.props.styles.buttonContainer}>
            {this.renderSummaryEditButton()}
          </View>
          {this.getTabHelpNotes()}
          {this.renderSummary()}
        </View>
      </ScrollView>
    );

  detailTab = () =>
    this.controller.loading || this.controller.detailLoading ? (
      <View style={this.props.styles.loading}>
        <ActivityIndicator size="large" color={theme.color} />
      </View>
    ) : (
      <ScrollView contentContainerStyle={this.props.styles.scrollView}>
        <View style={this.props.styles.scrollViewContent}>
          <View style={this.props.styles.buttonContainer}>
            {this.renderSummaryEditButton()}
            {this.renderDetailButtons(this.controller.topicDetailButtonFlags)}
          </View>
          {this.getTabHelpNotes()}
          {this.renderDetails()}
        </View>
      </ScrollView>
    );

  subTopicsTab = () =>
    this.controller.loading ? (
      <View style={this.props.styles.loading}>
        <ActivityIndicator size="large" color={theme.color} />
      </View>
    ) : (
      <ScrollView contentContainerStyle={this.props.styles.scrollView}>
        <View style={this.props.styles.scrollViewContent}>
          <View style={this.props.styles.buttonContainer}>
            {this.renderSummaryEditButton()}
            <ListFilter
              data={this.controller.subTopics}
              categories={this.controller.filterCategories}
              filterSetter={this.controller.setFilter}
            />
          </View>
          <ListFilterIndicator
            length={this.controller.filteredSubTopics.length}
            categories={this.controller.filterCategories}
          />
          {this.controller.isShiftTopic &&
            !this.controller.topic.isTemplate &&
            this.controller.currentTabKey === "task" &&
            !isEmpty(this.controller.filteredSubTopics) && (
              <View style={this.props.styles.buttonReminder}>
                <Text style={this.props.styles.errorMessage}>
                  {this.controller.isShiftAssignedToSelf
                    ? UIText.shiftTaskListHelp
                    : UIText.shiftHelpNonAssigned}
                </Text>
              </View>
            )}
          <View style={this.props.styles.scrollViewCard}>
            {this.controller.isShiftTopic ? (
              <TopicTaskList
                tasks={this.controller.filteredSubTopics}
                timezone={this.controller.timezone}
                handleClockInClockOut={this.controller.handleClockInClockOut}
                handleTopicItemPress={this.controller.handleTopicItemPress}
              />
            ) : (
              this.renderTopics(this.controller.filteredSubTopics)
            )}
          </View>
        </View>
      </ScrollView>
    );

  renderSummary = () => {
    return (
      <Observer>
        {() => (
          <View style={this.props.styles.topicDetail}>
            <Form
              form={this.controller.summaryForm}
              fieldProps={this.summaryFormFieldProps}
              expandAll
            />
          </View>
        )}
      </Observer>
    );
  };

  renderDetails = () => {
    return (
      <Observer>
        {() => (
          <View style={this.props.styles.topicDetail}>
            {this.controller.isShiftTopic && this.renderClockInOutForm()}
            <Form
              form={this.controller.topicDetail}
              renderFlags={this.controller.topicDetailRenderFlags}
              editFlags={this.controller.topicDetailEditFlags}
              fieldProps={this.detailsFormFieldProps}
              expandAll={true}
            />
          </View>
        )}
      </Observer>
    );
  };

  renderDetailButtons = buttonFlags => (
    <Observer>
      {() =>
        buttonFlags
          .reduce(
            (a, v) => (!a.some(b => b.type === v.type) ? [...a, v] : a),
            []
          )
          .map(buttonFlag => {
            if (buttonFlag.type === "submit") {
              return (
                (this.controller.isShiftTopic
                  ? !this.controller.dataLocked || !this.controller.completed
                  : !this.controller.dataLocked) && (
                  <ThemedButton
                    style={{ marginBottom: 10 }}
                    key={buttonFlag.type}
                    width={45}
                    height={42}
                    title={buttonFlag.title || capitalize(UIText.generalSubmit)}
                    loading={this.controller.detailSaving}
                    disabled={
                      this.controller.saving ||
                      this.controller.loading ||
                      this.controller.isShiftTopicSubmitDisabled ||
                      buttonFlag.disabled
                    }
                    onPress={
                      this.controller.isShiftTopic
                        ? this.controller.handleShiftTopicSubmit
                        : this.controller.commitDetail
                    }
                  />
                )
              );
            }
            if (buttonFlag.type === "edit") {
              return [
                <ThemedButton
                  style={this.props.styles.buttons}
                  key={buttonFlag.type}
                  width={45}
                  height={42}
                  // icon={this.controller.editing ? "cancel" : "edit"}
                  title={
                    this.controller.editing
                      ? UIText.generalCancel
                      : capitalize(UIText.profileEdit)
                  }
                  disabled={
                    this.controller.saving ||
                    this.controller.loading ||
                    buttonFlag.disabled
                  }
                  onPress={
                    this.controller.editing
                      ? this.controller.cancelEdit
                      : this.controller.editDetail
                  }
                />,
                this.controller.editing && (
                  <ThemedButton
                    style={this.props.styles.buttons}
                    key={"save"}
                    width={45}
                    height={42}
                    // icon="save"
                    title={capitalize(UIText.profileSave)}
                    disabled={this.controller.saving || this.controller.loading}
                    loading={this.controller.detailSaving}
                    onPress={this.controller.editDetail}
                  />
                )
              ];
            }
            if (buttonFlag.type === "start" || buttonFlag.type === "complete") {
              return (
                <ThemedButton
                  style={this.props.styles.buttons}
                  key={buttonFlag.type}
                  width={45}
                  height={42}
                  icon={buttonFlag.icon}
                  iconType={buttonFlag.iconType}
                  title={
                    !buttonFlag.icon &&
                    (buttonFlag.title || capitalize(UIText.topicStartTopic))
                  }
                  loading={this.controller.completionSaving}
                  disabled={
                    this.controller.saving ||
                    this.controller.loading ||
                    buttonFlag.disabled
                  }
                  onPress={() =>
                    this.controller.handleClockInClockOut(
                      this.controller.topic,
                      buttonFlag.forceAssignee
                    )
                  }
                />
              );
            }
            if (buttonFlag.type === "takeover") {
              return (
                <ThemedButton
                  style={this.props.styles.buttons}
                  key={buttonFlag.type}
                  width={45}
                  height={42}
                  icon={buttonFlag.icon}
                  iconType={buttonFlag.iconType}
                  title={
                    !buttonFlag.icon &&
                    (buttonFlag.title ||
                      capitalize(UIText.topicTakeoverAssignee))
                  }
                  loading={this.controller.completionSaving}
                  disabled={
                    this.controller.saving ||
                    this.controller.loading ||
                    buttonFlag.disabled
                  }
                  onPress={() =>
                    this.controller.handleTakeoverAssignee(
                      this.controller.topic
                    )
                  }
                />
              );
            } else if (buttonFlag.type === "unassign") {
              return (
                <ThemedButton
                  style={this.props.styles.buttons}
                  key={buttonFlag.type}
                  width={45}
                  height={42}
                  icon={buttonFlag.icon}
                  iconType={buttonFlag.iconType}
                  title={
                    !buttonFlag.icon &&
                    (buttonFlag.title || capitalize(UIText.topicUnassignSelf))
                  }
                  loading={this.controller.completionSaving}
                  disabled={
                    this.controller.saving ||
                    this.controller.loading ||
                    buttonFlag.disabled
                  }
                  onPress={() =>
                    this.controller.handleUnassignSelf(
                      this.controller.topic,
                      buttonFlag.options
                    )
                  }
                />
              );
            }
            return null;
          })
      }
    </Observer>
  );

  renderSummaryEditButton = () =>
    this.controller.summaryEditFlags.length > 0 && (
      <ThemedButton
        width={45}
        height={42}
        icon="playlist-play"
        loading={this.controller.summarySaving}
        disabled={this.controller.saving || this.controller.loading}
        onPress={this.controller.editTopic}
      />
    );

  renderClockInOutForm = () => {
    return (
      <Observer>
        {() => (
          <Form
            form={this.controller.clockInOutForm}
            fieldProps={this.clockInOutFormFieldProps}
            expandAll
          />
        )}
      </Observer>
    );
  };

  renderTopics = topics =>
    topics.map(t => (
      <Observer key={t.id}>
        {() => (
          <TopicCard
            title={t.description}
            compact={true}
            onPress={() => this.controller.handleTopicPress(t)}
            text={t.topicTypeName || ""}
          />
        )}
      </Observer>
    ));

  getTopicStatus = topic =>
    `${topic.isLocked ? UIText.topicLocked + ", " : ""}${
      topic.isCompleted
        ? topic.typeId === topicTypeIds.shift
          ? UIText.shiftCompleted
          : topic.typeId === topicTypeIds.issue
          ? UIText.issuesResolvedIssue
          : capitalize(UIText.topicCompleted)
        : topic.typeId === topicTypeIds.shift
        ? topic.execStartTime
          ? UIText.shiftClockedInShifts
          : ""
        : ""
    }\nTime Zone: ${getTimeZoneLongName(this.controller.timezone, true)}`;

  render() {
    this.pageTabs = this.controller.topTabs.filter(Boolean);
    const isMessagingEnabled = this.controller.messagingEnabled && {
      rightIcon: this.controller.topic.lastPublicMessageTime
        ? "chat"
        : this.controller.groupChatLobby.lastPublicMessageTime
        ? "chat"
        : "chat-bubble",
      rightIconBadgeCount: this.controller.unreadCount,
      rightIconLoading: this.controller.messageLoading
    };

    return (
      <View style={this.props.styles.style}>
        <Header
          placement="center"
          title={
            this.controller.loading
              ? ""
              : `${this.controller.topicTypeName}: ${getTopicDescription(
                  this.controller.topic
                )}`
          }
          handleLeftPress={this.controller.handleBackPress}
          leftIcon="arrow-back"
          {...isMessagingEnabled}
          handleRightPress={this.controller.openChat}
          navigation={this.props.navigation}
        />

        <View style={this.props.styles.topicHeading}>
          <ThemedListItem
            leftIcon={
              <Icon
                name={this.controller.topicData.icon || "assignment"}
                color="#666"
                size={theme.FONT_SIZE_XXLARGE}
              />
            }
            title={getTopicDescription(this.controller.topic)}
            titleStyle={this.props.styles.topicHeadingTitle}
            subtitle={this.getTopicTypeName(this.controller.topic)}
            subtitleStyle={this.props.styles.topicHeadingSubtitle}
            rightTitle={this.getTopicStatus(this.controller.topic).trim()}
            rightTitleStyle={this.props.styles.topicHeadingRightTitle}
          />
        </View>

        {this.controller.loading ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          // TODO: This tab needs to be swippable*/
          <View style={this.props.styles.style}>
            <TabHeader
              tabs={this.pageTabs}
              currentKey={this.controller.currentTabKey}
            />
            {this.getTabComponent(this.controller.currentTabKey)}
          </View>
        )}
      </View>
    );
  }
}

export { TopicScreen };
