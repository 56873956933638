import { stateController } from "../../../cdm/controllers/state-controller";
import { computed, observable } from "mobx";
import { clientController } from "../../../cdm/controllers/client-controller";
import { apiService } from "../../../cdm/services/api-service";
import { mcbEndpointConfig } from "../../../custom/mcb/config/api-config";
import { isEmpty } from "../../../utils/helpers";
import { fileService } from "../../../cdm/services/file-service";

export class WizardController {
  @observable frameUrl;
  @observable showLogout = false;
  @observable isApproved = false;
  @observable questionnaireCompleted = false;
  @observable isMissingIntroduction = false;

  @computed get groupId() {
    return (
      Number(this.props.navigation.getParam("group")) ||
      stateController.viewGroupId
    );
  }
  @computed get group() {
    return clientController.findGroupById(this.groupId);
  }

  constructor(props) {
    this.props = props;
    stateController.currentScreen = "Wizard";
    stateController.onboardingProfileClicked = true;

    this.checkQuestionnaire()
      .then(this.setFrameUrl)
      .catch(console.error);
  }

  checkQuestionnaire = async () => {
    const onboarding = await apiService
      .async("GET", {
        endpoint: `${mcbEndpointConfig.caregiver_onboarding}?tokenData=true`
      })
      .then(response => response.data);
    const profileData = onboarding.profileData;
    const isApproved =
      profileData && !!profileData["approvalOfCandidateConfirmedByReference"];
    const screeningDates = ((onboarding || {}).onboarding || {})
      .screeningStatusDates;
    this.questionnaireCompleted = (screeningDates || []).some(
      d => d.screeningStatus === "questionnaire completed"
    );
    this.isMissingIntroduction =
      profileData &&
      (!profileData["introductionQuestionBestStrengths"] ||
        !profileData["introductionQuestionOriginalInterest"] ||
        !profileData["introductionQuestionPastExperience"] ||
        !profileData["yearsOfExperienceAsCaregiver"]);
    this.isApproved = isApproved;
    this.showLogout = !isEmpty(onboarding) && !this.questionnaireCompleted;
  };

  setFrameUrl = () => {
    const targetStep = this.isMissingIntroduction
      ? "/intro"
      : this.questionnaireCompleted
      ? "/availability"
      : "";
    this.frameUrl =
      location.hostname === "localhost" && location.port === "3000"
        ? `http://localhost:3003/caregiver/steps${targetStep}`
        : `/caregiver/steps${targetStep}`;
  };

  handleBackPress = () => {
    fileService.resetAllAvatars();
    if (this.showLogout) {
      return clientController.execLogout(null, true);
    }
    this.props.navigation.navigate("Group", {
      group: stateController.viewGroupId,
      editProfile: undefined
    });
  };
}
