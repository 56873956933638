export const timezoneNames = [
  "America/Adak",
  "America/Anchorage",
  "America/Atikokan",
  "America/Blanc-Sablon",
  "America/Boise",
  "America/Cambridge_Bay",
  "America/Chicago",
  "America/Creston",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Edmonton",
  "America/Fort_Nelson",
  "America/Glace_Bay",
  "America/Goose_Bay",
  "America/Halifax",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Los_Angeles",
  "America/Menominee",
  "America/Metlakatla",
  "America/Moncton",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Pangnirtung",
  "America/Phoenix",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Resolute",
  "America/Sitka",
  "America/St_Johns",
  "America/Swift_Current",
  "America/Thunder_Bay",
  "America/Toronto",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Pacific/Honolulu"
];
