import React from "react";
import { Clipboard, Platform } from "react-native";
import { ThemedPopup } from "../ThemedPopup";
import { UIText } from "../../config/lang-config";
import { preventDefaultStopProp } from "../../utils/helpers";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
    this.error = error;
    this.info = info;
    this.setState({ hasError: true });
  }

  copyError = e => {
    preventDefaultStopProp(e);
    Clipboard.setString(this.error.toString() + this.info.componentStack);
    alert("Copied!");
  };

  sendErrorRefresh = () => {
    // Error logging API goes here
    // ...
    Platform.OS === "web" && window.location.reload();
  };

  renderError = () => {
    const popupState = {
      isVisible: true,
      title: UIText.title,
      content: UIText.fatalError,
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: this.sendErrorRefresh,
      leftButtonText: UIText.copyFatalError,
      leftButtonPress: this.copyError
    };
    return <ThemedPopup popupState={popupState} />;
  };

  render() {
    return this.state.hasError ? this.renderError() : this.props.children;
  }
}

export { ErrorBoundary };
