import React from "react";
import { AppState, KeyboardAvoidingView } from "react-native";
import { Button } from "react-native-elements";
import { computed } from "mobx";
import { observer } from "mobx-react";
import { clientController } from "../cdm/controllers/client-controller";
import { stateController } from "../cdm/controllers/state-controller";
import { txService } from "../cdm/services/tx-service";
import { msgService } from "../cdm/services/messaging-service";
import { formService } from "../cdm/services/form-service";
import { Loading } from "../screens/loading";
import { App } from "../navigators/app/AppNavigator";
import { Auth } from "../navigators/auth/AuthNavigator";
import { ThemedPopup } from "../components/ThemedPopup";
import NavigationService from "../utils/navigation-service";
import { UrlInterceptor } from "../components/UrlInterceptor";
import { MenuProvider } from "react-native-popup-menu";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { env } from "../config/variable-config";
import { theme } from "../config/style-configs/theme";
import { responsive } from "../config/style-configs/responsive";
import { embeddedService } from "../cdm/services/embedded-service";
import { apiService } from "../cdm/services/api-service";
import { wordPressService } from "../cdm/services/wordpress-service";
import { placesService } from "../cdm/services/places-service";

const buttonStyle = {
  position: "absolute",
  bottom: 0,
  left: 0
};
const betaLabelStyle = {
  position: "absolute",
  bottom: 0,
  left: 0,
  color: "#aaa",
  fontSize: theme.FONT_SIZE_MEDIUM
};

const BetaLabel = () => (
  <Button
    style={buttonStyle}
    title="Beta"
    type="clear"
    titleStyle={betaLabelStyle}
    onPress={clientController.baseSwitch}
    onLongPress={() => clientController.baseSwitch({ prod: true })}
  />
);

const BaseSwitchIndicator = () => (
  <Button
    style={{
      ...buttonStyle,
      ...{ left: undefined, right: 0 }
    }}
    title={apiService.axios.defaults.baseURL}
    type="clear"
    titleStyle={{
      ...betaLabelStyle,
      ...{ left: undefined, right: 0 }
    }}
    onLongPress={clientController.clearBaseSwitch}
  />
);

@observer
class RootContainer extends React.Component {
  @computed get loginState() {
    return clientController.loginState;
  }

  @computed get initialized() {
    return clientController.initialized;
  }

  constructor(props) {
    super(props);
    this.client = clientController;
    this.kingOfStates = stateController;
    this.form = formService;
    this.tx = txService;
    this.msg = msgService;
    this.responsive = responsive;
    this.embedded = embeddedService;
    this.wordpress = wordPressService;
    this.placesService = placesService;
  }

  componentDidMount() {
    AppState.addEventListener("change", console.log);
  }

  render() {
    const Navigator = this.loginState ? App : Auth;

    const style = {
      flex: 1,
      backgroundColor: "#fff"
    };

    return (
      <MenuProvider backHandler={true} withMenuContext>
        <ErrorBoundary>
          <UrlInterceptor />
          <KeyboardAvoidingView style={style} behavior="padding" enabled>
            {!clientController.isSyncMode && this.initialized ? (
              <Navigator
                ref={navigatorRef =>
                  NavigationService.setTopLevelNavigator(navigatorRef)
                }
              />
            ) : (
              !clientController.isSyncMode && <Loading />
            )}
            <ThemedPopup popupState={stateController.popup} />
            {env !== "prod" && <BetaLabel />}
            {env !== "prod" && clientController.baseSwitched && (
              <BaseSwitchIndicator />
            )}
          </KeyboardAvoidingView>
        </ErrorBoundary>
      </MenuProvider>
    );
  }
}

export { RootContainer };
