import React from "react";
import { ResetController } from "./controller";
import { ResetScreen } from "./view";
import { extraStyles, styles } from "./styles";
import { UIText } from "../../../config/lang-config";

export class Reset extends React.Component {
  static navigationOptions = {
    title: `Reset Password | ${UIText.title}`
  };

  constructor(props) {
    super(props);
    this.controller = new ResetController(this.props);
  }

  render() {
    return (
      <ResetScreen
        {...this.props}
        styles={styles}
        extraStyles={extraStyles}
        controller={this.controller}
      />
    );
  }
}
