import { common } from "../../../../config/style-configs/common";

export const styles = {
  buttonContainer: common.listButtonContainer,
  buttonContainerPicker: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap"
  }
};
