import { clientController } from "../../../../../../cdm/controllers/client-controller";
import { stateController } from "../../../../../../cdm/controllers/state-controller";
import { getDisplayNameEng } from "../../../../../../utils/helpers";
import { groupTypeIds } from "../../../../../../config/variable-config";
import { householdSetup } from "./setup";

export const householdGroup = isFirstTime => ({
  preSetup: clientController.getUserGroupsAndMembers,
  rootBack: {
    onPress: props => {
      stateController.initSetupMode = "addGroup";
      props.resetSetup();
    }
  },
  skipSelect: true,
  initialTitle: isFirstTime
    ? "First Time Setup"
    : `New ${
        clientController.findGroupTypeById(groupTypeIds.household).typeName
      }`,
  initialHeading: `Hi, ${getDisplayNameEng(
    clientController.defaultMember.profile
  )}, welcome!\nChoose the following options to start with:`,
  finishTitle: "Setup Complete",
  setupList: [householdSetup()],
  onFinishSetup: props =>
    isFirstTime
      ? (() => props.navigation.navigate("Root"))()
      : (() => {
          const groups = clientController.findVisibleGroups();
          if (groups.length > 0) {
            const groupId = groups.sort((a, b) => b.id - a.id)[0].id;
            stateController.viewGroupId = groupId;
            return props.navigation.navigate("Group", { group: groupId });
          }
        })(),
  finishHeading: isFirstTime && "Thank you for completing the initial setup."
  // footerNote:
  //   "*If you are interested in hiring a service provider, a portion of our undefined Tools will be used to collect certain information about the undefined so that an optimal match may be found for you.\n\nPlease be assured that all information collected will kept strictly confidential and in compliance with all privacy regulations"
});
