import { theme } from "../../config/style-configs/theme";
import { Platform } from "react-native";

const borderColor = "#333";

export const styles = {
  container: {
    borderBottomWidth: 0
  },
  input: {
    borderBottomWidth: 1,
    borderColor,
    fontSize: theme.FONT_SIZE_MEDIUM,
    padding: 5,
    textAlignVertical: "top",
    ...Platform.select({
      ios: {
        //,
      },
      android: {
        //,
      },
      web: {
        outlineStyle: "none"
      }
    })
  },
  multiline: {
    minHeight: 100,
    marginTop: 5
  },
  inputLeftIcon: {
    alignSelf: "flex-start",
    height: "auto",
    marginTop: 10,
    marginLeft: 0,
    marginRight: 10
  },
  inputRightIcon: {
    marginLeft: 0,
    borderBottomWidth: 1,
    borderColor
  },
  inputHeightCalculator: {
    position: "absolute",
    overflowY: "auto",
    padding: 6,
    right: 0
  },
  inputHeightCalculatorText: { fontSize: theme.FONT_SIZE_MEDIUM }
};
