import React from "react";
import { AdminPayrollPageController } from "./controller";
import { AdminPayrollPageView } from "./view";
import { styles } from "./styles";

export class AdminPayrollPage extends React.Component {
  render() {
    const controller = new AdminPayrollPageController(this.props);

    return (
      <AdminPayrollPageView
        controller={controller}
        {...this.props}
        styles={styles}
      />
    );
  }
}
