import { common } from "../../../config/style-configs/common";
import { theme } from "../../../config/style-configs/theme";

export const styles = {
  style: {
    flex: 1
  },
  loading: common.loading,
  loadingText: {
    color: theme.color,
    fontSize: theme.FONT_SIZE_MEDIUM,
    marginTop: 10
  },
  frameContainer: {
    flex: 1,
    height: "100%"
  },
  frame: {
    height: "100%",
    border: "none"
  }
};
