import React from "react";
import { Platform, View } from "react-native";
import { stateController } from "../../cdm/controllers/state-controller";
import {
  asyncPause,
  capitalize,
  getQueryParameters,
  isEmpty,
  safeParseJSON,
  whenFulfill
} from "../../utils/helpers";
import { clientController } from "../../cdm/controllers/client-controller";
import { observable } from "mobx";
import { apiController } from "../../cdm/controllers/api-controller";
import { UIText } from "../../config/lang-config";
import * as jsonpack from "jsonpack/main";
import * as URLSafeBase64 from "urlsafe-base64";
import { terms_of_service_2021_08 } from "../../custom/mcb/assets/terms_of_service_2021_08";
import { endpointConfig } from "../../config/api-config";

export class UrlInterceptor extends React.Component {
  @observable webPath = Platform.OS === "web" && window.location.pathname;
  @observable webParams = Platform.OS === "web" && window.location.search;

  constructor(props) {
    super(props);
    if (Platform.OS === "web") {
      this.interceptLogout();
      this.interceptTerms();
      this.interceptTemplates();
      this.interceptVerification();
      this.interceptPasswordReset();
      this.interceptInvitation();
      this.interceptInvitationVerification();
      this.interceptCaregiverPostInterview();
      this.interceptCaregiverJobReference();
      this.interceptCaregiverUpload();
      this.interceptRatingReview();
      this.interceptAuthForm();
      this.interceptRegistrationType();
      this.interceptRegistrationPlan();
      this.interceptFutureLinks();
      this.interceptSaveProfilePopup();
    }
  }

  _forceLogout = resetUrl => {
    clientController.clearStorage();
    clientController.execLogout(null, resetUrl);
  };

  interceptLogout = () => {
    if (
      this.webPath.match(/regist/i) ||
      this.webPath.match(/signout/i) ||
      this.webPath.match(/logout/i) ||
      this.webPath.match(/logoff/i) ||
      this.webPath.match(/visitor/i) ||
      this.webPath.match(/caregiver/i) ||
      this.webPath.match(/reviewAndRating/i)
    ) {
      // window.stop();
      return this._forceLogout(
        // reset url
        !this.webPath.match(/visitor/i) &&
          !this.webPath.match(/regist/i) &&
          !this.webPath.match(/caregiver/i) &&
          !this.webPath.match(/reviewAndRating/i)
      );
    }
  };

  interceptTerms = () => {
    const params = getQueryParameters(this.webParams);
    const showTerms = params["showterms"] || params["showTerms"];
    if (showTerms === "true" && Platform.OS === "web") {
      return whenFulfill(() => document.readyState === "complete").then(() => {
        window.stop();
        window.document.write(terms_of_service_2021_08);
      });
    } else {
      document.body.style.display = "";
    }
  };

  interceptTemplates = () => {
    const path = window.location && window.location.pathname;
    if (path.match(/emailTemplate\/public/g)) {
      this._forceLogout();
      const { template, templateData } = getQueryParameters(this.webParams);
      if (template && templateData && Platform.OS === "web") {
        let postData, buffer;
        try {
          buffer = URLSafeBase64.decode(templateData);
          postData = buffer && jsonpack.unpack(buffer.toString());
        } catch (e) {
          console.warn(e);
          postData = safeParseJSON(buffer.toString()); // Second pass for legacy JSON string support;
        } finally {
          postData &&
            apiController
              .getParsedHtmlTemplate(template, postData, true)
              .then(html => {
                return whenFulfill(
                  () => document.readyState === "complete"
                ).then(() => {
                  window.stop();
                  window.document.write(html);
                });
              });
        }
      }
    }
  };

  interceptVerification = () => {
    const hash = getQueryParameters(this.webParams).hash;
    const user = getQueryParameters(this.webParams).user;
    const email = getQueryParameters(this.webParams).email;
    const path = window.location && window.location.pathname;
    if (hash && (user || email) && path.match(/verification/g)) {
      this._forceLogout();
      stateController.verificationHash = hash;
    }
  };

  interceptPasswordReset = () => {
    const token = getQueryParameters(this.webParams).token;
    const user = getQueryParameters(this.webParams).user;
    const continueUrl = getQueryParameters(this.webParams).continueUrl;
    if (token && user) {
      this._forceLogout(true);
      stateController.viewResetState.resetToken = token;
      stateController.viewResetState.resetUser = user;
      stateController.viewResetState.continueUrl = continueUrl;
    }
  };

  interceptInvitation = () => {
    const id = getQueryParameters(this.webParams).invitationId;
    const hash = getQueryParameters(this.webParams).invitationHash;
    const inviteeEmail = getQueryParameters(this.webParams).inviteeEmail;
    if (id && hash && inviteeEmail) {
      this._forceLogout();
      stateController.viewInvitationRegState.id = id;
      stateController.viewInvitationRegState.hash = hash;
      stateController.viewInvitationRegState.inviteeEmail = inviteeEmail;
    }
  };

  interceptInvitationVerification = () => {
    const id = getQueryParameters(this.webParams).invitationId;
    const loginToken = getQueryParameters(this.webParams).invitationLoginToken;
    const user = getQueryParameters(this.webParams).user;
    if (id && loginToken && user) {
      this._forceLogout(true);
      stateController.viewInvitationRegState.id = id;
      stateController.viewInvitationRegState.loginToken = loginToken;
      stateController.viewInvitationRegState.loginUser = user;
    }
  };

  interceptCaregiverPostInterview = () => {
    const token = getQueryParameters(this.webParams).token;
    const email = getQueryParameters(this.webParams).email;
    if (token && email) {
      this._forceLogout(true);
      stateController.viewCaregiverPostInterviewState.submissionToken = token;
      stateController.viewCaregiverPostInterviewState.submissionUser = email;
    }
  };

  interceptCaregiverJobReference = () => {
    const id = getQueryParameters(this.webParams).id;
    const onboardingId = getQueryParameters(this.webParams).onboardingId;
    const emailQuestionResponseId = getQueryParameters(this.webParams)
      .responseId;
    if (id && onboardingId && emailQuestionResponseId) {
      this._forceLogout();
      stateController.viewCaregiverJobReferenceState.id = id;
      stateController.viewCaregiverJobReferenceState.onboardingId = onboardingId;
      stateController.viewCaregiverJobReferenceState.emailQuestionResponseId = emailQuestionResponseId;
    }
  };

  interceptCaregiverUpload = () => {
    const token = getQueryParameters(this.webParams).token;
    const onboardingId = getQueryParameters(this.webParams).onboardingId;
    if (token && onboardingId) {
      this._forceLogout();
      stateController.viewCaregiverUploadState.token = token;
      stateController.viewCaregiverUploadState.onboardingId = onboardingId;
    }
  };

  interceptRatingReview = () => {
    const token = getQueryParameters(this.webParams).token;
    const hashId = getQueryParameters(this.webParams).userHashId;
    const rating = getQueryParameters(this.webParams).rating;
    if (token && hashId && rating) {
      this._forceLogout();
      stateController.viewRatingReviewState.submissionToken = token;
      stateController.viewRatingReviewState.userHashId = hashId;
      stateController.viewRatingReviewState.rating = rating;
    }
  };

  interceptAuthForm = () => {
    const base64Form = getQueryParameters(this.webParams).form;
    const json = base64Form && atob(base64Form);
    if (json) {
      this._forceLogout(true);
      stateController.base64form = safeParseJSON(json) || {};
    }
  };

  interceptRegistrationType = () => {
    const regType = getQueryParameters(this.webParams).type;
    if (regType && Platform.OS === "web") {
      stateController.base64form = {
        roleIdList:
          regType === "family"
            ? [3]
            : regType === "paid"
            ? [9]
            : regType === "provider"
            ? ["other"]
            : null,
        roleIdListShowCheckbox: true
      };
    }
  };

  interceptRegistrationPlan = () => {
    const planType = getQueryParameters(this.webParams).plan;
    const promo =
      getQueryParameters(this.webParams).promo ||
      getQueryParameters(this.webParams).promoCode;
    if (planType && Platform.OS === "web") {
      if (isEmpty(stateController.base64form)) stateController.base64form = {};
      stateController.base64form.planId = planType;
      stateController.base64form.promoCode = promo;
    }
  };

  interceptSaveProfilePopup = () => {
    const params = getQueryParameters(this.webParams);
    const profileSave = params["profileSave"];
    const missingAvatar = params["missingAvatar"] === "true";
    const missingAvailability = params["missingAvailability"] === "true";
    if (profileSave === "true" && Platform.OS === "web") {
      clientController.preventLoading = true;
      let seconds = 8,
        pressed = false;
      stateController
        .showPopup({
          title: capitalize(UIText.profile),
          content: UIText.caregiverProfileSaveReminder(
            !missingAvatar,
            !missingAvailability
          ),
          leftButtonText: `${UIText.generalConfirm} (${seconds})`,
          leftButtonPress: () => {
            pressed = true;
            return stateController.dismissPopup();
          }
        })
        .then(async () => {
          while (seconds > 0) {
            if (pressed) break;
            await asyncPause(1000);
            seconds--;
            stateController.popup.leftButtonText = `${
              UIText.generalConfirm
            } (${seconds})`;
          }
          const url = `https://${endpointConfig.wp_hostname}/finder`;
          stateController.dismissPopup().then(() => (window.location = url));
        });
    }
  };

  interceptFutureLinks = () => {
    const { pathname } = window.location;
    if (pathname && pathname.match(/comingSoon/g)) {
      window.stop();
      this._forceLogout(true);
      return stateController
        .showPopup({
          title: UIText.title,
          content: UIText.comingSoon
        })
        .then(stateController.lockPopup);
    }
  };

  render() {
    return <View />;
  }
}
