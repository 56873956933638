import React from "react";
import { ActivityIndicator, Image, TouchableOpacity, View } from "react-native";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { Avatar, Icon } from "react-native-elements";
import { theme } from "../../config/style-configs/theme";

@observer
class AvatarView extends React.Component {
  @observable loading = true;

  showLoading = () => (this.loading = true);

  doneLoading = e => (this.loading = false);

  render() {
    const dimension = {
      width: this.props.size || this.props.styles.style.width,
      height: this.props.size || this.props.styles.style.height
    };

    const rounded = this.props.rounded && {
      borderRadius: dimension.width
    };

    const backgroundColor = this.props.backgroundColor && {
      backgroundColor: this.props.backgroundColor
    };

    const editButton = this.props.editButton || {};
    const editButtonSize = dimension.width / 3.75;

    return (
      <TouchableOpacity
        disabled={!this.props.onPress}
        onPress={this.props.onPress}
        style={{
          minWidth: dimension.width,
          minHeight: dimension.height
        }}
      >
        <View
          style={{
            ...this.props.styles.style,
            ...this.props.style,
            ...dimension
          }}
        >
          {this.props.source && this.props.source.uri ? (
            <View
              style={{
                ...this.props.styles.imageContainer,
                ...dimension
              }}
            >
              {(this.loading || this.props.loading) && (
                <ActivityIndicator
                  style={this.props.styles.loading}
                  size={dimension.width / 2}
                  color={theme.color}
                />
              )}
              <Image
                source={this.props.source}
                style={{
                  ...this.props.styles.image,
                  ...rounded,
                  ...dimension,
                  ...{ opacity: this.loading ? 0 : 1 }
                }}
                onLoadStart={this.showLoading}
                onLoad={this.doneLoading}
              />
            </View>
          ) : (
            <Avatar
              icon={this.props.icon}
              size={dimension.width}
              rounded={this.props.rounded}
              title={this.props.title}
              onLayout={this.doneLoading}
              overlayContainerStyle={backgroundColor}
            />
          )}
          {this.props.showEditButton && (
            <Icon
              containerStyle={{
                ...this.props.styles.icon,
                ...{ borderRadius: editButtonSize }
              }}
              iconStyle={{
                width: editButtonSize,
                height: editButtonSize
              }}
              size={editButtonSize}
              name={editButton.name || "edit"}
              type={editButton.type || "material"}
              color="#fff"
            />
          )}
        </View>
      </TouchableOpacity>
    );
  }
}

export { AvatarView };
