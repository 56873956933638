import React from "react";
import {
  ActivityIndicator,
  RefreshControl,
  ScrollView,
  Text,
  View
} from "react-native";
import { observer } from "mobx-react";
import { theme } from "../../../../config/style-configs/theme";
import { UIText } from "../../../../config/lang-config";
import { Header } from "../../../../components/Header";
import { ListFilterIndicator } from "../../../../components/ListFilterIndicator";
import { groupTypeIds } from "../../../../config/variable-config";
import { ThemedBottomNav } from "../../../../components/ThemedBottomNav";
import { ReportTimeSheetInsert } from "../../components/ReportTimesheetInsert";
import { AdminPayrollPage } from "../../components/AdminPayrollPage";
import { AdminTerritoryPage } from "../../components/AdminTerritoryPage";
import { AdminPromoPage } from "../../components/AdminPromoPage";
import { TabHeader } from "../../../../components/TabHeader";
import { Button } from "react-native-elements";
import { AdminProviderGroupPage } from "../../components/AdminProviderGroupPage";
import { AdminGroupCards } from "../../components/AdminGroupCards";
import { AdminCentreButtons } from "../../components/AdminCentreButtons";
import { AdminRatingReviewPage } from "../../components/AdminRatingReviewPage";
import { fileService } from "../../../../cdm/services/file-service";

@observer
class AdminCentreScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  scrollViewRef = ref => (this.scrollView = ref);

  setScrollPos = () => {
    const { scrollPos } = this.controller;
    if (!this.scrollView || !scrollPos) return;
    return this.scrollView.scrollTo({ y: scrollPos, animated: false });
  };

  renderGroupCards = groups => {
    if (!Array.isArray(groups) || groups.length === 0) {
      return (
        <Text style={this.props.styles.errorMessage}>
          {this.controller.loading
            ? ""
            : UIText.adminCentreNothingToShow(
                this.controller.groupType["typeNamePlural"]
              )}
        </Text>
      );
    }

    return (
      <AdminGroupCards
        groups={groups}
        getAvatarUri={fileService.getProfileAvatarUri}
        hideAvatar={this.controller.hideAvatar}
        is3FreeGroup={this.controller.is3FreeGroup}
        loading={this.controller.loading}
        getPickerPlaceholder={this.controller.getPickerPlaceholder}
        handleCardPress={this.controller.handleCardPress}
        handleMenuPress={this.controller.handleMenuPress}
      />
    );
  };

  renderGroupTimeSheet = id => (
    <View style={this.props.styles.scrollViewContent}>
      <ReportTimeSheetInsert
        groupId={id}
        group={this.controller.timeSheetGroup}
        state={this.controller.timeSheetState}
        caregiverFilter={this.controller.timeSheetMemberFilter}
        showGroupName={true}
        showCollapsible={false}
        showPayrollOption={true}
      />
    </View>
  );

  renderCareReceivers = groups => (
    <View
      style={{
        ...this.props.styles.scrollViewCard,
        padding: 10
      }}
    >
      {this.renderGroupCards(groups)}
    </View>
  );

  renderCaregivers = () => {
    return (
      <View style={this.props.styles.style}>
        <TabHeader
          tabs={this.controller.caregiverTopTabs}
          currentKey={this.controller.caregiverTabKey}
        />
        <View
          style={{
            ...this.props.styles.scrollViewCard,
            padding: 10
          }}
        >
          {this.renderGroupCards(this.controller.visibleCaregiverGroups)}
        </View>
      </View>
    );
  };

  renderAdminCentreView = () => (
    <ScrollView
      ref={this.scrollViewRef}
      scrollEventThrottle={250}
      onScroll={this.controller.onScroll}
      contentContainerStyle={this.props.styles.scrollView}
      refreshControl={
        <RefreshControl
          refreshing={this.controller.loading}
          onRefresh={this.controller.onRefresh}
        />
      }
    >
      {this.controller.loading || this.controller.timeSheetLoading ? (
        <View style={this.props.styles.loading}>
          <ActivityIndicator size="large" color={theme.color} />
        </View>
      ) : (
        <View onLayout={this.setScrollPos}>
          <View
            style={
              !this.controller.timeSheetMode &&
              this.props.styles.scrollViewContent
            }
          >
            {!this.controller.timeSheetMode && (
              <AdminCentreButtons
                cards={this.controller.cards}
                filterCategories={this.controller.adminCentreFilterCategories}
                setFilter={this.controller.setAdminCentreFilter}
                hideAvatar={this.controller.hideAvatar}
                onShowHideAvatars={this.controller.showHideAvatars}
                loading={this.controller.loading}
                onRefresh={this.controller.onRefresh}
                sortBy={this.controller.sortBy}
                onSortByAlpha={this.controller.sortByAlpha}
                onSortByDate={this.controller.sortByDate}
                isProvider={false}
              />
            )}
            <ListFilterIndicator
              length={this.controller.filteredGroups.length}
              categories={this.controller.adminCentreFilterCategories}
            />
            {!this.controller.timeSheetMode &&
              this.controller.groupTypeId === groupTypeIds.careReceiver && (
                <View style={this.props.styles.buttonReminder}>
                  <Text style={this.props.styles.errorMessage}>
                    {UIText.adminCentreTimeSheetModeText}
                  </Text>
                </View>
              )}
          </View>
          {this.controller.timeSheetMode
            ? this.renderGroupTimeSheet(this.controller.timeSheetGroupId)
            : this.controller.isCareCircleOrHousehold
            ? this.renderCareReceivers(this.controller.filteredGroups)
            : this.renderCaregivers()}
          {!this.controller.timeSheetMode &&
            !this.controller.noMoreNext &&
            (this.controller.paging ? (
              <ActivityIndicator
                size={24}
                style={{ paddingBottom: 10, minHeight: 50 }}
                color={theme.color}
              />
            ) : (
              <Button
                type="clear"
                style={{ minHeight: 50 }}
                title={
                  this.controller.hasFilter
                    ? UIText.adminLoadAll
                    : UIText.adminLoadMore
                }
                titleStyle={{ color: theme.color }}
                onPress={() =>
                  this.controller.hasFilter
                    ? this.controller.getAndProcessAllGroups()
                    : this.controller.getAndProcessNextPageGroups()
                }
              />
            ))}
        </View>
      )}
    </ScrollView>
  );

  render() {
    return (
      <View style={this.props.styles.style}>
        <Header
          title={UIText.adminCentre}
          leftIcon={
            this.controller.timeSheetMode || this.controller.ratingReviewMode
              ? "arrow-back"
              : "menu"
          }
          handleLeftPress={
            this.controller.timeSheetMode
              ? this.controller.handleTimeSheetBackPress
              : this.controller.ratingReviewMode
              ? this.controller.handleRatingReviewBackPress
              : null
          }
          navigation={this.props.navigation}
        />

        {this.controller.ratingReviewMode ? (
          <AdminRatingReviewPage />
        ) : this.controller.groupTypeId === "payroll" ? (
          <AdminPayrollPage />
        ) : this.controller.groupTypeId === "territory" ? (
          <AdminTerritoryPage />
        ) : this.controller.groupTypeId === "promo" ? (
          <AdminPromoPage />
        ) : this.controller.groupTypeId === "providers" ? (
          <AdminProviderGroupPage
            showError={this.controller.showError}
            navigation={this.props.navigation}
            handleCardPress={this.controller.handleCardPress}
            handleMenuPress={this.controller.handleMenuPress}
          />
        ) : (
          this.renderAdminCentreView()
        )}

        {!this.controller.timeSheetMode &&
          !this.controller.ratingReviewMode && (
            <ThemedBottomNav
              activeTab={this.controller.groupTypeId}
              onTabPress={this.controller.handleBottomTabChange}
              tabs={this.controller.bottomTabs}
            />
          )}
      </View>
    );
  }
}

export { AdminCentreScreen };
