import { careReceiverGroup } from "./groups/care-receiver/index";
import { caregiverGroup } from "./groups/caregiver/index";
import { invitation } from "./invitations/invite/index";
import { addGroup } from "./groups/add-group";
import { acceptInvite } from "./invitations/accept/index";
import { addTaskTemplate } from "./shifts/task-template/index";
import { addIssue } from "./issues";
import { cleanerGroup } from "./groups/cleaner";
import { householdGroup } from "./groups/houshold";
import { handymanGroup } from "./groups/handyman";
import { transportationGroup } from "./groups/transportation";

export const initSetup = mode => {
  let setup;
  switch (mode) {
    case 3: {
      setup = careReceiverGroup(true); // true === isFirstTime
      setup.preSetup && setup.preSetup();
      return setup;
    }
    case 3.1: {
      setup = careReceiverGroup();
      setup.preSetup && setup.preSetup();
      return setup;
    }
    case "finishScratchpad": {
      setup = careReceiverGroup(false, true); // second true === finishScratchpad
      return setup;
    }
    case "convertScratchpad": {
      setup = careReceiverGroup(false, true, true); // second true === finishScratchpad, third true === convertScratchpad
      return setup;
    }
    case "prePlanPromo": {
      setup = careReceiverGroup(false, false, false, true); // second true === finishScratchpad, third true === convertScratchpad, forth true === fromSubscription
      return setup;
    }
    case 9: {
      setup = caregiverGroup(true); // true === isFirstTime
      setup.preSetup && setup.preSetup();
      return setup;
    }
    case 9.1: {
      setup = caregiverGroup();
      setup.preSetup && setup.preSetup();
      return setup;
    }
    case "invite": {
      setup = invitation();
      !!setup.preSetup && setup.preSetup();
      return setup;
    }
    case "accept_invite": {
      setup = acceptInvite();
      !!setup.preSetup && setup.preSetup();
      return setup;
    }
    case "addGroup": {
      setup = addGroup();
      return setup;
    }
    case "addTaskTemplate": {
      setup = addTaskTemplate();
      return setup;
    }
    case "addTodo": {
      setup = addTaskTemplate("todo");
      return setup;
    }
    case "addIssue": {
      setup = addIssue();
      return setup;
    }
    case "addIssueAsSubTopic": {
      setup = addIssue("subTopic");
      return setup;
    }
    case "household": {
      setup = householdGroup();
      setup.preSetup && setup.preSetup();
      return setup;
    }
    case "cleaner": {
      setup = cleanerGroup();
      setup.preSetup && setup.preSetup();
      return setup;
    }
    case "handyman": {
      setup = handymanGroup();
      setup.preSetup && setup.preSetup();
      return setup;
    }
    case "transportation": {
      setup = transportationGroup();
      setup.preSetup && setup.preSetup();
      return setup;
    }
    default: {
      return {};
    }
  }
};
