import React from "react";
import { Linking, Picker, Platform, View } from "react-native";
import { Observer, observer } from "mobx-react";
import { Button, CheckBox, Divider, Icon, Text } from "react-native-elements";
import { Avatar } from "../Avatar";
import { ThemedInput } from "../ThemedInput";
import { getKeyboardType, getRGBFromStr } from "../../utils/helpers";
import { theme } from "../../config/style-configs/theme";
import { ThemedButton } from "../ThemedButton";
import { typeClassLibrary } from "../../cdm/lib/typeClass-utilities";
import { DatePicker } from "../DatePicker";
import { placeholderPickerValue } from "../../config/variable-config";
import { FilePickerWrapper } from "../FilePickerWrapper";
import { responsive } from "../../config/style-configs/responsive";
import { fileService } from "../../cdm/services/file-service";
import { UIText } from "../../config/lang-config";
import { stateController } from "../../cdm/controllers/state-controller";
import { AvatarHistoryPicker } from "../AvatarHistoryPicker";

const getPickerValue = field => {
  let isObject = false;
  const selected = field.value
    ? field.options.find(option =>
        option.name
          ? (isObject = true) && option.name === field.value
          : option === field.value
      ) || ""
    : field.options[0]
    ? field.options[0].name
      ? field.options[0].name
      : typeof field.options[0].name === "string" &&
        field.options[0].name === ""
      ? placeholderPickerValue
      : field.options[0]
    : placeholderPickerValue;
  return isObject ? selected.name : selected;
};

const showLightBox = source =>
  stateController.showPopup({
    image: source,
    dismissOnBackPress: true
  });

const Field = observer(props => {
  // trace();
  const field = props.field;

  if (field.type === "input") {
    const inputValue = props.inputValueRenderModifier
      ? props.enabled
        ? props.inputValue
        : props.inputValueRenderModifier(props.inputValue)
      : props.inputValue;

    return (
      <>
        {((props.showLabel && typeof field.showLabel !== "boolean") ||
          field.showLabel) &&
        (field.placeholder || field.label) ? (
          <Text
            style={{
              ...props.styles.fieldLabel,
              ...props.fieldLabelStyle
            }}
          >
            {`${field.label || field.placeholder} ${field.required ? "*" : ""}`}
          </Text>
        ) : null}
        <ThemedInput
          ref={props.refSetter}
          placeholder={`${field.placeholder} ${field.required ? "*" : ""}`}
          keyboardType={field.keyboardType || getKeyboardType(field)}
          leftIcon={
            field.icon ? (
              <Icon name={field.icon} size={24} color="black" />
            ) : props.inputLeftIcon ? (
              props.inputLeftIcon
            ) : null
          }
          rightIcon={props.inputRightIcon}
          rightIconContainerStyle={props.inputRightIconContainerStyle}
          leftIconContainerStyle={props.inputLeftIconContainerStyle}
          inputStyle={{
            ...props.styles.input,
            ...props.inputStyle
          }}
          errorMessage={field.errorMessage}
          defaultValue={props.inputDefaultValue}
          value={inputValue ? inputValue.toString() : ""}
          editable={props.enabled}
          returnKeyType="next"
          onChange={props.onInputChange}
          onEndEditing={props.onEndEditing}
          onBlur={props.onBlur}
          onSubmitEditing={props.onSubmitEditing}
          // Multiline props
          maxLength={field.maxLength}
          hideMaxLengthPlaceholder={field.hideMaxLengthPlaceholder}
          multiline={field.multiline}
          autoLineHeight={field.autoLineHeight}
          heightOverride={field.heightOverride}
        />
      </>
    );
  }
  if (field.type === "picker") {
    return (
      <>
        <Text
          style={{
            ...props.styles.pickerLabel,
            ...props.pickerLabelStyle
          }}
        >
          {`${field.placeholder} ${field.required ? "*" : ""}`}
        </Text>
        <Picker
          ref={props.refSetter}
          selectedValue={getPickerValue(field)}
          style={{
            ...props.styles.picker,
            ...props.pickerStyle
          }}
          enabled={props.enabled}
          onValueChange={props.onPickerChange} //WIP
        >
          {field.options.map(option => {
            return option.name ||
              (typeof option.name === "string" && option.name === "") ? (
              <Picker.Item
                key={option.name || placeholderPickerValue}
                label={option.placeholder}
                value={option.name || placeholderPickerValue}
              />
            ) : (
              <Picker.Item key={option} label={option} value={option} />
            );
          })}
        </Picker>
        {field.errorMessage ? (
          <Text style={props.styles.pickerErrorMessage}>
            {field.errorMessage}
          </Text>
        ) : null}
      </>
    );
  }
  if (field.type === "checkbox") {
    return (
      <>
        <CheckBox
          containerStyle={{
            ...props.styles.checkbox,
            ...props.checkboxStyle,
            ...(!props.enabled && props.styles.checkboxDisabled)
          }}
          ref={props.refSetter}
          title={field.placeholder}
          checkedIcon={
            <Icon
              name={props.enabled ? "check-box" : "check"}
              size={24}
              color="black"
            />
          }
          uncheckedIcon={
            <Icon name="check-box-outline-blank" size={24} color="black" />
          }
          checked={field.value}
          disabled={!props.enabled}
          checkedColor={theme.color}
          onPress={props.onCheckboxChange} //WIP
        />
      </>
    );
  }
  // if (field.type === "dynamicInput") {
  // }
  if (field.type === "link") {
    const onPress =
      typeClassLibrary[field.onPress] && typeClassLibrary[field.onPress];
    return (
      <>
        <Text
          style={props.styles.link}
          onPress={
            onPress
              ? () => onPress(field)
              : () => {
                  Platform.OS === "web"
                    ? window.open(field.value)
                    : Linking.openURL(field.value);
                }
          }
        >
          {field.placeholder}
        </Text>
      </>
    );
  }
  if (field.type === "linkHeavy") {
    const onPress =
      typeClassLibrary[field.onPress] && typeClassLibrary[field.onPress];
    return (
      <>
        <Text
          style={props.styles.linkHeavy}
          onPress={
            onPress
              ? () => onPress(field)
              : () => {
                  Platform.OS === "web"
                    ? window.open(field.value)
                    : Linking.openURL(field.value);
                }
          }
        >
          {field.placeholder}
        </Text>
      </>
    );
  }
  if (field.type === "text") {
    const value = field.value;
    return (
      <>
        {value ? (
          <Text
            style={{
              ...props.styles.fieldLabel,
              ...props.fieldLabelStyle,
              ...(field.styles && field.styles)
            }}
          >
            {value}
          </Text>
        ) : null}
      </>
    );
  }
  if (field.type === "custom") {
    const value = field.value;
    return (
      <>
        {((props.showLabel && typeof field.showLabel !== "boolean") ||
          field.showLabel) &&
        field.placeholder ? (
          <Text
            style={{
              ...props.styles.fieldLabel,
              ...props.fieldLabelStyle
            }}
          >
            {field.placeholder}
          </Text>
        ) : null}
        {value ? (
          <Text
            style={
              field.noStyles
                ? undefined
                : {
                    ...props.styles.fieldLabel,
                    ...props.fieldLabelStyle,
                    ...(field.styles && field.styles)
                  }
            }
          >
            {value}
          </Text>
        ) : null}
      </>
    );
  }
  if (field.type === "button") {
    const onPress =
      typeClassLibrary[field.onPress] && typeClassLibrary[field.onPress];
    const onLoad =
      typeClassLibrary[field.onLoad] && typeClassLibrary[field.onLoad];

    if (onLoad && !field.loaded) {
      setTimeout(() => typeClassLibrary[field.onLoad](field));
    }

    if ((onPress || onLoad) && !props.hidden) {
      return (
        <View>
          <ThemedButton
            loading={field.loading}
            disabled={(!props.enabled && !field.enabled) || field.loading}
            width={45}
            height={42}
            style={props.styles.button}
            buttonStyle={
              field.backgroundColor && {
                backgroundColor: field.backgroundColor
              }
            }
            title={field.placeholder}
            onPress={onPress && (() => onPress(field))}
          />
        </View>
      );
    }
  }
  if (field.type === "dateTime" || field.type === "datetime") {
    return (
      <>
        <Text
          style={{
            ...props.styles.pickerLabel,
            ...props.pickerLabelStyle
          }}
        >
          {field.placeholder}
        </Text>
        <DatePicker
          style={{
            ...props.styles.dateTime,
            ...(!props.enabled && { opacity: 0.65 })
          }}
          useMui
          title={field.placeholder}
          mode={field.mode}
          defaultValue={field.defaultValue || field.value}
          min={field.min}
          max={field.max}
          placeholder={field.placeholder}
          disabled={!props.enabled}
          onChange={props.onDateTimeChange || field.onChange}
          onOpen={props.onDateTimeOpen}
          onClose={props.onDateTimeClose}
        />
      </>
    );
  }
  if (field.type === "avatar") {
    const disabled = !props.enabled && !field.enabled;
    const avatarUri =
      (field.value &&
        fileService.getProfileAvatarUri(field.value, null, null)) ||
      null;
    const title = props.avatarTitle || field.avatarTitle;
    const colorId = props.avatarColorId || field.avatarColorId;
    const showOriginal = () => {
      setTimeout(() => {
        field.loading = true;
        fileService
          .preloadProfileAvatar(field.value, { loadOrig: true })
          .then(() =>
            showLightBox(fileService.getProfileAvatarOriginalUri(field.value))
          )
          .finally(() => (field.loading = false));
      }, 0);
    };

    return (
      <>
        <Text
          style={{
            ...props.styles.pickerLabel,
            ...props.pickerLabelStyle
          }}
        >
          {field.placeholder}
        </Text>
        <View style={props.styles.avatarContainer}>
          <FilePickerWrapper
            disabled={disabled}
            extensions={["png", "jpg"]}
            onChange={props.onAvatarPickerChange}
            onPress={disabled && avatarUri && showOriginal}
            // onError={props.onAvatarPickerError || console.warn}
          >
            <Avatar
              style={props.styles.avatar}
              size={field.avatarSize || responsive.styles.formAvatarSize}
              rounded
              title={title}
              backgroundColor={getRGBFromStr(colorId)}
              source={{ uri: avatarUri }}
              showEditButton={field.loading || !disabled}
              editButton={
                field.loading && {
                  name: "dots-horizontal",
                  type: "material-community"
                }
              }
            />
          </FilePickerWrapper>
          {field.showPreviousAvatars && (
            <AvatarHistoryPicker
              hidden={disabled}
              currentAvatarId={field.value}
              style={props.styles.avatarHistory}
              size={(field.avatarSize || responsive.styles.formAvatarSize) / 2}
              onAvatarPress={avatarId => (field.value = avatarId)}
            />
          )}
        </View>
        {!disabled && avatarUri && (
          <Button
            type="clear"
            title={UIText.profileRemoveAvatar}
            titleStyle={props.styles.avatarButtonTitle}
            onPress={props.onAvatarRemoval}
          />
        )}
      </>
    );
  }
  if (field.type === "file") {
    const disabled = !props.enabled && !field.enabled;
    return (
      <>
        <Text
          style={{
            ...props.styles.pickerLabel,
            ...props.pickerLabelStyle
          }}
        >
          {field.placeholder}
        </Text>
        <FilePickerWrapper
          disabled={disabled}
          extensions={["*"]}
          onChange={props.onFilePickerChange}
          onPress={disabled && props.onFilePickerPress}
          // onError={props.onAvatarPickerError || console.warn}
        >
          {field.value ? <View /> : <View />}
        </FilePickerWrapper>
      </>
    );
  }
  return <View />;
});

export const FormFieldView = observer(props => {
  const field = props.field;

  const additionalCheckedIcon = props.additionalCheckedIcon || {};
  const additionalUncheckedIcon = props.additionalUncheckedIcon || {};

  if (props.hidden && field.type !== "button") return null;

  return (
    <View style={props.styles.style}>
      <Observer>
        {() => (
          <View
            style={{
              ...props.styles.field,
              ...props.fieldStyle,
              ...(field.type === "button" &&
                props.hidden && { paddingVertical: 0 })
              // ...props.showAdditionalCheckbox && { maxWidth: "75%" }
            }}
          >
            {props.topDivider && <Divider style={props.styles.divider} />}
            {field.type !== "text" && field.title && props.showTitle ? (
              <Text
                style={{
                  ...props.styles.fieldLabel,
                  ...props.fieldLabelStyle
                }}
              >
                {field.title}
              </Text>
            ) : null}
            <Observer>{() => <Field {...props} />}</Observer>
            {props.bottomDivider && <Divider style={props.styles.divider} />}
          </View>
        )}
      </Observer>
      <Observer>
        {() => (
          <>
            {props.showAdditionalCheckbox && (
              <View style={props.styles.additionalCheckboxContainer}>
                <CheckBox
                  containerStyle={props.styles.additionalCheckbox}
                  center
                  title={props.additionalCheckboxTitle || ""}
                  titleProps={{
                    style: {
                      ...props.styles.additionalCheckboxTitle,
                      color: props.additionalCheckboxChecked
                        ? additionalCheckedIcon.color
                        : "#333",
                      opacity: props.additionalCheckboxDisabled ? 0.5 : 1
                    }
                  }}
                  iconRight
                  checkedIcon={
                    <Icon
                      iconStyle={{
                        opacity: props.additionalCheckboxDisabled ? 0.5 : 1
                      }}
                      name={additionalCheckedIcon.name || "check-box"}
                      type={additionalCheckedIcon.type || "material-community"}
                      size={additionalCheckedIcon.size || 24}
                      color={additionalCheckedIcon.color || "#333"}
                    />
                  }
                  uncheckedIcon={
                    <Icon
                      iconStyle={{
                        opacity: props.additionalCheckboxDisabled ? 0.5 : 1
                      }}
                      name={
                        additionalUncheckedIcon.name ||
                        "check-box-outline-blank"
                      }
                      type={
                        additionalUncheckedIcon.type || "material-community"
                      }
                      size={additionalUncheckedIcon.size || 24}
                      color={additionalUncheckedIcon.color || "#333"}
                    />
                  }
                  checked={props.additionalCheckboxChecked}
                  onPress={props.onAdditionalCheckboxPress}
                  disabled={props.additionalCheckboxDisabled}
                />
              </View>
            )}
          </>
        )}
      </Observer>
    </View>
  );
});
