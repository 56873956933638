import { stateController } from '../../../../cdm/controllers/state-controller';
import { UIText } from '../../../../config/lang-config';
import { observable } from 'mobx';
import { apiService } from '../../../../cdm/services/api-service';
import { mcbEndpointConfig } from '../../config/api-config';
import { calendlyFrameEvents, groupTypeIds } from '../../../../config/variable-config';
import { clientController } from '../../../../cdm/controllers/client-controller';

export class AdminInterviewBookingController {
  @observable caregiverName;
  @observable onboardingTopicId;
  @observable calendlyUrl;
  @observable calendlyReady = false;

  constructor(props) {
    this.props = props;
    stateController.currentScreen = "AdminInterviewBooking";
    this._initialize().catch(this._showError);
  }

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.adminCentre,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _initialize = async force => {
    this.onboardingTopicId = Number(this.props.navigation.getParam("topicId"));
    this.calendlyUrl = this.props.navigation.getParam("calendlyUrl");
    this.caregiverName = this.props.navigation.getParam("caregiverName");
    setTimeout(
      () => (document.title = UIText.adminBookingFor(this.caregiverName))
    );
    if (!this.onboardingTopicId || !this.calendlyUrl)
      return this.props.navigation.navigate("AdminCentre", {
        type: groupTypeIds.caregiver
      });
    return this.initCalendly();
  };

  initCalendly = () => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
    const isCalendlyEvent = e =>
      e.data.event && e.data.event.indexOf("calendly") === 0;
    window.addEventListener(
      "message",
      e => isCalendlyEvent(e) && this.onCalendlyEvent(e.data)
    );
    this.calendlyReady = true;
  };

  onCalendlyEvent = data => {
    console.log(data);
    const { event } = data;
    if (!event) return;
    if (!calendlyFrameEvents.includes(event)) return;
    if (event === "calendly.event_scheduled") return this.updateBookingStatus();
  };

  updateBookingStatus = async () => {
    const screeningStatus = "interview booked";
    if (!this.onboardingTopicId) return;
    return apiService
      .async("PATCH", {
        endpoint: mcbEndpointConfig.modify_screening_status,
        noKickOut: true,
        noRenew: true,
        data: {
          id: this.onboardingTopicId,
          data: JSON.stringify({ screeningStatus })
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          return apiService
            .renewOAuthExec(clientController.client.oauth.refresh_token)
            .then(this.updateBookingStatus);
        }
        return Promise.reject(err);
      })
      .then(() =>
        stateController.showPopup({
          title: document.title,
          content: UIText.adminBookingSuccess,
          leftButtonText: UIText.generalConfirm,
          leftButtonPress: () => window.close()
        })
      );
  };
}
