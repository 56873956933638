export const getBillingForm = () =>
  [
    {
      name: "name",
      placeholder: "Card holder name",
      required: true
    },
    {
      name: "addr1",
      placeholder: "Address line 1",
      required: true
    },
    {
      name: "addr2",
      placeholder: "Address line 2"
    },
    {
      name: "city",
      placeholder: "City"
    },
    {
      name: "prov",
      placeholder: "Province/State"
    },
    {
      name: "country",
      placeholder: "Country"
    },
    {
      name: "phone",
      placeholder: "Phone number",
      required: true
    }
  ].map(field => (field.type || (field.type = "input")) && field);
