import React from "react";
import { TopicSearchBarView } from "./view";
import { TopicSearchBarController } from "./controller";
import { styles } from "./styles";

export class TopicSearchBar extends React.Component {
  controller = new TopicSearchBarController(this.props);

  render() {
    return (
      <TopicSearchBarView
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
