import React from 'react';
import { DrawerContainerView } from './view';
import { DrawerContainerController } from './controller';
import { styles } from './styles';

export class DrawerContainer extends React.Component {
  controller = new DrawerContainerController(this.props);

  render() {
    return (
      <DrawerContainerView
        {...this.props}
        styles={styles}
        controller={this.controller}
      />
    );
  }
}
