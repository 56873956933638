import Axios from 'axios';
import { observable } from 'mobx';
import { serverConfig, tokenIdleTimeout } from '../../config/api-config';
import { asyncPause, capitalize, isEmpty } from '../../utils/helpers';
import { env } from '../../config/variable-config';
import { storage } from '../../utils/storage';

const config = {
  baseURL: `${serverConfig.https ? "https://" : "http://"}${
    serverConfig.hostname
  }${
    serverConfig.port !== 443 || serverConfig.port !== 80
      ? `:${serverConfig.port}`
      : ""
  }`,
  headers: serverConfig.defaultHeaders
};

class ApiService {
  @observable pending = 0;
  // deepRenew = false;
  deepRenew = true;
  attemptedRenewal = false;

  constructor() {
    this.axios = Axios.create(config);
    this.pendingIncrement();
    this.pendingDecrement();
    if (window && env !== "prod") window.apiCaller = this;
  }

  pendingIncrement = () => {
    this.axios.interceptors.request.use(request => {
      if (request.noSpinner) return request;
      this.pending++;
      // console.log("Ajax pending", this.pending);
      return request;
    });
  };

  pendingDecrement = () => {
    this.axios.interceptors.response.use(
      response => {
        this.pending--;
        // console.log("Ajax pending", this.pending);

        // if (
        //   response.config.headers.Authorization !==
        //     serverConfig.defaultHeaders.Authorization &&
        //   !response.config.noRenew &&
        //   !this.isSyncMode
        // )
        //   this.renewOAuthQueue();

        this.updateActivity(response).catch(console.warn);

        return response;
      },
      err => {
        this.pending--;
        // console.log("Ajax pending", this.pending);

        return this.handle401(err).then(is401 =>
          is401 ? Promise.resolve(is401) : Promise.reject(err)
        );
      }
    );
  };

  hardResetPending = () => (this.pending = 0);

  baseSwitch = baseUrl => (this.axios.defaults.baseURL = baseUrl);

  handle401 = async error => {
    const kickout = () => {
      this.execLogout && this.execLogout();
      this.attemptedRenewal = false;
      return true;
    };
    const noKickOut =
      error.response &&
      error.response.config &&
      error.response.config.noKickOut;

    const code = error.response && error.response.status;

    if (code === 401 && !noKickOut && !this.deepRenew) {
      return kickout();
    }

    if (code === 401 && this.deepRenew) {
      const idleLength = tokenIdleTimeout;
      const lastActive = await this.getLastActive();
      if (
        isEmpty(this.OAuth2Data) ||
        !lastActive ||
        new Date().getTime() - lastActive > idleLength ||
        this.attemptedRenewal
      ) {
        return kickout();
      }
      const config = { ...error.config };
      config.endpoint = config.url;
      if (config.headers) delete config.headers;
      if (config.url) delete config.url;
      console.log("Not yet idle, deep renewing");
      return (
        this.renewOAuthExec(this.OAuth2Data["refresh_token"])
          .catch(err => {
            console.error(err);
            return kickout();
          })
          // .then(() => window.location.reload())
          .then(() => this.async(config.method, { ...config }))
      );
    }

    return false;
  };

  updateOAuthData = OAuth2Data => {
    if (!OAuth2Data) return;
    this.OAuth2Data = OAuth2Data;
    this.axios.defaults.headers["Authorization"] = `${capitalize(
      OAuth2Data["token_type"]
    )} ${OAuth2Data["access_token"]}`;
  };

  resetOAuthData = () => {
    this.OAuth2Data = null;
    this.axios.defaults.headers["Authorization"] =
      serverConfig.defaultHeaders.Authorization;
  };

  renewOAuthQueue = () => {
    const renew = waitPending => {
      if (this.renewOAuthInQueue && !waitPending) return;
      this.renewOAuthInQueue = true;
      if (this.pending > 0) {
        setTimeout(() => renew(true), 100);
      } else {
        this.renewOAuthExec().finally(() => (this.renewOAuthInQueue = false));
      }
    };
    clearTimeout(this.renewTimeout);
    this.renewTimeout = setTimeout(() => renew());
  };

  registerRenewHandler = renewOAuthExec =>
    (this.renewOAuthExec = async () => {
      if (this.renewOAuthInProgress) return;
      if (!this.OAuth2Data) return;
      this.renewOAuthInProgress = true;
      return renewOAuthExec()
        .then(() => (this.attemptedRenewal = true))
        .finally(() => (this.renewOAuthInProgress = false));
    });

  registerLogoutHandler = execLogout => (this.execLogout = execLogout);

  registerEmbeddedStatus = (isEmbedded, isSyncMode) => {
    this.gotEmbeddedModes = true;
    this.isEmbedded = isEmbedded;
    this.isSyncMode = isSyncMode;
  };

  getLastActive = () =>
    storage
      .load({
        key: "lastActive"
      })
      .then(timestamp => Number(timestamp))
      .catch(this.updateActivity);

  updateActivity = async response => {
    if (isEmpty(response)) return;
    if (
      ((response.config || {}).headers || {}).Authorization !==
        serverConfig.defaultHeaders.Authorization &&
      !(response.config || {}).noRenew &&
      !this.isSyncMode
    ) {
      this.attemptedRenewal = false;
      return storage.save({
        key: "lastActive",
        data: new Date().getTime(),
        expires: null
      });
    }
  };

  async = (method, options) => {
    const waitRenew = async () => {
      if (this.renewOAuthInProgress && !options.renewingOAuth2Data)
        return await asyncPause(100).then(waitRenew);

      return this.axios({
        method: method.toLowerCase(),
        url: options.endpoint,
        data: options.data,
        responseType: options.responseType,
        noRenew: options.noRenew,
        noKickOut: options.noKickOut,
        noSpinner: options.noSpinner,
        timeout: options.timeout,
        headers: {
          ...options.headers
        }
      });
    };
    return waitRenew();
  };

  GET = options => {
    this.axios({
      method: "get",
      url: options.endpoint,
      headers: {
        ...options.headers
      },
      responseType: options.responseType
    })
      .then(options.success)
      .catch(error => {
        console.error(error);
        options.error && options.error(error);
      })
      .finally(options.complete);
  };

  POST = options => {
    this.axios({
      method: "post",
      url: options.endpoint,
      data: options.data,
      headers: {
        ...options.headers
      },
      responseType: options.responseType
    })
      .then(options.success)
      .catch(error => {
        console.error(error);
        options.error && options.error(error);
      })
      .finally(options.complete);
  };

  PATCH(options, cb) {}

  PUT(options, cb) {}

  DELETE(options, cb) {}
}

export const apiService = new ApiService();
