import { Platform } from "react-native";
import { action, autorun, computed, observable, reaction, toJS } from "mobx";
import { apiController } from "../../../../cdm/controllers/api-controller";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { clientController } from "../../../../cdm/controllers/client-controller";
import NavigationService from "../../../../utils/navigation-service";
import {
  capitalize,
  getDisplayNameEng,
  isEmpty,
  isNonZeroFalse,
  randomString
} from "../../../../utils/helpers";
import {
  groupTypeRoleIds,
  memberStatus,
  serviceRecipientGroupTypeIds,
  topicTypeIds,
  typeClassIds
} from "../../../../config/variable-config";
import { apiService } from "../../../../cdm/services/api-service";
import { endpointConfig } from "../../../../config/api-config";
import { UIText } from "../../../../config/lang-config";
import {
  careCircleNegotiatedPriceBlurHandler,
  careCirclePayrollHide,
  isHourlyRateProfile,
  isOwnerOrPrimaryCaregiverOrSupport,
  isServiceProviderGroup,
  matchCareReceiver
} from "../../lib/group-utilities-mcb";
import { fileService } from "../../../../cdm/services/file-service";
import { formService } from "../../../../cdm/services/form-service";
import { getGroupTimezoneName } from "../../../../cdm/lib/group-utilities";

export class GroupMemberPageController {
  @observable refreshing = false;
  @observable loading = true;
  @observable chatLoadingList = {};
  @observable chatLoading = true;

  @computed get group() {
    return clientController.findGroupById(stateController.viewGroupId) || {};
  }
  @computed get groupTypeName() {
    return isServiceProviderGroup(this.group)
      ? UIText.groupServiceProviderName(this.group.groupTypeName)
      : this.group.groupTypeName;
  }
  @computed get profileData() {
    return (this.group.profile && this.group.profile.data) || {};
  }
  @computed get groupAvatar() {
    return fileService.getProfileAvatarUri(
      this.profileData.avatar,
      this.group.id,
      "group"
    );
  }
  @computed get roles() {
    const roles = [
      {
        id: 0,
        description: UIText.unknownRole,
        members: []
      },
      {
        id: -1,
        description: UIText.groupMembersHiredCaregiverTag,
        members: []
      }
    ];
    const members = this.group.members;
    if (!Array.isArray(members)) return roles;
    for (let member of members) {
      const roleList = member.roleList;
      if (!Array.isArray(roleList)) {
        roles.find(r => r.id === 0).members.push(member);
        continue;
      }
      const customRoleDescription = ((member.profile || {}).data || {})
        .customRoleDescription;
      if (customRoleDescription) {
        const role = roles.find(r => r.description === customRoleDescription);
        if (!role) {
          let lowestId = -1;
          for (const r of roles) {
            if (r.id < lowestId) lowestId = r.id;
          }
          roles.push({
            id: lowestId - 1,
            description: customRoleDescription,
            members: [member]
          });
          continue;
        } else {
          role.members.push(member);
          continue;
        }
      }
      const pushOrMakeRole = mr => {
        let role;
        if (mr.groupTypeRoleId === groupTypeRoleIds.paidCaregiver) {
          const caregiverRole = this.group.roles.find(r => r.id === mr.id);
          if (
            (member.profile || {}).typeClassId ===
            typeClassIds.paidCaregiverProfile
          ) {
            role = roles.find(r => r.id === -1);
            role.description = `${caregiverRole.description} ${
              UIText.groupMembersHiredCaregiverTag
            }`;
            role.sortIndex = caregiverRole.sortIndex + 0.5;
            role.members.push(member);
            return;
          }
        }
        role = roles.find(r => r.description === mr.description);
        if (!role) {
          const Role = this.group.roles.find(r => r.id === mr.id);
          Role &&
            roles.push({
              ...toJS(Role),
              members: [member]
            });
        } else {
          role.members.push(member);
        }
      };
      for (let memberRole of roleList) {
        pushOrMakeRole(memberRole);
      }
    }
    return roles.sort((a, b) => a.sortIndex - b.sortIndex);
  }
  @computed get selfMember() {
    return (
      this.group.members.find(m => m.userId === clientController.userId) || {}
    );
  }
  @computed get myRole() {
    return this.props.getMyRole && this.props.getMyRole();
  }
  @computed get IAmOwner() {
    return this.group.owner === clientController.userId;
  }
  @computed get collapsible() {
    if (!stateController.viewGroupState.collapsible["member"][this.group.id])
      stateController.viewGroupState.collapsible["member"][this.group.id] = {};
    return stateController.viewGroupState.collapsible["member"][this.group.id];
  }
  @computed get timezoneName() {
    return (
      serviceRecipientGroupTypeIds.includes(this.group.typeId) &&
      getGroupTimezoneName(this.group)
    );
  }

  constructor(props) {
    this.props = props;
    this.handleHeadingPress = props.handleHeadingPress;
    this.handleBillingPress = props.handleBillingPress;
    this.handleAppointmentPress = props.handleAppointmentPress;
    this.getGroupMembers = props.getGroupMembers;
    setTimeout(this._initialize);
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  _showError = err => {
    console.error(err);
    stateController.showPopup({
      title: capitalize(UIText.group),
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _initialize = force =>
    this._loadGroupRolesAndMembers(force)
      .then(() => (this.loading = false))
      .then(() => this._loadChatTopics(force))
      .then(() => (this.chatLoading = false))
      .catch(this._showError);
  // .finally(() => console.log(this.roles));

  _loadGroupRolesAndMembers = async force =>
    Promise.all([this._loadGroupRoles(force), this._loadMembers(force)]);

  _loadGroupRoles = async force => {
    if (
      !Array.isArray(this.group.roles) ||
      this.group.roles.length === 0 ||
      force
    ) {
      return apiController
        .getGroupById(this.group.id)
        .then(group => clientController.updateGroup(group));
    }
  };

  _loadMembers = async force => {
    return this.getGroupMembers(force);
  };

  _loadChatTopics = async force => {
    const needRefresh = this.group.members.some(member => {
      if (
        Number(member.status) === memberStatus.invited ||
        !member.userId ||
        member.userId === clientController.userId
      )
        return false;
      return isEmpty(this.getChatTopic(member.id) || force);
    });
    if (needRefresh) return this._getChatTopics(force);
    return Promise.resolve();
  };

  _getChatTopics = async force => {
    this.chatLoading = true;
    if (force) {
      clientController
        .findTopics(
          t =>
            (t.groupIdList || []).includes(this.group.id) &&
            t.typeId === topicTypeIds.messaging
        )
        .map(clientController.removeTopic);
    }
    return apiController
      .getGroupTopicsByTypeId(this.group.id, topicTypeIds.messaging)
      .then(
        action(topics => {
          for (let topic of topics) {
            topic.members.find(m => m.id === this.selfMember.id) &&
              clientController.updateTopic(topic);
          }
          return Promise.resolve();
        })
      )
      .finally(() => (this.chatLoading = false));
  };

  getChatTopic = memberId => {
    return (
      clientController
        .findTopics(
          t =>
            t.groupIdList &&
            (t.groupIdList || []).includes(this.group.id) &&
            t.typeId === topicTypeIds.messaging &&
            t.members.length === 2 &&
            t.members.find(m => m.id === memberId) &&
            t.members.find(m => m.id === this.selfMember.id)
        )
        .sort((a, b) => a.id - b.id)[0] || {}
    );
  };

  handleMemberPress = member => {
    // Do not open pending member profile.
    if (Number(member.status) === memberStatus.invited) return;

    if (!member.id) return;

    const id = member.profileId;
    const screenId = randomString();

    const selfIsMaster = isOwnerOrPrimaryCaregiverOrSupport(
      this.group,
      this.selfMember
    );
    const roles = Array.isArray(member.roleList) && member.roleList;
    const canEdit =
      member.userId === clientController.userId ||
      (selfIsMaster &&
        roles.some(r => matchCareReceiver(r["description"] || "")));

    const isMasterViewingCaregiver =
      selfIsMaster &&
      roles.some(r => r.groupTypeRoleId === groupTypeRoleIds.paidCaregiver);

    stateController.viewProfileState.bottomButton[screenId] =
      isMasterViewingCaregiver &&
      observable({
        text: UIText.groupChangeHourlyRate,
        handlePress: () => this.changeHourlyRate(member.id, true)
      });

    stateController.viewProfileState.readOnly[screenId] = !canEdit;

    stateController.viewProfileState.additionalProfileProcessors[screenId] = [
      profile =>
        careCirclePayrollHide(
          this.group,
          this.selfMember,
          member,
          member.profileId,
          profile
        )
    ];

    if (isHourlyRateProfile(member.profile) && canEdit) {
      stateController.viewProfileState.fieldBlurHandlers[screenId] = [
        careCircleNegotiatedPriceBlurHandler
      ];
    }

    NavigationService.navigate("Profile", {
      group: this.group.id,
      profile: id,
      screenId
    });
  };

  handleCollapse = roleId => {
    stateController.viewGroupState.collapsible["member"][this.group.id][
      roleId
    ] = !this.collapsible[roleId];
  };

  invite = () => {
    stateController.initSetupMode = "invite";
    NavigationService.navigate("Setup");
  };

  openChat = memberId => {
    this.chatLoadingList[memberId] = true;
    const topic = this.getChatTopic(memberId);

    if (isEmpty(topic)) {
      return this.createMemberChatTopic(memberId)
        .then(this._loadChatTopics)
        .then(() => this.openChat(memberId));
    }

    this.chatLoadingList[memberId] = false;
    const topicId = topic.id;
    stateController.viewTopicId = topicId;
    NavigationService.navigate("Chat", { topic: topicId });
  };

  createMemberChatTopic = async memberId => {
    const member = this.group.members.find(m => m.id === memberId);
    const topic = {
      creatorMemberId: this.selfMember.id,
      typeId: topicTypeIds.messaging,
      description: member.profile.data && member.profile.data.displayName,
      onCalendar: 0,
      isTemplate: 0,
      isParentTemplate: 0,
      isCompleted: 0,
      isDataLocked: 0,
      isLocked: 0,
      typeClassId: typeClassIds.messagingTopic,
      typeClassVersion: 1, // Default for now.
      data: "{}"
    };

    return apiService.async("POST", {
      endpoint: endpointConfig.create_topic,
      data: {
        currentGroupId: this.group.id,
        otherMemberIdList: [memberId],
        topic
      }
    });
  };

  onMemberMenuSelect = (menuOption, memberId) => {
    if (typeof this[menuOption] === "function")
      return this[menuOption](memberId);
  };

  changeRole = async memberId => {
    const roles = toJS(this.group.roles)
      .sort((a, b) => {
        return a.sortIndex - b.sortIndex;
      })
      .filter(r => !matchCareReceiver(r.description) && r); // No Care Recipient for now
    const roleOptions = roles.map(r => ({
      name: r.id.toString(),
      placeholder: r.description
    }));
    const paidCaregiverRole = roles.find(
      r => r.groupTypeRoleId === groupTypeRoleIds.paidCaregiver
    );
    const paidCaregiverRoleOption = roleOptions.find(
      r => r.name === paidCaregiverRole.id.toString()
    );
    if (paidCaregiverRoleOption)
      paidCaregiverRoleOption.placeholder = UIText.groupPaidProvider;

    let form = [],
      data = {},
      isPaidCaregiver,
      roleChanged,
      rateChanged,
      roleDescriptionChanged,
      missingRate = true;
    const member = this.group.members.find(m => m.id === memberId);
    if (!member) return;
    const profile = member.profile && member.profile.data;
    const memberRole =
      (member.roleList && member.roleList[0]) ||
      this.group.roles.find(r => r.memberIdList.includes(member.id));

    if (matchCareReceiver(memberRole.description)) {
      return stateController.showPopup({
        title: UIText.groupChangeRole,
        content: UIText.groupRoleNoChangeCareReceiver,
        leftButtonText: UIText.generalConfirm,
        dismissOnBackPress: true
      });
    }
    const familyMemberTypeClass = await formService.findFormClassByIdAsync(
      typeClassIds.familyMemberProfile
    );
    form = observable(
      formService.assembleFormData(
        {
          metadata: [
            {
              name: "roleOption",
              type: "picker",
              options: roleOptions,
              placeholder: `${getDisplayNameEng(profile) || memberId}:`,
              value: memberRole.id.toString()
            },
            {
              name: "roleDescription",
              type: "picker",
              placeholder: "as",
              dependOn: "roleOption",
              dependOnValue: paidCaregiverRole.id.toString(),
              options: [
                { name: "Paid Caregiver", placeholder: "Paid Caregiver" },
                { name: "Cleaner", placeholder: "Cleaner" }
              ].filter(Boolean),
              value: profile["customRoleDescription"]
            },
            ...formService.assembleFormData(familyMemberTypeClass, {})
          ]
        },
        profile,
        {
          override: true,
          filter: f =>
            f.name === "roleOption" ||
            f.name === "roleDescription" ||
            (!!f.flags && !!f.flags["hourlyRate"])
        }
      )
    );
    (
      form.find(field => field.name === "myRequiredHourlyRate") || {}
    ).disabled = true;
    (
      form.find(field => field.name === "discountedHourlyRate") || {}
    ).hidden = true;

    const disposeAutoFieldChanges = autorun(() => {
      data = formService.disassembleFormData(form);
      isPaidCaregiver = Number(data["roleOption"]) === paidCaregiverRole.id;
      for (const field of form) {
        if (
          field.name === "roleOption" ||
          field.name === "roleDescription" ||
          field.name === "chargeDiscountFactor" ||
          field.name === "discountedHourlyRate"
        )
          continue;
        field.hidden = !isPaidCaregiver;
      }
      rateChanged =
        isPaidCaregiver &&
        Number(profile["negotiatedHourlyRate"]) !==
          Number(data["negotiatedHourlyRate"]);
      roleChanged = Number(data["roleOption"]) !== memberRole.id;
      roleDescriptionChanged =
        data["roleDescription"] !== profile["customRoleDescription"];
      missingRate =
        isPaidCaregiver && isNonZeroFalse(data["negotiatedHourlyRate"]);
      stateController.popup.rightButtonDisabled =
        (!rateChanged && !roleChanged && !roleDescriptionChanged) ||
        missingRate;
    });

    const execRoleChange = async () => {
      if (!roleChanged) return;
      const roleId = Number(data["roleOption"]);
      return apiService.async("PUT", {
        endpoint: endpointConfig.update_member_role,
        data: {
          memberId: memberId,
          roleId: roleId
        }
      });
    };

    const execRateChange = async () => {
      if (!rateChanged) return;
      if (!isPaidCaregiver) return;
      const update = { ...data };
      delete update["roleOption"];
      delete update["roleDescription"];
      return apiService.async("PATCH", {
        endpoint: endpointConfig.profile_by_id(member.profileId),
        data: { data: JSON.stringify(Object.assign(profile, update)) }
      });
    };

    const execRoleDescriptionChange = async () => {
      if (!roleDescriptionChanged) return;
      const customRoleDescription = data["roleDescription"];
      return apiService.async("PATCH", {
        endpoint: endpointConfig.profile_by_id(member.profileId),
        data: {
          data: JSON.stringify(
            Object.assign(profile, { customRoleDescription })
          )
        }
      });
    };

    const execChange = async () =>
      stateController
        .dismissPopup()
        .then(() =>
          stateController.showPopup({
            title: UIText.groupChangeRole,
            content: UIText.pleaseWait
          })
        )
        .then(() =>
          Promise.all(
            [
              execRoleChange(),
              execRateChange().then(execRoleDescriptionChange)
            ].filter(Boolean)
          )
        )
        .then(stateController.dismissPopup)
        .then(this.onRefresh)
        .catch(this._showError)
        .finally(() => {
          disposeAutoFieldChanges();
          this.loading = false;
        });

    return stateController.showPopup({
      title: UIText.groupChangeRole,
      leftButtonText: UIText.generalCancel,
      leftButtonPress: stateController.dismissPopup,
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: execChange,
      rightButtonDisabled: true,
      form
    });
  };

  changeOwner = memberId => {
    const options = this.group.members
      .map(m => {
        return (
          !matchCareReceiver(
            Array.isArray(m.roleList) &&
              m.roleList[0] &&
              m.roleList[0].description
          ) &&
          Number(m.status) === memberStatus.normal && {
            name: m.id,
            placeholder: getDisplayNameEng(m.profile) || m.id
          }
        );
      })
      .filter(Boolean);

    const selected = observable({
      name: this.selfMember.id
    });

    const execChange = selected => {
      stateController.dismissPopup().then(() => {
        this.loading = true;
        const member = this.group.members.find(m => m.id === selected.name);
        if (!member) return;

        return apiService
          .async("PATCH", {
            endpoint: endpointConfig.group_by_id(this.group.id),
            data: {
              owner: member.userId
            }
          })
          .catch(this._showError)
          .finally(this.onRefresh);
      });
    };

    const showWarning = selected => {
      stateController.dismissPopup().then(() => {
        if (!selected.name || selected.name === this.selfMember.id) return;

        const groupName =
          getDisplayNameEng(this.group.profile) || this.group.id;
        const groupTypeName = this.group["groupTypeName"];

        return stateController.showPopup({
          title: UIText.groupChangeOwner,
          content: UIText.groupChangeOwnerConfirm(groupName, groupTypeName),
          leftButtonText: UIText.generalCancel,
          leftButtonPress: stateController.dismissPopup,
          rightButtonText: UIText.generalConfirm,
          rightButtonPress: () => execChange(selected)
        });
      });
    };

    return stateController.showPopup({
      title: UIText.groupChangeOwner,
      leftButtonText: UIText.generalCancel,
      leftButtonPress: stateController.dismissPopup,
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: () => showWarning(selected),
      rightButtonDisabled: true,
      picker: {
        options,
        title: UIText.groupChangeOwnerNewOwner,
        selected,
        onChange: id => {
          id = Number(id);
          selected.name = id;
          stateController.popup.rightButtonDisabled = false;
        }
      }
    });
  };

  cancelInvite = memberId => {
    const member = this.group.members.find(m => m.id === memberId);

    if (!member) return;

    const displayName = getDisplayNameEng(member.profile);

    const execCancel = () => {
      this.loading = true;

      return (
        apiService
          .async("DELETE", {
            endpoint: endpointConfig.cancel_invitation(memberId)
          })
          // .then(console.log)
          .then(this.onRefresh)
          .catch(this._showError)
          .finally(() => (this.loading = false))
      );
    };

    return stateController.showPopup({
      title: UIText.groupCancelInvite,
      content: UIText.groupCancelInviteConfirm(displayName),
      leftButtonText: UIText.generalCancel,
      leftButtonPress: stateController.dismissPopup,
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: () => stateController.dismissPopup().then(execCancel)
    });
  };

  resendInvite = memberId => {
    const member = this.group.members.find(m => m.id === memberId);

    if (!member) return;

    const displayName = getDisplayNameEng(member.profile);

    const execResend = () =>
      stateController
        .showPopup({
          title: UIText.groupResendInvite,
          content: UIText.pleaseWait
        })
        // .then(() => new Promise((resolve, reject) => setTimeout(resolve, 2000)))
        .then(() =>
          apiService.async("GET", {
            endpoint: endpointConfig.resend_invitation(this.group.id, memberId)
          })
        )
        .then(stateController.dismissPopup)
        .then(() =>
          stateController.showPopup({
            title: UIText.groupResendInvite,
            content: UIText.groupResendInviteSuccess(displayName),
            leftButtonText: UIText.generalConfirm,
            dismissOnBackPress: true
          })
        )
        .catch(this._showError);

    return stateController.showPopup({
      title: UIText.groupResendInvite,
      content: UIText.groupResendInviteConfirm(displayName),
      leftButtonText: UIText.generalCancel,
      leftButtonPress: stateController.dismissPopup,
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: () => stateController.dismissPopup().then(execResend)
    });
  };

  // mcb
  changeHourlyRate = async (memberId, inProfile) => {
    const member = this.group.members.find(m => m.id === memberId);
    const isPaidCaregiver =
      member.roleList &&
      member.roleList.some(
        r => r.groupTypeRoleId === groupTypeRoleIds.paidCaregiver
      );

    if (!member || !isPaidCaregiver) return;

    const profileData = toJS(member.profile && member.profile.data);
    let form = [];

    const execChange = async () => {
      this.loading = true;

      const update = formService.disassembleFormData(form);
      const data = JSON.stringify(Object.assign(profileData, update));
      return stateController
        .dismissPopup()
        .then(() =>
          apiService.async("PATCH", {
            endpoint: endpointConfig.profile_by_id(member.profileId),
            data: { data }
          })
        )
        .then(this.onRefresh)
        .then(() => inProfile && stateController.viewProfileState.refresh())
        .catch(this._showError)
        .finally(() => (this.loading = false));
    };

    return await formService
      .findFormClassByIdAsync(typeClassIds.paidCaregiverProfile)
      .then(typeClass => {
        form = observable(
          formService.assembleFormData(typeClass, profileData, {
            override: true,
            filter: f => !!f.flags && !!f.flags["careCircleHourlyRate"]
          })
        );

        if (form.length === 0)
          return stateController.showPopup({
            title: UIText.groupChangeHourlyRate,
            content: UIText.groupChangeHourlyRateUnavailable,
            leftButtonText: UIText.generalConfirm,
            dismissOnBackPress: true
          });

        const unlockButton = reaction(
          () => [form[0].value, form[form.length - 1].value],
          (value, reaction) => {
            // console.log(value);
            stateController.popup.rightButtonDisabled = false;
            reaction.dispose && reaction.dispose();
          }
        );

        return stateController.showPopup({
          title: UIText.groupChangeHourlyRate,
          form,
          leftButtonText: UIText.generalCancel,
          rightButtonText: UIText.generalConfirm,
          rightButtonPress: execChange,
          rightButtonDisabled: true
        });
      });
  };

  deleteMember = memberId => {
    const member = this.group.members.find(m => m.id === memberId);

    if (!member) return;

    const displayName = getDisplayNameEng(member.profile);
    const groupName = `${this.group["groupTypeName"]} ${getDisplayNameEng(
      this.group.profile
    )}`;

    const execDelete = () => {
      this.loading = true;
      return apiController
        .archiveMember(memberId, notify.checked)
        .then(this.onRefresh)
        .catch(this._showError)
        .finally(() => (this.loading = false));
    };

    let notify = observable({
      placeholder: UIText.groupDeleteMemberNotify(displayName),
      checked: false
    });

    let countdown;
    return stateController
      .showPopup({
        title: UIText.groupDeleteMember,
        leftButtonText: UIText.generalNo,
        leftButtonPress: () =>
          stateController.dismissPopup().then(() => clearInterval(countdown)),
        rightButtonText: `${UIText.generalYes} (3)`,
        rightButtonPress: () => stateController.dismissPopup().then(execDelete),
        rightButtonDisabled: true,
        contentAlign: "left",
        multiSelect: {
          onCheckboxChange: notify => (notify.checked = !notify.checked),
          options: [notify],
          heading: UIText.groupDeleteMemberConfirm(displayName, groupName)
        }
      })
      .then(() => {
        let i = 3;
        countdown = setInterval(() => {
          i--;
          if (i === 0) {
            stateController.popup.rightButtonDisabled = false;
            stateController.popup.rightButtonText = UIText.generalYes;
            return clearInterval(countdown);
          }
          stateController.popup.rightButtonText = `${UIText.generalYes} (${i})`;
        }, 1000);
      });
  };

  onRefresh = async () => {
    Platform.OS === "web" && (this.loading = true);
    this.refreshing = true;
    return this._initialize(true).finally(() => (this.refreshing = false));
  };
}
