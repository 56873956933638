export const styles = {
  style: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#fff",
    boxShadow: "0px 0px 15px #999"
  }
};
