import React from "react";
import {
  ActivityIndicator,
  Platform,
  RefreshControl,
  ScrollView,
  View
} from "react-native";
import { Observer, observer } from "mobx-react";
import { theme } from "../../../../config/style-configs/theme";
import { UIText } from "../../../../config/lang-config";
import { Text } from "react-native-elements";
import { ListRefreshButtonWeb } from "../../../../components/ListRefreshButtonWeb";
import { TopicCard } from "../../../../components/TopicCard";
import { getDisplayNameEng } from "../../../../utils/helpers";
import { ThemedButton } from "../../../../components/ThemedButton";
import { fileService } from "../../../../cdm/services/file-service";

@observer
class MCBMessageListView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  renderSortButtons = () => (
    <>
      <ThemedButton
        icon={
          this.controller.sortBy === "dateAscend"
            ? "expand-less"
            : this.controller.sortBy === "dateDescend"
            ? "expand-more"
            : undefined
        }
        title={UIText.adminCentreSortByDate}
        onPress={this.controller.sortByDate}
      />
      <ThemedButton
        icon={
          this.controller.sortBy === "alphaAscend"
            ? "expand-less"
            : this.controller.sortBy === "alphaDescend"
            ? "expand-more"
            : undefined
        }
        title={UIText.adminCentreSortByName}
        onPress={this.controller.sortByAlpha}
      />
    </>
  );

  renderTopics = topics => {
    if (!Array.isArray(topics) || topics.length === 0)
      return (
        <Text style={this.props.styles.errorMessage}>
          {UIText.inboxNoLastActivity}
        </Text>
      );

    return (
      <View style={this.props.styles.scrollViewCard}>
        {topics.map(this.renderTopic)}
      </View>
    );
  };

  renderTopic = topic => {
    const group = topic.group;
    const profile = topic.gProfile;
    const displayName = getDisplayNameEng(profile);

    const children = (
      <View style={this.props.styles.cardContent}>
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={this.props.styles.cardContentMedium}
        >
          {this.controller.isMCBGroup ? group["groupTypeName"] : UIText.title}
        </Text>
        <Text
          numberOfLines={2}
          ellipsizeMode="tail"
          style={{
            ...this.props.styles.cardContentMedium,
            fontWeight: "normal"
          }}
        >
          {topic["lastPublicMessageTime"]
            ? UIText.inboxLastMessageAt(
                `\n${new Date(topic["lastPublicMessageTime"]).toLocaleString()}`
              )
            : UIText.inboxNoLastActivity}
        </Text>
      </View>
    );

    return (
      <Observer key={topic.id}>
        {() => (
          <View style={this.props.styles.card}>
            <TopicCard
              compact={true}
              title={
                this.controller.isMCBGroup
                  ? displayName
                  : topic["topicTypeName"] || displayName
              }
              avatarTitle={displayName}
              titleId={group.profileId}
              image={fileService.getProfileAvatarUri(
                profile.avatar,
                group.id,
                "group"
              )}
              pending={topic.loading}
              onPress={() => this.controller.openChat(topic.id)}
            >
              {children}
            </TopicCard>
          </View>
        )}
      </Observer>
    );
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        {(this.controller.loading || this.controller.refreshing) &&
        Platform.OS === "web" ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          <ScrollView
            contentContainerStyle={this.props.styles.scrollView}
            refreshControl={
              <RefreshControl
                refreshing={
                  this.controller.refreshing || this.controller.loading
                }
                onRefresh={this.controller.onRefresh}
              />
            }
          >
            <View style={this.props.styles.scrollViewContent}>
              <View style={this.props.styles.buttonContainer}>
                {this.controller.isMCBGroup && this.renderSortButtons()}
                {!this.controller.loading && (
                  <ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />
                )}
              </View>
              {this.renderTopics(this.controller.filteredTopics)}
            </View>
          </ScrollView>
        )}
      </View>
    );
  }
}

export { MCBMessageListView };
