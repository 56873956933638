import { isNonZeroFalse } from "../../../../utils/helpers";

export const getLocationName = location => {
  return (location || {}).name || (location.location || {}).address;
};

export const getAvailabilityTemplate = location => ({
  name: "",
  location: isNonZeroFalse(location) ? -1 : location,
  dayOfWeek: 0,
  start: null,
  end: null,
  maxCommuteMinutes: 30
});
