import React from "react";
import { ActivityIndicator, FlatList, View } from "react-native";
import { observer, Observer } from "mobx-react";
import { Header } from "../../../components/Header";
import { Divider, Icon, Text } from "react-native-elements";
import { ChatBottomBar } from "../../../components/ChatBottomBar";
import { MessageItem } from "../../../components/MessageItem";
import { theme } from "../../../config/style-configs/theme";
import {
  abbvLastNameDisplayNameEng,
  capitalize,
  getDisplayNameEng,
  getRGBFromStr,
  isEmpty
} from "../../../utils/helpers";
import { UIText } from "../../../config/lang-config";
import {
  groupTypeIds,
  memberStatus,
  RoleGroupGroupTypeIds,
  serviceRecipientGroupTypeIds,
  topicTypeIds
} from "../../../config/variable-config";
import { getCcTopicContactString } from "../../../custom/mcb/utils/helper";
import { Avatar } from "../../../components/Avatar";
import { observable, toJS, when } from "mobx";
import { fileService } from "../../../cdm/services/file-service";

const DisplayTitle = observer(props => {
  const { topic, groups, members, currentGroup, selfMember, styles } = props;
  const container = {
    width: 25,
    height: 25,
    justifyContent: "center"
  };
  const withCircle = {
    ...container,
    borderRadius: 15,
    borderWidth: 2,
    borderColor: theme.color,
    borderStyle: "solid"
  };

  if (topic.typeId === topicTypeIds.candidate) {
    const cGroup =
      groups.find(g => RoleGroupGroupTypeIds.includes(g.typeId)) || {};
    let c;
    !isEmpty(cGroup.profile) && (c = cGroup.profile);
    !!c && !isEmpty(c.data) && (c = c.data);
    !!c &&
      (c =
        currentGroup.id === cGroup.id
          ? getDisplayNameEng(c)
          : abbvLastNameDisplayNameEng(getDisplayNameEng(c)) || "");

    const cCGroup =
      groups.find(g => serviceRecipientGroupTypeIds.includes(g.typeId)) || {};
    let cC;
    !isEmpty(cCGroup.profile) && (cC = cCGroup.profile || {});
    !!cC && !isEmpty(cC.data) && (cC = cC.data);
    !!cC && (cC = getDisplayNameEng(cC) || "");

    const cCContactString = getCcTopicContactString(topic);

    return (
      <View style={styles.chatHeadingTitle}>
        <View style={styles.chatHeadingGroup}>
          <Icon
            name="account"
            type="material-community"
            containerStyle={container}
            color={theme.color}
            size={theme.FONT_SIZE_LARGE}
          />
          <Text style={styles.chatHeadingTextGroupType}>
            {`  ${cGroup.groupTypeName || ""}  `}
          </Text>
          <Text style={styles.chatHeadingText}>{c}</Text>
        </View>
        <View style={styles.chatHeadingGroup}>
          <Icon
            name="account-group"
            type="material-community"
            containerStyle={withCircle}
            color={theme.color}
            size={theme.FONT_SIZE_MEDIUM}
          />
          <Text style={styles.chatHeadingTextGroupType}>
            {currentGroup.id !== cGroup.id
              ? `  ${cCGroup.groupTypeName ||
                  currentGroup.groupTypeName ||
                  ""}  `
              : `  ${UIText.chatContactName(
                  cCGroup.groupTypeName || UIText.chatContactRemoved
                )}  `}
          </Text>
          <Text style={styles.chatHeadingText}>
            {currentGroup.id !== cGroup.id ? cC : cCContactString}
          </Text>
        </View>
      </View>
    );
  }
  if (topic.typeId === topicTypeIds.messaging) {
    const member = members.find(m => m.id !== selfMember.id);
    const profile = (!!member.profile.data && member.profile.data) || {};
    return (
      <View style={styles.chatHeadingTitle}>
        <View style={styles.chatHeadingGroup}>
          <Icon
            name="account"
            containerStyle={container}
            type="material-community"
            color={theme.color}
            size={theme.FONT_SIZE_LARGE}
          />
          <Text style={styles.chatHeadingText}>{`  ${getDisplayNameEng(
            profile
          )}`}</Text>
        </View>
      </View>
    );
  }
  if (
    topic.typeId === topicTypeIds.groupMessaging ||
    topic.typeId === topicTypeIds.groupChatLobby
  ) {
    const profile =
      (!!currentGroup.profile.data && currentGroup.profile.data) || {};
    const isRole = currentGroup.members.length === 1;
    const icon = isRole ? "account" : "account-group";

    return (
      <View style={styles.chatHeadingTitle}>
        <View style={styles.chatHeadingGroup}>
          <Icon
            name={icon}
            color={theme.color}
            containerStyle={withCircle}
            type="material-community"
            size={theme.FONT_SIZE_MEDIUM}
          />
          <Text style={styles.chatHeadingTextGroupType}>{`  ${
            currentGroup.groupTypeName
          }: `}</Text>
          <Text style={styles.chatHeadingText}>
            {getDisplayNameEng(profile)}
          </Text>
        </View>
      </View>
    );
  }
  if (topic.typeId === topicTypeIds.mCBBroadcasts) {
    const isMCBGroup = currentGroup.typeId === groupTypeIds.myCareBaseStaff;
    const group = groups.find(g => g.id !== currentGroup.id);
    const profile = group && group.profile;
    const displayName = getDisplayNameEng(profile);

    return (
      <View style={styles.chatHeadingTitle}>
        <View style={styles.chatHeadingGroup}>
          <Icon
            name="chat"
            color={theme.color}
            type="material-community"
            size={theme.FONT_SIZE_XLARGE}
          />
          <Text style={styles.chatHeadingTextGroupType}> </Text>
          <Text style={styles.chatHeadingText}>
            {isMCBGroup
              ? `${group["groupTypeName"]}: ${displayName}`
              : topic["topicTypeName"]}
          </Text>
        </View>
      </View>
    );
  }
  return (
    <View style={styles.chatHeadingTitle}>
      <View style={styles.chatHeadingGroup}>
        <Icon
          name="chat"
          color={theme.color}
          type="material-community"
          size={theme.FONT_SIZE_XLARGE}
        />
        <Text style={styles.chatHeadingTextGroupType}> </Text>
        <Text style={styles.chatHeadingText}>{topic.description}</Text>
      </View>
    </View>
  );
});

@observer
class ChatScreen extends React.Component {
  scroll;
  scrolled = false;
  @observable highlightFocused = true;

  constructor(props) {
    super(props);
    this.controller = props.controller;
    this.viewabilityConfig = {
      minimumViewTime: this.controller.readDelayThreshold,
      viewAreaCoveragePercentThreshold: 95
      // itemVisiblePercentThreshold: 95
    };
    this.buttonMethods = {
      handleEmojiPress: this.controller.handleEmojiPress,
      handleCameraPress: this.controller.handleCameraPress,
      handleMultilineChange: this.controller.handleMultilineChange,
      handleAttachmentPress: this.controller.handleAttachmentPress
    };
  }

  componentDidMount() {
    return this.controller.componentDidMount();
  }

  componentWillUnmount() {
    if (this.scroll) clearTimeout(this.scroll);
    return this.controller.componentWillUnmount();
  }

  renderError = () => (
    <Text style={this.props.styles.errorMessage}>
      {this.controller.errorMessage}
    </Text>
  );

  renderMembers = members => {
    return members.map((member, i) => {
      const profile = (member.profile && member.profile.data) || {};
      return (
        Number(member.status) !== memberStatus.invited && (
          <Avatar
            containerStyle={this.props.styles.avatar}
            key={i}
            //key={member.id}
            size={30}
            rounded
            title={profile.displayName && profile.displayName[0]}
            backgroundColor={getRGBFromStr(
              profile.displayName + member.profileId
            )}
            source={{
              uri: fileService.getProfileAvatarUri(
                profile.avatar,
                member.id,
                "member"
              )
            }}
            onPress={e => this.controller.handleAvatarPress(e, member)}
          />
        )
      );
    });
  };

  renderMessages = messages => (
    <Observer>
      {() => {
        return (
          <FlatList
            refreshing={this.controller.loading}
            onRefresh={this.controller.onRefresh}
            ref={this.listSetter}
            keyExtractor={this.keyExtractor}
            data={messages}
            onContentSizeChange={this.scrollMessages}
            onViewableItemsChanged={this.controller.onMessageRead}
            viewabilityConfig={this.viewabilityConfig}
            renderItem={this.renderMessage}
            onScrollToIndexFailed={console.warn}
            onScroll={this.controller.onScroll}
            // scrollEventThrottle={250}
            initialNumToRender={messages.length}
            ListEmptyComponent={!this.controller.loading && this.noMessage}
            ListFooterComponent={
              this.controller.messages.length > 0 && this.endOfMessages()
            }
          />
        );
      }}
    </Observer>
  );

  renderMessage = listItem => (
    <Observer>
      {() => {
        const message = this.controller.processSpecialMessage(
          toJS(listItem.item)
        );
        if (message.system) {
          return (
            <Text style={this.props.styles.systemMessage}>
              {message["sentTime"]
                ? new Date(message["sentTime"]).toLocaleString()
                : ""}
              {`\n`}
              <Text
                style={{
                  ...this.props.styles.systemMessage,
                  color: theme.color
                }}
              >
                {message.text}
              </Text>
            </Text>
          );
        }
        const sender =
          this.controller.members.find(
            member => member.id === message["senderMemberId"]
          ) || {};
        const own = sender.userId === this.controller.userId;
        const profile = this.controller.getMessageSenderProfile(sender);
        const avatar = this.controller.getMessageSenderAvatar(sender, profile);
        const sending = message.sending || (!message.id && message.watermark);
        return (
          <MessageItem
            id={message.id}
            sender={sender}
            profile={profile}
            avatar={avatar}
            text={message.text}
            unread={!own && !message.isRead}
            focused={
              this.highlightFocused &&
              this.controller.scrollToMessageId === message.id
            }
            sending={sending}
            timestamp={
              !sending && new Date(message["sentTime"]).toLocaleString()
            }
            self={own}
            onPress={e => this.controller.handleAvatarPress(e, sender)}
          />
        );
      }}
    </Observer>
  );

  keyExtractor = (item, index) => (item.id || item.watermark).toString();

  listSetter = list => (this.messageList = window.messageList = list);

  scrollMessages = async e => {
    if (!!this.messageList.scrollToEnd) {
      this.controller.setScrollToEnd(params =>
        this.messageList.scrollToEnd(params)
      );
    }
    if (!!this.messageList.scrollToItem) {
      this.controller.setScrollToItem(params =>
        this.messageList.scrollToItem(params)
      );
    }
    if (this.scrolled) {
      if (!this.controller.isEndReached) return;
      if (this.controller.lazyFetching) return;
      this.messageList.scrollToEnd({ animated: true });
    } else {
      clearTimeout(this.scroll);
      this.scroll = setTimeout(async () => {
        // console.log(this.controller.pendingScroll);
        await when(() => !this.controller.loading);
        if (this.scrolled) return;
        console.log("scroll fired");
        this.controller.shouldScrollToMessage &&
          this.messageList &&
          this.messageList.scrollToItem({
            animated: true,
            item: this.controller.shouldScrollToMessage
          });
        this.scrolled = true;
        setTimeout(this.dissolveFocus, 1500);
      }, 800);
    }
  };

  dissolveFocus = () => (this.highlightFocused = false);

  noMessage = (
    <Text style={this.props.styles.errorMessage}>
      {UIText.chatMessageEmpty}
    </Text>
  );

  endOfMessages = () => (
    <Text style={this.props.styles.endMessage}>
      {this.controller.lazyFetching
        ? UIText.fetchingMessages
        : UIText.chatMessageEnd}
    </Text>
  );

  render() {
    return (
      <View style={this.props.styles.style}>
        <Header
          title={capitalize(UIText.chat)}
          leftIcon="arrow-back"
          // rightIcon="more-vert"
          navigation={this.props.navigation}
          handleLeftPress={this.controller.handleBackPress}
          // handleRightPress={this.controller.handleMenuPress}
        />

        <View style={this.props.styles.chatHeading}>
          {this.controller.loading ? (
            <View style={this.props.styles.loading}>
              <ActivityIndicator size="small" color={theme.color} />
            </View>
          ) : (
            <>
              <DisplayTitle
                topic={this.controller.topic}
                groups={this.controller.groups}
                members={this.controller.members}
                currentGroup={this.controller.currentGroup}
                selfMember={this.controller.selfMember}
                styles={this.props.styles}
                loading={this.controller.lazyFetching}
              />
              {this.controller.lazyFetching && (
                <ActivityIndicator size="small" color={theme.color} />
              )}
            </>
          )}
          {/*<View style={this.props.styles.chatHeadingAvatars}>*/}
          {/*{this.renderMembers(this.controller.members)}*/}
          {/*</View>*/}
        </View>

        <Divider />

        <View style={this.props.styles.chatMessageContainer}>
          {this.controller.errorMessage
            ? this.renderError()
            : !this.controller.loading &&
              this.renderMessages(this.controller.messages)}
        </View>

        <ChatBottomBar
          loading={this.controller.loading}
          currentInputValue={this.controller.currentInputValue}
          handleInputChange={this.controller.handleInputChange}
          buttonMethods={this.buttonMethods}
          sendMessage={this.controller.sendMessage}
          multiline={this.controller.inputMultiline}
          // sendLoading={this.controller.sendLoading}
        />
      </View>
    );
  }
}

export { ChatScreen };
