import { getTopicAssigneeMembers } from "../../../../../../cdm/lib/topic-utilities";
import {
  getDisplayNameEng,
  getISOStringTimeZone
} from "../../../../../../utils/helpers";
import { UIText } from "../../../../../../config/lang-config";
import { template } from "../setup";

export const issueTimeAssigneeForm = group => {
  let form = [];
  const timezone = ((group.profile || {}).data || {}).timezone;

  const { members, assigneeMemberIds } = getTopicAssigneeMembers(group);

  const selections = members.map(m => ({
    type: "checkbox",
    set: "assignee",
    name: m.id,
    placeholder: getDisplayNameEng(m.profile.data),
    value: assigneeMemberIds.includes(Number(m.id))
  }));

  selections[0].expanded = true;
  selections[0].title = `${UIText.topicPickAssignee(template.title)} (${
    UIText.optional
  }):`;

  //date picker
  const today = getISOStringTimeZone(
    new Date(new Date().setHours(0, 0, 0, 0)),
    timezone
  );
  const endDate = {
    name: "endTime",
    type: "dateTime",
    mode: "datetime-local",
    placeholder: `${UIText.issueDueDate} (${UIText.optional})`,
    min: today
  };

  form.push(...selections, endDate);

  return form;
};
