import moment from "moment-timezone";
import { theme } from "../../../../config/style-configs/theme";

export const invoiceRerunConfirmationHtml = data => `<div style="box-sizing: border-box; padding: 15px; white-space: pre-wrap; font-size: ${
  theme.FONT_SIZE_MEDIUM
}px; font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif">
<div style="margin-bottom: 8px; color: ${
  theme.color
}">Please confirm the following details before executing:</div>
${data
  .map(
    careCircle => `<span>Care Circle ${
      careCircle.displayName
    } <span style="font-size: ${theme.FONT_SIZE_TINY}px">(ID: ${
      careCircle.groupId
    })</span> has <b>${
      careCircle.timesheets.length
    }</b> outstanding Timesheets:</span>
<ul>${careCircle.timesheets
      .map(
        timesheet =>
          `<li>${moment(timesheet.startTime)
            .tz(careCircle.timezone)
            .format("YYYY-MM-DD")} - ${moment(timesheet.endTime)
            .tz(careCircle.timezone)
            .format("YYYY-MM-DD")}: <b>${
            timesheet.totalHours
          } hours</b>&nbsp;&nbsp;<span style="font-size: ${
            theme.FONT_SIZE_TINY
          }px">(ID: ${timesheet.id})</span></li>`
      )
      .join("")}</ul>`
  )
  .join("")}
</div>`;
