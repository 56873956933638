import { theme } from "../../config/style-configs/theme";

export const styles = {
  style: {
    maxHeight: 45
  },
  background: {
    backgroundColor: "rgb(102, 102, 102)",
    padding: 5,
    borderRadius: 4
  },
  text: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    fontWeight: "600",
    color: "#fff"
  }
};
