import { issueTemplateSetup } from "./setup";
import { issueTimeAssigneeSetup } from "./issue-time-assignee/setup";

export const addIssue = isSubTopic => ({
  rootBack: {
    onPress: props => {
      props.navigation.navigate("Group");
    }
  },
  skipSelect: true,
  showFieldTitle: true,
  setupList: [
    {
      ...issueTemplateSetup(isSubTopic)
    },
    issueTimeAssigneeSetup
  ].filter(Boolean),
  onFinishSetup: props => {
    props.navigation.navigate("Group");
  },
  skipFinish: true,
  finishHeading: "Issue creation complete"
});
