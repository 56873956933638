import { computed, observable, toJS } from 'mobx';
import { clientController } from '../../../../cdm/controllers/client-controller';
import { stateController } from '../../../../cdm/controllers/state-controller';
import { groupTypeIds, topicTypeIds } from '../../../../config/variable-config';
import { apiController } from '../../../../cdm/controllers/api-controller';
import NavigationService from '../../../../utils/navigation-service';
import { capitalize } from '../../../../utils/helpers';
import { UIText } from '../../../../config/lang-config';

export class MCBProactiveChatListController {
  @observable refreshing = false;
  @observable loaded = false;

  @observable groups = [];
  @observable topics = [];

  topicTypeId = topicTypeIds.proactiveChat;

  topicFilter = t =>
    t.typeId === this.topicTypeId &&
    (this.isMCBGroup || !!t["lastPublicMessageTime"]);

  @computed get loading() {
    return !this.loaded;
  }
  // Current view group.
  @computed get groupId() {
    return stateController.viewGroupId;
  }
  @computed get group() {
    return clientController.findGroupById(this.groupId);
  }
  @computed get isMCBGroup() {
    return this.group.typeId === groupTypeIds.myCareBaseStaff;
  }
  @computed get member() {
    return (
      this.group.members.find(m => m.userId === clientController.userId) || {}
    );
  }
  @computed get filteredTopics() {
    return (
      toJS(this.topics)
        // .filter
        .sort((a, b) => {
          const dateA = a["createTime"] && new Date(a["createTime"]).getTime();
          const dateB = b["createTime"] && new Date(b["createTime"]).getTime();

          const nameA = a.description;
          const nameB = b.description;

          return this.sortBy === "dateAscend"
            ? -(dateA - dateB)
            : this.sortBy === "dateDescend"
            ? dateA - dateB
            : this.sortBy === "alphaAscend"
            ? nameA > nameB
              ? 1
              : -1
            : this.sortBy === "alphaDescend"
            ? nameA < nameB
              ? 1
              : -1
            : 0;
        })
    );
  }
  @computed get sortBy() {
    return (
      stateController.viewGroupState.adminMessageListSortBy[this.groupId] ||
      "dateAscend"
    );
  }

  constructor(props) {
    this.props = props;
    this._initialize();
  }

  componentWillUnmount() {
    Array.isArray(this.disposers) &&
      this.disposers.map(disposer => disposer && disposer());
    // txService.removeLogEventListener("MCBMessageListGroupListener");
  }

  _showError = err => {
    console.warn(err);
    return stateController.showPopup({
      title: capitalize(UIText.group),
      content: (err && err.message) || err,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: stateController.dismissPopup
    });
  };

  _initialize = () => {
    return this._getTopics(true)
      .catch(this._showError)
      .finally(() => (this.loaded = true));
  };

  _getTopics = async force => {
    if (this.topics.length === 0 || force) {
      return apiController
        .getTopicsByGroupId(this.groupId, true)
        .then(topics => (this.topics = topics.filter(this.topicFilter)))
        .catch(this._showError);
    }
  };

  openChat = topicId => {
    stateController.viewTopicId = topicId;
    NavigationService.navigate("Chat", { topic: topicId });
  };

  sortByDate = () =>
    setTimeout(
      () =>
        (stateController.viewGroupState.adminMessageListSortBy[this.groupId] =
          this.sortBy === "dateAscend"
            ? "dateDescend"
            : this.sortBy === "dateDescend"
            ? "dateAscend"
            : "dateAscend")
    );

  sortByAlpha = () =>
    setTimeout(
      () =>
        (stateController.viewGroupState.adminMessageListSortBy[this.groupId] =
          this.sortBy === "alphaAscend"
            ? "alphaDescend"
            : this.sortBy === "alphaDescend"
            ? "alphaAscend"
            : "alphaAscend")
    );

  onRefresh = () => {
    this.refreshing = true;
    return this._getTopics(true).finally(() => (this.refreshing = false));
  };

  updateTopic = async topic =>
    topic &&
    apiController.getTopicById(topic.id).then(clientController.updateTopic);
}
