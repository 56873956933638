import {
  CaregiverPostInterview,
  CaregiverSignUp,
  InvitationRegister,
  Login,
  RatingReview,
  Registration,
  Reset,
  Verification,
  Visitor
} from "../../screens/auth";
import { CaregiverJobReference } from "../../screens/auth/CaregiverJobReferenceScreen";
import { CaregiverUpload } from "../../screens/auth/CaregiverUploadScreen";

export const routeConfig = [
  {
    Login: {
      path: "Login",
      screen: Login
    },
    SignIn: {
      path: "SignIn",
      screen: Login
    },
    Registration: {
      path: "Registration",
      screen: Registration
    },
    Register: {
      path: "Register",
      screen: Registration
    },
    Verification,
    Reset,
    InvitationRegister: {
      path: "InvitationRegister",
      screen: InvitationRegister
    },
    Invitation: {
      path: "Invitation",
      screen: InvitationRegister
    },
    Visitor,
    CaregiverSignUpScreen: {
      path: "Caregiver",
      screen: CaregiverSignUp
    },
    VendorSignUpScreen: {
      path: "Vendor",
      screen: CaregiverSignUp
    },
    CaregiverPostInterview: {
      path: "CaregiverPostInterview",
      screen: CaregiverPostInterview
    },
    CaregiverJobReference: {
      path: "CaregiverJobReference",
      screen: CaregiverJobReference
    },
    CaregiverUpload: {
      path: "CaregiverUpload",
      screen: CaregiverUpload
    },
    VendorPostInterview: {
      path: "VendorPostInterview",
      screen: CaregiverPostInterview
    },
    RatingReview: {
      path: "reviewAndRating",
      screen: RatingReview
    }
  },
  {
    initialRouteName: "Login",
    headerMode: "none"
  }
];
