import React from "react";
import {
  ActivityIndicator,
  Platform,
  RefreshControl,
  ScrollView,
  View
} from "react-native";
import { observer, Observer } from "mobx-react";
import { GroupRoleItem } from "../../../../components/GroupRoleItem";
import { ThemedButton } from "../../../../components/ThemedButton";
import {
  groupMemberWording,
  serviceRecipientGroupTypeIds
} from "../../../../config/variable-config";
import { GroupHeading } from "../../../../components/GroupHeading";
import { TabHeader } from "../../../../components/TabHeader";
import { theme } from "../../../../config/style-configs/theme";
import { capitalize } from "../../../../utils/helpers";
import { UIText } from "../../../../config/lang-config";
import { paidCaregiverFeatureRestricted } from "../../lib/group-utilities-mcb";
import { ListRefreshButtonWeb } from "../../../../components/ListRefreshButtonWeb";
import { Tooltip } from "../../../../components/Tooltip";
import { fileService } from "../../../../cdm/services/file-service";

@observer
class GroupMemberPageView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  topTab = [
    {
      key: 0,
      name: groupMemberWording.plural
    }
  ];

  renderRoles = roles =>
    (roles || []).map(role => {
      return (
        <Observer key={role.id}>
          {() => (
            <GroupRoleItem
              groupProfile={this.props.group.profile}
              groupOwner={this.props.group.owner}
              IAmOwner={this.controller.IAmOwner}
              role={role}
              selfMember={this.controller.selfMember}
              showMenu={
                !paidCaregiverFeatureRestricted(
                  this.props.group,
                  this.controller.selfMember
                )
              }
              onPress={this.controller.handleMemberPress}
              onTitlePress={this.controller.handleCollapse}
              onChatPress={this.controller.openChat}
              onMemberMenuSelect={this.controller.onMemberMenuSelect}
              chatLoadingList={this.controller.chatLoadingList}
              chatLoading={this.controller.chatLoading}
              getChatTopic={this.controller.getChatTopic}
              getAvatarUri={fileService.getProfileAvatarUri}
              collapsed={!!this.controller.collapsible[role.id]}
            />
          )}
        </Observer>
      );
    });

  render() {
    return (
      <View style={this.props.styles.style}>
        <GroupHeading
          group={this.props.group}
          profile={this.controller.profileData}
          avatar={this.controller.groupAvatar}
          showAppointment={this.props.headerShowAppointment}
          showBilling={this.props.headerShowBilling}
          showEdit={this.props.headerShowEdit}
          groupTypeName={this.controller.groupTypeName}
          timezoneName={this.controller.timezoneName}
          handleHeadingPress={this.controller.handleHeadingPress}
          handleBillingPress={this.controller.handleBillingPress}
          handleAppointmentPress={this.controller.handleAppointmentPress}
        />
        <TabHeader tabs={this.topTab} currentKey={0} />
        {this.controller.loading && Platform.OS === "web" ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          <ScrollView
            refreshControl={
              <RefreshControl
                refreshing={
                  this.controller.refreshing || this.controller.loading
                }
                onRefresh={this.controller.onRefresh}
              />
            }
          >
            <View style={this.props.styles.groupRoleDetails}>
              <View style={this.props.styles.groupRoleHeader}>
                {!this.controller.loading &&
                  (serviceRecipientGroupTypeIds.includes(
                    this.props.group.typeId
                  ) &&
                    !paidCaregiverFeatureRestricted(
                      this.props.group,
                      this.controller.selfMember
                    ) && (
                      <View style={this.props.styles.buttonContainerHelp}>
                        <ThemedButton
                          style={this.props.styles.groupRoleHeaderButton}
                          title={`${capitalize(UIText.invitationInvite)} ${
                            groupMemberWording.singular
                          }`}
                          onPress={this.controller.invite}
                        />
                        <Tooltip
                          title={capitalize(UIText.invitation)}
                          content={UIText.groupHelpInvite(
                            this.controller.group["groupTypeName"]
                          )}
                        />
                      </View>
                    ))}
                {!this.controller.loading && (
                  <ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />
                )}
              </View>
              {!this.controller.loading &&
                this.renderRoles(this.controller.roles)}
              {/*this.renderRoles(this.props.group.roles)}*/}
            </View>
          </ScrollView>
        )}
      </View>
    );
  }
}

export { GroupMemberPageView };
