import React, { useState } from "react";
import { Observer, observer } from "mobx-react";
import {
  Button,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { computed, observable } from "mobx";
import {
  dayNamesEng,
  fullDayNamesEng,
  isEmpty,
  isEqual,
  isNonZeroFalse,
  preventDefaultStopProp
} from "../../../../utils/helpers";
import { Icon } from "react-native-elements";
import { theme } from "../../../../config/style-configs/theme";
import { responsive } from "../../../../config/style-configs/responsive";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { mCBDayTimePickerStyles } from "../../../../components/DatePicker/styles";
import moment from "moment-timezone";
import EditLocationModal from "../EditLocationModal";
import { getAvailabilityTemplate, getLocationName } from "./utils";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { UIText } from "../../../../config/lang-config";
import InjectToBody from "../../../../components/InjectToBody";
import LocationPreviewMap from "../LocationPreviewMap";
import { computedFn } from "mobx-utils";

const DaySelector = observer(({ dayOfWeek, disabled, onChange }) => (
  <Select value={dayOfWeek || "0"} onChange={onChange} readOnly={disabled}>
    {(responsive.deviceDimension.isDesktop ? fullDayNamesEng : dayNamesEng).map(
      (dayName, index) => (
        <MenuItem key={dayName} value={index}>
          {dayName}
        </MenuItem>
      )
    )}
  </Select>
));

const TimeSelector = ({ label, timeString, disabled, onChange }) => {
  const [isOpen, setOpen] = useState(false);
  const parsedTime = timeString && moment(timeString, "HH:mm:ss");
  const formattedTime =
    timeString && `${moment().format("YYYY-MM-DD")}T${timeString}Z`;
  const displayTime = parsedTime ? parsedTime.format("hh:mm a") : "--:-- --";
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <>
        <style
          dangerouslySetInnerHTML={{
            __html: mCBDayTimePickerStyles
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label={label}
          value={formattedTime}
          onChange={onChange}
          open={isOpen}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          TextFieldComponent={() => null}
        />
        <input
          readOnly
          style={{ maxWidth: 60, padding: 5 }}
          type="text"
          value={displayTime}
          placeholder={label}
          onClick={() => !disabled && setOpen(true)}
        />
      </>
    </MuiPickersUtilsProvider>
  );
};

const LocationSelector = ({
  locations,
  index,
  disabled,
  onEdit,
  onChange,
  onPreview,
  onDonePreview
}) => {
  const readOnlyPreview = disabled ? onPreview(index) : undefined;
  return (
    <Select
      value={index}
      onChange={onChange}
      readOnly={disabled}
      placeholder="No location selected"
      onMouseEnter={readOnlyPreview}
      onMouseLeave={onDonePreview}
    >
      {(locations || []).filter(Boolean).map((location, i) => (
        <MenuItem
          key={i}
          value={i}
          onMouseEnter={onPreview(i)}
          onMouseLeave={onDonePreview}
          onMouseMove={onPreview(i)}
        >
          {getLocationName(location)}
        </MenuItem>
      ))}
      {index && index.toString() === "-1" && (
        <MenuItem value={-1} onClick={onEdit}>
          {isEmpty(locations) ? "Add location" : "Add or edit location"}
        </MenuItem>
      )}
    </Select>
  );
};

const CommuteTimeSelector = ({ minutes, disabled, onChange }) => (
  <Select value={minutes} onChange={onChange} readOnly={disabled}>
    <MenuItem value={15}>15 min</MenuItem>
    <MenuItem value={30}>30 min</MenuItem>
    <MenuItem value={45}>45 min</MenuItem>
    <MenuItem value={60}>1 hour</MenuItem>
    <MenuItem value={90}>90 min</MenuItem>
    <MenuItem value={120}>2 hours</MenuItem>
  </Select>
);

@observer
class CaregiverAvailabilityFormView extends React.Component {
  @observable lastLocationsCount = 0;
  @observable editLocationAvailabilityIndex = null;
  @observable editLocationIndex = null;

  @observable previewLocation;
  @observable previewPos;
  // @observable availabilitySets = [];

  @computed get locations() {
    const field = (this.props.profileForm || []).find(
      field => field.name === "availabilityLocations"
    );
    return field && field.value;
  }
  @computed get availabilitySets() {
    const field = (this.props.profileForm || []).find(
      field => field.name === "availability"
    );
    return field && field.value;
  }

  @computed get editing() {
    // return true;
    return (
      !stateController.viewProfileState.availabilityFormDisabled[
        this.props.screenId
      ] && (stateController.viewProfileState.screenStates || {}).editing
    );
  }
  @computed get editingLocation() {
    return this.locations[this.editLocationIndex];
  }
  @computed get editLocationOpen() {
    return (
      !isNonZeroFalse(this.editLocationAvailabilityIndex) &&
      (!isEmpty(this.editingLocation) || this.editLocationIndex === -1)
    );
  }

  constructor(props) {
    super(props);
    this.populateFields();
  }

  componentDidMount() {
    window.document.body.addEventListener(
      "click",
      this.handleDoneLocationPreview
    );
  }

  componentWillUnmount() {
    window.document.body.removeEventListener(
      "click",
      this.handleDoneLocationPreview
    );
  }

  populateFields = () => {
    if (isEmpty(this.props.profileForm)) return;
    const availability = (this.props.profileForm || []).find(
      field => field.name === "availability"
    );
    if (availability && !Array.isArray(availability.value))
      availability.value = [];
    const locations = (this.props.profileForm || []).find(
      field => field.name === "availabilityLocations"
    );
    if (locations && !Array.isArray(locations.value)) locations.value = [];
    this.lastLocationsCount = this.locations.length;
  };

  isLocationInUse = computedFn(location => {
    if (isEmpty(location)) return false;
    const { locations, availabilitySets } = this;
    if (isEmpty(locations)) return false;
    const indexOfLocation = this.locations.indexOf(location);
    if (!locations[indexOfLocation]) return false;
    const availabilityIndex = (availabilitySets || []).findIndex(
      a => a.location === indexOfLocation
    );
    return (
      availabilityIndex >= 0 &&
      availabilityIndex !== this.editLocationAvailabilityIndex
    );
  });

  handleAddNewAvailability = event => {
    preventDefaultStopProp(event);
    const lastAvailability =
      this.availabilitySets[this.availabilitySets.length - 1] || {};
    const locationIndex = isNonZeroFalse(lastAvailability.location)
      ? this.locations.length - 1
      : lastAvailability.location;
    this.availabilitySets.push(getAvailabilityTemplate(locationIndex));
  };

  handleRemoveAvailability = index => {
    if (index < 0) return;
    return this.availabilitySets.splice(index, 1);
  };

  handleEditLocation = (event, locationIndex, availabilityIndex) => {
    preventDefaultStopProp(event);
    if (isNaN(locationIndex) || isNaN(availabilityIndex)) return;
    this.lastLocationsCount = this.locations.length;
    this.editLocationIndex = locationIndex;
    this.editLocationAvailabilityIndex = availabilityIndex;
  };

  handleCloseEditLocation = event => {
    preventDefaultStopProp(event);
    if (!isEqual(this.locations.length, this.lastLocationsCount)) {
      const availability = this.availabilitySets[
        this.editLocationAvailabilityIndex
      ];
      if (!availability) return;
      availability.location = this.locations.length - 1;
    }
    this.lastLocationsCount = this.locations.length;
    this.editLocationIndex = this.editLocationAvailabilityIndex = null;
  };

  handleEditingLocationChange = newLocation => {
    if (isEmpty(newLocation)) {
      if (isEmpty(this.editingLocation)) return;
      // Removing location
      if (this.isLocationInUse(this.editLocationIndex)) return;
      this.locations.splice(this.editLocationIndex, 1);
      for (const availability of this.availabilitySets) {
        if (availability.location >= this.editLocationIndex) {
          availability.location--;
        }
      }
      return this.handleCloseEditLocation();
    }
    if (this.editLocationIndex === -1) {
      this.locations.push(newLocation);
      return this.handleCloseEditLocation();
    }
    Object.assign(this.locations[this.editLocationIndex], newLocation);
    return this.handleCloseEditLocation();
  };

  handleTimeChange = (index, field, momentObj) => {
    // Set maximum end time to 11:59pm.
    if (field === "end" && momentObj.format("HH:mm") === "00:00") {
      momentObj.set({ hour: 23, minute: 59 });
    }
    const unixValue = momentObj.unix();
    const timeString = momentObj.format("HH:mm:00");
    const editingAvailability = this.availabilitySets[index];
    if (!editingAvailability) return;
    const setTimeString = field => {
      if (isEqual(editingAvailability[field], timeString)) return;
      editingAvailability[field] = timeString;
    };
    const sameDayAvailabilities = this.availabilitySets.filter(
      (a, i) => i !== index && a.dayOfWeek === editingAvailability.dayOfWeek
    );
    if (field === "start") {
      const currentEnd = moment(editingAvailability.end, "HH:mm:ss").unix();
      if (currentEnd && unixValue >= currentEnd) {
        return stateController.showPopup({
          title: "Invalid time",
          content:
            "Please select a start time that's no later than the selected end time.",
          leftButtonText: UIText.generalConfirm
        });
      }
      for (const availability of sameDayAvailabilities) {
        const parsedStart = moment(availability.start, "HH:mm:ss").unix();
        const parsedEnd = moment(availability.end, "HH:mm:ss").unix();
        if (unixValue > parsedStart && unixValue < parsedEnd) {
          return stateController.showPopup({
            title: "Overlapping time",
            content: `Please select a start time that does not overlap your other availabilities on ${
              fullDayNamesEng[editingAvailability.dayOfWeek]
            }.`,
            leftButtonText: UIText.generalConfirm
          });
        }
      }
    }
    if (field === "end") {
      const currentStart = moment(editingAvailability.start, "HH:mm:ss").unix();
      if (currentStart && unixValue <= currentStart) {
        return stateController.showPopup({
          title: "Invalid time",
          content:
            "Please select an end time that's no earlier than the selected start time.",
          leftButtonText: UIText.generalConfirm
        });
      }
      for (const availability of sameDayAvailabilities) {
        const parsedStart = moment(availability.start, "HH:mm:ss").unix();
        const parsedEnd = moment(availability.end, "HH:mm:ss").unix();
        const currentStart = moment(
          editingAvailability["start"],
          "HH:mm:ss"
        ).unix();
        const targetWithinRange =
          unixValue > parsedStart && unixValue < parsedEnd;
        const rangeOverlapping =
          currentStart <= parsedStart && unixValue >= parsedEnd;
        if (targetWithinRange || rangeOverlapping) {
          return stateController.showPopup({
            title: "Overlapping time",
            content: `Please select an end time that does not overlap your other availabilities on ${
              fullDayNamesEng[editingAvailability.dayOfWeek]
            }.`,
            leftButtonText: UIText.generalConfirm
          });
        }
      }
    }
    return setTimeString(field);
  };

  handleLocationPreview = locationIndex => event => {
    if (event)
      this.previewPos = {
        top: event.clientY,
        left: event.clientX
      };
    if (locationIndex < 0) return;
    const location = this.locations[locationIndex];
    if (!location) return;
    return (this.previewLocation = location || {});
  };

  handleDoneLocationPreview = () => (this.previewLocation = null);

  renderAvailabilityRow = (availabilitySet, index) => (
    <Observer key={index}>
      {() => {
        if (isEmpty(availabilitySet)) return null;
        const location = !isNonZeroFalse(availabilitySet.location)
          ? availabilitySet.location
          : -1;
        const locationData = this.locations[location];
        const noLocationSelected = location === -1 || isEmpty(locationData);
        const onDayChange = event =>
          (availabilitySet.dayOfWeek = event.target.value);
        const onStartChange = momentObj =>
          this.handleTimeChange(index, "start", momentObj);
        const onEndChange = momentObj =>
          this.handleTimeChange(index, "end", momentObj);
        const onLocationChange = event => {
          const index = event.target.value;
          if (Number(index) === -1) return;
          availabilitySet.location = index;
        };
        const onLocationPreview = index => this.handleLocationPreview(index);
        const onEditLocation = event =>
          this.handleEditLocation(event, location, index);
        const onAddLocation = event =>
          this.handleEditLocation(event, -1, index);
        const onCommuteChange = event =>
          (availabilitySet.maxCommuteMinutes = event.target.value);
        const onRemove = () => this.handleRemoveAvailability(index);
        const { dayOfWeek, start, end, maxCommuteMinutes } = availabilitySet;
        return (
          <TableRow>
            <TableCell>
              <DaySelector
                dayOfWeek={dayOfWeek}
                disabled={!this.editing}
                onChange={onDayChange}
              />
            </TableCell>
            <TableCell>
              <TimeSelector
                label="Start"
                timeString={start}
                disabled={!this.editing}
                onChange={onStartChange}
              />
            </TableCell>
            <TableCell>
              <TimeSelector
                label="End"
                timeString={end}
                disabled={!this.editing}
                onChange={onEndChange}
              />
            </TableCell>
            <TableCell>
              <LocationSelector
                locations={this.locations}
                index={location}
                disabled={!this.editing}
                onEdit={onEditLocation}
                onChange={onLocationChange}
                onPreview={onLocationPreview}
                onDonePreview={this.handleDoneLocationPreview}
              />
              {this.editing && (
                <>
                  &nbsp;
                  <IconButton
                    size="small"
                    disabled={noLocationSelected}
                    onClick={onEditLocation}
                    style={{ opacity: this.editing ? 1 : 0.5 }}
                  >
                    <Icon
                      name="pencil"
                      type="material-community"
                      size={22}
                      color={noLocationSelected ? "#999" : theme.color}
                    />
                  </IconButton>
                  &nbsp;
                  <IconButton size="small" onClick={onAddLocation}>
                    <Icon
                      name="plus"
                      type="material-community"
                      size={22}
                      color={theme.color}
                    />
                  </IconButton>
                </>
              )}
            </TableCell>
            <TableCell>
              <CommuteTimeSelector
                minutes={maxCommuteMinutes}
                disabled={!this.editing}
                onChange={onCommuteChange}
              />
            </TableCell>
            <TableCell>
              <IconButton
                onClick={onRemove}
                disabled={!this.editing}
                style={{ opacity: this.editing ? 1 : 0.5 }}
              >
                <Icon
                  name="delete"
                  type="material-community"
                  size={20}
                  color="#999"
                />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      }}
    </Observer>
  );

  render() {
    return (
      <div className="caregiverAvailabilityForm">
        <style>{`input, .MuiSelect-root { font-size: 14px !important }`}</style>
        <Typography
          style={{
            color: "#666",
            fontSize: theme.FONT_SIZE_SMALL,
            marginBottom: 16
          }}
          gutterBottom
        >
          Please tell us what days and times you’re available for new work.
          Select the day, the times you’re available on each day, where you are
          coming from, and your ideal maximum commute time. We will then use
          this information to identify job opportunities that match your
          preferences.
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="Availability entry table">
            <TableHead>
              <TableRow>
                <TableCell style={{ color: theme.color }}>Day</TableCell>
                <TableCell style={{ color: theme.color }}>Start Time</TableCell>
                <TableCell style={{ color: theme.color }}>End Time</TableCell>
                <TableCell style={{ color: theme.color }}>
                  Starting Location
                </TableCell>
                <TableCell style={{ color: theme.color }}>
                  Max Commute
                </TableCell>
                <TableCell style={{ color: theme.color }}>Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(this.availabilitySets || []).map(this.renderAvailabilityRow)}
            </TableBody>
          </Table>
          {this.editing && (
            <Button
              variant="text"
              style={{ textAlign: "center", width: "100%" }}
              onClick={this.handleAddNewAvailability}
            >
              <Icon
                name="plus-circle"
                type="material-community"
                size={24}
                color={theme.color}
              />
              &nbsp;
              <span style={{ color: theme.color, padding: "3px 0" }}>
                Add new availability
              </span>
            </Button>
          )}
        </TableContainer>

        <EditLocationModal
          isOpen={this.editLocationOpen}
          onClose={this.handleCloseEditLocation}
          location={this.editingLocation}
          isLocationInUse={this.isLocationInUse}
          onLocationUpdate={this.handleEditingLocationChange}
        />

        <InjectToBody>
          <LocationPreviewMap
            isOpen={!isEmpty(this.previewLocation)}
            onClose={this.handleDoneLocationPreview}
            value={this.previewLocation}
            position={this.previewPos}
          />
        </InjectToBody>
      </div>
    );
  }
}

export { CaregiverAvailabilityFormView };
