import React from "react";
import { MCBProactiveChatListView } from "./view";
import { MCBProactiveChatListController } from "./controller";
import { styles } from "./styles";

export class MCBProactiveChatList extends React.Component {
  controller = new MCBProactiveChatListController(this.props);

  render() {
    return (
      <MCBProactiveChatListView
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
