import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { isEmpty, preventDefaultStopProp } from "../../../../utils/helpers";
import EditLocationModal from "../EditLocationModal";
import InjectToBody from "../../../../components/InjectToBody";
import LocationPreviewMap from "../LocationPreviewMap";
import { Text } from "react-native";
import { Icon } from "react-native-elements";
import { theme } from "../../../../config/style-configs/theme";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { responsive } from "../../../../config/style-configs/responsive";

@observer
class CareReceiverLocationFieldView extends React.Component {
  @observable previewLocation;
  @observable previewPos;

  @observable editLocationOpen = false;

  @computed get editable() {
    if (this.props.editable) return true;
    const { editing, saving } =
      stateController.viewProfileState.screenStates || {};
    return editing && !saving;
  }

  @computed get location() {
    return (
      this.props.profileForm.find(field => field.name === "location") || {}
    );
  }
  @computed get locationValue() {
    if (isEmpty(this.location.value)) {
      return {
        name: "Home"
      };
    }
    return this.location.value;
  }

  componentDidMount() {
    window.document.body.addEventListener(
      "click",
      this.handleDoneLocationPreview
    );
  }

  componentWillUnmount() {
    window.document.body.removeEventListener(
      "click",
      this.handleDoneLocationPreview
    );
  }

  handleEditLocation = event => {
    preventDefaultStopProp(event);
    this.editLocationOpen = true;
  };

  handleCloseEditLocation = event => {
    preventDefaultStopProp(event);
    this.editLocationOpen = false;
  };

  handleEditingLocationChange = newLocation => {
    if (isEmpty(newLocation)) {
      this.location.value = false;
      return this.handleCloseEditLocation();
    }
    this.location.value = newLocation;
    return this.handleCloseEditLocation();
  };

  handleLocationPreview = event => {
    if (this.editable) return;
    if (event && responsive.deviceDimension.isDesktop) {
      this.previewPos = {
        top: event.clientY,
        left: event.clientX
      };
    } else {
      const top = Math.min(
        event.clientY,
        document.body.clientHeight * 0.25
      ).toString();
      this.previewPos = {
        top,
        left: 0
      };
    }
    return (this.previewLocation = this.location.value || {});
  };

  handleDoneLocationPreview = () => (this.previewLocation = null);

  isLocationInUse = () => false;

  render() {
    const isMobile = !responsive.deviceDimension.isDesktop;

    return (
      <div
        className="careReceiverLocationField"
        style={{
          display: "flex",
          minHeight: 40,
          alignItems: "center",
          fontSize: theme.FONT_SIZE_MEDIUM,
          paddingLeft: 10
        }}
      >
        <div style={{ margin: "5px 10px 5px 0" }}>
          <Icon name="place" size={24} color="black" />
        </div>
        <span
          onClick={this.handleLocationPreview}
          onMouseEnter={!isMobile ? this.handleLocationPreview : undefined}
          onMouseLeave={!isMobile ? this.handleDoneLocationPreview : undefined}
          style={{ padding: 5 }}
        >
          {this.editable ? (
            <Text
              style={{
                fontWeight: "600",
                textDecorationLine: "underline",
                color: theme.color
              }}
              onPress={this.handleEditLocation}
            >
              {!isEmpty(this.location.value)
                ? (this.location.value || {}).name
                : "Set location"}
            </Text>
          ) : (
            <Text
              style={
                !isEmpty(this.location.value)
                  ? { fontWeight: "600", color: theme.color }
                  : undefined
              }
            >
              {!isEmpty(this.location.value)
                ? (this.location.value || {}).name
                : "—"}
            </Text>
          )}
        </span>

        <EditLocationModal
          isOpen={this.editLocationOpen}
          onClose={this.handleCloseEditLocation}
          location={this.locationValue}
          isLocationInUse={this.isLocationInUse}
          onLocationUpdate={this.handleEditingLocationChange}
        />

        <InjectToBody>
          <LocationPreviewMap
            isOpen={!isEmpty(this.previewLocation)}
            onClose={this.handleDoneLocationPreview}
            value={this.previewLocation}
            position={this.previewPos}
          />
        </InjectToBody>
      </div>
    );
  }
}

export { CareReceiverLocationFieldView };
