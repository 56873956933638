import { Platform } from "react-native";
import { theme } from "../../config/style-configs/theme";

export const styles = {
  style: {
    padding: 15
  },
  rightContentContainerStyle: {
    flex: 0.2
  },
  titleStyles: {
    ...(Platform.OS === "web" && {
      marginLeft: 20
    }),
    marginRight: 10
  },
  subtitleStyle: {
    fontSize: theme.FONT_SIZE_SMALL
  },
  rightTitleStyle: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    color: "#666"
  }
};
