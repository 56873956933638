import { theme } from "../../../../config/style-configs/theme";
import { common } from "../../../../config/style-configs/common";

export const styles = {
  style: {
    width: "100%",
    flexDirection: "column",
    paddingStart: 5
  },
  loading: common.loading,
  errorMessage: {
    ...common.containerErrorMessage,
    textAlign: "left"
  },
  item: {
    flexDirection: "row",
    marginBottom: 10
  },
  linkHeavy: {
    marginRight: 5,
    fontWeight: "600",
    fontSize: theme.FONT_SIZE_MEDIUM,
    ...common.linkBlue
  }
};
