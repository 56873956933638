import React from 'react';
import { DrawerContentController } from './controller';
import { DrawerContentView } from './view';
import { styles } from './styles';

export class DrawerContent extends React.Component {
  controller = new DrawerContentController(this.props);

  render() {
    return (
      <DrawerContentView
        {...this.props}
        styles={styles}
        controller={this.controller}
      />
    );
  }
}
