import React from "react";
import { GroupMainPageView } from "./view";
import { GroupMainPageController } from "./controller";
import { styles } from "./styles";

export class GroupMainPage extends React.Component {
  controller = new GroupMainPageController(this.props);

  render() {
    return (
      <GroupMainPageView
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
