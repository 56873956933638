import React from "react";
import { Image, View } from "react-native";
import { Text } from "react-native-elements";
import { theme } from "../../../config/style-configs/theme";
import { ThemedInput } from "../../../components/ThemedInput";
import { ThemedButton } from "../../../components/ThemedButton";
import { observer } from "mobx-react";
import Icon from "react-native-vector-icons/MaterialIcons";
import { UIText } from "../../../config/lang-config";
import { embeddedService } from "../../../cdm/services/embedded-service";

@observer
class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
    this.rendered = false;
  }

  componentDidMount() {
    return this.controller.componentDidMount();
  }

  render() {
    setTimeout(() => {
      if (this.username && !!this.username.props.value && !this.rendered) {
        this.rendered = true;
        this.username.focus();
      }
    });
    return (
      <View style={this.props.styles.style}>
        <View style={this.props.styles.header}>
          <Image
            source={theme.loginLogo}
            style={this.props.styles.headerLogo}
          />
        </View>

        <View style={this.props.styles.fields}>
          <ThemedInput
            ref={input => {
              this.username = input;
            }}
            placeholder={UIText.loginFieldUsername}
            leftIcon={<Icon name="person" size={24} color="black" />}
            returnKeyType="next"
            value={this.controller.fieldData.username}
            editable={!this.controller.signingIn}
            onChange={e => {
              this.controller.updateFields("username", e);
            }}
            onEndEditing={e => {
              this.controller.updateFields("username", e);
            }}
            onBlur={e => {
              this.username.props.onEndEditing(e);
            }}
            onSubmitEditing={() => {
              this.password.focus();
            }}
          />
        </View>
        <View style={this.props.styles.fields}>
          <ThemedInput
            ref={input => {
              this.password = input;
            }}
            placeholder={UIText.loginFieldPassword}
            leftIcon={<Icon name="repeat" size={24} color="black" />}
            returnKeyType="go"
            blurOnSubmit={true}
            value={this.controller.fieldData.password}
            editable={!this.controller.signingIn}
            secureTextEntry={true}
            onChange={e => {
              this.controller.updateFields("password", e);
            }}
            onEndEditing={e => {
              this.controller.updateFields("password", e);
            }}
            onBlur={e => {
              this.password.props.onEndEditing(e);
            }}
            onSubmitEditing={this.controller.execSignIn}
          />
        </View>

        <ThemedButton
          ref={ref => (this.button = ref)}
          style={this.props.styles.button}
          disabled={this.controller.buttonDisabled || this.controller.signingIn}
          title={UIText.login}
          loading={this.controller.signingIn}
          onPress={this.controller.execSignIn}
        />

        <View style={this.props.styles.links}>
          {embeddedService.isEmbedded && (
            <Text
              style={this.props.styles.link}
              onPress={e => {
                this.controller.handleLinkPress("visitor", e);
              }}
            >
              {UIText.loginShowGenericMarket}
            </Text>
          )}
          <Text
            style={this.props.styles.link}
            onPress={e => {
              this.controller.handleLinkPress("register", e);
            }}
          >
            {UIText.loginLinkRegister}
          </Text>
          <Text
            style={this.props.styles.link}
            onPress={e => {
              this.controller.handleLinkPress("reset", e);
            }}
          >
            {UIText.loginLinkReset}
          </Text>
        </View>
      </View>
    );
  }
}

export { LoginScreen };
