import React from "react";
import { Input } from "react-native-elements";
import { theme } from "../../config/style-configs/theme";
import { observer } from "mobx-react";

@observer
class ThemedInput extends React.Component {
  render() {
    const props = this.props;
    const nativeProps = { ...props };

    nativeProps.hasOwnProperty("autoLineHeight") &&
      delete nativeProps.autoLineHeight;
    nativeProps.hasOwnProperty("showCounter") && delete nativeProps.showCounter;
    nativeProps.hasOwnProperty("heightOverride") &&
      delete nativeProps.heightOverride;
    nativeProps.hasOwnProperty("innerRef") && delete nativeProps.innerRef;
    nativeProps.hasOwnProperty("hideMaxLengthPlaceholder") &&
      delete nativeProps.hideMaxLengthPlaceholder;

    const lineBreaks = (props.value && props.value.match(/\n/g)) || [];

    const editable = typeof props.editable !== "boolean" || !!props.editable;

    const hideCounter =
      typeof props.showCounter === "boolean" && !props.showCounter;
    const showCounter =
      !hideCounter && !props.errorMessage && editable && props.multiline;

    const hasLengthLeft =
      showCounter &&
      (!props.maxLength ||
        (props.value && props.value.length) < props.maxLength);

    const dynamicBorder = {
      borderColor: editable ? props.extrastyles.input.borderColor : "#fff"
    };

    return (
      <>
        <Input
          {...nativeProps}
          ref={props.innerRef}
          inputStyle={{
            ...props.extrastyles.input,
            ...(props.multiline && props.extrastyles.multiline),
            ...(props.multiline &&
              props.autoLineHeight && {
                minHeight: lineBreaks.length * (theme.FONT_SIZE_MEDIUM * 2) + 25
              }),
            ...(props.maxLength && { minHeight: props.maxLength / 8 }),
            ...dynamicBorder,
            ...(props.inputStyle && props.inputStyle),
            ...(!isNaN(props.heightOverride) &&
              props.multiline && { minHeight: props.heightOverride })
          }}
          inputContainerStyle={props.extrastyles.container}
          rightIconContainerStyle={{
            ...props.extrastyles.inputRightIcon,
            ...props.rightIconContainerStyle
          }}
          leftIconContainerStyle={{
            ...props.extrastyles.inputLeftIcon,
            ...props.leftIconContainerStyle
          }}
          placeholder={`${props.placeholder || ""}${
            props.maxLength
              ? props.hideMaxLengthPlaceholder
                ? ""
                : ` (Max ${props.maxLength} character${
                    props.maxLength > 1 ? "s" : ""
                  })`
              : ""
          }`}
          errorMessage={
            showCounter
              ? props.maxLength
                ? `${props.maxLength -
                    (props.value && props.value.length)} left`
                : `${props.value ? props.value.length : 0} characters`
              : props.errorMessage
          }
          errorStyle={showCounter && hasLengthLeft && { color: "#666" }}
        />
      </>
    );
  }
}

export const ThemedInputView = React.forwardRef((props, ref) => (
  <ThemedInput innerRef={ref} {...props} />
));

// const nativeProps = { ...props };
//   nativeProps.hasOwnProperty("autoLineHeight") &&
//   delete nativeProps.autoLineHeight;
//   nativeProps.hasOwnProperty("showCounter") &&
//   delete nativeProps.showCounter;
//   nativeProps.hasOwnProperty("heightOverride") &&
//   delete nativeProps.heightOverride;
//
//   const lineBreaks = (props.value && props.value.match(/\n/g)) || [];
//
//   const editable = typeof props.editable !== "boolean" || !!props.editable;
//
//   const hideCounter =
//     typeof props.showCounter === "boolean" && !props.showCounter;
//   const showCounter =
//     !hideCounter && !props.errorMessage && editable && props.multiline;
//
//   const hasLengthLeft =
//     showCounter &&
//     (!props.maxLength ||
//       (props.value && props.value.length) < props.maxLength);
//
//   const dynamicBorder = {
//     borderColor: editable ? props.extrastyles.input.borderColor : "#fff"
//   };
//
//   const inputHeight = observable({ value: 0 });
//   const setInputHeight = event => {
//     const height =
//       event.nativeEvent.contentSize && event.nativeEvent.contentSize.height;
//     if (height) inputHeight.value = height;
//     console.log(inputHeight.value);
//   };
//   const reduceInputHeight = event => {
//     const height = event.nativeEvent.layout && event.nativeEvent.layout.height;
//     if (!isNaN(height) && inputHeight.value > height) inputHeight.value = height;
//     console.log(inputHeight.value);
//   };
//
//   return (
//     <>
//       <Input
//         {...nativeProps}
//         ref={ref}
//         inputStyle={{
//           ...props.extrastyles.input,
//           ...(props.multiline && props.extrastyles.multiline),
//           ...(props.multiline &&
//             props.autoLineHeight && {
//               // minHeight: lineBreaks.length * (theme.FONT_SIZE_MEDIUM * 2) + 25
//               minHeight: inputHeight.value
//             }),
//           ...(props.maxLength && { minHeight: props.maxLength / 4 }),
//           ...dynamicBorder,
//           ...(props.inputStyle && props.inputStyle),
//           ...(!isNaN(props.heightOverride) &&
//             props.multiline && { minHeight: props.heightOverride })
//         }}
//         inputContainerStyle={props.extrastyles.container}
//         rightIconContainerStyle={{
//           ...props.extrastyles.inputRightIcon,
//           ...props.rightIconContainerStyle
//         }}
//         leftIconContainerStyle={{
//           ...props.extrastyles.inputLeftIcon,
//           ...props.leftIconContainerStyle
//         }}
//         placeholder={`${props.placeholder || ""}${
//           props.maxLength
//             ? ` (Max ${props.maxLength} character${props.maxLength > 1 &&
//             "s"})`
//             : ""
//           }`}
//         errorMessage={
//           showCounter
//             ? props.maxLength
//             ? `${props.maxLength -
//             (props.value && props.value.length)} left`
//             : `${props.value ? props.value.length : 0} characters`
//             : props.errorMessage
//         }
//         errorStyle={showCounter && hasLengthLeft && { color: "#666" }}
//         onContentSizeChange={props.multiline && props.autoLineHeight && setInputHeight}
//       />
//       <View
//         style={props.extrastyles.inputHeightCalculator}
//         onLayout={reduceInputHeight}
//       >
//         <Text style={props.extrastyles.inputHeightCalculatorText}>
//           {props.value.replace(/(\r\n|\r|\n)$/i, "\n ")}
//         </Text>
//       </View>
//     </>
//   );
// });
