import React from "react";
import { RatingReviewController } from "./controller";
import { RatingReviewView } from "./view";
import { extraStyles, styles } from "./styles";

export class RatingReview extends React.Component {
  static navigationOptions = {
    title: `Review and Rating`
  };

  controller = new RatingReviewController(this.props);

  render() {
    return (
      <RatingReviewView
        {...this.props}
        styles={styles}
        extraStyles={extraStyles}
        controller={this.controller}
      />
    );
  }
}
