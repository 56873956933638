import React from "react";
import { GroupShiftPageView } from "./view";
import { GroupShiftPageController } from "./controller";
import { styles } from "./styles";

export class GroupShiftPage extends React.Component {
  controller = new GroupShiftPageController(this.props);

  render() {
    return (
      <GroupShiftPageView
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
