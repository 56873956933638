import React from "react";
import { Image, Picker, ScrollView, View } from "react-native";
import { observer } from "mobx-react";
import { Text } from "react-native-elements";
import Icon from "react-native-vector-icons/MaterialIcons";
import { ThemedInput } from "../../../components/ThemedInput";
import { ThemedButton } from "../../../components/ThemedButton";
import { theme } from "../../../config/style-configs/theme";
import { getKeyboardType, isEmpty } from "../../../utils/helpers";
import { UIText } from "../../../config/lang-config";
import { responsive } from "../../../config/style-configs/responsive";

// const CalendlyEmbedHtml = `<!-- Calendly inline widget begin -->
// <div class="calendly-inline-widget" data-url="{{dataUrl}}" style="min-width:320px;height:630px;"></div>
// <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
// <!-- Calendly inline widget end -->`;

@observer
class CaregiverSignUpView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
    this.input = {};
    this.rendered = false;
  }

  componentDidMount() {
    return this.controller.componentDidMount();
  }

  // setCalendlyHtml = html => {
  //   html = html
  //     .replace(
  //       /style=(.*)>/,
  //       `style="min-width:300px;height:${responsive.deviceDimension.height *
  //         0.55}px;">`
  //     )
  //     .replace(/{{dataUrl}}/, this.controller.calendlyUrl);
  //   return html;
  // };

  render() {
    if (!isEmpty(this.controller.form) && !this.rendered) {
      this.rendered = true;
      setTimeout(() => {
        for (let input in this.input) {
          if (this.input.hasOwnProperty(input))
            this.input[input].props.defaultValue && this.input[input].focus();
        }
      });
    }
    return (
      <View style={this.props.styles.style}>
        <View
          style={{
            ...this.props.styles.header,
            ...this.props.extraStyles.header
          }}
        >
          <Image
            source={theme.loginLogo}
            style={this.props.styles.headerLogo}
          />
        </View>
        <ScrollView
          style={this.props.styles.scrollView}
          contentContainerStyle={{
            ...this.props.styles.container,
            ...this.props.extraStyles.container
          }}
        >
          {(!this.controller.showCalendly ||
            responsive.deviceDimension.isDesktop) && (
            <View style={this.props.extraStyles.split}>
              <View style={this.props.styles.questions}>
                <Text style={this.props.styles.questionHeader}>
                  {UIText.caregiverApplicationWelcomeMessage}
                </Text>
              </View>

              <View style={this.props.styles.fields}>
                <Text style={this.props.styles.inputTitle}>
                  {UIText.registrationFieldNames.firstName}
                </Text>
                <ThemedInput
                  // containerStyle={this.props.styles.halfLength}
                  ref={input => {
                    this.input.firstName = input;
                  }}
                  placeholder={UIText.registrationFieldNames.firstName}
                  leftIcon={<Icon name="first-page" size={24} color="black" />}
                  errorMessage={this.controller.error.firstName.replace(
                    "Name",
                    " name"
                  )}
                  editable={!this.controller.fieldLocked}
                  returnKeyType="next"
                  value={this.controller.fieldData.firstName}
                  onChange={e => this.controller.updateFields("firstName", e)}
                  onEndEditing={e => {
                    this.controller.updateFields("firstName", e);
                    this.controller.validateRequired("firstName");
                  }}
                  onBlur={e => {
                    this.input.firstName.props.onEndEditing(e);
                  }}
                  onSubmitEditing={() => {
                    this.input.lastName.focus();
                  }}
                />
              </View>

              <View style={this.props.styles.fields}>
                <Text style={this.props.styles.inputTitle}>
                  {UIText.registrationFieldNames.lastName}
                </Text>
                <ThemedInput
                  // containerStyle={this.props.styles.halfLength}
                  ref={input => {
                    this.input.lastName = input;
                  }}
                  placeholder={UIText.registrationFieldNames.lastName}
                  leftIcon={<Icon name="last-page" size={24} color="black" />}
                  errorMessage={this.controller.error.lastName.replace(
                    "Name",
                    " name"
                  )}
                  editable={!this.controller.fieldLocked}
                  returnKeyType="next"
                  value={this.controller.fieldData.lastName}
                  onChange={e => this.controller.updateFields("lastName", e)}
                  onEndEditing={e => {
                    this.controller.updateFields("lastName", e);
                    this.controller.validateRequired("lastName");
                  }}
                  onBlur={e => {
                    this.input.lastName.props.onEndEditing(e);
                  }}
                  onSubmitEditing={() => {
                    this.input.email.focus();
                  }}
                />
              </View>

              <View style={this.props.styles.fields}>
                <Text style={this.props.styles.inputTitle}>
                  {UIText.registrationFieldNames.email}
                </Text>
                <ThemedInput
                  ref={input => {
                    this.input.email = input;
                  }}
                  placeholder={UIText.registrationFieldNames.email}
                  keyboardType={getKeyboardType({ name: "email" })}
                  leftIcon={<Icon name="email" size={24} color="black" />}
                  errorMessage={this.controller.error.email}
                  editable={!this.controller.fieldLocked}
                  returnKeyType="next"
                  value={this.controller.fieldData.email}
                  onChange={e => this.controller.updateFields("email", e)}
                  onEndEditing={e => {
                    this.controller.updateFields("email", e);
                    this.controller.validateRequired("email");
                  }}
                  onBlur={e => {
                    this.input.email.props.onEndEditing(e);
                  }}
                  onSubmitEditing={() => {
                    this.input.phone.focus();
                  }}
                />
              </View>

              <View style={this.props.styles.fields}>
                <Text style={this.props.styles.inputTitle}>
                  {UIText.registrationFieldNames.phone}
                </Text>
                <ThemedInput
                  ref={input => {
                    this.input.phone = input;
                  }}
                  placeholder={UIText.registrationFieldNames.phone}
                  keyboardType={getKeyboardType({ name: "phone" })}
                  leftIcon={<Icon name="phone" size={24} color="black" />}
                  errorMessage={this.controller.error.phone}
                  editable={!this.controller.fieldLocked}
                  returnKeyType="next"
                  value={this.controller.fieldData.phone}
                  onChange={e => this.controller.updateFields("phone", e)}
                  onEndEditing={e => {
                    this.controller.updateFields("phone", e);
                    this.controller.validateRequired("phone");
                  }}
                  onBlur={e => {
                    this.input.phone.props.onEndEditing(e);
                  }}
                  onSubmitEditing={() => {
                    this.input.gender.focus();
                  }}
                />
              </View>

              {!this.controller.isVendor && (
                <View style={this.props.styles.fields}>
                  <Text style={this.props.styles.inputTitle}>
                    {UIText.registrationFieldNames.gender}
                  </Text>
                  <View
                    style={{
                      ...this.props.styles.fields,
                      ...this.props.styles.pickerFields
                    }}
                  >
                    <Icon
                      name="person"
                      type="material-community"
                      size={24}
                      color="black"
                    />
                    <Picker
                      ref={input => {
                        this.input.gender = input;
                      }}
                      selectedValue={this.controller.fieldData.gender}
                      style={this.props.styles.picker}
                      enabled={!this.controller.fieldLocked}
                      onValueChange={value => {
                        this.controller.updateFields("gender", {
                          nativeEvent: { text: value }
                        });
                      }}
                    >
                      <Picker.Item
                        key="male"
                        label={UIText.registrationFieldNames.genderMale}
                        value="male"
                      />
                      <Picker.Item
                        key="female"
                        label={UIText.registrationFieldNames.genderFemale}
                        value="female"
                      />
                    </Picker>
                  </View>
                </View>
              )}

              {!this.controller.showCalendly && (
                <ThemedButton
                  style={this.props.styles.button}
                  disabled={
                    this.controller.buttonDisabled ||
                    this.controller.registering ||
                    this.controller.fieldLocked
                  }
                  title={UIText.generalSubmit}
                  loading={this.controller.registering}
                  onPress={this.controller.execRegister}
                />
              )}
            </View>
          )}

          {this.controller.showCalendly && (
            <View style={this.props.extraStyles.calendlyContainer}>
              <View style={this.props.styles.questions}>
                <Text style={this.props.styles.questionHeader}>
                  {UIText.caregiverApplicationBookAppointment}
                </Text>
              </View>
              {/*<HtmlComponent*/}
              {/*  style={this.props.extraStyles.calendlyFrame}*/}
              {/*  useFrame={true}*/}
              {/*  html={this.setCalendlyHtml(CalendlyEmbedHtml)}*/}
              {/*/>*/}
              <div
                className="calendly-inline-widget"
                style={{
                  minWidth: "300px",
                  height: `${responsive.deviceDimension.height * 0.6}px`
                }}
                data-url={this.controller.calendlyUrl}
              />
            </View>
          )}
        </ScrollView>
      </View>
    );
  }
}

export { CaregiverSignUpView };
