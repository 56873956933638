import { TypeClassTemplate } from '../../../../../cdm/services/form-service';
import { typeClassIds } from '../../../../../config/variable-config';

let template = new TypeClassTemplate(typeClassIds.issueTopic);

export const issueTemplateForm = () => {
  if (!template || !template.form) return null;

  const titleField = {
    name: "title",
    type: "input",
    required: true,
    placeholder: "Title",
    flags: {
      executionEdit: false,
      executionRender: false,
      setupEdit: true,
      setupRender: true
    },
    value: template.description || ""
  };

  const form = [...template.form];
  if (!form.find(f => f.name === "title")) form.unshift(titleField);

  return form;
};
