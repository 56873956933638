import React from "react";
import { Image, ScrollView, View } from "react-native";
import { Observer, observer } from "mobx-react";
import { theme } from "../../../config/style-configs/theme";
import { Text } from "react-native-elements";
import { UIText } from "../../../config/lang-config";
import { getDisplayNameEng, isEmpty } from "../../../utils/helpers";
import { ThemedButton } from "../../../components/ThemedButton";
import { ThemedInput } from "../../../components/ThemedInput";
import { FormField } from "../../../components/FormField";
import { placeholderPickerValue } from "../../../config/variable-config";

@observer
class CaregiverJobReferenceView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentDidMount() {
    return this.controller.componentDidMount();
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  renderQuestion = questionIndex => {
    const {
      questionData,
      questions,
      getResponseContent,
      updateResponseSelection,
      updateResponseContent,
      submitted
    } = this.controller;
    const questionText = questions[questionIndex];
    const questionSelection = (questionData[questionIndex] || {}).selections;
    return (
      <Observer key={questionIndex}>
        {() => (
          <View style={this.props.extraStyles.questionFields}>
            <Text style={this.props.extraStyles.questionHeader}>
              {questionText}
            </Text>
            {isEmpty(questionSelection) || submitted ? (
              <ThemedInput
                multiline
                heightOverride={100}
                placeholder={UIText.caregiverJobReferenceFieldPlaceholder}
                value={getResponseContent(questionIndex)}
                editable={!submitted}
                onChange={e => {
                  updateResponseContent(e, questionIndex);
                }}
              />
            ) : (
              <FormField
                field={{
                  type: "picker",
                  placeholder: UIText.caregiverJobReferenceSelectPlaceholder,
                  options: [
                    {
                      name: placeholderPickerValue,
                      placeholder: "Please select..."
                    },
                    ...questionSelection
                  ],
                  value:
                    getResponseContent(questionIndex) || placeholderPickerValue
                }}
                onPickerChange={(value, i) =>
                  updateResponseSelection(value, i - 1, questionIndex)
                }
              />
            )}
          </View>
        )}
      </Observer>
    );
  };

  render() {
    const {
      questions,
      jobReferenceResponse,
      candidateProfile,
      buttonDisabled,
      submitting,
      submitted,
      execSubmit
    } = this.controller;

    return (
      <View style={this.props.styles.style}>
        <ScrollView
          style={this.props.styles.scrollView}
          contentContainerStyle={this.props.styles.container}
        >
          <View style={this.props.styles.header}>
            <Image
              source={theme.loginLogo}
              style={this.props.styles.headerLogo}
            />
          </View>

          {!this.controller.loading && (
            <>
              <View style={this.props.styles.questions}>
                <Text style={this.props.extraStyles.heading}>
                  {UIText.caregiverJobReferenceHeading(
                    jobReferenceResponse.referenceName
                  )}
                </Text>
                <Text style={this.props.extraStyles.heading}>
                  {UIText.caregiverJobReferenceSubHeading(
                    getDisplayNameEng(candidateProfile)
                  )}
                </Text>
              </View>

              {Array.from(new Array(questions.length)).map((any, index) =>
                this.renderQuestion(index)
              )}

              <View style={this.props.styles.questions}>
                {!submitted && (
                  <ThemedButton
                    style={this.props.extraStyles.button}
                    disabled={buttonDisabled || submitting || submitted}
                    title={UIText.generalSubmit}
                    loading={submitting}
                    onPress={execSubmit}
                  />
                )}
              </View>
            </>
          )}
        </ScrollView>
      </View>
    );
  }
}

export { CaregiverJobReferenceView };
