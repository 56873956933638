import { common } from "../../../../config/style-configs/common";

export const styles = {
  style: {
    flex: 1
  },
  content: {
    flex: 1
    // padding: 10
  },
  webView: {
    flex: 1,
    paddingHorizontal: 15
  },
  loading: common.loading,
  buttonContainer: {
    ...common.listButtonContainer,
    padding: 20,
    borderColor: "#ccc",
    borderBottomWidth: 1,
    alignItems: "center"
  },
  buttonContainerHelp: common.listButtonContainerWithHelp,
  approvalButtonContainer: {
    flexDirection: "row"
  },
  errorMessage: common.containerErrorMessage,
  listRefreshButton: common.listRefreshButton
};
