import { apiController } from "../../../../cdm/controllers/api-controller";
import {
  contextReject,
  getDisplayNameEng,
  isEqual,
  safeParseJSON
} from "../../../../utils/helpers";
import { formService } from "../../../../cdm/services/form-service";
import { action, autorun, computed, decorate, observable } from "mobx";
import { AdminVendorOnboardingForm } from "./admin-vendor-onboarding-form";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { UIText } from "../../../../config/lang-config";
import { apiService } from "../../../../cdm/services/api-service";
import { mcbEndpointConfig } from "../../config/api-config";
import { groupTypeIds } from "../../../../config/variable-config";
import {
  adminCentreOnboardingFormAutoFieldControl,
  adminCentreOnboardingHandleProfileChange,
  adminCentreOnboardingHandleSaveButton
} from "./index";
import { computedFn } from "mobx-utils";

const adminCentreInjectLegacyRecruitForm = async (
  screenId,
  card,
  isRefresh,
  showError
) => {
  const refreshProfile = async () =>
    apiController
      .getProfileById(card.profile.id)
      .then(profile => (card.profile = profile));
  const refreshTopic = async () =>
    apiController
      .getTopicById(card.topic.id, card.member.id)
      .then(topic => (card.topic = apiController.parser.parseTopic(topic)))
      .then(() => (card.topic.data = safeParseJSON(card.topic.data)));
  if (isRefresh) {
    await refreshProfile()
      .then(refreshTopic)
      .catch(showError);
  }
  const profileForm = await formService
    .findFormClassByIdAsync(card.profile.typeClassId)
    .then(form =>
      formService.assembleFormData(form, card.profile.data, {
        override: true
      })
    );
  const approvalCheckbox = profileForm.find(
    field => field.name === "approvalOfCandidateConfirmedByReference"
  );
  approvalCheckbox.readonly = !card.topic.data.screeningStatus.match(
    /completed|(information provided)/gi
  );
  const onboardingProfileFields = profileForm.filter(
    field => !!field.flags["onboarding"]
  );
  const onboardingProfileData = formService.disassembleFormData(
    onboardingProfileFields
  );
  const onboardingData = {
    ...card.topic.data,
    ...onboardingProfileData
  };
  const form = observable(
    formService.assembleFormData(
      AdminVendorOnboardingForm(
        card.topic.data,
        onboardingProfileFields,
        card.topic.id,
        card.profile.id,
        getDisplayNameEng(card.profile),
        showError
      ),
      onboardingData
    )
  );
  const onboardingDataCopy = formService.disassembleFormData(form);
  Object.freeze(onboardingDataCopy);
  const monitor = {
    get formData() {
      return formService.disassembleFormData(form);
    }
  };
  decorate(monitor, { formData: computed });
  autorun(() =>
    adminCentreOnboardingFormAutoFieldControl(form, monitor, onboardingDataCopy)
  );
  const saveButton = observable({ loading: false });
  const fieldProps = {
    showLabel: true,
    showTitle: true,
    enabledGetter: computedFn(field => !field.readonly),
    valueGetter: computedFn(field =>
      field.value ? field.value : field.readonly ? "—" : ""
    ),
    onInputChange: action((e, field) => {
      field.value = e.nativeEvent.text;
    }),
    onCheckboxChange: action(field => {
      field.value = !field.value;
    }),
    onPickerChange: action((value, field) => {
      field.value = value;
    }),
    nonEditModeHideNoValue: true
  };
  const onSave = async () => {
    saveButton.loading = true;
    return stateController
      .showPopup({
        title: UIText.adminCentre,
        content: UIText.pleaseWait
      })
      .then(async () => {
        const changed = Object.keys(monitor.formData).filter(
          key => !isEqual(monitor.formData[key], onboardingDataCopy[key])
        );
        await adminCentreOnboardingHandleProfileChange(
          card,
          monitor,
          changed,
          onboardingDataCopy,
          onboardingProfileData,
          refreshProfile
        ).catch(contextReject);
        if (
          changed.includes("updateScreeningStatus") ||
          changed.includes("approvalOfCandidateConfirmedByReference")
        ) {
          let screeningStatus = changed.includes("updateScreeningStatus")
            ? monitor.formData["updateScreeningStatus"].toLowerCase()
            : monitor.formData["approvalOfCandidateConfirmedByReference"]
            ? "completed"
            : monitor.formData["updateScreeningStatus"].match(/Completed/g)
            ? "information provided"
            : undefined;
          if (!screeningStatus) return;
          if (screeningStatus.match(/Interview\scompleted/gi)) {
            screeningStatus = "information requested";
          }
          return apiService.async("PATCH", {
            endpoint: mcbEndpointConfig.modify_screening_status,
            data: {
              id: card.topic.id,
              data: JSON.stringify({ screeningStatus })
            }
          });
        }
      })
      .then(() => (stateController.viewProfileState.autoEdit = true))
      .then(() =>
        adminCentreInjectLegacyRecruitForm(screenId, card, true, showError)
      )
      .then(stateController.dismissPopup)
      .catch(showError)
      .finally(() => {
        saveButton.loading = false;
        stateController.viewProfileState.refresh();
      });
  };
  stateController.viewProfileState.rightButtonOverride[screenId] = {
    handlePress: () =>
      adminCentreOnboardingHandleSaveButton(
        screenId,
        saveButton,
        monitor,
        onboardingDataCopy,
        onSave
      )
  };
  stateController.viewProfileState.additionalForm[screenId] = {
    heading:
      card.group.typeId === groupTypeIds.caregiver
        ? "Caregiver onboarding data"
        : "Service provider onboarding data",
    form,
    fieldProps
  };
};

export default adminCentreInjectLegacyRecruitForm;
