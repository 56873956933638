import { common } from "../../../../config/style-configs/common";

export const styles = {
  style: {
    flex: 1
  },
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  scrollViewCard: common.scrollViewCard,
  errorMessage: common.containerErrorMessage,
  buttonContainer: common.listButtonContainer,
  listRefreshButton: common.listRefreshButton,
  card: common.card,
  loading: common.loading,
  cardContent: common.cardChildrenCompact,
  cardContentLarge: common.cardChildrenTextLarge,
  cardContentMedium: common.cardChildrenTextMedium,
  cardContentSmall: common.cardChildrenTextSmall
};
