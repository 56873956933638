import { common } from "../../../config/style-configs/common";

export const styles = {
  style: {
    flex: 1
  },
  loading: common.loading,
  errorMessage: common.containerErrorMessage,
  buttonContainer: common.listButtonContainer,
  badgeStyle: {
    paddingHorizontal: 5
  },
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  collapsibleTitleWrapper: common.collapsibleTitleWrapper,
  collapsibleTitleText: common.collapsibleTitleText
};
