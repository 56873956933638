import { TypeClassTemplate } from '../../../../../../cdm/services/form-service';
import { groupTypeRoleIds, typeClassIds } from '../../../../../../config/variable-config';

let template = new TypeClassTemplate(typeClassIds.familyMemberProfile);

export const acceptInviteForm = (invitation, isOffer) => {
  if (isOffer) {
    return [
      // {
      //   name: "termsText",
      //   title: "Conditions",
      //   type: "input",
      //   placeholder: "Conditions",
      //   multiline: true,
      //   numberOfLines: 10,
      //   disabled: true,
      //   value: ipsum()
      // },
      {
        name: "acceptOffer",
        type: "checkbox",
        placeholder: "I accept this offer of employment.",
        required: true
      },
      {
        name: "agreeTerms",
        type: "checkbox",
        placeholder: "I confirm the Terms and Conditions previously agreed to.",
        required: true
      },
      {
        name: "termsLink",
        type: "link",
        value: "/?showTerms=true",
        placeholder: "View Terms and Conditions"
      }
    ];
  }
  if (!template || !template.form) return null;

  const isPaidCaregiver = invitation.invitedMember.roleList.some(
    r => r.groupTypeRoleId === groupTypeRoleIds.paidCaregiver
  );

  for (let field of template.form) {
    if (field.name === "firstName") {
      field.value = invitation.invitedMember.profile.data.firstName;
    }
    if (field.name === "lastName") {
      field.value = invitation.invitedMember.profile.data.lastName;
    }
    if (field.name === "email") {
      field.value = invitation.invitedMember.email;
    }
    if (field.name === "phone") {
      field.value = invitation.invitedMember.phone;
    }
    if (field.name === "customRoleDescription") {
      field.value =
        invitation.invitedMember.profile.data.customRoleDescription || false;
    }
    if (
      (field.flags && !!field.flags["hourlyRate"] && !isPaidCaregiver) ||
      field.name === "discountedHourlyRate"
    ) {
      field.hidden = true;
      field.required = false;
    }
    if (field.flags && !!field.flags["hourlyRate"]) {
      field.showLabel = true;
    }
    if (field.name === "negotiatedHourlyRate") {
      field.required = false;
    }
  }
  return template.form;
};
