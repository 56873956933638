import React from "react";
import { GroupFinderPageView } from "./view";
import { GroupFinderPageController } from "./controller";
import { styles } from "./styles";

export class GroupFinderPage extends React.Component {
  controller = new GroupFinderPageController(this.props);

  render() {
    return (
      <GroupFinderPageView
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
