import { observer } from "mobx-react";
import { computed, observable, when } from "mobx";
import React from "react";
import { placesService } from "../../../../../cdm/services/places-service";
import {
  isEqual,
  preventDefaultStopProp,
  randomString,
  whenFulfill
} from "../../../../../utils/helpers";
import isEmpty from "react-native-web/dist/vendor/react-native/isEmpty";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { apiService } from "../../../../../cdm/services/api-service";
import { endpointConfig, serverConfig } from "../../../../../config/api-config";
import Icon from "react-native-vector-icons/MaterialIcons";

@observer
class GooglePlaceSelector extends React.Component {
  @observable initialized;
  @observable input;

  @observable value;

  @computed get displayValue() {
    if (isEmpty(this.value)) return "";
    return typeof this.value === "string"
      ? this.value
      : typeof this.value === "object"
      ? this.value.formattedAddress || `...`
      : "";
  }

  constructor(props) {
    super(props);
    const { value } = this.props;
    this.value = this.formatValue(value);
    this.fixPacContainerOutOfBound();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.value = this.formatValue(this.props.value);
    }
    // if (!this.props.readonly && !this.initialized) this.initGooglePlaces();
  }

  inputRef = ref => {
    if (ref) this.input = ref;
    return this.initGooglePlaces(this.input);
  };

  setMapCircleCenter = (autocomplete, options) => {
    const circle = new google.maps.Circle(options);
    return autocomplete.setBounds(circle.getBounds());
  };

  setCenterUsingLocation = async autocomplete => {
    const position = await placesService
      .getGeolocation(true)
      .catch(console.warn);
    if (!position) return;
    const circle = {
      center: {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      },
      radius: position.coords.accuracy
    };
    return this.setMapCircleCenter(autocomplete, circle);
  };

  setCenterUsingOptions = async autocomplete =>
    this.setMapCircleCenter(autocomplete, this.props.mapOptions);

  initGooglePlaces = async inputElm => {
    await whenFulfill(() => placesService.initialized);
    if (this.props.disabled) return;
    if (this.initialized) return;
    if (!inputElm) return;
    await when(() => placesService.initialized);
    const autocomplete = new google.maps.places.Autocomplete(
      inputElm,
      this.props.autocompleteOptions
    );
    if (this.props.biasCenter)
      this.setCenterUsingLocation(autocomplete).catch(console.warn);
    if (this.props.mapOptions)
      this.setCenterUsingOptions(autocomplete).catch(console.warn);
    autocomplete.addListener("place_changed", e => {
      const place = autocomplete.getPlace();
      if (!place.place_id) return; // Prevent enter-key clearing places data.
      const value = placesService.parseLatLngWithPlaceId(place);
      return this.props.onPositionChange && this.props.onPositionChange(value);
    });
    return (this.initialized = true);
  };

  handleChange = event => {
    preventDefaultStopProp(event);
    const { target } = event;
    if (!target) return;
    const { value } = target;
    this.value = value;
    if (isEmpty(value)) return this.handleClear(null);
  };

  handleClear = event => {
    preventDefaultStopProp(event);
    this.value = "";
    this.props.onPositionChange(null);
  };

  fixPacContainerOutOfBound = () => {
    // const pacContainer = () => Array.from(document.getElementsByClassName("pac-container"))[0];
    // if (!pacContainer()) return setTimeout(this.fixPacContainerOutOfBound, 2000);
    // const setPacContainerOffset = () => {
    //   const elm = pacContainer();
    //   if (!elm) return;
    //   elm.style.zIndex = "999999";
    //   // const top = Number((elm.style.top || "").toString().replace("px", ""));
    //   // if (isNaN(top)) return;
    //   // if ((top + elm.clientHeight) < (window.innerHeight - 50)) return;
    //   // return elm.style.marginTop = `-${elm.clientHeight + 50}px`;
    // };
    // new window.ResizeObserver(setPacContainerOffset).observe(pacContainer());
    const pacContainerId = "pac-container-style-fix";
    if (document.getElementById(pacContainerId)) return;
    const pacContainerStyle = document.createElement("style");
    pacContainerStyle.id = pacContainerId;
    pacContainerStyle.innerHTML = `.pac-container { z-index: 999999 !important }`;
    document.body.appendChild(pacContainerStyle);
  };

  formatValue = value => {
    if (!value) return {};
    const { lat, lng, formattedAddress, osm_id } = value;
    const withoutOSM = !formattedAddress || !osm_id;
    if (!withoutOSM) return value;
    apiService
      .async("GET", {
        endpoint: endpointConfig.reverse_geocoding(lat, lng),
        headers: serverConfig.defaultHeaders
      })
      .then(response => response.data)
      .then(data => {
        if (value.lat) delete value.lat;
        if (value.lng) delete value.lng;
        const formattedAddress = (data || {}).formattedAddress;
        this.value = {
          ...value,
          ...data,
          formattedAddress
        };
        this.props.onPositionChange && this.props.onPositionChange(this.value);
      })
      .catch(console.error);
    return "...";
  };

  render() {
    const { className, style, disabled } = this.props;

    return (
      <TextField
        variant="outlined"
        inputRef={this.inputRef}
        inputProps={{
          id: `googlePlaceSelector_${randomString()}`
        }}
        className={className}
        style={style}
        value={this.displayValue}
        disabled={disabled}
        onChange={this.handleChange}
        InputProps={{
          endAdornment: !isEmpty(this.value) && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={this.handleClear}
              >
                <Icon size={24} color="#0000008a" name="close" />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  }
}

export default GooglePlaceSelector;
