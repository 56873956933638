import React from "react";
import { SetupScreen } from "./view";
import { SetupController } from "./controller";
import { styles } from "./styles";

export class Setup extends React.Component {
  static navigationOptions = {
    title: "Setup"
  };

  controller = new SetupController(this.props);

  render() {
    return (
      <SetupScreen
        {...this.props}
        styles={styles}
        controller={this.controller}
      />
    );
  }
}
