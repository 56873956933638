import { stateController } from '../../cdm/controllers/state-controller';
import { computed } from 'mobx';
import { Platform } from 'react-native';

export class DrawerContainerController {
  isWeb = Platform.OS === "web";

  constructor(props) {
    this.props = props;
  }

  @computed get touch() {
    return stateController.drawerTouch;
  }

  @computed get drawerState() {
    return !this.isWeb || stateController.showDrawer;
  }

  @computed get docked() {
    // return stateController.isDesktop;
    return false;
  }

  toggleDrawer = open => {
    stateController.showDrawer = open;
  };
}
