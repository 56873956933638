import React from "react";
import { CareReceiverReportListView } from "./view";
import { CareReceiverReportListController } from "./controller";
import { styles } from "./styles";

export class CareReceiverReportList extends React.Component {
  render() {
    return (
      <CareReceiverReportListView
        {...this.props}
        controller={new CareReceiverReportListController(this.props)}
        styles={styles}
      />
    );
  }
}
