import React from "react";
import { observer } from "mobx-react";
import { Text, View } from "react-native";
import { UIText } from "../../config/lang-config";

@observer
class ListFilterIndicatorView extends React.Component {
  render() {
    const hasFilter =
      this.props.categories &&
      this.props.categories.find(f => !!f.selected && f.selected !== f.default);

    return (
      <View style={this.props.styles.style}>
        {hasFilter && (
          <View style={this.props.styles.container}>
            <Text style={this.props.styles.errorMessage}>
              {`${UIText.filterApplied} - ${UIText.filterShowing} ${
                this.props.length
              }`}
            </Text>
          </View>
        )}
      </View>
    );
  }
}

export { ListFilterIndicatorView };
