import {
  groupTypeIds,
  groupTypeRoleIds,
  rnrEnabledRoleGroupTypeIds,
  RoleGroupGroupTypeIds,
  serviceProviderGroupTypeIds,
  serviceRecipientGroupTypeIds,
  topicTypeIds,
  typeClassIds
} from "../../../config/variable-config";
import { apiService } from "../../../cdm/services/api-service";
import { endpointConfig } from "../../../config/api-config";
import { clientController } from "../../../cdm/controllers/client-controller";
import { isEmpty } from "../../../utils/helpers";
import { apiController } from "../../../cdm/controllers/api-controller";
import { UIText } from "../../../config/lang-config";
import { RatingReviewMarketStars } from "../components/RatingReviewMarketStars";
import { RatingReviewDetailEntryLink } from "../components/RatingReviewDetailEntryLink";
import { RatingReviewDetailFormInsert } from "../components/RatingReviewDetailFormInsert";
import { stateController } from "../../../cdm/controllers/state-controller";
import { computedFn } from "mobx-utils";
import { ProfileBookingLink } from "../components/ProfileBookingLink";
import { CaregiverAvailabilityForm } from "../components/CaregiverAvailabilityForm";
import { CareReceiverLocationField } from "../components/CareReceiverLocationField";
import { minimizeString } from "../utils/helper";

export const createGroupProfile = async (group, profileDetail, role, store) => {
  let profile = {
    type: 3, // Group Member shared profile
    data: JSON.stringify(profileDetail),
    typeClassId: typeClassIds.careReceiverProfile,
    typeClassVersion: 3 // Default version for now
  };
  return await apiService
    .async("POST", {
      endpoint: endpointConfig.create_group_profile,
      data: {
        group,
        profile
      }
    })
    .then(response => {
      const group = apiController.parser.parseGroup(response.data);
      if (!group)
        return Promise.reject({
          message:
            "Something happened and setup was not successful. [Empty group data]"
        });
      if (store) store.groupId = group.id;
      return [group, profileDetail, role];
    });
};

export const createSelfMember = async ([
  group,
  profileDetail,
  roles,
  selfRoleTypeId
]) => {
  selfRoleTypeId = selfRoleTypeId || groupTypeRoleIds.primaryFamilyCaregiver;

  const groupId = group.id;
  if (!groupId)
    return Promise.reject({
      message:
        "Something happened and setup was not successful. [groupId is null]"
    });
  const member = {
    userId: clientController.userId,
    groupId: groupId,
    email: clientController.defaultMember.email,
    phone: clientController.defaultMember.phone
  };
  const profile = {
    userId: clientController.userId,
    type: 2,
    data: JSON.stringify({
      email: clientController.defaultMember.profile.data.email,
      phone: clientController.defaultMember.profile.data.phone,
      firstName: clientController.defaultMember.profile.data.firstName,
      lastName: clientController.defaultMember.profile.data.lastName,
      displayName: clientController.defaultMember.profile.data.displayName,
      address: clientController.defaultMember.profile.data.address
    }),
    typeClassId: typeClassIds.familyMemberProfile,
    typeClassVersion: 1 // Default version for now
  };
  const roleIdList = [
    roles.find(role => role["groupTypeRoleId"] === selfRoleTypeId).id
  ];

  return await apiService
    .async("POST", {
      endpoint: endpointConfig.create_member_profile,
      data: {
        member,
        profile,
        roleIdList
      }
    })
    .then(response => {
      // console.log(response);
      return [group, profileDetail, roles];
    });
};

export const createCareReceiverMember = async ([
  group,
  profileDetail,
  roles
]) => {
  const groupId = group.id;
  if (!groupId)
    return Promise.reject({
      message:
        "Something happened and setup was not successful. [groupId is null]"
    });

  const member = {
    groupId: groupId, // 3 === Primary Family Caregiver
    profileId: group.profileId,
    email: profileDetail.email,
    phone: profileDetail.phone
  };
  const roleIdList = [roles.find(role => role["groupTypeRoleId"] === 5).id]; // 3 === Primary Family Caregiver

  return await apiService
    .async("POST", {
      endpoint: endpointConfig.create_member,
      data: {
        member,
        roleIdList
      }
    })
    .then(() => groupId);
};

export const isGroupSubscriptionMsgEnabled = group =>
  group.subscription &&
  group.subscription.plan &&
  group.subscription.plan.isMarketplaceMessageEnabled;

export const matchCareReceiver = string =>
  typeof string === "string" &&
  string.match(/Care Recipient|Care Receiver|Care Circle/g);

// Popup for messaging a topic without subscription
export const isCcSubscriptionTopic = (group, topic) =>
  !isEmpty(topic) &&
  topic.typeId === topicTypeIds.candidate &&
  !isEmpty(group) &&
  serviceRecipientGroupTypeIds.includes(group.typeId);

export const paidCaregiverFeatureRestricted = (group, member) => {
  const isSolePaidCaregiver = () =>
    Array.isArray(member.roleList) &&
    member.roleList.length === 1 &&
    member.roleList.some(
      role => role.groupTypeRoleId === groupTypeRoleIds.paidCaregiver
    );

  return (
    !isEmpty(group) &&
    !isEmpty(member) &&
    serviceRecipientGroupTypeIds.includes(group.typeId) &&
    isSolePaidCaregiver()
  );
}; // Currently restricted features are
// Shift Maker page
// Marketplace page
// PlanScreen
// Access member menu (role, owner change)
// Modify member profile
// Invite group member

export const isOwnerOrPrimaryCaregiverOrSupport = (group, member) => {
  if (!group && !member) return false;
  const owner = group && group.owner;
  const userId = member && member.userId;
  const isOwner = owner === userId;
  const isPrimary =
    Array.isArray(member.roleList) &&
    member.roleList.some(
      role => role.groupTypeRoleId === groupTypeRoleIds.primaryFamilyCaregiver
    );
  const isSupport =
    Array.isArray(member.roleList) &&
    member.roleList.some(role =>
      [groupTypeRoleIds.careConcierge, groupTypeRoleIds.support].includes(
        role.groupTypeRoleId
      )
    );

  return isOwner || isPrimary || isSupport;
};

export const isServiceGroup = group => {
  if (isEmpty(group) || !group.typeId) return false;
  return [...serviceRecipientGroupTypeIds, ...RoleGroupGroupTypeIds].includes(
    group.typeId
  );
};

export const isServiceProviderGroup = group => {
  if (isEmpty(group) || !group.typeId) return false;
  return serviceProviderGroupTypeIds.includes(group.typeId);
};

// TODO: Generalize and re-consolidate
// export const isCareCircleGroup = group => {
//   if (isEmpty(group) || !group.typeId) return false;
//   return group.typeId === groupTypeIds.careReceiver;
// };

export const careCirclePayrollHide = (
  group,
  selfMember,
  member,
  profileId,
  profile
) => {
  if (isEmpty(selfMember)) return;
  if (isEmpty(member)) return;
  if (isEmpty(profile)) return;
  const roles = selfMember.roleList;
  if (isEmpty(roles)) return;
  const isSelfProfile = selfMember.profileId === profileId;
  const isPaidCaregiver = roles.some(
    r => r.groupTypeRoleId === groupTypeRoleIds.paidCaregiver
  );
  const isMaster = isOwnerOrPrimaryCaregiverOrSupport(group, selfMember);
  const isOtherMembers = !isMaster && !isPaidCaregiver;
  const isViewingPaidCaregiver =
    member.roleList &&
    member.roleList.some(
      r => r.groupTypeRoleId === groupTypeRoleIds.paidCaregiver
    );
  const rateFields = profile.filter(
    field => field.flags && !!field.flags["hourlyRate"]
  );
  for (const field of rateFields) {
    if (
      isOtherMembers ||
      !isViewingPaidCaregiver ||
      (isPaidCaregiver && !isSelfProfile)
    ) {
      field.disabled = true;
      field.hidden = true;
    }
  }
};

export const isHourlyRateProfile = profile => {
  const { typeClassId } = profile;
  return (
    typeClassId === typeClassIds.paidCaregiverProfile ||
    typeClassId === typeClassIds.familyMemberProfile
  );
};

// export const careCircleNegotiatedPriceAuto = (
//   selfMember,
//   profileId,
//   profile
// ) => {
//   const isSelfProfile = selfMember.profileId === profileId;
//   const isValidTypeClass = isHourlyRateProfile(profile);
//   if (!isSelfProfile || !isValidTypeClass) return;
//   const negotiatedHourlyRate = profile.find(
//     f => f.name === "negotiatedHourlyRate"
//   );
//   if (
//     negotiatedHourlyRate.value === false ||
//     isNaN(Number(negotiatedHourlyRate.value))
//   ) {
//     const myRequiredHourlyRate = profile.find(
//       f => f.name === "myRequiredHourlyRate"
//     );
//     negotiatedHourlyRate.value = myRequiredHourlyRate.value;
//   }
// };

export const careCircleNegotiatedPriceBlurHandler = form => {
  const myRequiredHourlyRate = form.find(
    f => f.name === "myRequiredHourlyRate"
  );
  if (!!myRequiredHourlyRate && myRequiredHourlyRate.value) {
    const negotiatedHourlyRate = form.find(
      f => f.name === "negotiatedHourlyRate"
    );
    if (
      negotiatedHourlyRate &&
      (!negotiatedHourlyRate.value || Number(negotiatedHourlyRate.value) === 0)
    ) {
      negotiatedHourlyRate.value = myRequiredHourlyRate.value;
    }
  }
};

export const getServiceGroupTypeToGroupTypeRoleMatchMap = () => {
  const result = {};
  for (const groupTypeId of serviceProviderGroupTypeIds) {
    const keyName = Object.keys(groupTypeIds).find(
      k => groupTypeIds[k] === groupTypeId
    );
    result[groupTypeId] = groupTypeRoleIds[keyName];
  }
  return result;
};

export const matchServiceGroupTypeRoleByGroupTypeId = groupTypeId => {
  const map = getServiceGroupTypeToGroupTypeRoleMatchMap();
  return map[groupTypeId];
};

export const getServiceProviderGroupProfileFlags = () => {
  const result = {};
  const map = getServiceGroupTypeToGroupTypeRoleMatchMap();
  for (const id of Object.values(map)) {
    result[id] = ["caregiver"];
  }
  return result;
};

export const canCreateCaregiverOrCleanerGroup = () => {
  const allGroups = clientController.findVisibleGroups();
  return !allGroups.some(group => group.typeId === groupTypeIds.caregiver);
};

export const isNewBillingTypeGroup = group => {
  // TODO: Here should be logic to determine between new subscription or legacy subscription.
  return group.typeId === groupTypeIds.caregiver;
};

export const addRatingToProfile = (
  group,
  ratingScore,
  achievement
) => fields => {
  const indexOfYoEField = fields.findIndex(
    field =>
      field.name === "yearsOfExperienceAsCaregiver" ||
      field.name === "caregiverYearsOfExperienceAsCaregiver"
  );
  if (indexOfYoEField < 0) return;
  const { samples, rating } = ratingScore;
  const ratingField = {
    name: "aggregatedRating",
    placeholder: `${UIText.ratingReviewRating}`,
    type: "custom",
    groupTypeIds: [3, 8, 9, 10],
    flags: {
      caregiver: true,
      marketplace: true
    },
    value: RatingReviewMarketStars({
      achievement,
      loading: false,
      samples,
      rating,
      onPress: () => openRatingDetailByGroupId(group.id)
    })
  };
  const reviewEntryField = {
    name: "reviewEntry",
    placeholder: `${UIText.ratingReviewReviews}`,
    type: "custom",
    groupTypeIds: [3, 8, 9, 10],
    flags: {
      caregiver: true,
      marketplace: true
    },
    value: RatingReviewDetailEntryLink({
      groupId: group.id
    })
  };
  fields.splice(indexOfYoEField + 1, 0, reviewEntryField);
  fields.splice(indexOfYoEField + 1, 0, ratingField);
};

export const addBookingLinkToProfile = (
  organizerGroupId,
  profileId
) => fields => {
  const indexOfYoEField = fields.findIndex(
    field => field.name === "reviewEntry"
  );
  if (indexOfYoEField < 0) return;
  const link = `https://${
    endpointConfig.wp_hostname
  }/home-care-service?bfpid=${profileId}&bfgid=${organizerGroupId}`;
  const bookingLinkField = {
    name: "bookingEntry",
    placeholder: "Get connected",
    type: "custom",
    groupTypeIds: [3],
    flags: {
      caregiver: true,
      marketplace: true
    },
    value: ProfileBookingLink({ link })
  };
  fields.splice(indexOfYoEField + 1, 0, bookingLinkField);
};

export const addAvailabilityFormToProfile = (profileForm, screenId) => {
  const indexOfCarField = profileForm.findIndex(
    field => field.name === "ableCar"
  );
  const availabilityForm = profileForm.find(
    field => field.name === "availabilityForm"
  );
  if (availabilityForm) return;
  if (indexOfCarField < 0) return;
  const availabilityFormField = {
    name: "availabilityForm",
    placeholder: "Availability",
    type: "custom",
    groupTypeIds: [3],
    flags: {
      caregiver: true
    },
    value: CaregiverAvailabilityForm({ profileForm, screenId })
  };
  profileForm.splice(indexOfCarField + 1, 0, availabilityFormField);
};

export const addLocationFieldToProfile = (
  profileForm,
  screenStates,
  editable
) => {
  const editLocation = profileForm.find(field => field.name === "editLocation");
  if (!isEmpty(editLocation)) return;
  const indexOfLocationField = profileForm.findIndex(
    field => field.name === "location"
  );
  if (indexOfLocationField < 0) return;
  const locationLinkField = {
    icon: "place",
    name: "editLocation",
    placeholder: "Location",
    type: "custom",
    groupTypeIds: [2],
    flags: {
      general: true
    },
    value: CareReceiverLocationField({ profileForm, editable }),
    noStyles: true
  };
  profileForm.splice(indexOfLocationField + 1, 0, locationLinkField);
};

export const validateAvailabilityForm = profileForm => {
  const availabilityForm = profileForm.find(
    field => field.name === "availabilityForm"
  );
  if (!availabilityForm) return true;
  const availability = profileForm.find(field => field.name === "availability");
  const availabilityLocations = profileForm.find(
    field => field.name === "availabilityLocations"
  );
  const availabilitySets = availability.value || [];
  const locations = availabilityLocations.value || [];
  if (isEmpty(availabilitySets) && isEmpty(locations)) {
    availability.errorMessage = "Please fill in your availability.";
    return false;
  }
  if (
    availabilitySets.some(
      a =>
        isNaN(a.dayOfWeek) ||
        !a.start ||
        !a.end ||
        isNaN(a.location) ||
        Number(a.location) === -1 ||
        isNaN(a.maxCommuteMinutes)
    )
  ) {
    availability.errorMessage =
      "Please complete all fields of your availabilities.";
    return false;
  }
  return true;
};

export const openRatingDetailByGroupId = groupId => {
  const form = [
    {
      name: "ratingReviewDetails",
      placeholder: `${UIText.ratingReviewReviews}:`,
      type: "custom",
      value: RatingReviewDetailFormInsert({
        groupId
      }),
      styles: {
        marginHorizontal: 0
      }
    }
  ];
  return stateController.showPopup({
    title: UIText.ratingReviews,
    form,
    formShowLabel: false,
    formShowTitle: false,
    dismissOnBackPress: true,
    leftButtonText: UIText.generalDone
  });
};

export const isRnREnabledRoleGroup = group =>
  rnrEnabledRoleGroupTypeIds.includes(group.typeId);

export const getRatingAggregatedScoreAndSamples = computedFn(
  aggregatedRatingReview => {
    if (!aggregatedRatingReview) return { samples: 0, rating: 0 };
    const { reviews, testimonials } = aggregatedRatingReview;
    return {
      samples: (reviews || []).length + (testimonials || []).length, // The number of admin ratings will not count
      rating: aggregatedRatingReview.aggregatedRating // But aggregated rating will factor in admin rating scores.
    };
  }
);

export const matchOSMProvinceWithField = (provinceField, locationData) => {
  if (isEmpty(provinceField) || isEmpty(locationData)) return;
  const { address } = locationData || {};
  if (isEmpty(address)) return;
  const province = address.state;
  if (!province) return;
  const iso31662Lvl4 = address["ISO3166-2-lvl4"];
  const potentialProv1 = iso31662Lvl4 && iso31662Lvl4.split("-")[1];
  const provOption = (provinceField.options || []).find(
    o =>
      o.name === potentialProv1 ||
      minimizeString(o.placeholder) === minimizeString(province)
  );
  if (!isEmpty(provOption)) return provOption.name;
};

export const matchOSMCityWithTimeZone = (timezoneField, locationData) => {
  if (isEmpty(timezoneField) || isEmpty(locationData)) return;
  const { address } = locationData || {};
  if (isEmpty(address)) return;
  const city = address.city || address.town;
  const timezoneOption = (timezoneField.options || []).find(o =>
    minimizeString(city).includes(minimizeString(o.name.split("/")[1]))
  );
  if (!isEmpty(timezoneOption)) return timezoneOption.name;
};

// export const showLocalTime = profileData => {
//   return `${getCurrentCareCircleDateTimeString(profileData.timezone)} - ${
//     profileData.timezone
//   }`;
// };
//
// export const getCurrentCareCircleDateTimeString = timezone => {
//   return moment.tz(moment(), timezone).format("DD/MM/YYYY, h:mm:ss a");
// };
