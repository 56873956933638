import { NavigationActions, StackActions } from "@react-navigation/core";

let _navigator;
let navigatorReady = false;

const isReady = () => navigatorReady;

const setTopLevelNavigator = navigatorRef => {
  _navigator = navigatorRef;
  navigatorReady = true;
};

const navigate = (routeName, params) => {
  const dispatch = _navigator["_dispatch"] || _navigator["dispatch"];
  dispatch(
    NavigationActions.navigate({
      routeName,
      params
    })
  );
};

const push = (routeName, params) => {
  const dispatch = _navigator["_dispatch"] || _navigator["dispatch"];
  dispatch(
    StackActions.push({
      routeName,
      params
    })
  );
};

const pop = () => {
  const dispatch = _navigator["_dispatch"] || _navigator["dispatch"];
  dispatch(StackActions.pop());
};

// const getNavigator = () => _navigator;
//
// const getCurrentRouteName = (navState) => {
//   if (navState.hasOwnProperty('index')) {
//     getCurrentRouteName(navState.routes[navState.index])
//   } else {
//     return navState.routeName;
//   }
// };

export default {
  // getNavigator,
  // getCurrentRouteName,
  setTopLevelNavigator,
  isReady,
  navigate,
  push,
  pop
};
