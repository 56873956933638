import React from "react";
import {
  ActivityIndicator,
  Picker,
  Platform,
  RefreshControl,
  ScrollView,
  TouchableOpacity,
  View
} from "react-native";
import { Observer, observer } from "mobx-react";
import { TabHeader } from "../../../../components/TabHeader/index";
import { theme } from "../../../../config/style-configs/theme";
import { CheckBox, Icon, Text } from "react-native-elements";
import { ThemedButton } from "../../../../components/ThemedButton/index";
import {
  abbvLastNameDisplayNameEng,
  getDisplayNameEng,
  ipsum,
  isEmpty,
  safeParseJSON
} from "../../../../utils/helpers";
import { UIText } from "../../../../config/lang-config";
import { Form } from "../../../../components/Form/index";
import { TopicCard } from "../../../../components/TopicCard/index";
import { ListFilter } from "../../../../components/ListFilter";
import { ListFilterIndicator } from "../../../../components/ListFilterIndicator";
import { TextIcon } from "../../../../components/TextIcon";
import { ListRefreshButtonWeb } from "../../../../components/ListRefreshButtonWeb";
import { AnimatedBottomBanner } from "../../../../components/AnimatedBottomBanner";
import { Tooltip } from "../../../../components/Tooltip";
import { RatingReviewMarketStars } from "../RatingReviewMarketStars";
import { isSnSOrAdmin } from "../../utils/helper";
import { openRatingDetailByGroupId } from "../../lib/group-utilities-mcb";
import { fileService } from "../../../../cdm/services/file-service";
import VizSensor from "react-visibility-sensor";

import { action } from "mobx";
import { computedFn } from "mobx-utils";
import { achievementColors } from "../../../../config/variable-config";
import workspacePremium from "../../assets/workspacePremium";

const CriteriaButtons = observer(props => (
  <View style={props.containerStyle}>
    {!props.isVisitor && (
      <ThemedButton
        visible={!props.scratchpad}
        disabled={!props.dirty && !props.partialDirty}
        style={props.buttonStyle}
        title={UIText.marketResetCriteria}
        onPress={props.reset}
      />
    )}
    {!props.isVisitor && (
      <ThemedButton
        disabled={!props.dirty && !props.scratchpad}
        style={props.buttonStyle}
        title={
          props.scratchpad
            ? UIText.marketSaveCriteriaScratchpad
            : UIText.marketSaveCriteria
        }
        onPress={props.save}
      />
    )}
    <ThemedButton
      style={props.buttonStyle}
      title={UIText.marketSeeSearchResults}
      onPress={props.toResult}
    />
  </View>
));

@observer
class GroupMarketPageView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
    this.formFields = [];
    this.fieldProps = {
      showLabel: false,
      showTitle: false,
      enabledGetter: computedFn(field => field.name !== "email"),
      valueGetter: computedFn(field => field.value || ""),
      showAdditionalCheckbox: computedFn(field =>
        this.controller.fieldShouldShowMandatory(field)
      ),
      additionalCheckedIcon: {
        name: "toggle-switch",
        size: 40,
        color: theme.color
      },
      additionalUncheckedIcon: {
        name: "toggle-switch-off",
        size: 40,
        color: "#666"
      },
      additionalCheckboxTitle: UIText.marketMandatorySwitchTitle,
      additionalCheckboxDisabled: computedFn(field =>
        this.controller.fieldIsMandatoryDisabled(field)
      ),
      additionalCheckboxChecked: computedFn(field =>
        this.controller.fieldIsMandatory(field)
      ),
      onAdditionalCheckboxPress: action((e, field) => {
        this.controller.toggleMandatoryField(e, field);
      }),
      onInputChange: action((e, field) => {
        this.controller.setCriteriaDirty(true);
        field.value = e.nativeEvent.text;
        this.controller.syncMandatoryToggleField(field);
      }),
      onCheckboxChange: action(field => {
        this.controller.setCriteriaDirty(true);
        field.value = !field.value;
        this.controller.syncMandatoryToggleField(field);
      }),
      onPickerChange: action((value, field) => {
        this.controller.setCriteriaDirty(true);
        field.value = value;
      }),
      onSubmitEditing: action((form, field) => {
        this.controller.helpFocusNextField(field, this.formFields, form);
      }),
      refSetter: (form, field, f) => {
        this.formFields[form.indexOf(field)] = f;
      },
      fieldStyle: !this.controller.hideMandatoryCriteriaToggles && {
        maxWidth: "75%"
      }
    };
  }

  componentWillUnmount() {
    this.controller.componentWillUnmount();
  }

  componentDidUpdate() {
    const scrollPos = this.controller.scrollPos[this.controller.currentTab];
    this.scrollView.scrollTo({ y: scrollPos || 0, animated: false });
  }

  ipsum = ipsum();

  getHourlyRate = profile =>
    this.controller.getCaregiverHourlyRate(profile) || UIText.marketRateNotSet;

  getAvatarAppendix = (group, profile) => {
    const { samples, rating } = this.controller.findGroupRatingScore(
      group.profile
    );
    const yearsOfExperiences =
      profile.yearsOfExperienceAsCaregiver &&
      Number(profile.yearsOfExperienceAsCaregiver);
    const achievement = this.controller.findGroupAchievement(group);
    return (
      <Observer>
        {() => (
          <View style={{ minWidth: "166px" }}>
            <RatingReviewMarketStars
              achievement={achievement}
              samples={samples}
              rating={rating}
              loading={this.controller.isRatingLoading(group.profile)}
              onPress={() => openRatingDetailByGroupId(group.id)}
            />
            {!!yearsOfExperiences && (
              <Text style={this.props.styles.cardHourlyRate}>
                {UIText.marketYoE(yearsOfExperiences)}
              </Text>
            )}
            <Text style={this.props.styles.cardHourlyRate}>
              {this.getHourlyRate(group.profile && group.profile.data)}
            </Text>
            {isSnSOrAdmin(this.controller.user) && (
              <View>
                <Text style={this.props.styles.cardHourlyRate}>
                  {`Rank: ${group.rank || profile.rank || "—"}`}&nbsp;|&nbsp;
                  {`Score: ${group.total || profile.score || "—"}`}
                </Text>
              </View>
            )}
          </View>
        )}
      </Observer>
    );
  };

  // renderVisitorHeading = () => (
  //   <Text
  //     style={{
  //       ...this.props.styles.pickerLabel,
  //       ...{ fontSize: theme.FONT_SIZE_MEDIUM }
  //     }}
  //     numberOfLines={6}
  //     ellipsizeMode="tail"
  //   >
  //     {`${UIText.marketVisitorInfo} `}
  //     <Text
  //       numberOfLines={1}
  //       ellipsizeMode="tail"
  //       onPress={this.controller.openMarketCompanyNumber}
  //       style={this.props.styles.linkBlue}
  //     >
  //       {planMarketingNumber}
  //     </Text>
  //     {`${UIText.marketVisitorInfo2} ${UIText.marketPlanInfo}`}
  //     <Text
  //       numberOfLines={1}
  //       ellipsizeMode="tail"
  //       onPress={this.controller.openPlanMarketingUrl}
  //       style={this.props.styles.linkBlue}
  //     >
  //       {UIText.planAndPricing}
  //     </Text>
  //     {`.`}
  //   </Text>
  // );

  renderVisitorHeading = () => (
    <Text
      style={{
        ...this.props.styles.pickerLabel,
        ...{ fontSize: theme.FONT_SIZE_MEDIUM }
      }}
      numberOfLines={6}
      ellipsizeMode="tail"
    >
      T.B.D.
    </Text>
  );

  renderNormalHeading = () => (
    <Text
      style={this.props.styles.pickerLabel}
      numberOfLines={2}
      ellipsizeMode="tail"
    >
      {UIText.marketShortListPickerLabel}
    </Text>
  );

  renderSearchMandatoryToggle = () => (
    <Observer>
      {() => (
        <TouchableOpacity
          style={this.props.styles.searchMandatoryContainer}
          onPress={this.controller.toggleSearchMandatory}
        >
          <>
            <CheckBox
              containerStyle={this.props.styles.searchMandatoryToggle}
              center
              checkedIcon={
                <Icon
                  name="toggle-switch"
                  type="material-community"
                  size={45}
                  color={theme.color}
                />
              }
              uncheckedIcon={
                <Icon
                  name="toggle-switch-off"
                  type="material-community"
                  size={45}
                  color="#666"
                />
              }
              checked={this.controller.searchMandatoryOnly}
              onPress={this.controller.toggleSearchMandatory}
            />
            <Text
              style={{
                ...this.props.styles.searchMandatoryText,
                color: this.controller.searchMandatoryOnly
                  ? theme.color
                  : "#666"
              }}
            >
              {UIText.marketSearchMandatoryOnly}
            </Text>
            <Tooltip
              title={UIText.marketMandatoryCriteria}
              content={UIText.marketMandatoryCriteriaSearchHelp}
            />
          </>
        </TouchableOpacity>
      )}
    </Observer>
  );

  renderComponent = tabKey => {
    const isNative = Platform.OS !== "web";
    if (tabKey === "criteria") {
      return this.controller.profileLoading ? (
        <View style={this.props.styles.loading}>
          {!isNative && <ActivityIndicator size="large" color={theme.color} />}
        </View>
      ) : (
        <View style={this.props.styles.scrollViewContent}>
          {this.renderCriteria(
            this.controller.criteriaSimplified
              ? this.controller.simplifiedRenderProfile
              : this.controller.shouldRenderProfile
          )}
        </View>
      );
    }
    if (tabKey === "search") {
      return this.controller.loading || this.controller.searching ? (
        <View style={this.props.styles.loading}>
          {!isNative && <ActivityIndicator size="large" color={theme.color} />}
        </View>
      ) : (
        <View style={this.props.styles.scrollViewContent}>
          {this.renderSearch()}
        </View>
      );
    }
    if (tabKey === "candidates") {
      return this.controller.loading || this.controller.searching ? (
        <View style={this.props.styles.loading}>
          {!isNative && <ActivityIndicator size="large" color={theme.color} />}
        </View>
      ) : (
        <View style={this.props.styles.scrollViewContent}>
          {this.renderShortlist()}
        </View>
      );
    }
  };

  renderCriteria = profile => {
    if (profile && Array.isArray(profile) && profile.length > 0) {
      return (
        <>
          <View style={this.props.styles.criteriaButtonContainerTop}>
            <Observer>
              {() => (
                <CriteriaButtons
                  containerStyle={this.props.styles.criteriaButtonContainer}
                  buttonStyle={this.props.styles.criteriaButton}
                  isVisitor={this.controller.isVisitor}
                  partialDirty={
                    !isEmpty(this.controller.mandatoryCriteriaFieldNames)
                  }
                  dirty={this.controller.criteriaDirty}
                  scratchpad={this.controller.isScratchpad}
                  reset={this.controller.resetCriteria}
                  save={this.controller.saveCriteria}
                  toResult={e =>
                    this.controller.handleTabChange({ key: "search" })
                  }
                />
              )}
            </Observer>
            <TouchableOpacity
              style={this.props.styles.mandatoryCriteriaLinkContainer}
              onPress={this.controller.handleMandatoryCriteriaPress}
            >
              <Text style={this.props.styles.mandatoryCriteriaLink}>
                {UIText.marketMandatoryCriteriaHeading}
              </Text>
              <Tooltip
                style={this.props.styles.mandatoryCriteriaTooltip}
                onPress={this.controller.handleMandatoryCriteriaPress}
              />
            </TouchableOpacity>
          </View>
          <Form form={profile} fieldProps={this.fieldProps} />
          {this.controller.isScratchpad ? (
            <Text
              style={this.props.styles.searchSimplifiedCriteriaText}
              onPress={this.controller.handleCriteriaSimplifiedPress}
            >
              {UIText.marketCriteriaSimplified(
                this.controller.criteriaSimplified
              )}
            </Text>
          ) : null}
          <Observer>
            {() => (
              <CriteriaButtons
                containerStyle={this.props.styles.criteriaButtonContainer}
                buttonStyle={this.props.styles.criteriaButton}
                isVisitor={this.controller.isVisitor}
                dirty={this.controller.criteriaDirty}
                scratchpad={this.controller.isScratchpad}
                reset={this.controller.resetCriteria}
                save={this.controller.saveCriteria}
                toResult={e =>
                  this.controller.handleTabChange({ key: "search" })
                }
              />
            )}
          </Observer>
        </>
      );
    }
  };

  renderSearch = () => (
    <>
      <View style={this.props.styles.buttonContainer}>
        {!this.controller.isVisitor && (
          <>
            <ListFilter
              title={UIText.marketSearchResults}
              data={this.controller.caregiverGroups}
              categories={this.controller.caregiverFilterCategories}
              filterSetter={this.controller.setCaregiverFilter}
            />
            <ThemedButton
              width={140}
              title={UIText.marketAddAllToShortlist}
              loading={this.controller.addingAllToShortlist}
              onPress={this.controller.addAllToShortlist}
            />
          </>
        )}
        <ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />
      </View>
      <View style={this.props.styles.buttonContainer}>
        {this.renderSearchMandatoryToggle()}
      </View>
      <ListFilterIndicator
        length={this.controller.filteredCaregiverGroups.length}
        categories={this.controller.caregiverFilterCategories}
      />
      <View style={this.props.styles.buttonReminder}>
        {!this.controller.isVisitor && (
          <Text style={this.props.styles.errorMessage}>
            {UIText.marketShortlistReminder}
          </Text>
        )}
      </View>
      <View style={this.props.styles.scrollViewCard}>
        {this.renderCaregivers(this.controller.filteredCaregiverGroups)}
      </View>
    </>
  );

  renderShortlist = () => (
    <>
      <View style={this.props.styles.buttonContainer}>
        <ListFilter
          data={this.controller.currentCandidateTopics}
          categories={this.controller.candidateFilterCategories}
          filterSetter={this.controller.setCandidateFilter}
        />
        {this.controller.isMCBGroup && (
          <ThemedButton
            width={200}
            title={UIText.marketAdminBroadcastShortlist}
            // loading={this.controller.addingAllToShortlist}
            onPress={this.controller.handleAdminBroadcastPress}
          />
        )}
        <ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />
      </View>
      <ListFilterIndicator
        length={this.controller.currentFilteredCandidateTopics.length}
        categories={this.controller.candidateFilterCategories}
      />
      <View style={this.props.styles.scrollViewCard}>
        {this.renderCandidates(this.controller.currentFilteredCandidateTopics)}
      </View>
    </>
  );

  renderCaregivers = groups => {
    if (!Array.isArray(groups) || groups.length === 0) {
      return (
        <Text style={this.props.styles.errorMessage}>
          {this.controller.loading ? "" : UIText.marketSearchNoMatch}
        </Text>
      );
    }

    return groups.map(group => (
      <Observer key={group.id}>
        {() => {
          const profile = (group.profile && group.profile.data) || {};

          const achievement = this.controller.findGroupAchievement(group);

          if (!profile.displayName) return <View key={group.id} />;

          if (!this.controller.getCaregiverHourlyRate(profile)) return null;

          let cardContent = this.renderCardContent(profile);

          const cardPress = () => this.controller.handleCardPress(group);

          const icons = [
            {
              key: "profile",
              name: "account-card-details",
              onPress: cardPress
            }
          ];

          const achievementBadgeStyles = (achievement || {})
            .achievementLevel && {
            boxShadow: `0 0 10px ${
              achievement.achievementLevel === "silver"
                ? "#aabcc9"
                : achievementColors[achievement.achievementLevel]
            }${achievement.achievementLevel === "silver" ? "" : "80"}`,
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${encodeURIComponent(
              `${achievementColors[achievement.achievementLevel]}26`
            )}">${workspacePremium}</svg>')`,
            backgroundPosition: "70px 85px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "360px"
          };

          return (
            <View key={group.id} style={this.props.styles.card}>
              <VizSensor
                partialVisibility
                onChange={visible =>
                  visible && this.controller.setVisible(group.id)
                }
              >
                <TopicCard
                  id={group.id}
                  title={
                    this.controller.isMCBGroup
                      ? getDisplayNameEng(profile)
                      : abbvLastNameDisplayNameEng(getDisplayNameEng(profile))
                  }
                  titleId={group.profileId}
                  image={fileService.getProfileAvatarUri(
                    profile.avatar,
                    group.id,
                    "group"
                  )}
                  avatarAppendix={this.getAvatarAppendix(group, profile)}
                  customIcons={icons}
                  checked={this.controller.getCandidateChecked(group)}
                  pending={
                    group.inShortlistPending || this.controller.checkboxLoading
                  }
                  style={{
                    ...((achievement || {}).achievementLevel &&
                      achievementBadgeStyles)
                  }}
                  onPress={cardPress}
                  onCheckPress={
                    !this.controller.isVisitor &&
                    (() => this.controller.handleCheckboxChange(group))
                  }
                  children={cardContent}
                />
              </VizSensor>
            </View>
          );
        }}
      </Observer>
    ));
  };

  renderCandidates = candidateTopics => {
    if (!Array.isArray(candidateTopics) || candidateTopics.length === 0)
      return (
        <Text style={this.props.styles.errorMessage}>
          {this.controller.loading ? "" : UIText.marketCandidateNoMatch}
        </Text>
      );

    return candidateTopics.map(topic => {
      const group =
        this.controller.combinedCaregiverGroups.find(g =>
          topic["groupIdList"].includes(g.id)
        ) || {};

      const profile = (group.profile && group.profile.data) || {};

      if (!profile.displayName) return <View key={topic.id} />;

      let cardContent = this.renderCardContent(profile);

      const cardPress = () =>
        this.controller.handleCandidatePress(topic.id, group);

      const icons = [
        !topic.isCompleted &&
          !this.controller.isMCBGroup && {
            key: "hire",
            styles: {
              marginRight: 2,
              paddingTop: 1
            },
            component: <TextIcon value={UIText.marketHire.toUpperCase()} />,
            onPress: () => this.controller.hire(group.id, topic.id)
          },
        !this.controller.isMCBGroup && {
          key: "message",
          name: !!topic.lastPublicMessageTime ? "message-text" : "message",
          styles: { paddingTop: 4 },
          onPress: () => this.controller.openChat(topic.id)
        },
        {
          key: "profile",
          name: "account-card-details",
          onPress: () => this.controller.handleCardPress(group)
        }
      ].filter(Boolean);

      return (
        <View key={topic.id} style={this.props.styles.card}>
          <TopicCard
            title={
              this.controller.isMCBGroup
                ? getDisplayNameEng(profile)
                : abbvLastNameDisplayNameEng(getDisplayNameEng(profile))
            }
            titleId={group.profileId}
            image={fileService.getProfileAvatarUri(
              profile.avatar,
              group.id,
              "group"
            )}
            avatarAppendix={this.getAvatarAppendix(
              group,
              safeParseJSON(topic.data)
            )}
            customIcons={icons}
            checked={true}
            // checkedIcon={topic.lastPublicMessageTime ? "archive" : "minus-box"}
            checkedIcon={"minus-box"}
            // checkedIconColor={
            //   topic.lastPublicMessageTime ? "#666" : theme.generalDenyColor
            // }
            checkedIconColor={theme.generalDenyColor}
            // checkedIconCursor={topic.lastPublicMessageTime && "not-allowed"}
            chatHasMessages={topic.lastPublicMessageTime}
            pending={
              group.inShortlistPending || this.controller.checkboxLoading
            }
            onPress={cardPress}
            onCheckPress={
              !topic.isCompleted &&
              (() => this.controller.handleTopicRemove(topic.id, group))
            }
            children={cardContent}
          />
        </View>
      );
    });
  };

  renderCardContent = profile => {
    const isUnapproved = !profile["approvalOfCandidateConfirmedByReference"];
    const showUnapproved = isSnSOrAdmin(this.controller.user) && isUnapproved;
    const text = profile["introduction"] || profile["oldIntroduction"];
    return (
      <View style={{ width: "100%" }}>
        {showUnapproved && (
          <Text style={this.props.styles.cardAdminUnapprovedLabel}>
            UNAPPROVED
          </Text>
        )}
        <Text
          style={{
            ...this.props.styles.cardText,
            ...(!text && { color: "#999" }),
            ...(showUnapproved && { marginTop: 0 })
          }}
          numberOfLines={showUnapproved ? 4 : 6}
          ellipsizeMode="tail"
        >
          {text || UIText.marketIntroduction}
        </Text>
      </View>
    );
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        {!this.controller.isVisitor && (
          <View style={this.props.styles.heading}>
            {this.renderNormalHeading()}
            <View style={this.props.styles.pickerContainer}>
              <Picker
                selectedValue={this.controller.currentShortlistId}
                style={this.props.styles.picker}
                onValueChange={this.controller.handleShortlistChange}
              >
                {this.controller.shortlistItems.map((list, i) => (
                  <Picker.Item key={i} label={list.label} value={list.value} />
                ))}
              </Picker>
              <ThemedButton
                style={{ marginRight: 0 }}
                width={90}
                height={30}
                icon="playlist-play"
                title="Actions"
                loading={this.controller.loading || this.controller.editLoading}
                onPress={this.controller.editShortlist}
              />
            </View>
          </View>
        )}
        {/*<View style={this.props.styles.heading}>*/}
        {/*{this.controller.isVisitor*/}
        {/*? this.renderVisitorHeading()*/}
        {/*: this.renderNormalHeading()}*/}
        {/*{!this.controller.isVisitor && (*/}
        {/*<View style={this.props.styles.pickerContainer}>*/}
        {/*<Picker*/}
        {/*selectedValue={this.controller.currentShortlistId}*/}
        {/*style={this.props.styles.picker}*/}
        {/*onValueChange={this.controller.handleShortlistChange}*/}
        {/*>*/}
        {/*{this.controller.shortlistItems.map((list, i) => (*/}
        {/*<Picker.Item key={i} label={list.label} value={list.value} />*/}
        {/*))}*/}
        {/*</Picker>*/}
        {/*<ThemedButton*/}
        {/*style={{ marginRight: 0 }}*/}
        {/*width={90}*/}
        {/*height={30}*/}
        {/*icon="playlist-play"*/}
        {/*title="Actions"*/}
        {/*loading={this.controller.loading || this.controller.editLoading}*/}
        {/*onPress={this.controller.editShortlist}*/}
        {/*/>*/}
        {/*</View>*/}
        {/*)}*/}
        {/*</View>*/}
        <TabHeader
          tabs={this.controller.topTabs}
          currentKey={this.controller.currentTab}
        />
        <ScrollView
          ref={ref => (this.scrollView = ref)}
          scrollEventThrottle={100}
          onScroll={this.controller.onScroll}
          contentContainerStyle={this.props.styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={
                this.controller.loading ||
                this.controller.searching ||
                this.controller.profileLoading
              }
              onRefresh={this.controller.onRefresh}
            />
          }
        >
          {this.controller.errorMessage ? (
            <View style={this.props.styles.scrollViewContent}>
              <Text style={this.props.styles.errorMessage}>
                {this.controller.errorMessage}
              </Text>
            </View>
          ) : (
            this.renderComponent(this.controller.currentTab)
          )}
        </ScrollView>

        {this.controller.isVisitor && (
          <AnimatedBottomBanner
            height={120}
            visible={this.controller.tncBannerVisible}
          >
            <View style={this.props.styles.tncBanner}>
              <Text style={this.props.styles.tncBannerText} numberOfLines={2}>
                {`${UIText.visitorMarketTncText} `}
                <Text
                  numberOfLines={1}
                  onPress={this.controller.openAgreement}
                  style={this.props.styles.linkBlue}
                >
                  {UIText.resetAgreeTerms}
                </Text>
                {`.`}
              </Text>
              <ThemedButton
                containerStyle={this.props.styles.tncBannerButton}
                title={UIText.generalAccept}
                width={70}
                height={30}
                onPress={this.controller.dismissVisitorTncBanner}
              />
            </View>
          </AnimatedBottomBanner>
        )}
      </View>
    );
  }
}

export { GroupMarketPageView };
