import { consoleEnabledUsers, env } from '../config/variable-config';

const _console = {};

export const disabledConsole = () => {
  if (env === "prod" && window.console) {
    for (const fn in window.console) {
      window.console.hasOwnProperty(fn) &&
        typeof window.console[fn] === "function" &&
        (!_console[fn] ? (_console[fn] = window.console[fn]) : true) &&
        (window.console[fn] = () => {});
    }
  }
};

export const restoreConsole = () => {
  for (const fn in _console) {
    _console.hasOwnProperty(fn) &&
      typeof _console[fn] === "function" &&
      (window.console[fn] = _console[fn]);
  }
  console.showUsers = () => console.log(consoleEnabledUsers);
  console.log("Console restored under permissible conditions.");
  console.log(
    "Want to know who has access to console? Use 'console.showUsers()'"
  );
};

export const EnvAdjustmentLoader = () => {
  disabledConsole();
};
