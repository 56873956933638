import React from "react";
import { CaregiverSignUpController } from "./controller";
import { CaregiverSignUpView } from "./view";
import { extraStyles, styles } from "./styles";

export class CaregiverSignUp extends React.Component {
  static navigationOptions = ({ navigation, screenProps }) => ({
    title: `${
      navigation.state.routeName.match(/Vendor/g)
        ? "Service Provider"
        : "Caregiver"
    } Application`
  });

  controller = new CaregiverSignUpController(this.props);

  render() {
    return (
      <CaregiverSignUpView
        {...this.props}
        styles={styles}
        extraStyles={extraStyles}
        controller={this.controller}
      />
    );
  }
}
