import { observer } from "mobx-react";
import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { computed, observable } from "mobx";
import { theme } from "../../../../config/style-configs/theme";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { placesService } from "../../../../cdm/services/places-service";
import { responsive } from "../../../../config/style-configs/responsive";
import { isEmpty, isEqual } from "../../../../utils/helpers";

@observer
class LocationPreviewMap extends React.Component {
  map;
  mapDefaultZoom = 13;

  mapOptions = {
    clickableIcons: false,
    streetViewControl: false,
    fullscreenControl: false,
    scrollwheel: false,
    draggable: false,
    disableDoubleClickZoom: true,
    zoomControl: false,
    keyboardShortcuts: false,
    mapTypeControl: false
    // styles: mapStyles
  };

  @observable currentName;
  @observable currentPlace;

  @computed get mapCenter() {
    if (isEmpty(this.currentPlace)) return;
    const { lat, lng, lon } = this.currentPlace || {};
    const mapCenter = {
      lat: Number(lat || 0),
      lng: Number(lon || lng || 0)
    };
    if (isNaN(mapCenter.lat) || isNaN(mapCenter.lng)) return;
    return mapCenter;
  }

  constructor(props) {
    super(props);
    this.setGeoCenter();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isEqual(prevProps.value, this.props.value)) this.setGeoCenter();
  }

  placesFieldRef = elm => (this.placesField = elm);

  setMap = map => (this.map = map);

  setGeoCenter = () => {
    if (this.props.value && typeof this.props.value === "object") {
      this.currentName = (this.props.value || {}).name;
      this.currentPlace = (this.props.value || {}).location;
    }
  };

  render() {
    const { isOpen, onClose, position } = this.props;

    const top = ((position || {}).top || "").toString().match(/%/)
      ? (position || {}).top
      : `${(position || {}).top || 0}px`;
    const left = ((position || {}).left || "").toString().match(/%/)
      ? (position || {}).left
      : `${(position || {}).left || 0}px`;

    return (
      <Paper
        style={{
          visibility: isOpen ? "visible" : "hidden",
          position: "fixed",
          top,
          left,
          width: "400px",
          zIndex: 999999
        }}
        onClick={onClose}
      >
        <div
          style={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Typography
            gutterBottom
            style={{ color: theme.color, padding: "0 10px" }}
          >
            {this.currentName}
          </Typography>
          <Typography
            gutterBottom
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "0 10px"
            }}
          >
            {(this.currentPlace || {}).formattedAddress}
          </Typography>
          {placesService.initialized && (
            <GoogleMap
              mapContainerClassName="googleMap"
              onLoad={this.setMap}
              mapContainerStyle={{
                width: responsive.deviceDimension.isDesktop ? 400 : "100%",
                height: 400
              }}
              center={this.mapCenter || this.geoCenter}
              zoom={this.mapDefaultZoom}
              options={this.mapOptions}
            >
              <Marker
                position={this.mapCenter || this.geoCenter}
                options={{
                  position: new google.maps.LatLng(
                    this.mapCenter || this.geoCenter
                  ),
                  clickable: false,
                  // label: "Center",
                  draggable: false
                }}
              />
            </GoogleMap>
          )}
        </div>
      </Paper>
    );
  }
}

export default LocationPreviewMap;
