import {
  daysToMilli,
  getDatetimeInputValueFromDate,
  getTimeZoneLongName,
  replaceTimeForDate
} from "../../../utils/helpers";
import { UIText } from "../../../config/lang-config";

export const shiftClockInOutForm = (
  startTime,
  endTime,
  startTimeOverride,
  endTimeOverride,
  execStartTime,
  execEndTime,
  timezone
) => {
  const now = new Date();
  const completed = !!execEndTime;
  const clockedInValue = execStartTime
    ? getDatetimeInputValueFromDate(new Date(execStartTime), timezone)
    : undefined;
  const clockedOutValue = execEndTime
    ? getDatetimeInputValueFromDate(new Date(execEndTime), timezone)
    : undefined;

  let scheduledEnd, endTimeNotReachedYet;
  if ((endTime || endTimeOverride) && !completed) {
    if (endTimeOverride) {
      scheduledEnd = getDatetimeInputValueFromDate(endTimeOverride, timezone);
    } else {
      const normalizedEndDate = replaceTimeForDate(
        new Date(),
        new Date(endTime)
      );
      // If current time is before set endTime, use a day before the set endTime.
      if (now.getTime() > normalizedEndDate.getTime()) {
        scheduledEnd = getDatetimeInputValueFromDate(
          normalizedEndDate,
          timezone
        );
      } else {
        endTimeNotReachedYet = true;
        scheduledEnd = getDatetimeInputValueFromDate(
          new Date(normalizedEndDate.getTime() - daysToMilli(1)),
          timezone
        );
      }
    }
  }

  let scheduledStart;
  if ((startTime || startTimeOverride) && !completed) {
    if (startTimeOverride) {
      scheduledStart = getDatetimeInputValueFromDate(
        startTimeOverride,
        timezone
      );
    } else {
      const normalizedStartDate = replaceTimeForDate(
        new Date(),
        new Date(startTime)
      );
      // If current time is before set startTime, or endTime is before current time, use a day before the set endTime.
      scheduledStart =
        now.getTime() < normalizedStartDate.getTime() || endTimeNotReachedYet
          ? getDatetimeInputValueFromDate(
              new Date(normalizedStartDate.getTime() - daysToMilli(1)),
              timezone
            )
          : getDatetimeInputValueFromDate(normalizedStartDate, timezone);
    }
  }

  return [
    {
      set: "clock-in-out",
      name: "execStartTime",
      placeholder: "Clock-in",
      disabled: completed,
      value: scheduledStart || clockedInValue,
      required: true,
      type: "datetime"
    },
    {
      set: "clock-in-out",
      name: "execEndTime",
      placeholder: "Clock-out",
      disabled: completed,
      value: scheduledEnd || clockedOutValue,
      required: true,
      type: "datetime"
    },
    {
      title: "Clock in/out",
      set: "clock-in-out",
      name: "timezone",
      placeholder: UIText.groupTimeZone,
      disabled: true,
      value: getTimeZoneLongName(timezone, true),
      type: "input"
    }
  ];
};
