import React from "react";
import { CareReceiverToDoListView } from "./view";
import { CareReceiverToDoListController } from "./controller";
import { styles } from "./styles";

export class CareReceiverToDoList extends React.Component {
  controller = new CareReceiverToDoListController(this.props);

  render() {
    return (
      <CareReceiverToDoListView
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
