import { autorun, computed, observable, reaction, toJS, when } from "mobx";
import { clientController } from "../controllers/client-controller";
import { apiController } from "../controllers/api-controller";
import { endpointConfig } from "../../config/api-config";
import { apiService } from "./api-service";
import {
  arrayFlat,
  asyncPause,
  capitalize,
  getDisplayNameEng,
  isEmpty,
  randomString,
  safeParseJSON
} from "../../utils/helpers";
import { stateController } from "../controllers/state-controller";
import NavigationService from "../../utils/navigation-service";
import { UIText } from "../../config/lang-config";
import {
  botUserDefaultId,
  env,
  groupTypeIds,
  topicTypeIds,
  typeClassIds
} from "../../config/variable-config";
import { txConfig } from "../../config/tx-config";
import { embeddedService } from "./embedded-service";
import { mcbEndpointConfig } from "../../custom/mcb/config/api-config";
import { txService } from "./tx-service";
import { Platform } from "react-native";
import { fileService } from "./file-service";
import { responsive } from "../../config/style-configs/responsive";

class MessagingService {
  @observable messages = {};
  @observable loaded = false;
  @observable syncingUnreadData = false;
  buffer = {};

  @observable unreadCountData = [];

  // Web window tab focus
  @observable windowFocused = true;

  messageHistoryQueue = {};

  @computed get hasUnread() {
    return this.unreadCountData.some(
      group =>
        Array.isArray(group.topics) &&
        group.topics.some(topic => Number(topic.unreadCount) > 0)
    );
  }

  @computed get unreadCount() {
    return arrayFlat(
      this.unreadCountData
        .map(g => g.topics.filter(topic => topic.unreadCount > 0))
        .filter(Boolean)
    )
      .map(t => !isNaN(Number(t.unreadCount)) && Number(t.unreadCount))
      .filter(Boolean)
      .reduce((a, b) => a + b, 0);
  }
  @computed get unreadCountTopics() {
    return arrayFlat(this.unreadCountData.map(g => g.topics));
  }

  constructor() {
    // Main messaging service.
    setTimeout(this._initializeMessaging);
    // Register embeddedService events.
    setTimeout(this._registerEmbeddedListeners);
    // Register reactions related to proactive chat.
    setTimeout(this._registerProactiveChatReactions);
    // Window focus listeners.
    if (Platform.OS === "web") {
      window.addEventListener("blur", this.onWebWindowBlur);
      window.addEventListener("focus", this.onWebWindowFocus);
    }
  }

  _initializeMessaging = () => {
    this.disposer = autorun(() => {
      if (clientController.initialized && clientController.loginState) {
        // Start unread counter polling.
        if (!clientController.isSyncMode) this.startSyncUnreadInterval();

        if (this.loaded) return;
        // Read stored messages into observable.
        // if (!isEmpty(clientController.client.messages)) {
        //   // Make a copy
        //   this.buffer = { ...clientController.client.messages };
        //   // Restore copy
        //   this.messages = { ...this.buffer };
        //   // Remove all unsends
        //   for (const key in this.messages) {
        //     if (!this.messages.hasOwnProperty(key)) continue;
        //     this.messages[key] = this.messages[key].filter(m => m.id);
        //   }
        //   this.buffer = {};
        // }
        // Backward reference to create the link.
        clientController.client.messages = this.messages;
        // Init chat state
        clientController.client.chatState =
          clientController.client.chatState || {};
        // Stop further loading.
        this.loaded = true;
      } else {
        // Clear messages and polling on logout.
        clearInterval(this.syncUnreadInterval);
        clearTimeout(this.visitorOnlineTimeout);
        clearTimeout(this.visitorWaitingTimeout);
        clearTimeout(this.visitorLaterTimeout);
        clearTimeout(this.newVisitorTimeout);
        clearTimeout(this.agentSseTimeout);
        this.unreadCountData = [];
        this.messages = {};
        this.loaded = false;
        // this.proactiveChatDisposer && this.proactiveChatDisposer();
      }
    });
  };

  // SSE
  onMessage = ({ type, message }) => {
    if (type !== "msg") return;

    return this.getNewMessage(message).then(this.syncUnreadCount);
  };

  getMessageHistory = async options => {
    if (!isEmpty(this.messageHistoryQueue)) {
      await asyncPause(250);
      return this.getMessageHistory(options);
    }
    this.messageHistoryQueue = { ...options };
    return apiController
      .getMessageHistory(options)
      .then(messages => this.processMessages(options.topicId, messages))
      .finally(() => (this.messageHistoryQueue = {}));
  };

  getNewMessage = async message => {
    const topicId = message.topicId;
    if (!topicId) return;

    return this.processMessages(topicId, [message], true).then(() =>
      this.syncMessages(topicId, message.threadId, message.id)
    );
  };

  processMessages = async (topicId, messages, singleNew) => {
    let isUpdate;
    if (!Array.isArray(this.messages[topicId])) {
      this.messages[topicId] = [];
    }
    if (!Array.isArray(messages) || messages.length === 0) return;

    for (let msg of messages) {
      const message = this.processSpecialMessage(msg);
      message.sentTime = message.sentTimeLong || message.sentTime;
      message.updateTime = message.updateTimeLong || message.updateTime;
      message.isRead = !!message.isRead ? Number(message.isRead) : 0;
      const update = {
        message: this.messages[topicId].find(
          m =>
            m.id === message.id ||
            (!!m.watermark && m.watermark === message.watermark)
        )
      };
      if (update.message) {
        isUpdate = true;
        update.message = Object.assign(update.message, message);
      } else {
        this.messages[topicId].push(message);
      }
    }

    const message = messages[0];
    const inChat =
      stateController.currentScreen === "Chat" &&
      stateController.viewTopicId === topicId;
    const member = clientController.findMemberById(message.senderMemberId);
    const isSelf = member.userId === clientController.userId;
    // const isBot = message.senderMemberId === botUserDefaultId;
    const inSetup = stateController.currentScreen === "Setup";
    const isFocused = this.windowFocused;
    if (message && singleNew && !isUpdate && !clientController.isVisitor) {
      if (message && (!isSelf || message.system) && !inSetup) {
        !inChat &&
          !message.system &&
          this.notifyNewMessageArrivalInApp(message, topicId);
        !isFocused && this.notifyNewMessageArrivalDefocused(message, topicId);
      }
    }

    this.sortTopicMessages(topicId);

    return [topicId, this.messages[topicId]];
  };

  processSpecialMessage = message => {
    if (!message.text) return message;

    /** Special character "χ" placed at the end of the message text will be treated as system message (bot message) **/
    if (message.text.match(/χ$/g)) {
      message.text = message.text.replace(/χ/g, "");
      message.system = true;
    }

    return message;
  };

  syncMessages = async (topicId, threadId, from) => {
    const topic = clientController.findTopicById(topicId);
    if (isEmpty(topic)) return;

    const selfMember = topic.members.find(
      m => m.userId === clientController.userId
    );
    if (!selfMember) return;

    const data = {
      topicId,
      threadId,
      memberId: selfMember.id,
      from
    };

    return this.getMessageHistory(data);
  };

  updateMessages = messages => {};

  sortTopicMessages = topicId => {
    this.messages[topicId] = toJS(this.messages[topicId]).sort((a, b) =>
      this.sortMessages(a, b, "sentTime")
    );
  };

  sortMessages = (a, b, sortKey, operand) => {
    let aTime = new Date(a[sortKey]).getTime();
    let bTime = new Date(b[sortKey]).getTime();
    if (!aTime || !bTime) return 0;
    if (aTime === bTime) {
      aTime = a.id;
      bTime = b.id;
    }
    return operand === "+" ? -(aTime - bTime) : aTime - bTime;
  };

  findMessageById = messageId => {
    const topics = Object.keys(this.messages);
    for (let topic of topics) {
      if (Array.isArray(this.messages[topic])) {
        const message = this.messages[topic].find(msg => msg.id === messageId);
        if (message) return message;
      }
    }
    return {};
  };

  createTopicDefaultThread = async topicId => {
    const topic = clientController.findTopicById(topicId);
    if (!topic || !topic.id) return;

    const member =
      Array.isArray(topic.members) &&
      topic.members.find(m => m.userId === clientController.userId);
    if (!member || !member.id) return;

    return apiService.async("POST", {
      endpoint: endpointConfig.create_default_thread,
      data: {
        topicId: topic.id,
        creatorMemberId: member.id,
        description: "default thread",
        isPrivate: 0
      }
    });
  };

  addSendingMessage = async data => {
    const topicId = data && data.topicId;
    if (!topicId) return;
    this.messages[topicId].push(data);
    this.sortTopicMessages(topicId);
    return data;
  };

  removeMessage = async data => {
    const topicId = data && data.topicId;
    if (!topicId || !Array.isArray(this.messages[topicId])) return;
    const index = this.messages[topicId].findIndex(
      m =>
        (data.id && m.id === data.id) ||
        (data.watermark && m.watermark === data.watermark)
    );
    if (!index < 0) return;
    this.messages[topicId].splice(index, 1);
    return Promise.resolve();
  };

  sendMessage = async data => {
    return apiService.async("POST", {
      endpoint: endpointConfig.create_message,
      data
    });
  };

  readMessages = async (messages, memberId, topicId) => {
    if (!Array.isArray(messages)) return;

    const filter = message => {
      if (!message) return;
      if (message.read) return;
      if (message.reading) return;
      if (message.isRead) return;
      if (message.senderMemberId === memberId) return;
      return true;
    };

    const waitDone = message =>
      when(() => !message.reading, { timeout: 30000 }).finally(
        () => (message.reading = false)
      );

    const messagesToRead = messages.filter(filter);
    if (isEmpty(messagesToRead)) return;

    messagesToRead.forEach(message => (message.reading = true));

    const updatedMessages = await apiService
      .async("POST", {
        endpoint: endpointConfig.bulk_read_messages(memberId),
        data: { messageIds: messagesToRead.map(m => m.id) }
      })
      .then(response => response.data)
      .catch(console.error)
      .finally(() =>
        messagesToRead.forEach(message => (message.reading = false))
      );

    await Promise.all(messagesToRead.map(waitDone));

    setTimeout(this.syncUnreadCount);

    return this.processMessages(topicId, updatedMessages);
  };

  syncUnreadCount = async options => {
    options = options || {};

    const sync = async () => {
      await when(() => !isEmpty(clientController.findVisibleGroups()));
      this.syncingUnreadData = true;
      return apiController
        .getUnreadMessagesCount(clientController.findVisibleGroups())
        .then(unread => {
          if (!isEmpty(unread)) this.unreadCountData = unread;
        })
        .catch(console.error)
        .finally(() => (this.syncingUnreadData = false));
    };

    if (options.async && !isEmpty(this.unreadCountData)) {
      return setTimeout(sync);
    }

    return sync();
  };

  startSyncUnreadInterval = () => {
    clearTimeout(this.startInterval);
    this.startInterval = setTimeout(() => {
      this.syncUnreadCount().then(() => {
        clearInterval(this.syncUnreadInterval);
        this.syncUnreadInterval = setInterval(
          this.syncUnreadCount,
          txConfig.syncUnreadMessagesInterval
        );
      });
    }, 500);
  };

  onWebWindowFocus = () => (this.windowFocused = true);

  onWebWindowBlur = () => (this.windowFocused = false);

  notifyNewMessageArrivalInApp = (message, topicId) => {
    return (
      !message.system &&
      stateController.showPopup({
        title: capitalize(UIText.chat),
        content: UIText.chatNewMessage,
        leftButtonText: UIText.generalNo,
        rightButtonText: UIText.generalYes,
        rightButtonPress: () =>
          stateController.dismissPopup().then(() => {
            stateController.viewTopicId = topicId;
            NavigationService.navigate("Chat", { topic: topicId });
          })
      })
    );
  };

  notifyNewMessageArrivalDefocused = (message, topicId) => {
    if (!responsive.isDesktopOS) return;
    const topic = clientController.findTopicById(topicId);
    if (Notification.permission === "granted") {
      if (isEmpty(message) || isEmpty(topic)) return;
      const sender =
        topic.members.find(member => member.id === message["senderMemberId"]) ||
        {};
      const profile = (sender.profile && sender.profile.data) || {};
      const defaultIcon = require("../../assets/icon.png");
      const notification = new Notification(
        `${UIText.title} ${UIText.chat}${
          topic.description ? `: ${topic.description}` : ""
        }`,
        {
          body: message.text,
          icon:
            (topic.typeId !== topicTypeIds.mCBBroadcasts &&
              !message.system &&
              fileService.getProfileAvatarUri(
                profile.avatar,
                sender.id,
                "member"
              )) ||
            defaultIcon,
          badge: require("../../assets/icon.png")
        }
      );
      notification.onclick = () => {
        this.openChatFromNotification(topicId, message.id);
        if (stateController.popup.isVisible)
          return stateController.dismissPopup();
      };
    } else {
      Notification && Notification.requestPermission();
      return this.playSound();
    }
  };

  openChatFromNotification = (topicId, messageId) => {
    // window.location.href = `/Group/Chat?topic=${topicId}`;
    window.focus();
    stateController.viewTopicId = topicId;
    return NavigationService.navigate("Chat", {
      topic: topicId,
      scrollTo: messageId
    });
  };

  playSound = () => {
    const sound = new Audio(require("../../assets/light.mp3"));
    return sound.play();
  };

  findChatLobbyTopic = groupId =>
    clientController.findTopics(
      t =>
        t.typeId === topicTypeIds.groupChatLobby &&
        t["groupIdList"].includes(groupId)
    )[0] || {};

  /** Proactive Chat Functions **/
  @observable proactiveChatSessionTimeoutValue = 0;
  @computed get proactiveChatSessionTimeoutString() {
    return `${this.proactiveChatSessionTimeoutValue / 1000 / 60} minutes`;
  }
  createProactiveChatTopicRetry = 0;
  visitorOnlineTimeout;
  visitorWaitingTimeout;
  visitorLaterTimeout;
  newVisitorTimeout;
  agentSseTimeout;
  @observable agentSseTimeoutQueue = [];
  visitorWaitingTopicIds = [];
  visitorOnlineTopicIds = [];
  visitorProactiveChatInitialized = false;
  @observable proactiveChatAgentMemberId = 0;
  @computed get proactiveChatTopics() {
    return clientController.findTopics(
      t => t.typeId === topicTypeIds.proactiveChat
    );
  }
  @computed get designatedProactiveChatTopic() {
    return this.proactiveChatTopics[0] || {};
  }
  @computed get proactiveChatMessages() {
    if (!this.designatedProactiveChatTopic.id) return [];
    const messages = this.messages[this.designatedProactiveChatTopic.id] || [];
    const { members } = this.designatedProactiveChatTopic;
    return messages
      .filter(msg => !msg.system)
      .map(msg => {
        const sender = getDisplayNameEng(
          (members.find(m => m.id === msg.senderMemberId) || {}).profile
        );
        return {
          id: msg.id || msg.watermark,
          text: msg.text,
          isSelf: msg.senderMemberId === clientController.defaultMember.id,
          date: msg.sentTime && new Date(msg.sentTime),
          sender,
          loading: !msg.sentTime || msg.sending || (!msg.id && msg.watermark)
        };
      });
  }
  @computed get proactiveChatAgentMember() {
    const { members } = this.designatedProactiveChatTopic;
    if (isEmpty(members)) return {};
    return members.find(m => m.id === this.proactiveChatAgentMemberId) || {};
  }

  // Embedded mode functions
  _registerEmbeddedListeners = () => {
    embeddedService.addEventListener(
      "sessionTimeoutSetTo",
      this.setSessionTimeoutValue
    );
    embeddedService.addEventListener("createProactiveChatTopic", options =>
      this.initProactiveChat(true, options.isDevUser)
    );
    embeddedService.addEventListener("getProactiveChatTopic", () =>
      this.initProactiveChat()
    );
    embeddedService.addEventListener(
      "proactiveChatVisitor",
      this.onProactiveChatVisitorOnline
    );
    embeddedService.addEventListener(
      "proactiveChatVisitorOffline",
      this.onProactiveChatVisitorOffline
    );
    embeddedService.addEventListener(
      "proactiveChatVisitorNoThanks",
      this.onProactiveChatVisitorNoThanks
    );
    embeddedService.addEventListener(
      "proactiveChatVisitorLater",
      this.onProactiveChatVisitorLater
    );
    embeddedService.addEventListener(
      "proactiveChatVisitorLaterTimeout",
      this.onProactiveChatVisitorLaterTimeout
    );
    embeddedService.addEventListener(
      "proactiveChatVisitorWaiting",
      this.onProactiveChatVisitorWaiting
    );
    embeddedService.addEventListener(
      "proactiveChatSendMessage",
      this.sendProactiveChatMessage
    );
  };

  _registerProactiveChatReactions = () => {
    reaction(
      () => this.agentSseTimeoutQueue,
      () => {
        if (this.agentSseTimeoutQueue.length > 0) {
          if (!this.agentSseTimeout)
            return this.sendProactiveChatAgentSseQueue();
        } else {
          clearTimeout(this.agentSseTimeout);
          this.agentSseTimeout = null;
        }
      }
    );
  };

  setSessionTimeoutValue = value => {
    if (!clientController.isVisitor || !value) return;
    this.proactiveChatSessionTimeoutValue = value;
  };

  initProactiveChat = async (createTopic, isDevUser) => {
    if (createTopic || isEmpty(this.designatedProactiveChatTopic)) {
      await this.createProactiveChatTopic(isDevUser).catch(console.warn);
    }
    return this.getProactiveChatTopics()
      .then(this.postProactiveChatTopic)
      .then(this.syncProactiveChatMessages)
      .then(this.mkTopicThreadMemberForBot)
      .then(() => this.sendVisitorCurrentlyOnSite(isDevUser))
      .then(() => (this.visitorProactiveChatInitialized = true));
  };

  createProactiveChatTopic = async isDevUser => {
    if (
      isEmpty(clientController.defaultGroup) ||
      isEmpty(clientController.defaultMember)
    ) {
      await asyncPause(200);
      console.log("Waiting for default group member");
      return this.createProactiveChatTopic(isDevUser);
    }

    if (!clientController.isVisitor) return;

    const retry = async err => {
      console.warn(err);
      this.createProactiveChatTopicRetry++;
      if (this.createProactiveChatTopicRetry >= 5) {
        console.warn(
          "Create proactive chat topic exceeded maximum retry count of 5."
        );
        return Promise.reject(err);
      }
      await asyncPause(500);
      return this.createProactiveChatTopic().catch(retry);
    };

    const identifier =
      getDisplayNameEng(clientController.defaultGroup.profile) ||
      clientController.deviceId;

    const topic = {
      // creatorMemberId: clientController.defaultMember.id,
      typeId: topicTypeIds.proactiveChat,
      description: `Website visitor ${identifier}`,
      onCalendar: 0,
      isTemplate: 0,
      isParentTemplate: 0,
      isCompleted: 0,
      isDataLocked: 0,
      isLocked: 0,
      typeClassId: typeClassIds.messagingTopic,
      typeClassVersion: 1, // Default for now.
      data: "{}"
    };

    return apiService
      .async("POST", {
        endpoint: endpointConfig.create_topic,
        data: {
          currentGroupId: clientController.defaultGroup.id,
          otherGroupIdList: [clientController.defaultGroup.id],
          otherMemberIdList: [clientController.defaultMember.id],
          topic,
          isDevUser: !!isDevUser ? 1 : 0
        }
      })
      .then(() => (this.createProactiveChatTopicRetry = 0))
      .catch(retry);
  };

  getProactiveChatTopics = async () =>
    apiController
      .getGroupTopicsByTypeId(
        clientController.defaultGroup.id,
        topicTypeIds.proactiveChat
      )
      .then(topics => {
        for (let topic of toJS(this.proactiveChatTopics)) {
          !topics.find(t => t.id === topic.id) &&
            clientController.removeTopic(topic);
        }
        for (let topic of topics) {
          clientController.updateTopic(topic);
        }
        return Promise.resolve();
      });

  getProactiveChatMessages = async () =>
    this.syncMessages(this.designatedProactiveChatTopic.id).catch(console.warn);

  syncProactiveChatMessages = async () => {
    await this.getProactiveChatMessages();
    return (this.proactiveChatDisposer = autorun(
      this.postProactiveChatMessages
    ));
  };

  mkTopicThreadMemberForBot = async () => {
    const topicId = this.designatedProactiveChatTopic.id;
    const data = {
      topicId,
      memberId: botUserDefaultId,
      count: 1000,
      from: null,
      until: null
    };
    return apiController
      .getTopicById(topicId, botUserDefaultId)
      .then(() => apiController.getMessageHistory(data));
  };

  sendProactiveChatMessage = async text => {
    if (!clientController.isVisitor) return;
    if (!text) return;
    if (isEmpty(this.designatedProactiveChatTopic)) return;
    const data = {
      topicId: this.designatedProactiveChatTopic.id,
      threadId: this.designatedProactiveChatTopic["defaultThreadId"],
      senderMemberId: clientController.defaultMember.id,
      text,
      textIsMd: 0,
      watermark: randomString()
    };
    return this.addSendingMessage(data)
      .then(this.sendMessage)
      .then(this.getProactiveChatMessages);
  };

  onProactiveChatSse = async event => {
    if (!event || !event.data) return;
    const data = safeParseJSON(event.data);
    if (!data) return;
    const { message, topicId } = data;
    const command = message.split(":")[0];
    const content = message.split(":")[1];
    if (command === "agent") {
      clearTimeout(this.newVisitorTimeout);
      this.setProactiveChatAgent(content, topicId);
      return this.postProactiveChatAgent();
    }
    if (command === "visitor") {
      clearTimeout(this.visitorWaitingTimeout);
      !this.visitorOnlineTopicIds.includes(topicId) &&
        this.visitorOnlineTopicIds.push(topicId);
      return this.setVisitorOnlineTimeout(content, topicId);
    }
    if (command === "visitorHeartbeat") {
      clearTimeout(this.visitorWaitingTimeout);
      if (!this.visitorOnlineTopicIds.includes(topicId)) {
        this.visitorOnlineTopicIds.push(topicId);
        await this.appendVisitorReconnectMessage(content, topicId);
      }
      return this.setVisitorOnlineTimeout(content, topicId);
    }
    if (command === "visitorOffline") {
      clearTimeout(this.visitorWaitingTimeout);
      clearTimeout(this.visitorOnlineTimeout);
      return (this.visitorOnlineTopicIds = this.visitorOnlineTopicIds.filter(
        id => id !== topicId
      ));
    }
    if (command === "visitorNoThanks") {
      return clearTimeout(this.visitorWaitingTimeout);
    }
    if (command === "visitorLater") {
      return clearTimeout(this.visitorWaitingTimeout);
    }
    if (command === "visitorLaterTimeout") {
      return clearTimeout(this.visitorWaitingTimeout);
    }
    if (command === "visitorWaiting") {
      return this.setVisitorWaitingTimeout(content, topicId);
    }
    if (command === "newVisitor") {
      return this.handleNewVisitor(topicId);
    }
  };

  handleNewVisitor = async topicId => {
    // if (this.newVisitorRespondTopicIds.includes(topicId)) return;
    const mcbGroup = clientController.findGroups(
      g => g.typeId === groupTypeIds.myCareBaseStaff
    )[0];
    if (isEmpty(mcbGroup)) return;

    if (!responsive.isDesktopOS) return;
    if (Notification.permission === "granted") {
      const defaultIcon = require("../../assets/icon.png");
      const notification = new Notification(UIText.groupAdminProactiveChat, {
        body: UIText.groupAdminProactiveChatNewVisitor,
        icon: defaultIcon,
        badge: defaultIcon
      });
      notification.onclick = () => this.openChatFromNotification(topicId);
    } else {
      Notification && Notification.requestPermission();
      this.playSound();
    }
    if (clientController.isVisitor) return;

    // Proactive admin logic
    return this.addProactiveChatAgentSseQueue(topicId, true);
    // .then(() => this.newVisitorRespondTopicIds.push(topicId));
  };

  onProactiveChatVisitorOnline = async visitorName => {
    if (visitorName) {
      const profileId = clientController.defaultGroup.profile.id;
      const data = clientController.defaultGroup.profile.data || {};
      data.displayName = visitorName;
      await Promise.all([
        apiService.async("PATCH", {
          endpoint: endpointConfig.profile_by_id(profileId),
          data: { data: JSON.stringify(data) }
        }),
        apiService.async("PATCH", {
          endpoint: endpointConfig.topic_by_id(
            this.designatedProactiveChatTopic.id
          ),
          data: {
            description: `Website visitor ${visitorName} (${
              clientController.deviceId
            })`
          }
        })
      ]).catch(console.warn);
    }
    const displayName = getDisplayNameEng(
      clientController.defaultGroup.profile
    );
    const deviceId = clientController.deviceId;
    const name = displayName || `Visitor (${deviceId})`;
    const botMessage = `${name} has joined the chat.χ`;
    await this.sendVisitorActionSystemMessage(botMessage);
    await this.sendVisitorHelloMessage(displayName);
    await this.postProactiveChatStatusSse({
      topicId: this.designatedProactiveChatTopic.id,
      status: `visitor:${name}`
    });
    return this.onProactiveChatVisitorHeartbeat(name);
  };

  onProactiveChatVisitorHeartbeat = async visitorName =>
    (this.visitorHeartbeatTimeout = setTimeout(
      () =>
        this.postProactiveChatStatusSse({
          topicId: this.designatedProactiveChatTopic.id,
          status: `visitorHeartbeat:${visitorName}`
        }).then(() => this.onProactiveChatVisitorHeartbeat(visitorName)),
      5000
    ));

  onProactiveChatVisitorOffline = async () => {
    clearTimeout(this.visitorHeartbeatTimeout);
    const displayName = getDisplayNameEng(
      clientController.defaultGroup.profile
    );
    const deviceId = clientController.deviceId;
    const name = displayName || `Visitor (${deviceId})`;
    const botMessage = `${name} has left the chat. Please close this tab/window.χ`;
    return this.sendVisitorActionSystemMessage(botMessage).then(() =>
      this.postProactiveChatStatusSse({
        topicId: this.designatedProactiveChatTopic.id,
        status: `visitorOffline:${name}`
      })
    );
  };

  onProactiveChatVisitorNoThanks = async () => {
    const displayName = getDisplayNameEng(
      clientController.defaultGroup.profile
    );
    const deviceId = clientController.deviceId;
    const name = displayName || `Visitor (${deviceId})`;
    const botMessage = `${name} is not interested in chatting.χ`;
    return this.sendVisitorActionSystemMessage(botMessage).then(() =>
      this.postProactiveChatStatusSse({
        topicId: this.designatedProactiveChatTopic.id,
        status: `visitorNoThanks:${name}`
      })
    );
  };

  onProactiveChatVisitorLater = async () => {
    const displayName = getDisplayNameEng(
      clientController.defaultGroup.profile
    );
    const deviceId = clientController.deviceId;
    const name = displayName || `Visitor (${deviceId})`;
    const botMessage = `${name} may want to chat later. Please wait up to ${
      this.proactiveChatSessionTimeoutString
    } before closing the tab/window.χ`;
    return this.sendVisitorActionSystemMessage(botMessage).then(() =>
      this.postProactiveChatStatusSse({
        topicId: this.designatedProactiveChatTopic.id,
        status: `visitorLater:${name}`
      })
    );
  };

  onProactiveChatVisitorLaterTimeout = async () => {
    const displayName = getDisplayNameEng(
      clientController.defaultGroup.profile
    );
    const deviceId = clientController.deviceId;
    const name = displayName || `Visitor (${deviceId})`;
    const botMessage = `${name} has gone idle for ${
      this.proactiveChatSessionTimeoutString
    } and is not likely to return. You may wait longer; otherwise, please close this tab/window.χ`;
    return this.sendVisitorActionSystemMessage(botMessage).then(() =>
      this.postProactiveChatStatusSse({
        topicId: this.designatedProactiveChatTopic.id,
        status: `visitorLaterTimeout:${name}`
      })
    );
  };

  onProactiveChatVisitorWaiting = async () => {
    if (!clientController.isVisitor) return;
    const displayName = getDisplayNameEng(
      clientController.defaultGroup.profile
    );
    const deviceId = clientController.deviceId;
    const name = displayName || `Visitor (${deviceId})`;
    return this.postProactiveChatStatusSse({
      topicId: this.designatedProactiveChatTopic.id,
      status: `visitorWaiting:${name}`
    });
  };

  appendVisitorOfflineDisconnectMessage = (visitorName, topicId) => {
    if (clientController.isVisitor) return;
    return this.sendVisitorActionSystemMessage(
      `${visitorName} has disconnected from the chat.χ`,
      topicId
    );
  };

  appendVisitorReconnectMessage = (visitorName, topicId) => {
    if (clientController.isVisitor) return;
    return this.sendVisitorActionSystemMessage(
      `${visitorName} has reconnected to the chat.χ`,
      topicId
    );
  };

  appendVisitorWaitingAwayMessage = (visitorName, topicId) => {
    if (clientController.isVisitor) return;
    const topic = clientController.findTopicById(topicId);
    if (isEmpty(topic)) return;
    const deviceId = !visitorName
      ? topic.description.replace(/Website\svisitor\s/g, "")
      : "";
    const name = visitorName || `Visitor (${deviceId})`;
    return this.sendVisitorActionSystemMessage(
      `${name} has already left the website. Please close this tab/window.χ`,
      topicId
    );
  };

  sendVisitorCurrentlyOnSite = isDevUser => {
    if (!clientController.isVisitor) return;
    const displayName = getDisplayNameEng(
      clientController.defaultGroup.profile
    );
    const deviceId = clientController.deviceId;
    const name = displayName || `Visitor (${deviceId})`;
    const botMessage = `${name} is currently on the website.χ`;
    return (this.visitorProactiveChatInitialized
      ? Promise.resolve()
      : this.sendVisitorActionSystemMessage(botMessage)
    ).then(() => this.setNewVisitorTimeout(isDevUser));
  };

  sendVisitorActionSystemMessage = async (text, topicId) => {
    if (!clientController.isVisitor && !topicId) return;
    const topic = topicId
      ? clientController.findTopicById(topicId)
      : this.designatedProactiveChatTopic;
    if (isEmpty(topic) || isEmpty(topic.members)) return;
    if (!topic["defaultThreadId"]) {
      await this.createProactiveChatDefaultThread(topic.id).catch(console.warn);
      return this.sendVisitorActionSystemMessage(text, topicId);
    }
    const data = {
      topicId: topic.id,
      threadId: topic["defaultThreadId"],
      senderMemberId: botUserDefaultId,
      text,
      textIsMd: 0,
      watermark: randomString()
    };
    return this.sendMessage(data).then(() => this.syncMessages(topic.id));
  };

  sendVisitorHelloMessage = async visitorName => {
    if (!clientController.isVisitor) return;
    const topic = this.designatedProactiveChatTopic;
    if (isEmpty(topic)) return;
    if (!topic["defaultThreadId"]) {
      await this.createProactiveChatDefaultThread(topic.id).catch(console.warn);
      return this.sendVisitorHelloMessage(visitorName);
    }
    // Don't repeat send hello if already exists as last message
    const messages = this.messages[topic.id];
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && lastMessage.text.match(/Hello\s(.*)/g)) return;

    const data = {
      topicId: topic.id,
      threadId: topic["defaultThreadId"],
      senderMemberId: this.proactiveChatAgentMember.id,
      text: `Hello ${visitorName}!`,
      textIsMd: 0,
      watermark: randomString()
    };
    return this.sendMessage(data).then(() => this.syncMessages(topic.id));
  };

  sendProactiveChatAgentSse = async ({ topicId, isBackground }) => {
    if (!topicId) return;
    if (clientController.isVisitor) return;
    const mcbGroup = clientController.findGroups(
      g => g.typeId === groupTypeIds.myCareBaseStaff
    )[0];
    if (isEmpty(mcbGroup)) return;
    const selfMember =
      mcbGroup.members &&
      mcbGroup.members.find(m => m.userId === clientController.userId);
    if (!selfMember) return;
    const topic = clientController.findTopicById(topicId);
    const topicSelfMember =
      topic.members &&
      topic.members.find(m => m.userId === clientController.userId);

    if (isEmpty(topic) || isEmpty(topicSelfMember)) {
      await apiController
        .getTopicById(topicId, selfMember.id)
        .then(clientController.updateTopic)
        .catch(console.warn);
      return this.sendProactiveChatAgentSse({ topicId, isBackground });
    }

    const agentId = isBackground ? botUserDefaultId : topicSelfMember.id;

    return this.postProactiveChatStatusSse({
      topicId: topicId,
      status: `agent:${agentId}`
    });
  };

  sendProactiveChatAgentSseQueue = () =>
    Promise.all(
      this.agentSseTimeoutQueue.map(this.sendProactiveChatAgentSse)
    ).finally(
      () =>
        (this.agentSseTimeout = setTimeout(
          this.sendProactiveChatAgentSseQueue,
          5000
        ))
    );

  addProactiveChatAgentSseQueue = async (topicId, isBackground) => {
    if (this.agentSseTimeoutQueue.some(queue => queue.topicId === topicId))
      return;
    this.agentSseTimeoutQueue = this.agentSseTimeoutQueue.concat([
      {
        topicId,
        isBackground
      }
    ]);
  };

  removeProactiveChatAgentSseQueue = topicId =>
    (this.agentSseTimeoutQueue = this.agentSseTimeoutQueue.filter(
      queue => queue.topicId !== topicId
    ));

  clearProactiveChatAgentSseQueue = () => {
    for (const queue of this.agentSseTimeoutQueue) {
      this.removeProactiveChatAgentSseQueue(queue.topicId);
    }
  };

  setVisitorOnlineTimeout = (content, topicId) => {
    clearTimeout(this.visitorOnlineTimeout);
    this.visitorOnlineTimeout = setTimeout(() => {
      this.appendVisitorOfflineDisconnectMessage(content, topicId);
      this.visitorOnlineTopicIds = this.visitorOnlineTopicIds.filter(
        id => id !== topicId
      );
    }, 15000);
  };

  setVisitorWaitingTimeout = (content, topicId) => {
    clearTimeout(this.visitorWaitingTimeout);
    this.visitorWaitingTimeout = setTimeout(
      () => this.appendVisitorWaitingAwayMessage(content, topicId),
      15000
    );
  };

  setNewVisitorTimeout = isDevUser => {
    if (!clientController.isVisitor) return;
    this.postProactiveChatNewVisitorSse(isDevUser).finally(
      () =>
        (this.newVisitorTimeout = setTimeout(
          () => this.setNewVisitorTimeout(isDevUser),
          5000
        ))
    );
  };

  // setVisitorLaterTimeout = (content, topicId) => {
  //   if (!this.proactiveChatSessionTimeoutValue) return;
  //   clearTimeout(this.visitorLaterTimeout);
  //   this.visitorLaterTimeout = setTimeout(
  //     () => this.appendVisitorLaterAwayMessage(content, topicId),
  //     this.proactiveChatSessionTimeoutValue
  //   )
  // };

  setProactiveChatAgent = (agentMemberId, topicId) => {
    if (!agentMemberId) return;
    if (
      !clientController.isVisitor &&
      !this.visitorWaitingTopicIds.includes(topicId)
    ) {
      this.visitorWaitingTopicIds.push(topicId);
      this.setVisitorWaitingTimeout(null, topicId);
    }
    return (this.proactiveChatAgentMemberId = Number(agentMemberId));
  };

  createProactiveChatDefaultThread = async topicId =>
    this.createTopicDefaultThread(topicId)
      .then(() => apiController.getTopicById(topicId))
      .then(clientController.updateTopic);

  postProactiveChatStatusSse = async (data, callback) => {
    if (callback) {
      const { status } = data;
      txService.addSSEEventListener(status, event => {
        if (event.type !== "pc") return;
        return callback(event);
      });
    }
    return apiService.async("POST", {
      endpoint: mcbEndpointConfig.send_proactive_chat_status,
      data,
      noRenew: true
    });
  };

  postProactiveChatNewVisitorSse = async isDevUser => {
    if (!clientController.isVisitor) return;
    return apiService.async("GET", {
      endpoint: mcbEndpointConfig.send_new_visitor_proactive_chat_sse(
        this.designatedProactiveChatTopic.id,
        isDevUser
      ),
      noRenew: true
    });
  };

  postProactiveChatTopic = async () =>
    embeddedService.postMessage({
      proactiveChatTopic: toJS(msgService.designatedProactiveChatTopic)
    });

  postProactiveChatMessages = () =>
    embeddedService.postMessage({
      proactiveChatMessages: toJS(this.proactiveChatMessages)
    });

  postProactiveChatAgent = () =>
    embeddedService.postMessage({
      proactiveChatAgent: getDisplayNameEng(
        this.proactiveChatAgentMember.profile
      )
    });
}

const msgService = new MessagingService();

// For development;
if (window && env !== "prod") window.msgService = msgService;

export { msgService };
