import React from "react";
import { Image, View } from "react-native";
import { observer } from "mobx-react";
import { theme } from "../../../config/style-configs/theme";

@observer
class VerificationScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentDidMount() {
    return this.controller.componentDidMount();
  }

  render() {
    return (
      <View style={this.props.styles.style}>
        <View style={this.props.styles.header}>
          <Image
            source={theme.loginLogo}
            style={this.props.styles.headerLogo}
          />
        </View>
      </View>
    );
  }
}

export { VerificationScreen };
