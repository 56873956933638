import React from "react";
import { observer } from "mobx-react";
import { theme } from "../../../../config/style-configs/theme";
import { View } from "react-native";
import { Text } from "react-native-elements";
import {
  getDisplayNameEng,
  getTimeString24Timezone,
  isEmpty
} from "../../../../utils/helpers";
import { UIText } from "../../../../config/lang-config";
import { TopicCard } from "../../../../components/TopicCard";
import ActivityIndicator from "react-native-web/dist/exports/ActivityIndicator";

export const ShiftCardView = observer(props => {
  const {
    timezone,
    shift,
    clockState,
    clockers,
    couldClockInOut,
    clockInOutPending,
    noClockerLoading,
    onClockPress,
    onPress
  } = props;

  const startTime =
    shift.startTime &&
    getTimeString24Timezone(new Date(shift.startTime), true, timezone);
  const endTime =
    shift.startTime &&
    getTimeString24Timezone(new Date(shift.endTime), true, timezone);

  // const showClock =
  //   couldClockInOut && !!clockState && clockState !== "clocked-out";
  const showClock = false;
  const clock =
    showClock && (clockState === "clocked-in" ? "clock-out" : "clock-in");
  const clockColor = clockState === "clocked-in" ? theme.color : "#666";

  const cardIcon =
    clockState === "new"
      ? "clipboard-text"
      : clockState === "clocked-in"
      ? "clipboard-text-play"
      : shift.onCalendar
      ? "calendar-today"
      : "clipboard-check";
  const iconBackgroundColor =
    clockState === "new"
      ? theme.generalNormalColor
      : clockState === "clocked-in"
      ? theme.color
      : "";

  // const clockerName = ;
  const clockerName =
    !isEmpty(clockers) &&
    (clockers.length > 1
      ? couldClockInOut
        ? UIText.youAndOthers.toLowerCase()
        : UIText.others.toLowerCase()
      : couldClockInOut
      ? UIText.you.toLowerCase()
      : getDisplayNameEng((clockers[0] || {}).profile));

  const children = (
    <View style={props.styles.cardContent}>
      <Text
        style={{
          ...props.styles.cardContentMedium,
          fontSize: theme.FONT_SIZE_MEDIUM + 1
        }}
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {clockState === "clocked-out"
          ? UIText.shiftClockedOutBy(clockerName || "")
          : clockState === "clocked-in"
          ? UIText.shiftClockedInBy(clockerName || "")
          : clockerName
          ? `${UIText.assignedTo} ${clockerName}`
          : UIText.shiftNewShift}
        &nbsp;
        {!clockerName && !noClockerLoading && (
          <ActivityIndicator size={theme.FONT_SIZE_SMALL} color={theme.color} />
        )}
      </Text>
      <Text
        style={props.styles.cardContentSmall}
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {clockState === "new"
          ? // ? `${UIText.createdAt}: ${new Date(
            //     shift.createTime
            //   ).toLocaleString()}`
            ``
          : clockState === "clocked-in"
          ? `${UIText.shiftClockedInShifts}: ${new Date(
              shift.execStartTime
            ).toLocaleString("en-CA", { timeZone: timezone })}`
          : `${UIText.shiftCompleted}: ${new Date(
              shift.correctedEndTime || shift.execEndTime
            ).toLocaleString("en-CA", { timeZone: timezone })}`}
      </Text>
      <Text
        style={props.styles.cardContentSmall}
        numberOfLines={2}
        ellipsizeMode="tail"
      >
        {`${UIText.topicStartTime}: ${startTime || UIText.topicTimeNotSet}\n`}
        {`${UIText.topicEndTime}: ${endTime || UIText.topicTimeNotSet}`}
      </Text>
    </View>
  );

  return (
    <TopicCard
      compact={true}
      contentStyle={props.contentStyle}
      title={shift.description}
      icon={cardIcon}
      iconType="material-community"
      iconBackgroundColor={iconBackgroundColor}
      checked={showClock}
      checkedIcon={clock}
      checkedIconColor={clockColor}
      pending={shift.inClockInOutPending || clockInOutPending}
      onCheckPress={showClock && (onClockPress || (() => {}))}
      onPress={onPress || (() => {})}
      children={children}
    />
  );
});
