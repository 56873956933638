import {
  groupTypeActorIds,
  groupTypeIds,
  groupTypeRoleIds,
  topicTypeIds
} from "../../../config/variable-config";
import { apiService } from "../../../cdm/services/api-service";
import { mcbEndpointConfig } from "../config/api-config";
import {
  capitalize,
  getUTCOffsetMilli,
  isEmpty,
  safeParseJSON
} from "../../../utils/helpers";
import { getTopicCompletionState } from "../../../cdm/lib/topic-utilities";
import { stateController } from "../../../cdm/controllers/state-controller";
import NavigationService from "../../../utils/navigation-service";
import { UIText } from "../../../config/lang-config";
import { formService } from "../../../cdm/services/form-service";
import { endpointConfig } from "../../../config/api-config";
import { apiController } from "../../../cdm/controllers/api-controller";
import { toJS } from "mobx";

export class ShiftTaskController {
  getShiftClockCapability = (shift, memberId) => {
    const assignee =
      Array.isArray(shift.actors) &&
      shift.actors.find(
        a =>
          a["groupTypeActorId"] ===
          groupTypeActorIds[groupTypeIds.careReceiver].assigned
      );
    return (
      assignee &&
      assignee.memberIdList &&
      assignee.memberIdList.includes(memberId)
    );
  };

  sortShifts = shifts => {
    const sortIndex = {
      "clocked-in": 1,
      new: 0,
      "clocked-out": -1
    };

    return shifts.sort((a, b) => {
      if (!a || !b) return 0;

      const clockA = getTopicCompletionState(a);
      const clockB = getTopicCompletionState(b);

      const s1a = sortIndex[clockA];
      const s1b = sortIndex[clockB];

      let s2a, s2b;

      if (s1a === s1b) {
        if (s1a === 1) {
          s2a = new Date(a.execStartTime).getTime();
          s2b = new Date(b.execStartTime).getTime();
        }
        if (s1a === 0) {
          s2a = new Date(a.createTime).getTime();
          s2b = new Date(b.createTime).getTime();
        }
        if (s1a === -1) {
          s2a = new Date(a.execEndTime).getTime();
          s2b = new Date(b.execEndTime).getTime();
        }
        return -(s2a - s2b);
      }
      return -(s1a - s1b);
    });
  };

  sortTasks = tasks => {
    const sortIndex = {
      urgent: 1,
      mandatory: 0,
      optional: -1
    };

    return toJS(tasks).sort((a, b) => {
      if (!a || !b) return 0;

      const dataA = safeParseJSON(a.data);
      const dataB = safeParseJSON(b.data);

      const s1a = sortIndex[dataA.taskType];
      const s1b = sortIndex[dataB.taskType];

      if (s1a === s1b) {
        const s2a = new Date(a.updateTime).getTime();
        const s2b = new Date(b.updateTime).getTime();
        return -(s2a - s2b);
      }
      return -(s1a - s1b);
    });
  };

  // TODO: Generic function
  handleClockInClockOut = async (shift, group, member, timestamp) => {
    let action;
    const clockState = getTopicCompletionState(shift);
    if (clockState === "new") action = "in";
    if (clockState === "clocked-in") action = "out";

    if (!action) return;

    if (action === "out" && shift.typeId === topicTypeIds.shift) {
      await this.withShiftHourlyRate(member, shift.data).catch(err => {
        throw err;
      });
    }

    return apiService
      .async("PUT", {
        endpoint: mcbEndpointConfig.clock_in_out_shift(
          group.id,
          shift.id,
          member.id,
          action,
          timestamp
        )
      })
      .then(() => {
        if (action === "in") {
          // Move to tasks list after clocking-in.
          // const subTopics = clientController.findSubTopicByParentId(shift.id);
          // subTopics.length > 0 &&
          //   (stateController.viewTopicState.topTabs[shift.id] = "task");
          stateController.viewTopicId = shift.id;
          return NavigationService.navigate("Topic", { topic: shift.id });
        }
      });
  };

  shiftInstanceEditPopup = async () => {
    return new Promise((resolve, reject) => {
      stateController.showPopup({
        title: capitalize(UIText.shiftMakerShiftTemplate),
        content: UIText.shiftMakerEditInstance,
        leftButtonText: UIText.generalCancel,
        leftButtonPress: () => stateController.dismissPopup().then(resolve),
        rightButtonText: UIText.generalConfirm,
        rightButtonPress: () =>
          stateController.dismissPopup().then(() => resolve(true))
      });
    });
  };

  withShiftHourlyRate = async (member, shiftData) => {
    const setHourlyRate = (profileData, shiftData) => ({
      ...shiftData,
      hourlyRate: Number(profileData["discountedHourlyRate"]) || 0
    });
    const parseHourlyRate = fields => {
      const nhr = fields.find(f => f.name === "negotiatedHourlyRate");
      const phr = fields.find(f => f.name === "publishedHourlyRate");
      const cdf = fields.find(f => f.name === "chargeDiscountFactor");
      const dhr = fields.find(f => f.name === "discountedHourlyRate");
      if (!phr || !nhr) return fields;
      const phrLinkage = phr["linkedFields"];
      const publishedHourlyRate = new Function(
        phrLinkage.name,
        `return ${phrLinkage.modifier}`
      )(nhr.value);
      if (isNaN(publishedHourlyRate)) return fields;
      phr.value = publishedHourlyRate;
      const dhrLinkage = dhr["linkedFields"];
      const discountedHourlyRate = new Function(
        dhrLinkage.name.join(","),
        `return ${dhrLinkage.modifier}`
      )(publishedHourlyRate, cdf.value);
      if (isNaN(discountedHourlyRate)) return fields;
      dhr.value = discountedHourlyRate;
      return fields;
    };

    const { roleList, profileId } = member;
    const profile = await apiController.getProfileById(profileId);
    const { id, typeClassId, data } = profile;
    const isPaidCaregiver = roleList.some(
      r => r.groupTypeRoleId === groupTypeRoleIds.paidCaregiver
    );
    if (!id || isEmpty(data)) return setHourlyRate({}, shiftData);
    if (!isPaidCaregiver) return setHourlyRate({}, shiftData);
    if (
      !!data["discountedHourlyRate"] &&
      !isNaN(Number(data["discountedHourlyRate"]))
    )
      return setHourlyRate(data, shiftData);
    const form = await formService.findFormClassByIdAsync(typeClassId);
    const newProfile = formService.assembleFormData(form, data, {
      override: true
    });
    const newData = formService.disassembleFormData(
      parseHourlyRate(newProfile),
      { displayName: true }
    );
    // if (
    //   !newData["discountedHourlyRate"] ||
    //   isNaN(newData["discountedHourlyRate"])
    // ) {
    //   return Promise.reject(UIText.shiftMissingHourlyRate);
    // }
    if (
      !newData["discountedHourlyRate"] ||
      isNaN(newData["discountedHourlyRate"])
    ) {
      return setHourlyRate({}, shiftData);
    }
    return apiService
      .async("PATCH", {
        endpoint: endpointConfig.profile_by_id(id),
        data: { data: JSON.stringify(newData) }
      })
      .then(() => setHourlyRate(newData, shiftData));
  };

  static extractClockInOutFieldsValue = (
    clockInField,
    clockOutField,
    timeZone
  ) => {
    // const offset = minutesToMilli(new Date().getTimezoneOffset());
    const clockInDate = new Date(`${clockInField.value}Z`);
    const clockOutDate = new Date(`${clockOutField.value}Z`);
    const startOffset = -getUTCOffsetMilli(timeZone, clockInDate);
    const endOffset = -getUTCOffsetMilli(timeZone, clockOutDate);
    const correctedStart =
      clockInField.value && new Date(clockInDate.getTime() + startOffset);
    const correctedEnd =
      clockOutField.value && new Date(clockOutDate.getTime() + endOffset);
    const start = correctedStart && correctedStart.getTime();
    const end = correctedEnd && correctedEnd.getTime();
    return { start, end };
  };
}
