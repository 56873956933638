import { apiController } from "../../../../cdm/controllers/api-controller";
import { contextReject, isEqual } from "../../../../utils/helpers";
import { formService } from "../../../../cdm/services/form-service";
import { action, autorun, computed, decorate, observable } from "mobx";
import { AdminCaregiverOnboardingForm } from "./admin-caregiver-onboarding-form";
import { stateController } from "../../../../cdm/controllers/state-controller";
import {
  adminCentreOnboardingFormAutoFieldControl,
  adminCentreOnboardingHandleProfileChange,
  adminCentreOnboardingHandleSaveButton
} from "./index";
import { UIText } from "../../../../config/lang-config";
import { apiService } from "../../../../cdm/services/api-service";
import { mcbEndpointConfig } from "../../config/api-config";
import { computedFn } from "mobx-utils";

export const getVariousOnboardingData = onboarding => {
  const {
    applicationDate,
    questionnaireResponseLink,
    questionnaireContactId
  } = onboarding;

  const isMcbHosted = !questionnaireContactId;

  const questionnaireCompleteDate =
    onboarding &&
    onboarding.screeningStatusDates &&
    onboarding.screeningStatusDates.find(
      date => date.screeningStatus === "questionnaire completed"
    );

  const questionnaireResponses =
    isMcbHosted &&
    onboarding &&
    Array.isArray(onboarding.questionnaireResponse) &&
    onboarding.questionnaireResponse.filter(
      response => response.type !== "jobReference"
    );

  const questionnaireScorePercent = isMcbHosted
    ? (() => {
        const screeningQuestionResponses = (
          questionnaireResponses || []
        ).filter(response => response.type === "screening");
        const maxScore = screeningQuestionResponses.length * 3;
        let responseScore = 0;
        screeningQuestionResponses.forEach(
          response => (responseScore += 3 - response.responseIndex)
        );
        return `${Number((responseScore / maxScore).toFixed(2)) * 100}%`;
      })()
    : onboarding &&
      onboarding.questionnaireResponse &&
      onboarding.questionnaireResponse["quiz_score_as_percentage"];

  const referencesContactDate =
    onboarding &&
    onboarding.screeningStatusDates &&
    onboarding.screeningStatusDates.find(
      date => date.screeningStatus === "references contacted"
    );

  const referencesResponseCount =
    referencesContactDate &&
    onboarding &&
    onboarding.jobReferenceResponses &&
    onboarding.jobReferenceResponses.length;
  const referencesResponded =
    referencesResponseCount &&
    onboarding.jobReferenceResponses.filter(
      j => j.responseStatus === "received"
    ).length;
  const referencesResponseStatus =
    referencesResponseCount &&
    `${referencesResponded}/${referencesResponseCount}`;

  const completionDate =
    onboarding &&
    onboarding.screeningStatusDates &&
    onboarding.screeningStatusDates.find(
      date =>
        date.screeningStatus === "completed" ||
        date.screeningStatus === "approved"
    );

  return {
    isMcbHosted,
    applicationDate,
    questionnaireCompleteDate,
    questionnaireScorePercent,
    questionnaireResponseLink,
    questionnaireResponses,
    referencesContactDate,
    referencesResponseCount,
    referencesResponded,
    referencesResponseStatus,
    completionDate
  };
};

const adminCentreInjectNewRecruitForm = async (
  screenId,
  card,
  isRefresh,
  showError
) => {
  const refreshProfile = async () =>
    apiController
      .getProfileById(card.profile.id)
      .then(profile => (card.profile = profile));
  const refreshOnboarding = async () =>
    apiController
      .getAdminOnboardingDataForProfile(card.profile.id)
      .then(onboarding => (card.onboarding = onboarding));
  if (isRefresh) {
    await refreshProfile()
      .then(refreshOnboarding)
      .catch(showError);
  }
  const profileForm = await formService
    .findFormClassByIdAsync(card.profile.typeClassId)
    .then(form =>
      formService.assembleFormData(form, card.profile.data, {
        override: true
      })
    );
  const approvalCheckbox = profileForm.find(
    field => field.name === "approvalOfCandidateConfirmedByReference"
  );
  approvalCheckbox.readonly = !card.onboarding.screeningStatus.match(
    /completed|approved|(references contacted)|(profile requested)/gi
  );
  const onboardingProfileFields = profileForm.filter(
    field => !!field.flags["onboarding"] && field.name !== "interviewNotes"
  );
  const onboardingProfileData = formService.disassembleFormData(
    onboardingProfileFields
  );
  const form = observable(
    formService.assembleFormData(
      AdminCaregiverOnboardingForm(
        card.onboarding,
        onboardingProfileFields,
        card.profile,
        showError
      ),
      {}
    )
  );
  const onboardingDataCopy = formService.disassembleFormData(form);
  Object.freeze(onboardingDataCopy);
  const monitor = {
    get formData() {
      return formService.disassembleFormData(form);
    }
  };
  decorate(monitor, { formData: computed });
  autorun(() =>
    adminCentreOnboardingFormAutoFieldControl(form, monitor, onboardingDataCopy)
  );
  const saveButton = observable({ loading: false });
  const fieldProps = {
    showLabel: true,
    showTitle: true,
    enabledGetter: computedFn(field => !field.readonly),
    valueGetter: computedFn(field =>
      field.value ? field.value : field.readonly ? "—" : ""
    ),
    onInputChange: action((e, field) => {
      field.value = e.nativeEvent.text;
    }),
    onCheckboxChange: action(field => {
      field.value = !field.value;
    }),
    onPickerChange: action((value, field) => {
      field.value = value;
    }),
    nonEditModeHideNoValue: true
  };
  const onSave = async () => {
    saveButton.loading = true;
    return stateController
      .showPopup({
        title: UIText.adminCentre,
        content: UIText.pleaseWait
      })
      .then(async () => {
        const changed = Object.keys(monitor.formData).filter(
          key => !isEqual(monitor.formData[key], onboardingDataCopy[key])
        );
        await adminCentreOnboardingHandleProfileChange(
          card,
          monitor,
          changed,
          onboardingDataCopy,
          onboardingProfileData,
          refreshProfile
        ).catch(contextReject);
        if (
          changed.includes("updateScreeningStatus") ||
          changed.includes("approvalOfCandidateConfirmedByReference")
        ) {
          let screeningStatus = changed.includes("updateScreeningStatus")
            ? monitor.formData["updateScreeningStatus"].toLowerCase()
            : monitor.formData["approvalOfCandidateConfirmedByReference"]
            ? // ? "completed" // Updated 20220513 for better descriptive onboarding statuses
              "approved"
            : undefined;
          if (!screeningStatus) return;
          return apiService.async("PATCH", {
            endpoint: mcbEndpointConfig.caregiver_new_onboarding_modify_screening_status(
              card.onboarding.id
            ),
            data: { screeningStatus }
          });
        }
      })
      .then(() => (stateController.viewProfileState.autoEdit = true))
      .then(() =>
        adminCentreInjectNewRecruitForm(screenId, card, true, showError)
      )
      .then(stateController.dismissPopup)
      .catch(showError)
      .finally(() => {
        saveButton.loading = false;
        stateController.viewProfileState.refresh();
      });
  };
  stateController.viewProfileState.rightButtonOverride[screenId] = {
    handlePress: () =>
      adminCentreOnboardingHandleSaveButton(
        screenId,
        saveButton,
        monitor,
        onboardingDataCopy,
        onSave
      )
  };
  stateController.viewProfileState.additionalForm[screenId] = {
    heading: "Caregiver onboarding data",
    form,
    fieldProps
  };
};

export default adminCentreInjectNewRecruitForm;
