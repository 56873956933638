import { theme } from "../../../../config/style-configs/theme";
import { extraStyles as ratingReviewStyles } from "../../../../screens/auth/RatingReviewScreen/styles";
import { responsive } from "../../../../config/style-configs/responsive";
import { computed, decorate } from "mobx";

export const styles = {
  inputTitle: {
    marginLeft: 5,
    marginBottom: 10,
    color: theme.color,
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  ratingReviewSmallCard: {
    width: "100%",
    marginVertical: 15,
    padding: 10,
    borderWidth: 1,
    borderRadius: theme.FONT_SIZE_SMALL,
    borderColor: theme.color
  },
  ratingReviewSmallCardToolbar: {
    width: "100%",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 10
  },
  isPublishedToggle: {
    minWidth: 100,
    alignItems: "center",
    justifyContent: "center"
  },
  isPublishedCheckbox: {
    padding: 2,
    marginLeft: 0,
    marginRight: 0,
    margin: 0
  },
  get ratingReviewSmallCardStarRatings() {
    return {
      ...ratingReviewStyles.starRatings,
      width: undefined,
      justifyContent: "flex-start",
      paddingVertical: 0,
      ...(!responsive.deviceDimension.isDesktop && {
        marginBottom: 10
      })
    };
  },
  ratingReviewSmallCardGroupName: {
    fontWeight: "600",
    color: theme.color
  },
  get ratingReviewSmallCardComment() {
    return {
      ...styles.inputTitle,
      color: undefined
    };
  },
  get ratingReviewSmallCardText() {
    return {
      ...styles.inputTitle,
      fontSize: theme.FONT_SIZE_SMALL,
      marginBottom: 0
    };
  },
  get ratingReviewSmallCardAuthor() {
    return {
      ...styles.ratingReviewSmallCardComment,
      color: theme.color
    };
  }
};

decorate(styles, {
  ratingReviewSmallCardStarRatings: computed
});
