import { Observer, observer } from "mobx-react";
import React from "react";
import { ActivityIndicator, View } from "react-native";
import { theme } from "../../../../config/style-configs/theme";
import {
  capitalize,
  getDisplayNameEng,
  isEmpty
} from "../../../../utils/helpers";
import { CollapsibleAccordionTitle } from "../../../../components/CollapsibleAccordionTitle";
import { UIText } from "../../../../config/lang-config";
import Collapsible from "react-native-collapsible";
import { FormField } from "../../../../components/FormField";
import { Button, Icon, Text } from "react-native-elements";
import { ThemedListItem } from "../../../../components/ThemedListItem";
import { TouchableOpacityIOSFix } from "../../../../components/TouchableOpacityIOSFix";

@observer
class ReportTimeSheetInsertView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentDidUpdate = () => {
    if (this.props.showCollapsible && this.props.collapsed)
      return this.controller._initialize(true);
  };

  renderCompletedShiftSummaryBody = () => (
    <Observer>
      {() => (
        <View>
          <FormField
            field={{
              type: "picker",
              name: "dateBlocks",
              options: this.controller.dateBlockPickerItems,
              placeholder: UIText.reportCompletedShiftDateBlock,
              value: this.controller.selectedDateBlockValue,
              required: true,
              showLabel: true
            }}
            enabled={true}
            onPickerChange={this.controller.handleDateBlockChange}
            fieldStyle={{ maxWidth: "100%" }}
          />
          {this.renderCurrentPeriodIndicator()}
          <View style={this.props.styles.buttonReminder}>
            {!isEmpty(this.controller.selectedDateBlock) &&
            !this.controller.loading ? (
              <Text style={this.props.styles.errorMessage}>
                {this.controller.shouldRenderCaregivers.length > 0
                  ? UIText.reportCompletedShiftAvailReports
                  : UIText.reportCompletedShiftNoReport}
              </Text>
            ) : null}
          </View>
          {this.renderCompletedShiftSummaryMembers(
            this.controller.shouldRenderCaregivers
          )}
          <Button
            type="clear"
            loading={this.controller.loading}
            loadingProps={{ color: theme.color }}
            onPress={() => {}}
          />
        </View>
      )}
    </Observer>
  );

  renderCurrentPeriodIndicator = () => (
    <>
      {!this.controller.timesheetLoading &&
        this.controller.selectedDateBlock.timesheetId === -1 && (
          <View style={this.props.styles.buttonReminder}>
            <Text style={this.props.styles.errorMessage}>
              * {UIText.reportCurrentPeriodOngoing}
            </Text>
          </View>
        )}
    </>
  );

  renderNoAvailableTimesheets = () => (
    <>
      {this.renderCurrentPeriodIndicator()}
      <Text style={this.props.styles.errorMessage}>
        {UIText.reportNoTimesheetsAvailable}
      </Text>
    </>
  );

  renderTimesheetLoading = () => (
    <View style={this.props.styles.loading}>
      <ActivityIndicator size="large" color={theme.color} />
    </View>
  );

  renderCompletedShiftSummaryMembers = members =>
    members.map(member => (
      <Observer key={member.id}>
        {() => (
          <TouchableOpacityIOSFix
            onPress={e =>
              this.controller.handleShiftSummaryCaregiverPress(e, member.id)
            }
          >
            <ThemedListItem
              leftAvatar={member.avatar}
              title={getDisplayNameEng(member.profile)}
              titleId={member.profileId}
              subtitle={
                member.email || (member.phone && member.phone.toString()) || ""
              }
              rightElement={
                <Icon name="navigate-next" size={24} color="#666" />
              }
            />
          </TouchableOpacityIOSFix>
        )}
      </Observer>
    ));

  render() {
    const TimesheetComponent = this.controller.timesheetLoading
      ? this.renderTimesheetLoading()
      : isEmpty(this.controller.timesheetsForCareCircle)
      ? this.renderNoAvailableTimesheets()
      : this.renderCompletedShiftSummaryBody();

    return (
      <View style={this.props.styles.style}>
        {this.props.showGroupName && (
          <View>
            <FormField
              field={{
                type: "input",
                name: "groupName",
                placeholder:
                  this.controller.group["groupTypeName"] ||
                  capitalize(UIText.group),
                showLabel: true
              }}
              inputValue={getDisplayNameEng(this.controller.profile) || ""}
              enabled={false}
              fieldStyle={{ maxWidth: "100%" }}
            />
          </View>
        )}
        {this.props.showCollapsible ? (
          <View style={this.props.styles.style}>
            <CollapsibleAccordionTitle
              title={
                this.props.collapsibleTitle ||
                UIText.reportCompletedShiftTimeSheets
              }
              onTitlePress={this.props.handleCollapse}
              collapsed={!this.props.collapsed}
            />
            <Collapsible collapsed={!this.props.collapsed}>
              {TimesheetComponent}
            </Collapsible>
          </View>
        ) : (
          TimesheetComponent
        )}
        {this.props.showPayrollOption && (
          <View>
            <FormField
              field={{
                type: "checkbox",
                set: "payroll",
                title: UIText.adminCentrePayrollOption,
                name: "useExternalPayrollProcessing",
                placeholder: UIText.adminCentreUseExternalPayrollProcessing,
                showLabel: true,
                value: this.controller._useExternalPayrollProcessing
              }}
              showTitle
              enabled
              onCheckboxChange={this.controller.handlePayrollOptionChange}
              fieldStyle={{ maxWidth: "100%" }}
            />
          </View>
        )}
      </View>
    );
  }
}

export { ReportTimeSheetInsertView };
