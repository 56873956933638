import { styles } from "../styles";

export const extraStyles = {
  resetContainer: {
    ...styles.container,
    flex: 1,
    marginTop: 20
  }
};

export { styles };
