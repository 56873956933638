import { clientController } from "../../../cdm/controllers/client-controller";
import { autorun, computed, observable, toJS } from "mobx";
import { stateController } from "../../../cdm/controllers/state-controller";
import { UIText } from "../../../config/lang-config";
import { capitalize, validateEmail } from "../../../utils/helpers";
import { apiService } from "../../../cdm/services/api-service";
import { endpointConfig } from "../../../config/api-config";
import NavigationService from "../../../utils/navigation-service";
import { apiController } from "../../../cdm/controllers/api-controller";
import publicIp from "react-native-public-ip";

export class VisitorController {
  @observable loading = false;
  @observable visitorSigningIn = false;

  @observable buttonDisabled = true;
  @observable knownAccount = false;
  _knownAccount = false;
  @observable modeSwitched = false;

  @observable agreeTnc = false;
  @observable fieldData = {};
  @observable error = {
    email: "",
    firstName: ""
  };

  @computed get required() {
    return [
      !this.anonymousMode && "email",
      !this.anonymousMode && "firstName"
    ].filter(Boolean);
  }

  @computed get anonymousMode() {
    // return this.props.navigation.getParam("anonymous");
    return (
      !this.props.navigation.getParam("withEmail") &&
      !this.props.navigation.getParam("withemail")
    );
  }

  constructor(props) {
    this.props = props;
    stateController.currentScreen = "Registration";
    this._initialize();
  }

  componentWillUnmount = () => {
    this.disposer && this.disposer();
  };

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: capitalize(UIText.visitor),
      content:
        (err.response && JSON.stringify(err.response.data)) || err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _initialize = () => {
    this.disposer = autorun(() => {
      toJS(this.fieldData.email);
      this.modeSwitched = this._knownAccount !== this.knownAccount;
      this._knownAccount = this.knownAccount;
    });

    this.sourceUrl =
      this.props.navigation.getParam("source") ||
      this.props.navigation.getParam("sourceUrl");

    if (this.anonymousMode && !stateController.visitorLock) {
      stateController.visitorLock = true;
      setTimeout(this.execVisitMarket);
    }
  };

  updateFields = (field, event) => {
    if (!event) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      this.fieldData[field] = event.nativeEvent.text;
    this.validateFields();
  };

  validateFields = () => {
    if (this.knownAccount) {
      return (this.buttonDisabled =
        this.error.email || !this.fieldData.email || !this.fieldData.password);
    }

    let disabled = false;
    for (let field in this.error) {
      if (!!this.error[field]) disabled = true;
    }
    for (let field in this.required) {
      let data = this.fieldData[this.required[field]];
      if (!data || data === "" || data === undefined) disabled = true;
    }
    if (!this.agreeTnc) disabled = true;

    return (this.buttonDisabled = disabled);
  };

  validateEmail = () => {
    if (!validateEmail(this.fieldData.email)) {
      const isPlusSignError = (this.fieldData.email || "").match(/\+/);
      return (this.error.email = isPlusSignError
        ? UIText.registrationEmailNoPlusSign
        : `${UIText.entryRequiringValid} ${
            UIText.registrationUniqueFields.email
          }.`);
    }
  };

  onEmailChange = clear => {
    if (clear) {
      this.error.email = "";
      this.knownAccount = false;
    }
    clearTimeout(this.typingTimeout);
    this.typingTimeout = setTimeout(this.checkAccountExists, 150);
  };

  onCheckboxChange = event => {
    this.agreeTnc = !this.agreeTnc;
    this.validateFields();
  };

  checkAccountExists = () => {
    clientController.execLogout();

    return apiController
      .checkExist("email", this.fieldData.email)
      .then(result => {
        this.knownAccount = !!result.exist;
        if (result.error) throw result.error;
      })
      .catch(this._showError);
  };

  openAgreement = () => {
    return clientController.openAgreement();
  };

  execVisitMarket = event => {
    this.visitorSigningIn = true;
    return (
      stateController
        .showPopup({
          title: capitalize(UIText.visitor),
          content: UIText.pleaseWait
        })
        // .then(this.saveVisitorInfo)
        // .then(this.knownAccount ? this.execSignIn : this.execVisitorSignIn)
        .then(this.visitorDeviceIdLogin)
        .then(stateController.dismissPopup)
        .catch(this._showError)
        .finally(() => (this.visitorSigningIn = false))
    );
  };

  saveVisitorInfo = async () => {
    let ipAddress;

    await publicIp()
      .then(async ip => await (ipAddress = ip))
      .catch(console.warn);

    const { email, firstName } = this.fieldData;
    const isVisitor = !this.knownAccount;
    const sourceUrl = this.sourceUrl || "";
    const deviceId = clientController.deviceId;

    const data = {
      email,
      firstName,
      isVisitor,
      ipAddress,
      deviceId,
      sourceUrl
    };

    return apiService
      .async("POST", {
        endpoint: endpointConfig.save_visitor_info,
        data
      })
      .then(response => response.data && response.data.oauth)
      .catch(this._showError);
  };

  visitorDeviceIdLogin = async () => {
    const ipAddress = await publicIp().catch(console.warn);
    const data = {
      ipAddress,
      sourceUrl: this.sourceUrl,
      deviceId: clientController.deviceId,
      clientType: "webapp"
    };
    return apiService
      .async("POST", {
        endpoint: endpointConfig.visitor_device_id_login,
        data
      })
      .then(response => {
        const { oauth, scratchpadId } = response.data || {};
        return clientController
          .isMaintenance()
          .then(() => clientController.updateOAuth2Data(oauth))
          .then(clientController.initializeClient)
          .then(stateController.dismissPopup);
      });
  };

  execSignIn = () => {
    stateController.base64form = {
      email: this.fieldData.email,
      password: this.fieldData.password
    };
    stateController.autoSignIn = true;

    this.invokeAfterLoginMarketplace();

    return this.props.navigation.navigate("Login");
  };

  execVisitorSignIn = OAuth2Data => {
    return clientController
      .isMaintenance()
      .then(() => clientController.updateOAuth2Data(OAuth2Data))
      .then(this.invokeAfterLoginMarketplace)
      .then(clientController.initializeClient)
      .then(stateController.dismissPopup);
  };

  invokeAfterLoginMarketplace = () =>
    autorun(reaction => {
      if (
        clientController.loginState &&
        clientController.subscriptionReady &&
        clientController.invitationsReady
      ) {
        stateController.dismissPopup().then(() =>
          stateController.showPopup({
            title: capitalize(UIText.visitor),
            content: UIText.pleaseWait,
            locked: true
          })
        );
        setTimeout(() => {
          stateController.unlockPopup();
          stateController.viewGroupId = -1;
          NavigationService.navigate("Root");
        }, 1000);
        reaction && reaction.dispose();
      }
    });
}
