import React from "react";
import { observer } from "mobx-react";
import { View } from "react-native";
import { observable, toJS } from "mobx";
import { Text } from "react-native-elements";
import {
  getDisplayNameEng,
  isEmpty,
  whenFulfill
} from "../../../../utils/helpers";
import {
  renderQuestionnaireResponseQuestions,
  renderResponsesHtml
} from "../../lib/admin-centre-utilities";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { UIText } from "../../../../config/lang-config";
import { common } from "../../../../config/style-configs/common";

@observer
class AdminQuestionnaireResponsesView extends React.Component {
  @observable questions = [];
  @observable questionnaireResponses = [];

  constructor(props) {
    super(props);
    this.renderQuestionsFromServer().catch(props.showError);
    this.questionnaireResponses.replace(props.questionnaireResponses || []);
  }

  renderQuestionsFromServer = async () =>
    renderQuestionnaireResponseQuestions().then(
      questions => (this.questions = questions)
    );

  openResponses = async () => {
    await whenFulfill(() => !isEmpty(this.questions), 10);
    return stateController.showPopup({
      title: UIText.caregiverJobReferenceFrom(
        getDisplayNameEng(this.props.profile)
      ),
      contentHtml: renderResponsesHtml(
        toJS(
          this.questionnaireResponses.map(response => ({
            text: response.questionText
          }))
        ),
        toJS(
          this.questionnaireResponses.map(response => {
            const questionSet = this.questions.find(
              q => q.questionText === response.questionText
            );
            if (!questionSet) return;
            return (
              response.responseText ||
              `${response.responseIndex + 1}) ${
                questionSet.responseTexts[response.responseIndex]
              }`
            );
          })
        )
      ),
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        {isEmpty(this.questionnaireResponses) ? (
          <Text style={this.props.styles.errorMessage}>No responses</Text>
        ) : (
          <Text
            style={{
              ...common.link,
              marginTop: 5
            }}
            onClick={this.openResponses}
          >
            See responses
          </Text>
        )}
      </View>
    );
  }
}

export { AdminQuestionnaireResponsesView };
