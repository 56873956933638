import { clientController } from "../../../../../../cdm/controllers/client-controller";
import { apiService } from "../../../../../../cdm/services/api-service";
import { endpointConfig } from "../../../../../../config/api-config";
import { transportationForm } from "./form";
import {
  groupTypeIds,
  groupTypeRoleIds,
  typeClassIds
} from "../../../../../../config/variable-config";
import { formService } from "../../../../../../cdm/services/form-service";
import { getDisplayNameEng, isEmpty } from "../../../../../../utils/helpers";
import { apiController } from "../../../../../../cdm/controllers/api-controller";
import { UIText } from "../../../../../../config/lang-config";
import { autorun, observable } from "mobx";
import { typeClassLibrary } from "../../../../../../cdm/lib/typeClass-utilities";

const createGroupProfile = async (group, profileDetail) => {
  let profile = {
    type: 3, // Group Member shared profile
    data: JSON.stringify(profileDetail),
    typeClassId: typeClassIds.caregiverProfile,
    typeClassVersion: 3 // Default version for now
  };
  return await apiService
    .async("POST", {
      endpoint: endpointConfig.create_group_profile,
      data: {
        group,
        profile
      }
    })
    .then(response => {
      const group = response.data["groupResponseDTO"];
      if (!group)
        return Promise.reject({
          message:
            "Something happened and setup was not successful. [Empty group data]"
        });
      return [group, profileDetail];
    });
};

const getGroupRoles = async ([group, profileDetail]) => {
  return await apiController.getGroupRolesByGroupId(group.id).then(roles => {
    if (isEmpty(roles)) {
      return Promise.reject({
        message:
          "Something happened and setup was not successful. [Empty roles array]"
      });
    }

    return [group, profileDetail, roles];
  });
};

const createSelfMember = async ([group, profileDetail, roles]) => {
  const groupId = group.id;
  if (!groupId)
    return Promise.reject({
      message:
        "Something happened and setup was not successful. [groupId is null]"
    });
  const member = {
    userId: clientController.userId,
    groupId: groupId,
    profileId: group.profileId,
    email: profileDetail.email,
    phone: profileDetail.phone
  };
  const roleIdList = [
    roles.find(
      role => role["groupTypeRoleId"] === groupTypeRoleIds.transportation
    ).id
  ];

  return await apiService.async("POST", {
    endpoint: endpointConfig.create_member,
    data: {
      member,
      roleIdList
    }
  });
};

// Setup function.
export const transportationSetup = () => {
  const setup = {
    key: "transportationSetup",
    title: "Transportation Service Profile",
    heading: "Create your profile as a Transportation Service",
    checkboxTitle: "I want to setup my Transportation Service profile.",
    form: observable(transportationForm(clientController.defaultMember)),
    renderFlags: ["caregiver"],
    editFlags: ["caregiver"],
    onBlur: field => {
      field.errorMessage = "";
      if (field.required && !field.value)
        field.errorMessage = `Enter a ${(
          field.label || field.placeholder
        ).toLowerCase()}`;
    },
    onSubmit: async ([form, errorFields]) => {
      // Group creation
      if (!form || !isEmpty(errorFields)) {
        return Promise.reject({
          message: UIText.checkEntriesWithFields(errorFields),
          contentAlign: "left"
        });
      }
      try {
        typeClassLibrary.mandatoryAbleMunicipalityValidate(form);
      } catch (e) {
        return Promise.reject(e);
      }

      const profileDetail = formService.disassembleFormData(form, {
        displayName: true,
        autoApprove: true
        // requiredHourlyRate: true
      });
      const email = profileDetail.email;
      let group = {
        typeId: groupTypeIds.transportation,
        groupName: getDisplayNameEng(profileDetail),
        owner: clientController.userId,
        parentId: null
      };
      return apiController
        .checkExist("email", email)
        .then(result => {
          if (result.exist && email !== clientController.client.user.email) {
            return Promise.reject({
              message: "Sorry, the email address is not available."
            });
          }
        })
        .then(() => createGroupProfile(group, profileDetail))
        .then(getGroupRoles)
        .then(createSelfMember)
        .then(clientController.getUserGroupsAndMembers)
        .finally(dispose);
    },
    validate: async form => {
      const pass = formService.validateRequired(form);
      if (!pass) {
        const errorFields = form.filter(f => !!f.errorMessage);
        return [false, errorFields];
      }
      return [form];
    }
  };
  const dispose = autorun(() => {
    typeClassLibrary.autoSelectEnglish(setup.form);
  });
  return setup;
};
