import React from "react";
import { PlanScreen } from "./view";
import { PlanController } from "./controller";
import { styles } from "./styles";
import NavigationService from "../../../utils/navigation-service";

export class Plan extends React.Component {
  static navigationOptions = {
    title: "Plan"
  };

  constructor(props) {
    super(props);
    this.controller = new PlanController(props);
  }

  render() {
    const { navigation } = this.props;

    NavigationService.setTopLevelNavigator(navigation);

    return (
      <PlanScreen
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
