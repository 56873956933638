import React from "react";
import { View } from "react-native";
import { observer } from "mobx-react";
import { createSwitchNavigator } from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";
import {
  routeConfig,
  webNavigatorConfig
} from "../../../config/navigator-configs/app-navigator-config";
import { DrawerContainer } from "../../../components/DrawerContainer/index";
import NavigationService from "../../../utils/navigation-service";
import { UIText } from "../../../config/lang-config";
import { DrawerContent } from "../../../components/DrawerContent";

const AppNavigator = createSwitchNavigator(routeConfig, webNavigatorConfig);

@observer
class AppContainer extends React.Component {
  static router = AppNavigator.router;

  // Need to move this into nested screens.
  static navigationOptions = {
    title: UIText.title
  };

  render() {
    const { navigation } = this.props;
    const flexStyle = {
      flex: 1,
      paddingLeft: 0
    };

    NavigationService.setTopLevelNavigator(navigation);

    return (
      <View style={flexStyle}>
        <DrawerContainer sidebar={<DrawerContent navigation={navigation} />} />
        <AppNavigator navigation={navigation} />
      </View>
    );
  }
}

export const App = createBrowserApp(AppContainer);
