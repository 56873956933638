import React from "react";
import { autorun, observable } from "mobx";
import { observer } from "mobx-react";
import { View } from "react-native";
import Cropper from "react-easy-crop";

@observer
class ImageCropperView extends React.Component {
  @observable image;
  @observable zoom = 2;
  @observable crop = { x: 0, y: 0 };

  constructor(props) {
    super(props);
    this.disposer = autorun(this._loadImage);
    this.returnCropResult = this.props.onCropComplete || (() => {});
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  _loadImage = () => {
    this.img = new Image();
    this.img.src = this.props.source;
    this.onCropChange({ x: 0, y: 0 });
    this.onZoomChange(2);
  };

  onCropChange = crop => {
    this.props.onCropChange && this.props.onCropChange(crop);
    this.crop = crop;
  };

  onZoomChange = zoom => (this.zoom = zoom);

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;
    ctx.drawImage(
      this.img,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    if (this.props.dataType === "blob") {
      return canvas.toBlob(this.returnCropResult);
    }
    return (
      this.props.onCropComplete && this.props.onCropComplete(canvas.toDataURL())
    );
  };

  render() {
    return (
      <View
        style={{
          ...this.props.styles.style,
          ...this.props.style
        }}
      >
        <Cropper
          image={this.props.source}
          crop={this.crop}
          zoom={this.zoom}
          minZoom={this.props.minZoom}
          maxZoom={this.props.maxZoom}
          aspect={this.props.aspect || 1}
          cropSize={this.props.cropSize}
          cropShape={this.props.cropShape}
          showGrid={this.props.showGrid}
          onCropChange={this.onCropChange}
          onCropComplete={this.onCropComplete}
          onZoomChange={this.onZoomChange}
        />
      </View>
    );
  }
}

export { ImageCropperView };
