import React from "react";
import { View } from "react-native";
import { observer, Observer } from "mobx-react";
import { computed } from "mobx";
import { Avatar } from "../Avatar";
import { fileService } from "../../cdm/services/file-service";

@observer
class AvatarHistoryPickerView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  @computed get shouldRenderAvatars() {
    return this.controller.avatars
      .filter(a => a.id !== this.props.currentAvatarId)
      .slice(-this.controller.limit);
  }

  renderUserAvatars = avatars =>
    avatars.map((avatar, i) => (
      <Observer key={avatar.id || i}>
        {() => (
          <Avatar
            style={this.props.styles.avatar}
            size={this.props.size || 30}
            rounded
            source={{
              uri: fileService.getProfileAvatarUri(avatar.id, null, null)
            }}
            onPress={() => this.props.onAvatarPress(avatar.id)}
          />
        )}
      </Observer>
    ));

  render() {
    if (this.props.hidden) return null;

    return (
      <View
        style={{
          ...this.props.styles.style,
          ...this.props.style
        }}
      >
        {this.renderUserAvatars(this.shouldRenderAvatars)}
      </View>
    );
  }
}

export { AvatarHistoryPickerView };
