import { theme } from "../../config/style-configs/theme";

export const styles = {
  resultTypeTitleContainer: {
    backgroundColor: "#eee",
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: "100%"
  },
  resultTypeTitle: {
    fontSize: theme.FONT_SIZE_SMALL,
    fontWeight: "600",
    color: "#333"
  },
  resultDetailContainer: {
    paddingLeft: 58,
    paddingRight: 60,
    paddingBottom: 10
  },
  titleText: {
    fontSize: theme.FONT_SIZE_MEDIUM + 1,
    marginRight: 10,
    marginLeft: 20
  },
  highlightedText: {
    backgroundColor: "yellow"
  },
  detailContainer: {
    paddingVertical: 5
  },
  detailPlaceholder: {
    fontWeight: "600",
    marginBottom: 3,
    fontSize: theme.FONT_SIZE_SMALL
  },
  detailText: {
    fontSize: theme.FONT_SIZE_SMALL
  }
};
