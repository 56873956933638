import { computed } from "mobx";
import { clientController } from "../../../../cdm/controllers/client-controller";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { capitalize } from "../../../../utils/helpers";
import { UIText } from "../../../../config/lang-config";

export class CareReceiverReportListController {
  // Current view group.
  @computed get groupId() {
    return stateController.viewGroupId;
  }
  @computed get group() {
    return clientController.findGroupById(this.groupId);
  }

  // Completed shifts summary part.
  get ShiftReportState() {
    if (!stateController.viewGroupState.reportState[this.groupId]) return {};
    if (
      !stateController.viewGroupState.reportState[this.groupId][
        "completedShift"
      ]
    )
      stateController.viewGroupState.reportState[this.groupId][
        "completedShift"
      ] = {};
    return {
      get: property =>
        (stateController.viewGroupState.reportState[this.groupId] || {})[
          "completedShift"
        ][property],
      set: (property, value) =>
        ((stateController.viewGroupState.reportState[this.groupId] || {})[
          "completedShift"
        ][property] = value)
    };
  }

  @computed get collapsible() {
    if (!stateController.viewGroupState.collapsible["report"][this.group.id])
      stateController.viewGroupState.collapsible["report"][this.group.id] = {};
    return stateController.viewGroupState.collapsible["report"][this.group.id];
  }

  constructor(props) {
    this.props = props;

    if (!stateController.viewGroupState.reportState[this.groupId])
      stateController.viewGroupState.reportState[this.groupId] = {};
    if (
      !stateController.viewGroupState.reportState[this.groupId][
        "completedShift"
      ]
    )
      stateController.viewGroupState.reportState[this.groupId][
        "completedShift"
      ] = {};
  }

  _showError = err => {
    console.warn(err);
    return stateController.showPopup({
      title: capitalize(UIText.group),
      content: (err && err.message) || err,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: stateController.dismissPopup
    });
  };

  handleCollapse = reportName => {
    stateController.viewGroupState.collapsible["report"][this.group.id][
      reportName
    ] = !this.collapsible[reportName];
  };
}
