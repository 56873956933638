import { Platform } from "react-native";
import { env } from "../../config/variable-config";
import { clientController } from "../controllers/client-controller";
import {
  getQueryParameters,
  isEmpty,
  preventDefaultStopProp,
  whenFulfill
} from "../../utils/helpers";
import { autorun, observable, reaction, toJS } from "mobx";
import { stateController } from "../controllers/state-controller";
import NavigationService from "../../utils/navigation-service";
import { apiService } from "./api-service";

class EmbeddedService {
  parentOrigin;
  @observable isEmbedded = false;
  @observable isSyncMode = false;
  @observable initialized = false;

  eventListeners = [];

  constructor() {
    // Detect if is being iframed.
    if (Platform.OS !== "web") return this.reportEmbeddedStatus();
    if (window.self === window.parent) {
      this.setStorageDriverOAuthMonitor();
      return this.reportEmbeddedStatus();
    }

    console.log("embeddedService", "Web app embedded mode detected");
    this.isEmbedded = true;
    this.isSyncMode = !!(getQueryParameters(window.location.search) || {})
      .bg_sync_mode;
    if (this.isSyncMode) {
      console.log("embeddedService", "Web app background sync mode detected");
      this.setStorageDriverEventEmitter();
      this.checkWebKitStorageAccessExp();
    }
    this.reportEmbeddedStatus();
    this._initialize();
  }

  _reportClientController = () =>
    clientController.registerEmbeddedStatus(this.isEmbedded, this.isSyncMode);

  _reportApiService = () =>
    apiService.registerEmbeddedStatus(this.isEmbedded, this.isSyncMode);

  reportEmbeddedStatus = () => {
    this._reportApiService();
    this._reportClientController();
  };

  _initialize = () => {
    this._registerListeners();
    this._getParentOrigin();
  };

  _registerListeners = () => {
    window.addEventListener("message", this.processParentMessage);
  };

  _getParentOrigin = () => this.postMessage({ getOrigin: true }, true);

  _registerAutoEvents = () =>
    (this.disposers = [
      autorun(this.postInitialization),
      autorun(this.postDeviceId),
      autorun(this.postClientId),
      autorun(this.postLoggedInUser),
      // autorun(this.postOAuthData),
      autorun(this.postIsVisitor),
      autorun(this.postCurrentViewGroup),
      autorun(this.postDefaultGroup)
    ]);

  postMessage = (data, wildcard) => {
    if (!this.parentOrigin && !wildcard) return;
    return window.parent.postMessage(
      {
        fromMCB: true,
        timestamp: new Date().getTime(),
        app2FrameParent: true,
        data
      },
      wildcard ? "*" : this.parentOrigin
    );
  };

  processParentMessage = event => {
    preventDefaultStopProp(event);
    const { data } = event;
    if (!data || !data.fromMCB || !data.frame2AppChild) return;
    const messageData = data.data;
    console.log(`web-component (parent) -> web app:`, messageData);
    return this.handleParentMessage(messageData);
  };

  handleParentMessage = message => {
    if (!message) return;
    const types = Object.keys(message);
    for (const type of types) {
      this.processParentData(type, message[type]);
    }
  };

  processParentData = (type, data) => {
    if (type === "parentOrigin") {
      if (isEmpty(data)) return this.dispose();
      this.updateParentOrigin(data);
      this.initialized = true;
      if (this.isSyncMode) {
        this.postParentSelfOrigin();
        this.postStorageDriverData();
      }
      return this._registerAutoEvents();
    }
    if (type === "refreshToken") {
      return clientController
        .renewOAuth2Data(data)
        .catch(this.postRefreshFailure)
        .then(clientController.fullClientReset)
        .then(() => {
          this.isSyncMode && this.postStorageDriverData();
          this.isSyncMode && this.setStorageDriverEventEmitter();
        });
    }
    if (type === "getOAuthData") {
      return whenFulfill(() => clientController.initialized).then(
        this.postOAuthData
      );
    }
    if (type === "oauth") {
      apiService.updateOAuthData(data);
      return clientController.updateOAuth2Data(data);
    }
    if (type === "openScratchpad") {
      stateController.viewGroupId = -1;
      return NavigationService.navigate("root");
    }
    if (type === "logout") {
      return clientController.execLogout(null, true);
    }
    if (type === "reload") {
      return window.location.reload();
    }

    for (const listener of this.eventListeners) {
      if (type === listener.type) listener.handler(data);
    }
  };

  addEventListener = (type, handler) => {
    if (this.eventListeners.some(listener => listener.type === type)) return;
    return this.eventListeners.push({
      type,
      handler
    });
  };

  setStorageDriverEventEmitter = () => {
    window.removeEventListener("storage", this.postStorageDriverData);
    window.addEventListener("storage", this.postStorageDriverData);
    reaction(
      () => clientController.client.oauth,
      () => setTimeout(this.postStorageDriverData)
    );
  };

  setStorageDriverOAuthMonitor = () => {
    window.removeEventListener("storage", this.handleStorageDriverOAuthChange);
    window.addEventListener("storage", this.handleStorageDriverOAuthChange);
  };

  handleStorageDriverOAuthChange = event => {
    // const { newValue } = event;
    // const data = safeParseJSON(newValue, true);
    // if (isEmpty(data)) return window.location.reload();
    // const { rawData } = data || {};
    // const { oauth } = rawData || {};
    // if (!isEqual(oauth, apiService.OAuth2Data)) {
    //   if (!isEmpty(oauth)) return clientController.fullClientReset();
    //   return window.location.reload();
    // }
  };

  checkWebKitStorageAccessExp = () => {
    document.hasStorageAccess &&
      document.hasStorageAccess().then(console.log, console.error);
  };

  updateParentOrigin = origin => (this.parentOrigin = origin);

  postParentSelfOrigin = () =>
    this.postMessage({
      childOrigin: `${window.location.protocol}//${window.location.host}`
    });

  postInitialization = () =>
    this.postMessage({
      status: { initialized: toJS(clientController.initialized) }
    });

  postRefreshFailure = () => this.postMessage({ refreshFailure: true });

  postDeviceId = () =>
    this.postMessage({ deviceId: toJS(clientController.deviceId) });

  postClientId = () =>
    this.postMessage({ clientId: toJS(clientController.clientId) });

  postOAuthData = () =>
    this.postMessage({ oauth: toJS(clientController.client.oauth) });

  postLoggedInUser = () =>
    !this.isSyncMode &&
    this.postMessage({ user: toJS(clientController.client.user) || {} });

  postIsVisitor = () =>
    !this.isSyncMode &&
    this.postMessage({ isVisitor: toJS(clientController.isVisitor) });

  postCurrentViewGroup = () =>
    !this.isSyncMode &&
    this.postMessage({
      viewGroup: toJS(
        clientController.findGroupById(stateController.viewGroupId)
      )
    });

  postDefaultGroup = () =>
    !this.isSyncMode &&
    this.postMessage({
      defaultGroup: toJS(clientController.defaultGroup)
    });

  postStorageDriverData = () => {
    clearTimeout(this.storageDriverEmitDebouncer);
    this.storageDriverEmitDebouncer = setTimeout(
      () => this.postMessage({ storage: localStorage.getItem("client") }),
      300
    );
  };

  dispose = () => {
    this.disposers.map(disposer => disposer && disposer());
    this.eventListeners = [];
  };
}

const embeddedService = new EmbeddedService();

// For development;
if (window && env !== "prod") window.embeddedService = embeddedService;

export { embeddedService };
