import React from "react";
import { ActivityIndicator, Image, ScrollView, View } from "react-native";
import { Observer, observer } from "mobx-react";
import { theme } from "../../../config/style-configs/theme";
import { UIText } from "../../../config/lang-config";
import { ThemedButton } from "../../../components/ThemedButton";
import { CheckBox, Divider, Text } from "react-native-elements";
import Icon from "react-native-vector-icons/MaterialIcons";
import { ThemedInput } from "../../../components/ThemedInput";
import { observable } from "mobx";
import {
  getDisplayNameEng,
  isEmpty,
  isNonZeroFalse
} from "../../../utils/helpers";
import { RatingReviewSmallCard } from "../../../custom/mcb/components/RatingReviewSmallCard";
import { RatingReviewInteractiveStar } from "../../../custom/mcb/components/RatingReviewInteractiveStar";
import { getCurrentStarIndex } from "../../../custom/mcb/components/RatingReviewInteractiveStar/view";

@observer
class RatingReviewView extends React.Component {
  @observable starHovers = [false, false, false, false, false];

  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentDidMount() {
    return this.controller.componentDidMount();
  }

  renderHistoricalRatings = ratingReviews => (
    <Observer>
      {() =>
        ratingReviews.map(ratingReview => (
          <Observer key={ratingReview.id}>
            {() => (
              <RatingReviewSmallCard
                showPrivateReview
                ratingReview={ratingReview}
              />
            )}
          </Observer>
        ))
      }
    </Observer>
  );

  renderStar = (index, ratingReview, size) => (
    <RatingReviewInteractiveStar
      key={index}
      index={index}
      size={size}
      ratingReview={ratingReview}
      hoverState={this.starHovers}
      setHoverState={(index, isHover) => (this.starHovers[index] = isHover)}
      onRatingChange={this.controller.onRatingChange}
    />
  );

  render() {
    return this.controller.loading ? (
      <View style={this.props.styles.loading}>
        <ActivityIndicator size="large" color={theme.color} />
      </View>
    ) : (
      <View style={this.props.styles.style}>
        <ScrollView
          style={this.props.styles.scrollView}
          contentContainerStyle={this.props.styles.container}
        >
          <View style={this.props.styles.header}>
            <Image
              source={theme.loginLogo}
              style={this.props.styles.headerLogo}
            />
          </View>

          <View style={this.props.styles.questions}>
            <Text style={this.props.styles.questionHeader}>
              {this.controller.isSubmitted(this.controller.ratingReview)
                ? UIText.ratingReviewSubmittedHeading(
                    getDisplayNameEng(
                      this.controller.ratingReview.authorMember.profile
                    ),
                    getDisplayNameEng(
                      this.controller.ratingReview.targetMember.profile
                    ),
                    getDisplayNameEng(
                      this.controller.ratingReview.reviewerGroup.profile
                    )
                  )
                : UIText.ratingReviewHeading(
                    getDisplayNameEng(
                      this.controller.ratingReview.targetMember.profile
                    ),
                    getDisplayNameEng(
                      this.controller.ratingReview.reviewerGroup.profile
                    )
                  )}
            </Text>
          </View>

          <Observer>
            {() => (
              <View style={this.props.styles.fields}>
                <Text style={this.props.styles.inputTitle}>
                  {UIText.ratingReviewStarRating}
                </Text>
                <View style={this.props.extraStyles.starRatings}>
                  {Array.from(new Array(5)).map((n, i) =>
                    this.renderStar(i, this.controller.ratingReview)
                  )}
                </View>
                <Text style={this.props.extraStyles.starRatingDescription}>
                  "
                  {
                    UIText.ratingReviewRatingDescriptions[
                      getCurrentStarIndex(
                        this.controller.ratingReview,
                        this.starHovers
                      )
                    ]
                  }
                  "
                </Text>
              </View>
            )}
          </Observer>

          <View style={this.props.styles.fields} />

          <Observer>
            {() => (
              <View style={this.props.styles.fields}>
                <Text style={this.props.styles.inputTitle}>
                  {UIText.ratingReviewComments}
                </Text>
                <ThemedInput
                  placeholder={UIText.ratingReviewCommentsPlaceholder}
                  multiline
                  heightOverride={300}
                  showCounter
                  editable={
                    !this.controller.isSubmitted(this.controller.ratingReview)
                  }
                  value={
                    this.controller.ratingReview.review ||
                    (this.controller.isSubmitted(this.controller.ratingReview)
                      ? "⁠—"
                      : "")
                  }
                  onChange={this.controller.onCommentChange}
                />
              </View>
            )}
          </Observer>

          <View style={this.props.styles.fields} />

          <Observer>
            {() => (
              <View style={this.props.styles.fields}>
                <Text style={this.props.styles.inputTitle}>
                  {UIText.ratingReviewPrivateReview}
                </Text>
                {!this.controller.isSubmitted(this.controller.ratingReview) && (
                  <CheckBox
                    title={UIText.ratingReviewLeavePrivateReview}
                    containerStyle={this.props.styles.intentCheckbox}
                    checkedIcon={
                      <Icon name="check-box" size={24} color="black" />
                    }
                    uncheckedIcon={
                      <Icon
                        name="check-box-outline-blank"
                        size={24}
                        color="black"
                      />
                    }
                    checked={this.controller.enablePrivateReview}
                    checkedColor={theme.color}
                    onPress={this.controller.onPrivateReviewToggle}
                  />
                )}

                {(this.controller.enablePrivateReview ||
                  this.controller.isSubmitted(
                    this.controller.ratingReview
                  )) && (
                  <ThemedInput
                    placeholder={UIText.ratingReviewPrivateReviewPlaceholder(
                      this.controller.isCareCircle
                    )}
                    multiline
                    heightOverride={300}
                    showCounter
                    editable={
                      !this.controller.isSubmitted(this.controller.ratingReview)
                    }
                    value={
                      this.controller.ratingReview.privateReview ||
                      (this.controller.isSubmitted(this.controller.ratingReview)
                        ? "⁠—"
                        : "")
                    }
                    onChange={this.controller.onPrivateReviewChange}
                  />
                )}
                {this.controller.isSubmitted(this.controller.ratingReview) && (
                  <View style={this.props.styles.footerNoteContainer}>
                    <Text style={this.props.styles.footerNote}>
                      {UIText.ratingReviewPrivateReviewPlaceholder(
                        this.controller.isCareCircle
                      )}
                    </Text>
                  </View>
                )}
              </View>
            )}
          </Observer>

          {this.controller.isSubmitted(this.controller.ratingReview) && (
            <>
              <View style={this.props.styles.fields} />
              <View style={this.props.styles.fields}>
                <Text style={this.props.styles.inputTitle}>
                  {UIText.ratingReviewSubmissionDate}
                </Text>
                <ThemedInput
                  placeholder="⁠—"
                  editable={false}
                  value={new Date(
                    this.controller.ratingReview.submissionDateLong
                  ).toLocaleString()}
                />
              </View>
            </>
          )}

          <View style={this.props.styles.links}>
            {!this.controller.isSubmitted(this.controller.ratingReview) && (
              <ThemedButton
                style={this.props.styles.button}
                disabled={
                  !this.controller.ratingReview.rating ||
                  this.controller.submitting
                }
                title={UIText.generalSubmit}
                loading={this.controller.submitting}
                onPress={this.controller.execSubmit}
              />
            )}
          </View>

          {!isEmpty(this.controller.historicalRatings) && (
            <Observer>
              {() => (
                <>
                  <Divider style={this.props.styles.divider} />
                  <View style={this.props.styles.fields} />
                  <View style={this.props.styles.questions}>
                    <Text style={this.props.styles.inputTitle}>
                      {UIText.ratingReviewHistoricalRatings}
                    </Text>
                    {this.renderHistoricalRatings(
                      this.controller.historicalRatings
                    )}
                  </View>
                </>
              )}
            </Observer>
          )}
        </ScrollView>
      </View>
    );
  }
}

export { RatingReviewView };
