import { inviteSetup } from "./setup";

export const invitation = () => ({
  rootBack: {
    onPress: props => {
      props.navigation.navigate("Group");
    }
  },
  skipSelect: true,
  setupList: [
    {
      ...inviteSetup()
    }
  ],
  onFinishSetup: props => {
    props.navigation.navigate("Group");
  },
  finishHeading: "The invitation has been sent."
});
