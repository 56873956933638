import { observable } from 'mobx';
import { stateController } from '../../../../cdm/controllers/state-controller';
import { UIText } from '../../../../config/lang-config';
import { apiService } from '../../../../cdm/services/api-service';
import { mcbEndpointConfig } from '../../config/api-config';

export class AdminPromoPageController {
  @observable loading = false;
  @observable promos = [];

  constructor(props) {
    this.props = props;

    this._initialize();
  }

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.adminCentre,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _initialize = async () => {
    this.loading = true;
    return this._getPromos()
      .catch(this._showError)
      .finally(() => (this.loading = false));
  };

  _getPromos = async () =>
    apiService
      .async("GET", {
        endpoint: mcbEndpointConfig.get_all_promos
      })
      .then(response => {
        const promos = response && response.data;
        this.promos = promos || [];
      });

  onRefresh = () => this._initialize();
}
