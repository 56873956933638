import { action, autorun, observable, toJS } from "mobx";
import { clientController } from "../controllers/client-controller";
import {
  getDisplayNameEng,
  isEmpty,
  isEqual,
  safeParseJSON
} from "../../utils/helpers";
import { UIText } from "../../config/lang-config";
import { env, placeholderPickerValue } from "../../config/variable-config";
import { apiController } from "../controllers/api-controller";
import { stateController } from "../controllers/state-controller";
import { fileService } from "./file-service";
import { filterController } from "../controllers/filter-controller";
import { responsive } from "../../config/style-configs/responsive";
import { computedFn } from "mobx-utils";

class FormService {
  @observable typeClasses = [];
  @observable loaded = false;
  buffer = {};

  constructor() {
    setTimeout(this._initializeFormService);
  }

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.title,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _initializeFormService = () => {
    this.disposer = autorun(() => {
      if (
        clientController.initialized &&
        clientController.loginState &&
        !clientController.isSyncMode
      ) {
        this._registerFilterControllerMethods();
        if (this.loaded) return;
        // Read stored messages into observable.
        if (!isEmpty(clientController.client.typeClasses)) {
          // Make a copy
          this.buffer = [...clientController.client.typeClasses];
          // Restore copy
          this.typeClasses = [...this.buffer];
          this.buffer = [];
        }
        // Backward reference to create the link.
        clientController.client.typeClasses = this.typeClasses;
        // Stop further loading.
        this.loaded = true;
      } else {
        // Clear messages on logout
        this.typeClasses = [];
        this.loaded = false;
      }
    });
  };

  _registerFilterControllerMethods = () => {
    filterController.registerGetPickerPlaceholderValue(
      (data, typeClassId, fieldName) =>
        this.getFormPickerPlaceholderValue(data, typeClassId, fieldName)
    );
  };

  assembleFormData = (form, data, options) => {
    options = options || {};
    let formFields = [];
    if (!form || !data) return formFields;
    if (!form.metadata) return formFields;

    formFields =
      (typeof form.metadata === "string"
        ? safeParseJSON(form.metadata)
        : Array.isArray(form.metadata) && form.metadata) || [];

    if (typeof data !== "object") data = safeParseJSON(data);
    if (isEmpty(data)) return formFields;

    for (let field in data) {
      const formField = formFields.find(f => {
        return f.name === field;
      });
      if (formField) {
        if (!formField.value || options.override) formField.value = data[field];
      }
    }
    return options.filter ? formFields.filter(options.filter) : formFields;
  };

  disassembleFormData = (form, options) => {
    options = options || {};
    let data = {};

    if (!Array.isArray(form) || form.length === 0) return data;

    form.map(
      field =>
        field.type !== "link" &&
        field.type !== "text" &&
        field.type !== "button" &&
        field.type !== "collapsibleAnchor" &&
        field.type !== "custom" &&
        (data[field.name] = this.normalizeValue(field))
    );
    if (options["displayName"]) {
      if (data.firstName && data.lastName) {
        data.displayName = getDisplayNameEng(data);
      }
    }
    if (options["autoApprove"]) {
      data["approvalOfCandidateConfirmedByReference"] = true;
    }
    return data;
  };

  findFormClassById = typeClassId => {
    if (!typeClassId && typeClassId !== 0) return {};
    const typeClass = this.typeClasses.find(
      typeClass => typeClass.id.toString() === typeClassId.toString()
    );

    return typeClass || {};
  };

  findFormClassByIdAsync = async typeClassId => {
    if (!typeClassId && typeClassId !== 0) return {};
    const typeClass = this.findFormClassById(typeClassId);
    if (isEmpty(typeClass)) {
      return await this.refreshFormClass(typeClassId);
    }
    return typeClass;
  };

  refreshFormClass = async typeClassId => {
    return await apiController
      .getFormClassById(typeClassId)
      .then(form => formService.updateFormClass(form).then(() => form))
      .catch(this._showError);
  };

  updateFormClass = async data => {
    const typeClassId = data && data.id;
    if (!typeClassId || !data || !clientController.loginState) return;

    const update = { typeClass: this.findFormClassById(typeClassId) };

    if (isEmpty(update.typeClass)) {
      return this.typeClasses.push(data);
    }

    if (isEqual(update.typeClass, data)) return;

    // Use patch style update to prevent data error.
    return (update.typeClass = Object.assign(update.typeClass, data));
  };

  // getFormClassByConditions = conditions => {};

  normalizeValue = field => {
    let value = field.value;
    if (field["normalizer"]) {
      value = new Function("value", `return ${field["normalizer"]}`)(value);
    }
    return (
      value ||
      (typeof value === "undefined" && (field.type === "checkbox" ? false : ""))
    );
  };

  validateRequired = (form, noError) => {
    let pass = true;
    for (let field of form) {
      !noError && (field.errorMessage = "");
      if (
        field.required &&
        !field._disabled &&
        (isEmpty(field.value) || Number(field.value) === placeholderPickerValue)
      ) {
        pass = false;
        !noError &&
          (field.errorMessage = `${UIText.entryRequiring} ${(
            field.label || field.placeholder
          ).toLowerCase()}.`);
      }
    }
    return pass;
  };

  getFormPickerPlaceholderValue = (data, typeClassId, fieldName) => {
    let form = this.findFormClassById(typeClassId);

    if (isEmpty(form)) return "";

    if (isEmpty(data[fieldName])) return "";

    form = this.assembleFormData(form, data, {
      filter: f => f.name === fieldName,
      override: true
    });

    if (isEmpty(form[0])) return "";

    const pickerOption =
      Array.isArray(form[0].options) &&
      form[0].options.find(o => o.name === form[0].value);

    return pickerOption ? pickerOption.placeholder : "";
  };

  getFieldRenderValueModifier = (field, renderFlags) => {
    if (field["renderValueModifier"]) {
      return Array.isArray(field["renderValueModifier"].flags) &&
        field["renderValueModifier"].flags.length > 0
        ? Array.isArray(renderFlags) && renderFlags.length > 0
          ? renderFlags.some(f =>
              field["renderValueModifier"].flags.includes(f)
            )
            ? new Function(
                "value",
                `return ${field["renderValueModifier"].function}`
              )
            : null
          : null
        : new Function(
            "value",
            `return ${field["renderValueModifier"].function}`
          );
    }
  };

  getBlankFormClassById = typeClassId => {
    const form = this.findFormClassById(typeClassId);
    return this.assembleFormData(form, {}) || {};
  };

  onAvatarPickerChange = action((file, field, memberId, popupTitle) => {
    if (!file) return;

    const confirmUpload = file =>
      stateController.dismissPopup().then(() =>
        stateController.showPopup({
          title: popupTitle || UIText.title,
          content: UIText.profileUploadAvatarConfirm(file.name),
          leftButtonText: UIText.generalNo,
          leftButtonPress: stateController.dismissPopup,
          rightButtonText: UIText.generalYes,
          rightButtonPress: () => execUpload(file)
        })
      );

    const execUpload = async file => {
      return stateController
        .dismissPopup()
        .then(() => fileService.uploadAvatar(file, memberId))
        .then(avatar => {
          if (isEmpty(avatar)) {
            return Promise.reject({ message: UIText.fileUploadFailure });
          }
          if (field && avatar.id) {
            field.value = avatar.id;
            field.loading = true;
          }
          return Promise.resolve();
        })
        .then(stateController.dismissPopup)
        .catch(this._showError)
        .finally(() => (field.loading = false));
    };

    return fileService
      .overSizedAvatar(file)
      .then(pass => pass && this.cropAvatar(file, popupTitle))
      .then(confirmUpload);
  });

  cropAvatar = async (file, popupTitle) => {
    const fileReader = new FileReader();
    const fileName = file.name;
    let image = observable({ source: "" });

    const screenWidth = responsive.deviceDimension.width,
      screenHeight = responsive.deviceDimension.height;

    let naturalWidth = (screenWidth > 1024 ? 1024 : screenWidth) / 2;
    naturalWidth = naturalWidth < 300 ? 300 : naturalWidth;
    const naturalHeight = screenHeight / 2 - 100;

    const cropSize = Math.min(naturalWidth, naturalHeight);
    let confirmed;

    return stateController
      .showPopup({
        title: popupTitle,
        content: UIText.pleaseWait
      })
      .then(
        () =>
          new Promise((resolve, reject) => {
            let blob;
            fileReader.addEventListener("load", () => {
              image.source = fileReader.result;
              stateController.dismissPopup().then(() =>
                stateController.showPopup({
                  title: UIText.fileUploadCropImage,
                  cropper: {
                    cropShape: "round",
                    cropSize: { width: cropSize, height: cropSize },
                    source: image.source,
                    dataType: "blob",
                    maxZoom: 10,
                    onCropChange: crop => {
                      stateController.popup.rightButtonDisabled = !confirmed;
                    },
                    onCropComplete: b => {
                      blob = b;
                      blob.name = fileName;
                      confirmed = true;
                      stateController.popup.rightButtonDisabled = false;
                    }
                  },
                  leftButtonText: UIText.generalCancel,
                  rightButtonText: UIText.generalConfirm,
                  rightButtonPress: () => resolve(blob),
                  backdropColor: "#ffffff00"
                })
              );
            });
            try {
              fileReader.readAsDataURL(file);
            } catch (e) {
              reject(e);
            }
          })
      );
  };

  getAvatarTitle = computedFn(form => {
    const data = formService.disassembleFormData(form, { displayName: true });
    return getDisplayNameEng(data)[0];
  });

  getAvatarColorId = computedFn((form, colorId) => {
    const data = formService.disassembleFormData(form, { displayName: true });
    return getDisplayNameEng(data) + (colorId || "");
  });

  helpFocusNextField = (field, formFields, form, submit) => {
    const focusNext = i => {
      const next = formFields[i + 1];
      const nextField = form[i + 1];
      if (next && next.focus) {
        if (
          !nextField.disabled &&
          !nextField.hidden &&
          (nextField.type === "input" || nextField.type === "picker")
        )
          return next.focus();
      }
      if (nextField) {
        return focusNext(i + 1);
      }
      return typeof submit === "function" && submit();
    };
    focusNext(form.indexOf(field));
  };

  queryField = query => {
    const { key, value } = query;
    for (const tp of this.typeClasses) {
      const classes = this.assembleFormData(tp, {});
      const field = classes.find(
        f => f.hasOwnProperty(key) && (!value || f[key] === value)
      );
      const option = classes
        .map(
          f =>
            f.type === "picker" &&
            f.options.some &&
            f.options.map(
              o => o.hasOwnProperty(key) && (!value || o[key] === value) && o
            )
        )
        .flat()
        .filter(Boolean)[0];
      if (field) console.log(toJS(tp), field);
      if (option) console.log(toJS(tp), option);
    }
  };

  hideFieldsByGroupTypeId = (form, groupTypeId) => {
    if (isEmpty(form)) return;
    for (const field of form) {
      if (field.groupTypeIds && !field.groupTypeIds.includes(groupTypeId)) {
        field.hidden = true;
        field.disabled = true;
      }
      if (field.type === "picker") {
        field.options = toJS(field.options).filter(
          option =>
            !option.groupTypeIds || option.groupTypeIds.includes(groupTypeId)
        );
      }
    }
  };
}

export class TypeClassTemplate {
  form = [];
  constructor(typeClassId) {
    autorun(reaction => {
      if (
        !!clientController &&
        clientController.initialized &&
        clientController.loginState &&
        !clientController.isSyncMode
      ) {
        reaction.dispose();
        formService.findFormClassByIdAsync(typeClassId).then(form => {
          this.form = safeParseJSON(form.metadata);
        });
      }
    });
  }
}

const formService = new FormService();

// For development;
if (window && env !== "prod") window.formService = formService;

export { formService };
