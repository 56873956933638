import { autorun, computed, observable, toJS } from "mobx";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { UIText } from "../../../../config/lang-config";
import {
  asyncPause,
  contextReject,
  getDisplayNameEng,
  isEmpty,
  randomString
} from "../../../../utils/helpers";
import { apiService } from "../../../../cdm/services/api-service";
import { apiController } from "../../../../cdm/controllers/api-controller";
import { is3FreePromo, isSnSOrAdmin } from "../../utils/helper";
import { clientController } from "../../../../cdm/controllers/client-controller";
import { filterController } from "../../../../cdm/controllers/filter-controller";
import { formService } from "../../../../cdm/services/form-service";
import {
  groupTypeIds,
  groupTypeRoleIds,
  RoleGroupGroupTypeIds
} from "../../../../config/variable-config";
import { popupPlanSubscriptionDetail } from "../../../../cdm/lib/group-utilities";
import { endpointConfig } from "../../../../config/api-config";
import {
  addAvailabilityFormToProfile,
  isRnREnabledRoleGroup,
  isServiceProviderGroup
} from "../../lib/group-utilities-mcb";
import {
  adminCentreCheckDirectProfileEntry,
  adminCentreGetAllGroups,
  adminCentreGetNewOnboardingData,
  adminCentreGetNextGroups,
  adminCentreGetOnboardingTopics,
  adminCentreInjectLegacyRecruitForm,
  adminCentreInjectNewRecruitForm,
  adminCentrePrepProfileScreen,
  adminCentreProcessGroups,
  adminCentreSortByAlpha,
  adminCentreSortByDate,
  adminCentreSortCards
} from "../../lib/admin-centre-utilities";
import { AdminTriggerRatingPopupCareReceiverInsert } from "../../components/AdminTriggerRatingPopupInsert/CareReceiver";
import { computedFn } from "mobx-utils";
import { mcbEndpointConfig } from "../../config/api-config";

export class AdminCentreController {
  @observable loading = true;
  @observable timeSheetLoading = false;

  @observable paging = false;
  @computed get pagingStatus() {
    if (!stateController.viewAdminCentreState.pagingStatus) {
      stateController.viewAdminCentreState.pagingStatus = {};
    }
    if (!stateController.viewAdminCentreState.pagingStatus[this.groupTypeId]) {
      stateController.viewAdminCentreState.pagingStatus[this.groupTypeId] = {};
    }
    if (this.groupTypeId === groupTypeIds.caregiver) {
      if (
        !stateController.viewAdminCentreState.pagingStatus[this.groupTypeId][
          this.caregiverTabKey
        ]
      ) {
        stateController.viewAdminCentreState.pagingStatus[this.groupTypeId][
          this.caregiverTabKey
        ] = {};
      }
    }
    return this.groupTypeId === groupTypeIds.caregiver
      ? stateController.viewAdminCentreState.pagingStatus[this.groupTypeId][
          this.caregiverTabKey
        ]
      : stateController.viewAdminCentreState.pagingStatus[this.groupTypeId];
  }
  @computed get noMoreNext() {
    return this.pagingStatus && !this.pagingStatus.next;
  }

  pagingSize = 14;

  disposers = [];

  @observable subscriptionLoading = {};

  @computed get user() {
    return clientController.client.user || {};
  }

  @computed get groupType() {
    return clientController.findGroupTypeById(this.groupTypeId);
  }
  @computed get careReceiverGroupType() {
    return clientController.findGroupTypeById(groupTypeIds.careReceiver);
  }
  @computed get householdGroupType() {
    return clientController.findGroupTypeById(groupTypeIds.household);
  }
  @computed get caregiverGroupType() {
    return clientController.findGroupTypeById(groupTypeIds.caregiver);
  }

  @computed get groupTypeId() {
    if (!stateController.viewAdminCentreState.bottomTab) {
      const id = Number(this.props.navigation.getParam("type"));
      const bottomTab = isNaN(id) ? this.props.navigation.getParam("type") : id;
      stateController.viewAdminCentreState.bottomTab =
        bottomTab || this.caregiverGroupType.id;
    }

    const type = stateController.viewAdminCentreState.bottomTab;
    setTimeout(
      () =>
        stateController.currentScreen === "AdminCentre" &&
        this.props.navigation.navigate("AdminCentre", { type })
    );
    return type;
  }
  _groupTypeId = 0;

  @computed get isCareCircleOrHousehold() {
    return [groupTypeIds.household, groupTypeIds.careReceiver].includes(
      this.groupTypeId
    );
  }

  @computed get cards() {
    // return (stateController.viewAdminCentreState.cards[this.groupTypeId] || []).filter(c => c.group.id === 13424);
    return stateController.viewAdminCentreState.cards[this.groupTypeId] || [];
  }
  @computed get sortBy() {
    return (
      stateController.viewAdminCentreState.sortBy[this.groupTypeId] ||
      "dateAscend"
    );
  }
  @computed get pageSortBy() {
    return this.sortBy.match(/date/g) ? "id" : "name";
  }
  @computed get isDesc() {
    return this.sortBy === "dateAscend"
      ? false
      : this.sortBy === "dateDescend"
      ? true
      : this.sortBy === "alphaAscend";
  }
  @computed get hideAvatar() {
    return stateController.viewAdminCentreState.hideAvatar[this.groupTypeId];
  }

  @computed get caregiverTabKey() {
    const key = stateController.viewAdminCentreState.caregiverTabKey;
    if (!key) stateController.viewAdminCentreState.caregiverTabKey = "approved";
    return stateController.viewAdminCentreState.caregiverTabKey;
  }
  @computed get visibleCaregiverGroups() {
    const groups = this.filteredGroups;
    const approvedGroups = groups.filter(c => {
      const profile = !!c.profile && c.profile.data;
      return profile && !!profile["approvalOfCandidateConfirmedByReference"];
    });
    const candidateGroups = groups.filter(c => {
      const profile = !!c.profile && c.profile.data;
      return profile && !profile["approvalOfCandidateConfirmedByReference"];
    });
    return this.caregiverTabKey === "approved"
      ? approvedGroups
      : candidateGroups;
  }

  @computed get timeSheetMode() {
    return stateController.viewAdminCentreState.timeSheetMode;
  }
  @computed get timeSheetGroupId() {
    return stateController.viewAdminCentreState.timeSheetGroupId;
  }
  @computed get timeSheetGroup() {
    return stateController.viewAdminCentreState.timeSheetGroup;
  }
  @computed get timeSheetStore() {
    return stateController.viewAdminCentreState.timeSheetStore;
  }
  get timeSheetState() {
    return {
      get: property => this.timeSheetStore[property],
      set: (property, value) =>
        (stateController.viewAdminCentreState.timeSheetStore[property] = value)
    };
  }

  @computed get ratingReviewMode() {
    return stateController.viewAdminCentreState.ratingReviewMode;
  }
  @computed get ratingReviewGroupId() {
    return stateController.viewAdminCentreState.ratingReviewMode;
  }

  get caregiverTopTabs() {
    return [
      {
        key: "approved",
        name: "Approved",
        onTabPress: this.handleCaregiverTabChange
      },
      {
        key: "candidates",
        name: "Candidates",
        onTabPress: this.handleCaregiverTabChange
      }
    ];
  }

  bottomTabs = [
    {
      key: this.caregiverGroupType.id,
      icon: "account-circle",
      iconType: "material-community",
      label: UIText.adminCaregivers
    },
    {
      key: "providers",
      icon: "room-service",
      iconType: "material-community",
      label: UIText.adminServiceProviders
    },
    {
      key: this.careReceiverGroupType.id,
      icon: "account-supervisor-circle",
      iconType: "material-community",
      label: UIText.adminCareCircles
    },
    {
      key: this.householdGroupType.id,
      icon: "home-circle",
      iconType: "material-community",
      label: UIText.adminHouseholds
    },
    {
      key: "payroll",
      icon: "receipt",
      iconType: "material-community",
      label: UIText.adminCentrePayroll
    },
    {
      key: "territory",
      icon: "map-marker",
      iconType: "material-community",
      label: UIText.adminCentreLocalities
    }
    // {
    //   key: "promo",
    //   icon: "tag",
    //   iconType: "material-community",
    //   label: UIText.adminCentrePromo
    // }
  ];

  @computed get filteredGroups() {
    let template = this.cards;
    return filterController
      .applyFilterCategories(template, this.adminCentreFilterCategories)
      .slice()
      .sort(this.sortCards);
  }

  // Filter functions
  @computed get adminCentreFilterCategories() {
    return this.groupTypeId === groupTypeIds.caregiver
      ? filterController.AdminCentreCaregiverFilter
      : filterController.AdminCentreCareCircleFilter;
  }
  @computed get hasFilter() {
    return (
      this.adminCentreFilterCategories &&
      this.adminCentreFilterCategories.some(
        f => !!f.selected && f.selected !== f.default
      )
    );
  }
  setAdminCentreFilter = filterCategories =>
    filterController.filterSetter(
      this.adminCentreFilterCategories,
      filterCategories
    );

  timeSheetMemberFilter = member => {
    const roleList = Array.isArray(member.roleList) && member.roleList;
    return (
      roleList &&
      roleList.some(
        r => r.groupTypeRoleId === groupTypeRoleIds.paidCaregiver
      ) &&
      member
    );
  };

  @computed get scrollPos() {
    return stateController.viewAdminCentreState.scrollPos[this.groupTypeId];
  }

  get directProfileEntryId() {
    return (
      this.props.navigation.getParam("profile") ||
      this.props.navigation.getParam("profileId") ||
      this.props.navigation.getParam("caregiverProfile")
    );
  }

  constructor(props) {
    this.props = props;
    stateController.currentScreen = "AdminCentre";

    if (!this.groupTypeId) {
      stateController.viewAdminCentreState.bottomTab = this.caregiverGroupType.id;
    }

    setTimeout(() => {
      this._initialize().then(() => {
        this.disposers.push(
          autorun(() => {
            if (this._groupTypeId !== this.groupTypeId) {
              this._groupTypeId = toJS(this.groupTypeId);
              return this._initialize();
            }
          })
        );
        // this.disposers.push(
        //   reaction(
        //     () => this.sortBy,
        //     () => this._getAllGroups().catch(this.showError)
        //   )
        // );
        // this.disposers.push(
        //   reaction(
        //     () => this.caregiverTabKey && isEmpty(this.visibleCaregiverGroups),
        //     () => this.getAndProcessNextPageGroups().catch(this.showError)
        //   )
        // );
        // isEmpty(this.visibleCaregiverGroups) &&
        //   this.getAndProcessNextPageGroups().catch(this.showError);
      });
    }, 500);
  }

  componentWillUnmount = () => {
    this.disposers && this.disposers.map(disposer => disposer());
  };

  showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.adminCentre,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _initialize = async force => {
    if (!isSnSOrAdmin(this.user) || !this.groupTypeId)
      return this.props.navigation.navigate("Root");

    if (isEmpty(this.groupType)) return Promise.resolve();

    this.loading = true;

    !this.props.navigation.getParam("type") &&
      this.props.navigation.navigate("AdminCentre", { type: this.groupTypeId });

    if (this.cards.length > 0 && !force) {
      this.loading = false;
      return Promise.resolve();
    }

    if (!!this.directProfileEntryId) {
      return this.checkDirectProfileEntry();
    }

    return (
      this._getAllGroups()
        // .then(this._getGroupSubscriptions)
        .catch(this.showError)
    );
  };

  _getAllGroups = async () => {
    this.setPagingStatus(false);
    stateController.viewAdminCentreState.cards[this.groupTypeId] = [];
    // return this.getAndProcessNextPageGroups();
    return this.getAndProcessAllGroups();
  };

  getAndProcessAllGroups = async () => {
    if (
      ![
        groupTypeIds.careReceiver,
        groupTypeIds.caregiver,
        groupTypeIds.household
      ].includes(this.groupTypeId)
    ) {
      return;
    }
    this.loading = true;
    const { groupTypeId } = this;
    return adminCentreGetAllGroups(groupTypeId, () =>
      this.setPagingStatus(false)
    )
      .then(this._processNextPageGroups)
      .finally(() => setTimeout(() => (this.paging = this.loading = false)));
  };

  getAndProcessNextPageGroups = async directProfileEntry => {
    this.paging = true;
    return this._getNextPageGroups(directProfileEntry)
      .then(this._processNextPageGroups)
      .finally(() => setTimeout(() => (this.paging = false)));
  };

  _getNextPageGroups = async directProfileEntry => {
    const { groupTypeId } = this;
    if (
      this.groupTypeId !== groupTypeIds.careReceiver &&
      this.groupTypeId !== groupTypeIds.caregiver
    )
      return [null, groupTypeId];
    const isCaregiver =
      this.groupTypeId === groupTypeIds.caregiver && !directProfileEntry;
    const isApproved = this.caregiverTabKey === "approved" ? 1 : 0;
    return adminCentreGetNextGroups(
      groupTypeId,
      this.pagingStatus,
      this.pageSortBy,
      this.pagingSize,
      this.isDesc,
      isCaregiver,
      isApproved,
      this.setPagingStatus
    );
  };

  _processNextPageGroups = ([data, groupTypeId]) =>
    this._processGroups([data, groupTypeId])
      // .then(this._getOnboardingData)
      .then(this._setGroups);

  _processGroups = async ([data, groupTypeId]) =>
    adminCentreProcessGroups(data, groupTypeId);

  _getOnboardingData = async ([groups, groupTypeId]) => {
    if (groupTypeId !== groupTypeIds.caregiver) return [groups, groupTypeId];
    if (isEmpty(groups)) return [groups, groupTypeId];
    return adminCentreGetOnboardingTopics(groups, groupTypeId).then(
      adminCentreGetNewOnboardingData
    );
  };

  _setGroups = async ([groups, groupTypeId]) => {
    stateController.viewAdminCentreState.cards[groupTypeId] = [
      ...this.cards.filter(c => !groups.some(g => g.group.id === c.group.id)),
      ...groups
    ];
    return groupTypeId;
  };

  // _getGroupSubscriptions = async groupTypeId => {
  //   if (groupTypeId !== groupTypeIds.careReceiver) return;
  //   const cards = stateController.viewAdminCentreState.cards[groupTypeId];
  //   for (let card of cards) {
  //     const group = card.group;
  //     if (
  //       isEmpty(group) ||
  //       !group.id ||
  //       group.id < 0 ||
  //       !isEmpty(group.subscription)
  //     )
  //       continue;
  //   }
  //   return Promise.resolve();
  // };

  checkDirectProfileEntry = async () => {
    if (this.directProfileEntryId) {
      return adminCentreCheckDirectProfileEntry(
        this.directProfileEntryId,
        this.groupTypeId,
        this.getAndProcessAllGroups,
        this.handleCardPress
      ).finally(() => (this.loading = false));
    }
    this.loading = false;
  };

  setPagingStatus = next => (this.pagingStatus.next = next);

  handleCardPress = (event, card) => {
    const group = card.group;
    if (!group) return;
    const groupTypeId = group.typeId;

    if (groupTypeId === groupTypeIds.careReceiver) {
      return this.handleTimeSheetPress(group.id);
    }

    const id = group.profileId;

    if (!id) return;

    const screenId = randomString();

    // stateController.viewProfileState.editFlags[screenId] = ["mcb"];
    stateController.viewProfileState.editFlags[
      screenId
    ] = stateController.viewProfileState.renderFlags[screenId] = [
      "caregiver",
      "marketplace",
      "search",
      "general",
      "mcb"
    ].filter(Boolean);
    stateController.viewProfileState.backButtonOverride[screenId] = {
      handlePress: () => {
        stateController.viewAdminCentreState.groupTypeId = groupTypeId;
        return this.props.navigation.navigate("AdminCentre", {
          type: groupTypeId
        });
      }
    };

    // console.log(card.topic);

    if (
      groupTypeId === groupTypeIds.caregiver ||
      isServiceProviderGroup(group)
    ) {
      adminCentrePrepProfileScreen(card, screenId, groupTypeId, () =>
        this.props.navigation.navigate("AdminCentre", {
          type: groupTypeId
        })
      );
      if (groupTypeId === groupTypeIds.caregiver) {
        stateController.viewProfileState.availabilityFormDisabled[
          screenId
        ] = true;
        stateController.viewProfileState.additionalProfileProcessors[
          screenId
        ] = [
          profileForm => addAvailabilityFormToProfile(profileForm, screenId)
        ];
      }
    }

    if (!isEmpty(card.topic)) {
      setTimeout(() =>
        adminCentreInjectLegacyRecruitForm(
          screenId,
          card,
          false,
          this.showError
        )
      );
    } else if (!isEmpty(card.onboarding)) {
      setTimeout(() =>
        adminCentreInjectNewRecruitForm(screenId, card, false, this.showError)
      );
    } else {
      // Explicit show fields for legacy onboarding.
      stateController.viewProfileState.renderFlags[screenId].push(
        "legacyOnboarding"
      );
      stateController.viewProfileState.editFlags[screenId].push(
        "legacyOnboarding"
      );
    }

    this.props.navigation.navigate("Profile", {
      profile: id,
      screenId,
      admin: true,
      type: groupTypeId
    });
  };

  handleMenuPress = (event, card) => {
    const group = card.group;
    const profile = card.profile;
    const groupType = clientController.findGroupTypeById(group.typeId);

    if (isEmpty(group) || isEmpty(profile)) return;

    const groupTypeName = groupType.typeName;
    const displayName = getDisplayNameEng(profile);

    const hasMember = clientController
      .findVisibleGroups()
      .some(g => g.id === group.id);
    const isSelfGroup = group.owner === clientController.userId;

    const careCircleButtons =
      [groupTypeIds.careReceiver, groupTypeIds.household].includes(
        group.typeId
      ) &&
      [
        group.typeId === groupTypeIds.careReceiver && {
          title: UIText.adminCentreSubscriptionInfo,
          onPress: () =>
            stateController
              .dismissPopup()
              .then(() => this.handleSubscriptionPress(event, card))
        },
        group.typeId === groupTypeIds.careReceiver && {
          title: UIText.adminCentrePayrollOption,
          onPress: () => this.handleChangePayrollOption(card)
        },
        !isSelfGroup && {
          topDivider: true,
          title: UIText.adminGainLossAccess(hasMember),
          onPress: () => this.handleGainLossAccessPress(card, hasMember)
        },
        hasMember &&
          !isSelfGroup && {
            title: UIText.adminManageGroup,
            onPress: () =>
              stateController
                .dismissPopup()
                .then(() => this.handleManageGroupWithAccess(card))
          },
        isSelfGroup && {
          topDivider: true,
          title: UIText.adminCentreGainLossAccessOwnGroup,
          disabled: true
        },
        {
          topDivider: true,
          title: UIText.adminCentreTriggerRnR,
          onPress: () =>
            this.handleTriggerRatingReview(card, groupTypeRoleIds.paidCaregiver) //TODO: Soon to include service providers
        },
        {
          title: UIText.adminCentreViewRatingReview,
          onPress: () => this.handleRatingReviewPress(card)
        }
      ].filter(Boolean);

    const RoleGroupButtons =
      RoleGroupGroupTypeIds.includes(group.typeId) &&
      [
        {
          title: UIText.adminCentreProfile(groupTypeName),
          onPress: () =>
            stateController
              .dismissPopup()
              .then(() => this.handleCardPress(event, card))
        },
        isRnREnabledRoleGroup(group) && {
          topDivider: true,
          title: UIText.adminCentreTriggerRnR,
          onPress: () => this.handleTriggerRatingReview4RoleGroup(card)
        },
        {
          topDivider: !isRnREnabledRoleGroup(group),
          title: UIText.adminCentreViewRatingReview,
          onPress: () => this.handleRatingReviewPress(card)
        }
      ].filter(Boolean);

    return stateController.showPopup({
      title: displayName || groupTypeName || UIText.adminCentre,
      buttonSet: [
        ...(careCircleButtons || []),
        ...(RoleGroupButtons || []),
        {
          topDivider: true,
          title: UIText.generalDeleteItem(groupTypeName),
          onPress: () =>
            stateController
              .dismissPopup()
              .then(() => this.handleGroupDeletePress(event, card))
        },
        {
          topDivider: true,
          title: UIText.generalCancel,
          onPress: stateController.dismissPopup
        }
      ].filter(Boolean),
      dismissOnBackPress: true
    });
  };

  handleChangePayrollOption = async card => {
    card.loading = true;
    await stateController.dismissPopup();
    const group = card.group;
    const profile = (
      (await apiController.getProfileById(group.profileId)) || {}
    ).data;
    const useExternal = observable({
      placeholder: UIText.adminCentreUseExternalPayrollProcessing,
      checked: profile["useExternalPayrollProcessing"]
    });
    const options = [useExternal];
    const cancel = () => {
      card.loading = false;
      return stateController
        .dismissPopup()
        .then(() => this.handleMenuPress(null, card));
    };
    if (isEmpty(group) || isEmpty(profile)) return cancel();
    const saveProfile = async () =>
      stateController
        .dismissPopup()
        .then(() =>
          stateController.showPopup({
            title: UIText.adminCentrePayrollOption,
            content: UIText.pleaseWait
          })
        )
        .then(() =>
          apiController.getProfileById(group.profileId).then(profile => {
            if (isEmpty(profile) || isEmpty(profile.data)) return cancel();
            profile.data[
              "useExternalPayrollProcessing"
            ] = !!useExternal.checked;
            return apiService.async("PATCH", {
              endpoint: endpointConfig.profile_by_id(profile.id),
              data: { data: JSON.stringify(profile.data) }
            });
          })
        )
        .then(stateController.dismissPopup);
    card.loading = false;
    return stateController.showPopup({
      title: UIText.adminCentrePayrollOption,
      leftButtonText: UIText.generalCancel,
      leftButtonPress: cancel,
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: saveProfile,
      multiSelect: {
        onCheckboxChange: option => (option.checked = !option.checked),
        options
      }
    });
  };

  handleSubscriptionPress = async (event, card) => {
    const group = card.group;
    if (isEmpty(group)) return;

    if (!isEmpty(group.subscription)) {
      return popupPlanSubscriptionDetail(group, group.subscription);
    }

    card.loading = true;

    return apiController
      .getSubscriptionByGroupId(group.id)
      .then(subscription => {
        group.subscription = subscription;
        popupPlanSubscriptionDetail(group, subscription);
      })
      .catch(this.showError)
      .finally(() => (card.loading = false));
  };

  handleCaregiverTabChange = tab =>
    (stateController.viewAdminCentreState.caregiverTabKey = tab.key);

  handleBottomTabChange = tab => {
    this.props.navigation.navigate("AdminCentre", { type: tab.key });
    return (stateController.viewAdminCentreState.bottomTab = tab.key);
  };

  handleTimeSheetPress = async id => {
    stateController.viewAdminCentreState.timeSheetMode = true;
    stateController.viewAdminCentreState.timeSheetGroupId = id;
    this.timeSheetLoading = true;

    return apiController
      .getGroupById(id)
      .then(group => {
        if (isEmpty(group)) return this.handleTimeSheetBackPress();
        return (stateController.viewAdminCentreState.timeSheetGroup = group);
      })
      .then(() => apiController.getMembersByGroupId(this.timeSheetGroup.id))
      .then(members => {
        if (isEmpty(members)) return Promise.resolve();
        return (stateController.viewAdminCentreState.timeSheetGroup.members = members);
      })
      .then(() => (this.timeSheetLoading = false))
      .catch(err => {
        this.handleTimeSheetBackPress();
        return this.showError(err);
      });
  };

  handleTimeSheetBackPress = event => {
    this.loading = true;
    setTimeout(() => {
      stateController.viewAdminCentreState.timeSheetMode = false;
      stateController.viewAdminCentreState.timeSheetGroup = {};
      stateController.viewAdminCentreState.timeSheetGroupId = 0;
      this.loading = false;
    }, 200);
  };

  handleGroupDeletePress = async (event, card) => {
    const group = card.group;
    const profile = card.profile;

    if (isEmpty(group) || isEmpty(profile)) return;
    const groupTypeName = this.groupType.typeName;
    const displayName = getDisplayNameEng(profile);

    const subscriptionName = (() => {
      const plan = group.subscription && group.subscription.plan;
      if (isEmpty(plan)) return;
      return `${plan.productName} - ${plan.nickname}`;
    })();

    const removeFromList = groupId =>
      setTimeout(
        () =>
          (stateController.viewAdminCentreState.cards[
            this.groupTypeId
          ] = this.cards.filter(c => c.group && c.group.id !== groupId)),
        200
      );

    const execDelete = async () =>
      stateController
        .dismissPopup()
        .then(
          stateController.showPopup({
            title: UIText.generalDeleteItem(groupTypeName),
            content: UIText.pleaseWait
          })
        )
        .then(() =>
          apiController.archiveGroup(group.id, notify.checked, refund.checked)
        )
        .then(stateController.dismissPopup)
        .then(() =>
          stateController.showPopup({
            title: UIText.generalDeleteItem(groupTypeName),
            content: UIText.adminCentreDeleteGroupSuccess(displayName),
            leftButtonText: UIText.generalConfirm
          })
        )
        .then(() => removeFromList(group.id))
        .catch(this.showError);
    // .finally(this.onRefresh);

    let notify = observable({
      placeholder: UIText.adminCentreDeleteGroupNotify(displayName),
      checked: false
    });
    let refund = observable({
      placeholder: UIText.adminCentreDeleteGroupRefund,
      checked: false
    });

    let countdown;
    return stateController
      .showPopup({
        title: UIText.generalDeleteItem(groupTypeName),
        leftButtonText: UIText.generalNo,
        leftButtonPress: () =>
          stateController.dismissPopup().then(() => clearInterval(countdown)),
        rightButtonText: `${UIText.generalYes} (3)`,
        rightButtonPress: () => stateController.dismissPopup().then(execDelete),
        rightButtonDisabled: true,
        contentAlign: "left",
        multiSelect: {
          onCheckboxChange: notify => (notify.checked = !notify.checked),
          options: [notify, subscriptionName && refund].filter(Boolean),
          heading: UIText.adminCentreDeleteGroupConfirm(
            groupTypeName,
            displayName,
            subscriptionName
          )
        }
      })
      .then(() => {
        let i = 3;
        countdown = setInterval(() => {
          i--;
          if (i === 0) {
            stateController.popup.rightButtonDisabled = false;
            stateController.popup.rightButtonText = UIText.generalYes;
            return clearInterval(countdown);
          }
          stateController.popup.rightButtonText = `${UIText.generalYes} (${i})`;
        }, 1000);
      });
  };

  handleTriggerRatingReview = async (card, groupTypeRoleId) => {
    card.loading = true;
    await stateController.dismissPopup();
    const group = card.group;
    const getRatingReviewPossibleMembers = () =>
      apiService
        .async("GET", {
          endpoint: endpointConfig.get_rating_possible_members(
            group.id,
            groupTypeRoleId
          )
        })
        .then(response => response.data);
    return stateController
      .showPopup({
        title: UIText.adminCentreTriggerRnR,
        content: UIText.pleaseWait
      })
      .then(getRatingReviewPossibleMembers)
      .then(async members => {
        if (isEmpty(members)) {
          await stateController.dismissPopup();
          return stateController.showPopup({
            title: UIText.adminCentreTriggerRnR,
            content: UIText.adminCentreTriggerRnRNoAvailableMember,
            leftButtonText: UIText.generalConfirm
          });
        }
        for (const member of members) {
          if (isEmpty(member.profile)) {
            member.profile = await apiController
              .getProfileById(member.profileId)
              .catch(contextReject);
          }
        }
        const selections = observable(
          members.map(m => ({
            checked: false,
            value: m.id
          }))
        );
        let isMultiple;
        const roles = [];
        for (const member of members) {
          const memberRole = (member.roleList || [])[0];
          if (!memberRole) continue;
          const memberRoleName =
            (member.profile.data || {})["customRoleDescription"] ||
            (memberRole || {})["roleName"];
          const role = roles.find(r => r["roleName"] === memberRoleName);
          if (role) {
            role.members.push(member);
          } else {
            roles.push({
              ...memberRole,
              roleName: memberRoleName,
              members: [member]
            });
          }
        }
        const onMemberSelect = member => {
          const selection = selections.find(s => s.value === member.id);
          selection.checked = !selection.checked;
          stateController.popup.rightButtonDisabled = !selections.some(
            s => !!s.checked
          );
        };
        const form = [
          {
            name: "triggerRatingReviewCareCircle",
            placeholder: UIText.adminCentreTriggerRnR,
            type: "custom",
            value: AdminTriggerRatingPopupCareReceiverInsert({
              roles,
              selections,
              onMemberSelect
            }),
            styles: {
              marginHorizontal: 0
            }
          }
        ];
        const execRequest = async () => {
          const targetMemberIds = selections
            .map(s => s.checked && Number(s.value))
            .filter(Boolean);
          if (isEmpty(targetMemberIds)) return;
          const triggerRequest = targetMemberId =>
            apiService.async("POST", {
              endpoint: endpointConfig.trigger_rating_review_request,
              data: {
                targetMemberId,
                reviewerGroupId: group.id
              }
            });
          isMultiple = targetMemberIds.length > 1;
          for (const id of targetMemberIds) {
            await triggerRequest(id).catch(contextReject);
          }
        };
        await stateController.dismissPopup();
        return stateController.showPopup({
          title: UIText.adminCentreTriggerRnR,
          leftButtonText: UIText.generalCancel,
          leftButtonPress: stateController.dismissPopup,
          rightButtonText: UIText.generalConfirm,
          rightButtonPress: () =>
            stateController
              .dismissPopup()
              .then(() =>
                stateController.showPopup({
                  title: UIText.adminCentreTriggerRnR,
                  content: UIText.pleaseWait
                })
              )
              .then(execRequest)
              .then(stateController.dismissPopup)
              .then(() =>
                stateController.showPopup({
                  title: UIText.adminCentreTriggerRnR,
                  content: UIText.adminCentreTriggerRnRSuccess(isMultiple),
                  leftButtonText: UIText.generalConfirm
                })
              )
              .catch(this.showError),
          rightButtonDisabled: true,
          form,
          formStyles: {
            field: { paddingVertical: 0 }
          },
          formShowLabel: false,
          dismissOnBackPress: true
        });
      })
      .catch(this.showError)
      .finally(() => (card.loading = false));
  };

  handleTriggerRatingReview4RoleGroup = async card => {
    card.loading = true;
    await stateController.dismissPopup();
    const group = card.group;
    const isCaregiver = group.typeId === this.caregiverGroupType.id;
    const getRatingReviewPossibleGroups = () =>
      apiService
        .async("GET", {
          endpoint: endpointConfig.get_review_possible_group_members(group.id)
        })
        .then(response => response.data);
    return stateController
      .showPopup({
        title: UIText.adminCentreTriggerRnR,
        content: UIText.pleaseWait
      })
      .then(getRatingReviewPossibleGroups)
      .then(async data => {
        if (isEmpty(data)) {
          await stateController.dismissPopup();
          return stateController.showPopup({
            title: UIText.adminCentreTriggerRnR,
            content: UIText.adminCentreTriggerRnRNoAvailableGroup(isCaregiver),
            leftButtonText: UIText.generalConfirm
          });
        }
        for (const dataset of data) {
          const group = dataset.reviewerGroup;
          if (isEmpty(group)) continue;
          if (isEmpty(group.profile)) {
            group.profile = await apiController
              .getProfileById(group.profileId)
              .catch(contextReject);
          }
          dataset.disabled = isEmpty(dataset.targetMember);
        }
        const selections = observable(
          data.map(dataset => ({
            placeholder: UIText.adminCentreRnRRequestReviewerGroupName(
              getDisplayNameEng(dataset.reviewerGroup.profile),
              dataset.disabled
            ),
            value: dataset.reviewerGroup.id,
            disabled: dataset.disabled
          }))
        );
        let isMultiple;
        await stateController.dismissPopup();
        return stateController.showPopup({
          title: UIText.adminCentreTriggerRnR,
          leftButtonText: UIText.generalCancel,
          leftButtonPress: stateController.dismissPopup,
          rightButtonText: UIText.generalConfirm,
          rightButtonPress: () =>
            stateController
              .dismissPopup()
              .then(() =>
                stateController.showPopup({
                  title: UIText.adminCentreTriggerRnR,
                  content: UIText.pleaseWait
                })
              )
              .then(async () => {
                const reviewerGroupIds = selections
                  .map(s => s.checked && Number(s.value))
                  .filter(Boolean);
                if (isEmpty(reviewerGroupIds)) return;
                isMultiple = reviewerGroupIds.length > 1;
                for (const reviewerGroupId of reviewerGroupIds) {
                  const dataset = data.find(
                    dataset => dataset.reviewerGroup.id === reviewerGroupId
                  );
                  if (isEmpty(dataset)) continue;
                  const targetMemberId = (dataset.targetMember || {}).id;
                  if (!targetMemberId) continue;
                  await apiService
                    .async("POST", {
                      endpoint: endpointConfig.trigger_rating_review_request,
                      data: {
                        targetMemberId,
                        reviewerGroupId
                      }
                    })
                    .catch(contextReject);
                }
              })
              .then(stateController.dismissPopup)
              .then(() =>
                stateController.showPopup({
                  title: UIText.adminCentreTriggerRnR,
                  content: UIText.adminCentreTriggerRnRSuccess(isMultiple),
                  leftButtonText: UIText.generalConfirm
                })
              )
              .catch(this.showError),
          rightButtonDisabled: true,
          multiSelect: {
            title: UIText.adminCentreTriggerRnRReviewerGroup,
            onCheckboxChange: dataset => {
              dataset.checked = !dataset.checked;
              stateController.popup.rightButtonDisabled = !selections.some(
                s => !!s.checked
              );
            },
            options: selections
          }
        });
      })
      .catch(this.showError)
      .finally(() => (card.loading = false));
  };

  handleRatingReviewPress = async card => {
    await stateController.dismissPopup();
    const group = card.group;
    stateController.viewAdminCentreState.ratingReviewViewByCareCircle =
      group.typeId === this.careReceiverGroupType.id;
    stateController.viewAdminCentreState.ratingReviewGroupId = group.id;
    stateController.viewAdminCentreState.ratingReviewMode = true;
  };

  handleRatingReviewBackPress = () => {
    this.loading = true;
    setTimeout(() => {
      stateController.viewAdminCentreState.ratingReviewMode = false;
      stateController.viewAdminCentreState.ratingReviewGroupId = 0;
      stateController.viewAdminCentreState.ratingReviewViewByCareCircle = false;
      this.loading = false;
    }, 200);
  };

  handleGainLossAccessPress = async (card, hasMember) => {
    const rightButtonPress = async () => {
      if (isEmpty(card)) return;
      const { group } = card;
      if (isEmpty(group)) return;
      const groupId = group.id;
      return stateController
        .dismissPopup()
        .then(() =>
          stateController.showPopup({
            title: UIText.adminGainLossAccess(hasMember),
            content: UIText.pleaseWait
          })
        )
        .then(() =>
          apiService.async("POST", {
            endpoint: mcbEndpointConfig.admin_gain_loss_access,
            data: { groupId }
          })
        )
        .then(clientController.clearGroups)
        .then(clientController.getUserGroupsAndMembers)
        .then(stateController.dismissPopup)
        .then(asyncPause)
        .then(() => this.handleMenuPress(null, card));
    };
    const groupName = getDisplayNameEng(card.profile);
    const selfMember =
      hasMember &&
      clientController.findMembers(
        m => m.groupId === card.group.id && m.userId === clientController.userId
      )[0];
    const roleName = (((selfMember || {}).roleList || [])[0] || {}).description;
    return stateController.dismissPopup().then(() =>
      stateController.showPopup({
        title: UIText.adminGainLossAccess(hasMember),
        content: UIText.adminConfirmGainLossAccess(
          hasMember,
          groupName,
          roleName
        ),
        leftButtonText: UIText.generalCancel,
        leftButtonPress: () =>
          stateController
            .dismissPopup()
            .then(() => this.handleMenuPress(null, card)),
        rightButtonText: UIText.generalConfirm,
        rightButtonPress
      })
    );
  };

  handleManageGroupWithAccess = async card => {
    const { group } = card || {};
    if (isEmpty(group)) return;
    stateController.viewGroupId = group.id;
    return this.props.navigation.navigate("Group", { group: group.id });
  };

  sortByDate = () => adminCentreSortByDate(this.groupTypeId, this.sortBy);

  sortByAlpha = () => adminCentreSortByAlpha(this.groupTypeId, this.sortBy);

  sortCards = (a, b) => adminCentreSortCards(a, b, this.sortBy);

  showHideAvatars = () =>
    (stateController.viewAdminCentreState.hideAvatar[this.groupTypeId] = !this
      .hideAvatar);

  is3FreeGroup = computedFn(
    group =>
      !isEmpty(group.subscription) && is3FreePromo(group.subscription.promo)
  );

  getPickerPlaceholder = formService.getFormPickerPlaceholderValue;

  onRefresh = () => {
    this.loading = true;
    return this._initialize(true).finally(() => (this.loading = false));
  };

  onScroll = event => {
    if (this.timeSheetMode) return;
    stateController.viewAdminCentreState.scrollPos[this.groupTypeId] =
      event.nativeEvent.contentOffset.y;
    // const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
    // const isAtBottom =
    //   layoutMeasurement.height + contentOffset.y >= contentSize.height / 1.5;
    // if (!isAtBottom) return;
    // if (
    //   (this.groupTypeId !== groupTypeIds.caregiver &&
    //     this.groupTypeId !== groupTypeIds.careReceiver) ||
    //   this.timeSheetMode
    // )
    //   return;
    // if (this.hasFilter) return;
    // if (this.noMoreNext) return;
    // if (this.paging) return;
    // this.paging = true;
    // return setTimeout(() => this.getAndProcessNextPageGroups());
  };
}
