import React from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import { observer } from "mobx-react";
import { theme } from "../../../../config/style-configs/theme";
import { UIText } from "../../../../config/lang-config";
import { Header } from "../../../../components/Header";
import { HtmlComponent } from "../../../../components/HtmlComponent";
import { ThemedButton } from "../../../../components/ThemedButton";
import { ListRefreshButtonWeb } from "../../../../components/ListRefreshButtonWeb";
import { Tooltip } from "../../../../components/Tooltip/index";
import { Text } from "react-native-elements";

@observer
class ShiftSummaryScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  renderApprovalButtons = () => {
    if (this.controller.timesheetId === -1) {
      return (
        <Text style={this.props.styles.errorMessage}>
          {UIText.reportCurrentPeriodOngoing}
        </Text>
      );
    }
    if (!this.controller.approvalAvailable) {
      return null;
    }

    return this.controller.approvalGiven ? (
      <View style={this.props.styles.approvalButtonContainer}>
        {/*{!this.controller.isApproved && (*/}
        {/*<ThemedButton*/}
        {/*title={UIText.reportCompletedShiftApprv}*/}
        {/*disabled={!this.controller.buttonReady}*/}
        {/*loading={this.controller.approveLoading}*/}
        {/*onPress={this.controller.handleApprovalPress}*/}
        {/*/>*/}
        {/*)}*/}
        <ThemedButton
          title={UIText.reportCompletedShiftApprvGiven(
            this.controller.approvalGiven
          )}
          disabled={true}
          onPress={this.controller.handleApprovalPress}
        />
      </View>
    ) : (
      <View style={this.props.styles.approvalButtonContainer}>
        <ThemedButton
          title={UIText.reportCompletedShiftApprv}
          disabled={!this.controller.buttonReady}
          loading={this.controller.approveLoading}
          onPress={this.controller.handleApprovalPress}
        />
        {this.controller.disapprovalAvailable ? (
          <View style={this.props.styles.buttonContainerHelp}>
            <ThemedButton
              title={UIText.reportCompletedShiftDisapprv}
              disabled={!this.controller.buttonReady}
              loading={this.controller.disapproveLoading}
              onPress={this.controller.handleDisapprovalPress}
            />
            <Tooltip content={UIText.reportCompletedShiftHelpDisapprv} />
          </View>
        ) : (
          <ThemedButton
            title={UIText.reportCompletedShiftApprvGiven(
              this.controller.approvalGiven
            )}
            disabled={true}
            onPress={this.controller.handleApprovalPress}
          />
        )}
      </View>
    );
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        <Header
          title={UIText.reportCompletedShift}
          leftIcon="arrow-back"
          handleLeftPress={this.controller.handleBackPress}
        />
        {this.controller.loading ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          <View style={this.props.styles.content}>
            <View style={this.props.styles.buttonContainer}>
              {this.renderApprovalButtons()}
              <ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />
            </View>
            <ScrollView contentContainerStyle={this.props.styles.webView}>
              <HtmlComponent html={this.controller.reportHtml} />
            </ScrollView>
          </View>
        )}
      </View>
    );
  }
}

export { ShiftSummaryScreen };
