import React from "react";
import { CaregiverEEListView } from "./view";
import { CaregiverEEListController } from "./controller";
import { styles } from "./styles";

export class CaregiverEEList extends React.Component {
  controller = new CaregiverEEListController(this.props);

  render() {
    return (
      <CaregiverEEListView
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
