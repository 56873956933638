import React from "react";
import {
  Animated,
  ScrollView,
  TouchableWithoutFeedback,
  View
} from "react-native";
import { Button, Icon } from "react-native-elements";
import { observer } from "mobx-react";
import { ThemedInput } from "../ThemedInput";
import { theme } from "../../config/style-configs/theme";

@observer
class HeaderSearchPanelView extends React.Component {
  viewOpacity = new Animated.Value(0);
  viewZIndex = new Animated.Value(-1);
  backdropOpacity = new Animated.Value(0);
  duration = theme.popupFadeDuration / 1.5;

  constructor(props) {
    super(props);
  }

  animate(visible) {
    Animated.parallel([
      Animated.timing(this.viewOpacity, {
        toValue: visible ? 1 : 0,
        duration: this.duration
      }),
      Animated.timing(this.viewZIndex, {
        toValue: visible ? 2 : -1,
        duration: visible ? 0 : this.duration
      })
    ]).start();
    visible && this.input && this.input.focus();
  }

  get searchIcon() {
    if (
      this.props.searchFieldIcon &&
      typeof this.props.searchFieldIcon !== "string"
    ) {
      return this.props.searchFieldIcon;
    }

    const name = this.props.searchFieldIcon;

    return (
      <Icon
        name={name || "search"}
        size={this.props.styles.iconSize}
        color="#999"
      />
    );
  }

  get clearIcon() {
    return (
      <Button
        type="clear"
        icon={
          <Icon
            name="clear"
            size={this.props.styles.iconSize / 1.5}
            color="#999"
          />
        }
        onPress={() => {
          this.props.clearInput();
          this.input && this.input.focus();
        }}
      />
    );
  }

  render() {
    setTimeout(() => this.animate(this.props.visible));

    return (
      <Animated.View
        style={{
          ...this.props.styles.style,
          zIndex: this.viewZIndex,
          opacity: this.viewOpacity
        }}
      >
        <ThemedInput
          ref={input => (this.input = input)}
          containerStyle={{
            ...this.props.styles.inputContainer,
            ...(!this.props.hasResults && {
              borderBottomRightRadius: 8,
              borderBottomLeftRadius: 8
            })
          }}
          placeholder={this.props.searchFieldPlaceholder}
          inputStyle={this.props.styles.input}
          value={this.props.searchFieldValue}
          leftIconContainerStyle={this.props.styles.inputLeftIcon}
          leftIcon={this.searchIcon}
          rightIconContainerStyle={this.props.styles.inputRightIcon}
          rightIcon={!!this.props.searchFieldValue && this.clearIcon}
          returnKeyType="go"
          onChange={this.props.onSearchFieldChange}
        />

        <View
          style={{
            ...this.props.styles.searchResult,
            ...(this.props.hasResults && {
              borderBottomRightRadius: 8,
              borderBottomLeftRadius: 8
            })
          }}
        >
          <ScrollView contentContainerStyle={this.props.styles.scrollView}>
            {this.props.resultComponent}
          </ScrollView>
        </View>

        <TouchableWithoutFeedback onPress={this.props.onBackdropPress}>
          <View style={this.props.styles.webBackdrop} />
        </TouchableWithoutFeedback>
      </Animated.View>
    );
  }
}

export { HeaderSearchPanelView };
