import {
  devPlacesApiKey,
  env,
  placesApiKey,
  placesBaseUrl
} from "../../config/variable-config";
import { observable } from "mobx";
import isEmpty from "react-native-web/dist/vendor/react-native/isEmpty";
import { safeParseJSON } from "../../utils/helpers";

export class PlacesService {
  google;
  apiKey;

  @observable initialized;

  constructor() {
    this.initialize();
  }

  initialize = () => {
    this.checkDomTag();
    window.initPlacesApi = () => (this.initialized = true);
    // whenFulfill(() => !!(window as any).google);
  };

  checkDomTag = () => {
    this.apiKey = env !== "prod" ? devPlacesApiKey : placesApiKey;
    const scriptTags = document.getElementsByTagName("script");
    const existingPlacesTag = Array.from(scriptTags).find(
      tag =>
        tag.src && tag.src.match(/https:\/\/maps.googleapis.com\/maps\/api/g)
    );
    if (existingPlacesTag) return;
    const newTag = document.createElement("script");
    newTag.src = `${placesBaseUrl}?key=${
      this.apiKey
    }&libraries=places&callback=initPlacesApi`;
    newTag.async = true;
    newTag.defer = true;
    return document.body.appendChild(newTag);
  };

  getApiKey = () => this.apiKey;

  getGeolocation = async enableHighAccuracy =>
    new Promise((resolve, reject) => {
      return window.navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy
      });
    });

  parseAutocompleteResult = (place, limitTo) => {
    const base = {};
    let result = {
      street1: "",
      street2: "",
      city: "",
      provState: "",
      country: "",
      postalCode: ""
    };
    const { location } = place.geometry || {};
    if (location) {
      base.lng = result.lng = location.lng();
      base.lat = result.lat = location.lat();
    }
    base.formattedAddress = result.formattedAddress =
      (!limitTo && place.formatted_address) || "";
    base.placeId = result.placeId = place.place_id;
    if (place.address_components) {
      for (const component of place.address_components) {
        if (component.types.includes("postal_code"))
          result.postalCode = component.short_name;
        if (component.types.includes("country"))
          result.country += component.long_name;
        if (component.types.includes("administrative_area_level_1"))
          result.provState += component.long_name;
        if (component.types.includes("locality"))
          result.city += component.short_name;
        if (result.city === result.provState) result.provState = "";
        // if (!result.city) result.city = result.provState; // New York etc... not sure about this
        if (component.types.includes("street_number"))
          result.street1 += component.short_name;
        if (component.types.includes("route"))
          result.street1 += `${
            result.street1 ? ` ${component.short_name}` : component.short_name
          }`;
        if (!result.street1) {
          //TODO: Do Japan sublocality style...
        }
      }
      if (limitTo === "postalCode")
        result = { ...base, postalCode: result.postalCode };
      if (limitTo === "country") result = { ...base, country: result.country };
      if (limitTo === "provState")
        result = {
          ...base,
          provState: result.provState,
          country: result.country
        };
      if (limitTo === "city")
        result = {
          ...base,
          city: result.city,
          provState: result.provState,
          country: result.country
        };
    }
    if (!result.formattedAddress)
      result.formattedAddress = limitTo
        ? place.address_components
          ? this.parseLimitedAddress(result)
          : place.name
        : place.name;
    // return JSON.stringify(result);
    return result;
  };

  parseManualResult = (data, limitTo) => {
    if (isEmpty(data)) return undefined;
    let base = {};
    if (limitTo === "postalCode")
      base = { ...data, postalCode: data.postalCode };
    if (limitTo === "country") base = { ...data, country: data.country };
    if (limitTo === "provState")
      base = { ...data, provState: data.provState, country: data.country };
    if (limitTo === "city")
      base = {
        ...data,
        city: data.city,
        provState: data.provState,
        country: data.country
      };
    if (!limitTo) base = { ...data };
    base.formattedAddress = this.parseManualFullAddress(base);
    console.log(base);
    // return JSON.stringify(base);
    return base;
  };

  parseLatLngWithPlaceId = place => {
    if (isEmpty(place)) return;
    let base = {};
    const { location } = place.geometry || {};
    if (location) {
      base.lng = location.lng();
      base.lat = location.lat();
    }
    base.formattedAddress =
      place.formatted_address || place.formattedAddress || undefined;
    base.google_placeId = place.place_id || undefined;
    return base;
  };

  parseManualFullAddress = result => {
    const limitedAddress = this.parseLimitedAddress(result);
    return `${
      result.street1
        ? `${result.street1}${result.street2 || limitedAddress ? ", " : ""}`
        : ""
    }${
      result.street2 ? `${result.street2}${limitedAddress ? ", " : ""}` : ""
    }${limitedAddress}`;
  };

  parseLimitedAddress = result =>
    `${
      result.city
        ? `${result.city}${result.provState || result.country ? ", " : ""}`
        : ""
    }${
      result.provState ? `${result.provState}${result.country ? ", " : ""}` : ""
    }${result.country ? result.country : ""}${
      result.postalCode ? ` ${result.postalCode}` : ""
    }`;

  getFormattedAddress = (value, limitTo) => {
    if (!value) return "";
    const place =
      typeof value === "string"
        ? safeParseJSON(value, true) || {}
        : value || {};
    const parsed = place.formattedAddress;
    if (!parsed) {
      if (place.lat && place.lng) {
        return `${place.lat}, ${place.lng}`;
      }
      return (typeof value === "string" ? value : JSON.stringify(value)) || "";
    }
    if (limitTo) {
      const frags = parsed.split(",");
      if (limitTo === "postalCode")
        return (frags[frags.length - 1] || "").trim();
      if (limitTo === "country") return frags[frags.length - 2];
      if (limitTo === "provState") {
        frags.pop();
        return `${frags.join(", ")}`;
      }
      if (limitTo === "city") return frags[0];
    }
    return parsed;
  };
}

const placesService = new PlacesService();

// For development;
if (window && env !== "prod") window.placesService = placesService;

export { placesService };
