import React from "react";
import { View } from "react-native";
import { Divider, Icon } from "react-native-elements";
import { observer } from "mobx-react";
import BottomNavigation, {
  FullTab
} from "react-native-material-bottom-navigation";
import { responsive } from "../../config/style-configs/responsive";
import { UIText } from "../../config/lang-config";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger
} from "react-native-popup-menu";
import { common } from "../../config/style-configs/common";
import { observable } from "mobx";
import { theme } from "../../config/style-configs/theme";

@observer
class ThemedBottomNavView extends React.Component {
  @observable menuOpen = false;

  renderBottomTab = ({ tab, isActive }) => {
    const color = isActive
      ? this.props.styles.activeColor
      : tab.disabled
      ? this.props.styles.disabledColor
      : this.props.styles.inActiveColor;

    return (
      <FullTab
        isActive={isActive}
        key={tab.key}
        label={tab.label}
        labelStyle={{ color: color }}
        labelAnimation={() => {}}
        iconAnimation={() => {}}
        renderIcon={() => (
          <Icon
            size={this.props.styles.iconSize}
            color={color}
            name={tab.icon}
            type={tab.iconType}
          />
        )}
      />
    );
  };

  get tabs() {
    const { tabs } = this.props;
    let renderTabs = [];
    if (tabs.length > 4 && !responsive.deviceDimension.isDesktop) {
      const overflow = {
        key: "overflow",
        icon: "more-vert",
        label: UIText.tabsMore,
        overflow: true
      };
      renderTabs = [...this.visibleTabs, overflow];
    } else {
      renderTabs = tabs;
    }
    return renderTabs.map(tab =>
      Object.assign(tab, {
        barColor: this.props.styles.barColor,
        pressColor: this.props.styles.rippleDrop,
        pressSize: this.props.styles.rippleSize
      })
    );
  }

  get visibleTabs() {
    const { tabs } = this.props;
    if (tabs.length > 4 && !responsive.deviceDimension.isDesktop) {
      return tabs.slice(0, 3);
    }
    return tabs;
  }

  get overflowTabs() {
    const { tabs } = this.props;
    if (tabs.length <= 4 || responsive.deviceDimension.isDesktop) {
      return null;
    }
    return tabs.slice(3, tabs.length);
  }

  get activeTab() {
    if (this.overflowTabs) {
      const isInOverflow = this.overflowTabs.some(
        tab => tab.key === this.props.activeTab
      );
      if (isInOverflow) return "overflow";
    }
    return this.props.activeTab;
  }

  onTabPress = tab => {
    if (tab.overflow) {
      return (this.menuOpen = true);
    }
    return this.props.onTabPress(tab);
  };

  onMenuTabPress = tab => {
    this.onTabPress(tab);
    return (this.menuOpen = false);
  };

  render() {
    return (
      <View>
        <Divider style={this.props.styles.divider} />
        <BottomNavigation
          activeTab={this.activeTab}
          onTabPress={this.onTabPress}
          renderTab={this.renderBottomTab}
          tabs={this.tabs}
        />
        <Menu
          opened={this.menuOpen}
          onBackdropPress={() => (this.menuOpen = false)}
        >
          <MenuTrigger
            customStyles={{
              triggerOuterWrapper: {
                ...common.menuTriggerContainer,
                position: "absolute",
                right: 10,
                bottom: 65
              }
            }}
            onPress={console.log}
          />
          <MenuOptions
            customStyles={{
              optionsContainer: common.menuOptionContainer
            }}
          >
            {this.overflowTabs &&
              this.overflowTabs.map(tab => (
                <MenuOption
                  key={tab.key}
                  onSelect={() => this.onMenuTabPress(tab)}
                  text={tab.label}
                  customStyles={{
                    optionWrapper: {
                      ...common.menuOptionWrapper,
                      backgroundColor:
                        this.props.activeTab === tab.key && theme.color
                    },
                    optionText: {
                      ...common.menuOptionText,
                      color:
                        this.props.activeTab === tab.key
                          ? "#fff"
                          : tab.disabled
                          ? // ? "#ccc"
                            theme.lightColor
                          : "#000"
                    }
                  }}
                />
              ))}
          </MenuOptions>
        </Menu>
      </View>
    );
  }
}

export { ThemedBottomNavView };
