import { common } from "../../../../config/style-configs/common";
import { responsive } from "../../../../config/style-configs/responsive";
import { computed, decorate } from "mobx";
import { theme } from "../../../../config/style-configs/theme";

export const styles = {
  style: {
    flex: 1
  },
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  scrollViewCollapsible: common.scrollViewCollapsible,
  scrollViewCollapsibleCards: common.scrollViewCollapsibleCards,
  get scrollViewCard() {
    return {
      ...common.scrollViewCard,
      ...responsive.styles.scrollViewCard
    };
  },
  card: common.card,
  cardContent: common.cardChildrenCompact,
  cardContentLarge: common.cardChildrenTextLarge,
  cardContentMedium: common.cardChildrenTextMedium,
  cardContentSmall: common.cardChildrenTextSmall,
  loading: common.loading,
  buttonContainer: common.listButtonContainer,
  buttonReminder: common.listButtonReminderContainer,
  errorMessage: common.containerErrorMessage,
  buttonTitle: {
    ...common.buttonTitle,
    marginLeft: 5
  }
};

decorate(styles, {
  scrollViewCard: computed
});
