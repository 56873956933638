import React from "react";
import { ActivityIndicator, Image, ScrollView, View } from "react-native";
import { CheckBox, Text } from "react-native-elements";
import { theme } from "../../../config/style-configs/theme";
import { ThemedInput } from "../../../components/ThemedInput";
import { ThemedButton } from "../../../components/ThemedButton";
import { observer } from "mobx-react";
import Icon from "react-native-vector-icons/MaterialIcons";
import { UIText } from "../../../config/lang-config";
import { getKeyboardType } from "../../../utils/helpers";

@observer
class VisitorScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
    this.input = {};
    this.rendered = false;
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  renderPasswordField = () => (
    <View style={this.props.styles.fields}>
      <ThemedInput
        ref={input => {
          this.input.password = input;
        }}
        placeholder={UIText.loginFieldPassword}
        leftIcon={<Icon name="repeat" size={24} color="black" />}
        returnKeyType="go"
        value={this.controller.fieldData.password || ""}
        secureTextEntry={true}
        onChange={e => {
          this.controller.updateFields("password", e);
        }}
        onEndEditing={e => {
          this.controller.updateFields("password", e);
        }}
        onBlur={e => {
          this.input.password.props.onEndEditing(e);
        }}
        onSubmitEditing={this.controller.execVisitMarket}
      />
    </View>
  );

  renderVisitorFields = () =>
    !this.controller.anonymousMode && (
      <View style={this.props.styles.container}>
        <View style={this.props.styles.fields}>
          <ThemedInput
            // containerStyle={this.props.styles.halfLength}
            ref={input => {
              this.input.firstName = input;
            }}
            placeholder={UIText.registrationFieldNames.firstName}
            leftIcon={<Icon name="first-page" size={24} color="black" />}
            errorMessage={this.controller.error.firstName.replace(
              "Name",
              " name"
            )}
            value={this.controller.fieldData.firstName || ""}
            returnKeyType="next"
            onChange={e => {
              this.controller.updateFields("firstName", e);
            }}
            onEndEditing={e => {
              this.controller.updateFields("firstName", e);
            }}
            onBlur={e => {
              this.input.firstName.props.onEndEditing(e);
            }}
          />
        </View>
        <View style={this.props.styles.agreement}>
          <CheckBox
            containerStyle={this.props.styles.checkbox}
            checkedIcon={<Icon name="check-box" size={24} color="black" />}
            uncheckedIcon={
              <Icon name="check-box-outline-blank" size={24} color="black" />
            }
            checked={this.controller.agreeTnc}
            checkedColor={theme.color}
            onPress={this.controller.onCheckboxChange}
          />
          <View style={this.props.styles.checkboxLabel}>
            <Text
              style={this.props.styles.checkboxLabelText}
              onPress={this.controller.onCheckboxChange}
            >
              I have read and agree to
            </Text>
            <Text
              style={this.props.styles.checkboxLabelLink}
              onPress={this.controller.openAgreement}
            >
              Terms and Conditions.
            </Text>
          </View>
        </View>
      </View>
    );

  render() {
    if (!this.rendered) {
      this.rendered = true;
      setTimeout(() => {
        this.input.email && this.input.email.focus();
      });
    }
    // if (this.controller.modeSwitched) {
    //   setTimeout(
    //     () =>
    //       this.controller.knownAccount &&
    //       this.input.password &&
    //       this.input.password.focus()
    //   );
    // }
    return this.controller.loading ? (
      <View style={this.props.styles.loading}>
        <ActivityIndicator size="large" color={theme.color} />
      </View>
    ) : (
      <View style={this.props.styles.style}>
        <ScrollView
          style={this.props.styles.scrollView}
          contentContainerStyle={this.props.styles.container}
        >
          <View style={this.props.styles.header}>
            <Image
              source={theme.loginLogo}
              style={this.props.styles.headerLogo}
            />
          </View>

          {!this.controller.anonymousMode && (
            <View style={this.props.styles.questions}>
              <Text style={this.props.styles.questionHeader}>
                {this.controller.anonymousMode
                  ? UIText.visitorWelcomeMessageAnonymous
                  : UIText.visitorWelcomeMessage}
              </Text>
            </View>
          )}

          {!this.controller.anonymousMode && (
            <View style={this.props.styles.fields}>
              <ThemedInput
                ref={input => {
                  this.input.email = input;
                }}
                placeholder={UIText.registrationFieldNames.email}
                keyboardType={getKeyboardType({ name: "email" })}
                leftIcon={<Icon name="email" size={24} color="black" />}
                value={this.controller.fieldData.email || ""}
                returnKeyType="next"
                errorMessage={this.controller.error.email}
                onChange={e => {
                  this.controller.updateFields("email", e);
                  this.controller.onEmailChange(true);
                }}
                onEndEditing={e => {
                  this.controller.updateFields("email", e);
                  this.controller.onEmailChange();
                  this.controller.validateEmail();
                }}
                onBlur={e => {
                  this.input.email.props.onEndEditing(e);
                }}
                onSubmitEditing={() => {
                  // this.controller.onEmailChange(e, true); // true === advance field;
                }}
              />
            </View>
          )}

          {this.controller.knownAccount
            ? this.renderPasswordField()
            : this.renderVisitorFields()}

          {!this.controller.anonymousMode && (
            <ThemedButton
              style={this.props.styles.button}
              disabled={
                this.controller.buttonDisabled ||
                this.controller.visitorSigningIn
              }
              title={
                this.controller.knownAccount
                  ? UIText.login
                  : UIText.visitorLogin
              }
              loading={this.controller.visitorSigningIn}
              onPress={this.controller.execVisitMarket}
            />
          )}
        </ScrollView>
      </View>
    );
  }
}

export { VisitorScreen };
