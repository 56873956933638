import { observer } from "mobx-react";
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import {
  isEmpty,
  isEqual,
  preventDefaultStopProp,
  whenFulfill
} from "../../../../utils/helpers";
import { computed, observable } from "mobx";
import { theme } from "../../../../config/style-configs/theme";
import { GoogleMap } from "@react-google-maps/api";
import { placesService } from "../../../../cdm/services/places-service";
import { responsive } from "../../../../config/style-configs/responsive";
import GooglePlaceSelector from "../CaregiverAvailabilityForm/GooglePlaceSelector";
import { Icon } from "react-native-elements";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { UIText } from "../../../../config/lang-config";

const StaticMarker = ({ style }) => (
  <img
    alt=""
    src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi3.png"
    style={style}
  />
);

@observer
class EditLocationModal extends React.Component {
  placesField;
  modalContent;

  map;
  mapDefaultZoom = 13;

  mapOptions = {
    clickableIcons: false,
    streetViewControl: false,
    fullscreenControl: false,
    scrollwheel: true
    // styles: mapStyles
  };

  // @observable indexOfEditingLocation = -1;
  // @observable isEditing = false;
  // @observable isAdding = false;

  @observable currentName;
  @observable currentPlace;
  @observable geoCenter;

  @observable hideModal = false;

  // @observable highlightSave = false;

  // @computed get isEditingOrAdding() {
  //   return this.isEditing || this.isAdding;
  // }
  // @computed get isEditDisabled() {
  //   return this.indexOfEditingLocation === -1 || this.isEditingOrAdding;
  // }
  @computed get isAdding() {
    return isEmpty(this.props.location);
  }

  @computed get canSave() {
    return !!this.currentName && !!this.currentPlace;
  }

  @computed get mapCenter() {
    if (isEmpty(this.currentPlace)) return;
    const { lat, lng, lon } = this.currentPlace || {};
    const mapCenter = {
      lat: Number(lat || 0),
      lng: Number(lon || lng || 0)
    };
    if (isNaN(mapCenter.lat) || isNaN(mapCenter.lng)) return;
    return mapCenter;
  }

  @computed get isOpen() {
    return !this.hideModal && this.props.isOpen;
  }

  constructor(props) {
    super(props);
    // this.indexOfEditingLocation = isEmpty(this.props.locations) ? -1 : 0;
    this.setGeoCenter();
    this.setPropsLocation();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isEqual(prevProps.location, this.props.location)) {
      setTimeout(this.setPropsLocation);
    }
    if (
      !isEqual(prevProps.isOpen, this.props.isOpen) &&
      isEmpty(this.props.location)
    ) {
      setTimeout(this.setPropsLocation);
    }
  }

  placesFieldRef = elm => (this.placesField = elm);

  modalContentRef = elm => (this.modalContent = elm);

  setMap = map => (this.map = map);

  setGeoCenter = async () => {
    await whenFulfill(() => placesService.initialized);
    const geoLocation = await placesService.getGeolocation(true);
    if (geoLocation) {
      this.geoCenter = {
        lat: geoLocation.coords.latitude,
        lng: geoLocation.coords.longitude
      };
    }
  };

  setPropsLocation = () => {
    this.currentName = (this.props.location || {}).name || null;
    this.currentPlace = (this.props.location || {}).location || null;
  };

  // handleSelect = event => {
  //   const { value } = event.target || {};
  //   const index = Number(value);
  //   if (index === -1) return;
  //   this.indexOfEditingLocation = index;
  // };

  // handleEdit = event => {
  //   preventDefaultStopProp(event);
  //   const location = this.props.locations[this.indexOfEditingLocation];
  //   if (!location) return;
  //   this.currentName = location.name;
  //   this.currentPlace = location.location;
  //   this.isEditing = true;
  // };

  // handleDoneEdit = event => {
  //   preventDefaultStopProp(event);
  //   this.currentName = null;
  //   this.currentPlace = null;
  //   return (this.isEditing = this.isAdding = false);
  // };

  handleRemove = event => {
    preventDefaultStopProp(event);
    // if (this.isEditingOrAdding) return;
    // if (this.isLocationInUse(this.indexOfEditingLocation)) return;
    // if (isEmpty(this.props.locations)) return;
    // return this.props.locations.splice(this.indexOfEditingLocation, 1);
    if (this.props.isLocationInUse(this.props.location)) {
      this.hideModal = true;
      return stateController.showPopup({
        title: "Location in use",
        content: "Location cannot be removed when it's current in-use.",
        leftButtonText: UIText.generalConfirm,
        leftButtonPress: () =>
          stateController.dismissPopup().then(() => (this.hideModal = false))
      });
    }
    this.props.onLocationUpdate && this.props.onLocationUpdate(null);
    // return this.handleClose();
  };

  // handleAdd = async event => {
  //   preventDefaultStopProp(event);
  //   this.isAdding = true;
  // };

  handleSave = event => {
    preventDefaultStopProp(event);
    this.props.onLocationUpdate &&
      this.props.onLocationUpdate({
        name: this.currentName,
        location: this.currentPlace
      });
    // return this.handleClose();
    // return this.isAdding
    //   ? this.handleSaveNew(event)
    //   : this.handleSaveEdit(event);
  };

  // handleSaveEdit = event => {
  //   preventDefaultStopProp(event);
  //   this.isEditing = this.isAdding = false;
  //   this.props.locations[this.indexOfEditingLocation] = {
  //     name: this.currentName,
  //     location: this.currentPlace
  //   };
  //   return this.handleClose();
  // };

  // handleSaveNew = event => {
  //   preventDefaultStopProp(event);
  //   this.isEditing = this.isAdding = false;
  //   this.props.locations.push({
  //     name: this.currentName,
  //     location: this.currentPlace
  //   });
  //   this.currentName = null;
  //   this.currentPlace = null;
  //   this.indexOfEditingLocation = this.props.locations.length - 1;
  // };

  handleNameChange = event => {
    const { value } = event.target || {};
    return (this.currentName = value);
  };

  handlePlaceChange = place => (this.currentPlace = place);

  // handleMapClick = event => {
  //   if (!event.latLng) return;
  //   this.currentPlace = {
  //     lat: event.latLng.lat(),
  //     lng: event.latLng.lng()
  //   };
  // };

  handleMapMove = () => {
    const latLng = this.map && this.map.getCenter();
    if (!latLng) return;
    const place = {
      lat: latLng.lat(),
      lng: latLng.lng()
    };
    if (isEqual(this.geoCenter, place)) return;
    this.currentPlace = place;
  };

  handleClose = event => {
    // if (this.isEditingOrAdding) return this.scrollAndFlash();
    this.props.onClose && this.props.onClose(event);
  };

  // scrollAndFlash = async () => {
  //   if (!this.modalContent) return;
  //   const topElm = this.modalContent.querySelectorAll(".locationSelector")[0];
  //   if (!topElm) return;
  //   topElm.scrollIntoView({ behavior: "smooth" });
  //   this.highlightSave = true;
  //   await asyncPause(300);
  //   this.highlightSave = false;
  //   await asyncPause(300);
  //   this.highlightSave = true;
  //   setTimeout(() => (this.highlightSave = false), 5000);
  // };

  render() {
    return (
      <Dialog onClose={this.handleClose} open={this.isOpen}>
        <DialogTitle style={{ padding: "5px 10px 5px 24px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <span>{this.isAdding ? "Add location" : "Edit location"}</span>
            <IconButton onClick={this.handleClose}>
              <Icon name="close" type="material-community" size={22} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          ref={this.modalContentRef}
          dividers
          style={{ minWidth: 250, display: "flex", flexDirection: "column" }}
        >
          {/*<FormControl className="locationSelector">*/}
          {/*<InputLabel htmlFor="location-list">Saved locations</InputLabel>*/}
          {/*<Select*/}
          {/*  value={this.indexOfEditingLocation}*/}
          {/*  inputProps={{ id: "location-list" }}*/}
          {/*  disabled={isEmpty(locations) || this.isEditingOrAdding}*/}
          {/*  onChange={this.handleSelect}*/}
          {/*>*/}
          {/*  {isEmpty(locations) && (*/}
          {/*    <MenuItem value={-1}>No saved location</MenuItem>*/}
          {/*  )}*/}
          {/*  {(locations || []).filter(Boolean).map((l, index) => (*/}
          {/*    <MenuItem key={index} value={index}>*/}
          {/*      {getLocationName(l)}*/}
          {/*    </MenuItem>*/}
          {/*  ))}*/}
          {/*</Select>*/}
          {/*</FormControl>*/}
          {/*<div style={{ padding: "5px 5px 0", textAlign: "center" }}>*/}
          {/*  <Button*/}
          {/*    color="primary"*/}
          {/*    disabled={this.isEditDisabled}*/}
          {/*    onClick={this.handleEdit}*/}
          {/*  >*/}
          {/*    Edit location*/}
          {/*  </Button>*/}
          {/*  &nbsp;*/}
          {/*  <Button*/}
          {/*    color="primary"*/}
          {/*    disabled={*/}
          {/*      this.isLocationInUse(this.indexOfEditingLocation) ||*/}
          {/*      this.isEditingOrAdding*/}
          {/*    }*/}
          {/*    onClick={this.handleRemove}*/}
          {/*  >*/}
          {/*    Remove location*/}
          {/*  </Button>*/}
          {/*</div>*/}
          {/*<div*/}
          {/*  style={{*/}
          {/*    transition: "background .5s ease",*/}
          {/*    background: this.highlightSave ? "#fffbd6" : "#ffffff00",*/}
          {/*    padding: "5px 5px 0",*/}
          {/*    textAlign: "center"*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {!this.isEditing && !this.isAdding && (*/}
          {/*    <Button*/}
          {/*      color="primary"*/}
          {/*      disabled={this.isEditingOrAdding}*/}
          {/*      onClick={this.handleAdd}*/}
          {/*    >*/}
          {/*      Add new location*/}
          {/*    </Button>*/}
          {/*  )}*/}
          {/*  {this.isAdding && (*/}
          {/*    <Button color="primary" onClick={this.handleDoneEdit}>*/}
          {/*      Cancel add*/}
          {/*    </Button>*/}
          {/*  )}*/}
          {/*  {this.isEditing && (*/}
          {/*    <Button color="primary" onClick={this.handleDoneEdit}>*/}
          {/*      Cancel edit*/}
          {/*    </Button>*/}
          {/*  )}*/}
          {/*  <Button*/}
          {/*    color="primary"*/}
          {/*    // disabled={this.isLocationInUse(this.indexOfEditingLocation)}*/}
          {/*    onClick={this.handleRemove}*/}
          {/*  >*/}
          {/*    Remove location*/}
          {/*  </Button>*/}
          {/*  <Button*/}
          {/*    color="primary"*/}
          {/*    disabled={!this.canSave}*/}
          {/*    onClick={this.handleSave}*/}
          {/*  >*/}
          {/*    Save location*/}
          {/*  </Button>*/}
          {/*</div>*/}
          {/*{this.isEditingOrAdding && (*/}
          <div
            style={{
              marginTop: 10,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Typography gutterBottom style={{ color: theme.color }}>
              Name of location
            </Typography>
            <TextField
              variant="outlined"
              style={{ marginBottom: 10 }}
              placeholder="Name of location"
              value={this.currentName || ""}
              onChange={this.handleNameChange}
            />
            <Typography gutterBottom style={{ color: theme.color }}>
              Location search
            </Typography>
            {/*<OSMPlaceSelector*/}
            {/*  value={this.currentPlace}*/}
            {/*  onPositionChange={this.handlePlaceChange}*/}
            {/*/>*/}
            <GooglePlaceSelector
              biasCenter
              ref={this.placesFieldRef}
              value={this.currentPlace}
              onPositionChange={this.handlePlaceChange}
            />
            {placesService.initialized && (
              <GoogleMap
                mapContainerClassName="googleMap"
                onLoad={this.setMap}
                mapContainerStyle={{
                  width: responsive.deviceDimension.isDesktop ? 400 : "100%",
                  height: 400
                }}
                center={this.mapCenter || this.geoCenter}
                zoom={this.mapDefaultZoom}
                options={this.mapOptions}
                // onClick={this.handleMapClick}
                onZoomChanged={this.handleMapMove}
                onDragEnd={this.handleMapMove}
              >
                {/*<Marker*/}
                {/*  position={this.mapCenter || this.geoCenter}*/}
                {/*  options={{*/}
                {/*    position: new google.maps.LatLng(*/}
                {/*      this.mapCenter || this.geoCenter*/}
                {/*    ),*/}
                {/*    clickable: false,*/}
                {/*    // label: "Center",*/}
                {/*    draggable: false*/}
                {/*  }}*/}
                {/*/>*/}
                <StaticMarker
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)"
                  }}
                />
              </GoogleMap>
            )}
          </div>
          {/*)}*/}
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            color="primary"
            style={{
              color: isEmpty(this.props.location) ? undefined : theme.color
            }}
            disabled={isEmpty(this.props.location)}
            onClick={this.handleRemove}
          >
            Remove location
          </Button>
          <Button
            color="primary"
            style={{ color: this.canSave ? theme.color : undefined }}
            disabled={!this.canSave}
            onClick={this.handleSave}
          >
            Save location
          </Button>
          {/*<Button onClick={this.handleClose} color="primary">*/}
          {/*  Done*/}
          {/*</Button>*/}
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditLocationModal;
