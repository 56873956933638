import { common } from "../../../../config/style-configs/common";
import { theme } from "../../../../config/style-configs/theme";
import { responsive } from "../../../../config/style-configs/responsive";
import { computed, decorate } from "mobx";

export const styles = {
  style: {
    flex: 1
  },
  loading: common.loading,
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  buttonContainer: common.listButtonContainer,
  buttonReminder: common.listButtonReminderContainer,
  buttonTitleContainer: common.buttonTitleContainer,
  buttonTitle: common.buttonTitle,
  table: {
    padding: 20
  },
  get tableCell() {
    return (
      responsive.deviceDimension.isDesktop && {
        alignItems: "center"
      }
    );
  }
};

decorate(styles, {
  tableCell: computed
});
