import { theme } from "../../config/style-configs/theme";
import { common } from "../../config/style-configs/common";
import { computed, decorate } from "mobx";
import { responsive } from "../../config/style-configs/responsive";

export const styles = {
  webOverlay: {
    flex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, .5)",
    justifyContent: "center",
    alignItems: "center"
  },
  webBackdrop: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 0
  },
  overlay: {
    padding: 0
  },
  component: {
    zIndex: 100,
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "#fff",
    minWidth: 300,
    width: "auto",
    maxWidth: "50%",
    maxHeight: "70%",
    boxShadow: "3px 3px 10px #666"
  },
  header: {
    minWidth: "100%",
    width: "100%",
    paddingHorizontal: 15,
    backgroundColor: "#fff",
    boxShadow: "rgba(170, 170, 170, 0.5) 0px -1px 9px 0px",
    height: 50,
    justifyContent: "center",
    alignItems: "center"
  },
  headerText: {
    color: theme.color,
    fontSize: theme.FONT_SIZE_LARGE,
    fontWeight: "600"
  },
  content: {
    justifyContent: "center",
    minHeight: 50,
    minWidth: "100%",
    padding: 20
  },
  contentHtml: {
    flex: 1
  },
  contentText: {
    textAlign: "center",
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  contentLink: common.linkBlue,
  input: common.input,
  inputContainer: {
    paddingVertical: 5
  },
  inputTitle: {
    marginLeft: 5,
    marginBottom: 10,
    color: theme.color,
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  heading: {
    marginBottom: 20
  },
  footer: {
    paddingHorizontal: 10,
    paddingTop: 20,
    paddingBottom: 0,
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  picker: common.picker,
  buttons: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  button: {
    borderRadius: 0,
    width: "50%",
    height: "100%"
  },
  buttonStyle: {
    height: 50
  },
  buttonText: {
    color: theme.color,
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  buttonSet: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  buttonSetItem: {
    width: "100%",
    paddingHorizontal: 10
  },
  buttonSetButton: {
    borderRadius: 0,
    width: "100%"
  },
  buttonSetDivider: {
    width: "100%"
  },
  get image() {
    return responsive.styles.lightBoxImage;
  },
  cropperComponent: {
    width: "50%",
    height: "70%"
  }
};

decorate(styles, {
  image: computed
});
