import Storage from "react-native-storage/src/storage";
// import Storage from "react-native-storage";
import { AsyncStorage, Platform } from "react-native";

const storageBackend =
  Platform.OS === "web" ? window.localStorage : AsyncStorage;

const storage = new Storage({
  // maximum capacity, default 1000
  size: 1000,

  // Use AsyncStorage for RN apps, or window.localStorage for web apps.
  // If storageBackend is not set, data will be lost after reload.
  storageBackend: storageBackend, // for web: window.localStorage

  // expire time, default: 1 day (1000 * 3600 * 24 milliseconds).
  // can be null, which means never expire.
  defaultExpires: null,

  // cache data in the memory. default is true.
  enableCache: true,

  // if data was not found in storage or expired data was found,
  // the corresponding sync method will be invoked returning
  // the latest data.
  sync: {}
});

export { storage };
