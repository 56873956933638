import { common } from "../../../config/style-configs/common";
import { theme } from "../../../config/style-configs/theme";

export const styles = {
  style: {
    flex: 1
  },
  loading: common.loading,
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  listButtonContainer: {
    ...common.listButtonContainer,
    marginBottom: 10
  },
  buttonReminder: common.listButtonReminderContainer,
  errorMessage: {
    ...common.containerErrorMessage,
    textAlign: "start"
  },
  field: { paddingVertical: 10 },
  fieldLabel: {
    marginBottom: 0,
    fontSize: theme.FONT_SIZE_LARGE
  },
  pickerLabel: {
    fontSize: theme.FONT_SIZE_LARGE
  },
  planChangeProvince: {
    borderColor: theme.color
  },
  planChangeProvinceText: {
    color: theme.color,
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  planListLabel: { marginTop: 10 },
  planItem: {
    marginHorizontal: 5,
    marginVertical: 10,
    padding: 10,
    borderWidth: 1,
    borderRadius: 3,
    borderColor: "#ededed",
    backgroundColor: "#fafafa",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  planItemSelected: {
    borderColor: "#f15f2280",
    borderWidth: 2
  },
  planItemCurrent: {
    borderColor: "#f15f2280"
  },
  planItemDetail: {
    flex: 6,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  planItemText: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: 10
  },
  planItemTextItem: {
    // flex: 1
    justifyContent: "center",
    paddingVertical: 2
  },
  planItemCheckbox: {},
  planItemTitle: {},
  planItemTitleText: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    fontWeight: "600"
  },
  planItemNickname: {},
  planItemNicknameText: {
    fontSize: theme.FONT_SIZE_SMALL
  },
  planItemDescription: {},
  planItemDescriptionText: {
    fontSize: theme.FONT_SIZE_SMALL
  },
  planItemPrice: {
    // flex: 3,
    marginLeft: 10,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  planItemPriceText: {
    color: theme.color,
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  planItemIntervalText: {
    color: theme.color,
    fontSize: theme.FONT_SIZE_TINY
  },
  planCatDescriptionContainer: {
    paddingHorizontal: 10,
    marginBottom: 5
  },
  planCatSectionDividerContainer: {
    marginTop: 15,
    marginBottom: 20
  },
  planCatSectionDivider: {
    backgroundColor: "#ccc"
  },
  planCatDescription: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    fontWeight: "600"
  },
  planTrial: {
    paddingHorizontal: 10
  },
  planTrialText: {
    color: "#444",
    fontSize: theme.FONT_SIZE_SMALL,
    marginBottom: 5
  },
  buttonContainer: {
    justifyContent: "center",
    alignSelf: "center",
    margin: 30
  },
  topSection: {
    height: 80,
    justifyContent: "center",
    alignItems: "center"
  },
  heading: {
    color: theme.color,
    fontSize: theme.FONT_SIZE_LARGE
  },
  billingDetailConfirm: {
    paddingHorizontal: 15,
    paddingBottom: 15
  },
  billingDetailConfirmText: {
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  footerNoteContainer: {
    padding: 15
  },
  footerNote: {
    fontSize: theme.FONT_SIZE_SMALL,
    color: theme.color
  },
  footerNoteLink: common.linkBlue
};
