import React from "react";
import { observer } from "mobx-react";
import { ActivityIndicator, View } from "react-native";
import { CheckBox, Text } from "react-native-elements";
import { getDisplayNameEng, isEmpty } from "../../../../utils/helpers";
import { UIText } from "../../../../config/lang-config";
import Icon from "react-native-vector-icons/MaterialIcons";
import { theme } from "../../../../config/style-configs/theme";
import { getReadOnlyStarColor } from "../RatingReviewInteractiveStar/view";

const renderStar = (index, ratingReview) => (
  <Icon
    key={index}
    name="star"
    size={20}
    color={getReadOnlyStarColor(index, ratingReview)}
  />
);

export const RatingReviewSmallCardView = observer(props => {
  const {
    ratingReview,
    isTestimonial,
    onSetIsPublishedLoading,
    onSetIsPublished,
    showPrivateReview,
    showReviewerGroupName,
    truncateLastName
  } = props;

  if (isEmpty(ratingReview)) return null;
  const reviewerGroup = showReviewerGroupName && ratingReview.reviewerGroup;

  const removeLastName = name => (name || "").split(/\s+/g)[0];

  return (
    <View style={props.styles.ratingReviewSmallCard}>
      <View style={props.styles.ratingReviewSmallCardToolbar}>
        <View style={props.styles.ratingReviewSmallCardStarRatings}>
          {showReviewerGroupName && (
            <Text style={props.styles.ratingReviewSmallCardGroupName}>
              {reviewerGroup.groupTypeName}{" "}
              {getDisplayNameEng(reviewerGroup.profile)}:&nbsp;
            </Text>
          )}
          {!isTestimonial &&
            Array.from(new Array(5)).map((n, i) => renderStar(i, ratingReview))}
          {isTestimonial && <Text>{UIText.testimonial}</Text>}
        </View>
        {onSetIsPublished && (
          <View style={props.styles.isPublishedToggle}>
            {onSetIsPublishedLoading ? (
              <ActivityIndicator size="small" color={theme.color} />
            ) : (
              <CheckBox
                containerStyle={props.styles.isPublishedCheckbox}
                title={UIText.ratingReviewIsPublished}
                checkedIcon={<Icon name="check-box" size={24} color="black" />}
                uncheckedIcon={
                  <Icon
                    name="check-box-outline-blank"
                    size={24}
                    color="black"
                  />
                }
                checked={!!ratingReview.isPublished}
                checkedColor={theme.color}
                onPress={() => onSetIsPublished(ratingReview)}
              />
            )}
          </View>
        )}
      </View>
      <Text style={props.styles.ratingReviewSmallCardComment}>
        <Text style={props.styles.ratingReviewSmallCardAuthor}>
          {truncateLastName
            ? isTestimonial
              ? removeLastName(ratingReview.name)
              : removeLastName(
                  getDisplayNameEng((ratingReview.authorMember || {}).profile)
                )
            : isTestimonial
            ? ratingReview.name
            : getDisplayNameEng((ratingReview.authorMember || {}).profile)}
          :
        </Text>
        &nbsp;
        <Text
          style={{
            color: !!ratingReview.review ? "#000" : "#aaa"
          }}
        >
          {ratingReview.review || UIText.ratingReviewNoComment}
        </Text>
      </Text>
      {showPrivateReview && ratingReview.privateReview && (
        <Text style={props.styles.ratingReviewSmallCardComment}>
          <Text style={props.styles.ratingReviewSmallCardAuthor}>
            {UIText.ratingReviewPrivateReview}:
          </Text>
          &nbsp;
          <Text>{ratingReview.privateReview}</Text>
        </Text>
      )}
      <Text style={props.styles.ratingReviewSmallCardText}>
        {UIText.ratingReviewSubmissionDate}&nbsp;
        {new Date(
          ratingReview.submissionDateLong ||
            ratingReview.submissionDate ||
            ratingReview.createTime
        ).toLocaleString()}
      </Text>
    </View>
  );
});
