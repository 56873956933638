import { common } from "../../../../config/style-configs/common";

export const styles = {
  style: {
    flex: 1,
    width: "100%"
  },
  loading: common.loading,
  buttonContainer: common.listButtonContainer,
  buttonReminder: common.listButtonReminderContainer,
  errorMessage: common.containerErrorMessage,
  overall: {
    alignItems: "flex-start",
    marginBottom: 10,
    paddingVertical: 15,
    paddingHorizontal: 10
  },
  popupStretcher: {
    userSelect: "none",
    height: 0
  }
};
