import { responsive } from "../../config/style-configs/responsive";
import { computed, decorate } from "mobx";
import { common } from "../../config/style-configs/common";

export const styles = {
  style: {
    position: "absolute",
    top: 43,
    left: 0,
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  get iconSize() {
    return responsive.styles.headerSearchPanelIconSize;
  },
  get inputContainer() {
    return {
      ...responsive.styles.headerSearchPanelInputContainer,
      maxWidth: 600,
      justifyContent: "center",
      backgroundColor: "#fff",
      boxShadow: "0px 0px 8px #999",
      zIndex: 2
    };
  },
  get input() {
    return {
      borderColor: "#fff",
      ...responsive.styles.headerSearchPanelInput
    };
  },
  inputRightIcon: {
    borderWidth: 0,
    borderColor: "#FFFFFF00",
    alignSelf: "center"
  },
  inputLeftIcon: {
    alignSelf: "center",
    marginTop: 0
  },
  get searchResult() {
    return {
      overflow: "hidden",
      backgroundColor: "#fff",
      width: "100%",
      maxWidth: 600,
      maxHeight: responsive.deviceDimension.height / 1.5,
      boxShadow: "0px 0px 8px #999",
      zIndex: 1
    };
  },
  scrollView: common.scrollView,
  webBackdrop: {
    backgroundColor: "rgba(255, 255, 255, .5)",
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 0
  }
};

decorate(styles, {
  iconSize: computed,
  input: computed,
  searchResult: computed
});
