import React from "react";
import { Animated } from "react-native";
import { observer } from "mobx-react";
import { observable } from "mobx";

@observer
class AnimatedOpacityViewView extends React.Component {
  @observable bounceValue;

  constructor(props) {
    super(props);
    this.defaultValue = this.props.opacity || 1;
    this.bounceValue = new Animated.Value(
      props.visible ? this.defaultValue : 0
    );
  }

  animate = visible =>
    Animated.timing(this.bounceValue, {
      toValue: visible ? this.defaultValue : 0
    }).start();

  render() {
    setTimeout(() => this.animate(this.props.visible));

    return (
      <Animated.View
        style={{
          ...this.props.styles.style,
          ...this.props.style,
          ...{ opacity: this.bounceValue }
        }}
      >
        {this.props.children}
      </Animated.View>
    );
  }
}

export { AnimatedOpacityViewView };
