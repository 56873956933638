import { acceptInviteSetup } from "./setup";
import { clientController } from "../../../../../../cdm/controllers/client-controller";
import { stateController } from "../../../../../../cdm/controllers/state-controller";
import { getDisplayNameEng } from "../../../../../../utils/helpers";
import {
  memberStatus,
  RoleGroupGroupTypeIds
} from "../../../../../../config/variable-config";

export const acceptInvite = () => {
  const invitedMember = clientController.client.user.members.find(
    m => Number(m.status) === memberStatus.invited
  );
  const invitedGroup = clientController.findGroupById(invitedMember.groupId);
  const invitedRole =
    Array.isArray(invitedMember["roleList"]) && invitedMember["roleList"][0];

  if (!invitedGroup || !invitedMember || !invitedRole) return {};

  const invitationData = clientController.client.invitations.find(
    inv => inv.memberId === invitedMember.id
  );

  if (!invitationData) return {};

  const invitation = {
    invitedMember,
    invitedGroup,
    invitedRole,
    invitationData
  };

  const visibleGroups = clientController.findVisibleGroups();
  const roleGroups = visibleGroups.filter(g =>
    RoleGroupGroupTypeIds.includes(g.typeId)
  );

  const isOffer = invitationData.type === "hire";

  const groupName = getDisplayNameEng(invitedGroup.profile);
  const initialTitle = isOffer ? `New Employment Offer` : `New Invitation`;
  const actionString = isOffer
    ? "offered to employ you for"
    : "invited you to join";

  const customRoleDescription = ((invitedMember.profile || {}).data || {})
    .customRoleDescription;
  const roleDescription = customRoleDescription || invitedRole.description;

  const initialHeading = `Hi ${getDisplayNameEng(
    clientController.defaultMember.profile
  )}, ${groupName} has ${actionString} their ${invitedGroup.groupTypeName ||
    "group"} as a ${roleDescription}.`;

  return {
    rootBack: {
      icon: visibleGroups.length > 0 ? null : "exit-to-app",
      onPress: props => {
        visibleGroups.length > 0
          ? props.navigation.navigate("Group")
          : clientController.execLogout(true, true);
      }
    },
    initialTitle,
    initialHeading,
    setupList: [
      {
        ...acceptInviteSetup(invitation, isOffer, roleDescription)
      }
    ],
    onFinishSetup: props => {
      stateController.viewGroupId = invitedGroup.id;
      props.navigation.navigate("Group");
    }
  };
};
