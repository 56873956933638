import { common } from "../../../../../config/style-configs/common";

export const styles = {
  style: {
    flex: 1,
    width: "100%"
  },
  loading: common.loading,
  buttonContainer: common.listButtonContainer,
  buttonReminder: common.listButtonReminderContainer,
  errorMessage: common.containerErrorMessage,
  inputTitle: {
    marginLeft: 5,
    marginVertical: 5
  }
};
