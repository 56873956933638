import React from "react";
import { ActivityIndicator, View } from "react-native";
import { theme } from "../../config/style-configs/theme";

export class LoadingScreen extends React.Component {
  render() {
    return (
      <View style={this.props.styles}>
        <ActivityIndicator size="large" color={theme.color} />
      </View>
    );
  }
}
