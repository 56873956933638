import React from "react";
import { Observer, observer } from "mobx-react";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { UIText } from "../../../../config/lang-config";
import { apiController } from "../../../../cdm/controllers/api-controller";
import { contextReject, isEmpty } from "../../../../utils/helpers";
import { apiService } from "../../../../cdm/services/api-service";
import { endpointConfig } from "../../../../config/api-config";
import { computed, observable } from "mobx";
import { ActivityIndicator, View } from "react-native";
import { theme } from "../../../../config/style-configs/theme";
import { Text } from "react-native-elements";
import { RatingReviewSmallCard } from "../RatingReviewSmallCard";
import { RatingReviewMarketStars } from "../RatingReviewMarketStars";
import { getRatingAggregatedScoreAndSamples } from "../../lib/group-utilities-mcb";
import { mcbEndpointConfig } from "../../config/api-config";
import { isSnSOrAdmin } from "../../utils/helper";
import { clientController } from "../../../../cdm/controllers/client-controller";

@observer
class RatingReviewDetailFormInsertView extends React.Component {
  @observable loading = true;

  @observable group;
  @observable aggregatedRatingReview = {};
  @observable achievement = {};

  @computed get groupId() {
    return this.props.groupId;
  }
  @computed get ratingReviews() {
    return this.aggregatedRatingReview.reviews || [];
  }
  @computed get testimonials() {
    return this.aggregatedRatingReview.testimonials || [];
  }

  constructor(props) {
    super(props);
    this._initialize();
  }

  _initialize = async () =>
    this.loadAllData()
      .catch(this._showError)
      .finally(() => (this.loading = false));

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.ratingReviews,
      content:
        (err.response &&
          (err.response.status === 304
            ? UIText.marketTopicRemoveDeny
            : JSON.stringify(err.response.data).replace(/"/g, ""))) ||
        err.message,
      leftButtonText: UIText.generalConfirm
    });
  };

  loadAllData = async () => {
    if (!this.groupId) return;
    this.group = await apiController
      .getGroupById(this.groupId)
      .catch(contextReject);
    return Promise.all([this.getAggregatedRating(), this.getAchievement()]);
  };

  getAggregatedRating = async () =>
    apiService
      .async("GET", {
        endpoint: endpointConfig.get_aggregated_rating(this.group.owner, false)
      })
      .then(response => response.data)
      .then(rating => (this.aggregatedRatingReview = rating || {}));

  getAchievement = async () =>
    apiService
      .async("GET", {
        endpoint: mcbEndpointConfig.get_caregiver_achievement(this.group.id)
      })
      .then(response => response.data)
      .then(achievement => (this.achievement = achievement || {}));

  render() {
    const { rating, samples } = getRatingAggregatedScoreAndSamples(
      this.aggregatedRatingReview
    );

    return (
      <View style={this.props.styles.style}>
        {this.loading ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size={30} color={theme.color} />
          </View>
        ) : (
          <View>
            <Text style={this.props.styles.inputTitle}>
              {UIText.ratingReviewOverallRating}:
            </Text>
            <View style={this.props.styles.overall}>
              <RatingReviewMarketStars
                achievement={this.achievement}
                samples={samples}
                rating={rating}
              />
            </View>
            <Text style={this.props.styles.inputTitle}>
              {UIText.ratingReviewReviews}:
            </Text>
            {isEmpty(this.ratingReviews) && isEmpty(this.testimonials) && (
              <View style={this.props.styles.buttonContainer}>
                <View style={this.props.styles.buttonReminder}>
                  <Text style={this.props.styles.errorMessage}>
                    {UIText.adminCentreNoRatingReviews}
                  </Text>
                </View>
              </View>
            )}
            {this.testimonials.map(testimonial => (
              <Observer key={testimonial.createTime}>
                {() => (
                  <RatingReviewSmallCard
                    ratingReview={testimonial}
                    isTestimonial
                    truncateLastName
                  />
                )}
              </Observer>
            ))}
            {this.ratingReviews.map(ratingReview => (
              <Observer key={ratingReview.submissionDate}>
                {() => (
                  <RatingReviewSmallCard
                    ratingReview={ratingReview}
                    truncateLastName
                  />
                )}
              </Observer>
            ))}
            {!isEmpty(this.achievement) &&
              isSnSOrAdmin(clientController.client.user) && (
                <>
                  <Text>&nbsp;</Text>
                  <Text style={this.props.styles.inputTitle}>
                    Achievement data (visible to Admins):
                  </Text>
                  <View style={this.props.styles.buttonContainer}>
                    <Text>{JSON.stringify(this.achievement, 0, 2)}</Text>
                  </View>
                </>
              )}
            <Text style={this.props.styles.popupStretcher}>
              {Array.from(new Array(510)).join(" ")}
            </Text>
          </View>
        )}
      </View>
    );
  }
}

export { RatingReviewDetailFormInsertView };
