import { avatarSizeLimit, groupMemberWording } from "../variable-config";
import {
  getBrowserTimeZoneName,
  getDisplayNameEng,
  getPriceString,
  isEmpty
} from "../../utils/helpers";

const general = {
  title: "myCareBase",
  titleShort: "mCB",
  // welcomeMessage: ipsum(),
  welcomeMessage: "Welcome!\nPlease sign in or create a new account.",
  idleTimeout: "Your session has timed out. Please sign in again.",

  get timezoneUnavailable() {
    return `Timezone not available, defaulted to ${getBrowserTimeZoneName()}.`;
  },

  login: "Sign in",
  loginProgress: "Signing in...",
  postLoginProgress: "Loading profile...",
  loginWrongCred: "Incorrect username or password.",
  loginWrongPassword: "Incorrect password.",
  loginWrongCurrentPassword: "Incorrect current password.",
  loginUnverified:
    "Please verify your email address before attempting to sign in.",
  loginResendVerification: "Resend verification link",
  loginFailure: "Sign in unsuccessful",
  loginFieldUsername: "Username or Email Address",
  // loginFieldUsername: "Username, or Email, or Phone",
  loginFieldPassword: "Password",
  loginLinkRegister: "Create an account",
  loginLinkReset: "Trouble signing in",
  loginShowGenericMarket: "Show Caregiver Marketplace",

  registration: "registration",
  registrationProgress: "Creating your account...",
  registrationSuccess:
    "Your account has been created! Please check your email for further instructions.",
  registrationFailure: "Sign-up unsuccessful",
  registrationUniqueFields: {
    email: "email",
    username: "username"
  },
  registrationFieldNames: {
    username: "Username",
    password: "Password",
    repeat: "Confirm Password",
    email: "Email",
    phone: "Phone",
    firstName: "First Name",
    lastName: "Last Name",
    gender: "Gender",
    genderMale: "Male",
    genderFemale: "Female"
  },
  registrationAction: "Sign up",
  registrationUniqueExists: "already exists.",
  registrationConfirmPassword: "Please confirm your password.",
  registrationPasswordErrorLowerNum8:
    "Password must be a minimum of 8 characters and contain at least one letter and one number. Special characters are allowed.",
  registrationPasswordErrorLowerNumSpec8:
    "Password must be a minimum of 8 characters and contain at least one letter and one number and one special character.",
  registrationPasswordErrorUpperLowerNum8:
    "Password must be at least 8 characters with numbers, upper case and lower case letters",
  registrationPasswordErrorUpperLowerNumSpec8:
    "Password must be at least 8 characters with numbers, upper case and lower case letters, and special characters",
  registrationPasswordErrorUpperSpec8:
    // "Password must be a minimum of 8 characters and contain at least one uppercase character and one special character.",
    "Password must be a minimum of 8 characters and contain at least one uppercase character and one special character, except plus sign (+) is not allowed.",
  registrationPasswordNotMatch: "Password does not match",
  registrationAgreement: "Will open agreement page.",
  registrationKnowAccount: "I have an account",
  registrationEmailNoPlusSign:
    "Invalid email: The email address cannot contain a plus sign (+).",

  verification: "verification",
  verificationSuccess:
    "You have successfully verified your email! Please proceed to sign in.",
  verificationFailure: "Verification unsuccessful",
  verificationExpiry: "Your verification link is invalid or expired!",
  verificationResent:
    "Your verification link has been sent. Please check your email for further instructions.",

  resetPassword: "Reset password",
  resetPasswordGeneral: "Account & Password",
  resetPasswordFailure: "Reset password unsuccessful",
  resetPasswordSuccessful: "Your password has been updated.",
  resetPasswordNewPassword: "New password",
  resetInvalidLink: "Your reset password link is invalid or expired!",
  resetRequestSent:
    // "Your password reset link is on its way! Please check your email for further instructions.",
    "If you have entered the correct account identifier, you should receive an email containing password reset instructions within a few minutes.",
  resetKnowPassword: "I know my password",
  resetAgreeTerms: "Terms and Conditions",
  resetChangeEmail: "Change Email Address",
  resetChangeEmailPlaceholder: "Email (leave blank if not changing)",
  resetGotoLogin: "Go to sign in",

  changePassword: "Change password",
  changePasswordSamePasswordError:
    "New password must not be the same as old password.",
  changeEmail: "Change email address",
  changeEmailNewEmailAddress: "New email address",
  changeEmailPropagation: (original, changed) =>
    `Would you also like to change the email address ${original} used in other groups to ${changed}?`,
  changeEmailApplyToGroups: "Apply email address to groups",
  changeEmailRequestSuccess:
    "Your requested change will not made until you verify your new email address. You will receive the verification email shortly. You may also cancel the request on this page.",
  changeEmailPendingEmail: "Awaiting confirmation",
  changeEmailPending: "Pending email change",
  changeEmailCancel: "Cancel change",
  changeEmailCancelConfirm:
    "Are you sure you want to cancel the email change request?",
  changeEmailCancelSuccess: "Your requested email change has been cancelled.",
  changeEmailRecent: "Recent email change",
  changeEmailRecentOld: "From",
  changeEmailRecentNew: "Changed to",
  changeEmailRevert: "Undo email change",
  changeEmailRevertButton: "Undo change",
  changeEmailRevertDeadline: deadline =>
    `* You can only undo this change before ${deadline}.`,
  changeEmailRevertConfirm: "Are you sure you want to undo this email change?",
  changeEmailRevertRequestSuccess:
    "Your email change undo will not take effect until you verify your previous email address. You will receive the verification email shortly.",

  fileUpload: "upload",
  fileUploadFailure: "Upload failed.",
  fileUploadAvatarOversize: `Please choose an image smaller than ${avatarSizeLimit /
    1024}MB.`,
  fileUploadFileOversize: `Please choose a file smaller than ${avatarSizeLimit /
    1024}MB.`,
  fileUploadCropImage: "Crop and resize",

  invitation: "invitation",
  invitationInvite: "invite",
  invitationVerification: "Invitation verification",
  invitationIntroduction: (firstName, email, invitorFirstName) =>
    `Hi ${firstName} (${email}), you have received an invitation from ${invitorFirstName}!`,
  invitationToAccept: "To accept this invitation:",
  invitationLoginSent:
    "Please check your invitation email sent to your invited email address for further instructions.",
  invitationLoginVerifyFailure:
    "Your invitation email verification link is invalid or expired!",
  invitationLoginVerifySuccess:
    "You have successfully verified your invited email! Please sign in to continue accept your invitation.",
  invitationPendingAcceptLogin:
    "Please sign in to continue accept your invitation.",
  invitationAlreadyAccepted:
    "You have already accepted this invitation, please proceed to sign in.",
  invitationRegDivider: " Or ",
  invitationCancel: "Cancel and go to sign in.",
  invitationRejectOwn: "Sorry, you cannot accept your own invitation.",
  invitationPending: hasRoleGroup =>
    `You have at least one new invitation${
      hasRoleGroup ? " or employment offer" : ""
    } waiting.\nWould you like to view it now?`,
  invitationCancelled: "Sorry, this invitation has been cancelled.",
  invitationInvalidInvitation:
    "This invitation is either invalid or does not exist.",
  invitationPreviouslyInvited: (
    email,
    oriMemberName,
    newMemberName
  ) => `The email address ${email} is associated with the member ${oriMemberName}, who was previously removed.
  
Do you wish to reinstate ${oriMemberName} or invite a new member with the name "${newMemberName}"?`,
  invitationPreviouslyHired: (
    groupName,
    oriMemberName,
    newMemberName,
    isHired
  ) => `${newMemberName} was previously removed from the ${groupName}.
  
${
  isHired
    ? `Do you wish to reinstate ${oriMemberName} as a Paid Caregiver under the previous employment arrangement or send a new employment offer to ${newMemberName}?`
    : `Do you wish to reinstate ${oriMemberName} as a Paid Caregiver under the previous arrangement or send a new invitation to ${newMemberName}?`
}`,
  invitationReinstate: "Reinstate",
  invitationInviteNew: "New invitation",
  invitationHireNew: "New hire",
  invitationReinstated: memberName => `${memberName} has been reinstated.`,

  visitor: "Visitor",
  visitorLogin: "Visit Marketplace",
  visitorWelcomeMessage:
    "Welcome to myCareBase! Please enter your name and email in order to access our marketplace to search for pre-screened Caregivers who are available for hire.",
  visitorWelcomeMessageAnonymous:
    "Welcome to myCareBase! Please agree to our Terms and Conditions in order to access our marketplace to search for pre-screened Caregivers who are available for hire.",
  visitorMarketTncText: "By using myCareBase Marketplace, you agree to our",

  entryRequiring: "Please enter",
  entryRequiringSingular: "Please enter a",
  entryRequiringValid: "Please enter a valid",
  usernameRequirement:
    "Username can only contain alphanumeric characters, underscores, dashes and periods.",

  clientLoading: "Refreshing",
  clientReset: "Clear cache and reset",
  localCacheExp: "Local Cache (Experimental)",
  serviceUnavailable: "Service is not available at this time.",

  success: "success",
  comingSoon: "Coming soon.",
  pleaseWait: "Please wait...",
  updating: "Updating",
  logoutProgress: "Signing out",
  checkEntries: "Please review your entries.",
  checkEntriesWithFields: (fields, messages) =>
    `Please review your entries${
      !isEmpty(fields)
        ? ":\n" + fields.map(f => `• ${f.label || f.placeholder}`).join("\n")
        : "."
    }${messages ? `\n\n${messages}` : ""}`,

  password: "password",
  oldPassword: "current password",
  optional: "optional",
  drawer: "Side Menu",
  drawerGroupLabel: "Your roles and groups",
  settings: "Settings",
  logout: "Sign out",
  typeRoleGroupIndicator: "Role",
  newGroup: "New role or group",
  unknownRole: "Unknown role",

  createdAt: "Created at",
  updatedAt: "Updated at",

  generalConfirm: "Ok",
  generalCancel: "Cancel",
  generalYes: "Yes",
  generalNo: "No",
  generalAccept: "Accept",
  generalApply: "Apply",
  generalSend: "Send",
  generalMinimize: "Minimize",
  generalReset: "Reset",
  generalBack: "Back",
  generalNext: "Next",
  generalSubmit: "Submit",
  generalChange: "Change",
  generalDone: "Done",
  generalFinish: "Finish",
  generalSelectAll: "Select all",
  generalDeselectAll: "Deselect all",
  generalDeleteItem: itemName => `Delete ${itemName}`,

  pageNotExist: "Page does not exist.",
  chatNotExist: "The chat no longer exist.",

  filter: "filter",
  filterAll: "all",
  filterShowOnly: "Show only",
  filterTitle: listName => (listName ? `${listName} filter` : "Filter"),
  filterApplied: "Filter applied",
  filterShowing: "Showing",

  collapseAll: "Collapse All",
  uncollapseAll: "Expand All",

  tooltipHelp: title => `${title ? `${title} ` : ""}Help`,

  actionMenu: "Action Menu",
  noActionAvailable: "No action available",

  you: "You",
  others: "Others",
  youAndOthers: "You and others",

  shiftMaker: "Shift Maker",
  shiftMakerIntroduction:
    "Shift Maker helps you create Recurring Shifts with Tasks that you choose. You can create your first Shift now.",
  shiftMakerIntroductionHelp: `(1) To create a Recurring Shift, give it a name, which you can change later.
(2) Add Tasks.
(3) Optionally, set the start and end times via the Actions menu.
(4) Assign the Shift to one ore more Care Circle members via the Actions menu.

For more information, click/tap the help icon above the Actions menu button.`,
  shiftMakerHelp: groupTypeName => `(1) Create a Recurring Shift by using the drop down menu and selecting “New Recurring Shift”. Give it a meaningful name such as Daily Mornings. You can change the name at any time later through the Actions menu.

(2) Add the Tasks that are to be performed during the Recurring Shift.  You can use the pre-set templates or a choose “Custom Task” and create your own Task.  Within each template, there are things for you to complete such as Title, Instructions, and choosing whether the Task is mandatory or optional. The remaining items are for the Caregiver to fill in during his or her Shift later.  When you are finished entering the instructions, select Next at the bottom.  Repeat this step for all the Tasks that you want to keep track of for this Shift.

(3) Actions menu: set the start and end times of the Recurring Shift.

(4) Actions menu: assign the Recurring Shift to one or more members of the ${groupTypeName}. (Any one of the assigned members can clock in to the Shift after it has been assigned.)  You are done!

 ———————————————

If you would like to make a change later to a Recurring Shift, you need to first unassign the Shift through the Actions menu, make the changes you need (you can modify, add or delete Tasks), then re-assign the Shift to the members of the ${groupTypeName}.`,
  shiftMakerShifts: "Shifts",
  shiftMakerShift: "Shift",
  shiftMakerShiftTemplate: "Recurring Shift",
  // shiftMakerShiftTemplatePicker: "Select or create a Shift:",
  shiftMakerShiftTemplatePicker: "Select or create a Recurring Shift:",
  shiftMakerTemplateDeleteDeny:
    "Shift template cannot be deleted when there are Task templates.",
  shiftMakerTodoDeleteDeny: "To-do cannot be deleted after completion.",
  shiftMakerConfirmDeactivate:
    "Would you like to remove the Shift from the schedule?",
  shiftMakerAddScheduledShift: "Add to schedule",
  shiftMakerRemoveScheduledShift: "Remove from schedule",
  // shiftMakerActivating: "Activating",
  shiftMakerActivating: "Adding Shift to schedule",
  // shiftMakerDeactivating: "Deactivating",
  shiftMakerDeactivating: "Removing Shift from schedule",
  shiftMakerActivateReminder1: `Using the "Add Task" button above, add the Tasks to be performed on this Shift.`,
  shiftMakerActivateReminder2:
    "Don't forget to assign the Shift and add it to the schedule in Actions.",
  shiftMakerTaskTemplateTab: "Task templates",
  // shiftMakerShiftTab: "Shifts pending",
  shiftMakerShiftTab: "Scheduled Shifts",
  shiftMakerAllScheduledShifts: "All scheduled Shifts",
  shiftMakerNewTaskTemplate: "Add Task",
  shiftMakerNoTaskToShow: "No Tasks to show at this time.",
  shiftMakerNoShiftsToShow: "No scheduled Shifts to show at this time.",
  // shiftMakerShiftClockedIn: "Clocked in",
  // shiftMakerShiftClockedOut: "Clocked out",
  shiftMakerTaskOriginalTitle: "Original template",
  shiftMakerStartWithParent: "Please start this Task by clock-in the Shift.",
  shiftMakerEditInstance:
    "Changes to this scheduled Shift does not affect the Recurring Shift. To change the Recurring Shift and all future scheduled Shifts, remove the Recurring Shift from the schedule (Actions menu), make the changes, and the add the Recurring Shift back to the schedule",
  shiftListHelp: `"New" - future Shifts to be performed
  
"Clocked-in" - Shifts currently in progress

"Clocked-out" - Shifts already completed

Tap any Shift to review Task details
`,
  shiftTaskHelp: `Check off Tasks when completed.\n\nTap on Task to enter in observations and notes`,
  shiftTaskListHelp:
    "To view or enter details about a Task, click/tap on the card. If a Task is completed, click/tap on the checkmark. You can enter Shift notes or chat with the Care Circle by clicking/tapping the chat icon on the top-right. To enter your time, go to the Completion tab.",
  shiftHelpNonAssigned: "This Shift is assigned to another member.",
  shiftDetailHelp: `Tap on the Completion tab to answer the Shift questions or clock-in/out the Shift.`,
  shiftSubmitShift: "Submit Shift",
  shiftSubmitHelp:
    "Please enter your clocked times and answer the questions before clicking/tapping Submit.",
  shiftTaskChatNote:
    "Use the chat button in the top right corner to write a note specifically pertaining to this Task.",
  shiftScheduled: "Scheduled",
  shiftNewShifts: "New Shifts",
  shiftNewShift: "Pending",
  shiftClockedInShifts: "Clocked-in",
  shiftClockedOutShifts: "Clocked-out",
  shiftClockingInPleaseWait: "Clocking-in, please wait",
  shiftPartiallyComplete: "In Progress",
  shiftCompleted: "Completed",
  shiftClockedInBy: name => `In progress by ${name}`,
  shiftClockedOutBy: name => `Completed by ${name}`,
  shiftMakerCorrectEndTimeReminder:
    "To correct Shift clocked time, select a Completed Shift and use the Actions menu within.",
  shiftIsSimpleShifts: "From Shift Maker",
  shiftCalendar: "Shift calendar",
  // shiftCalendarEntryButton: "Log/view Shifts on calendar",
  shiftCalendarEntryButton: "Log unscheduled Shifts",
  shiftCalendarBackToList: "All Shifts",
  shiftCalendarMonthView: "Month",
  shiftCalendarWeekView: "Week",
  shiftCalendarIntroductionMonth:
    "Select a date to start enter the Shift information.",
  shiftCalendarIntroductionWeek:
    "Select a time block to start enter the Shift information.",
  shiftCalendarShowShifts: date => `Show Shifts for ${date || "this date"}`,
  shiftCalendarToday: "Today",
  shiftCalendarAddShift: "Add a Shift",
  shiftCalendarAddShiftTitle: "Title",
  shiftCalendarAddShiftTitlePlaceholder: "Name of the Shift (optional)",
  shiftCalendarAddShiftSuccess: "A Shift has been added successfully.",
  shiftCalendarAddShiftMissingValue: "Missing shift instance value.",
  shiftCalendarAddShiftClockedDateInverted:
    "Clocked-in time cannot be later than Clocked-out time.",
  shiftCalendarMarkHelp: "Shift indicator colors:",
  shiftCalendarDarkerText: "Darker day text:",
  shiftCalendarDarkerBlock: "Darker blocks:",
  shiftCalendarCurrentPayPeriod: "Current pay period",
  shiftMissingHourlyRate:
    "Shift cannot be completed, missing hourly rate on profile.",
  shiftCompletion: "Completion",

  marketplace: "Marketplace",
  marketNewGenericSearch: groupTypeName => `New ${groupTypeName} Search`,
  marketShortlist: "Shortlist",
  marketShortListPickerLabel: "Add candidates to this shortlist:",
  marketShortlistDeleteDeny:
    "Candidate shortlist cannot be deleted when there are candidates.",
  marketDefaultShortlistName: "Shortlist 1",
  marketTabCriteria: "Search\ncriteria",
  marketTabSearch: "Search\nresults",
  marketTabCandidates: "Shortlisted\ncandidates",
  marketSearchNoMatch:
    "Sorry, we could not find a match for your search criteria.",
  marketCandidateNoMatch: "No candidates to show.",
  marketCandidateAlreadyInShortlist:
    "This Caregiver was already added to another shortlist.",
  marketCandidateDuplicateShow: "Open the Candidate card for this Caregiver",
  marketCandidateDuplicateAdd: "Add to this shortlist anyway",
  marketCandidateDuplicateNoAdd: "Do not add to this shortlist",
  marketResetCriteria: "Reset criteria",
  marketSaveCriteria: "Save to profile",
  // marketSaveCriteriaScratchpad: "Save and create Care Circle",
  marketSaveCriteriaScratchpad: "Save and create new Care Circle",
  marketCriteriaSimplified: isSimplified =>
    isSimplified
      ? "Show more search criteria..."
      : "Show less search criteria...",
  marketSearchResults: "Search results",
  marketSeeSearchResults: "Update search results",
  marketShortlistReminder:
    "Adding a Caregiver to the shortlist of candidates will allow you to communicate via chat with that candidate.",
  marketAddToShortlist: "Add to shortlist",
  marketAddAllToShortlist: "Add all to shortlist",
  marketAddedToShortlist: (candidateName, shortlistName, chatEnabled) =>
    `${candidateName} has been added to ${shortlistName}.${
      chatEnabled ? " You may also chat with this candidate now." : ""
    }`,
  marketAddedAllToShortlist: (shortlistName, chatEnabled) =>
    `All Caregivers in the search result have been added to ${shortlistName}.${
      chatEnabled ? " You may also chat with them now." : ""
    }`,
  marketRemovedFromShortlist: (candidateName, shortlistName) =>
    `${candidateName} has been removed from ${shortlistName}`,
  marketArchivedFromShortlist: (candidateName, shortlistName) =>
    `${candidateName} has been archived from ${shortlistName}`,
  marketAlreadyInShortlist: "Added to shortlist",
  marketTopicRemoveDeny: "Candidate cannot be removed when there are messages.",
  marketTopicArchiveRemove: (isArchive, candidateName, shortlistName) =>
    `Are you sure you want to ${
      isArchive ? "archive" : "remove"
    } ${candidateName} from ${shortlistName}? `,
  marketSubNoChat:
    "Chat with Candidates is only available with selected plans.",
  marketSubNoSave: groupTypeName =>
    `Saving to profile requires a new ${groupTypeName}, which is only available with selected plans.`,
  marketSubNoHire: groupTypeName =>
    `Sending an employment offer requires a new ${groupTypeName}, which is only available with selected plans.`,
  marketSubNoSubNoHire:
    "Sending an employment offer is only available with selected plans.",
  marketPlanInfo: "For more information about our plans, go to ",
  marketVisitorInfo:
    "Welcome to the Marketplace! Feel free to search on your own, or contact our Care Advisor at",
  marketVisitorInfo2: " to help you find a great Caregiver.",
  marketConvertGroupName: groupTypeName => `Save ${groupTypeName}`,
  marketConvertGroupNamePlaceholder: "Name of care recipient",
  marketHour: "Hr",
  marketYoE: years => `${years} ${years > 1 ? "YEARS" : "YEAR"} EXPERIENCE`,
  marketRateNotSet: "Rate not set",
  marketIntroduction: "Introduction",
  marketScratchpadUnavailable:
    "Sorry, Marketplace is not available at this time.",
  marketHelpCriteria: groupTypeNamePlural =>
    `Please answer the following questions about the care recipient.\n\nWhen finished, select "See search results" to view a list of ${(groupTypeNamePlural &&
      groupTypeNamePlural.toLowerCase()) ||
      "Caregivers"} who match your search criteria.`,
  marketNotAvailable:
    "Sorry, Marketplace is not available at this time, please try again later.",
  marketShortlistNameReserved: "Shortlist name is reserved and cannot be used.",
  marketHire: "hire",
  marketConfirmHire: candidateName =>
    `Would you like to hire and send ${candidateName} an employment offer email?`,
  marketHireSent: candidateName =>
    `Employment offer email has been sent to ${candidateName}`,
  marketAlreadyHired: candidateName =>
    `You have already sent an employment offer email to ${candidateName}`,
  marketMandatoryCriteriaHeading: "   M =\nmandatory\ncriteria",
  marketMandatoryCriteria: "Mandatory criteria",
  marketMandatorySwitches: "Mandatory switches",
  marketHideMandatorySwitches: "Hide mandatory criteria switches.",
  marketMandatorySwitchTitle: "M",
  marketMandatoryCriteriaHelp: `Use the "M" (mandatory criteria) switch to make a search field mandatory, which ensures that the search results will only show Caregivers that match that field.`,
  marketMandatoryCriteriaSearchHelp: `When setting the search criteria, use the "M" (mandatory criteria) switch to make a search field mandatory in the search results.

When viewing the search results, select "mandatory criteria only" to ensure that the search results will only show Caregivers that strictly match fields marked as mandatory criteria.`,
  marketSearchMandatoryOnly: "Mandatory criteria only",
  marketAdminBroadcast: "Broadcast to Caregivers",
  marketAdminBroadcastMsg: "Message to broadcast:",
  marketAdminBroadcastShortlist: "Send broadcast to shortlist",
  marketAdminBroadcastHeading: `* Broadcast will be sent as chat message to the Caregivers, you can check out the chat(s) in "Dashboard - Messaging" later.`,
  marketAdminBroadcastPlaceholder: "Enter the message to broadcast...",
  marketAdminBroadcastSuccess: shortlistName =>
    `A message has been broadcast to the Caregivers in the Shortlist "${shortlistName}".`,
  marketLoadingCAF: "Loading Caregiver Finder",

  issues: "Issues",
  issuesAddIssue: "Add Issue",
  issuesOpenIssue: "Open",
  issuesAcceptedIssue: "Accepted",
  issuesResolvedIssue: "Resolved",
  issuesNoIssueToShow: "No Issues to show at this time",
  issueDueDate: "Due date",
  issueAcceptedBy: "Accepted by",
  issueResolvedDate: "Resolved on",
  issueResolvedBy: "Resolved by",
  issueEditStartEndTime: "Set/reset due date/time (optional)",
  issueEditAssignee: "Assign/re-assign Issue (optional)",
  issueResolutionNote:
    "To resolve an Issue, accept or take over the Issue first.",

  assignedTo: "Assigned to",

  memberSelf: "Me",
  memberPending: "Pending",

  group: "group",
  groups: "groups",
  topic: "topic",
  profile: "profile",
  chat: "chat",
  setup: "setup",
  summary: "summary",
  notes: "notes",
  reports: "reports",
  details: "details",
  attachment: "attachment",

  groupTabDashboard: "Dashboard",
  groupTabCaregiving: "caregiving",
  groupTabCaregivingTab: "Caregiving tab",
  groupMemberWording: groupMemberWording,
  groupSingleMemberGroup: "Role",
  groupOwnerLabel: "Owner",
  groupChangeOwner: "Change owner",
  groupChangeOwnerNewOwner: "New owner",
  groupChangeOwnerConfirm: (groupName, groupTypeName) =>
    `Are you sure you want to change the owner of the ${groupTypeName} for ${groupName}? Once the change has been made, only the new owner can perform this and other actions on the ${groupTypeName}.`,
  groupChangeRole: "Change role",
  groupChangeHourlyRate: "Change hourly rate",
  groupChangeHourlyRateUnavailable:
    "Sorry, change hourly rate is not available at this time.",
  groupRoleNoChangeCareReceiver: "Sorry, the Care Recipient cannot be changed.",
  groupAddTodo: "Add to-do",
  groupMembersHiredCaregiverTag: "(Hired)",
  groupNoShiftToShow: "No Shifts to show at this time.",
  groupNoTodoToShow: "No To-dos to show at this time.",
  groupChatNotAvailable: "Sorry, group chat is not available at this time",
  groupCaregiverEENoTopic:
    "Future opportunities will be shown here when families contact you.",
  groupCaregiverEETopicArchived:
    "This opportunity has been archived by the contact",
  groupHelpInvite: groupTypeName =>
    `Invited members of the ${groupTypeName} can participate in group chats, one-on-one chats, and view Caregiver Shift and Task details`,
  groupResendInvite: "Resend invitation email",
  groupResendInviteConfirm: memberName =>
    `Resend invitation email to\n${memberName}?`,
  groupResendInviteSuccess: memberName =>
    `Invitation email has been sent to\n${memberName}.`,
  groupCancelInvite: `Cancel invitation`,
  groupCancelInviteConfirm: memberName =>
    `Are you sure you want to cancel the invitation sent to ${memberName}?`,
  groupDeleteMember: "Remove member",
  groupDeleteMemberConfirm: (memberName, groupName) =>
    `Are you sure you want to remove ${memberName} from the ${groupName}?`,
  groupDeleteMemberNotify: memberName =>
    `Inform member ${memberName} of this removal.`,
  groupSummaryLastCompletedShift: "Last Shift:",
  groupSummaryTotalShifts: "Number of Shifts",
  groupSummaryWeek: "This week",
  groupSummaryMonth: "This month",
  groupSummaryMdtTaskCompleted: "Mandatory Tasks completed",
  groupSummaryOptTaskCompleted: "Optional Tasks completed",
  groupSummaryPendingIssues: "Number of pending Issues",
  groupSummaryUnresolvedIssues: "Total open Issues",
  groupSummaryNoLastShift: "No completed Shift",
  groupBeenDeleted: groupName => `You have been removed from the ${groupName}`,
  groupTopicsSearch: "Search Shifts, Tasks, Issues and more...",
  groupMCBMessages: "myCareBase messages",
  groupMCBShort: "mCB Staff",
  groupAdminGroupMessages: "Messaging",
  groupAdminProactiveChat: "Proactive chat",
  groupAdminProactiveChats: "Proactive chats",
  groupAdminProactiveChatNewVisitor:
    "A new visitor has landed on the website, click here to chat with the visitor.",
  groupPaidProvider: "Paid Provider",
  groupServiceProviderName: groupTypeName => `${groupTypeName} Service`,
  groupTimeZone: "Time Zone",
  groupUnknownTimeZone: "Unknown time zone",
  groupAllTimeShowIn: (timezone, short) =>
    short
      ? `Time Zone: ${timezone}`
      : `All dates and times are shown in ${timezone}`,

  chatMessageEmpty: "No messages yet.",
  chatMessageEnd: "End of messages.",
  fetchingMessages: "Loading more messages...",
  chatNewMessage: "New message has arrived, view it now?",
  chatNewMessageAlert: "You have a new message in the myCareBase chat.",
  chatInputPlaceholder: "Type your message...",
  chatUploadImage: "Upload image",
  chatEmojiWin10: `If you are running Windows 10 version 1809 or later, please press\n"WIN+(period)" or "WIN+(semicolon)"\nto access the emoji panel.`,
  chatEmojiOSX: `If you are running OS X Mavericks or later, please press\n"Control–Command–Space"\nto access the emoji panel.`,
  chatEmojiNotSupported:
    "Sorry, emoji is not supported on your operating system.",
  chatContactName: groupTypeName => `${groupTypeName} Contact`,
  chatContactRemoved: "Removed",

  topicNotCompleted: "not completed",
  topicCompleted: "completed",
  topicNewTopic: topicTypeName => `New ${topicTypeName}`,
  topicNewTopicInput: topicTypeName => `Name of new ${topicTypeName}`,
  topicEditName: topicTypeName => `Change ${topicTypeName} name`,
  topicEditNamePlaceholder: topicTypeName => `New ${topicTypeName} name`,
  topicEditAssignee: topicTypeName => `Assign/unassign ${topicTypeName}`,
  topicEditStartEndTime: topicTypeName =>
    `Set/reset ${topicTypeName} start/end time`,
  topicEditCorrectedEndTime: topicTypeName =>
    `Correct clocked time for ${topicTypeName}`,
  topicEditCorrectedEndTimePlaceholder: "Enter number of corrected hours",
  topicEditCorrectedEndTimeReminder:
    "To enter or view a clocked time correction, use the Action menu at the top of this form.",
  topicEditStartEndInvalidInverted:
    "End time cannot be earlier than start time.",
  topicEditStartEndInvalidEnd: "Time cannot be set in the past",
  topicAssignAssignee: topicTypeName => `Assign a ${topicTypeName}`,
  topicAssigning: "Assigning...",
  topicPickAssignee: topicName => `Assign "${topicName}" to`,
  topicDeleteConfirm: topicName =>
    `Are you sure you want to delete "${topicName}"?`,
  topicDeleteDeny: topicName => `Sorry, ${topicName} cannot be deleted.`,
  topicStartTime: "Start time",
  topicEndTime: "End time",
  topicTimeNotSet: "Not set",
  topicStartTopic: "start",
  topicCompleteTopic: "complete",
  topicTakeoverAssignee: "Take over",
  topicUnassignSelf: "Unaccept",
  topicRequiredDetailComplete: "Required fields completed",
  topicRequiredDetailIncomplete: "Required fields incomplete",
  topicLocked: "Locked",
  topicCreatedViaCalendar: "Created via calendar",

  profileSaving: "Saving profile...",
  profileSave: "save",
  profileEdit: "edit",
  profileUploadAvatarConfirm: filename => `Upload ${filename}?`,
  profileRemoveAvatar: "Remove avatar",
  profileMandatoryAbleMunicipality:
    "Please select at least one territory and one municipality that you are able to work in.",

  setupComplete: "Complete",
  setupFinish: "Finish",
  setupGeneralFinishMsg: "Thank you for your information",

  plan: "Plan",
  planSubscription: "Subscription",
  planSubscriptionShort: "Sub",
  planAndPricing: "Plans & Pricing",
  planBillingPlan: "Subscription and billing",
  planSubscribeNow: "Subscribe now",
  planResidentialProvince: "Province of residence of care recipient",
  planAvailablePlans: "Available plans",
  planSelectedPlan: "Selected plan",
  planSelectAPlan: "Select a plan",
  planFreePrice: "free",
  planBillingAddress: "Billing details",
  planCreditCard: last4 => `Credit card${last4 ? ` ending in ${last4}` : ""}`,
  planAddPaymentMethod: "Add payment method",
  planMissingPaymentMethod: "Missing payment method",
  planPayment: "Payment",
  planPaymentMethod: "Payment method",
  planPaymentMethods: "Payment methods",
  planModifyPaymentMethods: "Modify payment methods",
  planPlanName: "Plan name",
  planStartDate: "Start date",
  planInterval: "Plan interval type",
  planSubStatus: "Subscription status",
  planValidateCard: "Validate credit card",
  planValidateCardOk: "Valid credit card √",
  planInvalidCard: "Invalid credit card",
  planConfirmDetail: "Confirm information",
  planPriceBreakdown: "Price Breakdown",
  planNoFeature: (groupTypeName, toolName) =>
    `Access to ${
      toolName ? toolName : `${groupTypeName} tools`
    } requires the selection of a plan.`,
  // planLearnMore: "To learn more about these plans and products, please go to ",
  planLearnMore1: "To learn more about these plans and products, please call ",
  planLearnMore2: " or use our ",
  planLearnMoreContactForm: "contact form",
  planTrialPromoRemaining: isPromo => `${isPromo ? "Promo" : "Trial"} period`,
  planTrialRemaining: days => `${days} days remaining`,
  planTrialRemainingPopup: (days, isPromo) =>
    `${days} days remaining in the ${
      isPromo ? "promo" : "trial"
    } subscription period for this group.`,
  planChangePlanSuccess: "Your subscription has been updated.",
  planChangePayment: "Update payment information",
  planChangePaymentIntro:
    "Submit your new payment information as needed. This will not affect your current plan and promotions.",
  planChangePaymentSuccess: "Your payment information has been updated.",
  planTrialFirstMonthFreeAppendix: " - 1st month is FREE",
  planTrialReminder:
    "If you do not wish to continue using the Care Management tools beyond the 30-day free trial period, please be sure to contact our customer support to cancel your subscription prior to the end of the free trial period in order to avoid having your credit card charged.",
  planBillingProvince: "Province",
  planBillingState: "State",
  planPromoCode: "Promo code",
  planClearPromoCode: "Clear promo code",
  planPromoDeferCharge: (amount, unit) =>
    `You will not be charged until after the promo period${
      amount && unit ? `of ${amount} ${unit}${amount > 1 ? "s" : ""}` : ``
    }.`,
  planPromoNotApplicable: (promoCode, plans) =>
    `This promo code [${promoCode}] can only be used with the following plan(s):\n\n${plans}`,
  planPromoExpired: promoCode => `[${promoCode}] has expired.`,
  planPromoNotFound: promoCode => `[${promoCode}] is not a valid promo code.`,
  planPromoExceededUsage: promoCode =>
    `[${promoCode}] has exceeded the maximum number of redeem count.`,
  planNotAccessible:
    "Sorry, plan and subscription is not available at this time.",

  planConfirmDefaultSource: last4 =>
    `Is card number ending ${last4} still the correct default payment method?\n\nIf not, please update your default payment method in the opening window. ${
      general.planAfterPopupCloseWindow
    }`,
  get planRequestAddDefaultSource() {
    return `Please add your default payment method in the opening window.\n${
      general.planAfterPopupCloseWindow
    }`;
  },
  get planRequestConfirmDefaultSource() {
    return `Please confirm your default payment method in the opening window.\n${
      general.planAfterPopupCloseWindow
    }`;
  },
  planAfterPopupCloseWindow: `When you're done, simply click on "Done" or close the window.`,

  priceBreakdownItem: "Item",
  priceBreakdownPrice: "Price",
  priceBreakdownTax: "Tax",
  priceBreakdownTotal: "Total",
  priceBreakdownSubtotal: "Subtotal",
  // priceBreakdownDiscount: "Free trial",
  priceBreakdownDiscount: "Discount",
  priceBreakdownGST: "GST",
  priceBreakdownHST: "HST",
  priceBreakdownPST: "PST",
  priceBreakdownUnavailable:
    "Sorry, price breakdown is not available at this time.",
  priceBreakdownPayAsYouGo:
    "You will not be charged anything at this time. Charges will be invoiced as Caregiver hours are recorded and reported.",

  subscriptionMembership: "Membership",
  subscriptionStatus: "Subscription status",
  subscriptionCurrent: "Current subscription",
  subscriptionNoActive: "No active subscription",
  subscriptionActive: date =>
    `Active (Renews on ${new Date(date).toLocaleDateString()})`,
  subscriptionExpired: "Expired",
  subscriptionSetToCancel: date =>
    `Cancelled (Expires on ${new Date(date).toLocaleDateString()})`,
  subscriptionDisplay: subscription =>
    !isEmpty(subscription) &&
    `${(subscription.wpProduct || {}).name} - ${getPriceString(
      Number((subscription.wpProduct || {}).total || 0)
    )}`.trim(),
  subscriptionPaused: "Paused",
  subscriptionAdd: "Add a new subscription",
  subscriptionChange: "Manage subscriptions",
  subscriptionChangeBillingInfo: "Update billing address or payment methods.",
  subscriptionBillingAccount: "Billing account",
  subscriptionChangeAddress: "Update billing address",
  subscriptionChangeBillingIntro:
    "Correct your billing address information as needed.",
  subscriptionLearnMore1:
    "To learn more about subscriptions and products, please call ",
  subscriptionNoAvailableProducts:
    "No available product for current group type.",

  adminCentre: `Admin Centre`,
  adminCentreNothingToShow: groupTypeNamePlural =>
    `No ${groupTypeNamePlural} to show`,
  adminCentreCardStatus: "Status",
  adminCentreCardFirstContactDate: "First contacted on",
  adminCentreCardQuestionnaireCompleteDate: "Questionnaire completed on",
  adminCentreCardQuestionnaireScorePercent: "Questionnaire score",
  adminCentreCardQuestionnaireResponses: "Questionnaire responses",
  adminCentreCardJobReferenceContactDate: "References contacted on",
  adminCentreCardJobReferenceResponseCount: "References responded",
  adminCentreCardJobReferenceResponseDetail: "References response details",
  adminCentreCardInterviewDate: "Interview date",
  adminCentreCardInterviewDateCheckCalendar: "Refer to your calendar",
  adminCentreCardInterviewBookedDate: "Interview booked on",
  adminCentreCardReferenceReceivedDate: "References received on",
  adminCentreCardReferenceCheckedDate: "References checked on",
  adminCentreCardBackgroundCheckedDate: "Background check received on",
  adminCentreCardBackgroundCheckedFiles: "Background check files",
  adminCentreCardApprovalDate: "Approved on",
  adminCentreCardProfileCompletionDate: "Profile completed on",
  adminCentreCardApprovedForMarketplaceDate: "Approved for Marketplace on",
  adminCentreCardBusinessName: "Business name",
  adminCentreCardJoinedDate: "Joined on",
  adminCentreCardFormedDate: "Formed on",
  adminCentreCardAcceptedTerms: "Accepted terms?",
  adminCentreCardCaregiverApproval: "Caregiver approved?",
  adminCentreCardCaregiverApproved: isApproved =>
    isApproved ? "Approved" : "Unapproved",
  adminCentreCardProvince: "Province",
  adminCentreCardTerritory: "Territory",
  adminCentreCardCity: "City",
  adminCentreCardCareReceiver: "Care Recipient",
  adminCentreCardRelationship: "Relationship",
  adminCentreCardOwner: "Contact",
  adminCentreSortByDate: "Sort by date",
  adminCentreSortByName: "Sort by name",
  adminCentreShowHideAvatar: hide => `${hide ? "Show" : "Hide"} avatars`,
  adminCentreTimeSheetMode: enabled =>
    `${enabled ? "Disable" : "Enable"} timesheet mode`,
  adminCentreTimeSheetModeText:
    "Click on a card to see the Timesheets of the Caregivers from the Care Circle.",
  adminCentreExitTimeSheetMode: "Back to Care Circles",
  adminCentrePayroll: "Payroll",
  adminCentreTimeSheets: "Care Circle Timesheets",
  adminCentreInvoicesGen: "Auto invoice generation",
  adminCentreInvoicesReRun: "Trigger pending invoices re-run",
  adminCentreInvoicesReRunSuccess:
    "Invoices re-run complete. Please check the email sent to Admins for more details.",
  adminCentreInvoicesReRunNoData: "No pending invoices.",
  adminCentreSubscriptionInfo: "Subscription information",
  adminCentreProfile: typeName => `${typeName} Profile`,
  adminCentreDeleteGroupConfirm: (groupTypeName, groupName, subscriptionName) =>
    `Are you sure you want to delete ${groupTypeName}: ${groupName}?${
      subscriptionName
        ? `\n\nThe subscription of this ${groupTypeName} will also be cancelled:\n${subscriptionName}`
        : ""
    }`,
  adminCentreDeleteGroupNotify: groupName =>
    `Inform members of ${groupName} about this deletion.`,
  adminCentreDeleteGroupRefund:
    "Refund unused time on the current pre-paid plan.",
  adminCentreDeleteGroupSuccess: groupName => `${groupName} has been deleted.`,
  adminCentreTerritories: "Territories",
  adminCentreLocalities: "Localities",
  adminCentreLegacyTerritoriesTitle: "Legacy Territories and Municipalities",
  adminCentreTerritoriesTitle: "Territories and Municipalities",
  adminCentreCareCircleProvinces: "Care Circle Provinces",
  adminCentreTerritorySelectProv: "Select a province",
  adminCentreTerritorySelectTerri: "Select or add a territory",
  adminCentreTerritoryAddTerri: "New territory...",
  adminCentreTerritoryAddTerriName: "Territory name",
  adminCentreTerritoryDupName: (territory, province) =>
    `${territory} already exists in ${province}.`,
  adminCentreTerritoryAddTerriTimezone: "Time zone of territory",
  adminCentreTerritoryAddTerriInitMuni: "Name of first municipality",
  adminCentreTerritoryAddTerriInitMuniFooter:
    "You can add other municipalities later in the list.",
  adminCentreTerritoryAddMunicipality: "Add a municipality",
  adminCentreTerritoryAddMunicipalityName: "Name of municipality",
  adminCentreTerritoryRenameMunicipality: "Rename municipality",
  adminCentreTerritoryRemoveMunicipality: "Remove municipality",
  adminCentreTerritoryRemoveMunicipalityConfirm: (municipality, territoriy) =>
    `Are you sure you want to remove ${municipality} from ${territoriy}?`,
  adminCentreTerritoryRemoveMunicipalityInUse: municipality =>
    `${municipality} is being used by some users in the system therefore cannot be removed. Please rename ${municipality}, or pick a replacement municipality.`,
  adminCentreTerritoryReplaceMunicipality: "Replace municipality",
  adminCentreTerritoryReplaceMunicipalityPick: "Pick a replacement",
  adminCentreTerritoryMunicipalitiesOf: territory =>
    `Municipalities of ${territory}`,
  adminCentreTerritoryMunicipalityRename: "Rename",
  adminCentreTerritoryMunicipalityRemove: "Remove",
  adminCentrePromo: "Promo",
  adminCentrePromoList: "Promo List",
  adminBookingFor: name => `Booking interview for ${name}`,
  adminBookingSuccess:
    "Interview booked successfully.\nPlease close this tab and refresh Admin Centre",
  adminLoadMore: "Load more",
  adminLoadAll: "Load all",
  adminResendInfoRequestSent:
    "The information request link email has been sent to the candidate.",
  adminResendProfileCompleteSent:
    "The profile completion link email has been sent to the candidate.",
  adminResendQuestionnaireSent:
    "The questionnaire email has been sent to the candidate.",
  adminCentrePayrollOption: "Change payroll option",
  adminCentreUseExternalPayrollProcessing: "Use external payroll processing",
  adminCaregivers: "Caregivers",
  adminServiceProviders: "Service Providers",
  adminCareCircles: "Care Circles",
  adminHouseholds: "Households",
  adminCentreServiceType: "Service type",
  adminGainLossAccess: hasMember =>
    `${hasMember ? "Lose" : "Gain"} access to group`,
  adminConfirmGainLossAccess: (hasMember, groupName, roleName) =>
    hasMember
      ? `Remove your access to "${groupName}"? Your current role in the group is "${roleName}"`
      : `Add yourself as a member of "${groupName}"? This will give you access to the group.`,
  adminCentreGainLossAccessOwnGroup:
    "Cannot gain / lose access to self-owned group",
  adminManageGroup: "Manage group",
  adminCentreTriggerRnR: "Trigger R&R invitation",
  adminCentreTriggerRnRCareCircle:
    "Trigger review for the following Care Circle members:",
  adminCentreTriggerRnRReviewerGroup:
    "Send review invitation to the following Care Circle(s) or Household(s):",
  adminCentreTriggerRnRRoleNameMap: {
    "Paid Caregiver": "Paid Caregiver(s)—has shift(s)",
    Cleaner: "Paid Cleaner(s)—has shift(s)",
    Handyman: "Service Provider - Handyman",
    Cleaning: "Service Provider - Cleaning",
    Transportation: "Service Provider - Transportation"
  },
  adminCentreTriggerRnRNoAvailableMember:
    "No Caregiver(s) or Service Provider(s) available for R&R.",
  adminCentreTriggerRnRNoAvailableGroup: isProvider =>
    `No available Care Circle or Household to review this ${
      isProvider ? "Caregiver" : "Service Provider"
    }.`,
  adminCentreTriggerRnRSuccess: isMultiple =>
    `R&R invitation${isMultiple ? "s" : ""} sent successfully!`,
  adminCentreViewRatingReview: "View Review & Ratings",
  adminCentreRatingReviewGivenBy: (name, groupTypeName) =>
    `Reviews & Ratings from ${groupTypeName} ${name}`,
  adminCentreRatingReviewOf: (name, groupTypeName) =>
    `Reviews & Ratings given to ${groupTypeName} ${name}`,
  adminCentreRatingReviewTargetMember:
    "Set admin rating, add testimonials, or view R&Rs given to Caregiver/Service Provider:",
  adminCentreRatingReviewSelectTargetMember: "Select a member",
  adminCentreSetAdminRating: "Set admin rating",
  adminCentreAddTestimonials: "Testimonials",
  adminCentreNoRatingReviews: "No R&Rs to show at this time.",
  adminCentreNoTestimonials: "No testimonials to show at this time.",
  adminCentreTestimonialReviewerPlaceholder: "Testimonial name",
  adminCentreNewTestimonialPlaceholder: "Enter testimonial review here",
  adminCentreRnRRequestReviewerGroupName: (groupName, isDisabled) =>
    `${groupName}${isDisabled ? ` (No shift done)` : ""}`,

  inbox: "Message Inbox",
  inboxUnread: "Unread",
  inboxAll: "All",
  inboxNoUnread: "No new messages at this time.",
  inboxNoTopics: "No chats to show at this time.",
  inboxNoLastActivity: "No messages yet",
  inboxLastMessageAt: date => `Last message at: ${date}`,

  reportCompletedShift: "Completed Shifts Summary",
  reportCompletedShiftTimeSheets: "Completed Shifts (Timesheets)",
  reportCompletedShiftDisapprv: "Disapprove",
  reportCompletedShiftApprv: "Approve",
  reportCompletedShiftApprvGiven: isApproved =>
    isApproved ? "Approved" : "Disapproved",
  reportCompletedShiftConfirm: isApproved =>
    `${isApproved ? "Approve" : "Disapprove"} this report?`,
  reportCompletedShiftDateBlock: "Report period",
  reportNoTimesheetsAvailable: "No Timesheets avaliable for this Care Circle",
  reportCompletedShiftSelectDateBlock: "Please select a report period...",
  reportCompletedShiftNoReport: "No timesheet available.",
  reportCompletedShiftAvailReports: "Available Timesheets",
  reportCompletedShiftHelpDisapprv:
    "If you disapprove a timesheet, you or the Paid Provider will have 5 days to correct the clocked time on the Summary tab of a Shift. Corrected hours will update the timesheet, which you can then approve here.",
  reportCurrentPeriodOngoing: "Current reporting period still in progress",

  caregiverApplicationWelcomeMessage:
    "Welcome! Please fill out the fields below.",
  caregiverApplicationBookAppointment:
    "Thank you, please book an interview appointment with us.\nWhen you finish, please note the date and time of the interview in your calendar, close the window and we will call you at the chosen date and time for the interview.",
  caregiverApplicationNewFlowSuccessMessage:
    "Thank you. You application has been submitted. Please check your email for the next steps.",
  caregiverReference: "Reference",
  caregiverInfoReqFormInvalidLink:
    "Your information submission link is invalid or expired!",
  caregiverInfoReqSuccess:
    "Thank you for your information, we will get in touch with you shortly.",
  caregiverAddReference:
    "Please provide your reference names and contact information",
  caregiverAddReferenceItem: "Additional reference",
  caregiverReferenceContactName: "Contact name",
  caregiverReferenceContactInfo: "Contact information",
  caregiverReferenceContactInfoPlaceholder: "Email, phone, etc",
  caregiverCompleteProfileLogin: "First time sign in",
  caregiverCompleteProfileLoginFailure:
    "Sorry, your link is either invalid or expired. Please contact us to resolve this issue.",
  caregiverCompleteProfile: groupTypeName =>
    `Complete your ${groupTypeName} profile`,
  caregiverJobReferenceInvalidLink: "Invalid contact response link.",
  caregiverJobReferenceHeading: name => `Hello ${name},`,
  caregiverJobReferenceSubHeading: candidateName =>
    `${candidateName} has listed you as a job reference and we were hoping to ask you just a few short questions. Your input is very much appreciated. Thank you!`,
  caregiverJobReferenceQuestions: [
    profileData => `1. How do you know ${getDisplayNameEng(profileData)}?`,
    profileData =>
      `2. How would you describe ${getDisplayNameEng(
        profileData
      )}'s work performance?`,
    profileData =>
      `3. What do you think are ${
        (profileData || {}).gender === "male" ? "his" : "her"
      } best qualities?`,
    profileData =>
      `4. What, if anything, do you feel ${
        (profileData || {}).gender === "male" ? "he" : "she"
      } could work on improving?`
  ],
  caregiverJobReferenceFieldPlaceholder: "Please enter your response:",
  caregiverJobReferenceSelectPlaceholder: "Please select your response:",
  caregiverJobReferenceSuccess:
    "Thank you for your valuable information! You may close this window now.",
  caregiverJobReferenceFrom: name => `${name}'s Response`,

  caregiverUploadInvalidLink:
    "Sorry, your file upload link is invalid or expired",
  caregiverUploadHeading: displayName =>
    `${displayName}, thank you for your application!`,
  caregiverUploadSubHeading:
    " Please upload a copy of your recent criminal background check, issued within the last year.",
  caregiverUploadFormats:
    "Supported file formats: PDF, DOC, DOCX, JPG, JPEG, PNG",
  caregiverUploadDropOrClick: "Click to upload, or drop files here",
  caregiverUploadSuccess:
    "Thank you for uploading your information, we will get in touch with you shortly.",
  caregiverProfileSaveReminder: (hasAvatar, hasAvailability) =>
    `You have saved your profile without ${
      hasAvatar ? "" : "a profile picture"
    }${!hasAvatar && !hasAvailability ? " and " : ""}${
      hasAvailability ? "" : "your availability"
    }.${
      hasAvatar
        ? ""
        : !hasAvailability
        ? " Please add a profile picture on your profile page."
        : " Please add a profile picture on this page."
    }${
      hasAvailability
        ? ""
        : " A page will be opened here where you can choose your time and neighborhood availability so that we can match you with the right clients."
    }`,

  ratingReview: "Review and Rating",
  ratingReviews: "Reviews and Ratings",
  ratingReviewRating: "Rating",
  ratingReviewReviews: "Reviews",
  ratingReviewOverallRating: "Overall rating",
  ratingReviewInvalidLink: "Sorry, your R&R link is invalid or expired",
  ratingReviewHeading: (target, reviewer) =>
    `Please provide Review and Rating about ${target}'s recent services for ${reviewer}.`,
  ratingReviewSubmittedHeading: (author, target, reviewer) =>
    `${author}'s Review and Rating about ${target}'s recent services for ${reviewer}.`,
  ratingReviewStarRating: "Service rating",
  ratingReviewComments: "Review comments",
  ratingReviewOpenReviews: "Show list of reviews",
  ratingReviewCommentsPlaceholder:
    "Please enter comments here about their services:",
  ratingReviewRatingDescriptions: [
    "Poor",
    "Fair",
    "Good",
    "Very Good",
    "Excellent"
  ],
  ratingReviewSubmitSuccess: "Thank you for submitting your review and rating!",
  ratingReviewSubmissionDate: "Submitted on:",
  ratingReviewHistoricalRatings: "Previous Reviews and Ratings",
  ratingReviewNoComment: "(no comment)",
  ratingReviewPrivateReview: "Private feedback",
  ratingReviewLeavePrivateReview: "Leave a private feedback",
  ratingReviewPrivateReviewPlaceholder: isCareCircle =>
    `Private feedback is only visible to myCareBase staff, and will not be posted on ${
      isCareCircle ? "Caregiver" : "Service Provider"
    } Marketplace, nor will it be seen by the ${
      isCareCircle ? "Caregiver" : "Service Provider"
    }.`,
  ratingReviewIsPublished: "Published",
  testimonial: "Testimonial",
  testimonials: "Testimonials",

  appointment: "Appointment",
  appointments: "Appointments",

  careCircle: "Care Circle",

  cleaner: "Cleaner",

  household: "Household",

  fatalError:
    "We are sorry, but the app has encountered an unrecoverable error. The page will refresh now.",
  copyFatalError: "Copy error info",

  tabsMore: "More"
};

export default general;
