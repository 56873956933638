import React from "react";
import { ShiftCalendarView } from "./view";
import { ShiftCalendarController } from "./controller";
import { styles } from "./styles";

export class ShiftCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.controller = new ShiftCalendarController(props);
  }

  render() {
    return (
      <ShiftCalendarView
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
