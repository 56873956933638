import React from "react";
import { ActivityIndicator, Image, ScrollView, View } from "react-native";
import { observer } from "mobx-react";
import { theme } from "../../../config/style-configs/theme";
import { Divider, Text } from "react-native-elements";
import { UIText } from "../../../config/lang-config";
import { isEmpty } from "../../../utils/helpers";

@observer
class InvitationRegisterScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  render() {
    return this.controller.loading ? (
      <View style={this.props.styles.loading}>
        <ActivityIndicator size="large" color={theme.color} />
      </View>
    ) : (
      <View style={this.props.styles.style}>
        <View style={this.props.styles.header}>
          <Image
            source={theme.loginLogo}
            style={this.props.styles.headerLogo}
          />
        </View>
        {this.controller.invitationAccepting &&
        !isEmpty(this.controller.invitationData) ? (
          <ScrollView
            style={this.props.styles.scrollView}
            contentContainerStyle={{
              ...this.props.styles.scrollView,
              justifyContent: "flex-start"
            }}
          >
            <View style={this.props.styles.container}>
              <View style={this.props.styles.questions}>
                <Text style={this.props.extraStyles.questionHeader}>
                  {UIText.invitationIntroduction(
                    this.controller.invitationData["first_name"],
                    this.controller.invitationData["email"],
                    this.controller.invitationData["invitor_first_name"]
                  )}
                </Text>
              </View>

              <View style={this.props.styles.questions}>
                <Text style={this.props.extraStyles.questionHeader}>
                  {UIText.invitationToAccept}
                </Text>
              </View>
            </View>

            <View style={this.props.styles.links}>
              <Text
                style={this.props.styles.link}
                onPress={e => {
                  this.controller.handleTakeoverPress(e);
                }}
              >
                {UIText.registrationKnowAccount}
              </Text>
            </View>

            <View style={this.props.styles.regTypeDividerContainer}>
              <Divider style={this.props.styles.regTypeDivider} />
              <Text style={this.props.styles.regTypeDividerText}>
                {UIText.invitationRegDivider}
              </Text>
              <Divider style={this.props.styles.regTypeDivider} />
            </View>

            <View style={this.props.styles.links}>
              <Text
                style={this.props.styles.link}
                onPress={e => {
                  this.controller.handleRegistrationPress(e);
                }}
              >
                {UIText.loginLinkRegister}
              </Text>
            </View>

            <View style={this.props.styles.links}>
              <Text
                style={this.props.styles.link}
                onPress={e => {
                  this.controller.handleCancelPress(e);
                }}
              >
                {UIText.invitationCancel}
              </Text>
            </View>
          </ScrollView>
        ) : null}
      </View>
    );
  }
}

export { InvitationRegisterScreen };
