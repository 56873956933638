import { theme } from "../../config/style-configs/theme";

export const styles = {
  wrapper: {
    marginVertical: 10,
    flexDirection: "row",
    alignItems: "center"
  },
  titleText: {
    marginHorizontal: 5,
    fontSize: theme.FONT_SIZE_MEDIUM,
    color: theme.color
  }
};
