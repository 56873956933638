import React from "react";
import { View } from "react-native";
import { Button, Icon, Text } from "react-native-elements";
import { observer, Observer } from "mobx-react";
import { ThemedListItem } from "../ThemedListItem";
import { memberStatus, profilePrototype } from "../../config/variable-config";
import { clientController } from "../../cdm/controllers/client-controller";
import { UIText } from "../../config/lang-config";
import Collapsible from "react-native-collapsible";
import { theme } from "../../config/style-configs/theme";
import { evalStringBoolean, getDisplayNameEng } from "../../utils/helpers";
import { CollapsibleAccordionTitle } from "../CollapsibleAccordionTitle";
import { GroupMemberMenu } from "../../custom/mcb/components/GroupMemberMenu";
import { TouchableOpacityIOSFix } from "../TouchableOpacityIOSFix";
import { fileService } from "../../cdm/services/file-service";

@observer
class GroupRoleItemView extends React.Component {
  isOwner = member => this.props.groupOwner === member.userId;

  onTitlePress = () => this.props.onTitlePress(this.props.role.id);

  getListItems = list =>
    list.map(member => {
      const profile =
        (member.profile && member.profile.data) || profilePrototype;

      const noUser = !member.userId;
      const pending = Number(member.status) === memberStatus.invited;
      const self = member.userId === clientController.userId;
      const noChat = self || pending || noUser;
      const designatedMember = member.profileId === this.props.groupProfile.id;

      const memberTitle = getDisplayNameEng(profile);

      const rightTitle = self
        ? UIText.memberSelf
        : pending
        ? UIText.memberPending
        : "";

      const email =
        profile.email ||
        (profile.phone && profile.phone.toString()) ||
        member.email ||
        "";

      return (
        <Observer key={member.id}>
          {() => (
            <TouchableOpacityIOSFix
              onPress={e => {
                this.props.onPress && this.props.onPress(member);
              }}
            >
              <ThemedListItem
                containerStyle={{
                  ...this.props.styles.listItem,
                  ...(this.props.showMenu && {
                    paddingRight: 0
                  })
                }}
                leftAvatar={fileService.getProfileAvatarUri(
                  profile.avatar,
                  member.id,
                  "member"
                )}
                title={memberTitle}
                titleLabel={this.isOwner(member) && UIText.groupOwnerLabel}
                titleId={member.profileId}
                subtitle={evalStringBoolean(email) ? email : ""}
                rightElement={this.getButtons(
                  member,
                  rightTitle,
                  designatedMember
                )}
                rightTitle={rightTitle}
                rightContentContainerStyle={
                  this.props.styles.rightContentContainer
                }
              />
            </TouchableOpacityIOSFix>
          )}
        </Observer>
      );
    });

  getButtons = (member, rightTitle, designatedMember) => {
    const chatLoading =
      this.props.chatLoading || this.props.chatLoadingList[member.id];
    const topic = this.props.getChatTopic(member.id);
    return (
      <Observer>
        {() => (
          <View style={this.props.styles.buttonContainer}>
            {rightTitle ? (
              <View style={this.props.styles.rightTitleContainer}>
                <Text
                  style={this.props.styles.rightTitle}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {rightTitle}
                </Text>
              </View>
            ) : (
              !designatedMember && (
                <Button
                  containerStyle={this.props.styles.button}
                  type="clear"
                  icon={
                    <Icon
                      containerStyle={{ marginTop: 2 }}
                      name={
                        topic["lastPublicMessageTime"] ? "chat" : "chat-bubble"
                      }
                      size={this.props.styles.buttonSize}
                      color="#666"
                    />
                  }
                  loading={chatLoading}
                  loadingProps={{ color: theme.color }}
                  onPress={() =>
                    !chatLoading &&
                    this.props.onChatPress &&
                    this.props.onChatPress(member.id)
                  }
                />
              )
            )}
            {this.props.showMenu && (
              <GroupMemberMenu
                IAmOwner={this.props.IAmOwner}
                member={member}
                selfMember={this.props.selfMember}
                onMemberMenuSelect={this.props.onMemberMenuSelect}
              />
            )}
          </View>
        )}
      </Observer>
    );
  };

  render() {
    const { role } = this.props;
    return role.members
      ? role.members.length > 0 && (
          <View style={this.props.styles.style}>
            <CollapsibleAccordionTitle
              onTitlePress={this.onTitlePress}
              title={this.props.role.description}
              collapsed={this.props.collapsed}
            />
            <Collapsible collapsed={this.props.collapsed}>
              {this.getListItems(role.members)}
            </Collapsible>
          </View>
        )
      : null;
  }
}

export { GroupRoleItemView };
