import { stateController } from "../../../cdm/controllers/state-controller";
import { action, autorun, computed, observable, reaction, toJS } from "mobx";
import { Platform } from "react-native";
import {
  capitalize,
  contextReject,
  getBrowserTimeZoneName,
  isEmpty,
  isModified,
  preventDefaultStopProp,
  safeParseJSON
} from "../../../utils/helpers";
import { TopicServiceController } from "../../../cdm/controllers/topic-controller";
import { UIText } from "../../../config/lang-config";
import { formService } from "../../../cdm/services/form-service";
import { clientController } from "../../../cdm/controllers/client-controller";
import { apiService } from "../../../cdm/services/api-service";
import { endpointConfig } from "../../../config/api-config";
import {
  getTopicDetailButtonFlags,
  getTopicDetailEditFlags,
  getTopicDetailRenderFlags,
  getTopicSummaryEditFlags,
  getTopicSummaryRenderFlags
} from "../../../custom/mcb/config/flags_calculator";
import {
  serviceRecipientGroupTypeIds,
  topicTypeIds
} from "../../../config/variable-config";
import { ShiftTaskController } from "../../../custom/mcb/lib/shift-task-controller";
import {
  deleteTopic,
  editTopicAssignee,
  editTopicCorrectedEndTime,
  editTopicDescription,
  editTopicMenu,
  editTopicStartEndTime,
  getTopicCompletionState,
  isSystemUIHiddenTopic,
  topicSummaryForm,
  updateTopicActorMembers
} from "../../../cdm/lib/topic-utilities";
import { apiController } from "../../../cdm/controllers/api-controller";
import { filterController } from "../../../cdm/controllers/filter-controller";
import {
  isCcSubscriptionTopic,
  paidCaregiverFeatureRestricted
} from "../../../custom/mcb/lib/group-utilities-mcb";
import { shiftClockInOutForm } from "../../../custom/mcb/config/shift_clock_in_out_form";
import { mcbEndpointConfig } from "../../../custom/mcb/config/api-config";
import { computedFn } from "mobx-utils";
import { msgService } from "../../../cdm/services/messaging-service";

export class TopicController {
  @observable loaded = false;
  @observable detailLoading = true;
  @observable editing = false;

  @observable summarySaving = false;
  @observable completionSaving = false;
  @observable detailSaving = false;

  @observable topicController = {};
  @observable topicDetail = [];
  topicDetailCopy = [];

  @observable clockInOutForm;

  execShiftSilentlyClockIn;
  @observable shiftSilentlyClockingIn = false;

  @computed get loading() {
    return isEmpty(this.topic) || this.topicController.topicLoading;
  }
  @computed get saving() {
    return this.summarySaving || this.completionSaving || this.detailSaving;
  }
  @computed get messageLoading() {
    return this.topicController.messageLoading || msgService.syncingUnreadData;
  }
  @computed get userId() {
    return this.topicController.userId;
  }
  @computed get isVisitor() {
    return clientController.isVisitor;
  }
  @computed get topicId() {
    if (!stateController.viewTopicId)
      stateController.viewTopicId = Number(
        this.props.navigation.getParam("topic")
      );

    return stateController.viewTopicId;
  }
  @computed get topic() {
    return this.topicController.topic || {};
  }
  @computed get currentGroup() {
    return this.topicController.currentGroup || {};
  }
  @computed get groupChatLobby() {
    return this.topicController.groupChatLobby || {};
  }

  @computed get selfMember() {
    return this.topicController.selfMember || {};
  }
  @computed get selfRoles() {
    return this.topicController.selfRoles || [];
  }
  @computed get selfActors() {
    return this.topicController.selfActors || [];
  }
  // @computed get ownerActor() {
  //   return this.topicController.ownerActor;
  // }
  // @computed get assignedActor() {
  //   return this.topicController.assignedActor;
  // }
  @computed get members() {
    return this.topic.members || [];
  }
  @computed get unreadCount() {
    return this.topicController.unreadCount || 0;
  }
  @computed get messagingEnabled() {
    return !this.topicType.isMessagingDisabled;
  }
  @computed get subTopics() {
    return this.topicController["subTopics"] || [];
  }
  @computed get filteredSubTopics() {
    let topics = this.subTopics;
    return filterController.applyFilterCategories(
      topics,
      this.filterCategories
    );
  }
  @computed get topicTypes() {
    return this.topicController.topicTypes || [];
  }
  @computed get topicType() {
    return this.topicController.topicType || {};
  }
  @computed get topicTypeName() {
    return this.topic.topicTypeName || this.topicController.topicTypeName || "";
  }
  @computed get summaryFlags() {
    return getTopicSummaryRenderFlags(this.topic) || [];
  }
  @computed get summaryEditFlags() {
    let flags = [];
    for (let actor of this.selfActors) {
      for (let role of this.selfRoles) {
        flags.push(
          ...getTopicSummaryEditFlags(
            this.topic,
            actor.groupTypeActorId,
            role.groupTypeRoleId,
            this.currentGroup
          )
        );
      }
    }
    return flags.filter(Boolean);
  }
  @computed get topicDetailRenderFlags() {
    let flags = [],
      explicit;
    const detectExplicit = f => {
      if (f[f.length - 1] === "*") {
        explicit = true;
        return f.slice(0, -1);
      }
      return f;
    };
    for (let actor of this.selfActors) {
      let flag = getTopicDetailRenderFlags(
        this.topic,
        actor.groupTypeActorId,
        this.currentGroup,
        this.selfMember,
        clientController.client.user
      );
      flag = flag.map(detectExplicit);
      if (explicit) {
        flags = [];
        flags.push(...flag);
        break;
      } else {
        flags.push(...flag);
      } // TODO: Need more fine-grained explicit flag control.
    }
    return flags.filter(Boolean);
  }
  @computed get topicDetailEditFlags() {
    let flags = [];
    for (let actor of this.selfActors) {
      for (let role of this.selfRoles) {
        flags.push(
          ...getTopicDetailEditFlags(
            this.topic,
            actor.groupTypeActorId,
            role.groupTypeRoleId,
            this.currentGroup
          )
        );
      }
    }
    return flags.filter(Boolean);
  }
  @computed get topicDetailButtonFlags() {
    let flags = [];
    for (let actor of this.selfActors) {
      for (let role of this.selfRoles) {
        flags.push(
          ...getTopicDetailButtonFlags(
            this.topic,
            actor.groupTypeActorId,
            role.groupTypeRoleId,
            this.currentGroup
          )
        );
      }
    }
    for (let flag of flags) {
      const conflict = flags.find(f => f.type === flag.conflict);
      if (conflict) {
        flags = flags.filter(f => f.type !== conflict.type);
      }
    }
    return flags.filter(Boolean);
  }
  @computed get summaryForm() {
    if (this.summaryFlags.length > 0) {
      return topicSummaryForm(this.topic, this.timezone).filter(f =>
        this.summaryFlags.find(flag => f.flags && !!f.flags[flag])
      );
    }
    return topicSummaryForm(this.topic, this.timezone).filter(
      f => !!f.flags.general
    );
  }
  @computed get topicData() {
    return safeParseJSON(this.topic.data, true) || {};
  }
  @computed get topicDetailData() {
    return formService.disassembleFormData(this.topicDetail);
  }
  @computed get dataLocked() {
    return this.topic.isDataLocked;
  }
  @computed get completed() {
    return this.topic.isCompleted;
  }

  @computed get currentTabKey() {
    const key = stateController.viewTopicState.topTabs[this.topic.id];
    if (!key)
      stateController.viewTopicState.topTabs[this.topic.id] = this.isShiftTopic
        ? this.subTopics.length > 0
          ? "task"
          : "detail"
        : "detail";
    return stateController.viewTopicState.topTabs[this.topic.id];
  }

  @computed get customTabs() {
    return stateController.viewTopicState.customTabs || [];
  }
  @computed get topTabs() {
    let tabs = [this.summaryTab, this.detailTab, ...this.customTabs];
    const types = this.topicTypes.filter(tp =>
      this.subTopics.find(t => t.typeId === tp.id)
    );
    // tabs.push(
    //   ...types.map(tp => ({
    //     // key: tp.id,
    //     key: tp.description.toLowerCase(),
    //     name: `${tp.description}s`,
    //     onTabPress: this.handleTabChange
    //   }))
    // );
    const typeTabs = types.map(tp => ({
      // key: tp.id,
      key: tp.description.toLowerCase(),
      name: `${tp.description}s`,
      onTabPress: this.handleTabChange
    }));
    if (this.isShiftTopic) {
      tabs.splice(1, 0, ...typeTabs);
      const taskTab = tabs.find(tab => tab.key === "task");
      const detailTab = tabs.find(tab => tab.key === "detail");
      if (taskTab && this.topic.execStartTime && !this.topic.execEndTime) {
        taskTab.help = UIText.shiftTaskHelp;
      }
      detailTab.help = UIText.shiftDetailHelp;
    } else {
      tabs.push(...typeTabs);
    }
    return tabs.filter(Boolean);
  }

  @computed get isCompletedTopic4Caregiver() {
    return (
      !paidCaregiverFeatureRestricted(this.currentGroup, this.selfMember) ||
      (this.dataLocked && this.completed)
    );
  }

  @computed get isShiftTopic() {
    return this.topic && this.topic.typeId === topicTypeIds.shift;
  }
  @computed get isShiftTopicSubmitDisabled() {
    if (!this.isShiftTopic) return false;
    const clockFormData = formService.disassembleFormData(this.clockInOutForm);
    return !clockFormData["execStartTime"] || !clockFormData["execEndTime"];
  }
  @computed get isShiftAssignedToSelf() {
    return this.topicDetailEditFlags.includes("execution");
  }
  @computed get isShiftClockFormEditable() {
    return (
      this.isShiftAssignedToSelf &&
      getTopicCompletionState(this.topic) !== "clocked-out"
    );
  }

  @computed get timezone() {
    if (!serviceRecipientGroupTypeIds.includes(this.currentGroup.typeId))
      return getBrowserTimeZoneName();
    return (
      ((this.currentGroup.profile || {}).data || {}).timezone ||
      getBrowserTimeZoneName()
    );
  }

  get summaryTab() {
    return (
      this.isCompletedTopic4Caregiver && {
        key: "summary",
        name: capitalize(UIText.summary),
        onTabPress: this.handleTabChange
      }
    );
  }
  get detailTab() {
    return {
      key: "detail",
      name: this.isShiftTopic
        ? UIText.shiftCompletion
        : capitalize(UIText.details),
      onTabPress: this.handleTabChange
    };
  }

  @computed get additionalFormProcessors() {
    return stateController.viewTopicState.additionalFormProcessors || [];
  }

  // Filter functions
  @observable filterCategories = [
    {
      name: "Completion",
      classifier: t =>
        t.isCompleted ? UIText.topicCompleted : UIText.topicNotCompleted,
      alwaysShownCriteria: [UIText.topicCompleted, UIText.topicNotCompleted]
    }
  ];
  setFilter = filterCategories =>
    filterController.filterSetter(this.filterCategories, filterCategories);

  constructor(props) {
    this.props = props;
    setTimeout(this._initialize);
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.topicController.cancel && this.topicController.cancel();
    this.cancelled = true;
    Array.isArray(this.disposers) &&
      this.disposers.map(disposer => disposer && disposer());
    clearTimeout(this.emptyTimeout);
    stateController.clearTopicState();
  }

  _showError = (err, kickout) => {
    console.warn(err);
    return stateController.showPopup({
      title: capitalize(UIText.group),
      content: (err && err.message) || err,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress:
        kickout &&
        (() => {
          this.props.navigation.navigate("Group");
          return stateController.dismissPopup();
        }),
      contentAlign: err.contentAlign
    });
  };

  _notSaving = () => {
    this.detailSaving = this.summarySaving = this.completionSaving = false;
  };

  _initialize = () => {
    this.previousScreen = toJS(stateController.currentScreen);
    stateController.currentScreen = "Topic";

    if (this.isVisitor) return this.props.navigation.navigate("Root");

    this.loaded = false;
    this.topicDetail = [];
    this.topicDetailCopy = [];
    this.editing = false;
    this._notSaving();

    this.topicController = new TopicServiceController(this.topicId);
    return this.topicController
      .initialize()
      .then(() => !this.cancelled && this._loadDetail())
      .then(this.fillShiftClockForm)
      .then(() => {
        if (this.cancelled) return;

        if (isSystemUIHiddenTopic(this.topic)) {
          return this.handleBackPress();
        }

        this.disposers = [
          // autorun(reaction => {
          //   if (isEmpty(this.topic) && this.loaded) {
          //     this.emptyTimeout = setTimeout(() => {
          //       if (isEmpty(this.topic) &&
          //         clientController.loginState &&
          //         stateController.currentScreen === "Topic"
          //       ) {
          //         this._showError(`${UIText.pageNotExist}[autorun]`, true);
          //         return reaction.dispose();
          //       }
          //     }, 1000);
          //   }
          // }),
          autorun(() => {
            if (this.topicDetailButtonFlags.find(tb => tb.type === "submit")) {
              this.editing = true;
            }
          }),
          reaction(
            () => [
              this.topic.execStartTime,
              this.topic.execEndTime,
              this.topic.correctedEndTime
            ],
            () =>
              this.isShiftTopic &&
              !this.isShiftClockFormEditable &&
              this.fillShiftClockForm()
          )
        ];
      })
      .catch(err => this._showError(err, true))
      .finally(() => {
        if (this.cancelled) return;
        this.detailLoading = false;
        this.loaded = true;
        this.shiftController = new ShiftTaskController();
      });
  };

  _loadDetail = async () => {
    const loadDetail = async form => {
      this.topicDetail = formService.assembleFormData(form, this.topicData, {
        override: true
      });

      /* Filter out candidate score if not admin user */
      // if (!isSnSOrAdmin(clientController.client.user)) {
      //   this.topicDetail = this.topicDetail.filter(
      //     f =>
      //       f.flags &&
      //       !f.name.match(/careCircle/g) &&
      //       f.name !== "score" &&
      //       f.name !== "rank"
      //   );
      // }
      /* Technically we don't need above part anymore due to render flags restriction. */

      return this.execAdditionalProcessors();
    };

    this.detailLoading = true;
    const typeClassId = this.topic.typeClassId;
    return formService.findFormClassByIdAsync(typeClassId).then(loadDetail);
  };

  execAdditionalProcessors = async () => {
    for (const method of this.additionalFormProcessors) {
      method(this.topicDetail);
    }
  };

  handleTabChange = (tab, event) => {
    return (stateController.viewTopicState.topTabs[this.topic.id] = tab.key);
  };

  handleTopicItemPress = topic => {
    const id = topic.id;

    if (Platform.OS === "web") {
      stateController.viewTopicState[id] = {
        parent: this.topicId
      };
      stateController.viewTopicId = id;
      this.props.navigation.navigate("Topic", { topic: id });
      this._initialize();
    } else {
      this.props.navigation.push("Topic", { topic: id });
    }
  };

  handleBackPress = event => {
    if (Platform.OS === "web") {
      const stack = stateController.viewTopicState[this.topicId];
      const { parent } = stack || {};
      const parentId = parent || this.topic.parentId;
      if (
        parentId &&
        this.previousScreen !== "Group" &&
        // this.previousScreen !== "Inbox" &&
        !isSystemUIHiddenTopic(this.topic)
      ) {
        stateController.viewTopicId = parentId;
        this.props.navigation.navigate("Topic", { topic: parentId });
        this._initialize();
        // } else if (
        //   this.previousScreen === "Inbox"
        // ) {
        //   this.props.navigation.navigate("Inbox");
      } else {
        if (!stateController.viewGroupId)
          stateController.viewGroupId = this.currentGroup.id;
        this.props.navigation.navigate("Group", {
          group: stateController.viewGroupId
        });
      }
    } else {
      this.props.navigation.pop();
    }
  };

  isShiftTaskEditing = () =>
    this.isShiftTopic ||
    (this.topic.typeId === topicTypeIds.task && !this.topic.isTemplate)
      ? this.shiftController.shiftInstanceEditPopup()
      : (async () => true)();

  editTopic = event => {
    preventDefaultStopProp(event);

    if (this.loading || this.saving) return;

    return editTopicMenu(this.topic, this.summaryEditFlags, {
      editTopicName: this.editTopicName,
      editTopicAssignee: this.editTopicAssignee,
      editTopicStartEndTime: this.editTopicStartEndTime,
      editTopicCorrectedEndTime: this.editTopicCorrectedEndTime,
      deleteTopic: this.handleTopicDelete
    });
  };

  editTopicName = async () => {
    this.summarySaving = true;
    return this.isShiftTaskEditing()
      .then(confirm => confirm && editTopicDescription(this.topic))
      .then(update => update && this.topicController.loadTopic())
      .catch(this._showError)
      .finally(() => (this.summarySaving = false));
  };

  editTopicAssignee = async () => {
    this.summarySaving = true;
    return this.isShiftTaskEditing()
      .then(
        confirm =>
          confirm &&
          editTopicAssignee(this.currentGroup, this.topic, "assigned")
      )
      .then(update => update && this.topicController.loadTopic())
      .catch(this._showError)
      .finally(() => (this.summarySaving = false));
  };

  editTopicStartEndTime = async () => {
    this.summarySaving = true;
    return this.isShiftTaskEditing()
      .then(
        confirm =>
          confirm &&
          editTopicStartEndTime(
            this.topic,
            {
              noStart: this.topic.typeId === topicTypeIds.issue
            },
            null,
            null,
            this.timezone
          )
      )
      .then(update => update && this.topicController.loadTopic())
      .catch(this._showError)
      .finally(() => (this.summarySaving = false));
  };

  editTopicCorrectedEndTime = async () => {
    this.summarySaving = true;
    return editTopicCorrectedEndTime(this.topic, null, null, this.timezone)
      .then(update => update && this.topicController.loadTopic())
      .catch(this._showError)
      .finally(() => (this.summarySaving = false));
  };

  handleTopicDelete = async () => {
    this.summarySaving = true;
    return deleteTopic(this.topic)
      .then(update => {
        if (update && isEmpty(this.topic)) return this.handleBackPress();
      })
      .catch(this._showError)
      .finally(() => (this.summarySaving = false));
  };

  openChat = () => {
    if (isCcSubscriptionTopic(this.currentGroup, this.topic)) {
      if (!this.topicController.isSubscriptionMsgEnabled) {
        return stateController.showPopup({
          title: capitalize(UIText.marketplace),
          content: {
            text: UIText.marketSubNoChat
            // text: `${UIText.marketSubNoChat} ${UIText.marketPlanInfo}`,
            // link: planMarketingUrl
          },
          contentAlign: "left",
          leftButtonText: UIText.generalCancel,
          rightButtonText: UIText.planSelectAPlan,
          rightButtonPress: () =>
            stateController
              .dismissPopup()
              .then(this.topicController.handleBillingPress)
        });
      }
    }

    let topicId;
    if (this.topic.lastPublicMessageTime) {
      topicId = this.topicId;
    } else {
      stateController.lastViewTopicId = this.topicId;
      topicId = this.groupChatLobby.id;
    }

    stateController.viewTopicId = topicId;

    if (Platform.OS === "web") {
      this.props.navigation.navigate("Chat", { topic: topicId });
    } else {
      this.props.navigation.push("Chat", { topic: topicId });
    }
  };

  fieldEditable = computedFn(() => {
    if (
      !this.editing ||
      this.loading ||
      this.dataLocked ||
      this.shiftSilentlyClockingIn
    )
      return false;
    // return !!this.topicEditFlags.find(flag => field.flags && field.flags[flag]);
    return true;
  });

  fieldHasEditFlag = computedFn(
    field =>
      !!this.topicDetailEditFlags.find(
        flag => field.flags && !!field.flags[flag]
      )
  );

  editDetail = async () => {
    // Still loading! Slow down!!!
    if (this.topicDetail.length === 0) return;

    if (this.editing) {
      this.detailSaving = true;
      return this.saveDetail()
        .then(() => {
          this.editing = !this.editing;
          return true;
        })
        .then(this.topicController.loadTopic)
        .catch(this._showError)
        .finally(() => (this.detailSaving = false));
    } else {
      this.isShiftTaskEditing().then(confirm => {
        if (confirm) {
          this.topicDetailCopy = toJS(this.topicDetail);
          this.editing = true;
        }
      });
    }
  };

  cancelEdit = () => {
    this.editing = false;
    this.topicDetail = this.topicDetailCopy;
    return true;
  };

  commitDetail = async () => {
    // Still loading! Slow down!!!
    if (this.topicDetail.length === 0) return;

    this.detailSaving = true;
    return (
      this.saveDetail(true, { isCommit: 1 }) // override true, commit true
        // .then(
        //   success =>
        //     success &&
        //     apiService.async("PATCH", {
        //       endpoint: endpointConfig.topic_by_id(this.topic.id),
        //       data: { isDataLocked: 1 }
        //     })
        // )
        .then(this.topicController.loadTopic)
        .catch(this._showError)
        .finally(() => (this.detailSaving = false))
    );
  };

  saveDetail = async (override, options) => {
    const { isCommit } = options || {};
    const saveDetail = async modified => {
      if (!modified && !override) return;

      const topicDetail = JSON.stringify(this.topicDetailData);
      const data = {
        data: topicDetail,
        ...(!!isCommit && { isDataLocked: 1 })
      };

      return apiService
        .async("PATCH", {
          endpoint: endpointConfig.topic_by_id(this.topic.id),
          data
        })
        .then(() => true);
    };

    const validate = async data => {
      const pass = formService.validateRequired(data);
      if (!pass) {
        const errorFields = data.filter(f => !!f.errorMessage);
        return [false, errorFields];
      }
      return [data];
    };

    return validate(this.topicDetail)
      .then(([pass, errorFields]) => {
        if (!pass)
          return Promise.reject({
            message: UIText.checkEntriesWithFields(errorFields),
            contentAlign: "left"
          });
        return isModified(
          toJS(this.topicDetail),
          this.topicDetailCopy,
          "value"
        );
      })
      .then(saveDetail);
  };

  handleClockInClockOut = async (topic, forceAssignee) => {
    const shiftClockInOut = async () =>
      this.shiftController.handleClockInClockOut(
        topic,
        this.currentGroup,
        this.selfMember
      );

    this.completionSaving = true;
    topic.pending = true;

    const completionState = getTopicCompletionState(topic);
    if (completionState === "new") {
      if (topic.parentId) {
        // Topic is a task and shift is not clocked-in.
        const shiftTopic = clientController.findTopicById(topic.parentId);
        const getTask = () => clientController.findTopicById(topic.id);
        if (!isEmpty(shiftTopic)) {
          const waitTaskClockIn = () =>
            this.updateTopic(topic, true).then(() => {
              const topic = getTask();
              topic.pending = true;
              if (getTopicCompletionState(topic) !== "clocked-in")
                return waitTaskClockIn();
              return Promise.resolve();
            });
          this.shiftSilentlyClockIn();
          return waitTaskClockIn()
            .then(() => this.handleClockInClockOut(getTask()))
            .catch(this._showError)
            .finally(() => (this.completionSaving = topic.pending = false));
        }
        return stateController
          .showPopup({
            title: UIText.title,
            content: UIText.shiftMakerStartWithParent,
            leftButtonText: UIText.generalConfirm,
            dismissOnBackPress: true
          })
          .finally(() => (this.completionSaving = topic.pending = false));
      }

      if (forceAssignee) {
        await this.handleTakeoverAssignee(this.topic).catch(contextReject);
      }

      return await shiftClockInOut()
        .then(() => this.updateTopic(topic))
        .then(this.fillShiftClockForm)
        .catch(this._showError)
        .finally(() => (this.completionSaving = false));
    }
    if (completionState === "clocked-in") {
      if (topic.typeId === topicTypeIds.shift) {
        return shiftClockInOut()
          .then(() => this.updateTopic(topic))
          .then(this.fillShiftClockForm)
          .catch(this._showError)
          .finally(() => (this.completionSaving = false));
      }

      return apiService
        .async("PATCH", {
          endpoint: endpointConfig.topic_by_id(topic.id),
          data: {
            execEndTime: new Date().getTime(),
            isCompleted: 1
          }
        })
        .then(() => this.updateTopic(topic))
        .then(this.checkNotifyIssueCompletion)
        .catch(this._showError)
        .finally(() => {
          this.completionSaving = false;
          topic.pending = false;
        });
    }
  };

  handleShiftTopicSubmit = () => {
    const execStartTime = this.clockInOutForm.find(
      f => f.name === "execStartTime"
    );
    const execEndTime = this.clockInOutForm.find(f => f.name === "execEndTime");
    const { start, end } = ShiftTaskController.extractClockInOutFieldsValue(
      execStartTime,
      execEndTime,
      this.timezone
    );

    if (start > end) {
      return stateController.showPopup({
        title: UIText.shiftSubmitShift,
        content: UIText.shiftCalendarAddShiftClockedDateInverted,
        leftButtonText: UIText.generalConfirm,
        leftButtonPress: stateController.dismissPopup
      });
    }

    return stateController
      .showPopup({
        title: UIText.shiftSubmitShift,
        content: UIText.pleaseWait
      })
      .then(() => this.commitDetail())
      .then(async () => {
        const shiftClockInOut = time =>
          this.shiftController.handleClockInClockOut(
            this.topic,
            this.currentGroup,
            this.selfMember,
            time
          );

        const completionState = getTopicCompletionState(this.topic);

        if (completionState === "new") {
          await shiftClockInOut(start).catch(contextReject);
          while (getTopicCompletionState(this.topic) !== "clocked-in") {
            await this.updateTopic(this.topic, true).catch(console.warn);
          }
        } else if (
          completionState === "clocked-in" &&
          start !== this.topic.execStartTime
        ) {
          await apiService
            .async("PATCH", {
              endpoint: endpointConfig.topic_by_id(this.topic.id),
              data: {
                execStartTime: start
              }
            })
            .catch(contextReject);
        }

        return shiftClockInOut(end);
      })
      .then(this.topicController.loadTopic)
      .then(stateController.dismissPopup)
      .then(() => {
        stateController.viewGroupState.topTabs.main[this.currentGroup.id] =
          "shift";
        stateController.viewGroupId = this.currentGroup.id;
        return this.props.navigation.navigate("Group", {
          group: stateController.viewGroupId
        });
      })
      .catch(this._showError);
  };

  fillShiftClockForm = () =>
    this.isShiftTopic &&
    (this.clockInOutForm = shiftClockInOutForm(
      this.topic.startTime,
      this.topic.endTime,
      this.topicData.startTimeOverride,
      this.topicData.endTimeOverride,
      this.topic.execStartTime,
      this.topic.execEndTime,
      this.timezone
    ));

  lockUnlockShiftClockForm = isLock => {
    if (!this.clockInOutForm) return;
    this.clockInOutForm.forEach(field => {
      if (field.name === "timezone") return;
      field.disabled = isLock;
      if (isLock) {
        field._placeholder = field.placeholder;
        field.placeholder = UIText.shiftClockingInPleaseWait;
      } else {
        field.placeholder = field._placeholder;
        field._placeholder = undefined;
      }
    });
  };

  shiftSilentlyClockIn = () => {
    clearTimeout(this.execShiftSilentlyClockIn);
    this.execShiftSilentlyClockIn = setTimeout(() => {
      const done = () => (this.shiftSilentlyClockingIn = false);
      if (this.topic.isTemplate || this.topic.isParentTemplate) return done();
      if (!this.isShiftTopic && this.topic.typeId !== topicTypeIds.task)
        return done();
      if (getTopicCompletionState(this.topic) !== "new") return done();
      if (this.shiftSilentlyClockingIn) return;
      this.shiftSilentlyClockingIn = true;
      const topic = this.isShiftTopic
        ? this.topic
        : clientController.findTopicById(this.topic.parentId);
      if (isEmpty(topic)) return done();
      this.lockUnlockShiftClockForm(true);
      return this.shiftController
        .handleClockInClockOut(topic, this.currentGroup, this.selfMember)
        .then(() => this.updateTopic(this.topic, true))
        .finally(() => {
          done();
          this.lockUnlockShiftClockForm(false);
        });
    }, 200);
  };

  handleShiftClockFormChange = action(async (value, field) => {
    field.value = value;
    const data = { ...this.topicData };
    const execStartTime = this.clockInOutForm.find(
      f => f.name === "execStartTime"
    );
    const execEndTime = this.clockInOutForm.find(f => f.name === "execEndTime");
    const { start, end } = ShiftTaskController.extractClockInOutFieldsValue(
      execStartTime,
      execEndTime,
      this.timezone
    );
    if (start !== data.startTimeOverride) data.startTimeOverride = start;
    if (end !== data.endTimeOverride) data.endTimeOverride = end;

    await this.shiftSilentlyClockIn();

    return apiService.async("PATCH", {
      endpoint: endpointConfig.topic_by_id(this.topicId),
      data: { data: JSON.stringify(data) }
    });
  });

  handleTakeoverAssignee = async topic => {
    this.completionSaving = true;

    return updateTopicActorMembers(
      this.currentGroup,
      topic,
      [this.selfMember.id],
      "assigned"
    )
      .then(this.topicController.loadTopic)
      .catch(this._showError)
      .finally(() => (this.completionSaving = false));
  };

  handleUnassignSelf = async (topic, options) => {
    this.completionSaving = true;

    const actorName = "assigned";

    const assignedActor =
      this.topic.actors.find(a => a.actorName === actorName) || {};
    let assignedMemberIdList = assignedActor.memberIdList || [];
    assignedMemberIdList = assignedMemberIdList.filter(
      id => id !== this.selfMember.id
    );

    const resetCompletionState = async () =>
      apiService.async("PATCH", {
        endpoint: endpointConfig.topic_by_id(topic.id),
        data: {
          execStartTime: 0,
          // execEndTime: 0,
          isCompleted: 0
        }
      });

    return updateTopicActorMembers(
      this.currentGroup,
      this.topic,
      assignedMemberIdList,
      actorName
    )
      .then(() => options.resetCompletion && resetCompletionState())
      .then(this.topicController.loadTopic)
      .catch(this._showError)
      .finally(() => (this.completionSaving = false));
  };

  updateTopic = async (topic, silently) =>
    topic &&
    apiController
      .getTopicById(topic.id)
      .then(clientController.updateTopic)
      .then(() => topic.id === this.topicId && !silently && this._loadDetail())
      .finally(() => !silently && (this.detailLoading = false));

  helpFocusNextField = (field, formFields, form) =>
    formService.helpFocusNextField(field, formFields, form);

  checkNotifyIssueCompletion = async () => {
    return apiService
      .async("GET", {
        endpoint: mcbEndpointConfig.check_notify_issue_completion(this.topic.id)
      })
      .catch(console.error);
  };
}
