import { autorun, computed, observable, reaction, toJS } from "mobx";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { clientController } from "../../../../cdm/controllers/client-controller";
import { UIText } from "../../../../config/lang-config";
import {
  groupTypeActorIds,
  groupTypeIds,
  serviceRecipientGroupTypeIds,
  topicTypeIds,
  typeClassIds
} from "../../../../config/variable-config";
import NavigationService from "../../../../utils/navigation-service";
import {
  capitalize,
  getBrowserTimeZoneName,
  isEmpty,
  preventDefaultStopProp,
  safeParseJSON
} from "../../../../utils/helpers";
import { apiService } from "../../../../cdm/services/api-service";
import { endpointConfig } from "../../../../config/api-config";
import { apiController } from "../../../../cdm/controllers/api-controller";
import { mcbEndpointConfig } from "../../config/api-config";
import { ShiftTaskController } from "../../lib/shift-task-controller";
import {
  autoCompleteTopicData,
  deleteTopic,
  editTopicAssignee,
  editTopicDescription,
  editTopicMenu,
  editTopicStartEndTime,
  getTopicCompletionState
} from "../../../../cdm/lib/topic-utilities";
import { getTopicSummaryEditFlags } from "../../config/flags_calculator";
import { filterController } from "../../../../cdm/controllers/filter-controller";
import { formService } from "../../../../cdm/services/form-service";
import { handleGroupBottomTabChange } from "../../../../cdm/lib/group-utilities";

export class GroupShiftPageController {
  @observable loading = true;
  @observable editLoading = false;
  @observable shiftTemplateActivating = false;
  @observable taskTemplateLoading;
  @observable shiftInstanceLoading;

  @observable shiftInstanceTopics = [];

  // For network error.
  @observable errorMessage = "";
  retryThreshold = 3;
  retryCount = 0;

  // Default to "New shift template" option.
  newOption = {
    value: "new",
    label: `<${UIText.topicNewTopic(UIText.shiftMakerShiftTemplate)}>`
  };

  get topTabs() {
    return [
      {
        key: "task_templates",
        name: UIText.shiftMakerTaskTemplateTab
      },
      {
        key: "shifts",
        name: UIText.shiftMakerShiftTab
      }
    ].map(t => ({
      ...t,
      onTabPress: this.handleTabChange
    }));
  }

  // Top tab.
  @computed get currentTab() {
    if (!stateController.viewGroupState.topTabs["shift"][this.group.id])
      stateController.viewGroupState.topTabs["shift"][this.group.id] =
        "task_templates";
    return stateController.viewGroupState.topTabs["shift"][this.group.id];
  }

  @computed get shiftTemplateTopics() {
    return clientController.findTopics(
      t =>
        t.typeId === topicTypeIds.shift &&
        t.isTemplate &&
        t["groupIdList"].includes(this.group.id)
    );
  }
  @computed get shiftTemplateListItems() {
    const topics = this.shiftTemplateTopics;

    if (topics.length > 0 && isEmpty(this.currentShiftTemplate)) {
      setTimeout(() => this.handleShiftTemplateChange(topics[0].id));
    }

    return [
      this.newOption,
      ...topics.map(topic => ({
        value: topic.id,
        label: topic.description
      }))
    ];
  }

  // Current shift template topic id.
  @computed get currentShiftTemplateId() {
    return stateController.viewGroupState.shiftTemplateId[this.group.id];
  }
  @computed get currentShiftTemplate() {
    return clientController.findTopicById(this.currentShiftTemplateId);
  }
  @observable currentShiftTemplateActive = false;

  @computed get currentShiftTemplateShiftInstances() {
    return this.shiftInstanceTopics.filter(
      t =>
        t.typeId === topicTypeIds.shift &&
        !t.isTemplate &&
        !t.isParentTemplate &&
        t.templateTopicId === this.currentShiftTemplateId
    );
  }
  @computed get currentShiftTemplateNewShiftInstances() {
    return (
      this.currentShiftTemplateShiftInstances.filter(t => !t.execStartTime) ||
      []
    );
  }
  @computed get currentShiftTemplateClockedInInstances() {
    return (
      this.currentShiftTemplateShiftInstances.filter(
        t => getTopicCompletionState(t) === "clocked-in"
      ) || []
    );
  }
  @computed get currentShiftTemplateFilteredInstances() {
    let template = this.currentShiftTemplateShiftInstances;
    return filterController.applyFilterCategories(
      template,
      this.shiftInstanceFilterCategories
    );
  }
  @computed get currentShiftTemplateTaskTemplates() {
    return clientController.findTopics(
      t =>
        t.typeId === topicTypeIds.task &&
        t.isTemplate &&
        t.isParentTemplate &&
        t.parentId === this.currentShiftTemplateId
    );
  }
  @computed get currentShiftTemplateFilteredTaskTemplates() {
    let template = this.currentShiftTemplateTaskTemplates;
    return filterController.applyFilterCategories(
      template,
      this.taskTemplatesFilterCategories
    );
  }
  @computed get currentShiftTemplateAssigneeMemberIds() {
    const assignee =
      this.currentShiftTemplate.actors.find(
        a =>
          a["groupTypeActorId"] ===
          groupTypeActorIds[groupTypeIds.careReceiver].assigned
      ) || {};
    return assignee.memberIdList || [];
  }
  @computed get currentShiftTemplateAssignedToMe() {
    return !!this.currentShiftTemplateAssigneeMemberIds.includes(
      this.member.id
    );
  }
  // System task templates
  @computed get systemTaskTemplates() {
    return clientController.findTopics(
      t => t.typeId === topicTypeIds.task && t.isTemplate && !t.isParentTemplate
    );
  }

  // Current view group.
  @computed get group() {
    return clientController.findGroupById(stateController.viewGroupId);
  }
  @computed get timezone() {
    if (!serviceRecipientGroupTypeIds.includes(this.group.typeId))
      return getBrowserTimeZoneName();
    return (
      ((this.group.profile || {}).data || {}).timezone ||
      getBrowserTimeZoneName()
    );
  }
  // Member myself in current group.
  @computed get member() {
    return (
      this.group.members.find(m => m.userId === clientController.userId) || {}
    );
  }
  @computed get selfRoles() {
    return this.member.roleList || [];
  }
  @computed get selfActors() {
    return this.currentShiftTemplate.actors.filter(a =>
      a.memberIdList.includes(this.member.id)
    );
  }

  // Shift template summary edit flags.
  @computed get summaryEditFlags() {
    let flags = [];
    for (let actor of this.selfActors) {
      for (let role of this.selfRoles) {
        flags.push(
          ...getTopicSummaryEditFlags(
            this.currentShiftTemplate,
            actor.groupTypeActorId,
            role.groupTypeRoleId,
            this.group
          )
        );
      }
    }
    return flags.filter(Boolean);
  }

  @observable shiftInstanceFilterCategories = [
    ...filterController.CareReceiverShiftListFilterProto
  ].filter(
    fc =>
      fc.name !== filterController.CareReceiverShiftTitleCategory &&
      fc.name !== UIText.shiftIsSimpleShifts
  );
  setShiftInstanceFilter = filterCategories =>
    filterController.filterSetter(
      this.shiftInstanceFilterCategories,
      filterCategories
    );

  @observable taskTemplatesFilterCategories = [
    // {
    //   name: "original template",
    //   classifier: t => {
    //     const data = t.data && safeParseJSON(t.data);
    //     return data.originalTitle;
    //   }
    // }
    {
      name: "Task type",
      classifier: t => {
        const data = t.data && safeParseJSON(t.data);
        return data.taskType;
      },
      alwaysShownCriteria: ["urgent", "mandatory", "optional"]
    }
  ];

  setTaskTemplateFilter = filterCategories =>
    filterController.filterSetter(
      this.taskTemplatesFilterCategories,
      filterCategories
    );

  constructor(props) {
    this.props = props;
    this.shiftController = new ShiftTaskController();

    this.disposers = [
      autorun(() => this._getShiftTemplateTopicAuto()),
      autorun(() => this._getShiftTemplateActiveStateAuto()),
      autorun(() => this._getShiftInstanceTopicsAuto()),
      reaction(
        () => this.currentShiftTemplateId,
        () => this._getShiftTemplateTaskTemplatesAuto()
      )
    ];
    this._detectAutoTab();
    setTimeout(this._loadShiftMaker, 0);
  }

  componentWillUnmount() {
    Array.isArray(this.disposers) &&
      this.disposers.map(disposer => disposer && disposer());
  }

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.shiftMaker,
      content:
        (err.response &&
          (err.response.status === 304
            ? UIText.shiftMakerTemplateDeleteDeny
            : JSON.stringify(err.response.data).replace(/"/g, ""))) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _isLoading = () => {
    return setTimeout(() => (this.loading = true), 0);
  };

  _doneLoading = () => {
    return setTimeout(() => (this.loading = false), 0);
  };

  _retry = err => {
    if (this.retryCount > this.retryThreshold) {
      this._doneLoading();
      return (this.errorMessage = JSON.stringify(
        (err && err.response && err.response.data) || err.message
      ));
    }

    this.retryCount++;
    return this._loadShiftMaker();
  };

  _detectAutoTab = () => {
    const tabs = this.topTabs.map(tab => tab && tab.key).filter(Boolean);
    const tab = this.props["autoTab"];
    tab &&
      tabs.includes(tab) &&
      this.handleTabChange({ key: tab }) &&
      this.props.clearAutoTabs &&
      this.props.clearAutoTabs("shift");
  };

  _loadShiftMaker = async () => {
    this._isLoading();
    return Promise.all([
      this._getShiftTemplates(),
      this._getShiftTemplateTaskTemplatesAuto(true)
    ])
      .catch(this._retry)
      .finally(this._doneLoading);
  };

  _getShiftTemplates = async () => {
    return apiController
      .getGroupTopicsByTypeId(this.group.id, topicTypeIds.shift, true, false)
      .then(topics => {
        topics = topics.filter(
          t => !!t.isTemplate && t.typeId === topicTypeIds.shift
        );
        if (!topics || topics.length === 0) {
          return this.firstTimeSetupShiftMaker();
        }

        for (let topic of this.shiftTemplateTopics) {
          !topics.find(t => t.id === topic.id) &&
            clientController.removeTopic(topic);
        }

        for (let topic of topics) {
          clientController.updateTopic(topic);
        }

        return Promise.resolve();
      });
  };

  _getShiftTemplateTopicAuto = async () => {
    return apiController
      .getTopicById(this.currentShiftTemplateId)
      .then(clientController.updateTopic)
      .catch(this._showError);
  };

  _getShiftTemplateActiveStateAuto = async () => {
    if (!this.currentShiftTemplateId) return;
    this.shiftTemplateActivating = true;

    toJS(this.currentShiftTemplateNewShiftInstances);

    return apiService
      .async("GET", {
        endpoint: mcbEndpointConfig.shift_template_active(
          this.group.id,
          this.currentShiftTemplateId,
          this.member.id
        )
      })
      .then(response => {
        const status = response.data;

        if (typeof status !== "boolean")
          return console.warn(
            "Shift template activateStatus not a valid boolean!"
          );

        this.currentShiftTemplateActive = status;
      })
      .catch(this._showError)
      .finally(() => (this.shiftTemplateActivating = false));
  };

  _getShiftTemplateTaskTemplatesAuto = async quietly => {
    !quietly && (this.taskTemplateLoading = true);
    return (
      apiController
        .getTopicsByGroupId(this.group.id, true)
        // .getSubTopicsByParentId(this.currentShiftTemplateId)
        .then(topics =>
          topics.filter(t => t.parentId === this.currentShiftTemplateId)
        )
        .then(topics => {
          for (let topic of toJS(this.currentShiftTemplateTaskTemplates)) {
            !topics.find(t => t.id === topic.id) &&
              clientController.removeTopic(topic);
          }

          for (let topic of topics) {
            clientController.updateTopic(topic);
          }

          return Promise.resolve();
        })
        .finally(() => (this.taskTemplateLoading = false))
    );
  };

  _getShiftInstanceTopicsAuto = async quietly => {
    !quietly && (this.shiftInstanceLoading = true);
    const removeDuplicates = () => {
      const topics = toJS(this.shiftInstanceTopics);
      const uniqueIdList = Array.from(new Set(topics.map(topic => topic.id)));
      this.shiftInstanceTopics = uniqueIdList
        .map(id => topics.find(topic => topic.id === id))
        .filter(Boolean);
      return this.shiftInstanceTopics;
    };
    return (
      apiController
        .getTopicsByTemplateTopicId(this.currentShiftTemplateId, true)
        .then(topics => (this.shiftInstanceTopics = topics))
        .then(removeDuplicates)
        // .then(topics => {
        // for (let topic of toJS(this.currentShiftTemplateShiftInstances)) {
        //   !topics.find(t => t.id === topic.id) &&
        //     clientController.removeTopic(topic);
        // }
        //
        // for (let topic of topics) {
        //   clientController.updateTopic(topic);
        // }
        //
        // return Promise.resolve();
        // })
        .finally(() => (this.shiftInstanceLoading = false))
    );
  };

  checkCompleteShiftData = shift =>
    autoCompleteTopicData(shift, this.shiftInstanceTopics);

  createShiftTemplate = async templateName => {
    let data;

    await formService
      .findFormClassByIdAsync(typeClassIds.shiftTemplateTopic)
      .then(typeClass => formService.assembleFormData(typeClass, {}, {}))
      .then(formService.disassembleFormData)
      .then(topicData => (data = JSON.stringify(topicData)));

    const topic = {
      creatorMemberId: this.member.id,
      typeId: topicTypeIds.shift,
      description: templateName,
      groupId: this.group.id,
      onCalendar: 0,
      isTemplate: 1,
      isParentTemplate: 0,
      isCompleted: 0,
      isDataLocked: 0,
      isLocked: 0,
      data,
      typeClassId: typeClassIds.shiftTemplateTopic,
      typeClassVersion: 1 // Default for now.
    };

    return apiService
      .async("POST", {
        endpoint: endpointConfig.create_topic,
        data: {
          currentGroupId: this.group.id,
          topic
        }
      })
      .then(this._getShiftTemplates)
      .catch(this._showError);
  };

  editShiftTemplate = async event => {
    preventDefaultStopProp(event);

    if (
      this.loading ||
      this.editLoading ||
      this.currentShiftTemplateId === "new" ||
      !this.currentShiftTemplateId
    )
      return;

    return editTopicMenu(
      this.currentShiftTemplate,
      this.summaryEditFlags,
      {
        // button methods
        editTopicName: this.editShiftTemplateName,
        editTopicAssignee: this.editShiftTemplateAssignee,
        editTopicStartEndTime: this.editShiftTemplateTime,
        deleteTopic: this.deleteShiftTemplate,
        custom: [
          {
            index: 3,
            title: this.currentShiftTemplateActive
              ? UIText.shiftMakerRemoveScheduledShift
              : UIText.shiftMakerAddScheduledShift,
            onPress: this.editShiftTemplateActive,
            topDivider: true
          }
        ]
      },
      {
        // textOverride
        topicTypeName: UIText.shiftMakerShiftTemplate
      },
      {
        // icons (Use material-community)
        editTopicName: {
          circleChar: true,
          size: 19,
          value: 1,
          outline: true
        },
        editTopicStartEndTime: {
          circleChar: true,
          size: 19,
          value: 3,
          outline: true
        },
        editTopicAssignee: {
          circleChar: true,
          size: 19,
          value: 4,
          outline: true
        }
      }
    );
  };

  editShiftTemplateName = async () => {
    this.editLoading = true;
    return editTopicDescription(
      this.currentShiftTemplate,
      UIText.shiftMakerShiftTemplate
    )
      .then(update => update && this._getShiftTemplates())
      .catch(this._showError)
      .finally(() => (this.editLoading = false));
  };

  editShiftTemplateAssignee = async () => {
    this.editLoading = true;
    const assignedActorTypeId =
      groupTypeActorIds[groupTypeIds.careReceiver].assigned;
    const assignedActor = this.currentShiftTemplate.actors.find(
      a => a["groupTypeActorId"] === assignedActorTypeId
    );
    const previousAssignedMembers = assignedActor && assignedActor.memberIdList;

    return editTopicAssignee(
      this.group,
      this.currentShiftTemplate,
      "assigned",
      { allowNoAssignee: true },
      UIText.shiftMakerShiftTemplate
    )
      .then(update => update && this._getShiftTemplateTopicAuto())
      .then(update => {
        this.editLoading = false;
        return (
          update &&
          this.setShiftTemplateActiveByAssignee(previousAssignedMembers)
        );
      })
      .catch(this._showError)
      .finally(() => (this.editLoading = false));
  };

  editShiftTemplateTime = async () => {
    this.editLoading = true;
    return editTopicStartEndTime(
      this.currentShiftTemplate,
      { noDate: true },
      UIText.shiftMakerShiftTemplate,
      null,
      this.timezone
    )
      .then(this._getShiftTemplateTopicAuto)
      .catch(this._showError)
      .finally(() => (this.editLoading = false));
  };

  editShiftTemplateActive = async () => {
    const exec = async () =>
      this.setShiftTemplateActive(
        this.currentShiftTemplateActive ? "deactivate" : "activate",
        true
      );

    return this.currentShiftTemplateActive
      ? stateController.showPopup({
          title: capitalize(UIText.shiftMaker),
          content: UIText.shiftMakerConfirmDeactivate,
          leftButtonText: UIText.generalNo,
          rightButtonText: UIText.generalYes,
          rightButtonPress: exec,
          dismissOnBackPress: true
        })
      : exec();
  };

  setShiftTemplateActiveByAssignee = async previousAssignedMembers => {
    previousAssignedMembers = previousAssignedMembers || [];
    const computeAssigneeStatusAndSetActive = async () => {
      const clockedInShifts = this.currentShiftTemplateClockedInInstances;
      if (clockedInShifts.length > 0) return;
      if (isEmpty(this.currentShiftTemplateAssigneeMemberIds)) {
        return await this.setShiftTemplateActive("deactivate").then(() =>
          this.sendShiftTemplateUnassignedNotification(previousAssignedMembers)
        );
      } else {
        const previouslyDeactivated = toJS(!this.currentShiftTemplateActive);
        // Remove first on assignee change.
        if (this.currentShiftTemplateActive) {
          const removedAssignedMembers = previousAssignedMembers.filter(
            id => !this.currentShiftTemplateAssigneeMemberIds.includes(id)
          );
          await this.setShiftTemplateActive("deactivate").then(() =>
            this.sendShiftTemplateUnassignedNotification(removedAssignedMembers)
          );
        }
        // Then set it back active.
        const newAssignedMembers = this.currentShiftTemplateAssigneeMemberIds.filter(
          id => !previousAssignedMembers.includes(id) || previouslyDeactivated
        );
        return await this.setShiftTemplateActive("activate").then(() =>
          this.sendShiftTemplateAssignedNotification(newAssignedMembers)
        );
      }
    };

    return this._getShiftTemplateActiveStateAuto().then(
      computeAssigneeStatusAndSetActive
    );
  };

  setShiftTemplateActive = async (action, notify) => {
    const noAssigneePopup = () =>
      stateController
        .dismissPopup()
        .then(this.editShiftTemplateAssignee)
        .then(this._getShiftTemplateActiveStateAuto);

    if (
      action === "activate" &&
      isEmpty(this.currentShiftTemplateAssigneeMemberIds)
    ) {
      return noAssigneePopup();
    }

    if (this.loading || this.editLoading || this.shiftTemplateActivating) {
      return;
    }

    this.shiftTemplateActivating = true;
    return stateController
      .dismissPopup()
      .then(() =>
        stateController.showPopup({
          title: capitalize(
            UIText[
              action === "activate"
                ? "shiftMakerActivating"
                : "shiftMakerDeactivating"
            ]
          ),
          content: UIText.pleaseWait
        })
      )
      .then(() =>
        apiService.async("GET", {
          endpoint: mcbEndpointConfig.shift_template_active(
            this.group.id,
            this.currentShiftTemplateId,
            this.member.id,
            action
          )
        })
      )
      .then(() => this._getShiftInstanceTopicsAuto(true))
      .then(this._getShiftTemplateActiveStateAuto)
      .then(stateController.dismissPopup)
      .then(() => this.currentShiftTemplateAssigneeMemberIds)
      .then(
        notify &&
          (memberIds => {
            action === "activate"
              ? this.sendShiftTemplateAssignedNotification(memberIds)
              : this.sendShiftTemplateUnassignedNotification(memberIds);
          })
      )
      .catch(this._showError)
      .finally(() => (this.shiftTemplateActivating = false));
  };

  deleteShiftTemplate = async () => {
    const execTopicDelete = async () => {
      this.editLoading = true;
      return stateController
        .showPopup({
          title: UIText.generalDeleteItem(UIText.shiftMakerShiftTemplate),
          content: UIText.pleaseWait
        })
        .then(() => {
          return apiController
            .deleteTopic(this.currentShiftTemplateId)
            .then(() => {
              return clientController.removeTopic(this.currentShiftTemplateId);
            })
            .then(stateController.dismissPopup)
            .then(this._getShiftTemplates)
            .catch(this._showError)
            .finally(() => {
              if (this.shiftTemplateListItems.length === 1) {
                this.firstTimeSettingUp = false;
                return this.firstTimeSetupShiftMaker();
              }
            });
        });
    };

    return stateController.showPopup({
      title: UIText.generalDeleteItem(UIText.shiftMakerShiftTemplate),
      content: UIText.topicDeleteConfirm(this.currentShiftTemplate.description),
      leftButtonText: UIText.generalNo,
      rightButtonText: UIText.generalYes,
      rightButtonPress: () =>
        stateController
          .dismissPopup()
          .then(async () => {
            if (this.currentShiftTemplateActive) {
              await this.setShiftTemplateActive("deactivate", true);
            }
          })
          .then(stateController.dismissPopup)
          .then(execTopicDelete)
          .finally(() => (this.editLoading = false))
    });
  };

  sendShiftTemplateAssignedNotification = memberIdList => {
    if (isEmpty(memberIdList)) return;
    return apiService
      .async("POST", {
        endpoint: mcbEndpointConfig.send_shift_notification_assigned,
        data: {
          groupId: this.group.id,
          topicId: this.currentShiftTemplateId,
          memberIdList
        }
      })
      .catch(this._showError);
  };

  sendShiftTemplateUnassignedNotification = memberIdList => {
    if (isEmpty(memberIdList)) return;
    return apiService
      .async("POST", {
        endpoint: mcbEndpointConfig.send_shift_notification_unassigned,
        data: {
          groupId: this.group.id,
          topicId: this.currentShiftTemplateId,
          memberIdList
        }
      })
      .catch(this._showError);
  };

  getTaskTemplateSummaryEditFlags = task => {
    let flags = [];
    for (let actor of this.selfActors) {
      for (let role of this.selfRoles) {
        flags.push(
          ...getTopicSummaryEditFlags(
            task,
            actor.groupTypeActorId,
            role.groupTypeRoleId,
            this.group
          )
        );
      }
    }
    return flags;
  };

  addTaskTemplate = () => {
    stateController.initSetupMode = "addTaskTemplate";
    return NavigationService.navigate("Setup");
  };

  editTaskTemplate = (event, task) => {
    preventDefaultStopProp(event);

    if (this.loading || task.pending) return;

    const flags = this.getTaskTemplateSummaryEditFlags(task);

    return editTopicMenu(task, flags, {
      editTopicName: () => this.editTaskTemplateName(task),
      deleteTopic: () => this.deleteTaskTemplate(task)
    });
  };

  editTaskTemplateName = async task => {
    task.pending = true;
    return editTopicDescription(task)
      .then(update => update && this._getShiftTemplateTaskTemplatesAuto(true))
      .catch(this._showError)
      .finally(() => (task.pending = false));
  };

  deleteTaskTemplate = async task => {
    task.pending = true;
    return deleteTopic(task, UIText.topicDeleteConfirm(task.description))
      .then(update => update && this._getShiftTemplateTaskTemplatesAuto(true))
      .catch(this._showError);
  };

  getShiftClockCapability = shift =>
    this.shiftController.getShiftClockCapability(shift, this.member.id);

  sortShifts = shifts => this.shiftController.sortShifts(shifts);

  sortTasks = tasks => this.shiftController.sortTasks(tasks);

  handleTabChange = (tab, event) => {
    stateController.viewGroupState.topTabs["shift"][this.group.id] = tab.key;
    return true;
  };

  handleShiftTemplateChange = value => {
    if (value === "new") {
      if (this.loading || this.editLoading) return;

      let listName;

      return stateController.showPopup({
        title: UIText.topicNewTopic(
          UIText.shiftMakerShiftTemplate.toLowerCase()
        ),
        leftButtonText: UIText.generalCancel,
        leftButtonPress: () => {
          stateController.dismissPopup().then(() => {
            if (isEmpty(this.currentShiftTemplate)) {
              return this.navigateBackPreviousTab();
            }
          });
        },
        rightButtonText: UIText.generalConfirm,
        rightButtonPress: () => {
          stateController.dismissPopup().then(() => {
            if (!listName)
              return (
                isEmpty(this.currentShiftTemplate) &&
                this.navigateBackPreviousTab()
              );
            this._isLoading();

            return (
              this.createShiftTemplate(listName)
                .then(() => {
                  return this.handleShiftTemplateChange(
                    toJS(this.shiftTemplateListItems).reverse()[0].value
                  );
                })
                // .then(this.updateAssignee)
                .finally(this._doneLoading)
            );
          });
        },
        rightButtonDisabled: true,
        input: {
          placeholder: UIText.topicNewTopicInput(
            UIText.shiftMakerShiftTemplate
          ),
          footer: UIText.shiftMakerIntroductionHelp,
          onChange: e => {
            listName = e.nativeEvent.text;
            stateController.popup.rightButtonDisabled = isEmpty(listName);
          }
        }
      });
    }

    stateController.viewGroupState.shiftTemplateId[this.group.id] = Number(
      value
    );
  };

  handleClockInClockOut = async shift => {
    shift.inClockInOutPending = true;
    this.shiftController
      .handleClockInClockOut(shift, this.group, this.member)
      .catch(this._showError)
      .finally(() => {
        this._getShiftInstanceTopicsAuto(true).then(
          () => (shift.inClockInOutPending = false)
        );
      });
  };

  handleShiftInstancePress = shift => {
    stateController.viewTopicId = shift.id;
    NavigationService.navigate("Topic", { topic: shift.id });
  };

  handleTaskTemplatePress = task => {
    stateController.viewTopicId = task.id;
    NavigationService.navigate("Topic", { topic: task.id });
  };

  handleAllSchedulePress = event => {
    stateController.viewGroupState.topTabs.main[this.group.id] = "shift";
    return handleGroupBottomTabChange(this.group.id, "dashboard");
  };

  firstTimeSetupShiftMaker = () => {
    if (this.firstTimeSettingUp) return;
    this.firstTimeSettingUp = true;

    return stateController.showPopup({
      title: capitalize(UIText.shiftMaker),
      content: UIText.shiftMakerIntroduction,
      leftButtonText: UIText.generalCancel,
      rightButtonText: UIText.generalConfirm,
      leftButtonPress: () =>
        stateController.dismissPopup().then(this.navigateBackPreviousTab),
      rightButtonPress: () =>
        stateController
          .dismissPopup()
          .then(() => this.handleShiftTemplateChange("new", true))
    });
  };

  navigateBackPreviousTab = () => {
    setTimeout(() =>
      handleGroupBottomTabChange(
        this.group.id,
        stateController.viewGroupState.previousBottomTabs[this.group.id] ||
          "dashboard"
      )
    );
  };

  showHelpCallback = method => (this.showHelp = method);

  onRefresh = isTaskTemplates => {
    this.loading = true;
    return Promise.all(
      [
        this._getShiftTemplateTopicAuto(),
        this._getShiftTemplateActiveStateAuto(),
        !isTaskTemplates && this._getShiftInstanceTopicsAuto(),
        isTaskTemplates && this._getShiftTemplateTaskTemplatesAuto()
      ].filter(Boolean)
    )
      .catch(this._showError)
      .finally(() => (this.loading = false));
  };

  onScroll = event => {
    // stateController.viewGroupState.shiftScrollPos[this.group.id][
    //   this.currentTab
    //   ] = event.nativeEvent.contentOffset.y;
  };
}
