import { formService, TypeClassTemplate } from '../../../../../../../cdm/services/form-service';
import { groupTypeIds, typeClassIds } from '../../../../../../../config/variable-config';

let template = new TypeClassTemplate(typeClassIds.careReceiverProfile);

export const careReceiverMarketForm = () => {
  const form = template.form.filter(
    field =>
      field.type !== "collapsibleAnchor" &&
      (field.name === "provinces" ||
        field.name === "territories" ||
        (field.flags && (!field.flags["general"] && !!field.flags["search"])))
  );

  const provinces = form.find(field => field.name === "provinces");
  const territories = form.find(field => field.name === "territories");

  if (provinces) provinces.hidden = true;
  if (territories) territories.hidden = true;

  formService.hideFieldsByGroupTypeId(form, groupTypeIds.careReceiver);
  return form;
};
