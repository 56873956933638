import { common } from "../../../../config/style-configs/common";

export const styles = {
  style: {
    flex: 1
  },
  loading: common.loading,
  buttonReminder: common.listButtonReminderContainer,
  errorMessage: common.containerErrorMessage
};
