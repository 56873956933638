import React from "react";
import { PanResponder, Platform, ScrollView, View } from "react-native";
import { Button, Divider, Icon, Text } from "react-native-elements";
import { Avatar } from "../Avatar";
import { observer, Observer } from "mobx-react";
import { ThemedListItem } from "../ThemedListItem";
import { getDisplayNameEng, getRGBFromStr } from "../../utils/helpers";
import { UIText } from "../../config/lang-config";
import { TouchableOpacityIOSFix } from "../TouchableOpacityIOSFix";

@observer
class DrawerContentView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
    this.isWeb = Platform.OS === "web";
    this.buttonDriftThreshold = 2;
    this._panResponder = this.isWeb
      ? PanResponder.create({
          // Ask to be the responder:
          onStartShouldSetPanResponder: (evt, gestureState) => true,
          onStartShouldSetPanResponderCapture: (evt, gestureState) => false,
          onMoveShouldSetPanResponder: (evt, gestureState) => {
            const { dx, dy } = gestureState;
            return (
              dx > this.buttonDriftThreshold ||
              dx < -this.buttonDriftThreshold ||
              dy > this.buttonDriftThreshold ||
              dy < -this.buttonDriftThreshold
            );
          },
          onMoveShouldSetPanResponderCapture: (evt, gestureState) => false, // Problem
          onPanResponderTerminationRequest: (evt, gestureState) => true,
          onShouldBlockNativeResponder: (evt, gestureState) => false
        })
      : {};
  }

  getListItems = list =>
    list.map((l, i) => (
      <Observer key={l.id}>
        {() => (
          <TouchableOpacityIOSFix
            key={i}
            onPress={e => {
              this.controller.handleListItemPress(l.id, e);
            }}
          >
            <>
              <ThemedListItem
                showHoverHighlight
                key={i}
                leftAvatar={
                  l.isGroup &&
                  this.controller.getAvatarUri(l.avatar, l.id, "group")
                }
                leftIcon={l.icon && { name: l.icon, type: l.iconType }}
                title={l.title}
                titleId={l.titleId}
                subtitle={l.subtitle}
                rightTitle={l.rightTitle}
              />
              {l.showDivider && <Divider />}
            </>
          </TouchableOpacityIOSFix>
        )}
      </Observer>
    ));

  renderAddGroupOption = () => (
    <TouchableOpacityIOSFix
      onPress={e => {
        this.controller.handleListItemPress("add", e);
      }}
    >
      <ThemedListItem
        showHoverHighlight
        leftIcon={{ name: "add" }}
        title={UIText.newGroup}
      />
    </TouchableOpacityIOSFix>
  );

  render() {
    // Conditional rendering of drawer
    return this.controller.isVisitor ? null : (
      <View style={this.props.styles.style}>
        <ScrollView contentContainerStyle={this.props.styles.listContainer}>
          {/*Header contents*/}
          <TouchableOpacityIOSFix onPress={this.controller.openDefaultProfile}>
            <>
              <View style={this.props.styles.header}>
                <View style={this.props.styles.avatar}>
                  <Avatar
                    size={this.props.styles.avatarSize}
                    rounded
                    title={
                      getDisplayNameEng(this.controller.defaultProfileData)[0]
                    }
                    backgroundColor={getRGBFromStr(
                      getDisplayNameEng(this.controller.defaultProfileData) +
                        this.controller.user.defaultMember.profile.id
                    )}
                    source={{
                      uri: this.controller.getAvatarUri(
                        this.controller.defaultProfileData.avatar
                      )
                    }}
                  />
                </View>
                <Text
                  numberOfLines={3}
                  ellipsizeMode="tail"
                  style={this.props.styles.headerName}
                >
                  {getDisplayNameEng(this.controller.defaultProfileData)}
                </Text>
              </View>
              <View style={this.props.styles.headerIcon}>
                <Button
                  icon={<Icon size={24} name="settings" color="#aaa" />}
                  type="clear"
                  onPress={this.controller.openDefaultProfile}
                />
              </View>
            </>
          </TouchableOpacityIOSFix>

          {/*Member list contents*/}
          <View>
            <View
              style={this.props.styles.messageInbox}
              {...this._panResponder.panHandlers}
            >
              <TouchableOpacityIOSFix
                onPress={e => {
                  this.controller.handleListItemPress("inbox", e);
                }}
              >
                <ThemedListItem
                  showHoverHighlight
                  leftIcon={{ name: "message" }}
                  title={UIText.inbox}
                  titleStyle={{ marginBottom: 4 }}
                  badge={
                    this.controller.unreadCount > 0 && {
                      value: this.controller.unreadCount,
                      status: "error",
                      badgeStyle: this.props.styles.badgeStyle
                    }
                  }
                />
              </TouchableOpacityIOSFix>

              <Divider />
            </View>

            <Text style={this.props.styles.sectionHeader}>
              {UIText.drawerGroupLabel}
            </Text>
            <View
              style={this.props.styles.memberContainer}
              {...this._panResponder.panHandlers}
            >
              {this.getListItems(this.controller.groupMemberList)}
              {this.controller.showAddGroup && this.renderAddGroupOption()}
            </View>

            {/*<Divider />*/}

            {/*Navigation item contents*/}
            <View
              style={this.props.styles.navigationList}
              {...this._panResponder.panHandlers}
            >
              {this.getListItems(this.controller.navigationList)}
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

export { DrawerContentView };
