import { computed, observable, toJS } from "mobx";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { UIText } from "../../../../config/lang-config";
import { apiService } from "../../../../cdm/services/api-service";
import { mcbEndpointConfig } from "../../config/api-config";
import { apiController } from "../../../../cdm/controllers/api-controller";
import {
  groupTypeIds,
  groupTypeRoleIds
} from "../../../../config/variable-config";
import { getDisplayNameEng, isEmpty } from "../../../../utils/helpers";
import { paginationService } from "../../../../cdm/services/pagination-service";
import { endpointConfig } from "../../../../config/api-config";
import { invoiceRerunConfirmationHtml } from "../../lib/admin-centre-utilities/invoice-rerun-confirmation-html";

export class AdminPayrollPageController {
  @observable loading = false;
  @observable paging = false;
  @computed get noMoreNext() {
    return !this.timeSheetStore.next;
  }

  @computed get careReceiverGroups() {
    return stateController.viewAdminCentreState.payrollGroups || [];
  }
  @computed get lastGroup() {
    return (
      toJS(this.careReceiverGroups)[this.careReceiverGroups.length - 1] || {}
    );
  }
  @computed get lastPageId() {
    const lastId = (this.lastGroup || {}).id;
    return lastId ? lastId - 1 : "";
  }

  pagingSize = 10;

  @computed get collapsible() {
    if (!stateController.viewAdminCentreState.collapsible["payroll"])
      stateController.viewAdminCentreState.collapsible["payroll"] = {};

    return stateController.viewAdminCentreState.collapsible["payroll"];
  }

  @computed get timeSheetStore() {
    return stateController.viewAdminCentreState.payrollStore;
  }

  constructor(props) {
    this.props = props;

    this._initialize();
  }

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.adminCentre,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _initialize = async isRefresh => {
    if (isRefresh) this.careReceiverGroups.clear();
    if (isEmpty(this.careReceiverGroups)) {
      this.loading = true;
      return this._getAllGroups()
        .then(this._processGroups)
        .catch(this._showError)
        .finally(() => (this.loading = false));
    }
  };

  _getAllGroups = async () => {
    return apiService
      .async("POST", {
        endpoint: mcbEndpointConfig.get_all_admin_centre_groups,
        data: {
          groupTypeId: groupTypeIds.careReceiver,
          startTime: new Date("2019/06/01").getTime()
        }
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        this.timeSheetStore.next = undefined;
        return dataArray || [];
      });
  };

  _getNextPageGroups = async () =>
    paginationService
      .query("POST", {
        endpoint:
          this.timeSheetStore.next ||
          mcbEndpointConfig.get_all_admin_centre_groups_paged(
            "id",
            this.lastPageId,
            this.pagingSize,
            true
          ),
        data: {
          groupTypeId: groupTypeIds.careReceiver,
          startTime: new Date("2019/06/01").getTime()
        }
      })
      .then(response => {
        const dataArray = (response.data || {}).data;
        const links = (response.data || {}).links;
        this.timeSheetStore.next = links && links.next;
        return dataArray || [];
      });

  _processGroups = async data => {
    data = data
      .map(c => {
        const member = apiController.parser.parseMember(c) || {};
        return {
          ...apiController.parser.parseGroup(c),
          ownerEmail: member.email
        };
      })
      .filter(
        c =>
          c.ownerEmail && !c.ownerEmail.match(/(kefelan.com|kitsilanofc.com)/g)
      )
      // .sort((a, b) => {
      //   const nameA = this.getCollapsibleTitle(a);
      //   const nameB = this.getCollapsibleTitle(b);
      //
      //   return nameA > nameB ? 1 : -1;
      // })
      .filter(Boolean);

    return (stateController.viewAdminCentreState.payrollGroups = [
      ...this.careReceiverGroups.filter(c => !data.some(g => g.id === c.id)),
      ...data
    ]
      .filter(Boolean)
      .sort((a, b) => a.id || 0 - b.id || 0));
  };

  getNextPage = () => {
    if (this.paging || this.loading) return;
    this.paging = true;
    return this._getNextPageGroups()
      .then(this._processGroups)
      .finally(() => (this.paging = false));
  };

  timeSheetMemberFilter = member => {
    const roleList = Array.isArray(member.roleList) && member.roleList;
    return (
      roleList &&
      roleList.some(
        r => r.groupTypeRoleId === groupTypeRoleIds.paidCaregiver
      ) &&
      member
    );
  };

  getTimeSheetState = id => {
    if (!this.timeSheetStore[id]) this.timeSheetStore[id] = {};
    return {
      get: property => this.timeSheetStore[id][property],
      set: (property, value) =>
        (stateController.viewAdminCentreState.payrollStore[id][
          property
        ] = value)
    };
  };

  getCollapsibleTitle = group =>
    `${getDisplayNameEng(group.profile)} (${group.ownerEmail || "-"})`;

  handleCollapse = id =>
    (stateController.viewAdminCentreState.collapsible["payroll"][id] = !this
      .collapsible[id]);

  uncollapseAll = () => {
    for (let group of this.careReceiverGroups) {
      stateController.viewAdminCentreState.collapsible["payroll"][
        group.id
      ] = true;
    }
  };

  collapseAll = () =>
    (stateController.viewAdminCentreState.collapsible["payroll"] = {});

  onRefresh = () => this._initialize(true);

  onScroll = event => {
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
    const isAtBottom =
      layoutMeasurement.height + contentOffset.y >= contentSize.height / 1.5;
    this.timeSheetStore.scrollPos = contentOffset.y;
    if (!isAtBottom) return;
    if (this.noMoreNext) return;
    return this.getNextPage();
  };

  triggerInvoicesReRun = async () => {
    const rightButtonPress = () =>
      stateController
        .dismissPopup()
        .then(() =>
          stateController.showPopup({
            title: UIText.adminCentreInvoicesGen,
            content: UIText.pleaseWait
          })
        )
        .then(() =>
          apiService.async("GET", {
            endpoint: endpointConfig.re_run_invoice_generation(true)
          })
        )
        .catch(this._showError)
        .then(stateController.dismissPopup)
        .then(() =>
          stateController.showPopup({
            title: UIText.adminCentreInvoicesGen,
            content: UIText.adminCentreInvoicesReRunSuccess,
            leftButtonText: UIText.generalConfirm
          })
        );

    return stateController
      .showPopup({
        title: UIText.adminCentreInvoicesGen,
        content: UIText.pleaseWait
      })
      .then(() =>
        apiService.async("GET", {
          endpoint: endpointConfig.re_run_invoice_generation(false)
        })
      )
      .catch(this._showError)
      .then(response => response.data)
      .then(async results => {
        await stateController.dismissPopup();
        if (isEmpty(results))
          return stateController.showPopup({
            title: UIText.adminCentreInvoicesGen,
            content: UIText.adminCentreInvoicesReRunNoData,
            leftButtonText: UIText.generalConfirm
          });
        return stateController.showPopup({
          title: UIText.adminCentreInvoicesGen,
          contentHtml: invoiceRerunConfirmationHtml(results),
          leftButtonText: UIText.generalCancel,
          leftButtonPress: stateController.dismissPopup,
          rightButtonText: UIText.generalConfirm,
          rightButtonPress
        });
      });
  };
}
