import React from "react";
import { InvitationRegisterController } from "./controller";
import { InvitationRegisterScreen } from "./view";
import { extraStyles, styles } from "./styles";
import { UIText } from "../../../config/lang-config";

export class InvitationRegister extends React.Component {
  static navigationOptions = {
    title: `New Invitation | ${UIText.title}`
  };

  controller = new InvitationRegisterController(this.props);

  render() {
    return (
      <InvitationRegisterScreen
        {...this.props}
        styles={styles}
        extraStyles={extraStyles}
        controller={this.controller}
      />
    );
  }
}
