import { UIText } from '../../../../../../config/lang-config';

export const invitationForm = group => {
  let roles = group && group.roles;
  roles = roles.map(
    role =>
      ((role.maxMembers > 0 && role["members"].length < role.maxMembers) ||
        role.maxMembers === 0) &&
      role
  );
  roles = roles.sort((a, b) => {
    return a.sortIndex - b.sortIndex;
  });
  roles = roles.filter(Boolean).map(role => ({
    name: role.id,
    placeholder: role.description
  }));

  const paidProviderRole = roles.find(role => role.placeholder.match(/paid/gi));

  if (paidProviderRole) {
    paidProviderRole.placeholder = UIText.groupPaidProvider;
  }

  return [
    {
      name: "role",
      type: "picker",
      options: roles,
      value: roles[0].name,
      placeholder: "Invite a "
    },
    paidProviderRole && {
      name: "customRoleDescription",
      type: "picker",
      dependOn: "role",
      dependOnValue: paidProviderRole.name.toString(),
      placeholder: "as",
      options: [
        { name: "Paid Caregiver", placeholder: "Paid Caregiver" },
        { name: "Cleaner", placeholder: "Cleaner" }
      ].filter(Boolean)
    },
    {
      name: "firstName",
      type: "input",
      placeholder: "First Name",
      icon: "first-page",
      required: true
    },
    {
      name: "lastName",
      type: "input",
      placeholder: "Last Name",
      icon: "last-page",
      required: true
    },
    {
      name: "email",
      type: "input",
      placeholder: "Email Address",
      icon: "email",
      required: true
    }
  ].filter(Boolean);
};
