import { Button } from "react-native-elements";
import React from "react";
import { observer } from "mobx-react";
import { stateController } from "../../cdm/controllers/state-controller";
import { UIText } from "../../config/lang-config";

export const TooltipView = observer(props => {
  const showHelp = () =>
    stateController.showPopup({
      title: UIText.tooltipHelp(props.title),
      content: props.content,
      contentHtml: props.contentHtml,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true,
      contentAlign: "left"
    });

  if (props.showHelpCallback) props.showHelpCallback(showHelp); // Pass back the popup invoking method the parent for usage other than pressing circular icon.

  return (
    <Button
      buttonStyle={props.styles.style}
      containerStyle={{
        ...props.styles.button,
        ...(props.style || props.containerStyle)
      }}
      icon={{
        name: "help",
        size: props.styles.iconSize,
        color: props.styles.iconColor
      }}
      type="clear"
      onPress={props.onPress || showHelp}
    />
  );
});
