import { clientController } from "../../cdm/controllers/client-controller";
import { stateController } from "../../cdm/controllers/state-controller";
import { Platform } from "react-native";
import { computed } from "mobx";
import {
  groupTypeIds,
  profilePrototype,
  RoleGroupGroupTypeIds
} from "../../config/variable-config";
import NavigationService from "../../utils/navigation-service";
import { UIText } from "../../config/lang-config";
import {
  capitalize,
  getDisplayNameEng,
  isEmpty,
  preventDefaultStopProp,
  randomString
} from "../../utils/helpers";
import { isSnSOrAdmin } from "../../custom/mcb/utils/helper";
import { msgService } from "../../cdm/services/messaging-service";
import { txConfig } from "../../config/tx-config";
import { fileService } from "../../cdm/services/file-service";
import { filterController } from "../../cdm/controllers/filter-controller";
import { isServiceProviderGroup } from "../../custom/mcb/lib/group-utilities-mcb";

export class DrawerContentController {
  constructor(props) {
    this.props = props;
    this.navigator = props.navigation;
  }

  @computed get user() {
    return clientController.client.user || {};
  }

  @computed get groups() {
    return clientController.findVisibleGroups();
  }

  @computed get defaultProfileData() {
    return this.user.defaultMember.profile.data || {};
  }

  @computed get careReceiverGroupType() {
    return clientController.findGroupTypeById(groupTypeIds.careReceiver);
  }

  @computed get caregiverGroupType() {
    return clientController.findGroupTypeById(groupTypeIds.caregiver);
  }

  @computed get nonRoleGroups() {
    return this.groups.filter(g => !RoleGroupGroupTypeIds.includes(g.typeId));
  }

  @computed get unreadCount() {
    return msgService.unreadCount;
  }

  @computed get isVisitor() {
    return clientController.isVisitor;
  }

  @computed get showAddGroup() {
    const groups = clientController.findVisibleGroups();
    if (
      !isEmpty(groups) &&
      groups.every(g => g.typeId === groupTypeIds.caregiver)
    ) {
      const hasAvatar =
        !!this.defaultProfileData.avatar ||
        groups.some(g => g.profile && g.profile.data && g.profile.data.avatar);
      const hasIntro = groups.some(
        g =>
          g.profile &&
          g.profile.data &&
          (g.profile.data["introduction"] || g.profile.data["oldIntroduction"])
      );
      return hasAvatar && hasIntro;
    }
    return true;
  }

  @computed get groupMemberList() {
    return this.groups.map(group => {
      const gProfile = group.profile.data || profilePrototype;
      return {
        id: group.id,
        isGroup: true,
        avatar: gProfile.avatar,
        title: getDisplayNameEng(gProfile),
        titleId: group.profileId,
        subtitle: isServiceProviderGroup(group)
          ? UIText.groupServiceProviderName(group.groupTypeName)
          : group.groupTypeName,
        rightTitle: RoleGroupGroupTypeIds.includes(group.typeId)
          ? UIText.groupSingleMemberGroup
          : "", // Hard coded for now.
        showDivider: group.typeId === groupTypeIds.myCareBaseStaff
      };
    });
  }

  get navigationList() {
    return [
      {
        id: "marketplace",
        title:
          this.nonRoleGroups.length > 0
            ? UIText.marketNewGenericSearch(this.careReceiverGroupType.typeName)
            : UIText.marketplace,
        icon: "shopping-cart",
        showDivider: true
      },
      isSnSOrAdmin(this.user) && {
        id: "adminCentre",
        title: UIText.adminCentre,
        icon: "domain",
        iconType: "material-community"
      },
      // {
      //   id: "settings",
      //   title: UIText.settings,
      //   icon: "settings"
      // },
      window.location &&
        window.location.href.match(/localhost/g) && {
          id: "test",
          title: "{Playground}",
          icon: "settings"
        },
      !txConfig.fullResetOnLaunch && {
        id: "reset",
        title: UIText.clientReset,
        icon: "refresh"
      },
      {
        id: "logout",
        title: UIText.logout,
        icon: "exit-to-app"
      }
    ].filter(Boolean);
  }

  getAvatarUri = (avatarId, entityId, entityType) =>
    fileService.getProfileAvatarUri(avatarId, entityId, entityType);

  openDefaultProfile = () => {
    const profileId = clientController.defaultMember.profileId;
    const screenId = randomString();
    const lastScreen =
      stateController.currentScreen && stateController.currentScreen.toString();
    stateController.viewProfileState.backButtonOverride[screenId] = {
      handlePress: () => {
        NavigationService[Platform.OS === "web" ? "navigate" : "pop"](
          lastScreen
        );
        return true;
      }
    };
    NavigationService[Platform.OS === "web" ? "navigate" : "push"]("Profile", {
      profile: profileId,
      screenId
    });

    return (stateController.showDrawer = false);
  };

  handleListItemPress = (id, event) => {
    preventDefaultStopProp(event);

    switch (id) {
      case "root": {
        this.navigator.navigate("Root");
        break;
      }
      case "settings": {
        stateController.showPopup({
          title: capitalize(UIText.drawer),
          content: `${capitalize(
            UIText.settings
          )} ${UIText.comingSoon.toLowerCase()}`,
          leftButtonText: UIText.generalConfirm,
          dismissOnBackPress: true
        });
        break;
      }
      case "inbox": {
        this.navigator.navigate("Inbox");
        break;
      }
      case "marketplace": {
        // stateController.viewGroupId = -2;
        stateController.viewGroupId = -1;
        this.navigator.navigate("Root");
        break;
      }
      case "adminCentre": {
        this.navigator.navigate("AdminCentre");
        break;
      }
      case "test": {
        this.navigator.navigate("Test");
        break;
      }
      case "add": {
        stateController.initSetupMode = "addGroup";
        this.navigator.navigate("Setup");
        break;
      }
      case "reset": {
        fileService.resetAllAvatars();
        filterController.reset();
        clientController.fullClientReset();
        stateController.reset(true);
        break;
      }
      case "logout": {
        clientController.execLogout(true, true);
        setTimeout(() => NavigationService.navigate("Login"), 100);
        break;
      }
      default: {
        Platform.OS !== "web" && this.navigator.closeDrawer();
        this.navigator.navigate("Group", { group: id });
        stateController.viewGroupId = id;
        break;
      }
    }

    if (Platform.OS === "web") {
      stateController.showDrawer = false;
    }
  };
}
