import React from "react";
import { VisitorScreen } from "./view";
import { VisitorController } from "./controller";
import { styles } from "./styles";
import { UIText } from "../../../config/lang-config";

export class Visitor extends React.Component {
  static navigationOptions = {
    title: `Visitor | ${UIText.title}`
  };

  controller = new VisitorController(this.props);

  render() {
    return (
      <VisitorScreen
        {...this.props}
        styles={styles}
        controller={this.controller}
      />
    );
  }
}
