import React from "react";
import {
  ActivityIndicator,
  RefreshControl,
  ScrollView,
  Text,
  View
} from "react-native";
import { observer } from "mobx-react";
import { TabHeader } from "../../../../components/TabHeader";
import { UIText } from "../../../../config/lang-config";
import { theme } from "../../../../config/style-configs/theme";
import { AdminCentreButtons } from "../AdminCentreButtons";
import { ListFilterIndicator } from "../../../../components/ListFilterIndicator";
import { Button } from "react-native-elements";
import { AdminGroupCards } from "../AdminGroupCards";
import { fileService } from "../../../../cdm/services/file-service";

// TODO: nav param "serviceType" should be implicited just like Admin Centre

@observer
class AdminProviderGroupPageView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  renderGroupCards = groups => {
    if (!Array.isArray(groups) || groups.length === 0) {
      return (
        <Text style={this.props.styles.errorMessage}>
          {this.controller.loading
            ? ""
            : UIText.adminCentreNothingToShow(
                this.controller.groupType["typeNamePlural"]
              )}
        </Text>
      );
    }

    return (
      <AdminGroupCards
        showBusinessName
        groups={groups}
        getAvatarUri={fileService.getProfileAvatarUri}
        hideAvatar={this.controller.hideAvatar}
        loading={this.controller.loading}
        getPickerPlaceholder={this.controller.getPickerPlaceholder}
        handleCardPress={this.props.handleCardPress}
        handleMenuPress={this.props.handleMenuPress}
      />
    );
  };

  renderGroups = () => (
    <View style={this.props.styles.style}>
      <TabHeader
        tabs={this.controller.topTabs}
        currentKey={this.controller.topTabKey}
      />
      <View
        style={{
          ...this.props.styles.scrollViewCard,
          padding: 10
        }}
      >
        {this.renderGroupCards(this.controller.visibleGroups)}
      </View>
    </View>
  );

  render() {
    return (
      <ScrollView
        ref={ref => (this.scrollView = ref)}
        scrollEventThrottle={250}
        onScroll={this.controller.onScroll}
        contentContainerStyle={this.props.styles.scrollView}
        refreshControl={
          <RefreshControl
            refreshing={this.controller.loading}
            onRefresh={this.controller.onRefresh}
          />
        }
      >
        {this.controller.loading ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          <View>
            <View style={this.props.styles.scrollViewContent}>
              <AdminCentreButtons
                cards={this.controller.cards}
                filterCategories={this.controller.filterCategories}
                setFilter={this.controller.setFilter}
                hideAvatar={this.controller.hideAvatar}
                onShowHideAvatars={this.controller.showHideAvatars}
                loading={this.controller.loading}
                onRefresh={this.controller.onRefresh}
                sortBy={this.controller.sortBy}
                onSortByAlpha={this.controller.sortByAlpha}
                onSortByDate={this.controller.sortByDate}
                isProvider
                providerGroupTypes={this.controller.groupTypes}
                providerGroupTypeId={this.controller.groupTypeId}
                onProviderGroupTypeChange={
                  this.controller.handleGroupTypeChange
                }
              />
              <ListFilterIndicator
                length={this.controller.filteredGroups.length}
                categories={this.controller.filterCategories}
              />
            </View>
            {this.renderGroups()}
            {!this.controller.noMoreNext &&
              (this.controller.paging ? (
                <ActivityIndicator
                  size={24}
                  style={{ paddingBottom: 10, minHeight: 50 }}
                  color={theme.color}
                />
              ) : (
                <Button
                  type="clear"
                  style={{ minHeight: 50 }}
                  title={
                    this.controller.hasFilter
                      ? UIText.adminLoadAll
                      : UIText.adminLoadMore
                  }
                  titleStyle={{ color: theme.color }}
                  onPress={() =>
                    this.controller.hasFilter
                      ? this.controller.getAndProcessAllGroups()
                      : this.controller.getAndProcessNextPageGroups()
                  }
                />
              ))}
          </View>
        )}
      </ScrollView>
    );
  }
}

export { AdminProviderGroupPageView };
