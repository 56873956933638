import { computed, observable, reaction, toJS } from 'mobx';
import { clientController } from '../../../../cdm/controllers/client-controller';
import { stateController } from '../../../../cdm/controllers/state-controller';
import { topicTypeIds } from '../../../../config/variable-config';
import { apiController } from '../../../../cdm/controllers/api-controller';
import NavigationService from '../../../../utils/navigation-service';
import { capitalize, isEmpty, randomString } from '../../../../utils/helpers';
import { UIText } from '../../../../config/lang-config';
import { getCcTopicContactString } from '../../utils/helper';
import { txService } from '../../../../cdm/services/tx-service';

export class CaregiverEEListController {
  @observable refreshing = false;
  @observable loaded = false;

  @observable careCircleGroups = [];

  topicTypeId = topicTypeIds.candidate;

  topicFilter = t =>
    (t.groupIdList || []).includes(this.group.id) &&
    t.typeId === this.topicTypeId &&
    !!t["lastPublicMessageTime"];
  // !t.isCompleted;

  @computed get loading() {
    return !this.loaded;
  }
  // Current view group.
  @computed get groupId() {
    return stateController.viewGroupId;
  }
  @computed get group() {
    return clientController.findGroupById(this.groupId);
  }
  @computed get topics() {
    return clientController.findTopics(this.topicFilter);
  }
  @computed get shouldRenderTopics() {
    return this.topics
      .map(topic => {
        const groupId = topic.groupIdList.find(id => id !== this.group.id);
        const group = this.careCircleGroups.find(g => g.id === groupId);
        const profile = group && group.profile && group.profile.data;

        if (!profile) return false;

        return {
          ...topic,
          group,
          gProfile: profile
        };
      })
      .filter(Boolean);
  }

  constructor(props) {
    this.props = props;
    this._initialize();
  }

  componentWillUnmount() {
    Array.isArray(this.disposers) &&
      this.disposers.map(disposer => disposer && disposer());
    txService.removeLogEventListener("CaregiverEEListGroupListener");
  }

  _showError = err => {
    console.warn(err);
    return stateController.showPopup({
      title: capitalize(UIText.group),
      content: (err && err.message) || err,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: stateController.dismissPopup
    });
  };

  _initialize = () => {
    this._getCandidateTopics()
      .then(this._getCareCircleGroups)
      .then(() => {
        this.disposers = [
          reaction(
            () => this.groupId,
            (value, reaction) => this._getCandidateTopics()
          ),
          reaction(() => this.topics, this._getCareCircleGroups)
        ];
      })
      .catch(this._showError)
      .finally(() => {
        this.loaded = true;
        return this._registerSSEGroupListener();
      });
  };

  _registerSSEGroupListener = () => {
    const name = "CaregiverEEListGroupListener";
    const listener = async data => {
      if (isEmpty(data) || !data.id || data.entity !== "Group") return;
      const { id } = data;
      return apiController.getGroupById(id).then(group => {
        if (!isEmpty(group)) return;
        const index = this.careCircleGroups.findIndex(g => g.id === id);
        if (index < 0) return;
        return this.careCircleGroups.splice(index, 1);
      });
    };

    return txService.addLogEventListener(name, listener);
  };

  _getCandidateTopics = async force => {
    if (this.topics.length === 0 || force) {
      return apiController
        .getGroupTopicsByTypeId(this.groupId, this.topicTypeId)
        .then(topics => {
          for (let topic of toJS(this.topics)) {
            !topics.find(t => t.id === topic.id) &&
              clientController.removeTopic(topic);
          }

          topics = topics.filter(this.topicFilter);

          for (let topic of topics) {
            clientController.updateTopic(topic);
          }
        });
    }
  };

  _getCareCircleGroups = async () => {
    if (!Array.isArray(this.topics) || this.topics.length === 0) return;

    for (let topic of this.topics) {
      const groupId =
        Array.isArray(topic.groupIdList) &&
        topic.groupIdList.find(id => id !== this.group.id);
      if (!groupId) continue;

      const group = this.careCircleGroups.find(g => g.id === groupId);
      if (group) continue;

      await apiController.getGroupById(groupId).then(async group => {
        if (isEmpty(group)) return;
        if (isEmpty(group.profile)) {
          await apiController
            .getProfileById(group.profileId)
            .then(profile => (group.profile = profile));
        }
        this.careCircleGroups.push(group);
      });
    }

    return Promise.resolve();
  };

  handleTopicPress = topic => {
    const id = topic.id;

    // stateController.viewTopicId = id;
    // NavigationService.navigate("Topic", { topic: id });
    this.openChat(id);
  };

  handleCheckboxPress = (topic, group) => {
    if (isEmpty(group) || isEmpty(topic)) return;

    const cCContactString = `Contact: ${getCcTopicContactString(topic)}`;

    const screenId = randomString();
    stateController.viewProfileState.rightButtonOverride[screenId] = {
      icon: topic["lastPublicMessageTime"] ? "chat" : "chat-bubble",
      handlePress: () => NavigationService.navigate("Chat", { topic: topic.id })
    };
    stateController.viewProfileState.titleOverride[screenId] = cCContactString;
    // stateController.viewProfileState.renderFlags[screenId] = ["marketplace"];
    return NavigationService.navigate("Profile", {
      group: this.groupId,
      profile: group.profileId,
      screenId
    });
  };

  openChat = topicId => {
    stateController.viewTopicId = topicId;
    NavigationService.navigate("Chat", { topic: topicId });
  };

  onRefresh = () => {
    this.refreshing = true;
    return this._getCandidateTopics(true)
      .then(this._getCareCircleGroups)
      .finally(() => (this.refreshing = false));
  };
}
