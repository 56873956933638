import { computed, observable, reaction, toJS } from 'mobx';
import { clientController } from '../../../../cdm/controllers/client-controller';
import { stateController } from '../../../../cdm/controllers/state-controller';
import { groupTypeIds, topicTypeIds } from '../../../../config/variable-config';
import { apiController } from '../../../../cdm/controllers/api-controller';
import NavigationService from '../../../../utils/navigation-service';
import { capitalize, getDisplayNameEng, isEmpty } from '../../../../utils/helpers';
import { UIText } from '../../../../config/lang-config';
import { txService } from '../../../../cdm/services/tx-service';

export class MCBMessageListController {
  @observable refreshing = false;
  @observable loaded = false;

  @observable groups = [];

  topicTypeId = topicTypeIds.mCBBroadcasts;

  topicFilter = t =>
    t.groupIdList &&
    (t.groupIdList || []).includes(this.group.id) &&
    t.typeId === this.topicTypeId &&
    (this.isMCBGroup || !!t["lastPublicMessageTime"]);

  @computed get loading() {
    return !this.loaded;
  }
  // Current view group.
  @computed get groupId() {
    return stateController.viewGroupId;
  }
  @computed get group() {
    return clientController.findGroupById(this.groupId);
  }
  @computed get isMCBGroup() {
    return this.group.typeId === groupTypeIds.myCareBaseStaff;
  }
  @computed get member() {
    return (
      this.group.members.find(m => m.userId === clientController.userId) || {}
    );
  }
  @computed get topics() {
    return clientController.findTopics(this.topicFilter);
  }
  @computed get shouldRenderTopics() {
    return this.topics
      .map(topic => {
        const groupId = topic.groupIdList.find(id => id !== this.group.id);
        const group = this.groups.find(g => g.id === groupId);
        const profile = group && group.profile && group.profile.data;

        if (!profile) return false;

        return {
          ...topic,
          group,
          gProfile: profile
        };
      })
      .filter(Boolean);
  }
  @computed get filteredTopics() {
    return (
      this.shouldRenderTopics
        // .filter
        .sort((a, b) => {
          const dateA =
            a["lastPublicMessageTime"] &&
            new Date(a["lastPublicMessageTime"]).getTime();
          const dateB =
            b["lastPublicMessageTime"] &&
            new Date(b["lastPublicMessageTime"]).getTime();

          const nameA =
            a.gProfile && getDisplayNameEng(a.gProfile).toLowerCase();
          const nameB =
            b.gProfile && getDisplayNameEng(b.gProfile).toLowerCase();

          return this.sortBy === "dateAscend"
            ? -(dateA - dateB)
            : this.sortBy === "dateDescend"
            ? dateA - dateB
            : this.sortBy === "alphaAscend"
            ? nameA > nameB
              ? 1
              : -1
            : this.sortBy === "alphaDescend"
            ? nameA < nameB
              ? 1
              : -1
            : 0;
        })
    );
  }
  @computed get sortBy() {
    return (
      stateController.viewGroupState.adminMessageListSortBy[this.groupId] ||
      "dateAscend"
    );
  }

  constructor(props) {
    this.props = props;
    this._initialize();
  }

  componentWillUnmount() {
    Array.isArray(this.disposers) &&
      this.disposers.map(disposer => disposer && disposer());
    txService.removeLogEventListener("MCBMessageListGroupListener");
  }

  _showError = err => {
    console.warn(err);
    return stateController.showPopup({
      title: capitalize(UIText.group),
      content: (err && err.message) || err,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: stateController.dismissPopup
    });
  };

  _initialize = () => {
    return this._getTopics(true)
      .then(this._getGroups)
      .then(() => {
        this.disposers = [
          reaction(
            () => this.groupId,
            (value, reaction) => this._getTopics(true)
          ),
          reaction(() => this.topics, this._getGroups)
        ];
      })
      .catch(this._showError)
      .finally(() => {
        this.loaded = true;
        return this._registerSSEGroupListener();
      });
  };

  _registerSSEGroupListener = () => {
    const name = "MCBMessageListGroupListener";
    const listener = async data => {
      if (isEmpty(data) || !data.id || data.entity !== "Group") return;
      const { id } = data;
      return apiController.getGroupById(id).then(group => {
        if (!isEmpty(group)) return;
        const index = this.groups.findIndex(g => g.id === id);
        if (index < 0) return;
        return this.groups.splice(index, 1);
      });
    };

    return txService.addLogEventListener(name, listener);
  };

  _getTopics = async force => {
    if (this.topics.length === 0 || force) {
      return apiController
        .getGroupTopicsByTypeId(this.groupId, this.topicTypeId)
        .then(topics => {
          for (let topic of toJS(this.topics)) {
            !topics.find(t => t.id === topic.id) &&
              clientController.removeTopic(topic);
          }

          topics = topics.filter(this.topicFilter);

          for (let topic of topics) {
            clientController.updateTopic(topic);
          }
        })
        .catch(this._showError);
    }
  };

  _getGroups = async () => {
    if (!Array.isArray(this.topics) || this.topics.length === 0) return;

    const getGroup = async topic => {
      const groupId =
        Array.isArray(topic.groupIdList) &&
        topic.groupIdList.find(id => id !== this.group.id);
      if (!groupId) return;

      const group = this.groups.find(g => g.id === groupId);
      if (group) return;

      return apiController
        .getGroupById(groupId)
        .then(async group => {
          if (isEmpty(group)) return;
          if (isEmpty(group.profile)) {
            await apiController
              .getProfileById(group.profileId)
              .then(profile => (group.profile = profile));
          }
          this.groups.push(group);
        })
        .catch(this._showError);
    };

    return Promise.all(this.topics.map(getGroup));
  };

  openChat = topicId => {
    stateController.viewTopicId = topicId;
    NavigationService.navigate("Chat", { topic: topicId });
  };

  sortByDate = () =>
    setTimeout(
      () =>
        (stateController.viewGroupState.adminMessageListSortBy[this.groupId] =
          this.sortBy === "dateAscend"
            ? "dateDescend"
            : this.sortBy === "dateDescend"
            ? "dateAscend"
            : "dateAscend")
    );

  sortByAlpha = () =>
    setTimeout(
      () =>
        (stateController.viewGroupState.adminMessageListSortBy[this.groupId] =
          this.sortBy === "alphaAscend"
            ? "alphaDescend"
            : this.sortBy === "alphaDescend"
            ? "alphaAscend"
            : "alphaAscend")
    );

  onRefresh = () => {
    this.refreshing = true;
    return this._getTopics(true)
      .then(this._getGroups)
      .finally(() => (this.refreshing = false));
  };

  updateTopic = async topic =>
    topic &&
    apiController.getTopicById(topic.id).then(clientController.updateTopic);
}
