import { theme } from "../../config/style-configs/theme";
import { common } from "../../config/style-configs/common";

export const styles = {
  breakdown: {
    flexDirection: "row",
    paddingHorizontal: 15
  },
  breakdownColumn: {
    flexGrow: 1
  },
  breakdownTitle: {
    paddingBottom: 10
  },
  breakdownTitleText: {
    fontSize: theme.FONT_SIZE_LARGE,
    color: theme.color
  },
  breakdownDetail: {
    paddingBottom: 10,
    marginBottom: 10,
    borderBottomColor: theme.color,
    borderBottomWidth: 1
  },
  breakdownDetailNoDivide: {
    paddingBottom: 0,
    borderBottomWidth: 0
  },
  breakdownDetailText: {
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  breakdownTextRightAlign: {
    textAlign: "right",
    paddingRight: 25
  },
  breakdownDetailTextSmall: {
    fontSize: theme.FONT_SIZE_SMALL
  },
  errorMessage: common.containerErrorMessage
};
