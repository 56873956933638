import { common } from "../../../../config/style-configs/common";
import { theme } from "../../../../config/style-configs/theme";

export const styles = {
  style: {
    flex: 1
  },
  loading: common.loading,
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  button: {
    margin: 10,
    marginTop: 0,
    maxWidth: 200
  },
  buttonContainer: common.listButtonContainer,
  buttonReminder: common.listButtonReminderContainer,
  buttonTitleContainer: common.buttonTitleContainer,
  buttonTitle: common.buttonTitle,
  muniContainer: {
    maxWidth: 600
  },
  municipalityLabelField: {
    maxHeight: 40
  },
  municipalitiesContainer: {
    paddingLeft: 10,
    paddingRight: 15
  },
  municipalityItem: {
    marginVertical: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
    borderWidth: 1,
    borderRadius: 3,
    borderColor: "#ededed",
    backgroundColor: "#fafafa"
  },
  municipalityItemText: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    fontWeight: "600",
    color: "#333"
  }
};
