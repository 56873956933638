export const theme = {
  loginLogo: require("../../custom/mcb/assets/myCareBase_logo2.png"),
  color: "#f15f22",
  lightColor: "#fac0a8",

  generalAcceptColor: "#98FB98",
  // generalNormalColor: "#87CEFA",
  generalNormalColor: "#559cc8",
  generalDenyColor: "#ed5e68",

  bottomBarColor: "#fcfcfc",
  bottomBarDividerColor: "#eee",
  bottomBarRippleDrop: "rgba(255, 255, 255, 0.16)",

  chatBubbleColor: "#d3edf5",
  chatBubbleColorOwn: "#e7e7e7",

  defaultAvatarUri: "https://png.pngtree.com/svg/20161027/631929649c.svg",
  defaultAvatar: require("../../assets/defaultAvatar.svg"),

  FONT_SIZE_TINY: 12,
  FONT_SIZE_SMALL: 13,
  FONT_SIZE_MEDIUM: 15,
  FONT_SIZE_LARGE: 18,
  FONT_SIZE_XLARGE: 23,
  FONT_SIZE_XXLARGE: 28,

  popupFadeDuration: 200,

  sidebarWidth: 280,

  ratingStarColor: "#F9B403"
};
