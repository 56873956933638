import React from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { Badge } from "react-native-elements";
import { Avatar } from "../Avatar";
import { observer } from "mobx-react";
import { getDisplayNameEng, getRGBFromStr, isEmpty } from "../../utils/helpers";
import { theme } from "../../config/style-configs/theme";
import { AnimatedOpacityView } from "../AnimatedOpacityView";

@observer
class MessageItemView extends React.Component {
  render() {
    const profile = this.props.profile || {};

    this.avatar = (
      <Avatar
        style={
          this.props.self
            ? this.props.styles.messageAvatarOwn
            : this.props.styles.messageAvatar
        }
        size={28}
        rounded
        title={profile.displayName && profile.displayName[0]}
        backgroundColor={getRGBFromStr(
          profile.displayName + this.props.sender.profileId
        )}
        source={{
          uri: this.props.avatar
        }}
        onPress={() => !isEmpty(this.props.sender) && this.props.onPress()}
      />
    );

    this.body = (
      <View
        style={
          this.props.self
            ? this.props.styles.messageTextsOwn
            : this.props.styles.messageTexts
        }
      >
        <View style={this.props.styles.name}>
          <Text style={this.props.styles.nameText}>
            {getDisplayNameEng(profile)}
          </Text>
        </View>
        <View style={this.props.styles.bubbleWrapper}>
          {this.props.unread && (
            <Badge
              status="error"
              value=" "
              containerStyle={this.props.styles.unreadBadge}
              badgeStyle={this.props.styles.unreadBadgeSize}
            />
          )}
          {this.props.sending && (
            <View style={this.props.styles.sending}>
              <ActivityIndicator size="small" color={theme.color} />
            </View>
          )}
          <View
            style={
              this.props.self
                ? this.props.styles.bubbleOwn
                : this.props.styles.bubble
            }
          >
            <Text style={this.props.styles.text}>{this.props.text.trim()}</Text>
          </View>
        </View>
        <View style={this.props.styles.timestamp}>
          <Text style={this.props.styles.timestampText}>
            {this.props.timestamp || "Sending"}
          </Text>
        </View>
      </View>
    );

    if (this.props.self) {
      return (
        <View style={this.props.styles.style}>
          <AnimatedOpacityView
            visible={this.props.focused}
            opacity={0.5}
            style={this.props.styles.bgUnderlay}
          />
          {this.body}
          {this.avatar}
        </View>
      );
    } else {
      return (
        <View style={this.props.styles.style}>
          <AnimatedOpacityView
            visible={this.props.focused}
            opacity={0.4}
            style={this.props.styles.bgUnderlay}
          />
          {this.avatar}
          {this.body}
        </View>
      );
    }
  }
}

export { MessageItemView };
