import React from "react";
import { Badge } from "react-native-elements";
import { observer } from "mobx-react";
import { theme } from "../../config/style-configs/theme";

export const CircleCharIconView = observer(props => {
  const badgeStyle = {
    borderWidth: props.outline ? 2 : 1,
    backgroundColor: props.outline ? null : props.color || theme.color,
    borderColor: props.outline ? props.color || theme.color : "#fff",
    ...(props.size && {
      borderRadius: props.size,
      height: props.size,
      minWidth: props.size
    })
  };

  const textStyle = {
    color: props.outline ? props.color || theme.color : "#fff"
  };

  return (
    <Badge
      onPress={props.onPress}
      containerStyle={props.styles.container}
      badgeStyle={badgeStyle}
      textStyle={textStyle}
      value={props.value}
    />
  );
});
