import React from "react";
import { observer } from "mobx-react";
import { TouchableOpacity } from "react-native";
import { fileService } from "../../cdm/services/file-service";

@observer
class FilePickerWrapperView extends React.Component {
  _handleWebChange = event => {
    const files = this.props.multiple
      ? event.target.files
      : event.target.files[0];
    this.props.onChange(files);

    // free up the fileInput again
    this.fileInput.value = null;
  };

  render() {
    return (
      <TouchableOpacity>
        <input
          type="file"
          style={{ display: "none" }}
          onChange={this._handleWebChange}
          multiple={this.props.multiple}
          accept={fileService.getMediaTypes(this.props.extensions)}
          ref={ref => (this.fileInput = ref)}
        />
        {React.cloneElement(this.props.children, {
          onPress: () =>
            this.props.onPress
              ? this.props.onPress()
              : !this.props.disabled && this.fileInput.click()
        })}
      </TouchableOpacity>
    );
  }
}

export { FilePickerWrapperView };
