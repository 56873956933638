import { styles as generalStyles } from "../styles";

const styles = {
  ...generalStyles,
  intentCheckbox: {
    ...generalStyles.intentCheckbox
    // width: 315
  }
};

export { styles };
