import React from "react";
import { AdminCentreScreen } from "./view";
import { AdminCentreController } from "./controller";
import { styles } from "./styles";

export class AdminCentre extends React.Component {
  static navigationOptions = {
    title: "Admin Centre"
  };

  constructor(props) {
    super(props);
    this.controller = new AdminCentreController(props);
  }

  render() {
    return (
      <AdminCentreScreen
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
