import { formService } from '../../../../cdm/services/form-service';
import { isEmpty } from '../../../../utils/helpers';
import { UIText } from '../../../../config/lang-config';
import { ShiftTaskController } from '../../lib/shift-task-controller';
import { clientController } from '../../../../cdm/controllers/client-controller';
import { getTopicAssignedMembers } from '../../../../cdm/lib/topic-utilities';

export class TopicTaskListController {
  constructor(props) {
    this.props = props;
    this.shiftController = new ShiftTaskController();
  }

  sortTasks = tasks => this.shiftController.sortTasks(tasks);

  getDetailCompletion = topic => {
    formService.findFormClassByIdAsync(topic.typeClassId).then(form => {
      form = formService.assembleFormData(form, topic.data, {
        filter: f => f.flags && f.flags.executionEdit && f.required,
        override: true
      });
      if (isEmpty(form)) return;

      for (let field of form) {
        if (field.required && !field.value)
          return (topic.detailCompleted = UIText.topicRequiredDetailIncomplete);
      }

      return (topic.detailCompleted = UIText.topicRequiredDetailComplete);
    });
    return true;
  };

  getTaskCompletionCapability = topic => {
    const shift = clientController.findTopicById(topic.parentId);
    const members = getTopicAssignedMembers(shift) || [];
    return members.some(member => member.userId === clientController.userId);
  };
}
