import { styles } from "../styles";

export const extraStyles = {
  referenceHeader: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between"
  },
  agreement: {
    ...styles.agreement,
    margin: 0,
    paddingHorizontal: 20,
    maxWidth: 600,
    justifyContent: "flex-start"
  },
  checkboxLabel: {
    ...styles.checkboxLabel,
    flex: 1
  },
  regTypeDividerContainer: {
    ...styles.regTypeDividerContainer,
    width: "100%",
    maxWidth: 600,
    paddingHorizontal: 0,
    paddingVertical: 10
  }
};

export { styles };
