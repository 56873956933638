import React from "react";
import { Platform, Text, View } from "react-native";
import { observable } from "mobx";
import { observer, Observer } from "mobx-react";
import { Button, Divider } from "react-native-elements";
import { ThemedInput } from "../ThemedInput";
import Icon from "react-native-vector-icons/MaterialIcons";
import { theme } from "../../config/style-configs/theme";
import { UIText } from "../../config/lang-config";
import { preventDefaultStopProp } from "../../utils/helpers";

@observer
class ChatBottomBarView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  @observable inputHeight = 0;

  showEmoji =
    Platform.OS === "web" &&
    (window.navigator.userAgent.match(/Windows NT 10/g) ||
      window.navigator.userAgent.match(/Mac OS X 10_/g));

  setInputHeight = event => {
    const height =
      event.nativeEvent.contentSize && event.nativeEvent.contentSize.height;
    if (height && height <= this.props.styles.inputHeightCalculator.maxHeight)
      this.inputHeight = height;
  };

  reduceInputHeight = event => {
    const height = event.nativeEvent.layout && event.nativeEvent.layout.height;
    if (!isNaN(height) && this.inputHeight > height) this.inputHeight = height;
  };

  sendMessage = event => {
    preventDefaultStopProp(event);
    return this.props.sendMessage(() => this.input.focus());
  };

  handleKeyPress = event => {
    if (this.props.multiline) return;
    const { charCode, ctrlKey, shiftKey } = event;
    if ((!ctrlKey && !shiftKey) || charCode !== 13) return;
    return this.sendMessage();
  };

  getButtons = () =>
    [
      this.showEmoji && {
        name: "emoji",
        icon: "tag-faces",
        methodName: "handleEmojiPress"
      },
      {
        name: "camera",
        icon: "camera-alt",
        methodName: "handleCameraPress"
      },
      {
        name: "attachment",
        icon: "attach-file",
        methodName: "handleAttachmentPress"
      },
      {
        name: "line_switch",
        icon: this.props.multiline ? "wrap-text" : "keyboard-tab",
        methodName: "handleMultilineChange"
      }
      // {
      //   name: "double_check",
      //   icon: "done-all"
      // }
    ].filter(Boolean);

  renderButtons = buttons =>
    buttons.map(button => (
      <Observer key={button.name}>
        {() => (
          <Button
            containerStyle={this.props.styles.toolButtons}
            disabled={this.props.loading}
            type="clear"
            icon={
              <Icon
                name={button.icon}
                color={theme.color}
                size={theme.FONT_SIZE_XLARGE}
              />
            }
            onPress={() =>
              this.props.buttonMethods[button.methodName](() =>
                this.input.focus()
              )
            }
          />
        )}
      </Observer>
    ));

  renderInputHeightCalculator = () => {
    const value = this.props.currentInputValue || "";
    return (
      <Observer>
        {() => (
          <View
            style={this.props.styles.inputHeightCalculator}
            onLayout={this.reduceInputHeight}
          >
            <Text style={this.props.styles.inputHeightCalculatorText}>
              {value.replace(/(\r\n|\r|\n)$/i, "\n ")}
            </Text>
          </View>
        )}
      </Observer>
    );
  };

  render() {
    return (
      <View>
        <View style={this.props.styles.style}>
          <Divider />
          <View style={this.props.styles.top}>
            {this.renderButtons(this.getButtons())}
          </View>
          <Divider />
          <View style={this.props.styles.bottom}>
            <ThemedInput
              containerStyle={this.props.styles.chatInputContainer}
              inputStyle={{
                ...this.props.styles.chatInput,
                ...{ minHeight: this.inputHeight }
              }}
              ref={input => (this.input = input)}
              editable={!this.props.sendLoading && !this.props.loading}
              value={this.props.currentInputValue}
              multiline={true}
              blurOnSubmit={this.props.multiline}
              returnKeyType={!this.props.multiline ? "enter" : "send"}
              placeholder={
                !this.props.loading && `${UIText.chatInputPlaceholder}`
              }
              showCounter={false}
              onChange={this.props.handleInputChange}
              onKeyPress={this.handleKeyPress}
              onSubmitEditing={this.sendMessage}
              onContentSizeChange={this.setInputHeight}
            />
            <Button
              containerStyle={this.props.styles.chatSend}
              type="clear"
              icon={<Icon name="send" color={theme.color} size={25} />}
              loading={this.props.sendLoading}
              loadingProps={{ color: theme.color }}
              disabled={this.props.sendLoading}
              onPress={this.sendMessage}
            />
          </View>
        </View>
        {this.renderInputHeightCalculator()}
      </View>
    );
  }
}

export { ChatBottomBarView };
