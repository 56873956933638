import { formService } from "../../../../../cdm/services/form-service";
import {
  topicTypeIds,
  typeClassIds
} from "../../../../../config/variable-config";
import { stateController } from "../../../../../cdm/controllers/state-controller";
import { clientController } from "../../../../../cdm/controllers/client-controller";
import { isEmpty } from "../../../../../utils/helpers";
import { issueTemplateForm } from "./form";
import { observable } from "mobx";
import { UIText } from "../../../../../config/lang-config";

export const template = observable({
  title: ""
});

export const issueTemplateSetup = isSubTopic => {
  const title = "New Issue";
  return {
    title,
    heading: "Create a new Issue",
    checkboxTitle: title,
    form: issueTemplateForm(),
    renderFlags: ["setupRender"],
    editFlags: ["setupEdit"],
    onBlur: field => {
      field.errorMessage = "";
      if (field.required && !field.value && !field._disabled)
        field.errorMessage = `Enter a ${(
          field.label || field.placeholder
        ).toLowerCase()}`;
    },
    onSubmit: async ([form, errorFields], store) => {
      if (!form || !isEmpty(errorFields)) {
        return Promise.reject({
          message: UIText.checkEntriesWithFields(errorFields),
          contentAlign: "left"
        });
      }
      let data = formService.disassembleFormData(form);
      const description = data.title;
      template.title = data.title;
      data = JSON.stringify(data);

      const group = clientController.findGroupById(stateController.viewGroupId);
      if (isEmpty(group))
        return Promise.reject({
          message: `Unknown group creating Issue.`
        });

      const selfMember = group.members.find(
        m => m.userId === clientController.userId
      );
      if (isEmpty(selfMember))
        return Promise.reject({
          message: `Unknown member creating Issue.`
        });

      const topic = {
        creatorMemberId: selfMember.id,
        typeId: topicTypeIds.issue,
        onCalendar: 0,
        isTemplate: 0,
        isParentTemplate: 0,
        isCompleted: 0,
        isDataLocked: 0,
        isLocked: 0,
        description,
        groupId: group.id,
        parentId: isSubTopic ? stateController.viewTopicId : null,
        data,
        typeClassId: typeClassIds.issueTopic,
        typeClassVersion: 1 // Default for now.
      };

      return (store.topic = topic);
    },
    validate: async form => {
      const pass = formService.validateRequired(form);
      if (!pass) {
        const errorFields = form.filter(f => !!f.errorMessage);
        return [false, errorFields];
      }
      return [form];
    }
  };
};
