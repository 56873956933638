import { observable } from "mobx";
import { todoTimeAssigneeForm } from "./form";
import { clientController } from "../../../../../../../cdm/controllers/client-controller";
import { stateController } from "../../../../../../../cdm/controllers/state-controller";
import { getUTCOffsetMilli, isEmpty } from "../../../../../../../utils/helpers";
import {
  updateTopicActorMembers,
  updateTopicTime
} from "../../../../../../../cdm/lib/topic-utilities";

export const todoTimeAssigneeSetup = () => {
  const group = clientController.findGroupById(stateController.viewGroupId);
  const timezone = ((group.profile || {}).data || {}).timezone;
  const title = "New to-do";

  return {
    title: title,
    heading: "To-do options",
    checkboxTitle: title,
    form: observable(todoTimeAssigneeForm(group)),
    onSubmit: async (form, store) => {
      if (!form) {
        return Promise.reject({ message: "Please review your entries." });
      }

      const topic = store.topic;
      const errorMessage = "Previous step was unsuccessful.";
      if (isEmpty(topic))
        return Promise.reject(`${errorMessage}[todo_topic_non_exist]`);

      const assigneeMemberIds = form
        .filter(field => field.set === "assignee" && field.value)
        .map(field => field.name);

      let startTime = form.find(field => field.name === "startTime");
      let endTime = form.find(field => field.name === "endTime");
      startTime =
        startTime &&
        startTime.value &&
        new Date(
          new Date(startTime.value).getTime() -
            getUTCOffsetMilli(timezone, new Date(startTime.value))
        );
      endTime =
        endTime &&
        endTime.value &&
        new Date(
          new Date(endTime.value).getTime() -
            getUTCOffsetMilli(timezone, new Date(endTime.value))
        );

      if (assigneeMemberIds.length > 0) {
        return updateTopicActorMembers(
          group,
          topic,
          assigneeMemberIds,
          "assigned"
        ).then(() => {
          if (startTime || endTime) {
            return updateTopicTime(topic, startTime, endTime);
          }
          return Promise.resolve();
        });
      }
      return Promise.resolve();
    }
  };
};
