import React from "react";
import { Divider, Icon } from "react-native-elements";
import { observer } from "mobx-react";
import { UIText } from "../../../../config/lang-config";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger
} from "react-native-popup-menu";
import { common } from "../../../../config/style-configs/common";
import {
  groupTypeRoleIds,
  memberStatus
} from "../../../../config/variable-config";
import { isOwnerOrPrimaryCaregiverOrSupport } from "../../lib/group-utilities-mcb";
import { isEmpty } from "../../../../utils/helpers";

@observer
class GroupMemberMenuView extends React.Component {
  render() {
    const member = this.props.member;
    const isPaidCaregiver =
      member.roleList &&
      member.roleList.some(
        r => r.groupTypeRoleId === groupTypeRoleIds.paidCaregiver
      );
    const isOwnerOrPrimaryCaregiver = isOwnerOrPrimaryCaregiverOrSupport(
      null,
      this.props.selfMember
    );
    const menuItems = [
      this.props.IAmOwner && member.id === this.props.selfMember.id && (
        <MenuOption
          key={member.id + "changeOwner"}
          onSelect={() =>
            this.props.onMemberMenuSelect("changeOwner", member.id)
          }
          text={UIText.groupChangeOwner}
          customStyles={{
            optionWrapper: common.menuOptionWrapper,
            optionText: common.menuOptionText
          }}
        />
      ),
      (isOwnerOrPrimaryCaregiver || this.props.IAmOwner) && (
        <MenuOption
          key={member.id + "changeRole"}
          onSelect={() =>
            this.props.onMemberMenuSelect("changeRole", member.id)
          }
          text={UIText.groupChangeRole}
          customStyles={{
            optionWrapper: common.menuOptionWrapper,
            optionText: common.menuOptionText
          }}
        />
      ),
      Number(member.status) === memberStatus.invited && (
        <React.Fragment key="resend">
          <Divider />
          <MenuOption
            key={member.id + "resendInvite"}
            onSelect={() =>
              this.props.onMemberMenuSelect("resendInvite", member.id)
            }
            text={UIText.groupResendInvite}
            customStyles={{
              optionWrapper: common.menuOptionWrapper,
              optionText: common.menuOptionText
            }}
          />
        </React.Fragment>
      ),
      Number(member.status) === memberStatus.invited && (
        <MenuOption
          key={member.id + "cancelInvite"}
          onSelect={() =>
            this.props.onMemberMenuSelect("cancelInvite", member.id)
          }
          text={UIText.groupCancelInvite}
          customStyles={{
            optionWrapper: common.menuOptionWrapper,
            optionText: common.menuOptionText
          }}
        />
      ),
      (isOwnerOrPrimaryCaregiver || this.props.IAmOwner) &&
        Number(member.status) === memberStatus.normal &&
        isPaidCaregiver && (
          <MenuOption
            key={member.id + "changeHourlyRate"}
            onSelect={() =>
              this.props.onMemberMenuSelect("changeHourlyRate", member.id)
            }
            text={UIText.groupChangeHourlyRate}
            customStyles={{
              optionWrapper: common.menuOptionWrapper,
              optionText: common.menuOptionText
            }}
          />
        ),
      (isOwnerOrPrimaryCaregiver || this.props.IAmOwner) &&
        Number(member.status) === memberStatus.normal &&
        !!member.userId &&
        member.id !== this.props.selfMember.id && (
          <React.Fragment key="delete">
            <Divider />
            <MenuOption
              key={member.id + "deleteMember"}
              onSelect={() =>
                this.props.onMemberMenuSelect("deleteMember", member.id)
              }
              text={UIText.groupDeleteMember}
              customStyles={{
                optionWrapper: common.menuOptionWrapper,
                optionText: common.menuOptionText
              }}
            />
          </React.Fragment>
        )
    ].filter(Boolean);

    return isEmpty(menuItems) ? null : (
      <Menu>
        <MenuTrigger
          customStyles={{
            triggerOuterWrapper: {
              ...common.menuTriggerContainer,
              ...this.props.styles.button
            }
          }}
          children={<Icon name="more-vert" size={24} color="#666" />}
        />
        <MenuOptions
          customStyles={{
            optionsContainer: common.menuOptionContainer
          }}
        >
          {menuItems}
        </MenuOptions>
      </Menu>
    );
  }
}

export { GroupMemberMenuView };
