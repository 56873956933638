import { theme } from "../../../../config/style-configs/theme";

export const styles = {
  style: {
    width: "100%",
    flexDirection: "column",
    paddingStart: 5
  },
  item: {
    marginBottom: 8
  },
  text: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    color: "#000"
  },
  textColor: {
    color: theme.color
  }
};
