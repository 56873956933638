import React from "react";
import { Image, ScrollView, View } from "react-native";
import { Observer, observer } from "mobx-react";
import { Button, CheckBox, Text } from "react-native-elements";
import Icon from "react-native-vector-icons/MaterialIcons";
import { ThemedInput } from "../../../components/ThemedInput";
import { ThemedButton } from "../../../components/ThemedButton";
import { theme } from "../../../config/style-configs/theme";
import { registrationIntents } from "../../../custom/mcb/config/registration_intents";
import { capitalize, getKeyboardType, isEmpty } from "../../../utils/helpers";
import { UIText } from "../../../config/lang-config";
import { Form } from "../../../components/Form";
import { computedFn } from "mobx-utils";
import { action } from "mobx";
import { common } from "../../../config/style-configs/common";

@observer
class RegistrationScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
    this.input = {};
    this.rendered = false;
    this.caregiverFormFields = [];
    this.fieldProps = {
      showTitle: true,
      enabledGetter: computedFn(field => !field.disabled),
      valueGetter: computedFn(field =>
        field.value ? field.value : field._disabled ? "—" : ""
      ),
      onInputChange: action((e, field) => {
        this.controller.onCaregiverFormChange(e, field);
      }),
      onCheckboxChange: action(field => {
        field.value = !field.value;
      }),
      onPickerChange: action((value, field) => {
        field.value = value;
      }),
      onDateTimeChange: action((value, field) => {
        field.value = value;
      }),
      onEndEditing: action((form, field) => {
        this.controller.onCaregiverFormBlur(field);
      }),
      onBlur: action((form, field) => {
        this.controller.onCaregiverFormBlur(field);
      }),
      onSubmitEditing: action((form, field) => {
        this.controller.helpFocusNextField(
          field,
          this.caregiverFormFields,
          form
        );
      }),
      refSetter: (form, field, f) => {
        f && (f.name = field.name);
        this.caregiverFormFields[form.indexOf(field)] = f;
      }
      // fieldStyle: this.props.styles.field,
      // fieldLabelStyle: this.props.styles.inputTitle,
      // pickerStyle: this.props.styles.picker,
    };
  }

  componentDidMount() {
    return this.controller.componentDidMount();
  }

  renderRevealButton = field => (
    <Button
      containerStyle={this.props.styles.revealButton}
      type="clear"
      icon={<Icon size={24} color="#999" name="visibility" />}
      onPressIn={() => this.controller.reveal(field)}
      onPressOut={() => this.controller.reveal(field)}
      onPress={() => {}}
    />
  );

  renderIntentItems = items => {
    return items.map((item, i) => (
      <CheckBox
        key={i}
        title={item.intentText}
        containerStyle={{
          ...this.props.styles.intentCheckbox,
          ...(i === items.length - 1 && { marginTop: 20 })
        }}
        checkedIcon={<Icon name="check-box" size={24} color="black" />}
        uncheckedIcon={
          <Icon name="check-box-outline-blank" size={24} color="black" />
        }
        checked={this.controller.intentsChecked.includes(item.groupTypeRoleId)}
        checkedColor={theme.color}
        onPress={e => this.controller.handleIntentChange(e, item)}
      />
    ));
  };

  renderSetupForm = () => (
    <Observer>
      {() => (
        <Form
          containerStyle={this.props.styles.form}
          form={this.controller.caregiverForm}
          fieldProps={this.fieldProps}
          expandAll
          // showDisplayName
          renderFlags={["caregiver"]}
          editFlags={["caregiver"]}
          anchorDisabled
        />
      )}
    </Observer>
  );

  render() {
    if (!isEmpty(this.controller.form) && !this.rendered) {
      this.rendered = true;
      setTimeout(() => {
        for (let input in this.input) {
          if (this.input.hasOwnProperty(input))
            this.input[input].props.defaultValue && this.input[input].focus();
        }
      });
    }
    return (
      <View style={this.props.styles.style}>
        <ScrollView
          style={this.props.styles.scrollView}
          contentContainerStyle={this.props.styles.container}
        >
          <View style={this.props.styles.header}>
            <Image
              source={theme.loginLogo}
              style={this.props.styles.headerLogo}
            />
          </View>

          <View style={this.props.styles.questions}>
            <Text style={this.props.styles.questionHeader}>
              If you are a professional caregiver, click{" "}
              <Text
                style={common.link}
                onPress={this.controller.handleCaregiverLink}
              >
                here
              </Text>
              .
            </Text>
          </View>

          <View style={this.props.styles.fields}>
            <ThemedInput
              ref={input => {
                this.input.username = input;
              }}
              placeholder={UIText.registrationFieldNames.username}
              leftIcon={<Icon name="person" size={24} color="black" />}
              defaultValue={this.controller.autoUsername}
              returnKeyType="next"
              errorMessage={this.controller.error.username}
              onChange={e => {
                this.controller.updateFields("username", e);
              }}
              onEndEditing={e => {
                this.controller.updateFields("username", e);
                this.controller.validateRequired("username");
              }}
              onBlur={e => {
                this.input.username.props.onEndEditing(e);
              }}
              onSubmitEditing={() => {
                this.input.password.focus();
              }}
            />
          </View>

          <View style={this.props.styles.fields}>
            <ThemedInput
              // containerStyle={this.props.styles.halfLength}
              ref={input => {
                this.input.password = input;
              }}
              placeholder={UIText.registrationFieldNames.password}
              leftIcon={<Icon name="repeat" size={24} color="black" />}
              rightIcon={this.renderRevealButton("password")}
              returnKeyType="next"
              secureTextEntry={this.controller.visible.password}
              errorMessage={this.controller.error.password}
              onChange={e => {
                this.controller.updateFields("password", e);
                this.controller.validatePassword("password", true);
              }}
              onEndEditing={e => {
                this.controller.updateFields("password", e);
                this.controller.validatePassword("password");
              }}
              onBlur={e => {
                this.input.password.props.onEndEditing(e);
              }}
              onSubmitEditing={() => {
                this.input.repeat.focus();
              }}
            />
          </View>

          <View style={this.props.styles.fields}>
            <ThemedInput
              // containerStyle={this.props.styles.halfLength}
              ref={input => {
                this.input.repeat = input;
              }}
              placeholder={UIText.registrationFieldNames.repeat}
              leftIcon={<Icon name="repeat-one" size={24} color="black" />}
              rightIcon={this.renderRevealButton("repeat")}
              returnKeyType="next"
              secureTextEntry={this.controller.visible.repeat}
              errorMessage={this.controller.error.repeat}
              onChange={e => {
                this.controller.updateFields("repeat", e);
                this.controller.validatePassword("repeat", true);
              }}
              onEndEditing={e => {
                this.controller.updateFields("repeat", e);
                this.controller.validatePassword("repeat");
              }}
              onBlur={e => {
                this.input.repeat.props.onEndEditing(e);
              }}
              onSubmitEditing={() => {
                this.input.email.focus();
              }}
            />
          </View>

          <View style={this.props.styles.fields}>
            <ThemedInput
              ref={input => {
                this.input.email = input;
              }}
              placeholder={UIText.registrationFieldNames.email}
              keyboardType={getKeyboardType({ name: "email" })}
              leftIcon={<Icon name="email" size={24} color="black" />}
              defaultValue={this.controller.autoEmail}
              returnKeyType="next"
              errorMessage={this.controller.error.email}
              onChange={e => {
                this.controller.updateFields("email", e);
              }}
              onEndEditing={e => {
                this.controller.updateFields("email", e);
                this.controller.validateRequired("email");
              }}
              onBlur={e => {
                this.input.email.props.onEndEditing(e);
              }}
              onSubmitEditing={() => {
                this.input.phone.focus();
              }}
            />
          </View>

          <View style={this.props.styles.fields}>
            <ThemedInput
              ref={input => {
                this.input.phone = input;
              }}
              placeholder={`${
                UIText.registrationFieldNames.phone
              } (${capitalize(UIText.optional)})`}
              keyboardType={getKeyboardType({ name: "phone" })}
              leftIcon={<Icon name="phone" size={24} color="black" />}
              defaultValue={this.controller.autoPhone}
              returnKeyType="next"
              onChange={e => {
                this.controller.updateFields("phone", e);
              }}
              onEndEditing={e => {
                this.controller.updateFields("phone", e);
              }}
              onBlur={e => {
                this.input.phone.props.onEndEditing(e);
              }}
              onSubmitEditing={() => {
                this.input.firstName.focus();
              }}
            />
          </View>

          <View style={this.props.styles.fields}>
            <ThemedInput
              // containerStyle={this.props.styles.halfLength}
              ref={input => {
                this.input.firstName = input;
              }}
              placeholder={UIText.registrationFieldNames.firstName}
              leftIcon={<Icon name="first-page" size={24} color="black" />}
              defaultValue={this.controller.autoFirstName}
              errorMessage={this.controller.error.firstName.replace(
                "Name",
                " name"
              )}
              returnKeyType="next"
              onChange={e => {
                this.controller.updateFields("firstName", e);
              }}
              onEndEditing={e => {
                this.controller.updateFields("firstName", e);
                this.controller.validateRequired("firstName");
              }}
              onBlur={e => {
                this.input.firstName.props.onEndEditing(e);
              }}
              onSubmitEditing={() => {
                this.input.lastName.focus();
              }}
            />
          </View>

          <View style={this.props.styles.fields}>
            <ThemedInput
              // containerStyle={this.props.styles.halfLength}
              ref={input => {
                this.input.lastName = input;
              }}
              placeholder={UIText.registrationFieldNames.lastName}
              leftIcon={<Icon name="last-page" size={24} color="black" />}
              defaultValue={this.controller.autoLastName}
              returnKeyType="next"
              errorMessage={this.controller.error.lastName.replace(
                "Name",
                " name"
              )}
              onChange={e => {
                this.controller.updateFields("lastName", e);
              }}
              onEndEditing={e => {
                this.controller.updateFields("lastName", e);
                this.controller.validateRequired("lastName");
              }}
              onBlur={e => {
                this.input.lastName.props.onEndEditing(e);
              }}
              onSubmitEditing={this.controller.execRegister}
            />
          </View>

          {(!this.controller.autoIntent ||
            this.controller.autoIntentShowCheckbox) && (
            <View style={this.props.styles.questions}>
              <Text style={this.props.styles.questionHeader}>
                Please confirm the following:
              </Text>
              {this.renderIntentItems(
                registrationIntents.filter(intent => intent.enabled)
              )}
            </View>
          )}

          {this.controller.hasOtherServiceProvider && (
            <View style={this.props.styles.questions}>
              <Text style={this.props.styles.questionHeader}>
                Please complete the following:
              </Text>
              {this.renderSetupForm()}
            </View>
          )}

          <View style={this.props.styles.agreement}>
            <CheckBox
              containerStyle={this.props.styles.checkbox}
              checkedIcon={<Icon name="check-box" size={24} color="black" />}
              uncheckedIcon={
                <Icon name="check-box-outline-blank" size={24} color="black" />
              }
              checked={this.controller.agreeChecked}
              checkedColor={theme.color}
              onPress={this.controller.handleCheckboxChange}
            />
            <View style={this.props.styles.checkboxLabel}>
              <Text
                style={this.props.styles.checkboxLabelText}
                onPress={this.controller.handleCheckboxChange}
              >
                I have read and agree to
              </Text>
              <Text>
                <Text
                  style={this.props.styles.checkboxLabelLink}
                  onPress={this.controller.openAgreement}
                >
                  Terms and Conditions
                </Text>
                <Text>.</Text>
              </Text>
            </View>
          </View>

          <ThemedButton
            style={this.props.styles.button}
            disabled={
              this.controller.buttonDisabled || this.controller.registering
            }
            title={UIText.registrationAction}
            loading={this.controller.registering}
            onPress={this.controller.execRegister}
          />

          <View style={this.props.styles.links}>
            <Text
              style={this.props.styles.link}
              onPress={e => {
                this.controller.handleLinkPress("login", e);
              }}
            >
              {UIText.registrationKnowAccount}
            </Text>
          </View>
        </ScrollView>
      </View>
    );
  }
}

export { RegistrationScreen };

//Lang WIP
