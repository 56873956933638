import React from "react";
import { observer } from "mobx-react";
import { createSwitchNavigator } from "@react-navigation/core";
import {
  routeConfig,
  webNavigatorConfig
} from "../../../config/navigator-configs/group-main-navigator-config";
import { UIText } from "../../../config/lang-config";

const GroupTopicNavigator = createSwitchNavigator(
  routeConfig,
  webNavigatorConfig
);

@observer
class GroupTopic extends React.Component {
  static router = GroupTopicNavigator.router;

  // Need to move this into nested screens.
  static navigationOptions = {
    title: UIText.title
  };

  render() {
    const { navigation } = this.props;

    // NavigationService.setTopLevelNavigator(navigation);

    return <GroupTopicNavigator navigation={navigation} />;
  }
}

export { GroupTopic };
