import { common } from "../../../../config/style-configs/common";
import { theme } from "../../../../config/style-configs/theme";

export const styles = {
  card: common.card,
  cardContent: common.cardChildrenCompact,
  cardContentLarge: common.cardChildrenTextLarge,
  cardContentMedium: common.cardChildrenTextMedium,
  cardContentSmall: {
    ...common.cardChildrenTextSmall,
    fontSize: theme.FONT_SIZE_SMALL + 1
  }
};
