import { Observer, observer } from "mobx-react";
import React from "react";
import {
  ActivityIndicator,
  Platform,
  RefreshControl,
  ScrollView,
  View
} from "react-native";
import { Divider, Text } from "react-native-elements";
import { theme } from "../../../../config/style-configs/theme";
import { ShiftCard } from "../ShiftCard";
import {
  getTopicCompletionMember,
  getTopicCompletionState
} from "../../../../cdm/lib/topic-utilities";
import { CollapsibleAccordionTitle } from "../../../../components/CollapsibleAccordionTitle";
import { UIText } from "../../../../config/lang-config";
import Collapsible from "react-native-collapsible";
import { capitalize, isEmpty } from "../../../../utils/helpers";
import { TopicCard } from "../../../../components/TopicCard";

const EmptyLastShiftCard = observer(props => {
  return (
    <TopicCard
      contentStyle={props.styles.cardCompactContent}
      compact={true}
      icon="clipboard-text"
      iconType="material-community"
      title={UIText.groupSummaryNoLastShift}
      text="⁠—"
    />
  );
});

@observer
class CareReceiverSmryListView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  renderLastCompletedShift = topic => {
    const clockState = getTopicCompletionState(topic);
    const clocker = getTopicCompletionMember(topic) || topic.performer;

    return (
      <Observer key={topic.id}>
        {() => (
          <View style={this.props.styles.summarySections}>
            <CollapsibleAccordionTitle
              title={UIText.groupSummaryLastCompletedShift}
              onTitlePress={() => this.controller.handleCollapse("lastShift")}
              collapsed={this.controller.collapsible["lastShift"]}
            />
            <Collapsible
              style={{ alignItems: "center" }}
              collapsed={!!this.controller.collapsible["lastShift"]}
            >
              <View style={this.props.styles.card}>
                {isEmpty(this.controller.lastCompletedShift) ? (
                  <EmptyLastShiftCard styles={this.props.styles} />
                ) : (
                  <ShiftCard
                    timezone={this.controller.timezone}
                    contentStyle={this.props.styles.cardCompactContent}
                    shift={topic}
                    clockState={clockState}
                    clockers={[clocker]}
                    couldClockInOut={false}
                    onPress={e => this.controller.handleTopicPress(topic)}
                  />
                )}
              </View>
            </Collapsible>
          </View>
        )}
      </Observer>
    );
  };

  renderQtyOfPendingIssues = data => {
    data = {
      pending: data["numberOfPendingIssues"],
      completed: data["numberOfCompletedIssues"],
      total: data["numberOfTotalIssues"]
    };

    const PendingIssuesComponent = (
      <View
        style={{
          ...this.props.styles.cardAvatarContainer,
          backgroundColor: data.pending > 0 ? theme.color : "#bdbdbd"
        }}
      >
        <Text style={this.props.styles.cardAvatarText} numberOfLines={1}>
          {data.pending > 99 ? "99+" : data.pending ? data.pending : 0}
        </Text>
      </View>
    );

    const children = (
      <View style={this.props.styles.cardContent}>
        <Text
          style={this.props.styles.cardContentMedium}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {UIText.groupSummaryUnresolvedIssues}
        </Text>
      </View>
    );

    return (
      <Observer>
        {() => (
          <View style={this.props.styles.summarySections}>
            <CollapsibleAccordionTitle
              title={UIText.groupSummaryPendingIssues}
              onTitlePress={() =>
                this.controller.handleCollapse(UIText.groupSummaryPendingIssues)
              }
              collapsed={
                this.controller.collapsible[UIText.groupSummaryPendingIssues]
              }
            />
            <Collapsible
              style={{ alignItems: "center" }}
              collapsed={
                !!this.controller.collapsible[UIText.groupSummaryPendingIssues]
              }
            >
              <View style={this.props.styles.card}>
                <TopicCard
                  contentStyle={this.props.styles.cardCompactContent}
                  compact={true}
                  avatarComponent={PendingIssuesComponent}
                  title={UIText.groupSummaryPendingIssues}
                  onPress={this.controller.handleIssueCardPress}
                  children={children}
                />
              </View>
            </Collapsible>
          </View>
        )}
      </Observer>
    );
  };

  renderShiftsAndTasksCount = data => {
    const {
      collapsibleTitle,
      totalShifts,
      totalTasksMandatory,
      completedTasksMandatory,
      totalTasksOptional,
      completedTasksOptional
    } = data;

    const ShiftComponent = (
      <View
        style={{
          ...this.props.styles.cardAvatarContainer,
          backgroundColor: theme.generalNormalColor
        }}
      >
        <Text style={this.props.styles.cardAvatarText} numberOfLines={1}>
          {totalShifts && totalShifts.toString()}
        </Text>
      </View>
    );

    const avatarAppendix = (
      <Text style={this.props.styles.cardAvatarAppendix}>
        {capitalize(UIText.shiftMakerShifts)}
      </Text>
    );

    const children = (
      <View style={this.props.styles.cardContent}>
        <View style={this.props.styles.cardContentText}>
          <Text
            style={this.props.styles.cardContentMedium}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {UIText.groupSummaryMdtTaskCompleted}
          </Text>
          <Text
            style={{
              ...this.props.styles.cardContentLarge,
              color: theme.color
            }}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {`${completedTasksMandatory}/${totalTasksMandatory}`}
          </Text>
        </View>
        <Divider style={this.props.styles.divider} />
        <View style={this.props.styles.cardContentText}>
          <Text
            style={this.props.styles.cardContentMedium}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {UIText.groupSummaryOptTaskCompleted}
          </Text>
          <Text
            style={{
              ...this.props.styles.cardContentLarge,
              color: theme.color
            }}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {`${completedTasksOptional}/${totalTasksOptional}`}
          </Text>
        </View>
      </View>
    );

    return (
      <Observer>
        {() => (
          <View style={this.props.styles.summarySections}>
            <CollapsibleAccordionTitle
              title={collapsibleTitle}
              onTitlePress={() =>
                this.controller.handleCollapse(collapsibleTitle)
              }
              collapsed={this.controller.collapsible[collapsibleTitle]}
            />
            <Collapsible
              style={{ alignItems: "center" }}
              collapsed={!!this.controller.collapsible[collapsibleTitle]}
            >
              <View style={this.props.styles.card}>
                <TopicCard
                  compact={true}
                  avatarComponent={ShiftComponent}
                  avatarAppendix={avatarAppendix}
                  title={`${
                    UIText.groupSummaryTotalShifts
                  } ${collapsibleTitle.toLowerCase()}`}
                  onPress={this.controller.handleStatCardPress}
                  children={children}
                />
              </View>
            </Collapsible>
          </View>
        )}
      </Observer>
    );
  };

  renderWeekShiftsAndTasksCount = data => {
    const props = {
      collapsibleTitle: UIText.groupSummaryWeek,
      totalShifts: data["numberOfShiftsThisWeek"] || 0,
      totalTasksMandatory: data["numberOfTotalMandatoryTasksThisWeek"] || 0,
      completedTasksMandatory:
        data["numberOfCompletedMandatoryTasksThisWeek"] || 0,
      totalTasksOptional: data["numberOfTotalOptionalTasksThisWeek"] || 0,
      completedTasksOptional:
        data["numberOfCompletedOptionalTasksThisWeek"] || 0
    };

    return this.renderShiftsAndTasksCount(props);
  };

  renderMonthShiftsAndTasksCount = data => {
    const props = {
      collapsibleTitle: UIText.groupSummaryMonth,
      totalShifts: data["numberOfShiftsThisMonth"] || 0,
      totalTasksMandatory: data["numberOfTotalMandatoryTasksThisMonth"] || 0,
      completedTasksMandatory:
        data["numberOfCompletedMandatoryTasksThisMonth"] || 0,
      totalTasksOptional: data["numberOfTotalOptionalTasksThisMonth"] || 0,
      completedTasksOptional:
        data["numberOfCompletedOptionalTasksThisMonth"] || 0
    };

    return this.renderShiftsAndTasksCount(props);
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        {this.controller.loading && Platform.OS === "web" ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          <ScrollView
            contentContainerStyle={this.props.styles.scrollView}
            refreshControl={
              <RefreshControl
                refreshing={
                  this.controller.refreshing || this.controller.loading
                }
                onRefresh={this.controller.onRefresh}
              />
            }
          >
            <View style={this.props.styles.scrollViewContent}>
              {this.renderLastCompletedShift(
                this.controller.lastCompletedShift
              )}
              {this.renderQtyOfPendingIssues(this.controller.summaryData)}
              {this.renderWeekShiftsAndTasksCount(this.controller.summaryData)}
              {this.renderMonthShiftsAndTasksCount(this.controller.summaryData)}
            </View>
          </ScrollView>
        )}
      </View>
    );
  }
}

export { CareReceiverSmryListView };
