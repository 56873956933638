import { Platform } from 'react-native';
import { theme } from './theme';

export const linkCursor = Platform.select({
  ios: {},
  android: {},
  web: { cursor: "pointer" }
});

export const common = {
  // Dynamic style native nono
  formField: {
    width: "100%",
    paddingVertical: 10,
    maxWidth: 600,
    flexDirection: "column",
    alignItems: "flex-start"
  },
  link: {
    color: theme.color,
    ...linkCursor,
    textDecorationLine: "underline"
  },
  linkBlue: {
    color: theme.generalNormalColor,
    ...linkCursor,
    textDecorationLine: "underline"
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  divider: {
    backgroundColor: theme.color,
    height: 2
  },

  scrollView: {
    ...(Platform.OS === "web" && { flex: 1 }),
    width: "100%"
  },
  scrollViewContent: {
    padding: 10
  },
  scrollViewCollapsible: {
    alignItems: "center"
  },
  scrollViewCollapsibleCards: {
    width: "100%",
    maxWidth: 770
  },
  scrollViewCard: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "flex-start",
    alignItems: "center",
    justifyContent: "center"
  },

  buttonTitleContainer: {
    flex: 1,
    alignItems: "flex-start"
  },
  buttonTitle: {
    color: theme.color,
    fontSize: theme.FONT_SIZE_LARGE,
    textAlign: "left"
  },

  card: {
    width: "100%",
    maxWidth: 385,
    padding: 10
  },
  cardChildrenCompact: {
    flex: 1,
    paddingLeft: 15
  },
  cardChildrenTextXLarge: {
    fontSize: theme.FONT_SIZE_XLARGE,
    fontWeight: "600"
  },
  cardChildrenTextLarge: {
    fontSize: theme.FONT_SIZE_LARGE,
    fontWeight: "600"
  },
  cardChildrenTextMedium: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    fontWeight: "600"
  },
  cardChildrenTextSmall: {
    fontSize: theme.FONT_SIZE_SMALL
  },

  listButtonContainer: {
    padding: 10,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start"
  },
  listButtonContainerWithHelp: {
    flexDirection: "row",
    alignItems: "center"
  },
  listButtonReminderContainer: {
    paddingBottom: 10,
    paddingHorizontal: 10
  },

  listRefreshButton: {
    flex: 1,
    minWidth: 40,
    alignItems: "flex-end"
  },

  containerErrorMessage: {
    textAlign: "center",
    fontSize: theme.FONT_SIZE_MEDIUM,
    color: "#666"
  },

  picker: {
    flex: 1,
    minWidth: 200,
    marginHorizontal: 12,
    minHeight: 40
  },
  pickerLabel: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    marginHorizontal: 10,
    color: theme.color,
    marginBottom: 10
  },

  headingPickerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  headingPickerLabelContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  headingPickerLabel: {
    color: theme.color,
    fontSize: theme.FONT_SIZE_LARGE
  },
  headingPicker: {
    flex: 1,
    height: 30,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10
  },

  menuTriggerContainer: {
    justifyContent: "center"
  },
  menuOptionContainer: {
    borderRadius: 5,
    overflow: "hidden"
  },
  menuOptionWrapper: {
    backgroundColor: "3fff",
    paddingLeft: 10,
    justifyContent: "center",
    height: 50
  },
  menuOptionText: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    color: "#000"
  },

  textIconContainer: {
    marginHorizontal: 5
  }
};
