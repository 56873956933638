import { serverConfig } from "./api-config";
import { minutesToMilli } from "../utils/helpers";

export const txConfig = {
  interval: 5000,
  // fullResetOnLaunch: env === "prod",
  fullResetOnLaunch: false,
  baseUrl: `${serverConfig.https ? "https://" : "http://"}${
    serverConfig.hostname
  }${
    serverConfig.port !== 443 || serverConfig.port !== 80
      ? `:${serverConfig.port}`
      : ""
  }`,
  syncUnreadMessagesInterval: minutesToMilli(10) // Every 10 minutes
};

txConfig.baseSwitch = baseUrl => (txConfig.baseUrl = baseUrl);
