import { theme } from "../../config/style-configs/theme";
import { common } from "../../config/style-configs/common";
import { Platform } from "react-native";

export const styles = {
  style: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fcfcfc"
  },
  header: {
    flex: 1,
    minHeight: 150,
    maxHeight: 150,
    alignItems: "center",
    justifyContent: "center"
  },
  headerLogo: {
    width: 300,
    height: 71
  },
  scrollView: {
    flex: 1,
    width: "100%"
  },
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  loading: common.loading,
  fields: {
    ...common.formField,
    padding: 10
  },
  pickerFields: {
    alignItems: "center",
    flexDirection: "row",
    flex: 1
  },
  halfLength: {
    width: "50%"
  },
  input: {
    ...common.input
  },
  inputLeftIcon: {
    ...common.inputLeftIcon
  },
  inputTitle: {
    marginLeft: 5,
    marginBottom: 10,
    color: theme.color,
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  questions: {
    width: "100%",
    maxWidth: 600,
    padding: 20,
    alignItems: "center"
  },
  questionHeader: {
    fontSize: theme.FONT_SIZE_LARGE,
    color: theme.color,
    marginBottom: 10
  },
  picker: {
    width: "100%",
    // maxWidth: 400,
    padding: 5,
    marginLeft: 10
  },
  agreement: {
    width: "100%",
    margin: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  checkbox: {
    marginLeft: 0,
    paddingLeft: 0
  },
  checkboxLabel: {
    alignItems: "flex-start"
  },
  checkboxLabelText: {
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  checkboxLabelLink: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    ...common.link
  },
  intentCheckbox: {
    minWidth: 250,
    paddingLeft: 20
  },
  button: {
    marginTop: 20,
    maxWidth: 250
  },
  links: {
    marginTop: 20,
    marginBottom: 30,
    alignItems: "center"
  },
  link: {
    fontSize: theme.FONT_SIZE_LARGE,
    margin: 10,
    ...common.link
  },
  revealButton: Platform.select({
    ios: {
      //,
    },
    android: {
      //,
    },
    web: {
      outlineStyle: "none"
    }
  }),
  divider: {
    marginTop: 20,
    width: "100%"
  },
  regTypeDivider: {
    flex: 0.4
  },
  regTypeDividerContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 50
  },
  regTypeDividerText: {
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  footerNoteContainer: {
    padding: 15
  },
  footerNote: {
    fontSize: theme.FONT_SIZE_SMALL,
    color: theme.color
  }
};
