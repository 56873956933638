import React from "react";
import { observer } from "mobx-react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import { capitalize, safeParseJSON } from "../../../../utils/helpers";
import { UIText } from "../../../../config/lang-config";
import { TopicCard } from "../../../../components/TopicCard";
import { getTopicCompletionState } from "../../../../cdm/lib/topic-utilities";
import { theme } from "../../../../config/style-configs/theme";

export const TaskCardView = observer(props => {
  const { task, couldComplete, onPress, detailCompleted, timezone } = props;

  const timeZone = timezone;

  const data = (task.data && safeParseJSON(task.data)) || {};
  const completionState = task["isCompleted"]
    ? UIText.topicCompleted
    : UIText.topicNotCompleted;
  const clockState = getTopicCompletionState(task);
  const iconBackgroundColor =
    clockState === "new"
      ? theme.generalNormalColor
      : clockState === "clocked-in"
      ? theme.color
      : "";

  const children = (
    <View style={props.styles.cardContent}>
      <Text
        style={props.styles.cardContentLarge}
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {capitalize(data.taskType)}
      </Text>
      <Text
        style={props.styles.cardContentMedium}
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {capitalize(completionState)}
      </Text>
      <Text
        style={props.styles.cardContentSmall}
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {detailCompleted || ""}
      </Text>
      <Text
        style={props.styles.cardContentSmall}
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {`${UIText.updatedAt}: ${new Date(task.updateTime).toLocaleString(
          "en-CA",
          { timeZone }
        )}`}
      </Text>
    </View>
  );

  const completionIcon = task.isCompleted
    ? "check"
    : couldComplete
    ? "check-outline"
    : "";
  const completionIconColor = task.isCompleted ? theme.color : undefined;

  const icons = [
    completionIcon && {
      key: "complete",
      name: completionIcon,
      color: completionIconColor,
      onPress: !task.isCompleted && props.onIconPress,
      loading: task.pending
    }
  ].filter(Boolean);

  return (
    <View style={props.styles.card}>
      <TopicCard
        compact={true}
        contentStyle={props.contentStyle}
        title={task.description || data.title}
        image={data.image}
        icon={!data.image && "clipboard-text-outline"}
        iconBackgroundColor={iconBackgroundColor}
        iconType="material-community"
        customIcons={icons}
        onPress={onPress}
        children={children}
      />
    </View>
  );
});
