import { observer } from "mobx-react";
import { Text, View } from "react-native";
import React from "react";
import { theme } from "../../config/style-configs/theme";

export const GenericTableView = observer(props => {
  const { rows, columns } = props;

  const tableHeaders = (
    <View style={props.styles.tableRow}>
      {columns.map((column, i) => (
        <View
          style={{
            ...props.styles.tableCell,
            ...props.cellStyle
          }}
          key={i}
        >
          <Text style={{ fontSize: theme.FONT_SIZE_MEDIUM, fontWeight: "600" }}>
            {column}
          </Text>
        </View>
      ))}
    </View>
  );

  const tableBody = (
    <View>
      {rows.map((row, i) => (
        <View key={i} style={props.styles.tableRow}>
          {columns.map((column, i) => (
            <View
              style={{
                ...props.styles.tableCell,
                ...props.cellStyle
              }}
              key={i}
            >
              <Text style={props.styles.text}>{row[column]}</Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  );

  return (
    <View style={props.styles.table}>
      {tableHeaders}
      {tableBody}
    </View>
  );
});
