import React from "react";
import { AdminRatingReviewPageController } from "./controller";
import { AdminRatingReviewPageView } from "./view";
import { styles } from "./styles";

export class AdminRatingReviewPage extends React.Component {
  render() {
    const controller = new AdminRatingReviewPageController(this.props);

    return (
      <AdminRatingReviewPageView
        controller={controller}
        {...this.props}
        styles={styles}
      />
    );
  }
}
