import { computed, observable } from "mobx";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { clientController } from "../../../../cdm/controllers/client-controller";
import {
  serviceRecipientGroupTypeIds,
  topicTypeIds,
  typeClassIds
} from "../../../../config/variable-config";
import { getTopicCompletionState } from "../../../../cdm/lib/topic-utilities";
import {
  capitalize,
  formatFixedDigit,
  getBrowserTimeZoneName,
  getDatesInBetween,
  getFullDayNameEng,
  getTimeZoneLongName,
  getUTCOffsetMilli,
  hoursToMilli,
  isEmpty,
  yyyymmdd
} from "../../../../utils/helpers";
import { theme } from "../../../../config/style-configs/theme";
import { UIText } from "../../../../config/lang-config";
import { apiController } from "../../../../cdm/controllers/api-controller";
import { apiService } from "../../../../cdm/services/api-service";
import { endpointConfig } from "../../../../config/api-config";
import { filterController } from "../../../../cdm/controllers/filter-controller";
import NavigationService from "../../../../utils/navigation-service";
import { formService } from "../../../../cdm/services/form-service";
import { getPayrollSummaryPeriods } from "../../config/payroll_periods";
import { ShiftTaskController } from "../../lib/shift-task-controller";

export class ShiftCalendarController {
  @observable loading = true;
  @observable timeSheets = [];

  topicTypeId = topicTypeIds.shift;

  @computed get groupId() {
    return stateController.viewGroupId;
  }
  @computed get group() {
    return clientController.findGroupById(this.groupId);
  }
  @computed get selfMember() {
    return (
      (Array.isArray(this.group.members) &&
        this.group.members.find(m => m.userId === clientController.userId)) ||
      {}
    );
  }
  @computed get profile() {
    return this.selfMember.profile || {};
  }
  @computed get topics() {
    return this.props.getTopics() || [];
  }
  @computed get approvedTimeSheets() {
    return this.timeSheets.filter(t => t.isLocked);
  }
  @computed get lockedDates() {
    return this.approvedTimeSheets.map(t => ({
      start: t.startTime,
      end: t.endTime
    }));
  }
  @computed get allowedPlotPeriod() {
    const payrollPeriods = getPayrollSummaryPeriods({
      offsetEndToDayEnd: true
    });
    if (!Array.isArray(payrollPeriods) || payrollPeriods.length === 0)
      return {};
    const currentPeriod = payrollPeriods[payrollPeriods.length - 1];
    return {
      start: currentPeriod.start,
      end: currentPeriod.end
    };
  }
  @computed get clockedInTopics() {
    return this.topics.filter(t => getTopicCompletionState(t) === "clocked-in");
  }
  @computed get clockedOutTopics() {
    return this.topics.filter(
      t => getTopicCompletionState(t) === "clocked-out"
    );
  }
  @computed get mode() {
    if (!stateController.viewGroupState.shiftCalendarMode[this.groupId]) {
      stateController.viewGroupState.shiftCalendarMode[this.groupId] = "month";
    }
    return stateController.viewGroupState.shiftCalendarMode[this.groupId];
  }
  @computed get markedDatesMonth() {
    const markedDates = {
      [yyyymmdd(new Date(), "-")]: {
        selected: true,
        selectedColor: "#ffffff00"
      }
    };

    // console.log(newTopics.map(t => yyyymmdd(new Date(t.startTime), "-")));
    const clockedInDates = this.clockedInTopics.map(t =>
      yyyymmdd(
        new Date(
          t.execStartTime + this.timezoneOffset(new Date(t.execStartTime))
        ),
        "-"
      )
    );
    const clockedOutDates = this.clockedOutTopics
      .map(
        t =>
          !t.onCalendar &&
          yyyymmdd(
            new Date(
              (t.correctedEndTime || t.execEndTime) +
                this.timezoneOffset(
                  new Date(t.correctedEndTime || t.execEndTime)
                )
            ),
            "-"
          )
      )
      .filter(Boolean);
    const simpleShiftDates = this.clockedOutTopics
      .map(
        t =>
          t.onCalendar &&
          yyyymmdd(
            new Date(
              (t.correctedEndTime || t.execEndTime) +
                this.timezoneOffset(
                  new Date(t.correctedEndTime || t.execEndTime)
                )
            ),
            "-"
          )
      )
      .filter(Boolean);
    const allowedPlottingDates = getDatesInBetween(
      this.allowedPlotPeriod.start,
      this.allowedPlotPeriod.end
    ).map(d => yyyymmdd(d, "-"));

    const clockedInDot = { color: theme.color };
    const clockedOutDot = { color: "#999" };
    const SimpleShiftDot = { color: "#666" };

    for (const date of allowedPlottingDates) {
      if (!markedDates[date]) markedDates[date] = {};
      markedDates[date].disabled = true;
    }
    for (const date of clockedInDates) {
      if (!markedDates[date]) markedDates[date] = {};
      if (!Array.isArray(markedDates[date].dots)) markedDates[date].dots = [];
      markedDates[date].dots.push(clockedInDot);
    }
    for (const date of clockedOutDates) {
      if (!markedDates[date]) markedDates[date] = {};
      if (!Array.isArray(markedDates[date].dots)) markedDates[date].dots = [];
      markedDates[date].dots.push(clockedOutDot);
    }
    for (const date of simpleShiftDates) {
      if (!markedDates[date]) markedDates[date] = {};
      if (!Array.isArray(markedDates[date].dots)) markedDates[date].dots = [];
      markedDates[date].dots.push(SimpleShiftDot);
    }

    return markedDates;
  }
  @computed get markedDatesWeek() {
    const clockedInDates = this.clockedInTopics.map(t => {
      const start = t.execStartTime;
      const end = t.execStartTime + hoursToMilli(1);
      return {
        eventName: t.description,
        start,
        end,
        color: theme.color,
        onPress: () => this.handleShiftPress(t)
      };
    });
    const clockedOutDates = this.clockedOutTopics.map(t => ({
      eventName: t.description,
      start: t.execStartTime,
      end: t.correctedEndTime || t.execEndTime,
      color: t.onCalendar ? "#666" : "#999",
      onPress: () => this.handleShiftPress(t)
    }));
    return [...clockedInDates, ...clockedOutDates];
  }
  @computed get markedBlocksWeek() {
    const allowedPlottingDates = getDatesInBetween(
      this.allowedPlotPeriod.start,
      this.allowedPlotPeriod.end
    ).map(d => yyyymmdd(d, "-"));
    return allowedPlottingDates.map(d => ({
      date: d,
      allDay: true,
      noHover: false,
      customStyles: {
        backgroundColor: "#fff"
      }
    }));
  }
  @computed get calendarCurrent() {
    if (!stateController.viewGroupState.shiftCalendarCurrent[this.groupId]) {
      stateController.viewGroupState.shiftCalendarCurrent[
        this.groupId
      ] = this.currentDate;
    }
    return stateController.viewGroupState.shiftCalendarCurrent[this.groupId];
  }
  @computed get weekCurrent() {
    return new Date(`${this.calendarCurrent}T00:00:00`);
  }
  @computed get dateChanged() {
    return this.calendarCurrent !== this.currentDate;
  }

  @computed get timezone() {
    if (!serviceRecipientGroupTypeIds.includes(this.group.typeId))
      return getBrowserTimeZoneName();
    return (
      ((this.group.profile || {}).data || {}).timezone ||
      getBrowserTimeZoneName()
    );
  }

  get currentDate() {
    return yyyymmdd(new Date(), "-");
  }

  constructor(props) {
    this.props = props;
    this._initialize()
      .then(() => (this.loading = false))
      .catch(err => {
        this._showError(err);
        return this.handleShiftListPress(null);
      });
  }

  _showError = err => {
    console.warn(err);
    return stateController.showPopup({
      title: capitalize(UIText.shiftCalendar),
      content: (err && err.message) || err,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: stateController.dismissPopup
    });
  };

  _initialize = async () => {
    return this._getTimeSheets();
  };

  _getTimeSheets = async () =>
    apiController
      .getGroupTopicsByTypeId(
        this.groupId,
        topicTypeIds.timesheet,
        false,
        false
      )
      .then(timeSheets => (this.timeSheets = timeSheets));

  timezoneOffset(date) {
    return getUTCOffsetMilli(this.timezone, date);
  }

  handleShiftListPress = event => {
    event && this.setAllShiftsFilter("");
    stateController.viewGroupState.shiftListMode[this.groupId] = "shifts";
  };

  handleShiftPress = shift => {
    const { id } = shift;

    stateController.viewTopicId = id;
    NavigationService.navigate("Topic", { topic: id });
  };

  handleModeSwitchPress = (event, mode) => {
    stateController.viewGroupState.shiftCalendarMode[this.groupId] = mode;
  };

  handleTodayPress = event => {
    stateController.viewGroupState.shiftCalendarCurrent[
      this.groupId
    ] = this.currentDate;
  };

  dateHasTopics = dateString => {
    const topicTimestamps = [
      ...this.clockedInTopics.map(t =>
        yyyymmdd(
          new Date(
            t.execStartTime + this.timezoneOffset(new Date(t.execStartTime))
          ),
          "-"
        )
      ),
      ...this.clockedOutTopics.map(t =>
        yyyymmdd(
          new Date(
            (t.correctedEndTime || t.execEndTime) +
              this.timezoneOffset(new Date(t.correctedEndTime || t.execEndTime))
          ),
          "-"
        )
      )
    ];
    return topicTimestamps.includes(dateString);
  };

  onMonthChange = month => {
    const { dateString } = month;
    stateController.viewGroupState.shiftCalendarCurrent[
      this.groupId
    ] = dateString;
  };

  onWeekChange = date => {
    stateController.viewGroupState.shiftCalendarCurrent[
      this.groupId
    ] = yyyymmdd(date, "-");
  };

  onDayPress = day => {
    const { dateString } = day;

    return this.popupAddTopic(dateString, null, this.dateHasTopics(dateString));
  };

  onWeekBlockPress = date => {
    const dateString = yyyymmdd(date, "-");
    const timeStrings = {
      start: `${formatFixedDigit(date.getHours(), 2)}:00:00`,
      end: `${formatFixedDigit(date.getHours() + 1, 2)}:00:00`
    };
    return this.popupAddTopic(
      dateString,
      timeStrings,
      this.dateHasTopics(dateString)
    );
  };

  popupAddTopic = (dateString, timeStrings, hasTopic) => {
    let isLocked;
    const timestamp = new Date(`${dateString}T00:00:05`);
    const withinCurrentPeriod =
      timestamp >= this.allowedPlotPeriod.start &&
      timestamp <= this.allowedPlotPeriod.end;
    if (!withinCurrentPeriod) {
      isLocked = true;
    }

    timeStrings = timeStrings || {};

    const maxDateString = yyyymmdd(new Date(this.allowedPlotPeriod.end), "-");
    const maxTimeString =
      dateString === maxDateString ? "23:59:59" : "00:00:00";
    const min = `${dateString}T00:00:00`;
    const max = `${maxDateString}T${maxTimeString}`;
    const dateStringDisp = new Date(min).toDateString();

    const form = observable([
      {
        name: "description",
        type: "input",
        title: UIText.shiftCalendarAddShiftTitle,
        showLabel: false,
        placeholder: UIText.shiftCalendarAddShiftTitlePlaceholder
      },
      {
        name: "execStartTime",
        type: "dateTime",
        placeholder: UIText.shiftClockedInShifts,
        min,
        // max,
        value: `${dateString}T${timeStrings.start || "09:00:00"}`
      },
      {
        name: "execEndTime",
        type: "dateTime",
        placeholder: UIText.shiftClockedOutShifts,
        min,
        max,
        value: `${dateString}T${timeStrings.end || "17:00:00"}`
      },
      {
        name: "timezone",
        title: UIText.groupTimeZone,
        disabled: true,
        value: getTimeZoneLongName(this.timezone, true),
        type: "input"
      }
    ]);

    const showShifts = () => {
      const filterString = new Date(min).toDateString();
      this.setAllShiftsFilter(filterString);
      return stateController.dismissPopup().then(this.handleShiftListPress);
    };

    const popup = () =>
      stateController.showPopup({
        title: isLocked ? UIText.shiftCalendar : UIText.shiftCalendarAddShift,
        form: !isLocked && form,
        formShowTitle: true,
        buttonSet: [
          hasTopic && {
            title: UIText.shiftCalendarShowShifts(dateStringDisp),
            onPress: showShifts
          },
          !isLocked && {
            title: UIText.generalSubmit,
            onPress: () => setTimeout(submit)
          },
          {
            title: UIText.generalCancel,
            onPress: () => setTimeout(stateController.dismissPopup),
            topDivider: true
          }
        ]
          .filter(Boolean)
          .map(b => (b.align = "center") && b),
        dismissOnBackPress: true
      });

    const submit = async () => {
      const execStartTime = form.find(f => f.name === "execStartTime");
      const execEndTime = form.find(f => f.name === "execEndTime");
      const { start, end } = ShiftTaskController.extractClockInOutFieldsValue(
        execStartTime,
        execEndTime,
        this.timezone
      );

      if (start > end) {
        return stateController.showPopup({
          title: UIText.shiftCalendar,
          content: UIText.shiftCalendarAddShiftClockedDateInverted,
          leftButtonText: UIText.generalConfirm,
          leftButtonPress: popup
        });
      }

      execStartTime.value = start;
      execEndTime.value = end;

      return stateController
        .dismissPopup()
        .then(() =>
          stateController.showPopup({
            title: UIText.shiftCalendarAddShift,
            content: UIText.pleaseWait
          })
        )
        .then(() => this.submitShift(formService.disassembleFormData(form)))
        .then(this.onRefresh)
        .then(stateController.dismissPopup)
        .then(() =>
          stateController.showPopup({
            title: UIText.shiftCalendarAddShift,
            content: UIText.shiftCalendarAddShiftSuccess,
            leftButtonText: UIText.generalConfirm,
            leftButtonPress: stateController.dismissPopup,
            dismissOnBackPress: true
          })
        )
        .catch(this._showError);
    };

    return (!isLocked || hasTopic) && popup();
  };

  submitShift = async shift => {
    const { execEndTime, execStartTime, description } = shift || {};

    if (!execStartTime || !execEndTime) {
      return Promise.reject(UIText.shiftCalendarAddShiftMissingValue);
    }

    // return asyncPause(2000);

    const { withShiftHourlyRate } = new ShiftTaskController();
    const shiftData = await withShiftHourlyRate(this.selfMember, {}).catch(
      err => {
        throw err;
      }
    );

    const topic = {
      creatorMemberId: this.selfMember.id,
      typeId: topicTypeIds.shift,
      description:
        description ||
        `${getFullDayNameEng(
          new Date(execStartTime + this.timezoneOffset(new Date(execStartTime)))
        )} ${UIText.shiftMakerShift}`,
      execStartTime,
      execEndTime,
      onCalendar: 1,
      isTemplate: 0,
      isParentTemplate: 0,
      isCompleted: 1,
      isDataLocked: 0,
      isLocked: 0,
      groupId: this.group.id,
      data: JSON.stringify(shiftData),
      typeClassId: typeClassIds.shiftTemplateTopic,
      typeClassVersion: 1 // Default for now.
    };

    return apiService.async("POST", {
      endpoint: endpointConfig.create_topic,
      data: {
        currentGroupId: this.group.id,
        topic
      }
    });
    // .then(console.log);
  };

  setAllShiftsFilter = filterString => {
    let filter = filterController.CareReceiverShiftListFilters[this.group.id];
    if (isEmpty(filter)) {
      filterController.CareReceiverShiftListFilters[this.group.id] =
        filterController.CareReceiverShiftListFilterProto;
      filter = filterController.CareReceiverShiftListFilters[this.group.id];
    }
    const filterCategory = filter.find(f => f.name === "Date");
    if (!isEmpty(filterCategory)) filterCategory.selected = filterString;
  };

  showHelp = () => {
    const form = [
      {
        name: 0,
        type: "text",
        value: `${UIText.shiftCalendarMarkHelp}`
      },
      {
        name: 1,
        type: "text",
        styles: { backgroundColor: theme.color },
        value: `${UIText.shiftClockedInShifts} ${UIText.shiftMakerShift}`
      },
      {
        name: 2,
        type: "text",
        styles: { backgroundColor: "#aaa" },
        value: `${UIText.shiftCompleted} ${UIText.shiftMakerShift}`
      },
      {
        name: 3,
        type: "text",
        styles: { backgroundColor: "#666" },
        value: `${
          UIText.shiftMakerShift
        } ${UIText.topicCreatedViaCalendar.toLowerCase()}`
      },
      this.mode === "month" && {
        name: 4,
        type: "text",
        value: UIText.shiftCalendarDarkerText
      },
      this.mode === "month" && {
        name: 5,
        type: "text",
        styles: { color: "#000", padding: 0 },
        value: UIText.shiftCalendarCurrentPayPeriod
      },
      this.mode === "week" && {
        name: 6,
        type: "text",
        value: UIText.shiftCalendarDarkerBlock
      },
      this.mode === "week" && {
        name: 7,
        type: "text",
        styles: { backgroundColor: "#eee", color: "#000" },
        value: UIText.shiftCalendarCurrentPayPeriod
      }
    ]
      .filter(Boolean)
      .map(t => {
        if (t.styles) {
          !t.styles.color && (t.styles.color = "#fff");
          !t.styles.textAlign && (t.styles.textAlign = "center");
          !t.styles.alignSelf && (t.styles.alignSelf = "center");
          !t.styles.width && (t.styles.width = 200);
          !t.styles.padding && (t.styles.padding = 5);
          !t.styles.borderRadius && (t.styles.borderRadius = 3);
        }
        return t;
      });

    return stateController.showPopup({
      title: UIText.shiftCalendar,
      form,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  onRefresh = () => {
    this.loading = true;
    return setTimeout(() =>
      this.props
        .onRefresh()
        .catch(this._showError)
        .finally(() => (this.loading = false))
    );
  };
}
