import { computed, observable } from "mobx";
import { stateController } from "../../../cdm/controllers/state-controller";
import { UIText } from "../../../config/lang-config";
import {
  capitalize,
  isEmpty,
  parseErrorMsg,
  serializeObject,
  validateEmail,
  validatePhoneCA
} from "../../../utils/helpers";
import { clientController } from "../../../cdm/controllers/client-controller";
import { apiController } from "../../../cdm/controllers/api-controller";
import { apiService } from "../../../cdm/services/api-service";
import {
  calendlyFrameEvents,
  groupTypeRoleIds
} from "../../../config/variable-config";
import { mcbEndpointConfig } from "../../../custom/mcb/config/api-config";

export class CaregiverSignUpController {
  // @observable buttonDisabled = true;
  @observable registering = false;
  @observable showCalendly = false;
  @observable fieldLocked = false;
  @observable returnedUserData;
  @observable onboardingTopicId;
  @observable calendlyUrl = "";

  // Validation error messages
  @observable error = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: ""
  };

  @observable fieldData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "male"
  };

  required = ["firstName", "lastName", "email", "phone", "gender"];

  @computed get buttonDisabled() {
    let disabled = false;
    for (let field in this.error) {
      if (!!this.error[field]) disabled = true;
    }
    for (let field in this.required) {
      let data = this.fieldData[this.required[field]];
      if (!data || data === "" || data === undefined) disabled = true;
    }
    return disabled;
  }

  // Auto populate form fields.
  @computed get form() {
    return stateController.base64form;
  }
  @computed get autoEmail() {
    return this.form.email;
  }
  @computed get autoPhone() {
    return this.form.phone;
  }
  @computed get autoFirstName() {
    return this.form.firstName;
  }
  @computed get autoLastName() {
    return this.form.lastName;
  }
  @computed get autoGender() {
    return this.form.gender;
  }
  @computed get autoToken() {
    return this.form.token;
  }
  @computed get autoCalendlyUrl() {
    return this.form.calendlyUrl;
  }
  @computed get autoOnboardingTopicId() {
    return this.form.onboardingTopicId;
  }
  @computed get isVendor() {
    return this.props.navigation.state.routeName.match(/Vendor/g);
  }

  constructor(props) {
    this.props = props;
    if (!isEmpty(this.form)) this.fieldLocked = true;
    if (this.isVendor && !this.fieldLocked) {
      // TODO: Legacy screening sign up form redirect to web-component for now.
      window.location.href = "//mycarebase.com/join-as-a-service-provider/";
      return this;
    }
    if (this.autoFirstName) this.fieldData.firstName = this.autoFirstName;
    if (this.autoLastName) this.fieldData.lastName = this.autoLastName;
    if (this.autoEmail) this.fieldData.email = this.autoEmail;
    if (this.autoPhone) this.fieldData.phone = this.autoPhone;
    if (this.autoGender) this.fieldData.gender = this.autoGender;
    if (this.autoToken) this.userToken = this.autoToken;
    if (this.autoOnboardingTopicId)
      this.onboardingTopicId = this.autoOnboardingTopicId;
    if (this.autoCalendlyUrl) {
      this.calendlyUrl = this.autoCalendlyUrl;
      setTimeout(() => {
        this.initCalendly();
        this.showCalendly = true;
      });
    }
  }

  componentDidMount() {
    stateController.currentScreen = "CaregiverSignUp";
  }

  _registerLoading = () => {
    this.registering = true;
    stateController.showPopup({
      title: UIText.registrationProgress,
      content: UIText.pleaseWait
    });
  };

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.registrationFailure,
      content: parseErrorMsg(err),
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  onCalendlyEvent = data => {
    console.log(data);
    const { event } = data;
    if (!event) return;
    if (!calendlyFrameEvents.includes(event)) return;
    if (event === "calendly.event_scheduled") return this.updateBookingStatus();
  };

  updateFields = (field, event) => {
    if (!event) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      this.fieldData[field] = event.nativeEvent.text;
  };

  validateRequired = field => {
    const fieldData = this.fieldData;
    const value = fieldData[field];
    if (!value)
      return (this.error[field] = `${UIText.entryRequiring} ${field}.`);
    this.error[field] = "";
    if (field === "email") {
      if (!validateEmail(value)) {
        const isPlusSignError = (value || "").match(/\+/);
        return (this.error[field] = isPlusSignError
          ? UIText.registrationEmailNoPlusSign
          : `${UIText.entryRequiringValid} ${field}.`);
      }

      clientController.execLogout();

      apiController.checkExist(field, value).then(result => {
        this.error[field] = result.exist
          ? `${capitalize(UIText.registrationUniqueFields[field])} ${
              UIText.registrationUniqueExists
            }`
          : result.error
          ? (this.error[field] = result.error)
          : "";
      });
    }
    if (field === "phone") {
      if (!validatePhoneCA(value)) {
        return (this.error[field] = `${UIText.entryRequiringValid} ${field}.`);
      }
    }
  };

  execRegister = async event => {
    if (this.buttonDisabled) return;
    // Fail safe logout.
    clientController.execLogout();

    this.fieldData.intents = JSON.stringify([groupTypeRoleIds.caregiver]);

    this._registerLoading();
    return (
      clientController
        .isMaintenance()
        .then(() =>
          apiService.async("POST", {
            endpoint: mcbEndpointConfig.caregiver_onboard,
            data: serializeObject(this.fieldData)
          })
        )
        // .then(() => asyncPause(2000))
        // .then(this.postRegisterOldFlow)
        .then(this.postRegisterNewFlow)
        .catch(this._showError)
        .finally(() => (this.registering = false))
    );
  };

  postRegisterNewFlow = async response => {
    const user = response.data;
    if (isEmpty(user) || !user.onboardingId) {
      return Promise.reject({
        message: "Something happened and registration was not successful. "
      });
    }
    this.userToken = user.access_token;
    this.returnedUserData = user;
    this.fieldLocked = true;
    return stateController.dismissPopup().then(() =>
      stateController.showPopup({
        title: UIText.title,
        content: UIText.caregiverApplicationNewFlowSuccessMessage,
        leftButtonText: UIText.generalConfirm,
        leftButtonPress: () =>
          stateController
            .dismissPopup()
            .then(() => this.props.navigation.navigate("Login")),
        dismissOnBackPress: false
      })
    );
  };

  postRegisterOldFlow = async response => {
    const user = response.data;
    if (!user || !user.calendlyUrl)
      return Promise.reject({
        message:
          "Something happened and registration was not successful. [Empty user or calendlyUrl]"
      });
    this.userToken = user.access_token;
    this.onboardingTopicId = user.topicId;
    this.calendlyUrl = user.calendlyUrl;
    this.returnedUserData = user;
    await stateController.dismissPopup();
    this.fieldLocked = true;
    setTimeout(() => {
      this.initCalendly();
      this.showCalendly = true;
    });
  };

  updateBookingStatus = async () => {
    const screeningStatus = "interview booked";
    if (!this.onboardingTopicId) return;
    apiService.updateOAuthData({
      token_type: "bearer",
      access_token: this.userToken
    });
    return apiService.async("PATCH", {
      endpoint: mcbEndpointConfig.modify_screening_status,
      data: {
        id: this.onboardingTopicId,
        data: JSON.stringify({ screeningStatus })
      }
    });
  };

  initCalendly = () => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
    const isCalendlyEvent = e =>
      e.data.event && e.data.event.indexOf("calendly") === 0;
    window.addEventListener(
      "message",
      e => isCalendlyEvent(e) && this.onCalendlyEvent(e.data)
    );
  };
}
