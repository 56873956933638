import React from "react";
import { AdminPromoPageController } from "./controller";
import { AdminPromoPageView } from "./view";
import { styles } from "./styles";

export class AdminPromoPage extends React.Component {
  render() {
    const controller = new AdminPromoPageController(this.props);

    return (
      <AdminPromoPageView
        controller={controller}
        {...this.props}
        styles={styles}
      />
    );
  }
}
