import { careReceiverMarketForm } from "./form";
import { formService } from "../../../../../../../cdm/services/form-service";
import { apiController } from "../../../../../../../cdm/controllers/api-controller";
import { isEmpty } from "../../../../../../../utils/helpers";
import { updateGroupProfile } from "../../../../../../../cdm/lib/group-utilities";
import { autorun, observable } from "mobx";
import { typeClassLibrary } from "../../../../../../../cdm/lib/typeClass-utilities";

const setupBoxer = {};

export const updateMarketForm = formData => {
  const { form } = setupBoxer.setup;
  if (isEmpty(form)) return;

  setupBoxer.disposer = autorun(() => {
    typeClassLibrary.autoSelectEnglish(form);
  });

  formData =
    formData &&
    formService.disassembleFormData(formData, { displayName: true });
  if (isEmpty(formData)) return;
  for (let field of form) {
    if (formData[field.name]) {
      field.value = formData[field.name];
    }
  }
};

export const marketplaceSetup = () => {
  const setup = {
    key: "marketplaceSetup",
    title: "Find a Paid Caregiver",
    heading: "Find the right Caregiver\n according to your needs",
    checkboxTitle: "I want to hire a Paid Caregiver. *",
    require: "careReceiverSetup",
    backDisabled: true,
    form: observable(careReceiverMarketForm()),
    onSubmit: async (form, store) => {
      const errorMessage = "Previous step was unsuccessful.";
      const groupId = store.groupId;
      if (!groupId) return Promise.reject(`${errorMessage}[groupId_non_exist]`);

      setupBoxer.disposer && setupBoxer.disposer();

      return await apiController
        .getGroupById(groupId)
        .then(group => {
          if (isEmpty(group))
            return Promise.reject(`${errorMessage}[empty_group]`);
          return group.profileId;
        })
        .then(apiController.getProfileById)
        .then(profile => {
          if (isEmpty(profile) || !profile.data)
            return Promise.reject(`${errorMessage}[profile_non_exist]`);
          const profileData = profile.data;
          const profileAddition = formService.disassembleFormData(form);
          return {
            profileId: profile.id,
            data: JSON.stringify({ ...profileData, ...profileAddition })
          };
        })
        .then(({ profileId, data }) => updateGroupProfile(profileId, data));
    }
  };

  setupBoxer.setup = setup;

  return setup;
};
