import { Platform } from "react-native";

export const styles = {
  style: {
    flex: 1
  },
  webFrame: {
    outline: "none",
    border: "none"
  },
  webDiv: Platform.select({
    ios: {
      //,
    },
    android: {
      //,
    },
    web: {
      width: "100%"
    }
  })
};
