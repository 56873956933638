import React from "react";
import { observer } from "mobx-react";
import { UIText } from "../../../../config/lang-config";
import { Text } from "react-native";
import { openRatingDetailByGroupId } from "../../lib/group-utilities-mcb";

const RatingReviewDetailEntryLinkView = observer(({ styles, groupId }) => {
  return (
    <Text
      style={styles.style}
      onPress={() => openRatingDetailByGroupId(groupId)}
    >
      {UIText.ratingReviewOpenReviews}
    </Text>
  );
});

export { RatingReviewDetailEntryLinkView };
