import { computed, observable, reaction } from 'mobx';
import { stateController } from '../../../../cdm/controllers/state-controller';
import { UIText } from '../../../../config/lang-config';
import { groupTypeRoleIds, placeholderPickerValue } from '../../../../config/variable-config';
import { apiService } from '../../../../cdm/services/api-service';
import { endpointConfig } from '../../../../config/api-config';
import { apiController } from '../../../../cdm/controllers/api-controller';
import { getDisplayNameEng, isEmpty, randomString } from '../../../../utils/helpers';

export class AdminRatingReviewPageController {
  @observable loading = false;
  @observable ratingLoading = false;

  @observable group = {};
  @observable ratingReviews = [];
  @observable ratingReviewsLoading = {};
  @observable testimonialsLoading = {};

  @observable selectedTargetMemberId = placeholderPickerValue;
  @observable targetMemberAdminRating = {};
  @observable targetMemberTestimonials = [];

  @computed get viewByCareCircle() {
    return stateController.viewAdminCentreState.ratingReviewViewByCareCircle;
  }

  // Current view group.
  @computed get groupId() {
    return stateController.viewAdminCentreState.ratingReviewGroupId;
  }
  @computed get members() {
    return this.group.members || [];
  }
  @computed get roles() {
    return this.group.roles || [];
  }
  @computed get ratingPossibleMembers() {
    const paidCaregiverRole = this.roles.find(
      role => role.groupTypeRoleId === groupTypeRoleIds.paidCaregiver
    );
    return (paidCaregiverRole.memberIdList || []).map(id =>
      this.members.find(member => member.id === id)
    );
  }
  @computed get ratingAvailableMembers() {
    return this.ratingReviews
      .map(r => r.memberId)
      .map(memberId => this.ratingPossibleMembers.find(m => m.id === memberId));
  }
  @computed get targetMemberPickerOptions() {
    return [
      {
        name: placeholderPickerValue,
        placeholder: UIText.adminCentreRatingReviewSelectTargetMember
      },
      ...this.ratingPossibleMembers.map(member => ({
        name: member.id,
        placeholder: getDisplayNameEng(member.profile)
      }))
    ];
  }
  @computed get targetMemberPickerField() {
    return {
      type: "picker",
      placeholder: UIText.adminCentreRatingReviewTargetMember,
      options: this.targetMemberPickerOptions,
      value: this.selectedTargetMemberId
    };
  }
  @computed get targetMember() {
    return this.members.find(m => m.id === this.selectedTargetMemberId) || {};
  }
  @computed get targetMemberRatingReviews() {
    return this.findRatingsForMember(this.selectedTargetMemberId);
  }

  constructor(props) {
    this.props = props;
    this.dispose = reaction(
      () => this.selectedTargetMemberId,
      () => this.loadAllDataForMember()
    );
    this._initialize();
  }

  componentWillUnmount() {
    return this.dispose && this.dispose();
  }

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.adminCentre,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _initialize = async () => {
    this.loading = true;
    return this.getGroupAndMembers()
      .catch(this._showError)
      .finally(() => (this.loading = false));
  };

  getGroupAndMembers = async () =>
    apiController
      .getGroupById(this.groupId)
      .then(group => (this.group = group))
      .then(this.getMemberProfiles)
      .then(this.setTargetMemberId);

  getMemberProfiles = async () =>
    Promise.all(
      this.members.map(member =>
        apiController
          .getProfileById(member.profileId)
          .then(profile => (member.profile = profile))
      )
    );

  setTargetMemberId = () => {
    if (this.viewByCareCircle) return;
    return (this.selectedTargetMemberId = this.group.members[0].id);
  };

  loadAllDataForMember = async noLoading => {
    if (this.selectedTargetMemberId === placeholderPickerValue) return;
    if (!noLoading) this.ratingLoading = true;
    return Promise.all([
      this.getRatingReviewsForMember(),
      this.getAdminRatingForMemberUser(),
      this.getTestimonialsForMemberUser()
    ])
      .catch(this._showError)
      .finally(() => !noLoading && (this.ratingLoading = false));
  };

  getRatingReviewsForMember = async () => {
    return apiService
      .async("GET", {
        endpoint: this.viewByCareCircle
          ? endpointConfig.get_rating_reviews_by_target_member_id(
              this.selectedTargetMemberId
            )
          : endpointConfig.get_rating_reviews_by_user_id(
              this.targetMember.userId
            )
      })
      .then(response => response.data || {})
      .then(ratingReviews => {
        const result = {
          memberId: this.selectedTargetMemberId,
          ratingReviews
        };
        const existing = this.ratingReviews.find(
          r => r.memberId === this.selectedTargetMemberId
        );
        if (existing) return Object.assign(existing, result);
        return this.ratingReviews.push(result);
      });
  };

  getAdminRatingForMemberUser = async () => {
    const { userId } = this.targetMember;
    return apiService
      .async("POST", {
        endpoint: endpointConfig.set_admin_rating,
        data: { targetUserId: userId }
      })
      .then(response => response.data || {})
      .then(adminRating => (this.targetMemberAdminRating = adminRating));
  };

  getTestimonialsForMemberUser = async () => {
    const { userId } = this.targetMember;
    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_testimonials_by_user_id(userId)
      })
      .then(response => response.data || [])
      .then(testimonials => (this.targetMemberTestimonials = testimonials));
  };

  updateAdminRatingForMemberUser = async () => {
    const { id, rating, isPublished } = this.targetMemberAdminRating || {};
    return apiService
      .async("POST", {
        endpoint: endpointConfig.set_admin_rating,
        data: {
          id,
          rating,
          isPublished
        }
      })
      .then(response => response.data || {})
      .then(adminRating => (this.targetMemberAdminRating = adminRating))
      .catch(this._showError);
  };

  findRatingsForMember = memberId => {
    const map = this.ratingReviews.find(r => r.memberId === memberId) || {};
    return map.ratingReviews || [];
  };

  onTargetMemberChange = value => (this.selectedTargetMemberId = Number(value));

  onAdminRatingChange = rating => {
    this.targetMemberAdminRating.rating = rating;
    return this.updateAdminRatingForMemberUser();
  };

  onSetAdminRatingIsPublished = () => {
    this.targetMemberAdminRating.isPublished = this.targetMemberAdminRating
      .isPublished
      ? 0
      : 1;
    return this.updateAdminRatingForMemberUser();
  };

  makeTestimonialNameField = testimonial => ({
    type: "input",
    placeholder: UIText.adminCentreTestimonialReviewerPlaceholder,
    value: testimonial.name
  });

  makeTestimonialField = testimonial => ({
    type: "input",
    placeholder: UIText.adminCentreNewTestimonialPlaceholder,
    multiline: true,
    value: testimonial.review
  });

  onAddTestimonial = () =>
    this.targetMemberTestimonials.unshift({
      id: randomString(),
      targetUserId: this.targetMember.userId,
      review: "",
      isPublished: 0
    });

  onTestimonialChange = (event, id) => {
    if (!event) return;
    const testimonial = this.targetMemberTestimonials.find(t => t.id === id);
    if (!testimonial) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      testimonial.review = event.nativeEvent.text;
  };

  onTestimonialNameChange = (event, id) => {
    if (!event) return;
    const testimonial = this.targetMemberTestimonials.find(t => t.id === id);
    if (!testimonial) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      testimonial.name = event.nativeEvent.text;
  };

  onTestimonialRemove = id => {
    const index = this.targetMemberTestimonials.findIndex(t => t.id === id);
    if (index < 0) return;
    return this.targetMemberTestimonials.splice(index, 1);
  };

  onTestimonialSave = id => {
    const testimonial = this.targetMemberTestimonials.find(t => t.id === id);
    if (!testimonial) return;
    this.testimonialsLoading[id] = true;
    return stateController
      .showPopup({
        title: UIText.testimonials,
        content: UIText.pleaseWait
      })
      .then(() =>
        apiService.async("POST", {
          endpoint: endpointConfig.new_testimonial,
          data: {
            targetUserId: testimonial.targetUserId,
            name: testimonial.name,
            review: testimonial.review
          }
        })
      )
      .then(this.getTestimonialsForMemberUser)
      .then(stateController.dismissPopup)
      .catch(this._showError)
      .finally(() => (this.testimonialsLoading[id] = false));
  };

  onSetTestimonialIsPublished = id => {
    const testimonial = this.targetMemberTestimonials.find(t => t.id === id);
    if (!testimonial) return;
    if (!testimonial.createTime) return;
    testimonial.isPublished = testimonial.isPublished ? 0 : 1;
    return apiService
      .async("PATCH", {
        endpoint: endpointConfig.testimonial_by_id(id),
        data: { isPublished: testimonial.isPublished }
      })
      .catch(this._showError);
  };

  onSetRatingIsPublished = async ratingReview => {
    if (isEmpty(ratingReview)) return;
    this.ratingReviewsLoading[ratingReview.id] = true;
    return apiService
      .async("POST", {
        endpoint: endpointConfig.set_rating_review_is_published(
          ratingReview.id
        ),
        data: { isPublished: ratingReview.isPublished ? 0 : 1 }
      })
      .then(() => this.loadAllDataForMember(true))
      .catch(this._showError)
      .finally(() => (this.ratingReviewsLoading[ratingReview.id] = false));
  };

  onRefresh = () => this._initialize().then(() => this.loadAllDataForMember());
}
