import React from "react";
import StripeElementsView from "./view";
import { styles } from "./styles";
import { apiService } from "../../cdm/services/api-service";
import { endpointConfig } from "../../config/api-config";
import { observable } from "mobx";
import { Elements, StripeProvider } from "react-stripe-elements";
import { observer } from "mobx-react";

@observer
export class StripeElementsWeb extends React.Component {
  @observable stripeApiKey;

  constructor(props) {
    super(props);
    this.getStripeApiKey();
  }

  getStripeApiKey = () =>
    apiService
      .async("GET", {
        endpoint: endpointConfig.stripe_api_key
      })
      .then(response => response.data)
      .then(key => (this.stripeApiKey = key));

  render() {
    return !!this.stripeApiKey ? (
      <StripeProvider apiKey={this.stripeApiKey}>
        <Elements>
          <StripeElementsView {...this.props} styles={styles} />
        </Elements>
      </StripeProvider>
    ) : null;
  }
}
