import { common } from "../../../../config/style-configs/common";

export const styles = {
  style: {
    flex: 1
  },
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  scrollViewCard: common.scrollViewCard,
  loading: common.loading,
  buttonReminder: common.listButtonReminderContainer,
  errorMessage: common.containerErrorMessage
};
