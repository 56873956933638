import { Platform } from "react-native";
import { Button, Icon } from "react-native-elements";
import { theme } from "../../config/style-configs/theme";
import React from "react";
import { observer } from "mobx-react";

export const ListRefreshButtonWebView = observer(props =>
  Platform.OS === "web" ? (
    <Button
      containerStyle={{
        ...props.styles.listRefreshButton,
        ...props.style
      }}
      buttonStyle={{
        ...props.styles.buttonStyle,
        ...props.buttonStyle
      }}
      loading={props.loading}
      loadingProps={{ color: theme.color }}
      onPress={props.onRefresh || props.onPress}
      type="clear"
      icon={<Icon name="refresh" color="#aaa" size={theme.FONT_SIZE_XLARGE} />}
    />
  ) : null
);
