import { autorun, reaction } from "mobx";
import {
  formService,
  TypeClassTemplate
} from "../../../../../../cdm/services/form-service";
import {
  groupTypeIds,
  groupTypeRoleIds,
  typeClassIds
} from "../../../../../../config/variable-config";
import { apiService } from "../../../../../../cdm/services/api-service";
import { endpointConfig } from "../../../../../../config/api-config";
import { clientController } from "../../../../../../cdm/controllers/client-controller";
import {
  matchCareReceiver,
  matchOSMCityWithTimeZone,
  matchOSMProvinceWithField
} from "../../../../lib/group-utilities-mcb";
import { stateController } from "../../../../../../cdm/controllers/state-controller";

let template = new TypeClassTemplate(typeClassIds.careReceiverProfile);

let roleSelection = {
  name: "role",
  type: "picker",
  flags: {
    general: true
  },
  placeholder: "First, how are you related?",
  options: []
};

let customDisposers = {};

const dispose = autorun(() => {
  if (
    !!clientController &&
    clientController.initialized &&
    clientController.loginState
  ) {
    apiService["GET"]({
      endpoint: endpointConfig.type_roles_by_group_type_id(
        groupTypeIds.careReceiver
      ),
      success: response => {
        const typeRoles = response.data || [];
        const roles = typeRoles
          .map(role => {
            if (matchCareReceiver(role.description)) return null; // DONT INCLUDE CARE RECIPIENT.
            if (
              !clientController.client.user.isSa &&
              [
                groupTypeRoleIds.careConcierge,
                groupTypeRoleIds.techSupport
              ].includes(role.id)
            ) {
              return null;
            }
            return {
              name: role.id,
              placeholder: role.description
            };
          })
          .filter(Boolean);
        roleSelection.options = roles;
        roleSelection.value = roles[0].name;
      }
    });
  }
});

export const monitorProvinceFieldDirty = form => {
  if (customDisposers.checkProvinceDirty) customDisposers.checkProvinceDirty();
  if (customDisposers.syncProvince) customDisposers.syncProvince();
  let isSyncing = false,
    provinceDirty = false;
  const province = form.find(field => field.name === "provinces");
  const timezone = form.find(field => field.name === "timezone");
  const location = form.find(field => field.name === "location");
  if (!province || !location) return;
  const syncProvince = (args, disposer) => {
    if (provinceDirty) {
      disposer.dispose();
      return;
    }
    if (isSyncing) return;
    isSyncing = true;
    const locationData = (location.value || {}).location;
    const potentialProvValue = matchOSMProvinceWithField(
      province,
      locationData
    );
    if (potentialProvValue) province.value = potentialProvValue;
    setTimeout(() => {
      const potentialTimezoneValue = matchOSMCityWithTimeZone(
        timezone,
        locationData
      );
      if (potentialTimezoneValue) timezone.value = potentialTimezoneValue;
      setTimeout(() => (isSyncing = false));
    }, 100);
  };
  const checkProvinceDirty = (args, disposer) => {
    if (!isSyncing) provinceDirty = true;
    if (provinceDirty) disposer.dispose();
  };
  customDisposers.checkProvinceDirty = reaction(
    () => province.value,
    checkProvinceDirty
  );
  customDisposers.syncProvince = reaction(() => location.value, syncProvince);
};

export const careReceiverForm = gOnly => {
  const generalSearch = field => field.flags && !!field.flags["general"];
  const generalOnly = field =>
    field.flags && !!field.flags["general"] && !field.flags["search"];

  if (roleSelection.options.length === 0) return [];
  if (!Array.isArray(template.form) || template.form.length === 0) return [];

  dispose();
  !template.form.find(f => f.name === "role") &&
    template.form.unshift(roleSelection);

  if (stateController.viewPlanState.residentialProvince) {
    const province = template.form.find(f => f.name === "provinces");
    if (province) {
      province.value = stateController.viewPlanState.residentialProvince;
      province.disabled = true;
    }
  }

  const fields = template.form
    .filter(field => field.type !== "collapsibleAnchor")
    .filter(gOnly ? generalOnly : generalSearch);

  formService.hideFieldsByGroupTypeId(fields, groupTypeIds.careReceiver);
  return fields;
};
