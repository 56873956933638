import React from "react";
import { TouchableOpacity, View } from "react-native";
import { Button, Icon, Text } from "react-native-elements";
import { Avatar } from "../Avatar";
import { observer } from "mobx-react";
import { getDisplayNameEng, getRGBFromStr, isEmpty } from "../../utils/helpers";
import { theme } from "../../config/style-configs/theme";
import { UIText } from "../../config/lang-config";
import { RoleGroupGroupTypeIds } from "../../config/variable-config";
import { responsive } from "../../config/style-configs/responsive";

@observer
class GroupHeadingView extends React.Component {
  render() {
    this.group = this.props.group || {};
    this.profileData = this.props.profile || {};
    const displayName = getDisplayNameEng(this.profileData);

    return (
      <TouchableOpacity onPress={this.props.handleHeadingPress}>
        <View style={this.props.styles.groupHeading}>
          <View style={this.props.styles.groupHeadingContent}>
            <View style={this.props.styles.groupHeadingAvatar}>
              <Avatar
                size={responsive.deviceDimension.isDesktop ? 80 : 60}
                rounded
                title={displayName && displayName[0]}
                backgroundColor={getRGBFromStr(
                  displayName + this.group.profileId
                )}
                source={{
                  uri: this.props.avatar
                }}
              />
            </View>
            <View style={this.props.styles.groupHeadingText}>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={this.props.styles.groupHeadingName}
              >
                {displayName}
              </Text>
              <View>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={this.props.styles.groupHeadingItems}
                >
                  {this.profileData.email}
                </Text>
                {/*<Text*/}
                {/*  numberOfLines={1}*/}
                {/*  ellipsizeMode="tail"*/}
                {/*  style={this.props.styles.groupHeadingItems}*/}
                {/*>*/}
                {/*  {this.profileData.phone}*/}
                {/*</Text>*/}
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={this.props.styles.groupHeadingItems}
                >
                  {this.props.groupTypeName}
                </Text>
                {this.props.timezoneName && (
                  <Text
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    style={this.props.styles.groupHeadingItems}
                  >
                    {UIText.groupAllTimeShowIn(this.props.timezoneName, true)}
                  </Text>
                )}
              </View>
            </View>
          </View>
          <View style={this.props.styles.groupHeadingIcon}>
            {this.props.showAppointment && (
              <Button
                buttonStyle={this.props.styles.groupHeadingIconButton}
                icon={<Icon size={20} name="event" color="#aaa" />}
                type="clear"
                title={UIText.appointments.toUpperCase()}
                titleStyle={this.props.styles.groupHeadingIconTitle}
                onPress={this.props.handleAppointmentPress}
              />
            )}
            {this.props.showBilling && (
              <Button
                buttonStyle={this.props.styles.groupHeadingIconButton}
                icon={
                  <Icon
                    size={20}
                    name="payment"
                    color={
                      isEmpty(this.group.subscription) &&
                      isEmpty(this.group.wpSubscription)
                        ? theme.color
                        : "#aaa"
                    }
                  />
                }
                type="clear"
                title={
                  RoleGroupGroupTypeIds.includes(this.group.typeId)
                    ? UIText.subscriptionMembership.toUpperCase()
                    : UIText.plan.toUpperCase()
                }
                titleStyle={{
                  ...this.props.styles.groupHeadingIconTitle,
                  ...(isEmpty(this.group.subscription) &&
                    isEmpty(this.group.wpSubscription) && {
                      color: theme.color
                    })
                }}
                onPress={this.props.handleBillingPress}
              />
            )}
            {this.props.showEdit && (
              <Button
                buttonStyle={this.props.styles.groupHeadingIconButton}
                icon={<Icon size={20} name="edit" color="#aaa" />}
                type="clear"
                title={UIText.profile.toUpperCase()}
                titleStyle={this.props.styles.groupHeadingIconTitle}
                onPress={this.props.handleHeadingPress}
              />
            )}
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export { GroupHeadingView };
