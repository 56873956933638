import { theme } from "../../config/style-configs/theme";

export const styles = {
  style: {
    marginRight: 10
  },
  button: {
    backgroundColor: theme.color
  },
  buttonContainer: {
    borderRadius: 5
  },
  title: {
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  iconSize: 24,
  iconContainer: {
    minHeight: 26,
    justifyContent: "center"
  }
};
