import { apiService } from "../services/api-service";
import { endpointConfig, serverConfig } from "../../config/api-config";
import {
  contextReject,
  getPriceString,
  isEmpty,
  objectToMultipart,
  safeParseJSON
} from "../../utils/helpers";
import { mcbEndpointConfig } from "../../custom/mcb/config/api-config";
import { env } from "../../config/variable-config";

// TODO: Aggregate all POST calls from various controllers.
class ApiController {
  userCredPersist = false;

  constructor() {
    this.parser = new DataPaser();
  }

  /*
    Callback function providing preload call between FileService and DataParser
   */
  registerAvatarPreload = method => this.parser.registerAvatarPreload(method);

  registerDefaultAvatarPreload = method =>
    this.parser.registerDefaultAvatarPreload(method);

  /*
    Login
   */
  login = async (data, noKickOut) => {
    if (isEmpty(data)) return;
    data["username"] && (data["username"] = data["username"].toLowerCase());
    data["grant_type"] = "password";

    return apiService
      .async("POST", {
        headers: serverConfig.defaultHeaders,
        endpoint: endpointConfig.login,
        data,
        noKickOut
      })
      .then(response => {
        return response.data || {};
      })
      .catch(err => {
        if (err.response && err.response.status === 429) {
          return contextReject(err.response.data.message);
        }
        return contextReject(err);
      });
  };

  postLogin = async data => {
    return apiService
      .async("POST", {
        endpoint: endpointConfig.post_login,
        data: objectToMultipart(data)
      })
      .then(response => {
        const user = response.data;
        return user || {};
      });
  };

  getUserMe = async noRenew =>
    apiService
      .async("GET", {
        endpoint: endpointConfig.user,
        noRenew
      })
      .then(response => {
        const user = response.data;
        return user || {};
      });

  renewToken = async OAuth2Data => {
    if (isEmpty(OAuth2Data)) return;

    const refresh_token = OAuth2Data["refresh_token"];
    if (!refresh_token) return;

    const data = {
      grant_type: "refresh_token",
      refresh_token
    };

    return apiService
      .async("POST", {
        renewingOAuth2Data: true,
        headers: serverConfig.defaultHeaders,
        endpoint: endpointConfig.login,
        data
      })
      .then(response => {
        return response.data || {};
      })
      .catch(err => {
        if (err.response && err.response.status === 429) {
          return contextReject(err.response.data.message);
        }
        return contextReject(err);
      });
  };

  /*
    Account info existence checks
   */
  checkExist = async (type, value) => {
    if (!type || !value) return {};

    let result = { exist: null, error: null };

    await apiService
      .async("GET", {
        headers: serverConfig.defaultHeaders,
        endpoint: endpointConfig.exists(type, value.toLowerCase())
      })
      .then(response => {
        result.exist = response.data && response.data.exists;
      })
      .catch(err => {
        console.error(err);
        result.error = (err.response && err.response.data) || err.message;
      });

    return result;
  };

  /*
    Getters
   */
  getMaintenanceState = async () => {
    return apiService
      .async("GET", {
        endpoint: endpointConfig.maintenance,
        timeout: 5000
      })
      .then(response => {
        const message = response && response.data;
        return message || "";
      })
      .catch(err => {
        console.warn(err);
        if (isEmpty(err.response)) {
          return this.getFallbackMaintenanceState();
        }
        throw err;
      });
  };

  getFallbackMaintenanceState = async () => {
    return apiService
      .async("GET", {
        endpoint: endpointConfig.maintenance_fallback
      })
      .then(response => {
        const message = response && response.data;
        return message || "";
      });
  };

  getUserAvatarById = async avatarId => {
    if (!avatarId) return {};
    return apiService
      .async("GET", {
        endpoint: endpointConfig.user_avatar_by_id(avatarId)
      })
      .then(response => {
        const avatar = response.data;
        return avatar || {};
      });
  };

  getUserAvatars = async userId => {
    if (!userId) return [];
    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_user_avatars(userId)
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        return dataArray || [];
      });
  };

  getDefaultAvatarId = async (entityId, entityType) => {
    if (!entityId) return;
    const args = [
      entityType === "member" && entityId,
      entityType === "group" && entityId
    ];
    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_default_avatar_id(...args)
      })
      .then(response => {
        const avatarId = response.data;
        return avatarId || "";
      });
  };

  getDefaultMember = async () => {
    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_default_member
      })
      .then(response => {
        const data = response.data;
        const member = data && this.parser.parseMember(data);
        return member || {};
      });
  };

  getDefaultGroup = async () => {
    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_default_group
      })
      .then(response => {
        const data = response.data;
        const group = data && this.parser.parseGroup(data);
        return group || {};
      });
  };

  getUserGroups = async () => {
    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_my_groups
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        const groups =
          dataArray && dataArray.map(data => this.parser.parseGroup(data));
        return groups || [];
      });
  };

  getUserMembers = () => {
    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_my_members
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        const members =
          dataArray && dataArray.map(data => this.parser.parseMember(data));
        return members || [];
      });
  };

  getUserInvitations = () => {
    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_my_invitations
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        return dataArray || [];
      });
  };

  getGroupById = async (groupId, memberId) => {
    if (!groupId) return {};
    return apiService
      .async("GET", {
        endpoint: endpointConfig.group_by_id(groupId, memberId)
      })
      .then(response => {
        const data = response.data;
        const group = data && this.parser.parseGroup(data);
        return group || {};
      });
  };

  getGroupsByTypeId = async typeId => {
    if (!typeId) return [];
    return apiService
      .async("GET", {
        endpoint: endpointConfig.groups_by_type_id(typeId)
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        const groups =
          dataArray && dataArray.map(data => this.parser.parseGroup(data));
        return groups || [];
      });
  };

  getGroupRolesByGroupId = async groupId => {
    if (!groupId) return [];
    return apiService
      .async("GET", {
        endpoint: endpointConfig.type_roles_by_group_id(groupId)
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        return dataArray || [];
      });
  };

  getGroupTypes = async groupTypeId => {
    return apiService
      .async("GET", {
        endpoint: endpointConfig.group_types(groupTypeId)
      })
      .then(response => {
        const data = response.data;
        return data || (groupTypeId ? {} : []);
      });
  };

  getMemberById = async memberId => {
    if (!memberId) return {};
    return apiService
      .async("GET", {
        endpoint: endpointConfig.member_by_id(memberId)
      })
      .then(response => {
        const data = response.data;
        const member = data && this.parser.parseMember(data);
        return member || {};
      });
  };

  getMembersByGroupId = async groupId => {
    if (!groupId) return [];
    return apiService
      .async("GET", {
        endpoint: endpointConfig.members_by_group_id(groupId)
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        const members =
          dataArray && dataArray.map(data => this.parser.parseMember(data));
        return members || [];
      });
  };

  getProfileById = async profileId => {
    if (!profileId) return {};
    return apiService
      .async("GET", {
        endpoint: endpointConfig.profile_by_id(profileId)
      })
      .then(response => {
        const data = response.data;
        const profile = data && this.parser.parseProfile(data);
        return profile || {};
      });
  };

  getTopicById = async (topicId, memberId) => {
    if (!topicId) return {};
    return apiService
      .async("GET", {
        endpoint: endpointConfig.topic_by_id(topicId, memberId)
      })
      .then(response => {
        const data = response.data;
        const topic = data && data.id && this.parser.parseTopic(data);
        return topic || {};
      });
  };

  getTopicsByTypeId = async (typeId, isT, isPT, topicOnly) => {
    if (!typeId) return [];
    return apiService
      .async("GET", {
        endpoint: endpointConfig.topics_by_type_id(typeId, isT, isPT, topicOnly)
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        const topics =
          dataArray && dataArray.map(data => this.parser.parseTopic(data));
        return topics || [];
      });
  };

  getTopicsByGroupId = async (groupId, topicOnly) => {
    if (!groupId) return [];
    return apiService
      .async("GET", {
        endpoint: endpointConfig.topics_by_group_id(groupId, topicOnly) // topicOnly: Used for topic search, no return of actor member etc.
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        const topics =
          dataArray && dataArray.map(data => this.parser.parseTopic(data));
        return topics || [];
      });
  };

  getGroupTopicsByTypeId = async (groupId, typeId, isT, isPT) => {
    if (!groupId || !typeId) return [];
    return apiService
      .async("GET", {
        endpoint: endpointConfig.type_topics_by_group_id(
          groupId,
          typeId,
          isT,
          isPT
        )
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        const topics =
          dataArray && dataArray.map(data => this.parser.parseTopic(data));
        return topics || [];
      });
  };

  getSubTopicsByParentId = async (parentId, topicOnly) => {
    if (!parentId) return [];
    return apiService
      .async("GET", {
        endpoint: endpointConfig.sub_topics_by_parent_id(parentId, topicOnly)
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        const topics =
          dataArray && dataArray.map(data => this.parser.parseTopic(data));
        return topics || [];
      });
  };

  getTopicsByTemplateTopicId = async (templateTopicId, topicOnly) => {
    if (!templateTopicId) return [];
    return apiService
      .async("GET", {
        endpoint: endpointConfig.topic_by_template_topic_id(
          templateTopicId,
          topicOnly
        )
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        const topics =
          dataArray && dataArray.map(data => this.parser.parseTopic(data));
        return topics || [];
      });
  };

  getTopicTypeById = async topicTypeId => {
    if (!topicTypeId) return {};
    return apiService
      .async("GET", {
        endpoint: endpointConfig.topic_type_by_id(topicTypeId)
      })
      .then(response => {
        const data = response.data;
        const topicType = data && data.id && data;
        return topicType || {};
      });
  };

  getMessageHistory = async data => {
    if (isEmpty(data)) return;
    return apiService
      .async("POST", {
        endpoint: endpointConfig.get_messages,
        data
      })
      .then(response => {
        const messages = Array.isArray(response.data) && response.data;
        return messages || [];
      });
  };

  getUnreadMessagesCount = async groups => {
    const postData = groups.map(group => group.id);

    if (isEmpty(postData)) return [];

    return apiService
      .async("POST", {
        endpoint: endpointConfig.get_unread_messages_count,
        data: { groupIdList: postData },
        // headers: serverConfig.defaultHeaders,
        noKickOut: true,
        noRenew: !this.userCredPersist,
        noSpinner: true
      })
      .then(response => {
        const data = response.data;
        const groups = data && this.parser.parseUnread(data);
        return groups || [];
      });
  };

  getFormClassById = async typeClassId => {
    if (!typeClassId && typeClassId !== 0) return {};

    return apiService
      .async("GET", {
        endpoint: endpointConfig.form_class_by_id(typeClassId)
      })
      .then(response => {
        const typeClass = response.data;
        return typeClass || {};
      });
  };

  searchMarketplaceGroups = async data => {
    return apiService
      .async("POST", {
        endpoint: mcbEndpointConfig.search_marketplace,
        data
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        const groups =
          dataArray && dataArray.map(data => this.parser.parseGroup(data));
        return groups || [];
      });
  };

  getSubscriptionPlans = async groupId => {
    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_plans(groupId || -1)
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        const plans =
          dataArray && dataArray.map(data => this.parser.parsePlan(data));
        return plans || [];
      });
  };

  getSubscriptionByGroupId = async groupId => {
    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_group_subscription(groupId || -1)
      })
      .then(response => {
        const data = response.data;
        const subscription = data && this.parser.parseSubscription(data);
        return subscription || {};
      });
  };

  getPlanPriceBreakdown = async (planId, territory, isTrial, promoCode) => {
    if (!planId || !territory) return {};
    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_plan_price_breakdown(
          planId,
          territory,
          isTrial,
          promoCode
        )
      })
      .then(response => {
        const data = response.data;
        const breakdown = data && this.parser.parsePlanPriceBreakdown(data);
        return breakdown || {};
      });
  };

  getUserSubscriptionPayment = async userId => {
    if (!userId) return {};
    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_user_stored_payment(userId)
      })
      .then(response => {
        const dataArray = Array.isArray(response.data) && response.data;
        const data = dataArray && dataArray[0];
        return data || {};
      });
  };

  getPlanPromoCodeValidity = async (groupId, planId, code) => {
    if (!code) return null;
    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_promo_code_validity(groupId, planId, code)
      })
      .then(response => {
        const result = response && response.data;
        return result || false;
      });
  };

  getDashboardSummary = async groupId => {
    if (!groupId) return {};
    return apiService
      .async("GET", {
        endpoint: mcbEndpointConfig.get_dashboard_summary(groupId)
      })
      .then(response => {
        const data = response && response.data;
        const result = data && this.parser.parseGeneralDTO(data);
        return result || {};
      });
  };

  getReportCompletedShiftSummary = async data => {
    if (isEmpty(data)) return {};
    return apiService
      .async("POST", {
        endpoint: mcbEndpointConfig.get_completed_shift_summary,
        data
      })
      .then(response => {
        const report = response.data;
        return report || {};
      });
  };

  getParsedHtmlTemplate = async (templateName, postData, isEmail) => {
    if (!templateName) return "";

    postData = postData || {};

    return apiService
      .async("POST", {
        headers: serverConfig.defaultHeaders,
        endpoint: endpointConfig.parse_template(templateName),
        data: {
          data: postData,
          email: isEmail
        }
      })
      .then(response => {
        const data = response.data;
        return data || "";
      });
  };

  getFileById = async fileId => {
    if (!fileId) return {};
    return apiService
      .async("GET", {
        endpoint: endpointConfig.file_by_id(fileId)
      })
      .then(response => {
        const file = response.data;
        return file || {};
      });
  };

  getFileBinaryById = async fileId => {
    if (!fileId) return null;
    return apiService
      .async("GET", {
        responseType: "blob",
        endpoint: endpointConfig.file_download_by_id(fileId)
      })
      .then(response => {
        const binary = response.data;
        return binary || null;
      });
  };

  getEmailChangeRequest = async () => {
    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_email_change // !!isCompleted, !isCancelled, !isReverted
      })
      .then(response => {
        const changeRequest = response && response.data;
        return changeRequest || {};
      });
  };

  getAdminOnboardingDataForProfile = async profileId => {
    return apiService
      .async("GET", {
        endpoint: mcbEndpointConfig.get_caregiver_new_onboarding_by_profile_id(
          profileId
        )
      })
      .then(response => response.data);
  };

  getAdminOnboardingUploadDataByOnboarding = async onboarding => {
    if (isEmpty(onboarding)) return;
    return apiService
      .async("GET", {
        endpoint: mcbEndpointConfig.caregiver_onboarding_upload_data_by_onboarding_id(
          onboarding.id,
          true
        )
      })
      .then(response => response.data);
  };

  /*
    Deletes
   */
  archiveGroup = async (groupId, notify, refund) => {
    if (!groupId) return;

    return apiService
      .async("GET", {
        endpoint: endpointConfig.archive_group(groupId, notify, refund)
      })
      .then(response => response.data);
  };

  archiveMember = async (memberId, notify) => {
    if (!memberId) return;

    return apiService
      .async("GET", {
        endpoint: endpointConfig.archive_member(memberId, notify)
      })
      .then(response => response.data);
  };

  deleteTopic = async topicId => {
    if (!topicId) return;

    return apiService
      .async("DELETE", {
        endpoint: endpointConfig.topic_by_id(topicId)
      })
      .then(response => response.data);
  };

  // deleteMember
  // deleteGroup
  // deleteMessage

  /*
    Uploads
   */
  uploadFile = async data => {
    if (!data) return;
    if (isEmpty(data.get("file"))) return;

    return apiService
      .async("POST", {
        endpoint: endpointConfig.upload_files,
        data
      })
      .then(response => {
        const file = response && response.data;
        return file || {};
      });
  };

  uploadAvatar = async data => {
    if (!data) return;
    if (isEmpty(data.get("avatar"))) return;

    return apiService
      .async("POST", {
        endpoint: endpointConfig.upload_avatar,
        data
      })
      .then(response => {
        const avatar = response && response.data;
        return avatar || {};
      });
  };

  groupChatLobbyPreJoin = async groupId =>
    apiService
      .async("GET", {
        endpoint: endpointConfig.group_chat_lobby_pre_join(groupId)
      })
      .then(response => response.data);
}

class DataPaser {
  /*
  Parsers, these methods help normalize properties and values from responseDTO.
 */
  parseUser = data => {
    let user = data;
    if (user["userResponseDTO"]) {
      user = {
        ...data["userResponseDTO"]
      };
    }

    if (typeof user["userResponseDTOList"] !== "undefined")
      delete user["userResponseDTOList"];

    return user;
  };

  parseGroup = data => {
    let group = data;
    if (group["groupResponseDTO"]) {
      group = {
        ...data["groupResponseDTO"]
        // roles: data["groupResponseDTO"]["roleResponseDTOList"]
        // members: data["groupResponseDTO"]["memberResponseDTOList"]
      };
    }

    const roles = group["roleResponseDTOList"];
    if (Array.isArray(roles)) {
      group.roles = roles;
    }

    const members = group["memberResponseDTOList"];
    if (Array.isArray(members)) {
      group.members = members.map(m => this.parseMember(m));
    }

    let profile =
      group["profile"] ||
      group["profileResponseDTO"] ||
      data["profileResponseDTO"];

    profile = this.parseProfile(profile);
    group.profile = profile || {};

    this.preloadDefaultAvatar(group.id, "group");

    if (typeof group["roleResponseDTOList"] !== "undefined")
      delete group["roleResponseDTOList"];
    if (typeof group["memberResponseDTOList"] !== "undefined")
      delete group["memberResponseDTOList"];
    if (typeof group["profileResponseDTO"] !== "undefined")
      delete group["profileResponseDTO"];

    return group;
  };

  parseMember = data => {
    let member = data;
    if (member["memberResponseDTO"]) {
      let group = member["memberResponseDTO"]["groupResponseDTO"];
      member = {
        ...member["memberResponseDTO"],
        group: group && group.id && this.parseGroup(group)
      };
    }
    let profile =
      member["profile"] ||
      member["profileResponseDTO"] ||
      data["profileResponseDTO"];

    profile = this.parseProfile(profile);
    member.profile = profile || {};

    this.preloadDefaultAvatar(member.id, "member");

    if (typeof member["groupResponseDTO"] !== "undefined")
      delete member["groupResponseDTO"];
    if (typeof member["profileResponseDTO"] !== "undefined")
      delete member["profileResponseDTO"];

    return member;
  };

  parseProfile = profile => {
    if (profile && typeof profile.data === "string") {
      profile.data = safeParseJSON(profile.data);
    }

    const avatar = !!profile && profile.data && profile.data.avatar;
    if (avatar) {
      this.preloadAvatar(avatar);
    }

    return profile;
  };

  parseTopic = data => {
    let topic = {
      ...data,
      actors: data["actorResponseDTOList"]
    };

    topic.createTime = topic.createTimeLong || topic.createTime;
    topic.updateTime = topic.updateTimeLong || topic.updateTime;
    topic.startTime = topic.startTimeLong || topic.startTime;
    topic.endTime = topic.endTimeLong || topic.endTime;
    topic.execStartTime = topic.execStartTimeLong || topic.execStartTime;
    topic.execEndTime = topic.execEndTimeLong || topic.execEndTime;
    topic.correctedEndTime =
      topic.correctedEndTimeLong || topic.correctedEndTime;

    const members = topic["memberResponseDTOList"];
    if (Array.isArray(members)) {
      topic.members = members.map(m => this.parseMember(m));
    }

    // topic.data = safeParseJSON(topic.data) || {};

    topic.pending = false;

    if (typeof topic["actorResponseDTOList"] !== "undefined")
      delete topic["actorResponseDTOList"];
    if (typeof topic["memberResponseDTOList"] !== "undefined")
      delete topic["memberResponseDTOList"];

    return topic;
  };

  parsePlan = data => {
    // if (data.displayName) {
    data.amountStr = data.displayName || getPriceString(data.amount / 100);
    // }

    data.category = data["planCategoryResponseDTO"] || {};

    if (typeof data["planCategoryResponseDTO"] !== "undefined")
      delete data["planCategoryResponseDTO"];

    return data;
  };

  parseSubscription = data => {
    if (!data) return null;

    const plan =
      data["planResponseDTO"] && this.parsePlan(data["planResponseDTO"]);
    data.plan = plan || {};

    data.promo = data["promoResponseDTO"] || {};

    if (typeof data["planResponseDTO"] !== "undefined")
      delete data["planResponseDTO"];
    if (typeof data["promoResponseDTO"] !== "undefined")
      delete data["promoResponseDTO"];

    return data;
  };

  parsePlanPriceBreakdown = data => {
    const plans = data["planResponseDTOList"];

    if (plans) {
      data.plans = plans;
      delete data["planResponseDTOList"];
    }

    return data;
  };

  parseUnread = data => {
    const groupUnreads = data["groupIdForUnreadDTOList"] || data;

    if (groupUnreads) {
      for (const group of groupUnreads) {
        if (group["topicResponseDTOList"]) {
          group.topics = group["topicResponseDTOList"];
          delete group["topicResponseDTOList"];
        }
        for (const topic of group.topics) {
          if (topic.groups) {
            for (const g of topic.groups) {
              if (g.profile) {
                g.profile = this.parseProfile({ data: g.profile });
              }
            }
          }
        }
      }
    }

    return groupUnreads;
  };

  parseGeneralDTO = data => {
    const keys = Object.keys(data);

    for (let key of keys) {
      if (key.match(/ResponseDTO/g) && data[key] !== "undefined") {
        data[key.replace(/ResponseDTO/g, "")] =
          !!data[key] && this.parseGeneralDTO(data[key]);
        delete data[key];
      }
    }

    return data;
  };

  /*
    Register preload function of FileService for profile parse use.
   */
  registerAvatarPreload = method => (this.preloadAvatar = method);

  registerDefaultAvatarPreload = method => (this.preloadDefaultAvatar = method);
}

const apiController = new ApiController();

// For development;
if (window && env !== "prod") window.apiController = apiController;

export { apiController };
