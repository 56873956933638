import React from "react";
import { VerificationController } from "./controller";
import { VerificationScreen } from "./view";
import { styles } from "./styles";
import { UIText } from "../../../config/lang-config";

export class Verification extends React.Component {
  static navigationOptions = {
    title: `Verifying Email Address | ${UIText.title}`
  };

  controller = new VerificationController(this.props);

  render() {
    return (
      <VerificationScreen
        {...this.props}
        styles={styles}
        controller={this.controller}
      />
    );
  }
}
