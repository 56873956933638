import { computed, observable } from "mobx";
import { stateController } from "../../../cdm/controllers/state-controller";
import { UIText } from "../../../config/lang-config";
import { apiService } from "../../../cdm/services/api-service";
import { mcbEndpointConfig } from "../../../custom/mcb/config/api-config";
import { capitalize, isEmpty, isNonZeroFalse } from "../../../utils/helpers";
import { renderJobReferenceResponseQuestions } from "../../../custom/mcb/lib/admin-centre-utilities";
import { placeholderPickerValue } from "../../../config/variable-config";

export class CaregiverJobReferenceController {
  @observable loading = true;
  @observable submitting = false;
  @observable submitted = false;

  @observable jobReferenceResponse = {};
  @observable candidateProfile = {};
  @observable questionData = [];

  @computed get id() {
    return stateController.viewCaregiverJobReferenceState.id;
  }
  @computed get onboardingId() {
    return stateController.viewCaregiverJobReferenceState.onboardingId;
  }
  @computed get emailQuestionResponseId() {
    return stateController.viewCaregiverJobReferenceState
      .emailQuestionResponseId;
  }
  @computed get questions() {
    return this.questionData.map(q => q.text);
  }

  @computed get buttonDisabled() {
    return (
      (this.jobReferenceResponse.response || []).filter(
        content =>
          !isEmpty(
            (
              (typeof content === "string" ? content : (content || {}).text) ||
              ""
            ).trim()
          )
      ).length !== this.questions.length
    );
  }

  componentDidMount() {
    stateController.currentScreen = "CaregiverJobReference";
  }

  componentWillUnmount() {
    stateController.clearCaregiverJobReferenceState();
  }

  constructor(props) {
    this.props = props;
    this._initialize();
  }

  _showError = (err, kickout) => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.title,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: () =>
        stateController
          .dismissPopup()
          .then(() => kickout && this.props.navigation.navigate("Login"))
    });
  };

  _initialize = () =>
    this.getJobReferenceResponse()
      .then(this.getCandidateProfile)
      .then(this.renderQuestionsFromServer);

  getJobReferenceResponse = async () => {
    this.loading = true;
    const initError = () =>
      stateController.showPopup({
        title: UIText.title,
        content: UIText.caregiverJobReferenceInvalidLink,
        leftButtonText: UIText.generalConfirm,
        leftButtonPress: () =>
          stateController
            .dismissPopup()
            .then(() => this.props.navigation.navigate("Login"))
      });
    if (!this.id || !this.onboardingId || !this.emailQuestionResponseId)
      return initError();
    return apiService
      .async("GET", {
        endpoint: mcbEndpointConfig.caregiver_job_reference_response(
          this.id,
          this.onboardingId,
          this.emailQuestionResponseId
        )
      })
      .then(response => {
        this.jobReferenceResponse = response.data;
        this.submitted =
          this.jobReferenceResponse["responseStatus"] === "received";
      })
      .catch(initError)
      .finally(() => {
        if (isEmpty(this.jobReferenceResponse)) return initError();
      });
  };

  getCandidateProfile = async () => {
    this.loading = true;
    if (isEmpty(this.jobReferenceResponse)) return (this.loading = false);
    return apiService
      .async("GET", {
        endpoint: mcbEndpointConfig.caregiver_job_reference_candidate_profile(
          this.jobReferenceResponse.id
        )
      })
      .then(response => (this.candidateProfile = response.data))
      .catch(this._showError)
      .finally(() => (this.loading = false));
  };

  renderQuestionsFromServer = async () => {
    this.loading = true;
    if (isEmpty(this.candidateProfile)) return;
    return renderJobReferenceResponseQuestions(this.candidateProfile.data)
      .then(questions => (this.questionData = questions))
      .finally(() => (this.loading = false));
  };

  updateResponseContent = (event, questionIndex) => {
    if (!this.jobReferenceResponse.response)
      this.jobReferenceResponse.response = Array.from(
        new Array(this.questions.length)
      ).map(() => ({}));
    if (isNonZeroFalse(questionIndex)) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      this.jobReferenceResponse.response[questionIndex] = {
        text: event.nativeEvent.text
      };
  };

  updateResponseSelection = (value, selectionIndex, questionIndex) => {
    if (value === placeholderPickerValue.toString()) {
      return (this.jobReferenceResponse.response[questionIndex] = null);
    }
    if (!this.jobReferenceResponse.response)
      this.jobReferenceResponse.response = Array.from(
        new Array(this.questions.length)
      ).map(() => ({}));
    if (isNonZeroFalse(questionIndex)) return;
    this.jobReferenceResponse.response[questionIndex] = {
      text: value,
      selectionIndex
    };
  };

  getResponseContent = questionIndex => {
    if (!this.jobReferenceResponse.response) return "";
    if (isNonZeroFalse(questionIndex)) return "";
    const response = this.jobReferenceResponse.response[questionIndex];
    return (
      (typeof response === "string" ? response : (response || {}).text) || ""
    );
  };

  execSubmit = async () => {
    this.submitting = true;
    return stateController
      .showPopup({
        title: UIText.title,
        content: UIText.pleaseWait
      })
      .then(() =>
        apiService.async("POST", {
          endpoint: mcbEndpointConfig.submit_caregiver_job_reference_response,
          data: this.jobReferenceResponse
        })
      )
      .then(stateController.dismissPopup)
      .then(() =>
        stateController.showPopup({
          title: capitalize(UIText.success),
          content: UIText.caregiverJobReferenceSuccess,
          leftButtonText: UIText.generalConfirm,
          leftButtonPress: stateController.dismissPopup,
          dismissOnBackPress: true
        })
      )
      .then(() => (this.submitted = true))
      .catch(this._showError)
      .finally(() => (this.submitting = false));
  };
}
