import React from "react";
import Sidebar from "react-sidebar";
import { View } from "react-native";
import { observer } from "mobx-react";

@observer
class DrawerContainerView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  render() {
    // return this.controller.getDrawerState() ? (
    //<View style={this.props.styles.style}>
    //<View style={this.props.styles.wrapper}>
    //<DrawerContent {...this.props} />
    //</View>
    //<TouchableOpacity
    //style={this.props.styles.backdrop}
    //onPress={this.controller.hideDrawer}
    ///>
    //</View>
    return (
      <Sidebar
        sidebar={this.props.sidebar}
        dragToggleDistance={80}
        open={this.controller.drawerState}
        docked={this.controller.docked}
        shadow={!this.controller.docked}
        onSetOpen={this.controller.toggleDrawer}
        styles={this.props.styles.style}
      >
        <View />
      </Sidebar>
    );
    // ) : (
    //   <View />
    // );
  }
}

export { DrawerContainerView };
