import { env } from "./variable-config";
import { minutesToMilli } from "../utils/helpers";

export const serverConfig = {
  hostname:
    env === "local"
      ? "localhost"
      : env === "dev"
      ? "leonli.jateruy.com"
      : env === "local"
      ? "devtest.mycarebase.com"
      : `${env}.mycarebase.com`,
  port: env === "local" ? 8081 : env === "dev" ? 8080 : 443,
  https: env !== "dev" && env !== "local",

  defaultHeaders: {
    Authorization: "Basic d2ViYXBwOndlYmFwcA=="
  }
};

export const serverPortString = serverConfig.port
  .toString()
  .match(/^80$|^443$/g)
  ? ""
  : `:${serverConfig.port}`;

export const serverHostnameString = `${
  serverConfig.https ? "https://" : "http://"
}${serverConfig.hostname}${serverPortString}`;

export const devTestHost = "https://devtest.mycarebase.com";

export const endpointConfig = {
  // Maintenance status message.
  maintenance: `${env === "dev" ? devTestHost : ""}/maintenance_status`,
  maintenance_fallback: `${window.location.protocol}//${
    window.location.hostname
  }/maintenance_status`,

  // Before login
  register: `/registration/signup`,
  exists: (type, value) => `/registration/signup/exists/${type}?value=${value}`,
  verify: `/registration/signup/verify`,

  // Login Sequence
  login: "/auth/oauth/token",
  post_login: "/user/post_login",
  user: "/user/me",
  resend_verification: `/registration/signup/resend_verify`,

  // Reset password
  reset_pass_request: `/registration/reset`,
  reset_pass_verify: `/registration/reset/verify`,
  reset_pass_exec: `/registration/reset/exec`,

  // Change email
  reset_email_all: `/registration/reset/email`,
  reset_email_options: `/registration/reset/email/options`,
  verify_email_change: `/registration/reset/email/verify`,
  cancel_email_change: `/registration/reset/email/cancel`,
  revert_email_change_request: `/registration/reset/email/revert_request`,
  revert_email_change_exec: `/registration/reset/email/revert`,
  get_revert_email_expiry: changeRequestId =>
    `/registration/reset/email/revert_expiry/${changeRequestId}`,
  get_email_change: `/userEmail/me`,

  // Check terms and password reset
  get_user_terms_pass_reset: `/registration/users/check_terms_reset`,

  // Invitations
  invite: groupId => `/member/groupId/${groupId}/invite`,
  get_invitation: (invitationId, hash) =>
    `/registration/users/invitation?invitationId=${invitationId}&hash=${hash}`,
  // get_my_invitations: "/invitation/findForMe",
  get_my_invitations: `/userExt/invitation/findForMe`,
  invitation_by_id: invitationId => `/invitation/invitationId/${invitationId}`,
  invitation_login: `/registration/users/invitation/login`,
  invitation_login_verify: `/registration/users/invitation/login/verify`,
  resend_invitation: (groupId, memberId) =>
    `/member/resendInvitation/groupId/${groupId}/memberId/${memberId}`,
  cancel_invitation: memberId =>
    `/member/cancelInvitation/memberId/${memberId}`,
  notify_group_invitation_acceptance: `/groupExt/notifyInvitationAcceptance`,
  erase_intent_role: `/userExt/eraseIntentRole`,

  // Visitor
  save_visitor_info: `/registration/visitor/save`,
  visitor_device_id_login: `/registration/visitor/deviceId`,
  visitor_renew_token: `/registration/visitor/oauth/token`,

  // Groups
  get_default_group: "/group/me/default",
  get_my_groups: "/group/me",
  group_by_id: (groupId, memberId) =>
    `/group/${groupId}?memberId=${memberId ? memberId : ""}`,
  group_by_id_wo_member: groupId => `/group/${groupId}`,
  create_group_profile: "/group/profile",
  groups_by_type_id: typeId => `/group/typeId/${typeId}`,
  group_types: typeId => `/groupType/${typeId ? typeId : ""}`,
  archive_group: (groupId, inform, refund) =>
    `/group/archive/${groupId}/inform/${inform ? 1 : 0}/refund/${
      refund ? 1 : 0
    }`,

  // Members
  get_default_member: "/member/me/default",
  get_my_members: "/member/me",
  get_members: "/member",
  member_by_id: memberId => `/member/${memberId}`,
  create_member: "/member",
  create_member_profile: "/member/profile",
  update_member_profile: memberId => `/member/memberId/${memberId}/profile`,
  update_member_role: "/memberRole",
  members_by_group_id: groupId => `/member/groupId/${groupId}`,
  archive_member: (memberId, inform) =>
    `/member/archive/${memberId}/inform/${inform ? 1 : 0}`,

  // Roles
  get_roles: "/role",
  role_by_id: roleId => `/role/${roleId}`,
  type_roles_by_group_id: (groupId, typeRoleId) =>
    `/role/groupId/${groupId}${
      typeRoleId ? "/groupTypeRoleId/" + typeRoleId : ""
    }`,
  type_roles_by_group_type_id: groupTypeId =>
    `/groupTypeRole/groupTypeId/${groupTypeId}`,

  // Profiles
  profile_by_id: profileId => `/profile/${profileId}`,
  profile_field_in_use: "/formExt/profile/field",

  // Topics
  topic_by_id: (topicId, memberId) =>
    `/topic/${topicId}?memberId=${memberId ? memberId : ""}`,
  topic_member_actor: (groupId, topicId, actorName) =>
    `/topic/topicId/${topicId}/groupId/${groupId}/actorName/${actorName}`,
  create_topic: `/topic`,
  topics_by_group_id: (groupId, topicOnly) =>
    `/topic/groupId/${groupId}?topicOnly=${topicOnly ? 1 : 0}`,
  topics_by_type_id: (typeId, isT, isPT, topicOnly) =>
    `/topic/typeId/${typeId}?isTemplate=${isT ? 1 : 0}&isParentTemplate=${
      isPT ? 1 : 0
    }&topicOnly=${topicOnly ? 1 : 0}`,
  type_topics_by_group_id: (groupId, typeId, isT, isPT) =>
    `/topic/groupId/${groupId}/typeId/${typeId}?isTemplate=${
      isT ? 1 : 0
    }&isParentTemplate=${isPT ? 1 : 0}`,
  sub_topics_by_parent_id: (parentId, topicOnly) =>
    `/topic/parentId/${parentId}?topicOnly=${topicOnly ? 1 : 0}`,
  topic_type_by_id: topicTypeId => `/topicType/${topicTypeId}`,
  topic_by_template_topic_id: (templateTopicId, topicOnly) =>
    `/topic/templateTopicId/${templateTopicId}?topicOnly=${topicOnly ? 1 : 0}`,
  lock_topic: topicId => `/topic/lock/topicId/${topicId}`,

  // Form Engine
  form_class_by_id: typeClassId => `/formTypeClass/${typeClassId}`,
  update_form_class_byId: typeClassId =>
    `/formExt/form/typeClassId/${typeClassId}`,
  form_provinces: "/formProvince",
  toggle_form_province: provinceId =>
    `/formProvince/toggleProvince/${provinceId}`,

  // TX
  transaction_sse: clientId => `/sse/push?clientId=${clientId}`,
  logs_after_tx_id: txId => `/log/afterTxId/${txId}`,

  // Messaging
  create_default_thread: `/thread/default`,
  create_message: "/messaging",
  get_messages: `/messaging/getMessageHistory`,
  read_message: (messageId, memberId) =>
    `/messageMember/readMessage/memberId/${memberId}/messageId/${messageId}`,
  bulk_read_messages: memberId =>
    `/messageMember/readMessages/memberId/${memberId}`,
  // get_unread_messages_count: "/topic/unreadCountForGroup",
  get_unread_messages_count: `/topicExt/unreadCountForGroup`,
  group_chat_lobby_pre_join: groupId => `messaging/preJoin/${groupId}`,

  // Billing
  get_plans: groupId => `/plan/plans/groupId/${groupId}`,
  get_group_subscription: groupId => `/planSubscription/groupId/${groupId}`,
  get_plan_price_breakdown: (planId, provState, isTrial, promo) =>
    `/plan/priceBreakDown/planId/${planId}/provState/${provState}/isTrial/${
      isTrial ? 1 : 0
    }/promo/${promo ? promo : 0}`,
  get_user_stored_payment: userId => `/payment/userId/${userId}`,
  get_promo_code_validity: (groupId, planId, code) =>
    `/promo/check/groupId/${
      groupId ? groupId : 0
    }/planId/${planId}/code/${code}`,
  create_payment_plan: "/planSubscription",
  update_payment: "/planSubscription/updateCustomerPayment",
  stripe_api_key: "/stripe/pk",

  // Files
  file_by_id: fileId => `/fileExt/${fileId}`,
  file_download_by_id: fileId => `/fileExt/download/${fileId}`,
  user_avatar_by_id: avatarId => `/avatarExt/userAvatar/${avatarId}`,
  user_avatar_bulk: `/avatarExt/getBatchUserAvatar`,
  get_user_avatars: userId => `/avatarExt/userAvatar/userId/${userId}`,
  get_default_avatar_id: (memberId, groupId) =>
    `/avatarExt/getDefaultAvatarId?memberId=${memberId ||
      ""}&groupId=${groupId || ""}`,
  get_default_avatar_id_bulk: `/avatarExt/getBatchDefaultAvatarId`,
  upload_files: "/file/upload",
  upload_avatar: "/userAvatar/upload",

  // HTML Templator
  // parse_template: templateName => `/template/template/${templateName}`
  parse_template: templateName => `/template/${templateName}`,

  // Billing
  get wp_hostname() {
    return env === "test"
      ? "wwwtest.mycarebase.com"
      : env === "prod"
      ? "mycarebase.com"
      : "webdev.mycarebase.com";
  },
  wp_auto_login_link: redirectUrl =>
    `/autoLogin/link?redirectUrl=${redirectUrl}`,
  create_new_order: `/wooCommerce/order`,
  wc_payment_methods: `/stripe/paymentMethods`,
  wc_billing_detail: `/wooCommerce/billingDetail`,

  get_wp_url: endpoint => `${endpointConfig.wp_hostname}${endpoint}`,
  subscriptio_active_subscriptions: includeProduct =>
    `/subscriptio/subscriptions/active?includeProduct=${
      includeProduct ? 1 : 0
    }`,
  sync_user_subscriptions: `/subscriptionGroup/syncSubscriptions/user`,
  get_postpaid_product: `/invoice/product/postpaid`,
  get_prepaid_product: `/invoice/product/prepaid`,
  re_run_invoice_generation: confirm =>
    `/invoice/re-run?confirm=${confirm ? 1 : 0}`,

  // R&R
  update_rating_review_by_hash: `/ratingReview/emailSubmit`,
  get_rating_possible_members: (groupId, groupTypeRoleId) =>
    `/ratingReviewRequest/getPossibleTargetMembers4Group/${groupId}/groupTypeRoleId/${groupTypeRoleId}`,
  get_review_possible_group_members: groupId =>
    `/ratingReviewRequest/getReviewPossibleGroups4RoleGroup/${groupId}`,
  trigger_rating_review_request: `/ratingReviewRequest/adminTrigger`,
  get_rating_reviews_by_target_member_id: memberId =>
    `/ratingReview/targetMemberId/${memberId}`,
  get_rating_reviews_by_user_id: userId => `/ratingReview/userId/${userId}`,
  set_rating_review_is_published: id => `/ratingReview/setPublished/${id}`,
  set_admin_rating: `/adminRating`,
  get_testimonials_by_user_id: id => `/testimonial/targetUserId/${id}`,
  new_testimonial: `/testimonial`,
  testimonial_by_id: id => `/testimonial/${id}`,
  get_aggregated_rating: (userId, showUnpublished) =>
    `/ratingReview/aggregatedRating/${userId}?showUnpublished=${
      showUnpublished ? 1 : 0
    }`,
  batch_get_aggregated_rating: `/ratingReview/aggregatedRating`,

  reverse_geocoding: (lat, lon) =>
    `/geoDistance/reverse/json?lat=${lat}&lon=${lon}&formatAddress=true`
};

export const stripeElementsConfig = {
  hostname: `react.mycarebase.com`,
  port: 8887,
  https: true
};

export const tokenIdleTimeout = minutesToMilli(20);
