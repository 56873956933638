import { common } from "../../../config/style-configs/common";
import { theme } from "../../../config/style-configs/theme";
import { responsive } from "../../../config/style-configs/responsive";

export const styles = {
  style: {
    flex: 1
  },
  topicHeading: {},
  topicHeadingTitle: {
    fontSize: theme.FONT_SIZE_LARGE
  },
  get topicHeadingRightTitle() {
    return {
      whiteSpace: "pre",
      textAlign: "right",
      // color: "#666",
      fontSize: responsive.deviceDimension.isDesktop
        ? theme.FONT_SIZE_MEDIUM
        : theme.FONT_SIZE_SMALL + 1
    };
  },
  topicHeadingSubtitle: {
    color: "#666",
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  scrollViewCard: common.scrollViewCard,
  buttons: {
    marginBottom: 10
  },
  buttonReminder: common.listButtonReminderContainer,
  errorMessage: common.containerErrorMessage,
  card: common.card,
  cardContent: common.cardChildrenCompact,
  cardContentLarge: common.cardChildrenTextLarge,
  cardContentMedium: common.cardChildrenTextMedium,
  cardContentSmall: common.cardChildrenTextSmall,
  topicDetail: {
    flex: 1,
    height: "100%",
    alignItems: "flex-start"
  },
  loading: common.loading,
  buttonContainer: common.listButtonContainer
};
