import { computed, observable } from "mobx";
import { clientController } from "../../../../cdm/controllers/client-controller";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { capitalize, isEmpty, safeParseJSON } from "../../../../utils/helpers";
import { UIText } from "../../../../config/lang-config";
import { timesheetTopicsToPayrollPeriods } from "../../config/payroll_periods";
import { groupTypeRoleIds } from "../../../../config/variable-config";
import { apiController } from "../../../../cdm/controllers/api-controller";
import NavigationService from "../../../../utils/navigation-service";
import { apiService } from "../../../../cdm/services/api-service";
import { endpointConfig } from "../../../../config/api-config";
import { mcbEndpointConfig } from "../../config/api-config";

export class ReportTimeSheetInsertController {
  // @observable refreshing = false;
  @observable timesheetLoading = true;
  @observable loading = false;
  @observable hasReportCaregiverIds = [];
  @observable timesheetsForCareCircle = [];
  @observable _useExternalPayrollProcessing;

  loaded = false;

  // Current view group.
  @computed get groupId() {
    return this.props.groupId;
  }
  @computed get group() {
    return this.props.group || clientController.findGroupById(this.groupId);
  }
  @computed get profile() {
    return this.group.profile || {};
  }

  // Completed shifts summary part.
  @computed get currentDateBlocks() {
    return timesheetTopicsToPayrollPeriods(
      this.timesheetsForCareCircle,
      this.profile.data.timezone
    );
  }

  @computed get selectedDateBlockValue() {
    return this.props.state.get && this.props.state.get("dateBlockValue");
  }
  @computed get selectedDateBlock() {
    return (
      this.currentDateBlocks.find(
        block => block.timesheetId.toString() === this.selectedDateBlockValue
      ) || {}
    );
  }
  @computed get dateBlockPickerItems() {
    return [
      {
        placeholder: UIText.reportCompletedShiftSelectDateBlock,
        name: `selectDate`
      },
      ...this.currentDateBlocks.map(block => ({
        placeholder: `${block.placeholder}${
          block.timesheetId === -1 ? " *" : ""
        }`,
        name: block.timesheetId.toString()
      }))
    ];
  }
  @computed get timesheetId() {
    return this.selectedDateBlock.timesheetId;
  }

  // Paid caregivers in the group.
  @computed get caregivers() {
    let members = this.group.members || this.failSafeCaregivers;
    members = members.filter(
      m => m.groupTypeRoleId !== groupTypeRoleIds.careReceiver
    );
    return members || [];
  }
  @computed get failSafeCaregivers() {
    return (this.props.state.get && this.props.state.get("caregivers")) || [];
  }
  @computed get caregiverFilter() {
    return this.props.caregiverFilter || (i => i);
  }
  @computed get shouldRenderCaregivers() {
    return this.caregivers
      .filter(this.caregiverFilter)
      .filter(c => this.hasReportCaregiverIds.includes(c.id))
      .filter(Boolean);
  }

  @computed get useExternalPayrollProcessing() {
    return (this.profile.data || {})["useExternalPayrollProcessing"];
  }

  constructor(props) {
    this.props = props;
    this._initialize();
    this._useExternalPayrollProcessing = this.useExternalPayrollProcessing;
  }

  _showError = err => {
    console.warn(err);
    return stateController.showPopup({
      title: capitalize(UIText.group),
      content: (err && err.message) || err,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: stateController.dismissPopup
    });
  };

  _initialize = force => {
    if (this.loaded) return;
    if (this.props.showCollapsible && !this.props.collapsed && !force) return;
    this.getTimesheetTopicsForCareCircle()
      .then(this.getCaregivers)
      .then(this.onDateBlockChange)
      .catch(this._showError)
      .finally(() => {
        this.loading = this.timesheetLoading = false;
        this.loaded = true;
      });
  };

  getTimesheetTopicsForCareCircle = async () => {
    return await apiService
      .async("GET", {
        endpoint: mcbEndpointConfig.get_timesheets_for_care_circle(this.groupId)
      })
      .then(response => (this.timesheetsForCareCircle = response.data))
      .catch(console.error);
  };

  getCaregivers = async () => {
    if (
      !isEmpty(this.timesheetsForCareCircle) &&
      Array.isArray(this.caregivers)
    ) {
      if (this.caregivers.length === 0) {
        await apiController
          .getMembersByGroupId(this.groupId)
          .then(
            members =>
              this.props.state.set &&
              this.props.state.set("caregivers", members)
          );
      }

      for (let caregiver of this.caregivers) {
        if (isEmpty(caregiver.profile)) {
          await apiController
            .getProfileById(caregiver.profileId)
            .then(profile => (caregiver.profile = profile));
        }
      }
    }
    return Promise.resolve();
  };

  onDateBlockChange = async () => {
    this.hasReportCaregiverIds = [];

    if (
      this.selectedDateBlockValue === "selectDate" ||
      !this.selectedDateBlockValue
    )
      return;

    this.loading = true;

    await Promise.all(
      this.caregivers.map(member =>
        this.checkCaregiverMemberReport(member.id).then(
          hasReport => hasReport && this.hasReportCaregiverIds.push(member.id)
        )
      )
    );

    this.loading = false;
  };

  checkCaregiverMemberReport = async caregiverId => {
    const data = {
      groupId: this.groupId,
      caregiverId,
      startDate: this.selectedDateBlock.start,
      endDate: this.selectedDateBlock.end,
      timesheetId: this.timesheetId // timesheetId = -1 for current period
    };

    return apiController
      .getReportCompletedShiftSummary(data)
      .then(reportData => !isEmpty(reportData) && !isEmpty(reportData.detail))
      .catch(err => {
        this._showError(err);
        return false;
      });
  };

  handleDateBlockChange = value => {
    this.props.state.set && this.props.state.set("dateBlockValue", value);

    return setTimeout(this.onDateBlockChange);
  };

  handleShiftSummaryCaregiverPress = (event, memberId) => {
    if (isEmpty(this.caregivers.find(m => m.id === memberId))) return;

    const startDate =
      this.timesheetId < 0 ? this.selectedDateBlock.start.getTime() : undefined;
    const endDate =
      this.timesheetId < 0 ? this.selectedDateBlock.end.getTime() : undefined;

    const params = {
      group: this.groupId,
      caregiver: memberId,
      startDate,
      endDate,
      timesheetId: this.timesheetId // timesheetId = -1 for current period
    };

    return NavigationService.navigate("ShiftSummary", params);
  };

  handlePayrollOptionChange = async event => {
    return stateController
      .showPopup({
        title: UIText.adminCentrePayrollOption,
        content: UIText.pleaseWait
      })
      .then(() =>
        apiController.getProfileById(this.group.profileId).then(profile => {
          if (isEmpty(profile) || isEmpty(profile.data)) return;
          profile.data["useExternalPayrollProcessing"] = !this
            ._useExternalPayrollProcessing;
          return apiService
            .async("PATCH", {
              endpoint: endpointConfig.profile_by_id(profile.id),
              data: { data: JSON.stringify(profile.data) }
            })
            .then(response => response.data);
        })
      )
      .then(profile => {
        const data = profile && safeParseJSON(profile.data);
        if (data)
          this._useExternalPayrollProcessing = !!data[
            "useExternalPayrollProcessing"
          ];
      })
      .then(stateController.dismissPopup);
  };
}
