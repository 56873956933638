import { Observer, observer } from "mobx-react";
import { topicTypeIds } from "../../../../config/variable-config";
import { TaskCard } from "../TaskCard";
import React from "react";

export const TopicTaskListView = observer(props => {
  const {
    controller,
    tasks,
    timezone,
    handleClockInClockOut,
    handleTopicItemPress
  } = props;
  const sortedTask = controller.sortTasks(tasks);

  return sortedTask.map(t =>
    t.typeId === topicTypeIds.task ? (
      <Observer key={t.id}>
        {() => (
          <TaskCard
            task={t}
            couldComplete={controller.getTaskCompletionCapability(t)}
            onIconPress={() => handleClockInClockOut(t)}
            onPress={() => handleTopicItemPress(t)}
            detailCompleted={
              controller.getDetailCompletion(t) && t.detailCompleted
            }
            timezone={timezone}
          />
        )}
      </Observer>
    ) : null
  );
});
