import React from "react";
import { observer } from "mobx-react";
import { Linking, Platform, Text, View } from "react-native";
import { capitalize, isEmpty } from "../../../../utils/helpers";
import { Divider } from "react-native-elements";
import { common } from "../../../../config/style-configs/common";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { UIText } from "../../../../config/lang-config";
import { observable, toJS } from "mobx";
import {
  renderJobReferenceResponseQuestions,
  renderResponsesHtml
} from "../../lib/admin-centre-utilities";

@observer
class AdminReferenceResponseDetailView extends React.Component {
  @observable questions = [];

  constructor(props) {
    super(props);
    this.renderQuestionsFromServer().catch(props.showError);
  }

  renderQuestionsFromServer = async () => {
    const { profile } = this.props;
    if (isEmpty(profile)) return;
    return renderJobReferenceResponseQuestions(profile.data).then(
      questions => (this.questions = questions)
    );
  };

  renderReferenceResponse = (jobReferenceResponse, i) => {
    const { jobReferenceResponses } = this.props.onboarding || {};
    const openEmail = () => {
      if (Platform.OS === "web") {
        return window.open(`mailTo:${jobReferenceResponse.email}`);
      } else {
        return Linking.openURL(`mailTo:${jobReferenceResponse.email}`);
      }
    };
    const openPhone = () => {
      if (Platform.OS === "web") {
        return window.open(`tel:${jobReferenceResponse.phone}`);
      } else {
        return Linking.openURL(`tel:${jobReferenceResponse.phone}`);
      }
    };
    const openResponse = () =>
      stateController.showPopup({
        title: UIText.caregiverJobReferenceFrom(
          jobReferenceResponse.firstName || jobReferenceResponses.lastName
        ),
        contentHtml: renderResponsesHtml(
          toJS(this.questions),
          toJS(jobReferenceResponse.response)
        ),
        leftButtonText: UIText.generalConfirm,
        dismissOnBackPress: true
      });
    return (
      <View style={this.props.styles.item} key={jobReferenceResponse.id}>
        <Text style={this.props.styles.text}>
          <Text>
            <b>Reference {i + 1}:</b>
          </Text>
          <br />

          <Text>
            First name:&nbsp;<b>{jobReferenceResponse.firstName || "⁠—"}</b>
          </Text>
          <br />

          <Text>
            Last name:&nbsp;<b>{jobReferenceResponse.lastName || "⁠—"}</b>
          </Text>
          <br />

          {jobReferenceResponse.email && (
            <>
              <Text>
                Email:&nbsp;
                <Text style={common.linkBlue} onClick={openEmail}>
                  {jobReferenceResponse.email}
                </Text>
              </Text>
              <br />
            </>
          )}

          {jobReferenceResponse.phone && (
            <>
              <Text>
                Phone:&nbsp;
                <Text style={common.linkBlue} onClick={openPhone}>
                  {jobReferenceResponse.phone}
                </Text>
              </Text>
              <br />
            </>
          )}

          <Text>
            Request date:&nbsp;
            <Text style={this.props.styles.textColor}>
              {new Date(jobReferenceResponse.requestedDate).toLocaleString()}
            </Text>
          </Text>
          <br />

          <Text>
            Response status:&nbsp;
            <Text style={this.props.styles.textColor}>
              {capitalize(jobReferenceResponse.responseStatus)}
            </Text>
          </Text>
          <br />

          {jobReferenceResponse.responseDate && (
            <>
              <Text>
                Response date:&nbsp;
                <Text style={this.props.styles.textColor}>
                  {new Date(jobReferenceResponse.responseDate).toLocaleString()}
                </Text>
              </Text>
              <br />
            </>
          )}

          {!isEmpty(jobReferenceResponse.response) && !isEmpty(this.questions) && (
            <>
              <Text
                style={{
                  ...common.link,
                  marginTop: 5
                }}
                onClick={openResponse}
              >
                See responses
              </Text>
              <br />
            </>
          )}
        </Text>

        {i !== jobReferenceResponses.length - 1 && (
          <View style={{ marginTop: this.props.styles.item.marginBottom }}>
            <Divider />
          </View>
        )}
      </View>
    );
  };

  render() {
    const { jobReferenceResponses } = this.props.onboarding || {};
    if (isEmpty(jobReferenceResponses)) return null;
    return (
      <View style={this.props.styles.style}>
        {jobReferenceResponses.map(this.renderReferenceResponse)}
      </View>
    );
  }
}

export { AdminReferenceResponseDetailView };
