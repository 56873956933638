import React from "react";
import { CareReceiverShiftListView } from "./view";
import { CareReceiverShiftListController } from "./controller";
import { styles } from "./styles";

export class CareReceiverShiftList extends React.Component {
  controller = new CareReceiverShiftListController(this.props);

  render() {
    return (
      <CareReceiverShiftListView
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
