import { clientController } from "../../../../../../cdm/controllers/client-controller";
import { careReceiverForm, monitorProvinceFieldDirty } from "./form";
import { groupTypeIds } from "../../../../../../config/variable-config";
import { formService } from "../../../../../../cdm/services/form-service";
import {
  addLocationFieldToProfile,
  createCareReceiverMember,
  createGroupProfile,
  createSelfMember
} from "../../../../lib/group-utilities-mcb";
import { stateController } from "../../../../../../cdm/controllers/state-controller";
import { isEmpty } from "../../../../../../utils/helpers";
import { apiService } from "../../../../../../cdm/services/api-service";
import { endpointConfig } from "../../../../../../config/api-config";
import { mcbEndpointConfig } from "../../../api-config";
import {
  getGroupRoles,
  submitPaymentPlanChange,
  updateGroupProfile
} from "../../../../../../cdm/lib/group-utilities";
import { updateMarketForm } from "./marketplace/setup";
import { UIText } from "../../../../../../config/lang-config";

// Setup function.
const updatePlanSubscription = async groupId => {
  const selectedPlan =
    stateController.viewPlanState.plans.find(p => p.checked) || {};
  const payment = {
    userId: clientController.userId,
    paymentSource: stateController.viewPlanState.ccToken.id
  };
  const planSubscription = {
    planId: selectedPlan.id,
    groupId,
    chargeDiscountFactor: selectedPlan.chargeDiscountFactor
  };
  const promoCode = stateController.viewPlanState.promoCode;
  const trial = !selectedPlan.displayName && !promoCode;
  const customer = stateController.viewPlanState.billingFormData;

  return submitPaymentPlanChange({
    planSubscription,
    payment,
    customer,
    trial,
    promoCode
  }).then(() => groupId);
};

const updateGroupName = group => {
  if (isEmpty(group))
    return Promise.reject({
      message: "Cannot find group data. [updateGroupName]"
    });

  const groupName = group["groupName"];

  if (!groupName) return;

  const data = {
    groupName: groupName.replace(/{scratchpad}/g, "")
  };

  return apiService
    .async("PATCH", {
      endpoint: endpointConfig.group_by_id_wo_member(group.id),
      data
    })
    .then(() => group);
};

const notifyCareReceiverGroupCreation = async (group, minuteLimit) => {
  if (isEmpty(group))
    return Promise.reject({
      message: "Cannot find group data. [notifyCareReceiverGroupCreation]"
    });

  const selfMember =
    Array.isArray(group.members) &&
    group.members.find(m => m.userId === clientController.userId);

  if (!selfMember) return;

  const selfMemberProfileData =
    (selfMember.profile && selfMember.profile.data) ||
    (clientController.defaultMember.profile &&
      clientController.defaultMember.profile.data);

  const careCircleGroupProfileRequestDTO = {
    data:
      group.profile && group.profile.data && JSON.stringify(group.profile.data)
  };
  const personalMemberProfileRequestDTO = {
    data: JSON.stringify(selfMemberProfileData)
  };

  const data = {
    careCircleGroupProfileRequestDTO,
    personalMemberProfileRequestDTO,
    groupId: group.id,
    groupTypeId: group.typeId,
    userId: clientController.userId,
    ifCheckCreatedTime: minuteLimit ? 1 : 0
  };

  return apiService
    .async("POST", {
      endpoint: mcbEndpointConfig.notify_care_circle_creation,
      data
    })
    .then(response => {
      console.log(response);
    })
    .catch(err => {
      console.error(err);
    });
};

export const careReceiverSetup = () => ({
  key: "careReceiverSetup",
  title: "Care Recipient",
  heading: "Tell us more about your loved one who is needing care",
  checkboxTitle:
    "I want access to Care Management Tools to help ensure that the Care Receiver is getting the care they require.",
  form: careReceiverForm(),
  additionalFormProcessors: [
    profileForm => addLocationFieldToProfile(profileForm, null, true),
    monitorProvinceFieldDirty
  ],
  onBlur: field => {
    field.errorMessage = "";
    if (field.required && !field.value)
      field.errorMessage = `Enter a ${(
        field.label || field.placeholder
      ).toLowerCase()}`;
  },
  onSubmit: async ([form, errorFields], store) => {
    // Group creation
    if (!form || !isEmpty(errorFields)) {
      return Promise.reject({
        message: UIText.checkEntriesWithFields(errorFields),
        contentAlign: "left"
      });
    }
    const roleTypeId = Number(form.find(f => f.name === "role").value);
    form = form.filter(f => f.name !== "role");
    const profileDetail = formService.disassembleFormData(form, {
      displayName: true
    });
    let group = {
      typeId: groupTypeIds.careReceiver, // Care Receiver type.
      owner: clientController.userId,
      parentId: null
    };
    return (
      createGroupProfile(group, profileDetail, roleTypeId, store)
        .then(getGroupRoles)
        .then(createSelfMember)
        .then(createCareReceiverMember)
        .then(groupId => (group.id = groupId))
        .then(clientController.getUserGroupsAndMembers)
        // .then(() => updatePlanSubscription(group.id))
        .then(() => clientController.findGroupById(group.id))
        .then(group => notifyCareReceiverGroupCreation(group, true))
        .then(() => updateMarketForm(form))
    );

    // return Promise.resolve()
  },
  validate: async form => {
    const pass = formService.validateRequired(form);
    if (!pass) {
      const errorFields = form.filter(f => !!f.errorMessage);
      return [false, errorFields];
    }
    return [form];
  }
});

export const scratchpadFinishSetup = () => ({
  key: "scratchpadFinishSetup",
  title: "Care Recipient",
  heading: "Tell us more about your loved one who is needing care",
  checkboxTitle: "Finish Care Circle Setup",
  form: careReceiverForm(true), // true === without search fields.
  onBlur: field => {
    field.errorMessage = "";
    if (field.required && !field.value)
      field.errorMessage = `Enter a ${(
        field.label || field.placeholder
      ).toLowerCase()}`;
  },
  onSubmit: async ([form, errorFields], store) => {
    const errorMessage = "Previous setup was unsuccessful.";

    // Group update
    if (!form || !isEmpty(errorFields)) {
      return Promise.reject({
        message: UIText.checkEntriesWithFields(errorFields),
        contentAlign: "left"
      });
    }

    const group = clientController.findGroupById(stateController.viewGroupId);
    if (isEmpty(group)) return Promise.reject(`${errorMessage}[empty_group]`);

    const selfMember = group.members.find(
      m => m.userId === clientController.userId
    );

    const roleTypeId = Number(form.find(f => f.name === "role").value);
    const role =
      Array.isArray(group.roles) &&
      group.roles.find(r => r.groupTypeRoleId === roleTypeId);

    form = form.filter(f => f.name !== "role");
    const profileDetail = formService.disassembleFormData(form, {
      displayName: true
    });
    const originalData = (group.profile && group.profile.data) || {};
    const profileData = { ...originalData, ...profileDetail };

    return updateGroupProfile(group.profileId, JSON.stringify(profileData))
      .then(() => {
        !isEmpty(role) &&
          apiService.async("PUT", {
            endpoint: endpointConfig.update_member_role,
            data: {
              memberId: selfMember.id,
              roleId: role.id
            }
          });
        return group;
      })
      .then(updateGroupName)
      .then(clientController.getUserGroupsAndMembers)
      .then(() => clientController.setScratchpadId(0))
      .then(() => (stateController.viewGroupId = group.id))
      .then(() => clientController.findGroupById(group.id))
      .then(group => notifyCareReceiverGroupCreation(group, false));
  },
  validate: async form => {
    const pass = formService.validateRequired(form);
    if (!pass) {
      const errorFields = form.filter(f => !!f.errorMessage);
      return [false, errorFields];
    }
    return [form];
  }
});
