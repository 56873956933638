import { theme } from "../../config/style-configs/theme";

const bubble = {
  flex: 1,
  padding: 10,
  backgroundColor: theme.chatBubbleColor,
  borderRadius: 8
};

const badgeSize = 12;

export const styles = {
  style: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    padding: 10
  },
  messageAvatar: {
    marginRight: 10
  },
  messageAvatarOwn: {
    marginLeft: 10
  },
  messageTexts: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start"
  },
  messageTextsOwn: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-end"
  },
  nameText: {
    fontWeight: "bold",
    color: "#444444",
    paddingBottom: 5
  },
  bubbleWrapper: {
    maxWidth: "60%",
    flex: 1,
    flexDirection: "row"
  },
  bubble,
  bubbleOwn: {
    ...bubble,
    backgroundColor: theme.chatBubbleColorOwn
  },
  unreadBadge: {
    position: "absolute",
    top: -2,
    right: -4,
    zIndex: 2
  },
  unreadBadgeSize: {
    maxWidth: badgeSize,
    maxHeight: badgeSize,
    minWidth: 0,
    minHeight: 0,
    borderRadius: badgeSize / 2
  },
  sending: {
    position: "absolute",
    left: -30,
    alignSelf: "center"
  },
  text: {
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  timestampText: {
    paddingTop: 5,
    fontSize: theme.FONT_SIZE_SMALL,
    color: "#444444"
  },
  bgUnderlay: {
    backgroundColor: theme.color,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%"
  }
};
