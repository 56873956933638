import { clientController } from "../../../../../../cdm/controllers/client-controller";
import { careReceiverSetup, scratchpadFinishSetup } from "./setup";
import { marketplaceSetup } from "./marketplace/setup";
import { stateController } from "../../../../../../cdm/controllers/state-controller";
import { getDisplayNameEng, isEmpty } from "../../../../../../utils/helpers";
import { groupTypeIds } from "../../../../../../config/variable-config";
import { apiController } from "../../../../../../cdm/controllers/api-controller";
import { handleGroupBottomTabChange } from "../../../../../../cdm/lib/group-utilities";

export const careReceiverGroup = (
  isFirstTime,
  finishScratchpad,
  convertScratchpad,
  fromSubscription
) => ({
  preSetup: clientController.getUserGroupsAndMembers,
  rootBack: {
    // icon: isFirstTime ? "exit-to-app" : null,
    onPress:
      // isFirstTime
      // ? () => clientController.execLogout(true, true)
      // ? props => {
      //   stateController.viewGroupId = -1;
      //   props.navigation.navigate("Root");
      // }
      // :
      finishScratchpad
        ? convertScratchpad
          ? props => {
              props.navigation.navigate("Group");
            }
          : props => {
              stateController.viewGroupId = 0;
              props.navigation.navigate("Root");
            }
        : fromSubscription
        ? props => {
            stateController.viewPlanState.setupMode = true;
            stateController.viewPlanState.currentPage = "confirm";
            props.navigation.navigate("Plan");
          }
        : props => {
            stateController.initSetupMode = "addGroup";
            props.resetSetup();
          }
  },
  skipSelect: finishScratchpad,
  initialTitle: isFirstTime
    ? "First Time Setup"
    : `New ${
        clientController.findGroupTypeById(groupTypeIds.careReceiver).typeName
      }`,
  initialHeading: `Hi, ${getDisplayNameEng(
    clientController.defaultMember.profile
  )}, welcome!\nChoose the following options to start with:`,
  finishTitle: "Setup Complete",
  setupList: [
    !finishScratchpad && {
      ...careReceiverSetup()
    },
    !finishScratchpad && {
      ...marketplaceSetup()
    },
    finishScratchpad && {
      ...scratchpadFinishSetup()
    }
  ].filter(Boolean),
  onFinishSetup: props => {
    const navigatePlanTab = async groupId => {
      const group = clientController.findGroupById(groupId);
      if (isEmpty(group) || group.typeId !== groupTypeIds.careReceiver)
        return Promise.resolve();
      if (isEmpty(group.subscription)) {
        await apiController
          .getSubscriptionByGroupId(groupId)
          .then(subscription => {
            if (isEmpty(subscription)) return Promise.resolve();
            clientController.updateSubscription(subscription, group.id);
          });
      }
      const plan = (group.subscription && group.subscription.plan) || {};
      return handleGroupBottomTabChange(
        groupId,
        plan["isMarketplaceMessageEnabled"] ? "marketplace" : "members"
      );
    };

    return isFirstTime
      ? (() => {
          const group = clientController.findVisibleGroups()[0] || {};
          if (!isEmpty(group)) navigatePlanTab(group.id);
          return props.navigation.navigate("Root");
        })()
      : finishScratchpad
      ? (() => {
          return navigatePlanTab(stateController.viewGroupId).then(() =>
            props.navigation.navigate("Group")
          );
        })()
      : (() => {
          const groups = clientController.findVisibleGroups();
          if (groups.length > 0) {
            const groupId = groups.sort((a, b) => b.id - a.id)[0].id;
            stateController.viewGroupId = groupId;
            return navigatePlanTab(groupId).then(() =>
              props.navigation.navigate("Group", { group: groupId })
            );
          }
        })();
  },
  finishHeading: isFirstTime && "Thank you for completing the initial setup.",
  footerNote:
    "*If you are interested in hiring a Paid Provider, a portion of our Care Management Tools will be used to collect certain information about the care recipient so that an optimal match may be found for you.\n\nPlease be assured that all information collected will kept strictly confidential and in compliance with all privacy regulations"
});
