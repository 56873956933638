import { apiService } from "../../../../../../cdm/services/api-service";
import { endpointConfig } from "../../../../../../config/api-config";
import { stateController } from "../../../../../../cdm/controllers/state-controller";
import { clientController } from "../../../../../../cdm/controllers/client-controller";
import { invitationForm } from "./form";
import {
  capitalize,
  getDisplayNameEng,
  isEmpty,
  validateEmail
} from "../../../../../../utils/helpers";
import { formService } from "../../../../../../cdm/services/form-service";
import { UIText } from "../../../../../../config/lang-config";
import { apiController } from "../../../../../../cdm/controllers/api-controller";

const postInvite = async (formData, group) => {
  // console.log(formData);

  return apiService
    .async("POST", {
      endpoint: endpointConfig.invite(group.id),
      data: formData
    })
    .catch(err => {
      const isArchived = err && err.response && err.response.status === 406;
      if (isArchived) {
        const member =
          err.response.data &&
          apiController.parser.parseMember(err.response.data);
        return handleArchived(formData, group, member);
      }

      return Promise.reject(err);
    });
};

const handleArchived = async (formData, group, member) => {
  member = member || {};
  const originalName = getDisplayNameEng(member.profile || member);
  // const isHired = member.profile && (member.profile.typeClassId === typeClassIds.paidCaregiverProfile);

  return new Promise((resolve, reject) => {
    const rePostInvite = async type => {
      formData[type] = true;
      return stateController
        .dismissPopup()
        .then(() =>
          stateController.showPopup({
            title: capitalize(UIText.invitation),
            content: UIText.pleaseWait
          })
        )
        .then(() => postInvite(formData, group))
        .then(resolve)
        .catch(reject);
    };

    return stateController.dismissPopup().then(() =>
      stateController.showPopup({
        title: capitalize(UIText.invitation),
        content: UIText.invitationPreviouslyInvited(
          formData.email,
          originalName,
          getDisplayNameEng(formData)
        ),
        contentAlign: "left",
        buttonSet: [
          {
            title: UIText.invitationReinstate,
            onPress: () => rePostInvite("reinstate")
            // disabled: true
          },
          {
            title: UIText.invitationInviteNew,
            onPress: () => rePostInvite("forceCreateNew"),
            showDivider: true
          },
          {
            title: UIText.generalCancel,
            onPress: () => {
              reject({});
              setTimeout(stateController.dismissPopup);
            }
          }
        ].map(b => (b.align = "center") && b)
      })
    );
  });
};

// Setup function.
export const inviteSetup = () => {
  const group = clientController.findGroupById(stateController.viewGroupId);

  return {
    key: "inviteSetup",
    title: capitalize(UIText.invitation),
    heading: "Send an invitation",
    checkboxTitle: "I want to invite my family member.",
    form: invitationForm(group),
    onBlur: field => {
      field.errorMessage = "";
      if (field.required && !field.value)
        field.errorMessage = `Enter a ${(
          field.label || field.placeholder
        ).toLowerCase()}`;
      if (field.name === "email") {
        field.errorMessage = validateEmail(field.value)
          ? ""
          : (field.value || "").match(/\+/)
          ? UIText.registrationEmailNoPlusSign
          : "Enter a valid email address.";
      }
    },
    onSubmit: async ([form, errorFields]) => {
      if (!form || !isEmpty(errorFields)) {
        return Promise.reject({
          message: UIText.checkEntriesWithFields(errorFields),
          contentAlign: "left"
        });
      }

      const roles = group && group.roles;
      const selfMember =
        group && group.members.find(m => m.userId === clientController.userId);

      if (!group || !roles)
        return Promise.reject({
          message: "Cannot invite, missing required information."
        });

      let roleSelection = form.find(field => field.name === "role");
      roleSelection = (roleSelection && Number(roleSelection.value)) || 0;
      const role = roles.find(role => role.id === roleSelection);

      if (!role)
        return Promise.reject({ message: "Invalid type of invitation." });

      let formData = {
        groupId: group.id,
        email: form.find(field => field.name === "email").value,
        firstName: form.find(field => field.name === "firstName").value,
        lastName: form.find(field => field.name === "lastName").value,
        roleIdList: [role.id],
        customRoleDescription: form.find(
          field => field.name === "customRoleDescription"
        ).value,
        invitorMemberId: selfMember.id,
        forceCreateNew: false,
        reinstate: false
      };

      return postInvite(formData, group).then(
        clientController.getUserGroupsAndMembers
      );
    },
    validate: async form => {
      let pass = formService.validateRequired(form);
      if (!pass) {
        const errorFields = form.filter(f => !!f.errorMessage);
        return [false, errorFields];
      }
      for (let field of form) {
        if (field.name === "email") {
          pass = validateEmail(field.value);
          if (!pass) {
            field.errorMessage = (field.value || "").match(/\+/)
              ? UIText.registrationEmailNoPlusSign
              : "Enter a valid email address.";
            return [false, [field]];
          }
        }
      }
      return [form];
    }
  };
};
