import { computed, observable } from "mobx";
import { clientController } from "../../../../cdm/controllers/client-controller";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { capitalize, getBrowserTimeZoneName } from "../../../../utils/helpers";
import { UIText } from "../../../../config/lang-config";
import { apiController } from "../../../../cdm/controllers/api-controller";
import NavigationService from "../../../../utils/navigation-service";
import { subscriptionPopup } from "../../../../cdm/lib/group-utilities";
import { serviceRecipientGroupTypeIds } from "../../../../config/variable-config";

export class CareReceiverSmryListController {
  @observable refreshing = false;
  @observable loaded = false;

  @observable summaryData = {};

  @computed get loading() {
    return !this.loaded;
  }
  // Current view group.
  @computed get group() {
    return clientController.findGroupById(stateController.viewGroupId);
  }
  @computed get timezone() {
    if (!serviceRecipientGroupTypeIds.includes(this.group.typeId))
      return getBrowserTimeZoneName();
    return (
      ((this.group.profile || {}).data || {}).timezone ||
      getBrowserTimeZoneName()
    );
  }
  @computed get lastCompletedShift() {
    return this.summaryData["lastShift"] || {};
  }
  @computed get subscription() {
    return this.group.subscription || this.group.wpSubscription || {};
  }
  @computed get currentPlan() {
    return this.subscription.plan || {};
  }

  @computed get collapsible() {
    if (!stateController.viewGroupState.collapsible["summary"][this.group.id])
      stateController.viewGroupState.collapsible["summary"][this.group.id] = {};
    return stateController.viewGroupState.collapsible["summary"][this.group.id];
  }

  constructor(props) {
    this.props = props;
    this.subscriptionPopup = props.subscriptionPopup;
    this._initialize();
  }

  _showError = err => {
    console.warn(err);
    return stateController.showPopup({
      title: capitalize(UIText.group),
      content: (err && err.message) || err,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: stateController.dismissPopup
    });
  };

  _initialize = () => {
    this._getSummaryData()
      .catch(this._showError)
      .finally(() => (this.loaded = true));
  };

  _getSummaryData = async () => {
    return apiController.getDashboardSummary(this.group.id).then(data => {
      const keys = Object.keys(data);
      for (let key of keys) {
        if (data[key] === null) data[key] = 0;
      }
      this.summaryData = data;
    });
  };

  handleTopicPress = topic => {
    this.subscriptionCheck(capitalize(UIText.shiftMakerShifts)).then(pass => {
      if (!pass) return;
      stateController.viewTopicId = topic.id;
      NavigationService.navigate("Topic", { topic: topic.id });
    });
  };

  handleIssueCardPress = event => {
    // this.subscriptionCheck()
    // .then(pass => {
    //   if (!pass) return;
    stateController.viewGroupState.topTabs.main[this.group.id] = "issue";
    // });
  };

  handleStatCardPress = event => {
    this.subscriptionCheck(capitalize(UIText.shiftMakerShifts)).then(pass => {
      if (!pass) return;
      stateController.viewGroupState.topTabs.main[this.group.id] = "shift";
    });
  };

  handleCollapse = name => {
    stateController.viewGroupState.collapsible["summary"][this.group.id][
      name
    ] = !this.collapsible[name];
  };

  subscriptionCheck = async toolName => {
    if (!this.currentPlan.isCareEnabled) {
      subscriptionPopup(null, toolName);
      return false;
    }
    return true;
  };
}
