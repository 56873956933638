import { computed, observable, reaction } from 'mobx';
import { serviceProviderGroupTypeIds } from '../../../../config/variable-config';
import { stateController } from '../../../../cdm/controllers/state-controller';
import { clientController } from '../../../../cdm/controllers/client-controller';
import { isEmpty } from '../../../../utils/helpers';
import { filterController } from '../../../../cdm/controllers/filter-controller';
import {
  adminCentreCheckDirectProfileEntry,
  adminCentreGetAllGroups,
  adminCentreGetNextGroups,
  adminCentreGetOnboardingTopics,
  adminCentreProcessGroups,
  adminCentreSortByAlpha,
  adminCentreSortByDate,
  adminCentreSortCards
} from '../../lib/admin-centre-utilities';
import { formService } from '../../../../cdm/services/form-service';
import { isServiceProviderGroup } from '../../lib/group-utilities-mcb';

export class AdminProviderGroupPageController {
  disposers = [];
  @observable loading = false;
  @observable paging = false;
  @computed get pagingStatus() {
    if (!stateController.viewAdminCentreState.pagingStatus) {
      stateController.viewAdminCentreState.pagingStatus = {};
    }
    if (!stateController.viewAdminCentreState.pagingStatus[this.groupTypeId]) {
      stateController.viewAdminCentreState.pagingStatus[this.groupTypeId] = {};
    }
    if (
      !stateController.viewAdminCentreState.pagingStatus[this.groupTypeId][
        this.topTabKey
      ]
    ) {
      stateController.viewAdminCentreState.pagingStatus[this.groupTypeId][
        this.topTabKey
      ] = {};
    }
    return stateController.viewAdminCentreState.pagingStatus[this.groupTypeId][
      this.topTabKey
    ];
  }
  @computed get noMoreNext() {
    return this.pagingStatus && !this.pagingStatus.next;
  }
  pagingSize = 14;

  @computed get groupTypeId() {
    if (!stateController.viewAdminCentreState.providerGroupTypeId) {
      stateController.viewAdminCentreState.providerGroupTypeId =
        serviceProviderGroupTypeIds[0];
    }
    return stateController.viewAdminCentreState.providerGroupTypeId;
  }

  @computed get groupTypes() {
    return serviceProviderGroupTypeIds
      .map(
        typeId =>
          !isEmpty(clientController.findGroupTypeById(typeId)) &&
          clientController.findGroupTypeById(typeId)
      )
      .filter(Boolean);
  }
  @computed get groupType() {
    return clientController.findGroupTypeById(this.groupTypeId);
  }
  @computed get cards() {
    return stateController.viewAdminCentreState.cards[this.groupTypeId] || [];
  }
  @computed get sortBy() {
    return (
      stateController.viewAdminCentreState.sortBy[this.groupTypeId] ||
      "dateAscend"
    );
  }
  @computed get pageSortBy() {
    return this.sortBy.match(/date/g) ? "id" : "name";
  }
  @computed get isDesc() {
    return this.sortBy === "dateAscend"
      ? false
      : this.sortBy === "dateDescend"
      ? true
      : this.sortBy === "alphaAscend";
  }
  @computed get topTabKey() {
    const key = stateController.viewAdminCentreState.providerTabKey;
    if (!key) stateController.viewAdminCentreState.providerTabKey = "approved";
    return stateController.viewAdminCentreState.providerTabKey;
  }
  @computed get hideAvatar() {
    return stateController.viewAdminCentreState.hideAvatar[this.groupTypeId];
  }

  get topTabs() {
    return [
      {
        key: "approved",
        name: "Approved",
        onTabPress: this.handleTopTabChange
      },
      {
        key: "candidates",
        name: "Candidates",
        onTabPress: this.handleTopTabChange
      }
    ];
  }

  @computed get filterCategories() {
    return filterController.AdminCentreProviderFilter;
  }
  @computed get filteredGroups() {
    let template = this.cards;
    return filterController
      .applyFilterCategories(template, this.filterCategories)
      .slice()
      .sort(this.sortCards);
  }
  @computed get hasFilter() {
    return (
      this.filterCategories &&
      this.filterCategories.some(f => !!f.selected && f.selected !== f.default)
    );
  }
  setFilter = filterCategories =>
    filterController.filterSetter(this.filterCategories, filterCategories);

  @computed get visibleGroups() {
    const groups = this.filteredGroups;
    const approvedGroups = groups.filter(c => {
      const profile = !!c.profile && c.profile.data;
      return profile && !!profile["approvalOfCandidateConfirmedByReference"];
    });
    const candidateGroups = groups.filter(c => {
      const profile = !!c.profile && c.profile.data;
      return profile && !profile["approvalOfCandidateConfirmedByReference"];
    });
    return this.topTabKey === "approved" ? approvedGroups : candidateGroups;
  }

  get showError() {
    return this.props.showError;
  }

  constructor(props) {
    this.props = props;
    this._initialize().then(() => {
      this.disposers.push(
        reaction(
          () => this.sortBy,
          () => this._getAllGroups().catch(this.showError)
        )
      );
      // this.disposers.push(
      //   reaction(
      //     () => this.topTabKey && isEmpty(this.visibleGroups),
      //     () => this.getAndProcessNextPageGroups().catch(this.showError)
      //   )
      // );
    });
  }

  _initialize = async force => {
    this.loading = true;

    if (this.cards.length > 0 && !force) {
      this.loading = false;
      return Promise.resolve();
    }

    return this._getAllGroups()
      .catch(this.showError)
      .finally(this.checkDirectProfileEntry);
  };

  _getAllGroups = async () => {
    this.setPagingStatus(false);
    stateController.viewAdminCentreState.cards[this.groupTypeId] = [];
    // return this.getAndProcessNextPageGroups();
    return this.getAndProcessAllGroups();
  };

  getAndProcessAllGroups = async () => {
    if (!isServiceProviderGroup({ typeId: this.groupTypeId })) return;
    this.loading = true;
    const { groupTypeId } = this;
    return adminCentreGetAllGroups(groupTypeId, () =>
      this.setPagingStatus(false)
    )
      .then(this._processNextPageGroups)
      .finally(() => setTimeout(() => (this.paging = this.loading = false)));
  };

  getAndProcessNextPageGroups = async directProfileEntry => {
    this.paging = true;
    return this._getNextPageGroups(directProfileEntry)
      .then(this._processNextPageGroups)
      .finally(() => setTimeout(() => (this.paging = false)));
  };

  _getNextPageGroups = async directProfileEntry => {
    const { groupTypeId } = this;
    if (!isServiceProviderGroup({ typeId: groupTypeId }))
      return [null, groupTypeId];
    const isApproved = this.topTabKey === "approved" ? 1 : 0;
    return adminCentreGetNextGroups(
      groupTypeId,
      this.pagingStatus,
      this.pageSortBy,
      this.pagingSize,
      this.isDesc,
      !directProfileEntry,
      isApproved,
      this.setPagingStatus
    );
  };

  _processNextPageGroups = ([data, groupTypeId]) =>
    this._processGroups([data, groupTypeId])
      .then(this._getOnboardingTopics)
      .then(this._setGroups);

  _processGroups = async ([data, groupTypeId]) =>
    adminCentreProcessGroups(data, groupTypeId);

  _getOnboardingTopics = async ([groups, groupTypeId]) => {
    if (!isServiceProviderGroup({ typeId: groupTypeId }))
      return [groups, groupTypeId];
    if (isEmpty(groups)) return [groups, groupTypeId];
    return adminCentreGetOnboardingTopics(groups, groupTypeId);
  };

  _setGroups = async ([groups, groupTypeId]) => {
    stateController.viewAdminCentreState.cards[groupTypeId] = [
      ...this.cards.filter(c => !groups.some(g => g.group.id === c.group.id)),
      ...groups
    ];
    return groupTypeId;
  };

  checkDirectProfileEntry = async () => {
    const groupTypeId = this.props.navigation.getParam("serviceType");
    const directEntryProfileId =
      this.props.navigation.getParam("profile") ||
      this.props.navigation.getParam("profileId");

    if (groupTypeId && Number(groupTypeId) !== this.groupTypeId) {
      await this.handleGroupTypeChange(groupTypeId);
    }

    if (directEntryProfileId) {
      return adminCentreCheckDirectProfileEntry(
        directEntryProfileId,
        groupTypeId,
        this.getAndProcessAllGroups,
        this.props.handleCardPress
      ).finally(() => (this.loading = false));
    }

    this.loading = false;
  };

  setPagingStatus = next => (this.pagingStatus.next = next);

  handleTopTabChange = tab =>
    (stateController.viewAdminCentreState.providerTabKey = tab.key);

  handleGroupTypeChange = typeId => {
    stateController.viewAdminCentreState.providerGroupTypeId = Number(typeId);
    return this._initialize();
  };

  sortByDate = () => adminCentreSortByDate(this.groupTypeId, this.sortBy);

  sortByAlpha = () => adminCentreSortByAlpha(this.groupTypeId, this.sortBy);

  sortCards = (a, b) => adminCentreSortCards(a, b, this.sortBy);

  showHideAvatars = () =>
    (stateController.viewAdminCentreState.hideAvatar[this.groupTypeId] = !this
      .hideAvatar);

  getPickerPlaceholder = formService.getFormPickerPlaceholderValue;

  onRefresh = () => {
    this.loading = true;
    return this._initialize(true).finally(() => (this.loading = false));
  };

  onScroll = event => {
    stateController.viewAdminCentreState.scrollPos[this.groupTypeId] =
      event.nativeEvent.contentOffset.y;
    // const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
    // const isAtBottom =
    //   layoutMeasurement.height + contentOffset.y >= contentSize.height / 1.5;
    // if (!isAtBottom) return;
    // if (this.hasFilter) return;
    // if (this.noMoreNext) return;
    // if (this.paging) return;
    // this.paging = true;
    // return setTimeout(() => this.getAndProcessNextPageGroups());
  };
}
