import React from "react";
import { GroupScreen } from "./view";
import { GroupController } from "./controller";
import { styles } from "./styles";
import NavigationService from "../../../utils/navigation-service";

export class Group extends React.Component {
  static navigationOptions = {
    title: "Group"
  };

  controller = new GroupController(this.props);

  render() {
    const { navigation } = this.props;

    NavigationService.setTopLevelNavigator(navigation);

    return (
      <GroupScreen
        {...this.props}
        styles={styles}
        controller={this.controller}
      />
    );
  }
}
