import { theme } from "../../config/style-configs/theme";

export const styles = {
  table: {
    flex: 1
  },
  tableRow: {
    flexDirection: "row"
  },
  tableColumns: {
    flexDirection: "column"
  },
  tableCell: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 8,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc"
  },
  text: {
    fontSize: theme.FONT_SIZE_SMALL
  }
};
