import React from 'react';
import { ScrollView, View } from 'react-native';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { apiService } from '../../cdm/services/api-service';
import { common } from '../../config/style-configs/common';
import { Button, Icon } from 'react-native-elements';
import { theme } from '../../config/style-configs/theme';
import { FormField } from '../FormField';
import { safeParseJSON } from '../../utils/helpers';
import { serverConfig } from '../../config/api-config';

@observer
class ApiTestList extends React.Component {
  @observable data = "";
  @observable postData = "";
  @observable url = "";
  @observable method = "GET";
  @observable noCred = false;
  @observable openNewTab = false;
  @observable loading = false;
  @observable duration = "Not started";

  constructor(props) {
    super(props);
  }

  getData = async () => {
    this.loading = true;
    const start = new Date().getTime();

    return apiService
      .async(this.method, {
        headers: {
          ...(this.noCred && serverConfig.defaultHeaders),
          "Content-Type": "application/json"
        },
        endpoint: this.url,
        data: this.postData && safeParseJSON(this.postData)
      })
      .then(response => {
        console.log(response);
        this.data = response.data || "";
        if (this.openNewTab) {
          // const doc = window.open("/");
          // setTimeout(() =>
          //   doc.document.write(JSON.stringify(this.data), null, 2)
          // );
          window.open(this.url);
        }
      })
      .catch(err => {
        console.warn(err);
        this.data = err.response && err.response.data;
      })
      .finally(() => {
        this.loading = false;
        const end = new Date().getTime();
        this.duration = `${end - start} ms`;
      });
  };

  render() {
    return (
      <ScrollView contentContainerStyle={common.scrollView}>
        <View style={common.scrollViewContent}>
          <FormField
            field={{
              type: "input",
              placeholder: `URL`,
              showLabel: true
            }}
            inputValue={this.url}
            inputRightIcon={
              <Button
                type="clear"
                loading={this.loading}
                loadingProps={{ color: theme.color }}
                icon={
                  <Icon name="keyboard-tab" size={24} color={theme.color} />
                }
                onPress={this.getData}
              />
            }
            onInputChange={e => (this.url = e.nativeEvent.text)}
            onSubmitEditing={this.getData}
          />
          <FormField
            field={{
              type: "picker",
              placeholder: `Method`,
              options: ["GET", "POST", "PATCH", "PUT", "DELETE"],
              value: this.method,
              showLabel: true
            }}
            onPickerChange={value => (this.method = value)}
          />
          <FormField
            field={{
              type: "checkbox",
              placeholder: `Open in new tab`,
              value: this.openNewTab,
              showLabel: true
            }}
            enabled={true}
            onCheckboxChange={() => (this.openNewTab = !this.openNewTab)}
          />
          <FormField
            field={{
              type: "checkbox",
              placeholder: `Use basic auth (No cred)`,
              value: this.noCred,
              showLabel: true
            }}
            enabled={true}
            onCheckboxChange={() => (this.noCred = !this.noCred)}
          />
          {(this.method === "POST" ||
            this.method === "PUT" ||
            this.method === "PATCH") && (
            <FormField
              field={{
                type: "input",
                placeholder: `Post data (JSON)`,
                showLabel: true,
                multiline: true,
                numberOfLines: 10
              }}
              onInputChange={e => (this.postData = e.nativeEvent.text)}
              inputValue={this.postData}
              onSubmitEditing={this.getData}
            />
          )}
          <FormField
            field={{
              type: "input",
              placeholder: `Duration`,
              showLabel: true
            }}
            inputValue={this.duration}
            enabled={false}
          />
          <FormField
            field={{
              type: "input",
              placeholder: `Result data`,
              showLabel: true,
              multiline: true,
              autoLineHeight: true
            }}
            inputValue={JSON.stringify(this.data, null, 2)}
            enabled={false}
          />
        </View>
      </ScrollView>
    );
  }
}

export { ApiTestList };
