/******************************************************
 *  TODO: Break into AvatarService and FileService  *
 *****************************************************/

import { Platform } from "react-native";
import {
  avatarSizeLimit,
  env,
  fileSizeLimit
} from "../../config/variable-config";
import { action, observable, toJS } from "mobx";
import { apiController } from "../controllers/api-controller";
import { stateController } from "../controllers/state-controller";
import {
  asyncPause,
  capitalize,
  isEmpty,
  minutesToMilli
} from "../../utils/helpers";
import { UIText } from "../../config/lang-config";
import { apiService } from "./api-service";
import { endpointConfig } from "../../config/api-config";
import * as SparkMD5 from "spark-md5";
import { clientController } from "../controllers/client-controller";
import { computedFn } from "mobx-utils";
import * as mime from "react-native-mime-types";

class FileService {
  @observable avatars = [];
  @observable defaultAvatars = {
    group: {},
    member: {}
  };
  avatarLoading = {};
  avatarBlobLoading = {};

  checkDefaultAvatarInterval = minutesToMilli(5);

  defaultAvatarQueue = [];
  getAvatarQueue = [];

  constructor() {
    apiController.registerAvatarPreload(this.preloadProfileAvatar);
    apiController.registerDefaultAvatarPreload(this.preloadEntityDefaultAvatar);
  }

  overSizedAvatar = async file => {
    if (file.size > avatarSizeLimit * 1024)
      return stateController
        .showPopup({
          title: capitalize(UIText.fileUpload),
          content: UIText.fileUploadAvatarOversize,
          leftButtonText: UIText.generalConfirm
        })
        .then(() => false);

    return true;
  };

  overSizedFile = async file => {
    if (file.size > fileSizeLimit * 1024)
      return stateController
        .showPopup({
          title: capitalize(UIText.fileUpload),
          content: UIText.fileUploadFileOversize,
          leftButtonText: UIText.generalConfirm
        })
        .then(() => false);

    return true;
  };

  MD5FileSignature = async file =>
    Platform.OS === "web"
      ? this.MD5FileSignatureWeb(file)
      : this.MD5FileSignatureNative(file);

  MD5FileSignatureWeb = async file => {
    const blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice,
      chunkSize = 2 * 1024 * 1024, // Read in chunks of 2MB
      chunks = Math.ceil(file.size / chunkSize),
      spark = new SparkMD5.ArrayBuffer(),
      fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      let currentChunk = 0;

      const loadNext = () => {
        let start = currentChunk * chunkSize,
          end = start + chunkSize >= file.size ? file.size : start + chunkSize;

        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
      };

      fileReader.onload = e => {
        spark.append(e.target.result);
        currentChunk++;

        if (currentChunk < chunks) {
          loadNext();
        } else {
          resolve(spark.end());
        }
      };

      fileReader.onerror = reject;

      loadNext();
    });
  };

  MD5FileSignatureNative = file => {
    return "";
  };

  uploadAvatar = async (file, memberId) => {
    if (!file || !memberId) return;

    return stateController
      .showPopup({
        title: capitalize(UIText.fileUpload),
        content: UIText.pleaseWait
      })
      .then(() => this.upload(file, memberId, true));
  };

  uploadFiles = async files => {};

  upload = async (file, memberId, isAvatar) => {
    return this.MD5FileSignature(file)
      .then(hash => {
        const formData = new FormData();
        formData.append(isAvatar ? "avatar" : "file", file, file.name);
        formData.append("signature", hash);
        formData.append("uploadMemberId", memberId);

        return formData;
      })
      .then(formData =>
        isAvatar
          ? apiController.uploadAvatar(formData)
          : apiController.uploadFile(formData)
      );
  };

  @action
  preloadProfileAvatar = async (avatarId, options) => {
    if (!avatarId) return;
    if (this.isAvatarLoading(avatarId)) return;

    options = options || {};

    const avatar = this.getProfileAvatarById(avatarId);
    if (isEmpty(avatar)) {
      this.avatarLoading[avatarId] = true;
      this.getAvatarQueue.push(avatarId);
      clearTimeout(this.getAvatarQueueTimeout);
      return (this.getAvatarQueueTimeout = setTimeout(() => {
        const queue = toJS(this.getAvatarQueue);
        this.getAvatarQueue = [];
        this.bulkLoadUserAvatar(queue).then(() =>
          Promise.all(
            queue.map(id =>
              asyncPause().then(() => this.loadAvatarBinary(id, options))
            )
          )
        );
      }, 200));
    } else if (!avatar.uri || (options.loadOrig && !avatar.origUri)) {
      this.avatarLoading[avatarId] = true;
      return this.loadAvatarBinary(avatarId, options);
    }
  };

  @action
  preloadEntityDefaultAvatar = async (entityId, entityType) => {
    if (!entityId || !entityType) return;
    if (!this.defaultAvatars[entityType][entityId]) {
      this.defaultAvatars[entityType][entityId] = {};
    }

    if (this.shouldCheckDefaultAvatar(entityId, entityType)) {
      this.defaultAvatars[entityType][
        entityId
      ].lastChecked = new Date().getTime();
      const queue = this.defaultAvatarQueue.find(
        q => q.entityType === entityType && q.entityId === entityId
      );
      if (!!queue) return;
      this.defaultAvatarQueue.push({ entityType, entityId });
      clearTimeout(this.defaultAvatarLoadTimeout);
      this.defaultAvatarLoadTimeout = setTimeout(() => {
        this.bulkLoadDefaultAvatars(toJS(this.defaultAvatarQueue));
        this.defaultAvatarQueue = [];
      }, 200);
    }
  };

  @action
  loadAvatarBinary = async (avatarId, options) => {
    options = options || {};
    this.avatarLoading[avatarId] = true;
    return this.getProfileAvatarBlobUriAsync(avatarId, options.loadOrig)
      .then(
        action(uri => {
          const avatar = this.getProfileAvatarById(avatarId);
          if (options.loadOrig) {
            avatar.origUri = uri;
          } else {
            avatar.uri = uri;
          }
          return avatar;
        })
      )
      .catch(console.warn)
      .finally(
        () =>
          this.isAvatarLoading(avatarId) && delete this.avatarLoading[avatarId]
      );
  };

  @action
  bulkLoadDefaultAvatars = async queue => {
    queue = queue || [];
    if (queue.length === 0) return;
    if (!clientController.loginState) return;

    return apiService
      .async("POST", {
        endpoint: endpointConfig.get_default_avatar_id_bulk,
        data: queue
      })
      .then(
        action(async response => {
          const defaultAvatars = Array.isArray(response.data) && response.data;
          const getDefaultAvatar = entity => {
            const avatarId =
              !isNaN(Number(entity.avatarId)) && Number(entity.avatarId);
            if (!avatarId) return;
            this.defaultAvatars[entity.entityType][
              entity.entityId
            ].id = avatarId;

            if (isEmpty(this.getProfileAvatarById(avatarId))) {
              return this.preloadProfileAvatar(avatarId);
            }
          };
          return Promise.all(defaultAvatars.map(getDefaultAvatar));
        })
      )
      .catch(console.warn);
  };

  @action
  bulkLoadUserAvatar = async queue => {
    if (isEmpty(queue)) return;
    if (!clientController.loginState) return;
    return apiService
      .async("POST", {
        endpoint: endpointConfig.user_avatar_bulk,
        data: queue
      })
      .then(
        action(response => {
          const userAvatars = (response || {}).data || [];
          for (const avatar of userAvatars) {
            if (this.avatars.some(a => a.id === avatar.id)) continue;
            this.avatars.push(avatar);
          }
        })
      )
      .catch(console.warn);
  };

  shouldCheckDefaultAvatar = (entityId, entityType) => {
    if (!entityId || !entityType) return false;

    const checkedDefaultAvatar = this.defaultAvatars[entityType][entityId];
    if (!checkedDefaultAvatar || !checkedDefaultAvatar.lastChecked) return true;

    return (
      new Date().getTime() -
        new Date(checkedDefaultAvatar.lastChecked).getTime() >=
      this.checkDefaultAvatarInterval
    );
  };

  isAvatarLoading = avatarId => !!this.avatarLoading[avatarId];

  @action
  resetDefaultAvatarChecks = () => {
    for (let [entityTypeKey, entityType] of Object.entries(
      this.defaultAvatars
    )) {
      for (let [entityKey, entity] of Object.entries(entityType)) {
        // if (!entity.id) {
        setTimeout(
          action(() => delete this.defaultAvatars[entityTypeKey][entityKey])
        );
        // }
        if (entity.lastChecked) delete entity.lastChecked;
      }
    }
  };

  @action
  resetAllAvatars = () => {
    this.resetDefaultAvatarChecks();
    this.avatars.clear();
    this.defaultAvatars.group = {};
    this.defaultAvatars.member = {};
  };

  getProfileAvatarById = avatarId =>
    this.avatars.find(a => a.id === avatarId) || {};

  getDefaultAvatarByEntityId = (entityId, entityType) => {
    if (!entityId || !entityType) return {};
    const defaultAvatar = this.defaultAvatars[entityType][entityId];
    if (!defaultAvatar) return {};
    return this.avatars.find(a => a.id === defaultAvatar.id) || {};
  };

  @action
  getProfileAvatar = async avatarId => {
    return apiController
      .getUserAvatarById(avatarId)
      .then(
        action(avatar => {
          if (isEmpty(avatar)) return;
          this.avatars.push(avatar);
          return Promise.resolve(!isEmpty(avatar));
        })
      )
      .catch(console.warn);
  };

  @action
  getProfileAvatarBlobUriAsync = async (avatarId, loadOrig) => {
    if (this.avatarBlobLoading[avatarId]) return "";
    this.avatarBlobLoading[avatarId] = true;

    const avatar = this.getProfileAvatarById(avatarId);
    if (isEmpty(avatar)) {
      this.avatarBlobLoading[avatarId] = false;
      return this.getProfileAvatar(avatarId).then(
        hasAvatar =>
          hasAvatar && this.getProfileAvatarBlobUriAsync(avatarId, loadOrig)
      );
    }

    const fileId = (!loadOrig && avatar["thumbFileId"]) || avatar["oriFileId"];
    return this.downloadAndGetFileBlobUrlById(fileId)
      .catch(console.warn)
      .finally(
        action(
          () =>
            this.avatarBlobLoading[avatarId] &&
            delete this.avatarBlobLoading[avatarId]
        )
      );
  };

  getProfileAvatarUri = computedFn((avatarId, entityId, entityType) => {
    toJS(this.avatars.length);
    const nativeAvatar = this.getProfileAvatarById(avatarId);
    const defaultAvatar = this.getDefaultAvatarByEntityId(entityId, entityType);

    const avatar = isEmpty(nativeAvatar)
      ? isEmpty(defaultAvatar)
        ? {}
        : defaultAvatar
      : nativeAvatar;

    if (avatarId && isEmpty(avatar)) {
      this.preloadProfileAvatar(avatarId);
      return undefined;
    }

    if (entityId && entityType && isEmpty(avatar)) {
      setTimeout(() => this.preloadEntityDefaultAvatar(entityId, entityType));
    } else if (this.shouldCheckDefaultAvatar(entityId, entityType)) {
      setTimeout(() => this.preloadEntityDefaultAvatar(entityId, entityType));
    }

    return isEmpty(avatar) ? undefined : avatar.uri;
  });

  getProfileAvatarOriginalUri = computedFn(avatarId => {
    const avatar = this.getProfileAvatarById(avatarId);
    if ((avatarId && isEmpty(avatar)) || !avatar.origUri) {
      this.preloadProfileAvatar(avatarId, { loadOrig: true });
      return avatar.uri || "";
    }

    return avatar.origUri || avatar.uri || "";
  });

  downloadAndGetFileBlobUrlById = async fileId =>
    apiController.getFileBinaryById(fileId).then(blob => {
      if (Platform.OS === "web") {
        return window.URL.createObjectURL(blob);
      }
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onerror = reject;
        reader.onload = e => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    });

  invokeWebDownload = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  getMediaTypes = type =>
    Array.isArray(type)
      ? type.map(mime.lookup)
      : mime.lookup(type)
      ? mime.lookup(type)
      : "*";
}

const fileService = new FileService();

// For development;
if (window && env !== "prod") window.fileService = fileService;

export { fileService };
