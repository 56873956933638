import React from "react";
import {
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View
} from "react-native";

export const TouchableOpacityIOSFixView = props => {
  const isWeb = Platform.OS === "web";
  const isIOSWeb = isWeb && window.navigator.userAgent.match(/iPhone/g);
  return isIOSWeb ? (
    <TouchableWithoutFeedback {...props}>
      <View>{props.children}</View>
    </TouchableWithoutFeedback>
  ) : (
    <TouchableOpacity {...props} />
  );
};
