import { action, computed, observable, reaction, toJS } from "mobx";
import { stateController } from "../../cdm/controllers/state-controller";
import { clientController } from "../../cdm/controllers/client-controller";
import {
  isEmpty,
  preventDefaultStopProp,
  safeParseJSON
} from "../../utils/helpers";
import NavigationService from "../../utils/navigation-service";
import { apiController } from "../../cdm/controllers/api-controller";
import { isSystemUIHiddenTopic } from "../../cdm/lib/topic-utilities";
import { formService } from "../../cdm/services/form-service";
import { topicTypeIds } from "../../config/variable-config";

export class TopicSearchBarController {
  @observable loading = false;
  @observable searchLoading = false;
  @observable searchResults = [];

  topicLoading = {};
  execSearchTimeout;

  @computed get groupId() {
    return stateController.viewGroupId;
  }
  @computed get group() {
    return clientController.findGroupById(this.groupId);
  }
  @computed get visible() {
    if (!stateController.viewGroupState.searchBar[this.groupId]) {
      stateController.viewGroupState.searchBar[this.groupId] = {};
    }
    return stateController.viewGroupState.searchBar[this.groupId]["visible"];
  }
  @computed get searchFieldValue() {
    if (!stateController.viewGroupState.searchBar[this.groupId]) {
      stateController.viewGroupState.searchBar[this.groupId] = {};
    }
    return (
      stateController.viewGroupState.searchBar[this.groupId][
        "searchFieldValue"
      ] || ""
    );
  }
  @computed get topicData() {
    if (!stateController.viewGroupState.searchBar[this.groupId]) {
      stateController.viewGroupState.searchBar[this.groupId] = {};
    }
    return stateController.viewGroupState.searchBar[this.groupId]["topics"];
  }
  @computed get searchData() {
    const topics = this.topicData || [];
    return topics.filter(t => this.isValidSearchTopic(t));
  }
  // @computed get searchResults() {
  //   if (!this.searchFieldValue) return [];
  //   const topics = toJS(this.searchData);
  //   return topics.map(t => this.filter(t, topics)).filter(Boolean);
  // }
  @computed get searchResultsByTopicType() {
    const types = [];
    for (let topic of this.searchResults) {
      let type = types.find(
        t => t.name === (topic["topicTypeName"] || "unknown")
      );
      if (!type) {
        type = {
          name: topic["topicTypeName"] || "unknown",
          topics: []
        };
        types.push(type);
      }
      type.topics.push(topic);
    }
    return types;
  }

  @computed get hasResults() {
    return !isEmpty(this.searchResultsByTopicType);
  }

  constructor(props) {
    this.props = props;
    reaction(() => this.visible, this.onVisibilityChange);
  }

  getTopicData = topic => {
    if (!topic || !topic.id || this.topicLoading[topic.id]) return;
    this.topicLoading[topic.id] = true;
    return apiController
      .getTopicById(topic.id)
      .then(topic => {
        const topics = this.topicData || [];
        const index = topics.findIndex(t => t.id === topic.id);
        if (index >= 0) {
          stateController.viewGroupState.searchBar[this.groupId]["topics"][
            index
          ] = topic;
        }
      })
      .finally(() => {
        this.topicLoading[topic.id] = false;
        this.search();
      });
  };

  onVisibilityChange = () => {
    clearTimeout(this.visibilityDebounce);
    this.visibilityDebounce = setTimeout(() => {
      if (this.visible) {
        this.loading = true;
        apiController
          .getTopicsByGroupId(this.groupId, true)
          .then(async topics => {
            stateController.viewGroupState.searchBar[
              this.groupId
            ].topics = topics;
            for (let topic of topics) {
              await formService.findFormClassByIdAsync(topic.typeClassId);
            }
          })
          .finally(() => {
            this.loading = false;
            this.search();
          });
      }
    }, 100);
  };

  onSearchFieldChange = event => {
    if (!event) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      stateController.viewGroupState.searchBar[this.groupId][
        "searchFieldValue"
      ] = event.nativeEvent.text;

    if (!this.searchLoading) this.searchLoading = true;

    clearTimeout(this.execSearchTimeout);
    this.execSearchTimeout = setTimeout(this.search, 500);
  };

  isValidSearchTopic = topic => {
    if (isEmpty(topic)) return false;
    if (isSystemUIHiddenTopic(topic)) return false;

    const typeId = topic.typeId;

    if (typeId === topicTypeIds.candidate) return false;
    if (typeId === topicTypeIds.messaging) {
      return false;

      /* Will enable below in the future
        as this will involve displaying and searching
        the correct chat member name
        (as opposed to just topic description). */

      // const topicMembers = topic.members;
      // if (!topicMembers) {
      //   this.getTopicData(topic);
      //   return false;
      // }
      // return Array.isArray(topicMembers) &&
      //   topicMembers.length === 2 &&
      //   topicMembers.find(m => m.userId === clientController.userId);
    }
    return true;
  };

  searchFilter = (topic, topics) => {
    const matches = [];

    const regExp = new RegExp(this.searchFieldValue, "i");
    const matchDescpt = topic.description && topic.description.match(regExp);
    if (matchDescpt) {
      matches.push({
        type: "description",
        text: matchDescpt.input,
        highlight: [matchDescpt[0]]
      });
    }

    const parentName =
      topic.parentId &&
      (topics.find(t => t.id === topic.parentId) || {}).description;
    const data = safeParseJSON(topic.data);

    let matchTypeClass;
    if (data) {
      const form = formService.findFormClassById(topic.typeClassId);
      const topicForm = formService
        .assembleFormData(form, data, {
          override: true
        })
        .map(field => {
          let value =
            !field.hidden &&
            field.type === "input" &&
            // || field.type === "picker"
            typeof field.value === "string" &&
            !!data[field.name] &&
            field.value;

          if (!value) return false;

          const renderFlags = ["marketplace", "search"]; // These two are more important than anything else.
          const renderValueModifier =
            formService.getFieldRenderValueModifier(field, renderFlags) ||
            (value => value);

          if (field.type === "input") {
            field.value = renderValueModifier(value);
          }
          if (field.type === "picker") {
            field.value = (() => {
              const option =
                Array.isArray(field.options) &&
                field.options.find(o => o.name === value);
              return option && option.placeholder;
            })();
          }
          return field.value && field.value.match(regExp) && field;
        })
        .filter(Boolean);

      matchTypeClass = !isEmpty(topicForm);
      if (matchTypeClass) {
        const truncateLeng = 50;
        const truncateValue = value => {
          const match = value.match(regExp);
          if (!value || !match) return {};
          const start = match.index - truncateLeng;
          const end = match.index + truncateLeng;
          const truncateStart = start > 0;
          const truncateEnd = end < value.length;
          return {
            text: `${truncateStart ? "..." : ""}${value.substring(start, end)}${
              truncateEnd ? "..." : ""
            }`,
            match: match
          };
        };
        const fields = topicForm.map(f => {
          const value = truncateValue(f.value);
          return {
            type: "typeClass",
            placeholder: f.placeholder || f.title,
            text: value.text,
            highlight: [value.match && value.match[0]]
          };
        });
        matches.push(...fields);
      }
    }
    return (
      (matchDescpt || matchTypeClass) && {
        parentName,
        ...topic,
        matches
      }
    );
  };

  @action
  search = () => {
    if (!this.searchFieldValue) {
      this.searchResults.clear && this.searchResults.clear();
    } else {
      const topics = toJS(this.searchData);
      this.searchResults = topics
        .map(t => this.searchFilter(t, topics))
        .filter(Boolean);
    }
    return (this.searchLoading = false);
  };

  handleSearchResultPress = topic => {
    if (isEmpty(topic) || !topic.id) return;

    const { id } = topic;

    this.dismiss();

    stateController.viewTopicId = id;

    if (
      topic.typeId === topicTypeIds.groupMessaging ||
      topic.typeId === topicTypeIds.groupChatLobby ||
      topic.typeId === topicTypeIds.messaging
    ) {
      return NavigationService.navigate("Chat", { topic: id });
    }

    return NavigationService.navigate("Topic", { topic: id });
  };

  clearInput = () => {
    stateController.viewGroupState.searchBar[this.groupId]["searchFieldValue"] =
      "";
    this.search();
  };

  dismiss = (event, clearInput) => {
    preventDefaultStopProp(event);
    stateController.viewGroupState.searchBar[this.groupId].visible = false;
    clearInput && this.clearInput();
  };
}
