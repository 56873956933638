import { groupMemberWording } from "../variable-config";

export default {
  title: "myCareBase",

  login: "Sign in",
  loginProgress: "Signing in...",
  postLoginProgress: "Loading profile...",
  loginWrongPassword: "Incorrect username or password.",
  loginUnverified:
    "Please verify your email address before proceeding signing in.",
  loginFailure: "Sign in failed!",
  loginFieldUsername: "Username, or Email, or Phone",
  loginFieldPassword: "Password",
  loginLinkRegister: "Create an account",
  loginLinkReset: "Trouble signing in",

  registration: "registration",
  registrationProgress: "Creating your account...",
  registrationSuccess:
    "Your account has been created! Please check your email for further instructions.",
  registrationFailure: "Sign-up failed",
  registrationUniqueFields: {
    email: "email",
    username: "username"
  },
  registrationUniqueExists: "already exists.",
  registrationConfirmPassword: "Confirm your password",
  registrationPasswordErrorLowerNum8:
    "Password must be at least 8 characters with numbers and letters",
  registrationPasswordNotMatch: "Password does not match",
  registrationAgreement: "Will open agreement page.",

  verificationSuccess:
    "You have successfully verified your account! Please proceed to sign in.",
  verificationFailure: "Verification failed",
  verificationExpiry: "Your verification link is invalid or expired!",

  entryRequiring: "Enter",
  entryRequiringSingular: "Enter a",
  entryRequiringValid: "Enter a valid",

  clientLoading: "Refreshing",

  success: "success",
  comingSoon: "Coming soon.",
  pleaseWait: "Please wait...",
  checkEntries: "Please review your entries.",

  password: "password",
  drawer: "Side Menu",
  drawerGroupLabel: "Your roles and groups",
  marketplace: "Marketplace",
  settings: "Settings",
  logout: "Sign out",
  typeRoleGroupIndicator: "Role",
  newGroup: "New role or group",

  invite: "invite",
  invitation: "invitation",
  rejectOwnInvitation: "Sorry, you cannot accept your own invitation.",
  pendingInvitation:
    "You have new invitation awaiting,\nwould you like to view them now?",

  generalConfirm: "Ok",
  generalCancel: "Cancel",
  generalAccept: "Yes",
  generalDeny: "No",

  pageNotExist: "Page does not exist.",
  chatNotExist: "The chat no longer exist.",

  marketShortlist: "Shortlist",
  marketShortListPickerLabel: "Add candidates to this shortlist:",
  marketNewShortlist: "New shortlist",
  marketNewShortlistInputPlaceholder: "New shortlist name",
  marketNewShortlistInput: "Name of new shortlist",
  marketDefaultShortlistName: "Shortlist 1",
  marketEditShortlistName: "Edit shortlist name",
  marketTabCriteria: "Search\ncriteria",
  marketTabSearch: "Search\nresults",
  marketTabCandidates: "Shortlisted\ncandidates",
  marketSearchNoMatch:
    "Sorry, we could not find a match for your search criteria.",
  marketCandidateNoMatch: "No candidates to show.",
  marketResetCriteria: "Reset criteria",
  marketSaveCriteria: "Save to profile",
  marketSeeSearchResults: "See search results",
  marketAddToShortlist: "Add to shortlist",
  marketAlreadyInShortlist: "Added to shortlist",
  marketTopicRemoveDeny: "Candidate cannot be removed when there are messages.",

  memberSelf: "Me",
  memberPending: "Pending",

  group: "group",
  topic: "topic",
  profile: "profile",
  chat: "chat",
  setup: "setup",
  details: "details",

  groupTabDashboard: "Dashboard",
  groupTabCaregiving: "caregiving",
  groupTabCaregivingTab: "Caregiving tab",
  groupMemberWording: groupMemberWording,

  chatMessageEmpty: "No messages yet.",
  chatMessageEnd: "End of messages.",
  chatNewMessage: "New message has arrived, view it now?",

  profileSaving: "Saving profile...",
  profileEdit: "edit",

  setupComplete: "Complete",
  setupFinish: "Finish",
  setupGeneralFinishMsg: "Thank you for your information"
};
