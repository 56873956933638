import { computed, observable, toJS } from "mobx";
import { clientController } from "../../../../cdm/controllers/client-controller";
import { stateController } from "../../../../cdm/controllers/state-controller";
import {
  serviceRecipientGroupTypeIds,
  topicTypeIds
} from "../../../../config/variable-config";
import { apiController } from "../../../../cdm/controllers/api-controller";
import NavigationService from "../../../../utils/navigation-service";
import {
  capitalize,
  getBrowserTimeZoneName,
  isEmpty
} from "../../../../utils/helpers";
import { UIText } from "../../../../config/lang-config";
import { ShiftTaskController } from "../../lib/shift-task-controller";
import { filterController } from "../../../../cdm/controllers/filter-controller";
import { txService } from "../../../../cdm/services/tx-service";
import { autoCompleteTopicData } from "../../../../cdm/lib/topic-utilities";

export class CareReceiverShiftListController {
  disposers = [];

  @observable refreshing = false;
  @observable loaded = false;

  @observable topics = [];

  topicTypeId = topicTypeIds.shift;

  topicFilter = t =>
    t.typeId === this.topicTypeId && !t.isTemplate && !t.isParentTemplate;

  @computed get loading() {
    return !this.loaded;
  }
  // Current view group.
  @computed get group() {
    return clientController.findGroupById(stateController.viewGroupId);
  }
  @computed get timezone() {
    if (!serviceRecipientGroupTypeIds.includes(this.group.typeId))
      return getBrowserTimeZoneName();
    return (
      ((this.group.profile || {}).data || {}).timezone ||
      getBrowserTimeZoneName()
    );
  }
  @computed get groupMembers() {
    return this.group && this.group.members;
  }
  @computed get member() {
    return (
      this.group.members.find(m => m.userId === clientController.userId) || {}
    );
  }
  // @computed get topics() {
  //   return clientController.findTopics(this.topicFilter);
  // }
  @computed get filteredTopics() {
    let topics = this.topics;
    return filterController.applyFilterCategories(
      topics,
      this.filterCategories
    );
  }
  @computed get collapsible() {
    if (!stateController.viewGroupState.collapsible["allShifts"][this.group.id])
      stateController.viewGroupState.collapsible["allShifts"][
        this.group.id
      ] = {};
    return stateController.viewGroupState.collapsible["allShifts"][
      this.group.id
    ];
  }
  // Switch between calendar and shifts
  @computed get mode() {
    if (!stateController.viewGroupState.shiftListMode[this.group.id]) {
      stateController.viewGroupState.shiftListMode[this.group.id] = "shifts";
    }
    return stateController.viewGroupState.shiftListMode[this.group.id];
  }

  // Filter functions
  // @observable filterCategories = [
  //   {
  //     name: "Clock in/out",
  //     classifier: t => getTopicCompletionState(t)
  //   },
  //   {
  //     name: "Shift templates",
  //     classifier: t => t.description
  //   }
  // ];
  @computed get filterCategories() {
    if (isEmpty(filterController.CareReceiverShiftListFilters[this.group.id])) {
      filterController.CareReceiverShiftListFilters[this.group.id] = [
        ...filterController.CareReceiverShiftListFilterProto
      ];
    }
    return filterController.CareReceiverShiftListFilters[this.group.id];
  }
  setFilter = filterCategories =>
    filterController.filterSetter(this.filterCategories, filterCategories);
  // Filter functions

  constructor(props) {
    this.props = props;
    this.shiftController = new ShiftTaskController();
    this._initialize();
  }

  componentWillUnmount() {
    Array.isArray(this.disposers) &&
      this.disposers.map(disposer => disposer && disposer());
    txService.removeLogEventListener("CareReceiverShiftListListener");
  }

  _showError = err => {
    console.warn(err);
    return stateController.showPopup({
      title: capitalize(UIText.group),
      content: (err && err.message) || err,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: stateController.dismissPopup
    });
  };

  _initialize = () => {
    this._getShiftTopics()
      // .then(() => {
      //   this.disposers = [autorun(() => this._getShiftTopics())];
      // })
      .catch(this._showError)
      .finally(() => {
        this.loaded = true;
        this._registerSSETopicListener();
      });
  };

  _registerSSETopicListener = () => {
    const name = "CareReceiverShiftListListener";
    const listener = async data => {
      if (isEmpty(data) || !data.id || data.entity !== "Topic") return;
      const { id } = data;
      return apiController
        .getTopicById(id)
        .then(topic => this._handleSSETopic(topic, id))
        .then(this.removeDuplicates);
    };
    return txService.addLogEventListener(name, listener);
  };

  _getShiftTopics = async () =>
    apiController
      .getTopicsByGroupId(this.group.id, true)
      .then(topics => topics.filter(this.topicFilter))
      .then(topics => (this.topics = topics))
      .then(this.removeDuplicates);

  _handleSSETopic = (topic, id) => {
    if (!(topic.groupIdList || []).includes(this.group.id)) return;
    const oldTopic = this.topics.find(t => t.id === id);
    if (!!oldTopic && isEmpty(topic)) return this.topics.remove(oldTopic);
    if (!this.topicFilter(topic)) return;
    if (!oldTopic) return this.topics.push(topic);
    return Object.assign(oldTopic, topic);
  };

  removeDuplicates = () => {
    const topics = toJS(this.topics);
    const uniqueIdList = Array.from(new Set(topics.map(topic => topic.id)));
    this.topics = uniqueIdList
      .map(id => topics.find(topic => topic.id === id))
      .filter(Boolean);
    return this.topics;
  };

  getShiftClockCapability = shift =>
    this.shiftController.getShiftClockCapability(shift, this.member.id);

  checkCompleteShiftData = shift => autoCompleteTopicData(shift, this.topics);

  sortShifts = shifts => this.shiftController.sortShifts(shifts);

  handleClockInClockOut = async shift => {
    shift.inClockInOutPending = true;
    this.shiftController
      .handleClockInClockOut(shift, this.group, this.member)
      .catch(this._showError)
      .finally(() => {
        this._getShiftTopics().then(() => (shift.inClockInOutPending = false));
      });
  };

  handleTopicPress = topic => {
    stateController.viewTopicId = topic.id;
    NavigationService.navigate("Topic", { topic: topic.id });
  };

  handleShiftCalendarPress = event => {
    stateController.viewGroupState.shiftListMode[this.group.id] = "calendar";
  };

  handleCollapse = name => {
    stateController.viewGroupState.collapsible["allShifts"][this.group.id][
      name
    ] = !this.collapsible[name];
  };

  onRefresh = () => {
    this.refreshing = true;
    return this._getShiftTopics()
      .catch(this._showError)
      .finally(() => (this.refreshing = false));
  };
}
