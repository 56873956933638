import { apiService } from "./api-service";
import {
  contextReject,
  isEmpty,
  preventDefaultStopProp
} from "../../utils/helpers";
import { autorun, computed, observable, reaction, toJS } from "mobx";
import { env } from "../../config/variable-config";
import { UIText } from "../../config/lang-config";
import { stateController } from "../controllers/state-controller";
import { endpointConfig } from "../../config/api-config";
import { clientController } from "../controllers/client-controller";

export class WordPressService {
  @observable initialized = false;

  wpFrame;
  wpHostname;

  @observable wpUrl = "";
  @observable wpFrameCurrentUrl;
  @observable wpFrameHistory = [];
  @observable wpFrameHistoryIndex = -1;

  @observable wpFrameLoading = true;
  @observable wpFrameRefreshing = true;
  @observable wpFrameBacking = false;
  @observable wpFrameForwarding = false;

  @computed get wpFrameTransient() {
    return (
      this.wpFrameLoading ||
      this.wpFrameRefreshing ||
      this.wpFrameBacking ||
      this.wpFrameForwarding
    );
  }
  @computed get frameBackable() {
    return (
      !this.wpFrameTransient &&
      this.wpFrameHistoryIndex > 0 &&
      !!toJS(this.wpFrameHistory)[this.wpFrameHistoryIndex]
    );
  }
  @computed get frameForwardable() {
    return (
      !this.wpFrameTransient &&
      !!toJS(this.wpFrameHistory)[this.wpFrameHistoryIndex + 1]
    );
  }

  shoppingGroupReplyNonce;

  constructor() {
    this.initializeWpModalEventListeners();
    this.initialized = true;

    autorun(() =>
      console.log(toJS(this.wpFrameHistory), this.wpFrameHistoryIndex)
    );
  }

  getWpAutoLoginLink = async redirectUrl =>
    apiService
      .async("GET", {
        endpoint: endpointConfig.wp_auto_login_link(redirectUrl)
      })
      .then(async response => response.data);

  wpFrameRef = elm => {
    this.wpFrame = elm;
    if (this.wpFrame) this.wpFrame["allowPaymentRequest"] = true;
  };

  setWpHostname = hostname => (this.wpHostname = hostname);

  setWpModalUrl = async callbackUri => {
    return this.getWpAutoLoginLink(callbackUri).then(url => {
      if (!url) return false;
      return (this.wpUrl = url);
    });
  };

  setLoading = isLoading => (this.wpFrameLoading = isLoading);

  initializeWpModalEventListeners = () =>
    window.addEventListener("message", this.processWpModalMessage);

  cleanupWpModalEventListeners = () =>
    window.removeEventListener("message", this.processWpModalMessage);

  processWpModalMessage = event => {
    preventDefaultStopProp(event);
    const { data } = event;
    if (!data || !data.fromMCB || !data.frame2AppParent) return;
    const messageData = data.data;
    console.log(`web-component (iframe) -> web app:`, messageData);
    return this.handleFrameMessage(messageData);
  };

  handleFrameMessage = message => {
    if (isEmpty(message)) return;
    const types = Object.keys(message);
    for (const type of types) {
      if (type === "getOrigin") this.sendHostInformation().catch(contextReject);
      if (type === "currentUrl") this.setWpFrameCurrentUrl(message[type]);
      if (type === "closeModal") this.wpUrl = "";
      if (type === "confirm") {
        stateController
          .showPopup({
            title: message[type]["header"],
            content: message[type]["message"],
            leftButtonText: UIText.generalNo,
            leftButtonPress: () =>
              stateController.dismissPopup().then(() =>
                this.sendFrameMessage({
                  [message[type]["returnKey"]]: false
                })
              ),
            rightButtonText: UIText.generalYes,
            rightButtonPress: () =>
              stateController.dismissPopup().then(() =>
                this.sendFrameMessage({
                  [message[type]["returnKey"]]: true
                })
              ),
            dismissOnBackPress: false
          })
          .catch(contextReject);
      }
      if (type === "alert") {
        stateController
          .showPopup({
            title: message[type]["header"],
            content: message[type]["message"],
            leftButtonText: UIText.generalConfirm,
            dismissOnBackPress: true
          })
          .catch(contextReject);
      }
      if (type === "setShoppingGroupNonce")
        this.setShoppingGroupReplyNonce(message[type]);
      if (type === "getShoppingGroup" && !!message[type])
        this.replyCurrentShoppingGroup();
      if (type === "syncUserSubscriptions" && !!message[type])
        this.syncUserSubscriptions().catch(contextReject);
      if (type === "unload") this.wpFrameLoading = true;
      if (type === "load") this.wpFrameLoading = false;
      if (type === "enableStorageSync") this.setStorageDriverEventEmitter();
      if (type === "openChat") {
        const topicId = message[type];
        if (!isNaN(Number(topicId))) {
          window.open(`/Group/Chat?topic=${topicId}`, "_blank");
        }
      }
    }
  };

  sendHostInformation = async () =>
    this.sendFrameMessage({
      parentOrigin: `${window.location.protocol}//${window.location.host}`
    });

  sendFrameMessage = data =>
    this.wpFrame &&
    this.wpFrame.contentWindow &&
    this.wpFrame.contentWindow.postMessage(
      {
        fromMCB: true,
        timestamp: new Date().getTime(),
        app2FrameChild: true,
        data
      },
      `${window.location.protocol}//${this.wpHostname}`
      // `https://${endpointConfig.wp_hostname}`
    );

  onDismissFrame = () => {
    this.wpUrl = "";
    this.wpFrameHistory = [];
    this.wpFrameHistoryIndex = -1;
    this.wpFrameRefreshing = this.wpFrameBacking = this.wpFrameForwarding = false;
  };

  onRefreshFrame = () => {
    if (!this.wpFrame) return;
    this.wpFrameRefreshing = true;
    this.wpFrame.src = "";
    setTimeout(() => (this.wpFrame.src = this.wpFrameCurrentUrl));
  };

  onFrameOpenInNewWindow = event => {
    preventDefaultStopProp(event);
    return window.open(this.wpFrameCurrentUrl, "_blank");
  };

  setWpFrameCurrentUrl = url => {
    this.wpFrameCurrentUrl = url;
    if (this.wpFrameBacking) {
      this.wpFrameHistoryIndex--;
      this.wpFrameBacking = false;
    } else if (this.wpFrameForwarding) {
      this.wpFrameHistoryIndex++;
      this.wpFrameForwarding = false;
    } else if (!this.wpFrameRefreshing) {
      this.wpFrameHistoryIndex++;
      this.wpFrameHistory[this.wpFrameHistoryIndex] = url;
      this.wpFrameLoading = false;
    } else {
      this.wpFrameRefreshing = false;
    }
  };

  onFrameWindowBack = event => {
    preventDefaultStopProp(event);
    this.wpFrameBacking = true;
    this.wpFrame.src = "";
    setTimeout(
      () =>
        (this.wpFrame.src = this.wpFrameHistory[this.wpFrameHistoryIndex - 1])
    );
  };

  onFrameWindowForward = event => {
    preventDefaultStopProp(event);
    this.wpFrameForwarding = true;
    this.wpFrame.src = "";
    setTimeout(
      () =>
        (this.wpFrame.src = this.wpFrameHistory[this.wpFrameHistoryIndex + 1])
    );
  };

  setShoppingGroupReplyNonce = nonce => {
    this.shoppingGroupReplyNonce = nonce;
    this.replyCurrentShoppingGroup();
  };

  replyCurrentShoppingGroup = () =>
    this.sendFrameMessage({
      setCurrentShoppingGroup: {
        groupId: stateController.viewGroupId,
        // groupId: null, // For testing error state
        nonce: this.shoppingGroupReplyNonce
      }
    });

  syncUserSubscriptions = () =>
    apiService.async("GET", {
      endpoint: endpointConfig.sync_user_subscriptions
    });

  setStorageDriverEventEmitter = () => {
    window.removeEventListener("storage", this.postStorageDriverData);
    window.addEventListener("storage", this.postStorageDriverData);
    reaction(
      () => clientController.client.oauth,
      () => setTimeout(this.postStorageDriverData)
    );
    this.postDeviceId();
    this.postStorageDriverData();
  };

  postDeviceId = () =>
    this.sendFrameMessage({ deviceId: toJS(clientController.deviceId) });

  postStorageDriverData = () =>
    this.sendFrameMessage({ storage: localStorage.getItem("client") });

  dispose() {
    this.cleanupWpModalEventListeners();
  }
}

const wordPressService = new WordPressService();

// For development;
if (window && env !== "prod") window.wordPressService = wordPressService;

export { wordPressService };
