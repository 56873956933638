import React from "react";
import { ActivityIndicator, View } from "react-native";
import { Header } from "../../../components/Header";
import { observer } from "mobx-react";
import { theme } from "../../../config/style-configs/theme";
import { common } from "../../../config/style-configs/common";
import { UIText } from "../../../config/lang-config";

@observer
class RootScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentDidMount() {
    this.controller.componentDidMount();
  }

  componentWillUnmount() {
    this.controller.componentWillUnmount();
  }

  // Render any loading content during the load of CDO.
  render() {
    return (
      <View style={this.props.styles.style}>
        <Header
          title={UIText.title}
          handleLeftPress={() => {}}
          navigation={this.props.navigation}
        />
        <View style={common.loading}>
          <ActivityIndicator size="large" color={theme.color} />
        </View>
      </View>
    );
    // return (
    //   <View>
    //     <Header
    //       title={this.props.navigation.state.key}
    //       leftIcon="menu"
    //       // rightIcon="more-vert"
    //       navigation={this.props.navigation}
    //     />
    //
    //     <ScrollView>
    //       <Text>You've logged in.</Text>
    //       <Button
    //         type="clear"
    //         title="Sign out"
    //         onPress={this.controller.execLogout}
    //       />
    //       <Text>Client store: {JSON.stringify(this.controller.client)}</Text>
    //     </ScrollView>
    //   </View>
    // );
  }
}

export { RootScreen };
