import { formService, TypeClassTemplate } from '../../../../../../cdm/services/form-service';
import { groupTypeIds, typeClassIds } from '../../../../../../config/variable-config';
import { stateController } from '../../../../../../cdm/controllers/state-controller';

let template = new TypeClassTemplate(typeClassIds.careReceiverProfile);

export const householdForm = gOnly => {
  if (!Array.isArray(template.form) || template.form.length === 0) return [];

  const form = [...template.form].filter(
    field => field.type !== "collapsibleAnchor"
  );

  if (stateController.viewPlanState.residentialProvince) {
    const province = form.find(f => f.name === "provinces");
    if (province) {
      province.value = stateController.viewPlanState.residentialProvince;
      province.disabled = true;
    }
  }

  formService.hideFieldsByGroupTypeId(form, groupTypeIds.household);

  return form;
};
