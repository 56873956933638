import { View } from "react-native";
import { ListFilter } from "../../../../components/ListFilter";
import { ThemedButton } from "../../../../components/ThemedButton";
import { UIText } from "../../../../config/lang-config";
import { ListRefreshButtonWeb } from "../../../../components/ListRefreshButtonWeb";
import React from "react";
import { observer } from "mobx-react";
import { FormField } from "../../../../components/FormField";
import { isEmpty } from "../../../../utils/helpers";

export const AdminCentreButtonsView = observer(props => {
  const {
    cards,
    filterCategories,
    setFilter,
    hideAvatar,
    onShowHideAvatars,
    loading,
    onRefresh,
    sortBy,
    onSortByAlpha,
    onSortByDate,
    isProvider,
    providerGroupTypes,
    providerGroupTypeId,
    onProviderGroupTypeChange
  } = props;

  return (
    <View>
      <View style={props.styles.buttonContainer}>
        {cards && filterCategories && setFilter && (
          <ListFilter
            data={cards}
            categories={filterCategories}
            filterSetter={setFilter}
          />
        )}
        <ThemedButton
          title={UIText.adminCentreShowHideAvatar(hideAvatar)}
          onPress={onShowHideAvatars}
        />
        {!loading && <ListRefreshButtonWeb onRefresh={onRefresh} />}
      </View>
      <View style={props.styles.buttonContainerPicker}>
        <View style={props.styles.buttonContainer}>
          <ThemedButton
            icon={
              sortBy === "dateAscend"
                ? "expand-less"
                : sortBy === "dateDescend"
                ? "expand-more"
                : undefined
            }
            title={UIText.adminCentreSortByDate}
            onPress={onSortByDate}
          />
          <ThemedButton
            icon={
              sortBy === "alphaAscend"
                ? "expand-less"
                : sortBy === "alphaDescend"
                ? "expand-more"
                : undefined
            }
            title={UIText.adminCentreSortByName}
            onPress={onSortByAlpha}
          />
        </View>
        {isProvider && !isEmpty(providerGroupTypes) && (
          <FormField
            field={{
              type: "picker",
              name: "groupTypeId",
              options: providerGroupTypes.map(type => ({
                name: type.id,
                placeholder: type["typeName"]
              })),
              placeholder: UIText.adminCentreServiceType,
              value: providerGroupTypeId
            }}
            enabled={true}
            onPickerChange={onProviderGroupTypeChange}
            fieldStyle={{ maxWidth: "100%" }}
          />
        )}
      </View>
    </View>
  );
});
