import React from "react";
import { observer } from "mobx-react";
import { Linking, Platform, Text } from "react-native";

const ProfileBookingLinkView = observer(({ styles, link }) => {
  const open = () => {
    if (Platform.OS === "web") {
      window.open(link);
    } else {
      return Linking.openURL(link);
    }
  };
  return (
    <Text style={styles.style} onPress={open}>
      Book an appointment
    </Text>
  );
});

export { ProfileBookingLinkView };
