import { Platform, Text, View } from "react-native";
import { UIText } from "../../config/lang-config";
import { Form } from "../Form";
import React from "react";
import { observer } from "mobx-react";
import { FormField } from "../FormField";
import { StripeElementsWeb } from "../StripeElementsWeb";
import { WebView } from "react-native-webview";

@observer
class PlanPaymentFormView extends React.Component {
  renderGenericFieldLabel = text => (
    <FormField
      field={{
        type: "text",
        value: text
      }}
      fieldStyle={this.props.styles.field}
      fieldLabelStyle={this.props.styles.fieldLabel}
    />
  );

  renderStripeElements = () =>
    Platform.OS === "web"
      ? this.renderStripeWebView()
      : this.renderStripeNativeView();

  renderStripeWebView = () => {
    return (
      <StripeElementsWeb
        handleCardChange={this.props.handleCardChange}
        createTokenCallback={this.props.createTokenCallback}
      />
    );
  };

  renderStripeNativeView = () => {
    return <WebView source={{ uri: this.props.stripeUrl }} />;
  };

  render() {
    const {
      enabledGetter,
      valueGetter,
      onInputChange,
      onCheckboxChange,
      onPickerChange,
      onDateTimeChange,
      onSubmitEditing,
      refSetter,

      isPaymentUpdate,
      isWpWcMode,

      billingForm
    } = this.props;

    const fieldProps = {
      showLabel: true,
      showTitle: false,
      enabledGetter,
      valueGetter,
      onInputChange,
      onCheckboxChange,
      onPickerChange,
      onDateTimeChange,
      onSubmitEditing,
      refSetter
    };

    return (
      <View>
        {isPaymentUpdate && (
          <>
            {this.renderGenericFieldLabel(
              isWpWcMode
                ? UIText.subscriptionChangeAddress
                : UIText.planChangePayment
            )}
            <View style={this.props.styles.buttonReminder}>
              <Text style={this.props.styles.errorMessage}>
                {this.props.paymentUpdateIntro}
              </Text>
            </View>
          </>
        )}
        {!isWpWcMode && this.renderGenericFieldLabel(UIText.planCreditCard())}
        {!isWpWcMode && (
          <View style={this.props.styles.stripeElements}>
            {this.renderStripeElements()}
          </View>
        )}
        {!isWpWcMode && this.renderGenericFieldLabel(UIText.planBillingAddress)}
        <Form
          form={billingForm}
          fieldProps={fieldProps}
          expandAll={true}
          anchorDisabled={true}
        />
      </View>
    );
  }
}

export { PlanPaymentFormView };
