import { stateController } from '../../../cdm/controllers/state-controller';
import { UIText } from '../../../config/lang-config';
import { computed, observable } from 'mobx';
import { capitalize, isEmpty } from '../../../utils/helpers';
import { apiService } from '../../../cdm/services/api-service';
import { endpointConfig } from '../../../config/api-config';
import { clientController } from '../../../cdm/controllers/client-controller';
import { groupTypeIds } from '../../../config/variable-config';

export class RatingReviewController {
  @observable loading = true;
  @observable submitting = false;
  @observable ratingReview = {};

  @observable enablePrivateReview = false;

  @computed get submissionToken() {
    return stateController.viewRatingReviewState.submissionToken;
  }
  @computed get userHashId() {
    return stateController.viewRatingReviewState.userHashId;
  }
  @computed get rating() {
    return stateController.viewRatingReviewState.rating;
  }

  @computed get historicalRatings() {
    return this.ratingReview.historicalRatings;
  }

  @computed get isCareCircle() {
    const { reviewerGroup } = this.ratingReview;
    return reviewerGroup.typeId === groupTypeIds.careReceiver;
  }

  componentDidMount() {
    stateController.currentScreen = "RatingReview";
  }

  constructor(props) {
    this.props = props;
    this._initialize();
  }

  _showError = (err, kickout) => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.title,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: () =>
        stateController
          .dismissPopup()
          .then(() => kickout && this.props.navigation.navigate("Login"))
    });
  };

  _initialize = () => {
    this.loading = true;
    const initError = () =>
      stateController.showPopup({
        title: UIText.ratingReview,
        content: UIText.ratingReviewInvalidLink,
        leftButtonText: UIText.generalConfirm,
        leftButtonPress: () =>
          stateController
            .dismissPopup()
            .then(() => this.props.navigation.navigate("Login"))
      });
    if (!this.userHashId || !this.submissionToken) return initError();
    return this.refreshRatingReview()
      .then(this.populateRatingReview)
      .catch(err => {
        const errResponse = (err.response || {}).data || {};
        const { message } = errResponse;
        if (message) return this._showError({ message }, true);
        this._showError(err, true);
      })
      .finally(() => {
        if (isEmpty(this.ratingReview)) return initError();
        this.loading = false;
      });
  };

  refreshRatingReview = async () => {
    return apiService
      .async("POST", {
        endpoint: endpointConfig.update_rating_review_by_hash,
        data: {
          userHashId: this.userHashId,
          token: this.submissionToken,
          rating: this.rating,
          includePrivateReview: true
        }
      })
      .then(response => response.data);
  };

  populateRatingReview = data => (this.ratingReview = data);

  isSubmitted = ratingReview => !!ratingReview.submissionDate;

  onCommentChange = event => {
    if (!event) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      this.ratingReview.review = event.nativeEvent.text;
  };

  onRatingChange = rating => {
    if (!!this.isSubmitted(this.ratingReview)) return;
    this.ratingReview.rating = rating;
  };

  onPrivateReviewToggle = () =>
    (this.enablePrivateReview = !this.enablePrivateReview);

  onPrivateReviewChange = event => {
    if (!event) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      this.ratingReview.privateReview = event.nativeEvent.text;
  };

  execSubmit = async () => {
    if (!this.ratingReview.rating) return;
    this.submitting = true;
    return stateController
      .showPopup({
        title: UIText.ratingReview,
        content: UIText.pleaseWait
      })
      .then(() =>
        apiService.async("POST", {
          endpoint: endpointConfig.update_rating_review_by_hash,
          data: {
            userHashId: this.userHashId,
            token: this.submissionToken,
            rating: this.ratingReview.rating,
            review: this.ratingReview.review,
            privateReview:
              this.enablePrivateReview && this.ratingReview.privateReview,
            isSubmit: true
          }
        })
      )
      .then(stateController.dismissPopup)
      .then(() =>
        stateController.showPopup({
          title: capitalize(UIText.success),
          content: UIText.ratingReviewSubmitSuccess,
          leftButtonText: UIText.generalConfirm,
          leftButtonPress: () =>
            stateController
              .dismissPopup()
              .then(() => clientController.execLogout(null, true))
              .then(() => this.props.navigation.navigate("Login")),
          dismissOnBackPress: false
        })
      )
      .catch(err => {
        const errResponse = (err.response || {}).data || {};
        const { message } = errResponse;
        if (message) return this._showError({ message });
        this._showError(err);
      })
      .finally(() => (this.submitting = false));
  };
}
