import React from "react";
import { ReportTimeSheetInsertView } from "./view";
import { ReportTimeSheetInsertController } from "./controller";
import { styles } from "./styles";

export class ReportTimeSheetInsert extends React.Component {
  render() {
    const controller = new ReportTimeSheetInsertController(this.props);

    return (
      <ReportTimeSheetInsertView
        {...this.props}
        styles={styles}
        controller={controller}
      />
    );
  }
}
