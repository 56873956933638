import React from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import { Button } from "react-native-elements";
import { observer, Observer } from "mobx-react";
import { theme } from "../../../../config/style-configs/theme";
import { UIText } from "../../../../config/lang-config";
import { ListRefreshButtonWeb } from "../../../../components/ListRefreshButtonWeb";
import { ThemedButton } from "../../../../components/ThemedButton";
import { ReportTimeSheetInsert } from "../ReportTimesheetInsert";

@observer
class AdminPayrollPageView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentDidMount() {
    const scrollPos = this.controller.timeSheetStore.scrollPos;
    if (!scrollPos) return;
    if (!this.scrollView) return;
    return this.scrollView.scrollTo({ y: scrollPos || 0, animated: false });
  }

  render() {
    return (
      <View style={this.props.styles.style}>
        {this.controller.loading ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          <ScrollView
            ref={ref => (this.scrollView = ref)}
            scrollEventThrottle={250}
            onScroll={this.controller.onScroll}
            contentContainerStyle={this.props.styles.scrollView}
          >
            <View style={this.props.styles.scrollViewContent}>
              <View style={this.props.styles.buttonContainer}>
                <Button
                  type="clear"
                  title={UIText.adminCentreInvoicesGen}
                  titleStyle={this.props.styles.buttonTitle}
                  onPress={() => {}}
                />
              </View>
              <View style={this.props.styles.buttonContainer}>
                <ThemedButton
                  style={this.props.styles.button}
                  title={UIText.adminCentreInvoicesReRun}
                  onPress={this.controller.triggerInvoicesReRun}
                />
              </View>
              <View style={this.props.styles.buttonContainer}>
                <Button
                  type="clear"
                  title={UIText.adminCentreTimeSheets}
                  titleStyle={this.props.styles.buttonTitle}
                  onPress={() => {}}
                />
              </View>
              <View style={this.props.styles.buttonContainer}>
                <ThemedButton
                  icon="expand-less"
                  style={this.props.styles.button}
                  title={UIText.collapseAll}
                  onPress={this.controller.collapseAll}
                />
                <ThemedButton
                  icon="expand-more"
                  style={this.props.styles.button}
                  title={UIText.uncollapseAll}
                  onPress={this.controller.uncollapseAll}
                />
                <ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />
              </View>
              {this.controller.careReceiverGroups.map(group => (
                <Observer key={group.id}>
                  {() => (
                    <View
                      style={{
                        ...this.props.styles.scrollViewCollapsibleCards,
                        paddingVertical: 5
                      }}
                    >
                      <ReportTimeSheetInsert
                        groupId={group.id}
                        group={group}
                        state={this.controller.getTimeSheetState(group.id)}
                        caregiverFilter={this.controller.timeSheetMemberFilter}
                        collapsibleTitle={this.controller.getCollapsibleTitle(
                          group
                        )}
                        showCollapsible={true}
                        handleCollapse={() =>
                          this.controller.handleCollapse(group.id)
                        }
                        collapsed={this.controller.collapsible[group.id]}
                      />
                    </View>
                  )}
                </Observer>
              ))}
              {!this.controller.noMoreNext &&
                (this.controller.paging ? (
                  <ActivityIndicator
                    size={24}
                    style={{ paddingBottom: 10, minHeight: 50 }}
                    color={theme.color}
                  />
                ) : (
                  <Button
                    type="clear"
                    style={{ minHeight: 50 }}
                    title={UIText.adminLoadMore}
                    titleStyle={{ color: theme.color }}
                    onPress={this.controller.getNextPage}
                  />
                ))}
            </View>
          </ScrollView>
        )}
      </View>
    );
  }
}

export { AdminPayrollPageView };
