import { theme } from "../../../../config/style-configs/theme";
import { isEmpty, isNonZeroFalse } from "../../../../utils/helpers";
import Icon from "react-native-vector-icons/MaterialIcons";
import React from "react";
import { observer } from "mobx-react";

export const getCurrentStarIndex = (ratingReview, hoverState) => {
  hoverState = hoverState || [];
  const hoverIndex = hoverState.indexOf(true);
  const ratingIndex = (ratingReview.rating || 1) - 1;
  if (!!ratingReview.submissionDate) return ratingIndex;
  return isNonZeroFalse(hoverIndex < 0 ? false : hoverIndex)
    ? ratingIndex
    : hoverIndex;
};

export const getReadOnlyStarColor = (index, ratingReview) =>
  ratingReview.rating >= index + 1 ? theme.ratingStarColor : "#ccc";

export const getStarColor = (index, ratingReview, starHovers) => {
  if (isEmpty(ratingReview)) return "#ccc";
  const isSubmitted = !!ratingReview.submissionDate;
  if (!isSubmitted) {
    if (!isEmpty(starHovers.filter(Boolean))) {
      if (starHovers[index]) {
        return theme.ratingStarColor;
      }
      return starHovers.indexOf(true) >= index ? theme.ratingStarColor : "#ccc";
    }
  }
  return getReadOnlyStarColor(index, ratingReview);
};

const RatingReviewInteractiveStarView = observer(
  ({ index, size, ratingReview, hoverState, setHoverState, onRatingChange }) =>
    isEmpty(ratingReview) ? null : (
      <Icon
        key={index}
        name="star"
        size={size || 45}
        color={getStarColor(index, ratingReview, hoverState)}
        onMouseEnter={() => setHoverState(index, true)}
        onMouseLeave={() => setHoverState(index, false)}
        onPress={
          !ratingReview.submissionDate
            ? () => onRatingChange(index + 1)
            : undefined
        }
      />
    )
);

export { RatingReviewInteractiveStarView };
