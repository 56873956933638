import { autorun, computed, observable, toJS } from "mobx";
import { clientController } from "../../../../cdm/controllers/client-controller";
import { stateController } from "../../../../cdm/controllers/state-controller";
import {
  serviceRecipientGroupTypeIds,
  topicTypeIds
} from "../../../../config/variable-config";
import { apiController } from "../../../../cdm/controllers/api-controller";
import NavigationService from "../../../../utils/navigation-service";
import {
  capitalize,
  getBrowserTimeZoneName,
  preventDefaultStopProp,
  safeParseJSON
} from "../../../../utils/helpers";
import { UIText } from "../../../../config/lang-config";
import {
  deleteTopic,
  editTopicAssignee,
  editTopicDescription,
  editTopicMenu,
  editTopicStartEndTime
} from "../../../../cdm/lib/topic-utilities";
import { ShiftTaskController } from "../../lib/shift-task-controller";
import { getTopicSummaryEditFlags } from "../../config/flags_calculator";
import { filterController } from "../../../../cdm/controllers/filter-controller";

export class CareReceiverToDoListController {
  @observable refreshing = false;
  @observable loaded = false;

  topicTypeId = topicTypeIds.task;

  topicFilter = t =>
    (t.groupIdList || []).includes(this.group.id) &&
    t.typeId === this.topicTypeId &&
    !t.parentId &&
    !t.isTemplate &&
    !t.isParentTemplate;

  @computed get loading() {
    return !this.loaded;
  }
  // Current view group.
  @computed get group() {
    return clientController.findGroupById(stateController.viewGroupId);
  }
  @computed get timezone() {
    if (!serviceRecipientGroupTypeIds.includes(this.group.typeId))
      return getBrowserTimeZoneName();
    return (
      ((this.group.profile || {}).data || {}).timezone ||
      getBrowserTimeZoneName()
    );
  }
  @computed get member() {
    return (
      this.group.members.find(m => m.userId === clientController.userId) || {}
    );
  }
  @computed get topics() {
    return clientController.findTopics(this.topicFilter);
  }
  @computed get filteredTopics() {
    let topics = this.topics;
    return filterController.applyFilterCategories(
      topics,
      this.filterCategories
    );
  }

  // Filter functions
  @observable filterCategories = [
    {
      name: "To-do type",
      classifier: t => {
        const data = t.data && safeParseJSON(t.data);
        return data.taskType;
      }
    },
    {
      name: "Completion",
      classifier: t =>
        t.isCompleted ? UIText.topicCompleted : UIText.topicNotCompleted
    }
  ];
  setFilter = filterCategories =>
    filterController.filterSetter(this.filterCategories, filterCategories);
  // Filter functions

  constructor(props) {
    this.props = props;
    this.taskController = new ShiftTaskController();
    this._initialize();
  }

  componentWillUnmount() {
    Array.isArray(this.disposers) &&
      this.disposers.map(disposer => disposer && disposer());
  }

  _showError = err => {
    console.warn(err);
    return stateController.showPopup({
      title: capitalize(UIText.group),
      content: (err && err.message) || err,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: stateController.dismissPopup
    });
  };

  _initialize = () => {
    this._getToDoTopics()
      .then(() => this.topics.map(t => (t.pending = false)))
      .then(() => {
        this.disposers = [autorun(() => this._getToDoTopics(true))];
      })
      .catch(this._showError);
  };

  _getToDoTopics = async quietly => {
    !quietly && (this.loaded = false);
    return await apiController
      .getGroupTopicsByTypeId(this.group.id, this.topicTypeId, false, false)
      .then(topics => {
        for (let topic of toJS(this.topics)) {
          !topics.find(t => t.id === topic.id) &&
            clientController.removeTopic(topic);
        }

        topics = topics.filter(this.topicFilter);

        for (let topic of topics) {
          clientController.updateTopic(topic);
        }
      })
      .finally(() => (this.loaded = true));
  };

  addTodo = () => {
    stateController.initSetupMode = "addTodo";
    return NavigationService.navigate("Setup");
  };

  editTopic = (event, topic) => {
    preventDefaultStopProp(event);

    if (this.loading || topic.pending) return;

    const selfActors = topic.actors.filter(a =>
      a.memberIdList.includes(this.member.id)
    );
    const selfRoles = this.member.roleList || [];

    let flags = [];
    for (let actor of selfActors) {
      for (let role of selfRoles) {
        flags.push(
          ...getTopicSummaryEditFlags(
            topic,
            actor.groupTypeActorId,
            role.groupTypeRoleId,
            this.group
          )
        );
      }
    }

    return editTopicMenu(topic, flags, {
      editTopicName: () => this.editTopicName(topic),
      editTopicAssignee: () => this.editTopicAssignee(topic),
      editTopicStartEndTime: () => this.editTopicStartEndTime(topic),
      deleteTopic: () => this.handleTopicDelete(topic)
    });
  };

  editTopicName = async topic => {
    topic.pending = true;
    return editTopicDescription(topic)
      .then(update => update && this._getToDoTopics(true))
      .catch(this._showError)
      .finally(() => {
        topic.pending = false;
      });
  };

  editTopicAssignee = topic => {
    topic.pending = true;
    return editTopicAssignee(this.group, topic, "assigned")
      .then(update => update && this._getToDoTopics(true))
      .catch(this._showError)
      .finally(() => {
        topic.pending = false;
      });
  };

  editTopicStartEndTime = topic => {
    topic.pending = true;
    return editTopicStartEndTime(topic, null, null, null, this.timezone)
      .then(update => update && this._getToDoTopics(true))
      .catch(this._showError)
      .finally(() => {
        topic.pending = false;
      });
  };

  handleTopicDelete = topic => {
    topic.pending = true;
    return deleteTopic(topic)
      .then(update => update && this._getToDoTopics(true))
      .catch(this._showError)
      .finally(() => {
        topic.pending = false;
      });
  };

  handleTopicPress = topic => {
    const id = topic.id;

    stateController.viewTopicId = id;
    NavigationService.navigate("Topic", { topic: id });
  };

  sortTasks = tasks => this.taskController.sortTasks(tasks);

  onRefresh = () => {
    this.refreshing = true;
    return this._getToDoTopics(true).finally(() => (this.refreshing = false));
  };
}
