import { common } from "../../../config/style-configs/common";
import { theme } from "../../../config/style-configs/theme";

export const styles = {
  style: {
    flex: 1
  },
  loading: common.loading,
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  buttonContainer: {
    justifyContent: "center",
    alignSelf: "center",
    margin: 30
  },
  listButtonContainer: {
    ...common.listButtonContainer,
    paddingTop: 0
  },
  navButtonContainer: {
    ...common.listButtonContainer,
    padding: 20,
    borderColor: "#ccc",
    borderBottomWidth: 1
  },
  buttonReminder: common.listButtonReminderContainer,
  button: { marginVertical: 5 },
  errorMessage: {
    ...common.containerErrorMessage,
    textAlign: "start"
  },
  field: { paddingTop: 10 },
  fieldLabel: {
    marginBottom: 0,
    fontSize: theme.FONT_SIZE_LARGE
  },
  frameContainer: {
    flex: 1,
    height: "100%"
  },
  frame: {
    height: "100%",
    border: "none"
  },
  footerNoteContainer: {
    padding: 15
  },
  footerNote: {
    fontSize: theme.FONT_SIZE_SMALL,
    color: theme.color
  },
  footerNoteLink: common.linkBlue
};
