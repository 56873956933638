import React from "react";
import { observer } from "mobx-react";
import { Text, View } from "react-native";
import { UIText } from "../../config/lang-config";
import { getPriceString, isEmpty } from "../../utils/helpers";

@observer
class PriceBreakdownTableView extends React.Component {
  getPriceString = amount => {
    return getPriceString(amount / 100);
  };

  renderBreakdownItems = plans =>
    plans &&
    plans.map(plan => (
      <View
        style={{
          ...this.props.styles.breakdownDetail,
          ...this.props.styles.breakdownDetailNoDivide
        }}
        key={plan.id || plan.productName}
      >
        <Text style={this.props.styles.breakdownDetailText}>
          {plan.productName}
        </Text>
        <Text style={this.props.styles.breakdownDetailTextSmall}>
          {plan.nickname}
        </Text>
      </View>
    ));

  renderBreakdownTaxItems = breakdown => (
    <View style={this.props.styles.breakdownDetail}>
      <Text style={this.props.styles.breakdownDetailText}>
        {UIText.priceBreakdownSubtotal}
      </Text>
      {breakdown.gst ? (
        <Text style={this.props.styles.breakdownDetailText}>
          {UIText.priceBreakdownGST}
        </Text>
      ) : null}
      {breakdown.pst ? (
        <Text style={this.props.styles.breakdownDetailText}>
          {UIText.priceBreakdownPST}
        </Text>
      ) : null}
      {breakdown.hst ? (
        <Text style={this.props.styles.breakdownDetailText}>
          {UIText.priceBreakdownHST}
        </Text>
      ) : null}
    </View>
  );

  renderBreakdownDiscountItems = breakdown =>
    !isNaN(breakdown.discount) && breakdown.discount < 0 ? (
      <View style={this.props.styles.breakdownDetail}>
        <Text style={this.props.styles.breakdownDetailText}>
          {UIText.priceBreakdownDiscount}
        </Text>
      </View>
    ) : null;

  renderBreakdownItemPrices = plans =>
    plans &&
    plans.map(plan => (
      <View
        style={{
          ...this.props.styles.breakdownDetail,
          ...this.props.styles.breakdownDetailNoDivide
        }}
        key={plan.amount}
      >
        <Text
          style={{
            ...this.props.styles.breakdownDetailText,
            ...this.props.styles.breakdownTextRightAlign
          }}
        >
          {this.getPriceString(plan.amount)}
        </Text>
        <Text
          style={{
            ...this.props.styles.breakdownDetailTextSmall,
            ...this.props.styles.breakdownTextRightAlign
          }}
        >
          {" "}
        </Text>
      </View>
    ));

  renderBreakdownTaxItemPrices = breakdown => (
    <View style={this.props.styles.breakdownDetail}>
      <Text
        style={{
          ...this.props.styles.breakdownDetailText,
          ...this.props.styles.breakdownTextRightAlign
        }}
      >
        {this.getPriceString(this.props.breakdown.subtotal)}
      </Text>
      {breakdown.gst ? (
        <Text
          style={{
            ...this.props.styles.breakdownDetailText,
            ...this.props.styles.breakdownTextRightAlign
          }}
        >
          {this.getPriceString(breakdown.gst)}
        </Text>
      ) : null}
      {breakdown.pst ? (
        <Text
          style={{
            ...this.props.styles.breakdownDetailText,
            ...this.props.styles.breakdownTextRightAlign
          }}
        >
          {this.getPriceString(breakdown.pst)}
        </Text>
      ) : null}
      {breakdown.hst ? (
        <Text
          style={{
            ...this.props.styles.breakdownDetailText,
            ...this.props.styles.breakdownTextRightAlign
          }}
        >
          {this.getPriceString(breakdown.hst)}
        </Text>
      ) : null}
    </View>
  );

  renderBreakdownDiscountPrices = breakdown =>
    !isNaN(breakdown.discount) && breakdown.discount < 0 ? (
      <View style={this.props.styles.breakdownDetail}>
        <Text
          style={{
            ...this.props.styles.breakdownDetailText,
            ...this.props.styles.breakdownTextRightAlign
          }}
        >
          {this.getPriceString(breakdown.discount)}
        </Text>
      </View>
    ) : null;

  renderBreakdownTotalPrice = breakdown => {
    let total = !isNaN(breakdown.discount)
      ? breakdown.total + breakdown.discount
      : breakdown.total;

    return (
      <View style={this.props.styles.breakdownDetail}>
        <Text
          style={{
            ...this.props.styles.breakdownDetailText,
            ...this.props.styles.breakdownTextRightAlign
          }}
        >
          {this.getPriceString(total)}
        </Text>
      </View>
    );
  };

  renderBreakdownItemTaxCategories = plans =>
    plans &&
    plans.map(plan => (
      <View
        style={{
          ...this.props.styles.breakdownDetail,
          ...this.props.styles.breakdownDetailNoDivide
        }}
        key={plan.nickname}
      >
        <Text style={this.props.styles.breakdownDetailText}>
          {[
            plan.gstRequired ? "G" : "",
            plan.pstRequired ? "P" : "",
            plan.hstRequired ? "H" : ""
          ]
            .filter(Boolean)
            .join(", ")}
        </Text>
        <Text style={this.props.styles.breakdownDetailTextSmall}> </Text>
      </View>
    ));

  renderBreakdownTaxItemCategoryPlaceholders = breakdown => (
    <View style={this.props.styles.breakdownDetail}>
      <Text style={this.props.styles.breakdownDetailText}> </Text>
      {breakdown.gst ? (
        <Text style={this.props.styles.breakdownDetailText}> </Text>
      ) : null}
      {breakdown.pst ? (
        <Text style={this.props.styles.breakdownDetailText}> </Text>
      ) : null}
      {breakdown.hst ? (
        <Text style={this.props.styles.breakdownDetailText}> </Text>
      ) : null}
    </View>
  );

  renderBreakdownDiscountPlaceholder = breakdown =>
    !isNaN(breakdown.discount) && breakdown.discount < 0 ? (
      <View style={this.props.styles.breakdownDetail}>
        <Text style={this.props.styles.breakdownDetailText}> </Text>
      </View>
    ) : null;

  render() {
    return isEmpty(this.props.breakdown) ? (
      <View>
        <Text style={this.props.styles.errorMessage}>
          {UIText.priceBreakdownUnavailable}
        </Text>
      </View>
    ) : this.props.breakdown.payAsYouGo ? (
      <View>
        <Text style={this.props.styles.errorMessage}>
          {UIText.priceBreakdownPayAsYouGo}
        </Text>
      </View>
    ) : (
      <View style={this.props.styles.breakdown}>
        <View style={this.props.styles.breakdownColumn}>
          <View style={this.props.styles.breakdownTitle}>
            <Text style={this.props.styles.breakdownTitleText}>
              {UIText.priceBreakdownItem}
            </Text>
          </View>
          <View style={this.props.styles.breakdownDetail}>
            {this.renderBreakdownItems(this.props.breakdown.plans)}
          </View>
          {this.renderBreakdownTaxItems(this.props.breakdown)}
          {this.renderBreakdownDiscountItems(this.props.breakdown)}
          <View style={this.props.styles.breakdownDetail}>
            <Text style={this.props.styles.breakdownDetailText}>
              {UIText.priceBreakdownTotal}
            </Text>
          </View>
        </View>

        <View
          style={{
            ...this.props.styles.breakdownColumn,
            flexGrow: 0.5
          }}
        >
          <View style={this.props.styles.breakdownTitle}>
            <Text
              style={{
                ...this.props.styles.breakdownTitleText,
                ...this.props.styles.breakdownTextRightAlign
              }}
            >
              {UIText.priceBreakdownPrice}
            </Text>
          </View>
          <View style={this.props.styles.breakdownDetail}>
            {this.renderBreakdownItemPrices(this.props.breakdown.plans)}
          </View>
          {this.renderBreakdownTaxItemPrices(this.props.breakdown)}
          {this.renderBreakdownDiscountPrices(this.props.breakdown)}
          {this.renderBreakdownTotalPrice(this.props.breakdown)}
        </View>

        <View
          style={{
            ...this.props.styles.breakdownColumn,
            flexGrow: 0.1
          }}
        >
          <View style={this.props.styles.breakdownTitle}>
            <Text style={this.props.styles.breakdownTitleText}>
              {UIText.priceBreakdownTax}
            </Text>
          </View>
          <View style={this.props.styles.breakdownDetail}>
            {this.renderBreakdownItemTaxCategories(this.props.breakdown.plans)}
          </View>
          {this.renderBreakdownTaxItemCategoryPlaceholders(
            this.props.breakdown
          )}
          {this.renderBreakdownDiscountPlaceholder(this.props.breakdown)}
          <View style={this.props.styles.breakdownDetail}>
            <Text style={this.props.styles.breakdownDetailText}> </Text>
          </View>
        </View>
      </View>
    );
  }
}

export { PriceBreakdownTableView };
