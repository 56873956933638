import { observer } from "mobx-react";
import React from "react";
import { ActivityIndicator, Platform, ScrollView, View } from "react-native";
import { theme } from "../../../../config/style-configs/theme";
import { ReportTimeSheetInsert } from "../ReportTimesheetInsert";

@observer
class CareReceiverReportListView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  render() {
    return (
      <View style={this.props.styles.style}>
        {this.controller.loading && Platform.OS === "web" ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          <ScrollView contentContainerStyle={this.props.styles.scrollView}>
            <View style={this.props.styles.scrollViewContent}>
              {/*{this.renderCompletedShiftSummaryEntry()}*/}
              <ReportTimeSheetInsert
                groupId={this.controller.groupId}
                state={this.controller.ShiftReportState}
                showCollapsible={true}
                handleCollapse={() =>
                  this.controller.handleCollapse("completedShift")
                }
                collapsed={!this.controller.collapsible["completedShift"]}
              />
            </View>
          </ScrollView>
        )}
      </View>
    );
  }
}

export { CareReceiverReportListView };
