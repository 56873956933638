const size = 22;

const dimension = {
  maxWidth: size,
  maxHeight: size
};
export const styles = {
  style: {
    ...dimension
  },
  button: {
    ...dimension
  },
  iconColor: "#aaa",
  iconSize: size
};
