import { observer, Observer } from "mobx-react";
import React from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import { Header } from "../../../components/Header";
import { UIText } from "../../../config/lang-config";
import { ThemedBottomNav } from "../../../components/ThemedBottomNav";
import Collapsible from "react-native-collapsible";
import { Text } from "react-native-elements";
import { capitalize, getDisplayNameEng, isEmpty } from "../../../utils/helpers";
import { theme } from "../../../config/style-configs/theme";
import { ThemedListItem } from "../../../components/ThemedListItem";
import { AndroidBackHandler } from "react-navigation-backhandler";
import { RoleGroupGroupTypeIds } from "../../../config/variable-config";
import { ListRefreshButtonWeb } from "../../../components/ListRefreshButtonWeb";
import { ThemedButton } from "../../../components/ThemedButton";
import { CollapsibleAccordionTitle } from "../../../components/CollapsibleAccordionTitle";
import { TouchableOpacityIOSFix } from "../../../components/TouchableOpacityIOSFix";

@observer
class InboxScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  getGroupTypeString = group =>
    RoleGroupGroupTypeIds.includes(group.typeId)
      ? capitalize(UIText.typeRoleGroupIndicator)
      : group.groupTypeName;

  renderUnreadGroups = groups =>
    groups.length === 0 ? (
      <Text style={this.props.styles.errorMessage}>{UIText.inboxNoUnread}</Text>
    ) : (
      groups.map(g => this.renderGroupCollapsible(g, "collapsedUnreadGroups"))
    );

  renderAllGroups = groups =>
    groups.length === 0 ? (
      <Text style={this.props.styles.errorMessage}>{UIText.inboxNoTopics}</Text>
    ) : (
      groups.map(g => this.renderGroupCollapsible(g, "collapsedAllGroups"))
    );

  renderGroupCollapsible = (group, type) => {
    if (isEmpty(group.topics.filter(topic => Number(topic.msgCount) > 0)))
      return null;

    return (
      <Observer key={group.id}>
        {() => (
          <View>
            <CollapsibleAccordionTitle
              onTitlePress={e =>
                this.controller.handleGroupTitlePress(group, type, e)
              }
              title={`${this.getGroupTypeString(group)}: ${getDisplayNameEng(
                group.profile
              )}`}
              collapsed={group.collapsed}
            />
            <Collapsible collapsed={!!group.collapsed}>
              {this.renderGroupTopics(group)}
            </Collapsible>
          </View>
        )}
      </Observer>
    );
  };

  renderGroupTopics = group =>
    Array.isArray(group.topics)
      ? group.topics
          .filter(topic => Number(topic.msgCount) > 0)
          .map(topic => this.renderGroupTopicItems(group, topic))
      : null;

  renderGroupTopicItems = (group, topic) => (
    <Observer key={topic.id}>
      {() => (
        <TouchableOpacityIOSFix
          onPress={e => this.controller.handleTopicItemPress(group, topic, e)}
        >
          <ThemedListItem
            leftIcon={{ name: "chat" }}
            title={`${
              topic.isTemplate ? UIText.shiftScheduled + " " : ""
            }${topic.topicTypeName || ""}${
              !!topic.description ? ` - ${topic.description}` : ""
            }`}
            subtitle={
              topic["lastPublicMessageTimeLong"]
                ? UIText.inboxLastMessageAt(
                    new Date(
                      topic["lastPublicMessageTimeLong"]
                    ).toLocaleString()
                  )
                : UIText.inboxNoLastActivity
            }
            badge={
              Number(topic.unreadCount) > 0 && {
                value: Number(topic.unreadCount),
                status: "error",
                badgeStyle: this.props.styles.badgeStyle
              }
            }
          />
        </TouchableOpacityIOSFix>
      )}
    </Observer>
  );

  render() {
    return (
      <View style={this.props.styles.style}>
        <View style={this.props.styles.style}>
          <Header
            title={UIText.inbox}
            leftIcon="arrow-back"
            handleLeftPress={this.controller.handleBackPress}
            navigation={this.props.navigation}
          />

          <ScrollView
            ref={view => (this.scrollView = view)}
            contentContainerStyle={this.props.styles.scrollView}
          >
            {this.controller.loading ? (
              <View style={this.props.styles.loading}>
                <ActivityIndicator size="large" color={theme.color} />
              </View>
            ) : (
              <View style={this.props.styles.scrollViewContent}>
                <View style={this.props.styles.buttonContainer}>
                  <ThemedButton
                    icon="expand-less"
                    style={this.props.styles.button}
                    title={UIText.collapseAll}
                    onPress={this.controller.collapseAll}
                  />
                  <ThemedButton
                    icon="expand-more"
                    style={this.props.styles.button}
                    title={UIText.uncollapseAll}
                    onPress={this.controller.uncollapseAll}
                  />
                  {!this.controller.loading && (
                    <ListRefreshButtonWeb
                      loading={this.controller.syncing}
                      onRefresh={this.controller.onRefresh}
                    />
                  )}
                </View>
                {this.controller.currentBottomTab === "unread"
                  ? this.renderUnreadGroups(this.controller.unreadGroups)
                  : this.renderAllGroups(this.controller.allGroups)}
              </View>
            )}
          </ScrollView>
        </View>

        <ThemedBottomNav
          activeTab={this.controller.currentBottomTab}
          onTabPress={this.controller.handleBottomTabChange}
          tabs={this.controller.bottomTabs}
        />

        <AndroidBackHandler onBackPress={this.controller.handleBackPress} />
      </View>
    );
  }
}

export { InboxScreen };
