import React from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import { Button } from "react-native-elements";
import { observer } from "mobx-react";
import { theme } from "../../../../config/style-configs/theme";
import { UIText } from "../../../../config/lang-config";
import { ListRefreshButtonWeb } from "../../../../components/ListRefreshButtonWeb";
import { GenericTable } from "../../../../components/GenericTable";

@observer
class AdminPromoPageView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  renderTableOfPromos = promos => {
    let columnMap = [
      {
        name: "code",
        placeholder: "Code",
        processor: data => data
      },
      {
        name: "description",
        placeholder: "Description",
        processor: data => data
      },
      {
        name: "expiryDate",
        placeholder: "Expiry Date",
        processor: data => (data ? new Date(data).toDateString() : "—")
      },
      {
        name: "trialEndDate",
        placeholder: "Trial end date",
        processor: data => (data ? new Date(data).toDateString() : "—")
      },
      {
        name: "type",
        placeholder: "Promo type",
        processor: data => data
      }
    ].filter(Boolean);

    const columns = columnMap.map(c => c.placeholder);
    const rows = promos.map(row => {
      let result = {};
      for (let field of columnMap) {
        result[field.placeholder] = field.processor(row[field.name]);
      }
      return result;
    });

    return (
      <GenericTable
        cellStyle={this.props.styles.tableCell}
        rows={rows}
        columns={columns}
      />
    );
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        {this.controller.loading ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          <ScrollView contentContainerStyle={this.props.styles.scrollView}>
            <View style={this.props.styles.scrollViewContent}>
              <View style={this.props.styles.buttonContainer}>
                <Button
                  type="clear"
                  title={UIText.adminCentrePromoList}
                  titleStyle={this.props.styles.buttonTitle}
                  onPress={() => {}}
                />
                <ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />
              </View>
              <View style={this.props.styles.table}>
                {this.renderTableOfPromos(this.controller.promos)}
              </View>
            </View>
          </ScrollView>
        )}
      </View>
    );
  }
}

export { AdminPromoPageView };
