import { computed, observable, toJS } from "mobx";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { UIText } from "../../../../config/lang-config";
import {
  placeholderPickerValue,
  typeClassIds
} from "../../../../config/variable-config";
import {
  capitalize,
  formatFixedDigit,
  isEmpty,
  preventDefaultStopProp
} from "../../../../utils/helpers";
import { formService } from "../../../../cdm/services/form-service";
import { timezoneNames } from "../../../../utils/timezone-names";
import { apiService } from "../../../../cdm/services/api-service";
import { endpointConfig } from "../../../../config/api-config";

export class AdminTerritoryPageController {
  @observable loading = true;

  @observable provinces = [];

  // @observable selectedProvince = "";
  // @observable selectedTerritory = "";

  @computed get selectedProvince() {
    return stateController.viewAdminCentreState.territories.selectedProvince;
  }
  @computed get selectedTerritory() {
    return stateController.viewAdminCentreState.territories.selectedTerritory;
  }
  @computed get careReceiverTypeClass() {
    return formService.getBlankFormClassById(typeClassIds.careReceiverProfile);
  }
  @computed get caregiverTypeClass() {
    return formService.getBlankFormClassById(typeClassIds.caregiverProfile);
  }
  @computed get provinceOptions() {
    const provincesField = this.careReceiverTypeClass.find(
      f => f.name === "provinces"
    );
    const provinceOptions = provincesField && provincesField.options;
    return [
      {
        name: placeholderPickerValue,
        placeholder: UIText.adminCentreTerritorySelectProv
      },
      ...provinceOptions
    ];
  }
  @computed get provincePickerField() {
    return {
      type: "picker",
      placeholder: UIText.adminCentreTerritorySelectProv,
      options: this.provinceOptions,
      value: this.selectedProvince
    };
  }
  @computed get territoryOptions() {
    const territoryField = this.careReceiverTypeClass.find(
      f => f.name === "territories"
    );
    let territoryOptions = (territoryField && territoryField.options) || [];
    territoryOptions = territoryOptions.filter(
      o => o["dependOnValue"] === this.selectedProvince
    );
    return [
      {
        name: placeholderPickerValue,
        placeholder: UIText.adminCentreTerritorySelectTerri
      },
      ...territoryOptions,
      {
        name: "addTerritory",
        placeholder: UIText.adminCentreTerritoryAddTerri
      }
    ];
  }
  @computed get territoryPickerField() {
    return {
      type: "picker",
      placeholder: UIText.adminCentreTerritorySelectTerri,
      options: this.territoryOptions,
      value: this.selectedTerritory
    };
  }
  @computed get municipalities() {
    const municipalityField = this.careReceiverTypeClass.find(
      f => f.name === "municipalities"
    );
    let municipalityOptions =
      (municipalityField && municipalityField.options) || [];
    municipalityOptions = municipalityOptions.filter(
      o => o["dependOnValue"] === this.selectedTerritory
    );
    return municipalityOptions;
  }

  @computed get municipalityLabelField() {
    let selectedTerritory = this.territoryOptions.find(
      o => o.name === this.selectedTerritory
    );
    selectedTerritory = selectedTerritory && selectedTerritory.placeholder;
    return {
      showLabel: true,
      type: "input",
      placeholder: UIText.adminCentreTerritoryMunicipalitiesOf(
        selectedTerritory
      ),
      value: " "
    };
  }

  @computed get provincesForm() {
    return this.provinces.map(province => ({
      type: "checkbox",
      name: province.name,
      placeholder: province.displayName,
      value: !!province.enabled
    }));
  }

  constructor(props) {
    this.props = props;
    this._initialize();
  }

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: capitalize(UIText.adminCentre),
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _initialize = async () => {
    if (
      isEmpty(this.caregiverTypeClass) ||
      isEmpty(this.careReceiverTypeClass)
    ) {
      await formService.refreshFormClass(typeClassIds.careReceiverProfile);
      await formService.refreshFormClass(typeClassIds.caregiverProfile);
      return this._initialize();
    }

    return this.getProvinces()
      .catch(this._showError)
      .finally(() => (this.loading = false));
  };

  getProvinces = async () =>
    apiService
      .async("GET", {
        endpoint: endpointConfig.form_provinces
      })
      .then(response => response.data)
      .then(provinces => this.provinces.replace(provinces));

  onProvinceChange = value => {
    if (value === placeholderPickerValue.toString()) value = "";
    stateController.viewAdminCentreState.territories.selectedProvince = value;
    this.onTerritoryChange("");
  };

  onTerritoryChange = value => {
    if (value === "addTerritory") {
      return this.handleAddTerritoryPress();
    }
    if (value === placeholderPickerValue.toString()) value = "";
    stateController.viewAdminCentreState.territories.selectedTerritory = value;
  };

  getMunicipalityMenuOptions = municipality => {
    return [
      {
        key: "rename",
        placeholder: UIText.adminCentreTerritoryMunicipalityRename,
        onSelect: () => this.handleRenameMunicipalityPress(municipality)
      },
      {
        key: "remove",
        placeholder: UIText.adminCentreTerritoryMunicipalityRemove,
        onSelect: () => this.handleRemoveMunicipalityPress(municipality)
      }
    ];
  };

  handleAddTerritoryPress = event => {
    preventDefaultStopProp(event);

    const selectedProvince = this.provinceOptions.find(
      o => o.name === this.selectedProvince
    );

    const timezoneOptions = timezoneNames
      // .filter(tz => tz.match(/America/g))
      .map(tz => ({
        name: tz,
        placeholder: tz
      }));

    const data = observable({
      name: "",
      timezone: { name: timezoneOptions[0].name },
      initialMunicipality: ""
    });

    const namePopup = () =>
      stateController.showPopup({
        title: UIText.adminCentreTerritoryAddTerri,
        leftButtonText: UIText.generalCancel,
        rightButtonText: UIText.generalNext,
        rightButtonPress: () => {
          if (this.territoryOptions.find(tr => tr.placeholder === data.name)) {
            return nameErrorPopup();
          }
          return timezonePopup();
        },
        rightButtonDisabled: !data.name,
        input: {
          title: UIText.adminCentreTerritoryAddTerriName,
          placeholder: UIText.adminCentreTerritoryAddTerriName,
          value: data.name,
          onChange: event => {
            if (!event) return;
            if (event.nativeEvent && typeof event.nativeEvent.text === "string")
              data.name = event.nativeEvent.text;
            stateController.popup.rightButtonDisabled = !data.name;
          }
        }
      });

    const nameErrorPopup = () =>
      stateController.showPopup({
        title: UIText.adminCentreTerritoryAddTerri,
        content: UIText.adminCentreTerritoryDupName(
          data.name,
          selectedProvince.placeholder
        ),
        leftButtonText: UIText.generalConfirm,
        leftButtonPress: namePopup
      });

    const timezonePopup = () =>
      stateController.showPopup({
        title: UIText.adminCentreTerritoryAddTerri,
        leftButtonText: UIText.generalBack,
        leftButtonPress: namePopup,
        rightButtonText: UIText.generalNext,
        rightButtonPress: initialMuniPopup,
        picker: {
          title: UIText.adminCentreTerritoryAddTerriTimezone,
          options: timezoneOptions,
          selected: data.timezone,
          onChange: value => (data.timezone.name = value)
        }
      });

    const initialMuniPopup = () =>
      stateController.showPopup({
        title: UIText.adminCentreTerritoryAddTerri,
        leftButtonText: UIText.generalBack,
        leftButtonPress: timezonePopup,
        rightButtonText: UIText.generalNext,
        rightButtonPress: reviewPopup,
        rightButtonDisabled: !data.initialMunicipality,
        input: {
          title: UIText.adminCentreTerritoryAddTerriInitMuni,
          placeholder: UIText.adminCentreTerritoryAddTerriInitMuni,
          value: data.initialMunicipality,
          footer: UIText.adminCentreTerritoryAddTerriInitMuniFooter,
          onChange: event => {
            if (!event) return;
            if (event.nativeEvent && typeof event.nativeEvent.text === "string")
              data.initialMunicipality = event.nativeEvent.text;
            stateController.popup.rightButtonDisabled = !data.initialMunicipality;
          }
        }
      });

    const reviewPopup = () =>
      stateController.showPopup({
        title: UIText.adminCentreTerritoryAddTerri,
        leftButtonText: UIText.generalBack,
        leftButtonPress: initialMuniPopup,
        rightButtonText: UIText.generalSubmit,
        rightButtonPress: submit,
        inputSet: [
          {
            title: UIText.adminCentreTerritoryAddTerriName,
            value: data.name
          },
          {
            title: UIText.adminCentreTerritoryAddTerriTimezone,
            value: data.timezone.name
          },
          {
            title: UIText.adminCentreTerritoryAddTerriInitMuni,
            value: data.initialMunicipality
          }
        ].map(input => (input.disabled = true) && input)
      });

    const submit = async () => {
      const caregiverTerritoryFieldResult = this.newCaregiverTerritoryField(
        data
      );
      const caregiverMunicipalityFieldResult = this.newCaregiverMunicipalityField(
        data,
        true,
        caregiverTerritoryFieldResult.data
      );
      const careReceiverTerritoryField = this.newCareReceiverTerritoryOption(
        data,
        caregiverTerritoryFieldResult.data
      );
      const careReceiverTerritoryOption =
        careReceiverTerritoryField.options &&
        careReceiverTerritoryField.options.find(
          o => o.placeholder === data.name
        );
      const careReceiverTimezoneField = this.newCareReceiverTimezoneOption(
        data,
        careReceiverTerritoryOption
      );
      const careReceiverMunicipalityField = this.newCareReceiverMunicipalityOption(
        data,
        true,
        caregiverMunicipalityFieldResult.data,
        careReceiverTerritoryField
      );

      const caregiverPostData = {
        id: typeClassIds.caregiverProfile,
        fields: [
          {
            type: "add",
            index: caregiverTerritoryFieldResult.index,
            data: caregiverTerritoryFieldResult.data
          },
          {
            type: "add",
            index: caregiverMunicipalityFieldResult.index,
            data: caregiverMunicipalityFieldResult.data
          }
        ]
      };
      const careReceiverPostData = {
        id: typeClassIds.careReceiverProfile,
        fields: [
          {
            type: "update",
            name: careReceiverTerritoryField.name,
            data: careReceiverTerritoryField
          },
          {
            type: "update",
            name: careReceiverMunicipalityField.name,
            data: careReceiverMunicipalityField
          },
          {
            type: "update",
            name: careReceiverTimezoneField.name,
            data: careReceiverTimezoneField
          }
        ]
      };

      return stateController
        .dismissPopup()
        .then(() =>
          stateController.showPopup({
            title: UIText.adminCentreTerritoryAddTerri,
            content: UIText.pleaseWait
          })
        )
        .then(() => this.submitTypeClassChange(caregiverPostData))
        .then(() => this.submitTypeClassChange(careReceiverPostData))
        .then(stateController.dismissPopup)
        .then(this.onRefresh)
        .then(() => this.onTerritoryChange(careReceiverTerritoryOption.name))
        .catch(this._showError);
    };

    return namePopup();
  };

  handleAddMunicipalityPress = event => {
    preventDefaultStopProp(event);

    const careReceiverTerritoryField = this.careReceiverTypeClass.find(
      f => f.name === "territories"
    );
    const careReceiverTerritoryOption = careReceiverTerritoryField.options.find(
      o => o.name === this.selectedTerritory
    );
    const caregiverTerritoryField = this.caregiverTypeClass.find(
      f =>
        careReceiverTerritoryOption &&
        careReceiverTerritoryOption.match[0] &&
        f.name === careReceiverTerritoryOption.match[0].key
    );

    const data = observable({
      name: ""
    });

    const namePopup = async () =>
      stateController.showPopup({
        title: UIText.adminCentreTerritoryAddMunicipality,
        leftButtonText: UIText.generalCancel,
        rightButtonText: UIText.generalSubmit,
        rightButtonPress: () => {
          if (this.municipalities.find(m => m.placeholder === data.name)) {
            return nameErrorPopup();
          }
          return submit();
        },
        rightButtonDisabled: !data.name,
        input: {
          title: UIText.adminCentreTerritoryAddMunicipalityName,
          placeholder: UIText.adminCentreTerritoryAddMunicipalityName,
          value: data.name,
          onChange: event => {
            if (!event) return;
            if (event.nativeEvent && typeof event.nativeEvent.text === "string")
              data.name = event.nativeEvent.text;
            stateController.popup.rightButtonDisabled = !data.name;
          }
        }
      });

    const nameErrorPopup = () =>
      stateController.showPopup({
        title: UIText.adminCentreTerritoryAddMunicipality,
        content: UIText.adminCentreTerritoryDupName(
          data.name,
          careReceiverTerritoryOption.placeholder
        ),
        leftButtonText: UIText.generalConfirm,
        leftButtonPress: namePopup
      });

    const submit = async () => {
      const caregiverMunicipalityFieldResult = this.newCaregiverMunicipalityField(
        data,
        false,
        caregiverTerritoryField
      );
      const careReceiverMunicipalityField = this.newCareReceiverMunicipalityOption(
        data,
        false,
        caregiverMunicipalityFieldResult.data
      );

      const caregiverPostData = {
        id: typeClassIds.caregiverProfile,
        fields: [
          {
            type: "add",
            index: caregiverMunicipalityFieldResult.index,
            data: caregiverMunicipalityFieldResult.data
          }
        ]
      };
      const careReceiverPostData = {
        id: typeClassIds.careReceiverProfile,
        fields: [
          {
            type: "update",
            name: careReceiverMunicipalityField.name,
            data: careReceiverMunicipalityField
          }
        ]
      };

      return stateController
        .dismissPopup()
        .then(() =>
          stateController.showPopup({
            title: UIText.adminCentreTerritoryAddMunicipality,
            content: UIText.pleaseWait
          })
        )
        .then(() => this.submitTypeClassChange(caregiverPostData))
        .then(() => this.submitTypeClassChange(careReceiverPostData))
        .then(stateController.dismissPopup)
        .then(this.onRefresh)
        .catch(this._showError);
    };

    return namePopup();
  };

  handleRenameMunicipalityPress = muni => {
    const careReceiverTerritoryField = this.careReceiverTypeClass.find(
      f => f.name === "territories"
    );
    const careReceiverTerritoryOption = careReceiverTerritoryField.options.find(
      o => o.name === this.selectedTerritory
    );

    let municipality = toJS(muni);

    const data = observable({
      name: municipality.placeholder || ""
    });

    const namePopup = async =>
      stateController.showPopup({
        title: UIText.adminCentreTerritoryRenameMunicipality,
        leftButtonText: UIText.generalCancel,
        rightButtonText: UIText.generalSubmit,
        rightButtonPress: () => {
          if (this.municipalities.find(m => m.placeholder === data.name)) {
            return nameErrorPopup();
          }
          return submit();
        },
        rightButtonDisabled:
          !data.name || data.name === municipality.placeholder,
        input: {
          title: UIText.adminCentreTerritoryAddMunicipalityName,
          placeholder: UIText.adminCentreTerritoryAddMunicipalityName,
          value: data.name,
          onChange: event => {
            if (!event) return;
            if (event.nativeEvent && typeof event.nativeEvent.text === "string")
              data.name = event.nativeEvent.text;
            stateController.popup.rightButtonDisabled =
              !data.name || data.name === municipality.placeholder;
          }
        }
      });

    const nameErrorPopup = () =>
      stateController.showPopup({
        title: UIText.adminCentreTerritoryAddMunicipality,
        content: UIText.adminCentreTerritoryDupName(
          data.name,
          careReceiverTerritoryOption.placeholder
        ),
        leftButtonText: UIText.generalConfirm,
        leftButtonPress: namePopup
      });

    const submit = async () => {
      const careReceiverMunicipalityField = toJS(
        this.careReceiverTypeClass.find(f => f.name === "municipalities")
      );

      const careReceiverMunicipalityOption =
        careReceiverMunicipalityField &&
        careReceiverMunicipalityField.options.find(
          o => o.name === municipality.name
        );
      if (careReceiverMunicipalityOption)
        careReceiverMunicipalityOption.placeholder = data.name;

      const caregiverMunicipalityField = this.caregiverTypeClass.find(
        f =>
          careReceiverMunicipalityOption &&
          careReceiverMunicipalityOption.match[0] &&
          f.name === careReceiverMunicipalityOption.match[0].key
      );
      if (caregiverMunicipalityField)
        caregiverMunicipalityField.placeholder = data.name;

      const caregiverPostData = {
        id: typeClassIds.caregiverProfile,
        fields: [
          {
            type: "update",
            name: caregiverMunicipalityField.name,
            data: caregiverMunicipalityField
          }
        ]
      };
      const careReceiverPostData = {
        id: typeClassIds.careReceiverProfile,
        fields: [
          {
            type: "update",
            name: careReceiverMunicipalityField.name,
            data: careReceiverMunicipalityField
          }
        ]
      };

      return stateController
        .dismissPopup()
        .then(() =>
          stateController.showPopup({
            title: UIText.adminCentreTerritoryRenameMunicipality,
            content: UIText.pleaseWait
          })
        )
        .then(() => this.submitTypeClassChange(caregiverPostData))
        .then(() => this.submitTypeClassChange(careReceiverPostData))
        .then(stateController.dismissPopup)
        .then(this.onRefresh)
        .catch(this._showError);
    };

    return namePopup();
  };

  handleRemoveMunicipalityPress = async municipality => {
    let selectedTerritory = this.territoryOptions.find(
      o => o.name === this.selectedTerritory
    );
    selectedTerritory = selectedTerritory && selectedTerritory.placeholder;

    const caregiverMunicipalityField = toJS(
      this.caregiverTypeClass.find(
        f =>
          f.set === "ableMunicipalities" &&
          f.placeholder === municipality.placeholder
      )
    );

    const inUsePopup = () =>
      stateController.showPopup({
        title: UIText.adminCentreTerritoryRemoveMunicipality,
        content: UIText.adminCentreTerritoryRemoveMunicipalityInUse(
          municipality.placeholder
        ),
        contentAlign: "left",
        buttonSet: [
          {
            title: `${UIText.adminCentreTerritoryMunicipalityRename} ${
              municipality.placeholder
            }`,
            onPress: () =>
              stateController
                .dismissPopup()
                .then(() => this.handleRenameMunicipalityPress(municipality))
          },
          {
            title: UIText.adminCentreTerritoryReplaceMunicipalityPick,
            onPress: () =>
              stateController
                .dismissPopup()
                .then(() => this.handleReplaceMunicipalityPress(municipality))
          },
          {
            title: UIText.generalCancel,
            onPress: stateController.dismissPopup,
            topDivider: true
          }
        ].map(b => {
          b.align = "center";
          return b;
        })
      });

    const confirmPopup = async () =>
      stateController.showPopup({
        title: UIText.adminCentreTerritoryRemoveMunicipality,
        content: UIText.adminCentreTerritoryRemoveMunicipalityConfirm(
          municipality.placeholder,
          selectedTerritory
        ),
        leftButtonText: UIText.generalNo,
        rightButtonText: UIText.generalYes,
        rightButtonPress: submit
      });

    const checkInUse = async () => {
      let caregiverInUse, careReceiverInUse;
      return await this.checkFieldInUseAvailability(
        caregiverMunicipalityField && caregiverMunicipalityField.name,
        true
      )
        .then(result => (caregiverInUse = result))
        .then(
          async () =>
            await this.checkFieldInUseAvailability(
              "municipalities",
              municipality && municipality.name
            )
        )
        .then(result => (careReceiverInUse = result))
        .then(() => caregiverInUse || careReceiverInUse)
        .catch(this._showError);
    };

    const submit = async () => {
      const careReceiverMunicipalityField = toJS(
        this.careReceiverTypeClass.find(f => f.name === "municipalities")
      );
      if (careReceiverMunicipalityField.options) {
        careReceiverMunicipalityField.options = careReceiverMunicipalityField.options.filter(
          o => o.name !== municipality.name
        );
      }

      const caregiverPostData = {
        id: typeClassIds.caregiverProfile,
        fields: [
          {
            type: "remove",
            name: caregiverMunicipalityField && caregiverMunicipalityField.name
          }
        ]
      };
      const careReceiverPostData = {
        id: typeClassIds.careReceiverProfile,
        fields: [
          {
            type: "update",
            name: careReceiverMunicipalityField.name,
            data: careReceiverMunicipalityField
          }
        ]
      };

      return stateController
        .dismissPopup()
        .then(() =>
          stateController.showPopup({
            title: UIText.adminCentreTerritoryRemoveMunicipality,
            content: UIText.pleaseWait
          })
        )
        .then(() => this.submitTypeClassChange(caregiverPostData))
        .then(() => this.submitTypeClassChange(careReceiverPostData))
        .then(stateController.dismissPopup)
        .then(this.onRefresh)
        .catch(this._showError);
    };

    return stateController
      .showPopup({
        title: UIText.adminCentreTerritoryRemoveMunicipality,
        content: UIText.pleaseWait
      })
      .then(checkInUse)
      .then(inUse => (inUse ? inUsePopup() : confirmPopup()));
  };

  handleReplaceMunicipalityPress = async municipality => {
    return stateController.showPopup({
      title: UIText.adminCentreTerritoryReplaceMunicipality,
      content: UIText.comingSoon,
      leftButtonText: UIText.generalConfirm
    });
  };

  newCaregiverTerritoryField = data => {
    const territoryFields = toJS(
      this.caregiverTypeClass
        .filter(f => f.set === "ableTerritories")
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );
    const lastTerritoryField = territoryFields[territoryFields.length - 1];

    const index =
      this.caregiverTypeClass.findIndex(
        f => f.name === lastTerritoryField.name
      ) + 1;

    const serial = Number(lastTerritoryField.name.split("ableTerritories")[1]);
    const name =
      (!isNaN(serial) && `ableTerritories${formatFixedDigit(serial + 1, 2)}`) ||
      "ableTerritories00";

    return {
      data: {
        flags: {
          caregiver: true,
          marketplace: true
        },
        icon: "",
        name,
        placeholder: data.name,
        set: "ableTerritories",
        type: "checkbox"
      },
      index
    };
  };

  newCareReceiverTerritoryOption = (data, matchField) => {
    const careReceiverTerritoryField = this.careReceiverTypeClass.find(
      f => f.name === "territories"
    );
    const territoryOptions =
      careReceiverTerritoryField &&
      toJS(
        careReceiverTerritoryField.options.sort((a, b) =>
          a.name > b.name ? 1 : -1
        )
      );
    if (!territoryOptions) return;

    const lastTerritoryOption = territoryOptions[territoryOptions.length - 1];
    const serial = Number(lastTerritoryOption.name.split("needTerritories")[1]);
    const name =
      (!isNaN(serial) && `needTerritories${formatFixedDigit(serial + 1, 2)}`) ||
      "needTerritories00";

    const newTerritoryOption = {
      name,
      match: [
        {
          key: matchField.name,
          value: "true"
        }
      ],
      placeholder: data.name,
      dependOnValue: this.selectedProvince
    };

    const field = toJS(careReceiverTerritoryField);
    field.options.push(newTerritoryOption);
    return field;
  };

  newCareReceiverTimezoneOption = (data, territoryOption) => {
    if (!data || !data.timezone || !territoryOption) return;

    const careReceiverTimezoneField = this.careReceiverTypeClass.find(
      f => f.name === "timezone"
    );
    const timezoneOptions =
      careReceiverTimezoneField && toJS(careReceiverTimezoneField.options);
    if (!timezoneOptions) return;

    const timezoneOption = timezoneOptions.find(
      tz => tz.dependOnValue === territoryOption.name
    );
    if (!!timezoneOption) return;

    const { timezone } = data;

    const newTimezoneOption = {
      name: timezone.name,
      placeholder: timezone.name,
      dependOnValue: territoryOption.name
    };

    const field = toJS(careReceiverTimezoneField);
    field.options.push(newTimezoneOption);
    return field;
  };

  newCaregiverMunicipalityField = (data, isInitialOption, territoryField) => {
    if (!territoryField) return;

    const municipalityFields = toJS(
      this.caregiverTypeClass
        .filter(
          f =>
            f.name.match(/ableMunicipalities/g) &&
            f.name !== "ableMunicipalitiesText"
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );
    const lastMunicipalityField =
      municipalityFields[municipalityFields.length - 1];

    const index =
      this.caregiverTypeClass.findIndex(
        f => f.name === lastMunicipalityField.name
      ) + 1;

    const serial = Number(
      lastMunicipalityField.name.split("ableMunicipalities")[1]
    );
    const name =
      (!isNaN(serial) &&
        `ableMunicipalities${formatFixedDigit(serial + 1, 2)}`) ||
      "ableMunicipalities00";

    return {
      data: {
        dependOn: territoryField.name,
        flags: {
          caregiver: true,
          marketplace: true
        },
        icon: "",
        name,
        placeholder: isInitialOption ? data.initialMunicipality : data.name,
        set: "ableMunicipalities",
        type: "checkbox"
      },
      index
    };
  };

  newCareReceiverMunicipalityOption = (
    data,
    isInitialOption,
    matchField,
    territoryField
  ) => {
    if (!territoryField && isInitialOption) return;

    const careReceiverMunicipalityField = this.careReceiverTypeClass.find(
      f => f.name === "municipalities"
    );
    const municipalityOptions =
      careReceiverMunicipalityField &&
      toJS(
        careReceiverMunicipalityField.options.sort((a, b) =>
          a.name > b.name ? 1 : -1
        )
      );
    if (!municipalityOptions) return;

    const lastMunicipalityOption =
      municipalityOptions[municipalityOptions.length - 1];
    const serial = Number(
      lastMunicipalityOption.name.split("needMunicipalities")[1]
    );
    const name =
      (!isNaN(serial) &&
        `needMunicipalities${formatFixedDigit(serial + 1, 2)}`) ||
      "needMunicipalities00";

    let dependency;
    if (isInitialOption) {
      dependency = territoryField.options.find(
        o => o.placeholder === data.name
      );
    }

    const newMunicipalityOption = {
      dependOnValue: isInitialOption ? dependency.name : this.selectedTerritory,
      match: [
        {
          key: matchField.name,
          value: "true"
        }
      ],
      name,
      placeholder: isInitialOption ? data.initialMunicipality : data.name
    };

    const field = toJS(careReceiverMunicipalityField);
    field.options.push(newMunicipalityOption);
    return field;
  };

  checkFieldInUseAvailability = async (name, value) => {
    if (!name || !value) return;

    const data = { name, value };
    return apiService
      .async("POST", {
        endpoint: endpointConfig.profile_field_in_use,
        data
      })
      .then(response => {
        const result = response && response.data;
        if (!result && typeof result !== "boolean") {
          return Promise.reject("Unknown error");
        }
        return result;
      });
  };

  submitTypeClassChange = async data => {
    console.log(data);

    const typeClassId = data.id;
    if (!typeClassId) return;

    return apiService
      .async("PATCH", {
        endpoint: endpointConfig.update_form_class_byId(typeClassId),
        data
      })
      .then(console.log);
  };

  onRefresh = async () => {
    this.loading = true;
    return Promise.all([
      formService.refreshFormClass(typeClassIds.careReceiverProfile),
      formService.refreshFormClass(typeClassIds.caregiverProfile),
      formService.refreshFormClass(typeClassIds.candidateTopic),
      formService.refreshFormClass(typeClassIds.paidCaregiverProfile),
      this.getProvinces()
    ]).finally(() => (this.loading = false));
  };

  onToggleProvince = provinceField => {
    const { name, value } = provinceField || {};
    const province = this.provinces.find(p => p.name === name);
    if (!province) return;
    province.enabled = !value;
    return apiService
      .async("GET", {
        endpoint: endpointConfig.toggle_form_province(province.id)
      })
      .then(this.getProvinces)
      .then(() =>
        formService.refreshFormClass(typeClassIds.careReceiverProfile)
      )
      .catch(this._showError);
  };
}
