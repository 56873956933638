import React from "react";
import { TestScreen } from "./view";
import { styles } from "./styles";

// Playground
export class Test extends React.Component {
  static navigationOptions = {
    title: "Playground"
  };

  render() {
    return <TestScreen {...this.props} styles={styles} />;
  }
}
