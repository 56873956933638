import { theme } from "../../config/style-configs/theme";
import { common } from "../../config/style-configs/common";

const checkboxSize = 40;

export const styles = {
  style: {
    width: "100%"
  },
  card: {
    width: "100%",
    boxShadow: "0 4px 16px rgba(0,0,0,.12)",
    borderRadius: 7,
    borderWidth: 0,
    margin: 0,
    padding: 0
  },
  content: {
    padding: 15,
    minHeight: 285,
    maxHeight: 285,
    alignItems: "center"
  },
  compactContent: {
    flexDirection: "row",
    alignItems: "center",
    padding: 15,
    minHeight: 105,
    // maxHeight: 105,
    overflow: "hidden"
  },
  titleWrapper: {
    marginHorizontal: 10
  },
  titleContent: {
    width: "100%",
    height: 55,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  titleChild: {
    height: "100%",
    justifyContent: "center"
  },
  titleTextContainer: {
    // flexGrow: 1
    // flex: 5
    flex: 1,
    flexShrink: 1
  },
  titleText: {
    paddingLeft: 5,
    textAlign: "left",
    color: "#333",
    fontWeight: "bold",
    fontSize: theme.FONT_SIZE_LARGE
  },
  titleIcon: {
    // flexGrow: 0.1,
    flexShrink: 0,
    minWidth: 45,
    alignItems: "center",
    justifyContent: "center"
  },
  titleDivider: {
    ...common.divider,
    width: "100%"
  },
  checkboxSize,
  checkbox: {
    width: checkboxSize,
    height: checkboxSize,
    margin: 0,
    padding: 0
  },
  menuIconSize: 32,
  avatarContainer: {
    flex: 1,
    maxHeight: 100,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  },
  avatarContainerCompact: {
    alignItems: "center",
    justifyContent: "space-around"
  },
  cardChildren: {
    flex: 1,
    padding: 10
  },
  text: {
    fontSize: theme.FONT_SIZE_MEDIUM
  }
};
