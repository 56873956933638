import { computed, observable } from 'mobx';
import { Linking, Platform } from 'react-native';
import { capitalize, isEmpty, preventDefaultStopProp } from '../../../utils/helpers';
import { stateController } from '../../../cdm/controllers/state-controller';
import { apiService } from '../../../cdm/services/api-service';
import { UIText } from '../../../config/lang-config';
import { backCheckUrl } from '../../../config/variable-config';
import { mcbEndpointConfig } from '../../../custom/mcb/config/api-config';

export class CaregiverPostInterviewController {
  @observable loading = true;
  @observable submitting = false;
  @observable emailedChecked = false;
  @observable backgroundChecked = false;
  @observable references = [
    {
      id: 1,
      name: "",
      contact: ""
    }
  ];
  @observable submissionHash = "";

  @computed get buttonDisabled() {
    const emptyReference = isEmpty(this.references);
    const noValidReference = !this.references.every(
      r => !!r.name && !!r.contact
    );
    const noCheck = !this.emailedChecked && !this.backgroundChecked;
    return emptyReference || noValidReference || noCheck;
  }
  @computed get submissionToken() {
    return stateController.viewCaregiverPostInterviewState.submissionToken;
  }
  @computed get submissionUser() {
    return stateController.viewCaregiverPostInterviewState.submissionUser;
  }

  componentWillUnmount = () => {
    stateController.clearCaregiverPostInterviewState();
  };

  componentDidMount() {
    stateController.currentScreen = "CaregiverSignUp";
  }

  constructor(props) {
    this.props = props;
    this._initialize();
  }

  _showError = (err, kickout) => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.title,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: () =>
        stateController
          .dismissPopup()
          .then(() => kickout && this.props.navigation.navigate("Login"))
    });
  };

  _initialize = () => {
    this.loading = true;
    const initError = () =>
      stateController.showPopup({
        title: UIText.title,
        content: UIText.caregiverInfoReqFormInvalidLink,
        leftButtonText: UIText.generalConfirm,
        leftButtonPress: () =>
          stateController
            .dismissPopup()
            .then(() => this.props.navigation.navigate("Login"))
      });
    if (!this.submissionUser || !this.submissionToken) return initError();
    this._getSubmissionHash()
      .then(hash => (this.submissionHash = hash))
      .catch(console.warn)
      .finally(() => {
        if (!this.submissionHash) return initError();
        this.loading = false;
      });
  };

  _getSubmissionHash = async () => {
    return apiService
      .async("POST", {
        endpoint: mcbEndpointConfig.caregiver_onboard_info_req_hash,
        data: {
          identifier: this.submissionUser,
          token: this.submissionToken
        }
      })
      .then(response => {
        const data = response && response.data;
        return (data || {}).hash || "";
      });
  };

  removeReference = id => {
    const reference = this.references.find(r => r.id === id);
    if (!reference) return;
    return this.references.remove(reference);
  };

  addReference = () => {
    const last = this.references[this.references.length - 1] || {};
    const lastId = last.id || 0;
    this.references.push({
      id: lastId + 1,
      name: "",
      contact: ""
    });
  };

  updateReference = (id, fieldName, event) => {
    if (!event) return;
    const reference = this.references.find(r => r.id === id);
    if (!reference) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      reference[fieldName] = event.nativeEvent.text;
  };

  handleEmailedCheckboxChange = event => {
    if (!this.emailedChecked && this.backgroundChecked)
      this.backgroundChecked = false;
    this.emailedChecked = !this.emailedChecked;
  };

  handleBackgroundCheckboxChange = event => {
    if (!this.backgroundChecked && this.emailedChecked)
      this.emailedChecked = false;
    this.backgroundChecked = !this.backgroundChecked;
  };

  openEmail = () => {
    const url = "mailto:stephanie@mycarebase.com";
    if (Platform.OS === "web") {
      window.open(url);
    } else {
      return Linking.openURL(url);
    }
  };

  openBackCheck = () => {
    const url = backCheckUrl;
    if (Platform.OS === "web") {
      window.open(url);
    } else {
      return Linking.openURL(url);
    }
  };

  execSubmit = event => {
    preventDefaultStopProp(event);
    if (this.buttonDisabled) return;
    this.submitting = true;
    const data = {
      data: {
        references: this.references
          .map(r => `Name: ${r.name}, Contact: ${r.contact}`)
          .join("\n"),
        backgroundChecked: this.backgroundChecked || undefined,
        backgroundEmailed: this.emailedChecked || undefined
      },
      hash: this.submissionHash
    };
    return stateController
      .showPopup({
        title: UIText.title,
        content: UIText.pleaseWait
      })
      .then(() =>
        apiService.async("POST", {
          endpoint: mcbEndpointConfig.caregiver_onboard_info_req_submit,
          data
        })
      )
      .then(stateController.dismissPopup)
      .then(() =>
        stateController.showPopup({
          title: capitalize(UIText.success),
          content: UIText.caregiverInfoReqSuccess,
          leftButtonText: UIText.generalConfirm,
          leftButtonPress: () =>
            stateController
              .dismissPopup()
              .then(() => this.props.navigation.navigate("Login")),
          dismissOnBackPress: true
        })
      )
      .catch(this._showError)
      .finally(() => (this.submitting = false));
  };
}
