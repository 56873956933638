import { clientController } from "../../../cdm/controllers/client-controller";
import { stateController } from "../../../cdm/controllers/state-controller";
import { computed, observable, reaction } from "mobx";
import { UIText } from "../../../config/lang-config";
import {
  isEmpty,
  isEqual,
  parseErrorMsg,
  preventDefaultStopProp
} from "../../../utils/helpers";
import {
  billingEnabledGroupTypeIds,
  planMarketingContactUrl,
  planMarketingNumber,
  wpPaymentMethodUri,
  wpShopGroupTypeUrls,
  wpSubscriptionUri
} from "../../../config/variable-config";
import { wordPressService } from "../../../cdm/services/wordpress-service";
import {
  getWpWcBillingDetail,
  updateWpWcBillingDetail
} from "../../../cdm/lib/group-utilities";
import { getBillingForm } from "../../../custom/mcb/config/billing_form";
import { formService } from "../../../cdm/services/form-service";
import { provinces } from "canada";
import { Linking, Platform } from "react-native";
import { endpointConfig } from "../../../config/api-config";

export class BillingController {
  wpFrameDisposer;

  @observable loading = true;
  @observable submitting = false;

  @observable currentPage = "welcome";
  @observable isWpPaymentWindow = false;

  @observable billingForm = getBillingForm();

  @observable pickerResidentialProvinceOptions = [
    {
      name: "",
      placeholder: "Please select..."
    },
    {
      name: "BC",
      placeholder: "British Columbia"
    },
    {
      name: "ON",
      placeholder: "Ontario"
    }
  ];

  @computed get subscriptions() {
    return (clientController.client.user || {}).wpSubscriptions || [];
  }

  @computed get isVisitor() {
    return clientController.isVisitor;
  }
  @computed get user() {
    return clientController.client.user || {};
  }
  @computed get groupId() {
    return stateController.viewGroupId;
  }
  @computed get currentGroup() {
    return clientController.findGroupById(this.groupId);
  }
  @computed get currentSubscription() {
    return (
      clientController.findGroupWpSubscriptionById(this.groupId, true) || {}
    );
  }
  @computed get profile() {
    return (this.currentGroup.profile && this.currentGroup.profile.data) || {};
  }
  @computed get selfMember() {
    return (
      (Array.isArray(this.currentGroup.members) &&
        this.currentGroup.members.find(m => m.userId === this.user.id)) ||
      {}
    );
  }

  @computed get billingFormData() {
    return formService.disassembleFormData(this.billingForm) || {};
  }
  @computed get wpWcBillingFormData() {
    return {
      addr1: this.wpWcBillingDetail["address_1"],
      addr2: this.wpWcBillingDetail["address_2"],
      city: this.wpWcBillingDetail.city,
      prov: this.wpWcBillingDetail.state,
      country: this.wpWcBillingDetail.country || "CA",
      postalCode: this.wpWcBillingDetail["postcode"],
      phone: this.wpWcBillingDetail.phone
    };
  }
  @computed get wpWcBillingFormChanged() {
    return !isEqual(this.billingFormData, this.wpWcBillingFormData);
  }
  @computed get validBilling() {
    return formService.validateRequired(this.billingForm, true);
  }

  constructor(props) {
    this.props = props;
    stateController.currentScreen = "Billing";

    this.wpFrameDisposer = reaction(
      () => !!wordPressService.wpUrl,
      () => {
        if (!wordPressService.wpUrl) {
          this.onRefreshBilling();
          this.currentPage = "welcome";
        }
      }
    );

    setTimeout(this._initialize);
  }

  componentWillUnmount() {
    this.wpFrameDisposer && this.wpFrameDisposer();
  }

  _initialize = () =>
    this._loadAllData()
      .then(() => (this.loading = false))
      .catch(err => this._showError(err, true));

  _loadAllData = () =>
    Promise.all([this.getGroup(), this.getUserSubscription()].filter(Boolean));

  _showError = (err, kickout) => {
    console.warn(err);
    return stateController.showPopup({
      title: UIText.planBillingPlan,
      content: parseErrorMsg(err),
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: () =>
        stateController.dismissPopup().then(() => {
          if (kickout) {
            this.props.navigation.navigate("Group");
          }
        })
    });
  };

  getGroup = async () => {
    const groupIdOverride = Number(this.props.navigation.getParam("group"));
    if (groupIdOverride && clientController.loginState) {
      stateController.viewGroupId = groupIdOverride;
    }

    // If is visitor account or currentGroup not exist or not a billing enabled type of group, back to Root.
    if (
      this.isVisitor ||
      (isEmpty(this.currentGroup) ||
        !billingEnabledGroupTypeIds.includes(this.currentGroup.typeId))
    ) {
      stateController.viewGroupId = 0;
      return this.props.navigation.navigate("Root");
    }

    return Promise.resolve();
  };

  getUserSubscription = async () =>
    clientController.getUserWpSubscriptions(true);

  getWpWcBillingDetail = async () =>
    getWpWcBillingDetail().then(billing => (this.wpWcBillingDetail = billing));

  modBillingFormForWpMode = () => {
    return stateController
      .showPopup({
        title: UIText.planBillingPlan,
        content: UIText.pleaseWait
      })
      .then(this.getWpWcBillingDetail)
      .then(() => {
        this.billingForm = this.billingForm.filter(f => f.name !== "name");
        const cityField = this.billingForm.find(f => f.name === "city");
        cityField.value = this.wpWcBillingDetail.city;
        const provField = this.billingForm.find(f => f.name === "prov");
        provField.required = true;
        provField.type = "picker";
        provField.options = Object.entries(provinces).map(([abbr, name]) => ({
          name: abbr,
          placeholder: name
        }));
        const countryField = this.billingForm.find(f => f.name === "country");
        countryField.required = true;
        countryField.disabled = true;
        if (!this.billingForm.some(f => f.name === "postalCode")) {
          const postalCodeField = {
            name: "postalCode",
            type: "input",
            placeholder: "Postal code",
            required: true
          };
          this.billingForm.splice(
            this.billingForm.length - 2,
            0,
            postalCodeField
          );
        }
        return this.populateWpWcBillingForm();
      })
      .then(stateController.dismissPopup);
  };

  populateWpWcBillingForm = () => {
    for (const field of this.billingForm) {
      const { name } = field;
      if (this.wpWcBillingFormData[name])
        field.value = this.wpWcBillingFormData[name];
    }
  };

  handleBackPress = event => {
    preventDefaultStopProp(event);
    if (this.currentPage === "wordpress") {
      wordPressService.onDismissFrame();
      return (this.currentPage = "welcome");
    }
    if (this.currentPage === "billing") {
      return (this.currentPage = "welcome");
    }
    const id = stateController.viewGroupId;
    return this.props.navigation.navigate("Group", { group: id });
  };

  handleSubscriptionPress = event => {
    preventDefaultStopProp(event);
    this.isWpPaymentWindow = false;
    const targetUrl = isEmpty(this.currentSubscription)
      ? wpShopGroupTypeUrls[this.currentGroup.typeId]
      : wpSubscriptionUri;
    if (!targetUrl)
      return stateController.showPopup({
        title: UIText.planSubscription,
        content: UIText.subscriptionNoAvailableProducts,
        leftButtonText: UIText.generalConfirm
      });
    return this.setWpModalUrl(targetUrl);
  };

  handleUpdatePaymentPress = event => {
    preventDefaultStopProp(event);
    this.isWpPaymentWindow = true;
    return this.setWpModalUrl(wpPaymentMethodUri);
  };

  handleUpdateBillingAddressPress = event => {
    preventDefaultStopProp(event);
    return this.modBillingFormForWpMode().then(
      () => (this.currentPage = "billing")
    );
  };

  handleBillingFormSubmit = async () => {
    this.submitting = true;
    return stateController
      .showPopup({
        title: UIText.subscriptionChangeAddress,
        content: UIText.pleaseWait
      })
      .then(() => updateWpWcBillingDetail(this.billingFormData))
      .then(stateController.dismissPopup)
      .then(this.handleBackPress)
      .catch(this._showError)
      .finally(() => (this.submitting = false));
  };

  handleMarketingNumberPress = event => {
    preventDefaultStopProp(event);
    if (Platform.OS === "web") {
      return window.open(`tel:${planMarketingNumber}`);
    } else {
      return Linking.openURL(`tel:${planMarketingNumber}`);
    }
  };

  handleMarketingContactFormPress = event => {
    preventDefaultStopProp(event);
    if (Platform.OS === "web") {
      return window.open(planMarketingContactUrl);
    } else {
      return Linking.openURL(planMarketingContactUrl);
    }
  };

  setWpModalUrl = callbackUri => {
    this.currentPage = "wordpress";
    this.loading = true;
    wordPressService.setWpHostname(endpointConfig.wp_hostname);
    return wordPressService
      .setWpModalUrl(callbackUri)
      .catch(this._showError)
      .finally(() => (this.loading = false));
  };

  onRefreshBilling = async () => {
    this.loading = true;
    return this.getUserSubscription()
      .catch(this._showError)
      .finally(() => (this.loading = false));
  };

  onFieldInputChange = (field, event) => {
    if (!event) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      field.value = event.nativeEvent.text;
  };

  helpFocusNextField = (field, formFields, form) =>
    formService.helpFocusNextField(
      field,
      formFields,
      form,
      this.handleBillingFormSubmit
    );
}
