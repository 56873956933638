import { common } from "../../../config/style-configs/common";
import { theme } from "../../../config/style-configs/theme";

export const styles = {
  style: {
    flex: 1
  },
  topSection: {
    height: 110,
    justifyContent: "center",
    alignItems: "center"
  },
  heading: {
    color: theme.color,
    fontSize: theme.FONT_SIZE_LARGE,
    margin: "auto",
    textAlign: "center",
    padding: 10
  },
  setupSelections: {
    marginTop: 10,
    marginBottom: 10
  },
  setupScrollView: {
    paddingLeft: 10,
    paddingRight: 10,
    width: "100%"
  },
  setupComponent: {
    alignSelf: "center",
    width: "100%",
    maxWidth: 600
  },
  form: {
    width: "100%"
  },
  field: {
    padding: 10
  },
  fieldLabel: {},
  link: {
    ...common.link
  },
  input: {
    marginTop: 5
  },
  picker: {},
  checkbox: {},
  button: {
    justifyContent: "center",
    alignSelf: "center",
    margin: 30
  },
  loading: common.loading,
  footerNoteContainer: {
    padding: 15
  },
  footerNote: {
    fontSize: theme.FONT_SIZE_SMALL,
    color: theme.color
  }
};
