import { capitalize } from "../../../../utils/helpers";
import UIText from "../../../../config/languages/en-CA";
import { AdminOobBackgroundCheckFiles } from "../../components/AdminOobBackgroundCheckFiles";

export const AdminVendorOnboardingForm = (
  topic,
  onboardingFields,
  topicId,
  profileId,
  displayName,
  showError
) => ({
  metadata: [
    {
      name: "updateScreeningStatus",
      placeholder: "Screening status",
      value: `${capitalize(topic["screeningStatus"])} (Current)`,
      type: "picker",
      readonly:
        topic["screeningStatus"] === "completed" ||
        topic["screeningStatus"] === "cancelled",
      options: [
        `${capitalize(topic["screeningStatus"])} (Current)`,

        (topic["screeningStatus"] === "interview booking reminded" ||
          topic["screeningStatus"] === "interview not booked") &&
          "Interview booked",

        topic["screeningStatus"] === "interview booked" &&
          "Interview call-back requested",

        topic["screeningStatus"] === "interview booked" &&
          "Candidate unsuitable",

        topic["screeningStatus"] === "interview booked" &&
          "Candidate disinterested",

        (topic["screeningStatus"] === "interview booked" ||
          topic["screeningStatus"] === "interview call-back requested") &&
          "Interview completed",

        (topic["screeningStatus"] === "information requested" ||
          topic["screeningStatus"] === "information not provided" ||
          topic["screeningStatus"] === "information request reminded") &&
          "Information provided",

        topic["screeningStatus"] === "information provided" &&
          "Candidate failed reference checks",

        "Cancelled"
      ]
        .filter(Boolean)
        .filter(s => s.toLowerCase() !== topic["screeningStatus"])
    },
    topic["screeningStatus"] === "completed" && {
      name: "resendProfileLink",
      type: "linkHeavy",
      readonly: true,
      placeholder: "Re-send profile completion link",
      onPress: "adminResendCandidateEmail",
      profileId,
      value: true
    },
    {
      name: "applicationDate",
      type: "input",
      readonly: true,
      placeholder: UIText.adminCentreCardFirstContactDate,
      value:
        topic && topic.applicationDate
          ? new Date(topic.applicationDate).toLocaleString()
          : null
    },
    {
      name: "interviewBookedDate",
      type: "input",
      readonly: true,
      placeholder: UIText.adminCentreCardInterviewBookedDate,
      value:
        topic && topic["interviewBookedDate"]
          ? new Date(topic["interviewBookedDate"]).toLocaleString()
          : null
    },
    (topic["screeningStatus"] === "new" ||
      topic["screeningStatus"] === "interview booking reminded" ||
      topic["screeningStatus"] === "interview not booked") && {
      name: "interviewBookingLink",
      type: "linkHeavy",
      readonly: true,
      placeholder: "Book interview",
      value: `/AdminInterviewBooking?calendlyUrl=${encodeURIComponent(
        topic.calendlyUrl
      )}&topicId=${topicId}&caregiverName=${displayName}`
    },
    {
      name: "interviewDate",
      type: "input",
      readonly: true,
      placeholder: UIText.adminCentreCardInterviewDate,
      value:
        topic && topic["interviewDate"]
          ? new Date(topic["interviewDate"]).toLocaleString()
          : UIText.adminCentreCardInterviewDateCheckCalendar
    },
    {
      name: "backgroundCheckFiles",
      type: "custom",
      readonly: true,
      placeholder: UIText.adminCentreCardBackgroundCheckedFiles,
      value: AdminOobBackgroundCheckFiles({
        onboardingTopic: topic,
        showError
      })
    },
    {
      name: "referencesReceivedDate",
      type: "input",
      readonly: true,
      placeholder: UIText.adminCentreCardReferenceReceivedDate,
      value:
        topic && topic["informationProvidedDate"]
          ? new Date(topic["informationProvidedDate"]).toLocaleString()
          : null
    },
    // {
    //   name: "referencesCheckedDate",
    //   type: "input",
    //   readonly: true,
    //   placeholder: UIText.adminCentreCardReferenceCheckedDate,
    //   value:
    //     topic && topic.referencesCheckedDate
    //       ? new Date(topic.referencesCheckedDate).toLocaleString()
    //       : null
    // },
    {
      name: "backgroundReceivedDate",
      type: "input",
      readonly: true,
      placeholder: UIText.adminCentreCardBackgroundCheckedDate,
      value:
        topic && topic["informationProvidedDate"]
          ? new Date(topic["informationProvidedDate"]).toLocaleString()
          : null
    },
    (topic["screeningStatus"] === "information requested" ||
      topic["screeningStatus"] === "information request reminded") && {
      name: "resendRequestLink",
      type: "linkHeavy",
      readonly: true,
      placeholder: "Re-send information request link",
      onPress: "adminResendCandidateEmail",
      profileId,
      value: true
    },
    {
      name: "profileCompletedDate",
      type: "input",
      readonly: true,
      placeholder: UIText.adminCentreCardProfileCompletionDate,
      value:
        topic && topic.profileCompletedDate
          ? new Date(topic.profileCompletedDate).toLocaleString()
          : null
    },
    {
      name: "approvedForMarketplaceDate",
      type: "input",
      readonly: true,
      placeholder: UIText.adminCentreCardApprovedForMarketplaceDate,
      value:
        topic && topic["completedDate"]
          ? new Date(topic["completedDate"]).toLocaleString()
          : null
    },
    {
      name: "references",
      type: "input",
      readonly: true,
      multiline: true,
      placeholder: "References information",
      value: topic.references || "—"
    },
    ...onboardingFields
  ].filter(Boolean)
});
