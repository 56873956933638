import React from "react";
import { AdminInterviewBookingScreen } from "./view";
import { AdminInterviewBookingController } from "./controller";
import { styles } from "./styles";

export class AdminInterviewBooking extends React.Component {
  static navigationOptions = {
    title: "Admin Interview Booking"
  };

  constructor(props) {
    super(props);
    this.controller = new AdminInterviewBookingController(props);
  }

  render() {
    return (
      <AdminInterviewBookingScreen
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
