import { stateController } from "../../../cdm/controllers/state-controller";
import { autorun, computed, observable, reaction, toJS } from "mobx";
import { msgService } from "../../../cdm/services/messaging-service";
import {
  asyncPause,
  capitalize,
  isEmpty,
  randomString
} from "../../../utils/helpers";
import { TopicServiceController } from "../../../cdm/controllers/topic-controller";
import { UIText } from "../../../config/lang-config";
import { Platform } from "react-native";
import {
  careCircleNegotiatedPriceBlurHandler,
  careCirclePayrollHide,
  isCcSubscriptionTopic,
  isHourlyRateProfile,
  isOwnerOrPrimaryCaregiverOrSupport,
  matchCareReceiver
} from "../../../custom/mcb/lib/group-utilities-mcb";
import { clientController } from "../../../cdm/controllers/client-controller";
import { fileService } from "../../../cdm/services/file-service";
import { groupTypeIds, topicTypeIds } from "../../../config/variable-config";
import { txService } from "../../../cdm/services/tx-service";
import { responsive } from "../../../config/style-configs/responsive";
import { checkGroupSubscription } from "../../../cdm/lib/group-utilities";

export class ChatController {
  disposers = [];

  @observable loaded = false;
  @observable loading = true;
  @observable lazyFetching = false;
  // @observable sendLoading = false;
  @observable errorMessage = "";
  @observable currentThreadId = 0;
  @observable titleUnread = 0;

  @observable topicController = {};

  // How long should message be read after scrolling stops
  readDelayThreshold = 500;

  // Back button memory
  previousScreen;

  // Messages length counter for de-focused new message monitoring
  lastMessagesCount = 0;

  // Read queue for when window is not active
  messageReadQueue = [];
  messageReadDebouncer;

  // Scroll controls
  @observable isEndReached = false;
  scrollToEnd = () => {};
  scrollToItem = () => {};
  scrollDebouncer;

  @computed get userId() {
    return this.topicController.userId;
  }
  @computed get topicId() {
    if (!stateController.viewTopicId)
      stateController.viewTopicId = Number(
        this.props.navigation.getParam("topic")
      );

    return stateController.viewTopicId;
  }
  @computed get topic() {
    return this.topicController.topic || {};
  }
  @computed get selfMember() {
    return this.topicController.selfMember || {};
  }
  @computed get topicSelfMember() {
    return this.topicController.topicSelfMember || {};
  }
  @computed get members() {
    return this.topic.members || [];
  }
  @computed get messages() {
    return this.topicController.messages || [];
  }
  @computed get readMessages() {
    return this.messages.filter(
      m => m.senderMemberId !== this.selfMember.id && m.isRead
    );
  }
  @computed get unreadMessages() {
    return this.messages.filter(
      m => m.senderMemberId !== this.selfMember.id && !m.isRead
    );
  }
  @computed get firstMessage() {
    return this.messages[0] || {};
  }
  @computed get lastMessage() {
    return this.messages[this.messages.length - 1] || {};
  }
  @computed get scrollToMessageId() {
    return Number(this.props.navigation.getParam("scrollTo"));
  }
  @computed get scrollToMessage() {
    return this.messages.find(m => m.id === this.scrollToMessageId);
  }
  @computed get shouldScrollToMessage() {
    return !isEmpty(this.scrollToMessage)
      ? this.scrollToMessage
      : isEmpty(this.unreadMessages)
      ? this.messages[this.messages.length - 1]
      : this.unreadMessages[0];
  }
  @computed get groups() {
    return this.topicController.groups || [];
  }
  @computed get currentGroup() {
    return this.topicController.currentGroup || {};
  }
  @computed get isMCBGroup() {
    return this.currentGroup.typeId === groupTypeIds.myCareBaseStaff;
  }
  @computed get displayTitle() {
    return this.topicController.displayTitle;
  }
  @computed get defaultThreadId() {
    return this.topic.defaultThreadId;
  }
  @computed get currentInputValue() {
    if (!clientController.client.chatState.inputValue) {
      clientController.client.chatState.inputValue = {};
    }
    return clientController.client.chatState.inputValue[this.topicId] || "";
  }
  @computed get inputMultiline() {
    return clientController.client.chatState.inputMultiline;
  }
  @computed get messageLoading() {
    return this.topicController.messageLoading;
  }
  @computed get windowFocused() {
    return msgService.windowFocused;
  }

  constructor(props) {
    this.props = props;
    setTimeout(this._initialize);
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.topicController.cancel();
    this.cancelled = true;
    this.disposers && this.disposers.map(disposer => disposer());
    msgService.clearProactiveChatAgentSseQueue();
    clearTimeout(this.emptyTimeout);
    txService.removeSSEEventListener("proactiveChatVisitor");
    responsive.removeIOSSafariKeyboardFix();
  }

  _showError = (err, kickout) => {
    console.warn(err);
    return stateController.showPopup({
      title: capitalize(UIText.chat),
      content: (err && err.message) || err,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: () => {
        kickout && this.props.navigation.navigate("Group");
        return stateController.dismissPopup();
      }
    });
  };

  _initialize = () => {
    this.previousScreen = toJS(stateController.currentScreen);
    stateController.currentScreen = "Chat";

    responsive.applyIOSSafariKeyboardFix();

    this.topicController = new TopicServiceController(this.topicId);
    return this.topicController
      .initialize()
      .then(() =>
        this.topicController.loadTopicMessages(this.scrollToMessageId)
      )
      .then(() => !this.cancelled && this._checkGroupSubscription())
      .then(() => !this.cancelled && this._topicExistenceMonitor())
      .then(() => !this.cancelled && this._topicChangeMonitor())
      .then(() => !this.cancelled && this._messagesChangeMonitor())
      .then(() => !this.cancelled && this._messageArrivalInactiveMonitor())
      .then(() => !this.cancelled && this._windowFocusMonitor())
      .catch(e => this._showError(e, true))
      .finally(() => {
        if (this.cancelled) return;
        this.loaded = true;
        this.loading = false;

        if (!stateController.viewGroupId) {
          stateController.viewGroupId = this.currentGroup.id;
        }

        if (
          this.isMCBGroup &&
          this.topic.typeId === topicTypeIds.proactiveChat
        ) {
          // this.waitProactiveChatVisitor();
          return this.sendProactiveChatSseAgent();
        }

        // Double check browser notification permission.
        responsive.isDesktopOS && // Only for desktop browsers
          window.Notification &&
          window.Notification.requestPermission();
      });
  };

  _checkGroupSubscription = async () => {
    if (isCcSubscriptionTopic(this.currentGroup, this.topic)) {
      if (!this.currentGroup.subscription) {
        await checkGroupSubscription(this.currentGroup.id).catch(err =>
          Promise.reject(err)
        );
      }
      if (!this.topicController.isSubscriptionMsgEnabled) {
        return stateController.showPopup({
          title: capitalize(UIText.marketplace),
          content: {
            text: UIText.marketSubNoChat
            // text: `${UIText.marketSubNoChat} ${UIText.marketPlanInfo}`,
            // link: planMarketingUrl
          },
          contentAlign: "left",
          leftButtonText: UIText.generalCancel,
          leftButtonPress: () =>
            stateController.dismissPopup().then(this.handleBackPress),
          rightButtonText: UIText.planSelectAPlan,
          rightButtonPress: () =>
            stateController
              .dismissPopup()
              .then(this.topicController.handleBillingPress)
        });
      }
    }
  };

  _topicExistenceMonitor = () =>
    this.disposers.push(
      autorun(reaction => {
        if (isEmpty(this.topic) && this.loaded) {
          this.emptyTimeout = setTimeout(() => {
            if (
              isEmpty(this.topic) &&
              clientController.loginState &&
              stateController.currentScreen === "Chat"
            ) {
              this._showError(UIText.chatNotExist, true);
              return reaction.dispose();
            }
          }, 1000);
        }
      })
    );

  _topicChangeMonitor = () =>
    this.disposers.push(
      reaction(
        () => this.topicId,
        (value, reaction) => {
          if (
            !!Number(value) &&
            clientController.loginState &&
            stateController.currentScreen === "Chat"
          )
            return this._initialize();
        }
      )
    );

  _messagesChangeMonitor = () =>
    this.disposers.push(
      reaction(
        () => this.messages,
        (value, reaction) => {
          if (!this.windowFocused) {
            this.titleUnread = this.messages.length - this.lastMessagesCount;
          } else {
            this.lastMessagesCount = this.messages.length;
          }
        }
      )
    );

  _messageArrivalInactiveMonitor = () =>
    this.disposers.push(
      reaction(
        () => this.titleUnread,
        (value, reaction) => {
          if (Platform.OS !== "web") return;
          clearInterval(this.faviconFlashTimer);
          clearTimeout(this.faviconFlashTimer1);
          const faviconElm = document.getElementById("favicon");
          if (this.titleUnread === 0) {
            faviconElm.href = "/favicon.png";
            return (document.title = UIText.title);
          }
          document.title = `(${this.titleUnread}) ${UIText.title}`;
          const flashInterval = 500;
          this.faviconFlashTimer = setInterval(() => {
            faviconElm.href = require("../../../assets/favicon-notify.png");
            this.faviconFlashTimer1 = setTimeout(
              () => (faviconElm.href = "/favicon.png"),
              flashInterval
            );
          }, flashInterval * 2);
        }
      )
    );

  _windowFocusMonitor = () =>
    this.disposers.push(
      reaction(
        () => this.windowFocused,
        (value, reaction) => {
          if (!this.windowFocused) return;
          this.titleUnread = 0;
          this.lastMessagesCount = this.messages.length;
          if (this.messageReadQueue.length > 0) {
            return msgService
              .readMessages(
                this.messageReadQueue.map(id =>
                  this.messages.find(m => m.id === id)
                ),
                this.topicSelfMember.id,
                this.topicId
              )
              .then(() => (this.messageReadQueue = []));
          }
        }
      )
    );

  getMessageSenderProfile = sender => {
    if (isEmpty(sender)) {
      return {
        firstName: "(Deleted",
        lastName: "member)"
      };
    }
    if (
      this.topic.typeId === topicTypeIds.mCBBroadcasts &&
      !this.isMCBGroup &&
      sender.userId !== this.userId
    ) {
      const mCBGroup = this.groups.find(g => g.id !== this.currentGroup.id);
      return (mCBGroup && mCBGroup.profile && mCBGroup.profile.data) || {};
    }

    return (sender.profile && sender.profile.data) || {};
  };

  getMessageSenderAvatar = (sender, profile) => {
    if (isEmpty(sender)) return null;
    const senderId =
      this.topic.typeId === topicTypeIds.mCBBroadcasts &&
      !this.isMCBGroup &&
      sender.userId !== this.userId
        ? 0
        : sender.id;

    return fileService.getProfileAvatarUri(profile.avatar, senderId, "member");
  };

  // waitProactiveChatVisitor = () =>
  //   txService.addSSEEventListener("proactiveChatVisitor", event => {
  //     if (stateController.currentScreen !== "Chat") return;
  //     if (!event || event.type !== "pc") return;
  //     const { message, topicId } = event;
  //     if (topicId !== this.topicId) return;
  //     if (!message) return;
  //     const command = message.split(":")[0];
  //     const content = message.split(":")[1];
  //     if (command === "visitor") {
  //       return this.sendProactiveChatVisitorWelcome(content);
  //     }
  //     if (command === "visitorHeartbeat") {
  //       const messages = this.messages.filter(
  //         m => !isEmpty(m) && !msgService.processSpecialMessage(toJS(m)).system
  //       );
  //       if (isEmpty(messages))
  //         return this.sendProactiveChatVisitorWelcome(content);
  //     }
  //   });
  //
  // sendProactiveChatVisitorWelcome = visitorName =>
  //   setTimeout(() => {
  //     if (this.messages.filter(m => !m.system).length > 0) return;
  //     return this.sendMessage(null, `Hello ${visitorName}!`);
  //   }, 500);

  sendProactiveChatSseAgent = () => {
    if (stateController.currentScreen !== "Chat") return;
    msgService.clearProactiveChatAgentSseQueue();
    return msgService.addProactiveChatAgentSseQueue(this.topicId);
  };

  handleBackPress = event => {
    msgService.clearProactiveChatAgentSseQueue();
    setTimeout(() => {
      if (Platform.OS === "web") {
        if (this.previousScreen === "Topic") {
          const { lastViewTopicId } = stateController;
          if (
            this.topic.typeId === topicTypeIds.groupChatLobby &&
            lastViewTopicId
          ) {
            if (lastViewTopicId) stateController.viewTopicId = lastViewTopicId;
            return this.props.navigation.navigate("Topic", {
              topic: lastViewTopicId
            });
          } else {
            const id = this.topicId;
            stateController.viewTopicId = id;
            return this.props.navigation.navigate("Topic", { topic: id });
          }
        }
        // if (this.previousScreen === "Profile") {
        //   return this.props.navigation.navigate("Profile");
        // }

        if (this.previousScreen === "Inbox") {
          return this.props.navigation.navigate("Inbox");
        }

        return this.props.navigation.navigate("Group", {
          group: stateController.viewGroupId
        });
      } else {
        this.props.navigation.pop();
      }
    });
  };

  handleMenuPress = event => {
    stateController.showPopup({
      title: capitalize(UIText.chat),
      content: capitalize(UIText.comingSoon),
      leftButtonText: UIText.generalConfirm
    });
  };

  handleAvatarPress = (event, member) => {
    const profileId = member.profileId;
    const screenId = randomString();
    stateController.viewProfileState.backButtonOverride[screenId] = {
      handlePress: () =>
        this.props.navigation.navigate("Chat", { topic: this.topicId })
    };

    const roles = Array.isArray(member.roleList) && member.roleList;
    const canEdit =
      member.userId === clientController.userId ||
      (isOwnerOrPrimaryCaregiverOrSupport(this.currentGroup, this.selfMember) &&
        roles.some(r => matchCareReceiver(r["description"] || "")));

    stateController.viewProfileState.readOnly[screenId] = !canEdit;

    stateController.viewProfileState.additionalProfileProcessors[screenId] = [
      profile =>
        careCirclePayrollHide(
          this.currentGroup,
          this.selfMember,
          member,
          member.profileId,
          profile
        )
    ];

    if (isHourlyRateProfile(member.profile) && canEdit) {
      stateController.viewProfileState.fieldBlurHandlers[screenId] = [
        careCircleNegotiatedPriceBlurHandler
      ];
    }

    profileId &&
      this.props.navigation[Platform.OS === "web" ? "navigate" : "push"](
        "Profile",
        { group: this.currentGroup.id, profile: profileId, screenId }
      );
  };

  handleEmojiPress = () => {
    if (Platform.OS === "web") {
      const os = window.navigator.userAgent.match(/Windows NT 10/g)
        ? "win10"
        : window.navigator.userAgent.match(/Mac OS X 10_/g)
        ? "osx"
        : "";

      return stateController.showPopup({
        title: capitalize(UIText.chat),
        content:
          os === "osx"
            ? UIText.chatEmojiOSX
            : os === "win10"
            ? UIText.chatEmojiWin10
            : UIText.chatEmojiNotSupported,
        leftButtonText: UIText.generalConfirm,
        dismissOnBackPress: true
      });
    }
  };

  handleAttachmentPress = () => {
    stateController.showPopup({
      title: capitalize(UIText.attachment),
      content: capitalize(UIText.comingSoon),
      leftButtonText: UIText.generalConfirm
    });
  };

  handleCameraPress = () => {
    stateController.showPopup({
      title: capitalize(UIText.attachment),
      content: capitalize(UIText.comingSoon),
      leftButtonText: UIText.generalConfirm
    });
  };

  handleMultilineChange = focus => {
    clientController.client.chatState.inputMultiline = !this.inputMultiline;
    typeof focus === "function" && setTimeout(focus);
  };

  handleInputChange = event => {
    if (!clientController.client.chatState.inputValue) {
      clientController.client.chatState.inputValue = {};
    }
    clientController.client.chatState.inputValue[this.topicId] =
      event.nativeEvent.text;
  };

  sendMessage = async (focus, text) => {
    const clearInput = () => {
      clientController.client.chatState.inputValue[this.topicId] = "";
    };

    text = text || toJS(this.currentInputValue).trim();
    if (!this.topic["defaultThreadId"] && !this.currentThreadId) return;
    if (!text) {
      return typeof focus === "function" && setTimeout(focus);
    }

    clearInput();
    typeof focus === "function" && setTimeout(focus);

    const data = {
      topicId: this.topicId,
      threadId: this.currentThreadId || this.topic["defaultThreadId"],
      senderMemberId: this.topicSelfMember.id,
      text,
      textIsMd: 0,
      watermark: randomString()
    };

    const scrollToEnd = data => {
      setTimeout(() => this.scrollToEnd({ animated: false }));
      return data;
    };

    setTimeout(() => {
      msgService
        .addSendingMessage(data)
        .then(scrollToEnd)
        .then(msgService.sendMessage)
        .then(this.loadNewestMessages)
        .then(scrollToEnd)
        .catch(err => {
          this._showError(err);
          return msgService.removeMessage(data);
        });
    });
  };

  loadNewestMessages = () =>
    this.topicController.loadTopicMessages(this.lastMessage.id);

  loadOlderMessages = () =>
    this.topicController.loadTopicMessages(this.firstMessage.id);

  onMessageRead = event => {
    const messages = event.viewableItems.map(i => i.item);
    this.messageReadQueue = Array.from(
      new Set([...this.messageReadQueue, ...messages.map(m => m.id)])
    );
    if (!this.windowFocused) return;
    clearTimeout(this.messageReadDebouncer);
    return (this.messageReadDebouncer = setTimeout(() => {
      msgService
        .readMessages(
          this.messageReadQueue.map(id => this.messages.find(m => m.id === id)),
          this.topicSelfMember.id,
          this.topicId
        )
        .finally(() => (this.messageReadQueue = []));
    }, 1000));
  };

  onRefresh = () => {
    this.loading = true;
    return this.topicController
      .loadTopicMessages()
      .finally(() => (this.loading = false));
  };

  // onWebWindowFocus = () => {
  //   this.windowFocused = true;
  //   // if (this.titleUnread > 0) alert(UIText.chatNewMessageAlert);
  //   this.titleUnread = 0;
  //   this.lastMessagesCount = this.messages.length;
  //   if (this.messageReadQueue.length > 0) {
  //     return msgService
  //       .readMessages(
  //         this.messageReadQueue.map(id => this.messages.find(m => m.id === id)),
  //         this.topicSelfMember.id
  //       )
  //       .then(() => (this.messageReadQueue = []));
  //   }
  // };
  //
  // onWebWindowBlur = () => {
  //   this.windowFocused = false;
  // };

  processSpecialMessage = msg => msgService.processSpecialMessage(msg);

  setScrollToEnd = method => (this.scrollToEnd = method);

  setScrollToItem = method => (this.scrollToItem = method);

  onScroll = event => {
    clearTimeout(this.scrollDebouncer);
    this.scrollDebouncer = setTimeout(async () => {
      {
        const scrollViewHeight = event.nativeEvent.layoutMeasurement.height;
        const scrollContentSize = event.nativeEvent.contentSize.height;
        const scrollOffset = event.nativeEvent.contentOffset.y;
        this.isAtTheTop = scrollOffset === 0;
        this.isEndReached =
          scrollViewHeight + scrollOffset >= scrollContentSize;
        if (this.isAtTheTop || this.isEndReached) {
          this.lazyFetching = true;
          if (this.isAtTheTop) {
            const firstMessageId = this.firstMessage.id;
            await this.loadOlderMessages();
            this.scrollToItem({
              animated: false,
              item: this.messages.find(m => m.id === firstMessageId)
            });
          } else if (this.isEndReached) {
            await this.loadNewestMessages();
          }
          await asyncPause(150);
          this.lazyFetching = false;
        }
      }
    }, 200);
  };
}
