import { observable, toJS } from "mobx";
import { theme } from "../../config/style-configs/theme";
import { env } from "../../config/variable-config";
import { asyncPause, isModifiedSync } from "../../utils/helpers";

class StateController {
  currentScreen; // Marker for navigation

  // Controls drawer open/close on web.
  @observable showDrawer = false;

  // Controls the rendering of GroupScreen.
  @observable viewGroupId = 0;
  @observable viewGroupState = {
    topTabs: {
      main: {}, // groupId as key.
      shift: {}, // groupId as key.
      market: {} // groupId as key.
    },
    bottomTabs: {}, // groupId as key.
    previousBottomTabs: {}, // groupId as key.
    collapsible: {
      summary: {}, // groupId as key.
      allShifts: {}, // groupId as key.
      allIssues: {}, // groupId as key.
      member: {}, // groupId as key.
      report: {} // groupId as key.
    },

    shiftTemplateId: {}, // groupId as key.
    shiftScrollPos: {}, // groupId as key.
    shiftListMode: {}, // groupId as key.
    shiftCalendarMode: {}, // groupId as key.
    shiftCalendarCurrent: {}, // groupId as key.

    marketShortlistId: {}, // groupId as key.
    marketCriteria: {}, // groupId as key.
    marketCriteriaDirty: {}, // groupId as key.
    marketCriteriaSimplified: {}, // groupId as key.
    marketScrollPos: {}, // groupId as key.
    marketHideMandatoryToggles: {}, // groupId as key.
    marketMandatoryCriteria: {}, // groupId as key.
    marketSearchMandatory: {}, // groupId as key.
    marketAdminBroadcastMsg: {}, // Admin groupIds should be the only keys.

    reportState: {}, // groupId as key

    subscriptionChecked: {}, // groupId as key.

    searchBar: {}, // groupId as key.

    adminMessageListSortBy: {}, // groupId as key.

    // scratchpadId: 0,

    autoPageParamUsed: false
  };

  // Controls the rendering of TopicScreen.
  @observable viewTopicId = 0;
  @observable lastViewTopicId = 0;
  @observable viewTopicState = {
    topTabs: {}, // topicId as key.
    customTabs: [], // Push custom tab view with props.
    additionalFormProcessors: [],
    parent: 0
  };
  clearTopicState = () => {
    this.viewTopicState.additionalFormProcessors = [];
  };

  // Controls the rendering of ChatScreen.
  // @observable viewChatState = {
  //   inputValue: {}, // topicId as key.
  //   inputMultiline: {} // topicId as key.
  // };

  // Controls the rendering of ProfileScreen.
  @observable viewProfileState = {
    refresh: () => {},
    getProfile: () => {},
    getProfileCopy: () => {},
    setField: (fieldName, value) => {},
    screenStates: {},
    autoEdit: false,
    additionalForm: {},
    titleOverride: {},
    backButtonOverride: {}, // screenId as key
    rightButtonOverride: {}, // screenId as key
    topButton: {}, // screenId as key
    bottomButton: {}, // screenId as key
    readOnly: {}, // screenId as key
    nonEditModeHideNoValue: {}, // screenId as key
    renderFlags: {}, // screenId as key
    editFlags: {}, // screenId as key
    fieldToScrollAt: {}, // screenId as key
    allowEmailEdit: {}, // screenId as key
    additionalProfileProcessors: {}, // screenId as key
    additionalProfileValidators: {}, // screenId as key
    profilePostSaveProcessors: {}, // screenId as key
    fieldBlurHandlers: {}, // screenId as key
    availabilityFormDisabled: {} // screenId as key
  };
  clearProfileState = screenId => {
    if (!screenId) return;
    this.viewProfileState.screenStates = {};
    this.viewProfileState.titleOverride[screenId] = null;
    this.viewProfileState.backButtonOverride[screenId] = null;
    this.viewProfileState.rightButtonOverride[screenId] = null;
    this.viewProfileState.topButton[screenId] = null;
    this.viewProfileState.bottomButton[screenId] = null;
    this.viewProfileState.readOnly[screenId] = false;
    this.viewProfileState.nonEditModeHideNoValue[screenId] = false;
    this.viewProfileState.renderFlags[screenId] = null;
    this.viewProfileState.editFlags[screenId] = null;
    this.viewProfileState.fieldToScrollAt[screenId] = null;
    this.viewProfileState.allowEmailEdit[screenId] = null;
    this.viewProfileState.additionalProfileProcessors[screenId] = null;
    this.viewProfileState.additionalProfileValidators[screenId] = null;
    this.viewProfileState.profilePostSaveProcessors[screenId] = null;
    this.viewProfileState.fieldBlurHandlers[screenId] = null;
  };

  // Controls the rendering of PlanScreen.
  @observable viewPlanState = {
    setupMode: false,
    currentPage: "plan",
    plans: [],
    ccToken: {},
    billingFormData: {},
    residentialProvince: "",
    postalCode: "",
    promoCode: "",
    promoData: {}
  };
  clearPlanState = () => {
    this.viewPlanState.setupMode = false;
    this.viewPlanState.currentPage = "plan";
    this.viewPlanState.ccToken = {};
    this.viewPlanState.billingFormData = {};
    this.viewPlanState.residentialProvince = "";
    this.viewPlanState.postalCode = "";
    this.viewPlanState.promoCode = "";
    this.viewPlanState.promoData = {};
  };

  // Controls reset password screen behaviors and password reset data.
  @observable viewResetState = {
    terms: false,
    reset: false,
    resetRequest: false,
    resetToken: "",
    resetUser: "",
    continueUrl: ""
  };
  clearResetState = () =>
    (this.viewResetState = {
      terms: false,
      reset: false,
      resetRequest: false,
      resetToken: "",
      resetUser: ""
    });

  // Invitation data for use during pre-login InvitationRegister screen.
  @observable viewInvitationRegState = {
    id: "",
    hash: "",
    email: "",
    data: {}, // invitation instance object
    loginToken: "",
    loginUser: ""
  };
  clearInvitationRegState = () =>
    (this.viewInvitationRegState = {
      id: "",
      hash: "",
      email: "",
      data: {},
      loginToken: "",
      loginUser: ""
    });

  // Caregiver onboard information request form data.
  @observable viewCaregiverPostInterviewState = {
    submissionUser: "",
    submissionToken: ""
  };
  clearCaregiverPostInterviewState = () =>
    (this.viewInvitationRegState = {
      submissionUser: "",
      submissionToken: ""
    });

  @observable viewCaregiverJobReferenceState = {
    id: null,
    onboardingId: null,
    emailQuestionResponseId: ""
  };
  clearCaregiverJobReferenceState = () =>
    (this.viewCaregiverJobReferenceState = {
      id: null,
      onboardingId: null,
      emailQuestionResponseId: ""
    });

  @observable viewCaregiverUploadState = {
    token: null,
    onboardingId: null
  };
  clearCaregiverUploadState = () =>
    (this.viewCaregiverUploadState = {
      token: null,
      onboardingId: null
    });

  // Rating review form data.
  @observable viewRatingReviewState = {
    submissionToken: "",
    userHashId: 0,
    rating: 0
  };
  clearRatingReviewState = () =>
    (this.viewRatingReviewState = {
      submissionToken: "",
      userHashId: 0,
      rating: 0
    });

  // Controls the rendering of InboxScreen.
  @observable viewInboxState = {
    currentBottomTab: "unread",
    collapsedAllGroups: {},
    collapsedUnreadGroups: {}
  };

  // Controls the rendering of AdminCentreScreen.
  @observable viewAdminCentreState = {
    cards: {},
    sortBy: {},
    hideAvatar: {},
    timeSheetMode: false,
    timeSheetGroupId: 0,
    timeSheetGroup: {},
    timeSheetStore: {},

    ratingReviewMode: false,
    ratingReviewGroupId: 0,
    ratingReviewViewByCareCircle: false,

    bottomTab: 0,

    caregiverTabKey: "",

    payrollGroups: [],
    payrollStore: {},
    collapsible: {},

    territories: {
      selectedProvince: "",
      selectedTerritory: ""
    },

    providerGroupTypeId: 0,
    providerTabKey: "",

    pagingStatus: {},
    scrollPos: {} // groupTypeId is key
  };

  // Controls setup screen variants.
  @observable initSetupMode;

  // Stores base64 encoded form for use during registration or login.
  base64form = {};

  // Stores account verification hash.
  verificationHash = "";
  // Controls execution of account verification, prevents render state change re-verifying.
  verificationLock = false;

  // Controls visitor auto login mode lock;
  visitorLock = false;

  // Controls whether to auto execute login upon arriving LoginScreen.
  autoSignIn = false;

  // Controls onboarding states.
  @observable onboardingSignIn = false;
  @observable onboardingProfileClicked = false;

  // Controls app global popup overlay state visibility and content.
  @observable popup = {
    isVisible: false,

    image: "",
    cropper: null,

    title: "",
    leftButtonText: "",
    leftButtonPress: null,
    leftButtonDisabled: false,
    rightButtonText: "",
    rightButtonPress: null,
    rightButtonDisabled: false,
    buttonSet: null,
    content: "",
    contentHtml: "",
    contentAlign: null,

    input: null,
    inputSet: null,
    inputValue: {},

    picker: null,
    pickerSet: null,

    datePicker: null,
    datePickerSet: null,

    multiSelect: null,

    form: null,
    formStyles: {},
    formShowTitle: false,
    formShowLabel: true,

    onBackdropPress: null,
    dismissOnBackPress: null,
    backdropColor: null,

    locked: false
  };

  showPopup = async options => {
    if (this.popup.locked) return;

    let inputValue = {};
    if (options.input) {
      inputValue[options.input.title] =
        options.input.value || options.input.defaultValue;
    }
    if (options.inputSet) {
      for (let input of options.inputSet) {
        inputValue[input.title] = input.value || input.defaultValue;
      }
    }

    return (this.popup = {
      isVisible: true,

      image: options.image,
      cropper: options.cropper,

      title: options.title,
      leftButtonText: options.leftButtonText,
      leftButtonPress:
        options.leftButtonPress ||
        (options.leftButtonText && this.dismissPopup),
      leftButtonDisabled: options.leftButtonDisabled,
      rightButtonText: options.rightButtonText,
      rightButtonPress:
        options.rightButtonPress ||
        (options.rightButtonText && this.dismissPopup),
      rightButtonDisabled: options.rightButtonDisabled,
      buttonSet: options.buttonSet,
      content: options.content,
      contentHtml: options.contentHtml,
      contentAlign: options.contentAlign,

      input: options.input,
      inputSet: options.inputSet,
      inputValue,
      onInputChange: this.popup.onInputChange,

      picker: options.picker,
      pickerSet: options.pickerSet,

      datePicker: options.datePicker,
      datePickerSet: options.datePickerSet,

      multiSelect: options.multiSelect,

      form: options.form,
      formStyles: options.formStyles || {},
      formShowLabel: options.formShowLabel,
      formShowTitle: options.formShowTitle,

      onBackdropPress:
        options.onBackdropPress ||
        (options.dismissOnBackPress && this.dismissPopup),
      dismissOnBackPress: options.dismissOnBackPress,
      backdropColor: options.backdropColor,

      locked: !!options.locked
    });
  };

  // Use these to make popup resistant to subsequent popups until further unlock action.
  lockPopup = () => (this.popup.locked = true);

  unlockPopup = () => (this.popup.locked = false);

  getPopupLock = () => !!this.popup.locked;

  // Reset popup state.
  dismissPopup = async () => {
    this.popup.isVisible = false;
    this.popup.inputValue = {};
    this.popup.leftButtonDisabled = false;
    this.popup.rightButtonDisabled = false;
    this.unlockPopup();
    await asyncPause(theme.popupFadeDuration + 10);
    this.popup.form = undefined;
    this.popup.formStyles = {};
    this.popup.formShowTitle = false;
    this.popup.formShowLabel = true;
    return Promise.resolve();
  };

  // onPopupBackButton = () => {
  //   if (this.popup.isVisible && this.popup.dismissOnBackPress)
  //     this.dismissPopup();
  //
  //   return true;
  // };

  isProfileScreenModified = () => {
    const profileScreenProfile = this.viewProfileState.getProfile();
    const profileScreenCopy = this.viewProfileState.getProfileCopy();
    return isModifiedSync(
      toJS(profileScreenProfile),
      profileScreenCopy,
      "value"
    );
  };

  // Reinitialize StateController (Logout, re-initialize client)
  reset = exclPopup => {
    const dummy = new StateController();
    for (let props in this) {
      if (this.hasOwnProperty(props)) {
        if (exclPopup && props === "popup") continue;

        // Skip these as they are session wide permanent.
        if (props === "visitorLock") continue;
        if (props === "verificationLock") continue;
        if (props === "onboardingSignIn") continue;

        const prop = toJS(dummy)[props];
        typeof prop !== "function" && (this[props] = prop);
      }
    }
  };
}

const stateController = new StateController();

// For development;
if (window && env !== "prod") window.stateController = stateController;

export { stateController };
