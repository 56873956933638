import {
  groupTypeActorIds,
  groupTypeIds,
  groupTypeRoleIds,
  topicTypeIds,
  typeClassIds
} from "../../../config/variable-config";
import { getTopicCompletionState } from "../../../cdm/lib/topic-utilities";
import { isEmpty } from "../../../utils/helpers";
import { isSnSOrAdmin } from "../utils/helper";
import { getServiceProviderGroupProfileFlags } from "../lib/group-utilities-mcb";

const profileRenderFlagValues = {
  // [typeClassId]: {
  //   [viewer's role groupTypeRoleId]: [flags of fields that the viewer should see]
  // },
  [typeClassIds.caregiverProfile]: {
    [groupTypeRoleIds.caregiver]: ["caregiver"],
    ...getServiceProviderGroupProfileFlags(),
    [groupTypeRoleIds.primaryFamilyCaregiver]: ["marketplace"],
    [groupTypeRoleIds.secondaryFamilyCaregiver]: ["marketplace"],
    [groupTypeRoleIds.healthProvider]: ["marketplace"],
    [groupTypeRoleIds.paidCaregiver]: ["marketplace"],
    [groupTypeRoleIds.friend]: ["marketplace"],
    [groupTypeRoleIds.family]: ["marketplace"],
    [groupTypeRoleIds.primaryMember]: ["marketplace"],
    [groupTypeRoleIds.otherMember]: ["marketplace"],
    [groupTypeRoleIds.mCBStaff]: ["marketplace"],
    [groupTypeRoleIds.careConcierge]: ["marketplace"],
    [groupTypeRoleIds.support]: ["marketplace"]
  },
  [typeClassIds.careReceiverProfile]: {
    [groupTypeRoleIds.caregiver]: ["search"],
    [groupTypeRoleIds.primaryFamilyCaregiver]: ["general", "search"],
    [groupTypeRoleIds.secondaryFamilyCaregiver]: ["general", "search"],
    [groupTypeRoleIds.healthProvider]: ["general", "search"],
    [groupTypeRoleIds.paidCaregiver]: ["general", "search"],
    [groupTypeRoleIds.friend]: ["general", "search"],
    [groupTypeRoleIds.family]: ["general", "search"],
    [groupTypeRoleIds.primaryMember]: ["general", "search"],
    [groupTypeRoleIds.otherMember]: ["general", "search"],
    [groupTypeRoleIds.mCBStaff]: ["general", "search"],
    [groupTypeRoleIds.careConcierge]: ["general", "search"],
    [groupTypeRoleIds.support]: ["general", "search"]
  },
  [typeClassIds.familyMemberProfile]: {
    [groupTypeRoleIds.caregiver]: [true],
    [groupTypeRoleIds.primaryFamilyCaregiver]: [true],
    [groupTypeRoleIds.secondaryFamilyCaregiver]: [true],
    [groupTypeRoleIds.healthProvider]: [true],
    [groupTypeRoleIds.paidCaregiver]: [true],
    [groupTypeRoleIds.friend]: [true],
    [groupTypeRoleIds.family]: [true],
    [groupTypeRoleIds.primaryMember]: [true],
    [groupTypeRoleIds.otherMember]: [true],
    [groupTypeRoleIds.mCBStaff]: [true],
    [groupTypeRoleIds.careConcierge]: [true],
    [groupTypeRoleIds.support]: [true]
  },
  [typeClassIds.paidCaregiverProfile]: {
    [groupTypeRoleIds.caregiver]: ["caregiver"],
    [groupTypeRoleIds.primaryFamilyCaregiver]: ["caregiver", "careCircle"],
    [groupTypeRoleIds.secondaryFamilyCaregiver]: ["caregiver", "careCircle"],
    [groupTypeRoleIds.healthProvider]: ["caregiver", "careCircle"],
    [groupTypeRoleIds.paidCaregiver]: ["caregiver"],
    [groupTypeRoleIds.friend]: ["caregiver", "careCircle"],
    [groupTypeRoleIds.family]: ["caregiver", "careCircle"],
    [groupTypeRoleIds.primaryMember]: ["caregiver", "careCircle"],
    [groupTypeRoleIds.otherMember]: ["caregiver", "careCircle"],
    [groupTypeRoleIds.mCBStaff]: ["caregiver", "careCircle"],
    [groupTypeRoleIds.careConcierge]: ["caregiver", "careCircle"],
    [groupTypeRoleIds.support]: ["caregiver", "careCircle"]
  }
};

export const getProfileRenderFlags = (typeClassId, typeRoleId) => {
  if (typeClassId === typeClassIds.personalProfile) return [true];
  if (!typeClassId || !typeRoleId) return [];
  return (
    (profileRenderFlagValues[typeClassId] &&
      profileRenderFlagValues[typeClassId][typeRoleId]) ||
    []
  );
};

const topicSummaryRenderFlagValues = {
  [topicTypeIds.shift]: {
    execution: {
      default: ["shift", "general"]
    }
  },
  [topicTypeIds.task]: {
    execution: {
      task: ["task", "general"],
      todo: ["todo", "general"]
    }
  },
  [topicTypeIds.issue]: {
    execution: {
      default: ["issue", "general"]
    }
  }
};

export const getTopicSummaryRenderFlags = topic => {
  if (isEmpty(topic)) return [];
  const topicTypeId = topic.typeId;
  const editType = topic.isTemplate ? "setup" : "execution";
  const subType =
    topic.typeId === topicTypeIds.task
      ? topic.parentId
        ? "task"
        : "todo"
      : "default";

  return (
    (topicSummaryRenderFlagValues[topicTypeId] &&
      topicSummaryRenderFlagValues[topicTypeId][editType] &&
      topicSummaryRenderFlagValues[topicTypeId][editType][subType]) ||
    []
  );
};

const topicSummaryEditFlagValues = {
  [topicTypeIds.shift]: {
    setup: {
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        [groupTypeRoleIds.family]: {
          default: {
            new: ["description", "assignee", "startEnd", "delete"]
          }
        },
        [groupTypeRoleIds.paidCaregiver]: {
          default: {
            new: []
          }
        }
      }
    },
    execution: {
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        [groupTypeRoleIds.family]: {
          default: {
            new: ["description"],
            "clocked-in": [],
            "clocked-out": ["correctedEndTime"]
          }
        },
        [groupTypeRoleIds.paidCaregiver]: {
          default: {
            new: [],
            "clocked-in": [],
            "clocked-out": ["correctedEndTime"]
          }
        }
      }
    }
  },
  [topicTypeIds.task]: {
    setup: {
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        [groupTypeRoleIds.family]: {
          task: {
            new: ["description", "delete"]
          }
        },
        [groupTypeRoleIds.paidCaregiver]: {
          default: {
            new: []
          }
        }
      }
    },
    execution: {
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        [groupTypeRoleIds.family]: {
          task: {
            new: [],
            "clocked-in": [],
            "clocked-out": []
          },
          todo: {
            new: ["description", "assignee", "startEnd", "delete"],
            "clocked-in": ["description", "startEnd"],
            "clocked-out": ["description"]
          }
        },
        [groupTypeRoleIds.paidCaregiver]: {
          task: {
            new: [],
            "clocked-in": [],
            "clocked-out": []
          },
          todo: {
            new: [],
            "clocked-in": [],
            "clocked-out": []
          }
        }
      }
    }
  },
  [topicTypeIds.shortlist]: {
    execution: {
      [groupTypeActorIds[groupTypeIds.careReceiver].owner]: {
        [groupTypeRoleIds.family]: {
          default: {
            new: ["description", "delete"]
          }
        }
      },
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        [groupTypeRoleIds.family]: {
          default: {
            new: ["description", "delete"]
          }
        },
        [groupTypeRoleIds.paidCaregiver]: {
          default: {
            new: []
          }
        }
      },
      [groupTypeActorIds[groupTypeIds.myCareBaseStaff].owner]: {
        [groupTypeRoleIds.mCBStaff]: {
          default: {
            new: ["description", "delete"]
          }
        }
      },
      [groupTypeActorIds[groupTypeIds.myCareBaseStaff].participants]: {
        [groupTypeRoleIds.mCBStaff]: {
          default: {
            new: ["description", "delete"]
          }
        }
      }
    }
  },
  [topicTypeIds.issue]: {
    execution: {
      [groupTypeActorIds[groupTypeIds.careReceiver].owner]: {
        [groupTypeRoleIds.family]: {
          default: {
            new: ["description", "assignee", "startEnd", "delete"],
            "clocked-in": ["startEnd", "delete"]
          }
        },
        [groupTypeRoleIds.paidCaregiver]: {
          default: {
            new: ["description", "startEnd", "delete"],
            "clocked-in": ["startEnd", "delete"]
          }
        }
      },
      [groupTypeActorIds[groupTypeIds.myCareBaseStaff].owner]: {
        [groupTypeRoleIds.mCBStaff]: {
          default: {
            new: ["description", "assignee", "startEnd", "delete"],
            "clocked-in": ["startEnd", "delete"]
          }
        }
      }
    }
  }
};

export const getTopicSummaryEditFlags = (
  topic,
  actorTypeId,
  roleTypeId,
  group
) => {
  if (isEmpty(topic) || topic.isLocked) return [];

  const topicTypeId = topic.typeId;
  const editType = topic.isTemplate ? "setup" : "execution";
  const subType =
    topic.typeId === topicTypeIds.task
      ? topic.parentId
        ? "task"
        : "todo"
      : "default";
  const completionStatus = getTopicCompletionState(topic);

  if (
    roleTypeId === groupTypeRoleIds.primaryFamilyCaregiver ||
    roleTypeId === groupTypeRoleIds.secondaryFamilyCaregiver ||
    roleTypeId === groupTypeRoleIds.healthProvider ||
    roleTypeId === groupTypeRoleIds.friend ||
    roleTypeId === groupTypeRoleIds.careReceiver ||
    roleTypeId === groupTypeRoleIds.careConcierge ||
    roleTypeId === groupTypeRoleIds.support
  ) {
    roleTypeId = groupTypeRoleIds.family; // Override to family member role type, if not paidCaregiver.
  }

  return (
    (topicSummaryEditFlagValues[topicTypeId] &&
      topicSummaryEditFlagValues[topicTypeId][editType] &&
      topicSummaryEditFlagValues[topicTypeId][editType][actorTypeId] &&
      topicSummaryEditFlagValues[topicTypeId][editType][actorTypeId][
        roleTypeId
      ] &&
      topicSummaryEditFlagValues[topicTypeId][editType][actorTypeId][
        roleTypeId
      ][subType] &&
      topicSummaryEditFlagValues[topicTypeId][editType][actorTypeId][
        roleTypeId
      ][subType][completionStatus]) ||
    []
  );
};

const topicDetailRenderFlagValues = {
  [topicTypeIds.shift]: {
    setup: {
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        default: {
          new: ["setup"],
          "clocked-in": ["setup"],
          "clocked-out": ["setup"]
        }
      }
    },
    execution: {
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        default: {
          new: ["execution"],
          "clocked-in": ["execution"],
          "clocked-out": ["execution"]
        }
      }
    }
  },
  [topicTypeIds.task]: {
    setup: {
      [groupTypeActorIds[groupTypeIds.careReceiver].owner]: {
        task: {
          new: ["setupRender"]
        },
        todo: {
          new: ["setupRender"]
        }
      },
      [groupTypeActorIds[groupTypeIds.careReceiver].assigned]: {
        task: {
          new: ["setupRender"]
        },
        todo: {
          new: ["setupRender"]
        }
      },
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        task: {
          new: ["setupRender"]
        },
        todo: {
          new: ["setupRender"]
        }
      }
    },
    execution: {
      [groupTypeActorIds[groupTypeIds.careReceiver].owner]: {
        task: {
          // new: ["setupRender*"],
          new: [],
          "clocked-in": ["executionRender"],
          "clocked-out": ["executionRender"]
        },
        todo: {
          new: ["setupRender*"],
          "clocked-in": ["executionRender"],
          "clocked-out": ["executionRender"]
        }
      },
      [groupTypeActorIds[groupTypeIds.careReceiver].assigned]: {
        task: {
          new: ["executionRender"],
          "clocked-in": ["executionRender"],
          "clocked-out": ["executionRender"]
        },
        todo: {
          new: ["executionRender"],
          "clocked-in": ["executionRender"],
          "clocked-out": ["executionRender"]
        }
      },
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        task: {
          new: ["executionRender"],
          "clocked-in": ["executionRender"],
          "clocked-out": ["executionRender"]
        },
        todo: {
          new: ["executionRender"],
          "clocked-in": ["executionRender"],
          "clocked-out": ["executionRender"]
        }
      }
    }
  },
  [topicTypeIds.candidate]: {
    execution: {
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        default: {
          new: ["marketplace", "search"],
          "clocked-in": ["marketplace", "search"],
          "clocked-out": ["marketplace", "search"]
        }
      },
      [groupTypeActorIds[groupTypeIds.myCareBaseStaff].participants]: {
        default: {
          new: ["marketplace", "search"],
          "clocked-in": ["marketplace", "search"],
          "clocked-out": ["marketplace", "search"]
        }
      }
    }
  },
  [topicTypeIds.issue]: {
    execution: {
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        default: {
          new: ["executionRender"],
          "clocked-in": ["executionRender"],
          "clocked-out": ["executionRender"]
        }
      },
      [groupTypeActorIds[groupTypeIds.myCareBaseStaff].participants]: {
        default: {
          new: ["executionRender"],
          "clocked-in": ["executionRender"],
          "clocked-out": ["executionRender"]
        }
      }
    }
  }
};

export const getTopicDetailRenderFlags = (
  topic,
  actorTypeId,
  group,
  member,
  user
) => {
  // if (isEmpty(topic) || !actorTypeId || isEmpty(group)) return [];
  if (isEmpty(topic) || !actorTypeId) return [];
  const topicTypeId = topic.typeId;
  const editType = topic.isTemplate ? "setup" : "execution";
  const subType =
    topicTypeId === topicTypeIds.task
      ? topic.parentId
        ? "task"
        : "todo"
      : "default";
  const completionStatus = getTopicCompletionState(topic);

  const flags =
    (topicDetailRenderFlagValues[topicTypeId] &&
      topicDetailRenderFlagValues[topicTypeId][editType] &&
      topicDetailRenderFlagValues[topicTypeId][editType][actorTypeId] &&
      topicDetailRenderFlagValues[topicTypeId][editType][actorTypeId][
        subType
      ] &&
      topicDetailRenderFlagValues[topicTypeId][editType][actorTypeId][subType][
        completionStatus
      ]) ||
    [];

  if (isSnSOrAdmin(user)) {
    flags.push("mcb");
  }

  if (member) {
    const customRoleDescription = ((member.profile || {}).data || {})
      .customRoleDescription;
    if (
      customRoleDescription &&
      customRoleDescription !== "Paid Caregiver" &&
      topicTypeId === topicTypeIds.shift
    ) {
      return ["providerShift"];
    }
  }

  return flags;
};

const topicDetailEditFlagValues = {
  [topicTypeIds.shift]: {
    execution: {
      [groupTypeActorIds[groupTypeIds.careReceiver].assigned]: {
        [groupTypeRoleIds.family]: {
          default: {
            new: ["execution"],
            "clocked-in": ["execution"],
            "clocked-out": ["execution"]
          }
        },
        [groupTypeRoleIds.paidCaregiver]: {
          default: {
            new: ["execution"],
            "clocked-in": ["execution"],
            "clocked-out": ["execution"]
          }
        }
      }
    }
  },
  [topicTypeIds.task]: {
    setup: {
      // [groupTypeActorIds[groupTypeIds.careReceiver].owner]: {
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        [groupTypeRoleIds.family]: {
          task: {
            new: ["setupEdit"],
            "clocked-in": [],
            "clocked-out": []
          },
          todo: {
            new: ["setupEdit"],
            "clocked-in": [],
            "clocked-out": []
          }
        },
        [groupTypeRoleIds.paidCaregiver]: {
          task: {
            new: [],
            "clocked-in": [],
            "clocked-out": []
          },
          todo: {
            new: [],
            "clocked-in": [],
            "clocked-out": []
          }
        }
      }
    },
    execution: {
      // [groupTypeActorIds[groupTypeIds.careReceiver].owner]: {
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        [groupTypeRoleIds.family]: {
          task: {
            new: [],
            "clocked-in": [],
            "clocked-out": []
          },
          todo: {
            new: ["setupEdit"],
            "clocked-in": [],
            "clocked-out": []
          }
        },
        [groupTypeRoleIds.paidCaregiver]: {
          task: {
            new: [],
            "clocked-in": [],
            "clocked-out": []
          },
          todo: {
            new: [],
            "clocked-in": [],
            "clocked-out": []
          }
        }
      },
      [groupTypeActorIds[groupTypeIds.careReceiver].assigned]: {
        [groupTypeRoleIds.family]: {
          task: {
            new: ["executionEdit"],
            "clocked-in": ["executionEdit"],
            "clocked-out": ["executionEdit"]
          },
          todo: {
            new: [],
            "clocked-in": ["executionEdit"],
            "clocked-out": ["executionEdit"]
          }
        },
        [groupTypeRoleIds.paidCaregiver]: {
          task: {
            new: ["executionEdit"],
            "clocked-in": ["executionEdit"],
            "clocked-out": ["executionEdit"]
          },
          todo: {
            new: [],
            "clocked-in": ["executionEdit"],
            "clocked-out": ["executionEdit"]
          }
        }
      }
    }
  },
  [topicTypeIds.issue]: {
    execution: {
      [groupTypeActorIds[groupTypeIds.careReceiver].owner]: {
        [groupTypeRoleIds.family]: {
          default: {
            "clocked-in": ["setupEdit"]
          }
        }
      },
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        [groupTypeRoleIds.family]: {
          default: {
            new: ["setupEdit"],
            "clocked-in": ["executionEdit"],
            "clocked-out": ["executionEdit"]
          }
        },
        [groupTypeRoleIds.paidCaregiver]: {
          default: {
            new: [],
            "clocked-in": ["executionEdit"],
            "clocked-out": ["executionEdit"]
          }
        }
      },
      [groupTypeActorIds[groupTypeIds.myCareBaseStaff].owner]: {
        [groupTypeRoleIds.mCBStaff]: {
          default: {
            "clocked-in": ["setupEdit"]
          }
        }
      },
      [groupTypeActorIds[groupTypeIds.myCareBaseStaff].participants]: {
        [groupTypeRoleIds.mCBStaff]: {
          default: {
            new: ["setupEdit"],
            "clocked-in": ["executionEdit"],
            "clocked-out": ["executionEdit"]
          }
        }
      }
    }
  }
};

export const getTopicDetailEditFlags = (
  topic,
  actorTypeId,
  roleTypeId,
  group
) => {
  if (isEmpty(topic) || !actorTypeId || isEmpty(group) || topic.isLockked)
    return [];
  const topicTypeId = topic.typeId;
  const editType = topic.isTemplate ? "setup" : "execution";
  const completionStatus = getTopicCompletionState(topic);
  const subType =
    topic.typeId === topicTypeIds.task
      ? topic.parentId
        ? "task"
        : "todo"
      : "default";

  if (
    roleTypeId === groupTypeRoleIds.primaryFamilyCaregiver ||
    roleTypeId === groupTypeRoleIds.secondaryFamilyCaregiver ||
    roleTypeId === groupTypeRoleIds.healthProvider ||
    roleTypeId === groupTypeRoleIds.friend ||
    roleTypeId === groupTypeRoleIds.careReceiver ||
    roleTypeId === groupTypeRoleIds.careConcierge ||
    roleTypeId === groupTypeRoleIds.support
  ) {
    roleTypeId = groupTypeRoleIds.family; // Override to family member role type, if not paidCaregiver.
  }

  return (
    (topicDetailEditFlagValues[topicTypeId] &&
      topicDetailEditFlagValues[topicTypeId][editType] &&
      topicDetailEditFlagValues[topicTypeId][editType][actorTypeId] &&
      topicDetailEditFlagValues[topicTypeId][editType][actorTypeId][
        roleTypeId
      ] &&
      topicDetailEditFlagValues[topicTypeId][editType][actorTypeId][roleTypeId][
        subType
      ] &&
      topicDetailEditFlagValues[topicTypeId][editType][actorTypeId][roleTypeId][
        subType
      ][completionStatus]) ||
    []
  );
};

const topicDetailButtonFlagValues = {
  [topicTypeIds.shift]: {
    execution: {
      [groupTypeActorIds[groupTypeIds.careReceiver].assigned]: {
        [groupTypeRoleIds.family]: {
          new: [
            {
              type: "submit",
              title: "Submit"
            }
            // {
            //   type: "start",
            //   icon: "clock-in",
            //   iconType: "material-community"
            // }
          ],
          "clocked-in": [
            {
              type: "submit",
              title: "Submit"
            }
            // {
            //   type: "complete",
            //   icon: "clock-out",
            //   iconType: "material-community"
            // }
          ],
          "clocked-out": [
            // {
            //   type: "submit",
            //   title: "Submit"
            // }
          ]
        },
        [groupTypeRoleIds.paidCaregiver]: {
          new: [
            {
              type: "submit",
              title: "Submit"
            }
            // {
            //   type: "start",
            //   icon: "clock-in",
            //   iconType: "material-community"
            // }
          ],
          "clocked-in": [
            {
              type: "submit",
              title: "Submit"
            }
            // {
            //   type: "complete",
            //   icon: "clock-out",
            //   iconType: "material-community"
            // }
          ],
          "clocked-out": [
            // {
            //   type: "submit",
            //   title: "Submit"
            // }
          ]
        }
      }
    }
  },
  [topicTypeIds.task]: {
    setup: {
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        [groupTypeRoleIds.family]: {
          new: [
            {
              type: "edit"
            }
          ],
          "clocked-in": [],
          "clocked-out": []
        },
        [groupTypeRoleIds.paidCaregiver]: {
          new: [],
          "clocked-in": [],
          "clocked-out": []
        }
      }
    },
    execution: {
      // [groupTypeActorIds[groupTypeIds.careReceiver].owner]: {
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        [groupTypeRoleIds.family]: {
          new: [],
          "clocked-in": [],
          "clocked-out": []
        },
        [groupTypeRoleIds.paidCaregiver]: {
          new: [],
          "clocked-in": [],
          "clocked-out": []
        }
      },
      [groupTypeActorIds[groupTypeIds.careReceiver].assigned]: {
        [groupTypeRoleIds.family]: {
          new: [
            {
              type: "submit",
              title: "Submit"
            },
            {
              type: "complete",
              title: "Complete"
            }
          ],
          "clocked-in": [
            {
              type: "submit",
              title: "Submit"
            },
            {
              type: "complete",
              title: "Complete"
            }
          ],
          "clocked-out": [
            {
              type: "submit",
              title: "Submit"
            },
            {
              type: "complete",
              title: "Completed",
              disabled: true
            }
          ]
        },
        [groupTypeRoleIds.paidCaregiver]: {
          new: [
            {
              type: "submit",
              title: "Submit"
            },
            {
              type: "complete",
              title: "Complete"
            }
          ],
          "clocked-in": [
            {
              type: "submit",
              title: "Submit"
            },
            {
              type: "complete",
              title: "Complete"
            }
          ],
          "clocked-out": [
            {
              type: "submit",
              title: "Submit"
            },
            {
              type: "complete",
              title: "Completed",
              disabled: true
            }
          ]
        }
      }
    }
  },
  [topicTypeIds.candidate]: {
    execution: {
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        [groupTypeRoleIds.family]: {
          "clocked-in": [
            {
              type: "complete",
              title: "Complete"
            }
          ]
        }
      },
      [groupTypeActorIds[groupTypeIds.myCareBaseStaff].participants]: {
        [groupTypeRoleIds.mCBStaff]: {
          "clocked-in": [
            {
              type: "complete",
              title: "Complete"
            }
          ]
        }
      }
    }
  },
  [topicTypeIds.issue]: {
    execution: {
      [groupTypeActorIds[groupTypeIds.careReceiver].owner]: {
        [groupTypeRoleIds.family]: {
          new: [
            {
              type: "edit"
            }
          ]
        },
        [groupTypeRoleIds.paidCaregiver]: {
          new: [
            {
              type: "edit"
            }
          ]
        }
      },
      [groupTypeActorIds[groupTypeIds.careReceiver].participants]: {
        [groupTypeRoleIds.family]: {
          new: [
            {
              type: "start",
              title: "Accept",
              forceAssignee: true
            }
          ],
          "clocked-in": [
            {
              type: "takeover"
            },
            {
              type: "edit"
            }
            // {
            //   type: "submit",
            //   title: "Submit"
            // },
          ],
          "clocked-out": [
            {
              type: "edit"
            }
            // {
            //   type: "submit",
            //   title: "Submit"
            // }
          ]
        },
        [groupTypeRoleIds.paidCaregiver]: {
          new: [
            {
              type: "start",
              title: "Accept",
              forceAssignee: true
            }
          ],
          "clocked-in": [
            {
              type: "takeover"
            },
            {
              type: "edit"
            }
            // {
            //   type: "submit",
            //   title: "Submit"
            // },
          ],
          "clocked-out": [
            {
              type: "edit"
            }
            // {
            //   type: "submit",
            //   title: "Submit"
            // }
          ]
        }
      },
      [groupTypeActorIds[groupTypeIds.careReceiver].assigned]: {
        [groupTypeRoleIds.family]: {
          "clocked-in": [
            {
              type: "complete",
              title: "Resolve"
            },
            {
              type: "unassign",
              title: "Unaccept",
              conflict: "takeover",
              options: {
                resetCompletion: true
              }
            }
          ],
          "clocked-out": [
            {
              type: "complete",
              title: "Resolve",
              disabled: true
            }
          ]
        },
        [groupTypeRoleIds.paidCaregiver]: {
          "clocked-in": [
            {
              type: "complete",
              title: "Resolve"
            },
            {
              type: "unassign",
              title: "Unaccept",
              conflict: "takeover",
              options: {
                resetCompletion: true
              }
            }
          ],
          "clocked-out": [
            {
              type: "complete",
              title: "Resolved",
              disabled: true
            }
          ]
        }
      },
      [groupTypeActorIds[groupTypeIds.myCareBaseStaff].owner]: {
        [groupTypeRoleIds.mCBStaff]: {
          new: [
            {
              type: "edit"
            }
          ]
        }
      },
      [groupTypeActorIds[groupTypeIds.myCareBaseStaff].participants]: {
        [groupTypeRoleIds.mCBStaff]: {
          new: [
            {
              type: "start",
              title: "Accept",
              forceAssignee: true
            }
          ],
          "clocked-in": [
            {
              type: "takeover"
            },
            {
              type: "edit"
            }
          ],
          "clocked-out": [
            {
              type: "edit"
            }
          ]
        }
      },
      [groupTypeActorIds[groupTypeIds.myCareBaseStaff].assigned]: {
        [groupTypeRoleIds.mCBStaff]: {
          "clocked-in": [
            {
              type: "complete",
              title: "Resolve"
            },
            {
              type: "unassign",
              title: "Unaccept",
              conflict: "takeover",
              options: {
                resetCompletion: true
              }
            }
          ],
          "clocked-out": [
            {
              type: "complete",
              title: "Resolve",
              disabled: true
            }
          ]
        }
      }
    }
  }
};

export const getTopicDetailButtonFlags = (
  topic,
  actorTypeId,
  roleTypeId,
  group
) => {
  if (isEmpty(topic) || !actorTypeId || isEmpty(group)) return [];
  const topicTypeId = topic.typeId;
  const editType = topic.isTemplate ? "setup" : "execution";
  const completionStatus = getTopicCompletionState(topic);

  if (
    roleTypeId !== groupTypeRoleIds.paidCaregiver &&
    roleTypeId !== groupTypeRoleIds.caregiver
  ) {
    roleTypeId = groupTypeRoleIds.family; // Override to family member role type, if not paidCaregiver.
  }

  return (
    (topicDetailButtonFlagValues[topicTypeId] &&
      topicDetailButtonFlagValues[topicTypeId][editType] &&
      topicDetailButtonFlagValues[topicTypeId][editType][actorTypeId] &&
      topicDetailButtonFlagValues[topicTypeId][editType][actorTypeId][
        roleTypeId
      ] &&
      topicDetailButtonFlagValues[topicTypeId][editType][actorTypeId][
        roleTypeId
      ][completionStatus]) ||
    []
  );
};
