import React from "react";
import { observer } from "mobx-react";
import { View } from "react-native";
import { CheckBox, Icon, Text } from "react-native-elements";
import { getDisplayNameEng, isEmpty } from "../../../../../utils/helpers";
import { theme } from "../../../../../config/style-configs/theme";
import { UIText } from "../../../../../config/lang-config";

@observer
class AdminTriggerRatingPopupCareReceiverInsertView extends React.Component {
  render() {
    const { roles, selections, onMemberSelect } = this.props;
    return (
      <View style={this.props.styles.style}>
        <View style={{ marginBottom: 15 }}>
          <Text>{UIText.adminCentreTriggerRnRCareCircle}</Text>
        </View>
        {!isEmpty(roles) &&
          roles.map(role => (
            <View key={role["roleName"]} style={{ marginBottom: 5 }}>
              <Text style={this.props.styles.inputTitle}>
                {UIText.adminCentreTriggerRnRRoleNameMap[role["roleName"]]}
              </Text>
              {(role.members || []).map(member => (
                <CheckBox
                  containerStyle={{
                    opacity: member.disabled ? 0.5 : 1
                  }}
                  key={member.id}
                  title={getDisplayNameEng(member.profile)}
                  checkedIcon={
                    <Icon name="check-box" size={24} color="black" />
                  }
                  uncheckedIcon={
                    <Icon
                      name="check-box-outline-blank"
                      size={24}
                      color="black"
                    />
                  }
                  checked={selections.some(
                    s => s.value === member.id && s.checked
                  )}
                  checkedColor={theme.color}
                  disabled={member.disabled}
                  onPress={() => onMemberSelect(member)}
                />
              ))}
            </View>
          ))}
      </View>
    );
  }
}

export { AdminTriggerRatingPopupCareReceiverInsertView };
