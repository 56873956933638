import { Group } from "../../screens/app/GroupScreen";
import { Topic } from "../../screens/app/TopicScreen";
import { Profile } from "../../screens/app/ProfileScreen";
import { Chat } from "../../screens/app/ChatScreen";
import { Plan } from "../../screens/app/PlanScreen";
import { ShiftSummary } from "../../custom/mcb/screens/ShiftSummaryScreen";
import { Billing } from "../../screens/app/BillingScreen";
import { Wizard } from "../../screens/app/WizardScreen";

export const routeConfig = {
  Group: {
    screen: Group,
    path: "Dashboard"
  },
  // Screens below are one stack level above Group.
  Topic,
  Profile,
  Chat,
  Plan,
  ShiftSummary,
  Billing,
  Wizard
};

export const webNavigatorConfig = {
  initialRouteName: "Group"
};
