import { Platform, StatusBar } from "react-native";
import { theme } from "../../config/style-configs/theme";

const styles = {
  style: {
    flex: 1
  },
  listContainer: {
    paddingTop: Platform.OS === "web" ? 0 : StatusBar.currentHeight || 24,
    backgroundColor: "white"
  },
  header: {
    flex: 1,
    flexDirection: "row",
    height: "auto",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    paddingRight: 40
  },
  headerIcon: {
    position: "absolute",
    zIndex: 10,
    right: 0
  },
  avatar: {
    // flex: 0.35,
    justifyContent: "center",
    alignItems: "center"
  },
  avatarSize: 80,
  badgeStyle: {
    paddingHorizontal: 5
  },
  headerName: {
    flex: 1,
    paddingLeft: 10,
    textAlign: "center",
    fontSize: theme.FONT_SIZE_LARGE + 2,
    fontWeight: "600"
  },
  sectionHeader: {
    padding: 15,
    paddingTop: 5,
    paddingBottom: 0,
    fontSize: theme.FONT_SIZE_MEDIUM,
    color: "gray"
  },
  messageInbox: {
    marginVertical: 15
  },
  memberContainer: {
    paddingTop: 10
  },
  navigationList: {
    paddingBottom: 50
  }
};

export { styles };
