import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { View } from "react-native";
import { ListItem, Text } from "react-native-elements";
import { getRGBFromStr } from "../../utils/helpers";
import { Avatar } from "../Avatar";

@observer
class ThemedListItemView extends React.Component {
  @observable hovered = false;
  hoverProps = this.props.showHoverHighlight && {
    onMouseEnter: () => (this.hovered = true),
    onMouseLeave: () => (this.hovered = false)
  };

  render() {
    const title = this.props.titleLabel
      ? `${this.props.title} (${this.props.titleLabel})`
      : this.props.title;

    const titleProps = {
      ellipsizeMode: "tail",
      numberOfLines: 1
    };

    let rightElement;

    if (this.props.rightTitle) {
      rightElement = (
        <View>
          <Text
            style={{
              ...this.props.styles.rightTitleStyle,
              ...this.props.rightTitleStyle
            }}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {this.props.rightTitle}
          </Text>
        </View>
      );
    }

    return (
      <>
        <ListItem
          containerStyle={{
            ...this.props.styles.style,
            ...this.props.containerStyle,
            ...(this.props.appendix && {
              paddingBottom: 5
            }),
            ...(this.hovered && {
              backgroundColor: "#f5f5f5"
            })
          }}
          {...this.hoverProps}
          contentContainerStyle={{
            ...this.props.styles.contentContainerStyle,
            ...this.props.contentContainerStyle
          }}
          rightContentContainerStyle={{
            ...this.props.styles.rightContentContainerStyle,
            ...this.props.rightContentContainerStyle
          }}
          leftAvatar={
            // !this.props.leftIcon && {
            //   rounded: true,
            //   title: this.props.title ? this.props.title[0] : "",
            //   placeholderStyle: {
            //     backgroundColor: getRGBFromStr(this.props.title + this.props.titleId)
            //   },
            //   source: { uri: this.props.leftAvatar }
            // }
            !this.props.leftIcon && (
              <Avatar
                rounded={this.props.leftAvatarRounded !== false}
                title={this.props.title ? this.props.title[0] : ""}
                backgroundColor={getRGBFromStr(
                  this.props.title + this.props.titleId
                )}
                source={{ uri: this.props.leftAvatar }}
              />
            )
          }
          // onLongPress={console.log}
          leftIcon={this.props.leftIcon}
          badge={this.props.badge || null}
          title={title}
          titleProps={titleProps}
          subtitle={this.props.subtitle}
          subtitleProps={titleProps}
          titleStyle={{
            ...this.props.styles.titleStyles,
            ...this.props.titleStyle
          }}
          subtitleStyle={{
            ...this.props.styles.titleStyles,
            ...this.props.styles.subtitleStyle,
            ...this.props.subtitleStyle
          }}
          rightElement={this.props.rightElement || rightElement}
          topDivier={this.props.topDivider}
          bottomDivier={this.props.bottomDivider}
        />
        {this.props.appendix && (
          <View
            style={
              this.hovered && {
                backgroundColor: "#f5f5f5"
              }
            }
            {...this.hoverProps}
          >
            {this.props.appendix}
          </View>
        )}
      </>
    );
  }
}

export { ThemedListItemView };
