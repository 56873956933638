import React from "react";
import { LoginScreen } from "./view";
import { LoginController } from "./controller";
import { styles } from "./styles";
import { UIText } from "../../../config/lang-config";

export class Login extends React.Component {
  static navigationOptions = {
    title: `Sign In | ${UIText.title}`
  };

  controller = new LoginController(this.props);

  render() {
    return (
      <LoginScreen
        {...this.props}
        styles={styles}
        controller={this.controller}
      />
    );
  }
}
