import { theme } from "../../config/style-configs/theme";
import { responsive } from "../../config/style-configs/responsive";
import { computed, decorate } from "mobx";

export const styles = {
  groupHeading: {
    height: "auto",
    flexDirection: "row"
  },
  groupHeadingContent: {
    flex: 6.5,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 15,
    flexDirection: "row",
    alignItems: "center"
  },
  groupHeadingIcon: {
    flex: 3.5,
    height: "100%",
    paddingTop: 5,
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  groupHeadingIconButton: {
    padding: 0
  },
  get groupHeadingIconTitle() {
    return {
      color: "#aaa",
      fontSize: responsive.deviceDimension.isDesktop
        ? theme.FONT_SIZE_MEDIUM
        : theme.FONT_SIZE_SMALL,
      fontWeight: "600",
      paddingTop: 5,
      paddingBottom: 5,
      paddingHorizontal: 5
    };
  },
  groupHeadingAvatar: {
    marginRight: 15
  },
  groupHeadingText: {
    flex: 1,
    flexDirection: "column"
  },
  get groupHeadingName() {
    return {
      fontSize: responsive.deviceDimension.isDesktop
        ? theme.FONT_SIZE_XLARGE
        : theme.FONT_SIZE_LARGE,
      fontWeight: "600"
    };
  },
  groupHeadingItems: {
    color: "gray"
  }
};

decorate(styles, {
  groupHeadingName: computed
});
