import { taskTemplatePickerSetup, taskTemplateSetup } from "./setup";
import { todoTimeAssigneeSetup } from "./todo-time-assignee/setup";

export const addTaskTemplate = todo => ({
  rootBack: {
    onPress: props => {
      props.navigation.navigate("Group");
    }
  },
  skipSelect: true,
  showFieldTitle: true,
  setupList: [
    taskTemplatePickerSetup,
    taskTemplateSetup,
    todo && todoTimeAssigneeSetup
  ].filter(Boolean),
  onFinishSetup: props => {
    props.navigation.navigate("Group");
  },
  skipFinish: true,
  finishHeading: "Task template setup complete"
});
