import React from "react";
import { Text, View } from "react-native";
import { Observer, observer } from "mobx-react";
import { UIText } from "../../../../config/lang-config";
import {
  capitalize,
  getDisplayNameEng,
  isEmpty
} from "../../../../utils/helpers";
import { TopicCard } from "../../../../components/TopicCard";
import { groupTypeIds } from "../../../../config/variable-config";
import { common } from "../../../../config/style-configs/common";
import { TextIcon } from "../../../../components/TextIcon";
import { theme } from "../../../../config/style-configs/theme";
import { isServiceProviderGroup } from "../../lib/group-utilities-mcb";
import { getVariousOnboardingData } from "../../lib/admin-centre-utilities/caregiver-onboarding";

@observer
class AdminGroupCardsView extends React.Component {
  renderBusinessName = profile => (
    <Text
      style={this.props.styles.cardContentMediumBold}
      numberOfLines={2}
      ellipsizeMode="tail"
    >
      {`${UIText.adminCentreCardBusinessName}:\n${profile.businessName || "—"}`}
    </Text>
  );

  getCaregiverCardContent = (group, member, profile, user) => (
    <Observer>
      {() => (
        <View style={this.props.styles.cardContent}>
          {this.props.showBusinessName
            ? this.renderBusinessName(profile)
            : null}
          {group && group.createTime ? (
            <Text
              style={this.props.styles.cardContentMedium}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {`${UIText.adminCentreCardJoinedDate}: ${new Date(
                group.createTime
              ).toLocaleString()}`}
            </Text>
          ) : null}
          {member && member.email ? (
            <Text
              style={this.props.styles.cardContentMedium}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {member.email}
            </Text>
          ) : null}
          <Text
            style={this.props.styles.cardContentMedium}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {`${UIText.adminCentreCardAcceptedTerms} ${
              !user["termsAgreed"]
                ? UIText.generalNo
                : user
                ? UIText.generalYes
                : "Unknown"
            }`}
          </Text>
          {profile ? (
            <Text
              style={this.props.styles.cardContentMedium}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {`${UIText.adminCentreCardCaregiverApproval} ${
                profile["approvalOfCandidateConfirmedByReference"]
                  ? UIText.generalYes
                  : UIText.generalNo
              }`}
            </Text>
          ) : null}
        </View>
      )}
    </Observer>
  );

  getCaregiverCardContentV4 = (topic, user, profile) => (
    <Observer>
      {() => (
        <View style={this.props.styles.cardContent}>
          {this.props.showBusinessName
            ? this.renderBusinessName(profile)
            : null}

          <Text
            style={this.props.styles.cardContentMediumBold}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {UIText.adminCentreCardStatus}:
          </Text>
          <Text
            style={this.props.styles.cardContentMediumBreakLine}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {capitalize(topic.screeningStatus ? topic.screeningStatus : "—")}
          </Text>

          <Text
            style={this.props.styles.cardContentMediumBold}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {UIText.adminCentreCardFirstContactDate}:
          </Text>
          <Text
            style={this.props.styles.cardContentMediumBreakLine}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {topic && topic.applicationDate
              ? new Date(topic.applicationDate).toLocaleString()
              : "—"}
          </Text>

          <Text
            style={this.props.styles.cardContentMediumBold}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {UIText.adminCentreCardInterviewBookedDate}:
          </Text>
          <Text
            style={this.props.styles.cardContentMediumBreakLine}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {topic && topic.interviewBookedDate
              ? new Date(topic.interviewBookedDate).toLocaleString()
              : "—"}
          </Text>

          <Text
            style={this.props.styles.cardContentMediumBold}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {UIText.adminCentreCardInterviewDate}:
          </Text>
          <Text
            style={this.props.styles.cardContentMediumBreakLine}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {topic && topic.interviewDate
              ? new Date(topic.interviewDate).toLocaleString()
              : UIText.adminCentreCardInterviewDateCheckCalendar}
          </Text>

          <Text
            style={this.props.styles.cardContentMediumBold}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {UIText.adminCentreCardReferenceReceivedDate}:
          </Text>
          <Text
            style={this.props.styles.cardContentMediumBreakLine}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {topic && topic.informationProvidedDate
              ? new Date(topic.informationProvidedDate).toLocaleString()
              : "—"}
          </Text>

          <Text
            style={this.props.styles.cardContentMediumBold}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {UIText.adminCentreCardBackgroundCheckedDate}:
          </Text>
          <Text
            style={this.props.styles.cardContentMediumBreakLine}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {topic && topic.informationProvidedDate
              ? new Date(topic.informationProvidedDate).toLocaleString()
              : "—"}
          </Text>

          <Text
            style={this.props.styles.cardContentMediumBold}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {UIText.adminCentreCardApprovedForMarketplaceDate}:
          </Text>
          <Text
            style={this.props.styles.cardContentMediumBreakLine}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {topic && topic.completedDate
              ? new Date(topic.completedDate).toLocaleString()
              : "—"}
          </Text>

          <Text
            style={this.props.styles.cardContentMediumBold}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {UIText.adminCentreCardAcceptedTerms}{" "}
            <Text
              style={this.props.styles.cardContentMediumBreakLine}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {!user["termsAgreed"]
                ? UIText.generalNo
                : user
                ? UIText.generalYes
                : "Unknown"}
            </Text>
          </Text>
        </View>
      )}
    </Observer>
  );

  getCaregiverCardContentNewOnboarding = (onboarding, user, profile) => (
    <Observer>
      {() => {
        const {
          applicationDate,
          questionnaireCompleteDate,
          questionnaireScorePercent,
          referencesContactDate,
          referencesResponseStatus,
          completionDate
        } = getVariousOnboardingData(onboarding);

        return (
          <View style={this.props.styles.cardContent}>
            {this.props.showBusinessName
              ? this.renderBusinessName(profile)
              : null}

            <Text
              style={this.props.styles.cardContentMediumBold}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {UIText.adminCentreCardStatus}:
            </Text>
            <Text
              style={this.props.styles.cardContentMediumBreakLine}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {capitalize(
                onboarding.screeningStatus ? onboarding.screeningStatus : "—"
              )}
            </Text>

            <Text
              style={this.props.styles.cardContentMediumBold}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {UIText.adminCentreCardFirstContactDate}:
            </Text>
            <Text
              style={this.props.styles.cardContentMediumBreakLine}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {applicationDate
                ? new Date(applicationDate).toLocaleString()
                : "—"}
            </Text>

            <Text
              style={this.props.styles.cardContentMediumBold}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {UIText.adminCentreCardQuestionnaireCompleteDate}:
            </Text>
            <Text
              style={this.props.styles.cardContentMediumBreakLine}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {questionnaireCompleteDate
                ? new Date(questionnaireCompleteDate.date).toLocaleString()
                : "—"}
            </Text>

            <Text
              style={this.props.styles.cardContentMediumBold}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {UIText.adminCentreCardQuestionnaireScorePercent}:
            </Text>
            <Text
              style={this.props.styles.cardContentMediumBreakLine}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {questionnaireCompleteDate && questionnaireScorePercent
                ? questionnaireScorePercent
                : "—"}
            </Text>

            <Text
              style={this.props.styles.cardContentMediumBold}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {UIText.adminCentreCardJobReferenceContactDate}:
            </Text>
            <Text
              style={this.props.styles.cardContentMediumBreakLine}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {referencesContactDate
                ? new Date(referencesContactDate.date).toLocaleString()
                : "—"}
            </Text>

            <Text
              style={this.props.styles.cardContentMediumBold}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {UIText.adminCentreCardJobReferenceResponseCount}:
            </Text>
            <Text
              style={this.props.styles.cardContentMediumBreakLine}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {referencesResponseStatus ? referencesResponseStatus : "—"}
            </Text>

            <Text
              style={this.props.styles.cardContentMediumBold}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {UIText.adminCentreCardApprovedForMarketplaceDate}:
            </Text>
            <Text
              style={this.props.styles.cardContentMediumBreakLine}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {completionDate
                ? new Date(completionDate.date).toLocaleString()
                : "—"}
            </Text>

            <Text
              style={this.props.styles.cardContentMediumBold}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {UIText.adminCentreCardAcceptedTerms}{" "}
              <Text
                style={this.props.styles.cardContentMediumBreakLine}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {!user["termsAgreed"]
                  ? UIText.generalNo
                  : user
                  ? UIText.generalYes
                  : "Unknown"}
              </Text>
            </Text>
          </View>
        );
      }}
    </Observer>
  );

  getCareCircleCardContent = (group, member, profile, typeClassId) => (
    <Observer>
      {() => (
        <View style={this.props.styles.cardContent}>
          {group && group.createTime ? (
            <Text
              style={this.props.styles.cardContentMedium}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {`${UIText.adminCentreCardFormedDate}: ${new Date(
                group.createTime
              ).toLocaleString()}`}
            </Text>
          ) : null}
          {profile ? (
            <View>
              {/*<Text*/}
              {/*style={this.props.styles.cardContentMedium}*/}
              {/*numberOfLines={1}*/}
              {/*ellipsizeMode="tail"*/}
              {/*>*/}
              {/*{`${this.controller.getPickerPlaceholder(profile, typeClassId, "salutation")} ${getDisplayNameEng(profile)}`}*/}
              {/*</Text>*/}
              <Text
                style={this.props.styles.cardContentMedium}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {`${
                  UIText.adminCentreCardRelationship
                }: ${this.props.getPickerPlaceholder(
                  profile,
                  typeClassId,
                  "relationship"
                )}`}
              </Text>
              <Text
                style={this.props.styles.cardContentMedium}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {`${
                  UIText.adminCentreCardTerritory
                }: ${this.props.getPickerPlaceholder(
                  profile,
                  typeClassId,
                  "territories"
                )}`}
              </Text>
            </View>
          ) : null}
          {member ? (
            <View>
              <Text
                style={this.props.styles.cardContentMedium}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {`${
                  UIText.adminCentreCardOwner
                }: ${this.props.getPickerPlaceholder(
                  member.profile,
                  member.profile.typeClassId,
                  "salutation"
                )} ${getDisplayNameEng(member.profile)}`}
              </Text>
              <Text
                style={this.props.styles.cardContentMedium}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {`${UIText.registrationFieldNames.email}: ${member.email}`}
              </Text>
              <Text
                style={this.props.styles.cardContentMedium}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {`${UIText.registrationFieldNames.phone}: ${member.phone ||
                  ""}`}
              </Text>
            </View>
          ) : null}
        </View>
      )}
    </Observer>
  );

  handleCardPress = (e, c) => this.props.handleCardPress(e, c);

  handleMenuPress = (e, c) => this.props.handleMenuPress(e, c);

  render() {
    return this.props.groups.map(c => {
      const user = !!c.user && c.user;
      const group = !!c.group && c.group;
      const profile = !!c.profile && c.profile.data;
      const typeClassId = !!c.profile && c.profile.typeClassId;
      const member = !!c.member && c.member;
      const topic = !!c.topic && c.topic.data;
      const onboarding = !isEmpty(c.onboarding) && c.onboarding;

      const { getAvatarUri, hideAvatar, is3FreeGroup, loading } = this.props;

      return (
        <Observer key={group.id}>
          {() => (
            <View style={this.props.styles.card}>
              <TopicCard
                compact={true}
                title={profile && getDisplayNameEng(profile)}
                titleId={group && group.profileId}
                image={getAvatarUri((profile || {}).avatar, group.id, "group")}
                hideAvatar={hideAvatar}
                customIcons={[
                  group.typeId === groupTypeIds.careReceiver &&
                    is3FreeGroup(group) && {
                      key: "3free",
                      styles: common.textIconContainer,
                      component: (
                        <TextIcon backgroundColor={theme.color} value="3FREE" />
                      )
                    }
                ].filter(Boolean)}
                showMenuIcon={true}
                menuPending={c.loading || loading}
                onMenuPress={e => this.handleMenuPress(e, c)}
                onPress={e => this.handleCardPress(e, c)}
              >
                {isServiceProviderGroup(group) ||
                group.typeId === groupTypeIds.caregiver
                  ? !!topic
                    ? this.getCaregiverCardContentV4(topic, user, profile)
                    : onboarding
                    ? this.getCaregiverCardContentNewOnboarding(
                        onboarding,
                        user,
                        profile
                      )
                    : this.getCaregiverCardContent(group, member, profile, user)
                  : this.getCareCircleCardContent(
                      group,
                      member,
                      profile,
                      typeClassId
                    )}
              </TopicCard>
            </View>
          )}
        </Observer>
      );
    });
  }
}

export { AdminGroupCardsView };
