import React from "react";
import { ActivityIndicator, Image, ScrollView, View } from "react-native";
import { Observer, observer } from "mobx-react";
import { theme } from "../../../config/style-configs/theme";
import { Button, Icon, Text } from "react-native-elements";
import { UIText } from "../../../config/lang-config";
import { getDisplayNameEng } from "../../../utils/helpers";
import { FileDrop } from "react-file-drop";
import { TouchableOpacityIOSFix } from "../../../components/TouchableOpacityIOSFix";
import { FilePickerWrapper } from "../../../components/FilePickerWrapper";
import { ThemedListItem } from "../../../components/ThemedListItem";
import filesize from "filesize";
import { ThemedButton } from "../../../components/ThemedButton";

@observer
class CaregiverUploadView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentDidMount() {
    return this.controller.componentDidMount();
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  handleFileInputClick = () => this.fileInput && this.fileInput.click();

  render() {
    const {
      buttonDisabled,
      profileData,
      files,
      filePreviews,
      fileTypes,
      setDropInFrame,
      unsetDropInFrame,
      setDropInTarget,
      unsetDropInTarget,
      execSubmit,
      submitted,
      submitting,
      handleFileChange,
      handleRemoveFile
    } = this.controller;

    return this.controller.loading ? (
      <View style={this.props.styles.loading}>
        <ActivityIndicator size="large" color={theme.color} />
      </View>
    ) : (
      <View style={this.props.styles.style}>
        <ScrollView
          style={this.props.styles.scrollView}
          contentContainerStyle={this.props.styles.container}
        >
          <View style={this.props.styles.header}>
            <Image
              source={theme.loginLogo}
              style={this.props.styles.headerLogo}
            />
          </View>

          <View style={this.props.styles.questions}>
            <Text style={this.props.extraStyles.heading}>
              {UIText.caregiverUploadHeading(getDisplayNameEng(profileData))}
            </Text>
            <br />
            <Text style={this.props.extraStyles.heading}>
              {submitted
                ? UIText.caregiverUploadSuccess
                : UIText.caregiverUploadSubHeading}
            </Text>
          </View>

          <Observer>
            {() => {
              const dropColor = this.controller.dropInFrame
                ? this.controller.dropInTarget
                  ? theme.color
                  : theme.generalNormalColor
                : "#aaa";
              return (
                <>
                  {!submitted && (
                    <FilePickerWrapper
                      multiple
                      extensions={fileTypes}
                      onChange={handleFileChange}
                    >
                      <TouchableOpacityIOSFix>
                        <View
                          style={{
                            ...this.props.extraStyles.fileDrop,
                            borderColor: dropColor
                          }}
                        >
                          <FileDrop
                            onFrameDragEnter={setDropInFrame}
                            onFrameDragLeave={unsetDropInFrame}
                            onFrameDrop={() => {
                              unsetDropInFrame();
                              unsetDropInTarget();
                            }}
                            onDragOver={setDropInTarget}
                            onDragLeave={unsetDropInTarget}
                            onDrop={handleFileChange}
                          >
                            <style>{`.file-drop-target { padding: 50px; text-align: center; }`}</style>
                            <Icon
                              name="file"
                              type="material-community"
                              // size={this.props.styles.iconSize / 1.5}
                              color={dropColor}
                            />
                            <Text
                              style={{
                                ...this.props.extraStyles.fileDropText,
                                color: dropColor
                              }}
                            >
                              {UIText.caregiverUploadDropOrClick}
                              <br />
                              <Text
                                style={this.props.extraStyles.fileDropFormats}
                              >
                                {UIText.caregiverUploadFormats}
                              </Text>
                            </Text>
                          </FileDrop>
                        </View>
                      </TouchableOpacityIOSFix>
                    </FilePickerWrapper>
                  )}
                </>
              );
            }}
          </Observer>

          <Observer>
            {() => (
              <View style={this.props.styles.questions}>
                {files.map(file => (
                  <ThemedListItem
                    containerStyle={this.props.extraStyles.fileItem}
                    key={file.name}
                    leftAvatar={filePreviews[file.name] || undefined}
                    leftAvatarRounded={false}
                    leftIcon={
                      !filePreviews[file.name] && (
                        <Icon
                          name={
                            file.type.match(/image/g)
                              ? "image"
                              : file.type.match(/pdf/g)
                              ? "file-pdf-box"
                              : file.type.match(/word/g)
                              ? "file-word-box"
                              : "file"
                          }
                          type="material-community"
                          color={
                            file.type.match(/pdf/g)
                              ? "#e0191a"
                              : file.type.match(/word/g)
                              ? "#2b579a"
                              : "#aaa"
                          }
                        />
                      )
                    }
                    title={file.name}
                    titleId={file.name}
                    subtitle={filesize(file.size, { standard: "jedec" })}
                    rightElement={
                      <Button
                        type="clear"
                        icon={
                          <Icon
                            name={submitted ? "check" : "close"}
                            size={24}
                            color={
                              submitted ? theme.generalAcceptColor : theme.color
                            }
                          />
                        }
                        onPress={() => !submitted && handleRemoveFile(file)}
                      />
                    }
                  />
                ))}
              </View>
            )}
          </Observer>

          {!submitted && (
            <ThemedButton
              style={this.props.styles.button}
              disabled={buttonDisabled}
              title={UIText.generalSubmit}
              loading={submitting}
              onPress={execSubmit}
            />
          )}
        </ScrollView>
      </View>
    );
  }
}

export { CaregiverUploadView };
