import NavigationService from "../../utils/navigation-service";
import { computed, observable, when } from "mobx";
import { clientController } from "./client-controller";
import { msgService } from "../services/messaging-service";
import { stateController } from "./state-controller";
import { isEmpty, preventDefaultStopProp } from "../../utils/helpers";
import { apiController } from "./api-controller";
import { isGroupSubscriptionMsgEnabled } from "../../custom/mcb/lib/group-utilities-mcb";

export class TopicServiceController {
  @observable topicLoading = true;
  @observable messageLoading = false;

  @observable topicTypes = [];
  @observable topicGroups = [];

  cancelled = false;

  @computed get userId() {
    return clientController.userId;
  }
  @computed get topic() {
    return clientController.findTopicById(this.topicId) || {};
  }
  @computed get topicTypeName() {
    return (this.topicTypes.find(tp => tp.id === this.topic.typeId) || {})
      .description;
  }
  @computed get selfMember() {
    return (
      clientController.findMembers(
        m =>
          m.userId === this.userId &&
          (this.topic.groupIdList && this.topic.groupIdList.includes(m.groupId))
      )[0] || {}
    );
  }
  @computed get topicSelfMember() {
    return this.members.find(m => m.userId === this.userId) || {};
  }
  @computed get selfRoles() {
    return this.selfMember.roleList || [];
  }
  @computed get actors() {
    return this.topic.actors || [];
  }
  @computed get selfActors() {
    return this.actors.filter(a =>
      a.memberIdList.includes(this.topicSelfMember.id)
    );
  }
  @computed get ownerActor() {
    return this.selfActors.find(a => a.actorName === "owner") || {};
  }
  @computed get assignedActor() {
    return this.selfActors.find(a => a.actorName === "assigned") || {};
  }
  @computed get members() {
    return this.topic.members || [];
  }
  @computed get messages() {
    return msgService.messages[this.topicId] || [];
  }
  @computed get groups() {
    return this.topicGroups || [];
  }
  @computed get currentGroup() {
    return clientController.findGroupById(
      this.selfMember.groupId ||
        this.topicSelfMember.groupId ||
        stateController.viewGroupId
    );
  }
  @computed get topicType() {
    return this.topicTypes.find(tp => tp.id === this.topic.typeId) || {};
  }
  @computed get subTopics() {
    return clientController.findSubTopicByParentId(this.topicId);
  }
  @computed get isSubscriptionMsgEnabled() {
    return isGroupSubscriptionMsgEnabled(this.currentGroup);
  }
  @computed get groupChatLobby() {
    return msgService.findChatLobbyTopic(this.currentGroup.id);
  }
  @computed get unreadCount() {
    const topicUnreadCountData =
      (msgService.unreadCountTopics || []).find(t => t.id === this.topicId) ||
      {};
    const groupUnreadCountData =
      (!topicUnreadCountData.lastPublicMessageTimeLong &&
        (msgService.unreadCountTopics || []).find(
          t => t.id === this.groupChatLobby.id
        )) ||
      {};
    return groupUnreadCountData.unreadCount || topicUnreadCountData.unreadCount;
  }

  constructor(topicId) {
    this.topicId = topicId;
  }

  initialize = async () => {
    stateController.viewTopicState.shadowLoading = true;

    return this.loadTopic()
      .then(() => !this.cancelled && this._loadTopicPermissions())
      .then(() => !this.cancelled && this._loadTopicMetadataSync());
  };

  loadTopic = async () => {
    return apiController
      .getTopicById(this.topicId)
      .then(clientController.updateTopic);
  };

  _loadTopicPermissions = async () => {
    if (isEmpty(this.topicSelfMember)) {
      console.log("no self topic member");
      if (isEmpty(this.selfMember) || !this.selfMember.id) {
        // return Promise.reject(UIText.pageNotExist);
        return NavigationService.navigate("Group");
      }
      return await apiController
        .getTopicById(this.topicId, this.selfMember.id)
        .then(topic => {
          if (isEmpty(topic)) {
            // return Promise.reject(UIText.pageNotExist);
            return NavigationService.navigate("Group");
          }

          return clientController.updateTopic(topic);
        });
    }
    return Promise.resolve();
  };

  _loadTopicMetadataSync = async () =>
    Promise.all([
      Promise.all([
        !this.cancelled &&
          this._loadSubTopics().then(
            () => !this.cancelled && this._loadTopicTypes()
          ),
        !this.cancelled && this._loadTopicGroups(),
        !this.cancelled && this._checkDefaultThread()
      ]).then(() => (this.topicLoading = false)),
      // this.loadTopicMessages(),
      this.getGroupChatLobby()
    ]);

  _loadSubTopics = async () => {
    return apiController
      .getSubTopicsByParentId(this.topicId, true)
      .then(subTopics => {
        clientController
          .findSubTopicByParentId(this.topicId)
          .map(t => clientController.removeTopic(t.id));

        for (let topic of subTopics) {
          clientController.updateTopic(topic);
        }
        return Promise.resolve();
      });
  };

  _loadTopicTypes = async () => {
    const typeIds = new Set([
      this.topic.typeId,
      ...this.subTopics.map(t => t.typeId)
    ]);
    for (let typeId of typeIds) {
      if (this.cancelled) return;
      await apiController
        .getTopicTypeById(typeId)
        .then(topicType => this.topicTypes.push(topicType));
    }
    return Promise.resolve();
  };

  _loadTopicGroups = async () => {
    const getGroup = id => {
      if (this.cancelled) return;
      return apiController.getGroupById(id).then(async group => {
        if (isEmpty(group.profile)) {
          await apiController
            .getProfileById(group.profileId)
            .then(profile => (group.profile = profile));
        }
        return this.topicGroups.push(group);
      });
    };

    return Promise.all(this.topic.groupIdList.map(getGroup));
  };

  _checkDefaultThread = async () => {
    if (!this.topic["defaultThreadId"]) {
      return this._createDefaultThread().then(() =>
        this.loadTopic().then(this._checkDefaultThread)
      );
    }

    if (!this.currentThreadId)
      this.currentThreadId = this.topic.defaultThreadId;

    return Promise.resolve();
  };

  _createDefaultThread = async () => {
    return msgService.createTopicDefaultThread(this.topicId);
  };

  loadTopicMessages = async fromId => {
    this.messageLoading = true;
    return msgService
      .syncMessages(this.topicId, this.currentThreadId, fromId)
      .finally(() => (this.messageLoading = false));
  };

  getGroupChatLobby = async () => {
    await when(() => !isEmpty(this.currentGroup));
    if (!isEmpty(msgService.findChatLobbyTopic(this.currentGroup.id))) return;
    this.messageLoading = true;
    return apiController
      .groupChatLobbyPreJoin(this.currentGroup.id)
      .then(clientController.updateTopic)
      .finally(() => (this.messageLoading = false));
  };

  handleBillingPress = event => {
    preventDefaultStopProp(event);

    stateController.viewPlanState.setupMode = false;
    const id = this.currentGroup.id;
    NavigationService.navigate("Plan", {
      group: id
    });
  };

  cancel = () => (this.cancelled = true);
}
