import { Keyboard } from 'react-native';
import { clientController } from '../../../cdm/controllers/client-controller';
import { computed, observable, toJS } from 'mobx';
import { stateController } from '../../../cdm/controllers/state-controller';
import { UIText } from '../../../config/lang-config';
import { isEmpty } from '../../../utils/helpers';
import { apiService } from '../../../cdm/services/api-service';
import { endpointConfig, serverConfig } from '../../../config/api-config';
import { userStatus } from '../../../config/variable-config';

export class LoginController {
  @observable buttonDisabled = true;
  @observable signingIn = false;

  @computed get autoUsername() {
    const form = stateController.base64form;
    return form && (form.username || form.email || form.phone);
  }
  @computed get autoPassword() {
    const form = stateController.base64form;
    return form && form.password;
  }
  @computed get autoSignIn() {
    return stateController.autoSignIn;
  }
  @computed get invitationData() {
    return stateController.viewInvitationRegState.data;
  }

  @observable fieldData = {
    username: toJS(this.autoUsername) || "",
    password: toJS(this.autoPassword) || ""
  };

  constructor(props) {
    this.props = props;
  }

  componentDidMount() {
    stateController.currentScreen = "Login";
    this.checkAutoSignIn();
    setTimeout(this.validateFields, 100);
  }

  _loginLoading = () => {
    this.signingIn = true;
    return stateController.showPopup({
      title: UIText.loginProgress,
      content: UIText.pleaseWait
    });
  };

  _showError = err => {
    // console.warn(err);
    this.signingIn = false;
    stateController.showPopup({
      title: UIText.loginFailure,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  checkAutoSignIn = () => {
    if (this.autoSignIn) {
      this.execSignIn();
      return (stateController.autoSignIn = false);
    }
  };

  updateFields = (field, event) => {
    if (!event) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      this.fieldData[field] = event.nativeEvent.text;
    this.validateFields();
  };

  validateFields = () => {
    this.buttonDisabled = !this.fieldData.username || !this.fieldData.password;
  };

  handleLinkPress = (link, event) => {
    if (link === "visitor") {
      this.props.navigation.navigate("Visitor");
    }
    if (link === "register") {
      this.props.navigation.navigate("Registration");
    }
    if (link === "reset") {
      stateController.viewResetState.resetRequest = true;
      this.props.navigation.navigate("Reset");
    }
  };

  execSignIn = event => {
    if (this.buttonDisabled && !this.autoSignIn) return;

    this.validateFields();
    if (this.buttonDisabled) return;

    Keyboard.dismiss();

    const invitationData = toJS(this.invitationData);

    const handleLoginType = async () => {
      if (!isEmpty(invitationData)) {
        // Invitation member associating sequence.
        return this.invitationLogin(invitationData);
      } else {
        // normal login.
        return this.normalLogin();
      }
    };

    this._loginLoading()
      .then(() => this.fieldData)
      .then(clientController.execSignIn)
      // .then(stateController.dismissPopup)
      .then(handleLoginType)
      .catch(this.handleLoginError);
  };

  invitationLogin = async invitationData => {
    const oauth = clientController.client.oauth;
    const data = {
      authToken: oauth["access_token"],
      invitationId: invitationData.id,
      invitationHash: invitationData["invitation_hash"]
    };

    return stateController
      .showPopup({
        title: UIText.invitationVerification,
        content: UIText.pleaseWait
      })
      .then(() => {
        apiService.resetOAuthData();
        return apiService.async("POST", {
          endpoint: endpointConfig.invitation_login,
          data
        });
      })
      .then(console.log)
      .then(stateController.dismissPopup)
      .then(() =>
        stateController.showPopup({
          title: UIText.invitationVerification,
          content: UIText.invitationLoginSent,
          leftButtonText: UIText.generalConfirm,
          leftButtonPress: this.execSignIn
        })
      );
  };

  normalLogin = async () => {
    return stateController
      .showPopup({
        title: UIText.postLoginProgress,
        content: UIText.pleaseWait
      })
      .then(clientController.initializeClient)
      .then(stateController.dismissPopup);
  };

  handleLoginError = err => {
    console.error(err);
    this.buttonDisabled = false;
    this.signingIn = false;

    return stateController.dismissPopup().then(() => {
      if (!err.response && !err.message) {
        return this._showError({ message: UIText.serviceUnavailable });
      }
      if (err.response && err.response.status === 400) {
        return this._showError({ message: UIText.loginWrongCred });
      }
      if (err.response && err.response.status === 403) {
        const user = err.response.data;
        if (Number(user.status) === userStatus.unverified) {
          return stateController.showPopup({
            title: UIText.loginFailure,
            content: UIText.loginUnverified,
            buttonSet: [
              {
                title: UIText.loginResendVerification,
                onPress: this.resendVerification
              },
              {
                title: UIText.generalConfirm,
                onPress: () =>
                  stateController
                    .dismissPopup()
                    .then(clientController.execLogout)
              }
            ].map(b => (b.align = "center") && b)
          });
        }
        if (!user.termsAgreed || user.needPasswordReset) {
          stateController.viewResetState.terms = !user.termsAgreed;
          stateController.viewResetState.reset = user.needPasswordReset;
          return this.props.navigation.navigate("Reset");
        }
      }
      return this._showError(err);
    });
  };

  resendVerification = async () => {
    const oauth = clientController.client.oauth;
    const data = {
      authToken: oauth["access_token"]
    };
    return stateController
      .dismissPopup()
      .then(() =>
        stateController.showPopup({
          title: UIText.title,
          content: UIText.pleaseWait
        })
      )
      .then(() =>
        apiService.async("POST", {
          endpoint: endpointConfig.resend_verification,
          headers: serverConfig.defaultHeaders,
          data
        })
      )
      .then(stateController.dismissPopup)
      .then(() =>
        stateController.showPopup({
          title: UIText.title,
          content: UIText.verificationResent,
          leftButtonText: UIText.generalConfirm,
          leftButtonPress: () =>
            stateController.dismissPopup().then(clientController.execLogout)
        })
      )
      .catch(this._showError);
  };
}
