import { common } from "../../../../config/style-configs/common";
import { theme } from "../../../../config/style-configs/theme";

export const styles = {
  style: {
    flex: 1
  },
  loading: common.loading,
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  buttonContainer: common.listButtonContainer,
  buttonReminder: common.listButtonReminderContainer,
  buttonTitleContainer: common.buttonTitleContainer,
  buttonTitle: common.buttonTitle
};
