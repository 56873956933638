import { clientController } from '../../../cdm/controllers/client-controller';
import { computed } from 'mobx';
import { stateController } from '../../../cdm/controllers/state-controller';
import { capitalize, isEmpty, randomString, safeParseJSON } from '../../../utils/helpers';
import { careCircleIntentTypeRoleId } from '../../../custom/mcb/config/registration_intents';
import { groupTypeIds, typeClassIds } from '../../../config/variable-config';
import { formService } from '../../../cdm/services/form-service';
import {
  createCareReceiverMember,
  createGroupProfile,
  createSelfMember
} from '../../../custom/mcb/lib/group-utilities-mcb';
import { UIText } from '../../../config/lang-config';
import { apiController } from '../../../cdm/controllers/api-controller';
import { getGroupRoles } from '../../../cdm/lib/group-utilities';

export class RootController {
  initializationTriggered = false;

  @computed get client() {
    return clientController.client || {};
  }

  @computed get user() {
    return this.client.user || {};
  }

  constructor(props) {
    this.props = props;
    this._initialize();
  }

  componentDidMount() {
    stateController.currentScreen = "Root";
    this._didFocusSubscription = this.props.navigation.addListener(
      "didFocus",
      this._delayedInitialize
    );
  }

  componentWillUnmount() {
    this._didFocusSubscription.remove();
  }

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: capitalize(UIText.group),
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: () =>
        stateController.dismissPopup().then(() => {
          stateController.viewGroupId = 0;
          this.props.navigation.navigate("Group");
        }),
      dismissOnBackPress: true
    });
  };

  _delayedInitialize = () => {
    setTimeout(() => {
      if (
        !this.initializationTriggered &&
        stateController.currentScreen === "Root"
      ) {
        return this._initialize();
      }
    }, 1000);
  };

  _initialize = () => {
    this.initializationTriggered = true;
    if (stateController.viewGroupId < 0 || clientController.isVisitor) {
      const cachedScratchpadId = clientController.scratchpadId;
      const cachedScratchpad = clientController.findGroupById(
        cachedScratchpadId
      );
      const cachedScratchpadExists =
        cachedScratchpadId &&
        !isEmpty(cachedScratchpad) &&
        (cachedScratchpad.groupName || "").match(/{scratchpad}/g) &&
        stateController.viewGroupId > -2;

      if (cachedScratchpadExists) {
        stateController.viewGroupId = cachedScratchpadId;
        return this.props.navigation.navigate("Group", {
          group: cachedScratchpadId
        });
      } else {
        return this._launchScratchPad();
      }
    } else {
      return this._detectSetup();
    }
  };

  _detectSetup = () => {
    if (
      !clientController.initialized ||
      !clientController.loginState ||
      !clientController.invitationsReady
    ) {
      return setTimeout(this._detectSetup, 500);
    }

    return clientController.checkInvitedMembers().then(invitations => {
      if (!invitations) {
        return this._initializeSetup();
      }
      return Promise.resolve();
    });
  };

  _initializeSetup = () => {
    let knownTypeRoleIds = [];

    // If member data not ready return;
    if (!Array.isArray(this.user.members)) return;

    for (let member of this.user.members) {
      // Skip member that's not in a role of group.
      if (!Array.isArray(member["roleList"])) continue;

      // Skip member doesn't have a groupId.
      const group = clientController
        .findVisibleGroups()
        .find(g => g.id === member.groupId);
      if (!group) continue;

      // Collect typeRoleId.
      for (let role of member["roleList"]) {
        knownTypeRoleIds.push(role.groupTypeRoleId);
      }
    }

    // Future..
    let hasCareCircleRole = false;
    for (let id of knownTypeRoleIds) {
      careCircleIntentTypeRoleId.includes(id) && (hasCareCircleRole = true);
    }

    // User's registration intention typeRoleIds.
    let intentTypeRoles = safeParseJSON(this.user["intentRole"]) || [];

    // Future..
    for (let id of intentTypeRoles) {
      careCircleIntentTypeRoleId.includes(id) &&
        hasCareCircleRole &&
        (intentTypeRoles = intentTypeRoles.filter(i => i !== id));
    }

    // neededSetup is user's intended type roles minus user's existing type roles.
    const neededSetup =
      Array.isArray(intentTypeRoles) &&
      intentTypeRoles
        .filter(typeRoleId => !knownTypeRoleIds.includes(typeRoleId))
        .filter(typeRoleId => [3, 9].includes(typeRoleId))
        .sort((a, b) => a - b);

    // nonDefaultGroups are user's valid groups.
    const nonDefaultGroups = clientController
      .findVisibleGroups()
      .filter(g => !g.groupName || !g.groupName.match(/{scratchpad}/g));

    if (neededSetup.length > 0 || nonDefaultGroups.length === 0) {
      // CareReceiver Setup
      if (neededSetup[0] === 3) {
        // return this._launchScratchPad();
        return this._checkPrePlanAndPromoCode();
      }

      stateController.initSetupMode = neededSetup[0];

      if (stateController.initSetupMode) {
        return this.props.navigation.navigate("Setup");
      } else {
        // Out of this world then go to scratchpad.
        return this._launchScratchPad();
      }
    } else {
      this.props.navigation.navigate("Group", {
        group: stateController.viewGroupId || nonDefaultGroups[0].id
      });
    }
  };

  _checkPrePlanAndPromoCode = () => {
    const preSelectedPlanId = this.user.planId;
    const prePromoCode = this.user.promoCode;

    if (preSelectedPlanId && prePromoCode) {
      return apiController
        .getPlanPromoCodeValidity(
          0, // No existing group so group id is 0;
          preSelectedPlanId,
          prePromoCode
        )
        .then(result => {
          if (!isEmpty(result)) {
            // Plan first before group.
            stateController.viewPlanState.setupMode = true;
            stateController.initSetupMode = "prePlanPromo";
            stateController.viewPlanState.promoCode = prePromoCode;
            return setTimeout(() => this.props.navigation.navigate("Plan"));
          }
        })
        .catch(err => {
          console.warn(err);
          return this._launchScratchPad();
        });
    }

    return this._launchScratchPad();
  };

  _launchScratchPad = () => {
    const group = {
      groupName: "{scratchpad}" + randomString(),
      typeId: groupTypeIds.careReceiver,
      owner: clientController.userId,
      parentId: null,
      scratchpad: true
    };

    let getGroupRetry = 0;

    const getScratchpad = async groupId =>
      await apiController
        .getGroupById(groupId)
        .then(group => {
          if (isEmpty(group)) {
            getGroupRetry++;
            return getGroupRetry > 5
              ? Promise.reject({ message: UIText.marketScratchpadUnavailable })
              : getScratchpad(groupId);
          }
          return group;
        })
        .then(clientController.updateGroup);

    const setDisplayName = async profile =>
      (profile.displayName = `${UIText.title} ${capitalize(
        UIText.marketplace
      )}`) && profile;

    const initNavId = groupId => {
      clientController.setScratchpadId(groupId);
      stateController.viewGroupId = groupId;
      return groupId;
    };

    return stateController
      .dismissPopup()
      .then(() =>
        stateController.showPopup({
          title: capitalize(UIText.marketplace),
          content: UIText.pleaseWait
        })
      )
      .then(() =>
        formService.findFormClassByIdAsync(typeClassIds.careReceiverProfile)
      )
      .then(typeClass =>
        formService.assembleFormData(typeClass, {}, { displayName: true })
      )
      .then(formService.disassembleFormData)
      .then(setDisplayName)
      .then(profile => createGroupProfile(group, profile, null))
      .then(getGroupRoles)
      .then(createSelfMember)
      .then(createCareReceiverMember)
      .then(initNavId)
      .then(getScratchpad)
      .then(() =>
        this.props.navigation.navigate("Group", {
          group: stateController.viewGroupId
        })
      )
      .then(stateController.dismissPopup)
      .catch(this._showError);
  };

  execLogout = () => {
    clientController.execLogout(true, true);
  };
}
