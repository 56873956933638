import React from "react";
import {
  ActivityIndicator,
  Platform,
  RefreshControl,
  ScrollView,
  Text,
  View
} from "react-native";
import { observer, Observer } from "mobx-react";
import { Header } from "../../../components/Header";
import { theme } from "../../../config/style-configs/theme";
import { AndroidBackHandler } from "react-navigation-backhandler";
import { ThemedButton } from "../../../components/ThemedButton";
import { capitalize, getDisplayNameEng, isEmpty } from "../../../utils/helpers";
import { UIText } from "../../../config/lang-config";
import { Form } from "../../../components/Form";
import { ListRefreshButtonWeb } from "../../../components/ListRefreshButtonWeb";
import { Divider } from "react-native-elements";
import { action, observable } from "mobx";
import { CollapsibleAccordionTitle } from "../../../components/CollapsibleAccordionTitle";
import Collapsible from "react-native-collapsible";
import { findNodeHandle } from "react-native-web";
import { computedFn } from "mobx-utils";

@observer
class ProfileScreen extends React.Component {
  @observable additionalFormCollapsed = false;

  constructor(props) {
    super(props);
    this.controller = props.controller;
    this.form = [];
    this.formFields = [];
    this.controller.setScrollPosCallback(this.scrollTo);
    this.controller.setScrollToFieldCallback(this.scrollToField);
    this.fieldProps = {
      showLabel: true,
      showTitle: true,
      enabledGetter: computedFn(
        field =>
          this.controller.editing &&
          !this.controller.loading &&
          (field.name !== "email" || this.controller.allowEmailEdit)
      ),
      // inputRightIcon: field =>
      //   editable && field.name === "email" && (
      //     <Button
      //       type="clear"
      //       icon={<Icon size={24} color="#999" name="edit" />}
      //       onPress={this.controller.handleEmailChange}
      //     />
      //   )
      // ,
      valueGetter: computedFn(field =>
        field.value ? field.value : this.controller.editing ? "" : "—"
      ),
      avatarTitleGetter: computedFn(field =>
        this.controller.getAvatarTitle(this.controller.profile)
      ),
      avatarColorIdGetter: computedFn(field =>
        this.controller.getAvatarColorId(this.controller.profile)
      ),
      onInputChange: action((e, field) => (field.value = e.nativeEvent.text)),
      onCheckboxChange: action(field => (field.value = !field.value)),
      onPickerChange: action((value, field) => (field.value = value)),
      onAvatarPickerChange: this.controller.onAvatarPickerChange,
      onAvatarRemoval: action(field => (field.value = null)),
      onBlur: action((form, field) => this.controller.onFieldBlur(form, field)),
      onSubmitEditing: action((form, field) =>
        this.controller.helpFocusNextField(field, this.formFields, form)
      ),
      refSetter: (form, field, f) => {
        f && (f.name = field.name);
        this.formFields[form.indexOf(field)] = f;
      },
      fieldStyle: this.props.styles.field,
      fieldLabelStyle: this.props.styles.fieldLabel,
      pickerStyle: this.props.styles.picker,
      pickerLabelStyle: this.props.styles.pickerLabel,
      checkboxStyle: this.props.styles.checkbox,
      nonEditModeHideNoValue: this.controller.nonEditModeHideNoValue
    };
  }

  scrollTo = args => this.scrollView.scrollTo(args);

  scrollToField = fieldName => {
    const field = this.formFields.find(f => f && f.name === fieldName);
    const fieldElm = findNodeHandle(field);
    if (fieldElm)
      fieldElm.scrollIntoView({
        behavior: "smooth"
      });
    // if (field && typeof field.measure === "function")
    //   field.measure((fx, fy, w, h, px, py) => {
    //     this.scrollTo({ y: py - 90 });
    //   });
  };

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  getDisplayName = () =>
    this.controller.profile.length > 0
      ? getDisplayNameEng(this.controller.profileData)
      : capitalize(UIText.profile);

  // TODO: Multiple buttons capable.
  renderTopButton = () =>
    this.controller.loaded && (
      <Observer>
        {() => (
          <View style={this.props.styles.buttonContainer}>
            <ThemedButton
              loading={this.controller.topButton.loading}
              disabled={this.controller.topButton.disabled}
              style={this.props.styles.button}
              title={this.controller.topButton.text}
              onPress={this.controller.topButton.handlePress}
            />
          </View>
        )}
      </Observer>
    );

  renderBottomButton = () =>
    this.controller.loaded && (
      <Observer>
        {() => (
          <View style={this.props.styles.buttonContainer}>
            <ThemedButton
              loading={this.controller.bottomButton.loading}
              disabled={this.controller.bottomButton.disabled}
              style={this.props.styles.button}
              title={this.controller.bottomButton.text}
              onPress={this.controller.bottomButton.handlePress}
            />
          </View>
        )}
      </Observer>
    );
  // TODO: Multiple buttons capable.

  renderProfileForm = () => (
    <Observer>
      {() => (
        <Form
          form={this.controller.profile}
          // showDisplayName={this.controller.showDisplayName}
          renderFlags={this.controller.renderFlags}
          editFlags={this.controller.editFlags}
          fieldProps={this.fieldProps}
        />
      )}
    </Observer>
  );

  renderAdditionalForm = additionalForm => (
    <Observer>
      {() => {
        const { heading, form, fieldProps } = additionalForm;
        return (
          <>
            <CollapsibleAccordionTitle
              onTitlePress={() =>
                (this.additionalFormCollapsed = !this.additionalFormCollapsed)
              }
              title={heading}
              collapsed={this.additionalFormCollapsed}
              bold
            />
            <Collapsible collapsed={this.additionalFormCollapsed}>
              <View style={this.props.styles.scrollViewContent}>
                <Observer>
                  {() => {
                    const { onSave, onDiscard, onSaveLoading } = additionalForm;
                    return onDiscard || onSave ? (
                      <View style={this.props.styles.buttonContainer}>
                        {onDiscard && (
                          <ThemedButton
                            width={45}
                            icon="cancel"
                            disabled={onSaveLoading}
                            onPress={onDiscard}
                          />
                        )}
                        {onSave && (
                          <ThemedButton
                            width={45}
                            icon="save"
                            disabled={onSaveLoading}
                            loading={onSaveLoading}
                            onPress={onSave}
                          />
                        )}
                      </View>
                    ) : null;
                  }}
                </Observer>
                <View style={this.props.styles.form}>
                  <Form form={form} fieldProps={fieldProps} />
                </View>
              </View>
            </Collapsible>
            <Divider style={{ marginVertical: 10 }} />
          </>
        );
      }}
    </Observer>
  );

  renderVersionInfo = () => (
    <View style={this.props.styles.versionContainer}>
      <Text style={this.props.styles.versionInfo}>[[versionInfo]]</Text>
      <Text style={this.props.styles.versionInfo}>[[versionDate]]</Text>
    </View>
  );

  render() {
    return (
      <View style={this.props.styles.style}>
        <Header
          placement="center"
          title={
            // this.controller.editing
            //   ? capitalize(UIText.profileEdit)
            //   : this.controller.titleOverride &&
            //     typeof this.controller.titleOverride === "string"
            //   ? this.controller.titleOverride
            //   : this.getDisplayName()
            this.controller.titleOverride &&
            typeof this.controller.titleOverride === "string"
              ? this.controller.titleOverride
              : this.controller.editing
              ? capitalize(UIText.profileEdit)
              : this.getDisplayName()
          }
          handleLeftPress={
            this.controller.saving
              ? () => {}
              : this.controller.editing
              ? this.controller.backButtonOverride.includeEdit &&
                this.controller.backButtonOverride.handlePress
                ? this.controller.backButtonOverride.handlePress
                : this.controller.cancelEdit
              : !this.controller.backButtonOverride.includeEdit &&
                this.controller.backButtonOverride.handlePress
              ? this.controller.backButtonOverride.handlePress
              : this.controller.handleBackPress
          }
          leftIcon={
            this.controller.saving
              ? null
              : this.controller.backButtonOverride.icon
              ? this.controller.backButtonOverride.icon
              : this.controller.editing
              ? "cancel"
              : "arrow-back"
          }
          handleRightPress={
            this.controller.rightButtonOverride.handlePress
              ? this.controller.rightButtonOverride.handlePress
              : this.controller.edit
          }
          rightIcon={
            this.controller.rightButtonOverride.loading
              ? null
              : !this.controller.saving &&
                this.controller.rightButtonOverride.icon
              ? this.controller.rightButtonOverride.icon
              : // : !this.controller.readOnly &&
                //   (this.controller.editing ? "save" : "edit")
                null
          }
          rightTitle={
            this.controller.rightButtonOverride.loading
              ? null
              : !this.controller.saving &&
                this.controller.rightButtonOverride.title
              ? this.controller.rightButtonOverride.title
              : !this.controller.readOnly &&
                (this.controller.editing
                  ? UIText.profileSave.toUpperCase()
                  : UIText.profileEdit.toUpperCase())
          }
          rightIconLoading={
            this.controller.rightButtonOverride.loading ||
            (!this.controller.readOnly && this.controller.loading) ||
            this.controller.intermediateLoading
          }
          navigation={this.props.navigation}
        />

        {Platform.OS === "web" ? (
          this.controller.profile.length > 0 ? (
            <ScrollView
              ref={view => (this.scrollView = view)}
              contentContainerStyle={this.props.styles.scrollView}
              onScroll={this.controller.onScroll}
              onLayout={this.controller.checkScrollToField}
              scrollEventThrottle={100}
            >
              <View style={this.props.styles.scrollViewContent}>
                {this.controller.topButton && this.renderTopButton()}
                {!isEmpty(this.controller.additionalForm) &&
                  this.renderAdditionalForm(this.controller.additionalForm)}
                <View style={this.props.styles.formButtonColumn}>
                  <View style={this.props.styles.form}>
                    {this.renderProfileForm()}
                  </View>
                  <ListRefreshButtonWeb
                    style={this.props.styles.refreshButton}
                    buttonStyle={{ margin: 5 }}
                    onRefresh={this.controller.onRefresh}
                  />
                </View>
                {this.controller.isDefaultGroup && this.renderVersionInfo()}
                {this.controller.bottomButton && this.renderBottomButton()}
              </View>
            </ScrollView>
          ) : (
            <View style={this.props.styles.loading}>
              <ActivityIndicator size="large" color={theme.color} />
            </View>
          )
        ) : (
          <ScrollView
            ref={view => (this.scrollView = view)}
            contentContainerStyle={this.props.styles.scrollView}
            refreshControl={
              <RefreshControl
                refreshing={this.controller.loading}
                onRefresh={this.controller.onRefresh}
              />
            }
            onScroll={this.controller.onScroll}
            onLayout={this.controller.checkScrollToField}
            scrollEventThrottle={100}
          >
            <View style={this.props.styles.scrollViewContent}>
              {this.controller.topButton && this.renderTopButton()}
              {this.renderProfileForm()}
              {this.controller.isDefaultGroup && this.renderVersionInfo}
              {this.controller.bottomButton && this.renderBottomButton()}
            </View>
          </ScrollView>
        )}

        <AndroidBackHandler
          onBackPress={
            this.controller.saving
              ? () => true
              : this.controller.editing
              ? this.controller.cancelEdit
              : this.controller.backButtonOverride.handlePress
              ? this.controller.backButtonOverride.handlePress
              : this.controller.handleBackPress
          }
        />
      </View>
    );
  }
}

export { ProfileScreen };
