import React from "react";
import { ShiftSummaryScreen } from "./view";
import { ShiftSummaryController } from "./controller";
import { styles } from "./styles";

export class ShiftSummary extends React.Component {
  static navigationOptions = {
    title: "Completed Shift Summary"
  };

  constructor(props) {
    super(props);
    this.controller = new ShiftSummaryController(props);
  }

  render() {
    return (
      <ShiftSummaryScreen
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
