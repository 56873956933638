import React from "react";
import { toJS } from "mobx";
import { observer, Observer } from "mobx-react";
import { View } from "react-native";
import { Divider } from "react-native-elements";
import Collapsible from "react-native-collapsible";
import { FormField } from "../FormField";
import { CollapsibleAccordionTitle } from "../CollapsibleAccordionTitle";
import { formService } from "../../cdm/services/form-service";
import { isEmpty } from "../../utils/helpers";

const ICollapsibleTitle = observer(props => {
  const { field, collapsed, anchor } = props;

  return (
    <Observer>
      {() => (
        <CollapsibleAccordionTitle
          onTitlePress={() => (field.expanded = !field.expanded)}
          title={field.title}
          collapsed={collapsed}
          bold={!!anchor}
        />
      )}
    </Observer>
  );
});

const ICollapsibleAnchorSet = observer(props => {
  const { form, field, renderFlags, editFlags, fieldProps, styles } = props;

  const visibleForm = form.filter(field =>
    Array.isArray(renderFlags) && renderFlags.length > 0
      ? !!renderFlags.find(flag => field.flags && field.flags[flag])
      : field
  );

  if (!form || form.length === 0 || visibleForm.length === 0) return null;

  const collapsed = !field.expanded;

  return (
    <Observer>
      {() => (
        <View>
          <ICollapsibleTitle field={field} collapsed={collapsed} anchor />
          <Observer>
            {() => (
              <Collapsible collapsed={collapsed}>
                <FormView
                  form={form}
                  renderFlags={renderFlags}
                  editFlags={editFlags}
                  fieldProps={fieldProps}
                  styles={styles}
                  anchored={true}
                />
              </Collapsible>
            )}
          </Observer>
          {!form[form.length - 1].set && <Divider style={styles.divider} />}
        </View>
      )}
    </Observer>
  );
});

const IFormSet = observer(props => {
  const {
    form,
    masterField,
    renderFlags,
    editFlags,
    fieldProps,
    showDisplayName,
    styles
  } = props;

  const setName = masterField.set;
  const set = form.filter(f => setName && f.set === setName);
  const collapsed = !masterField.expanded;

  const masterDependency = form.find(f => f.name === masterField["dependOn"]);
  const masterDependencyHide =
    masterDependency &&
    (form[0]["dependOnFalsy"]
      ? masterDependency.value
      : form[0]["dependOnValue"]
      ? form[0]["dependOnValue"] !== masterDependency.value
      : !masterDependency.value);

  // const masterFieldEnabled = fieldProps.enabledGetter(masterField)
  //   ? Array.isArray(editFlags)
  //     ? editFlags.length > 0 &&
  //       !!editFlags.find(flag => masterField.flags && masterField.flags[flag])
  //     : true
  //   : false;

  const masterFieldHidden = masterField.hidden || masterDependencyHide;
  // (fieldProps.nonEditModeHideNoValue &&
  //   !masterFieldEnabled &&
  //   !masterField.value) ||
  const dependency = form.find(f => f.name === masterField["setDependOn"]);
  const dependencyHide =
    dependency &&
    (form[0]["dependOnFalsy"]
      ? dependency.value
      : form[0]["dependOnValue"]
      ? form[0]["dependOnValue"] !== dependency.value
      : !dependency.value);
  if (dependencyHide) {
    for (let field of set) {
      field.value && setTimeout(() => (field.value = undefined));
    }
    return null;
  }

  const visibleForm = set.filter(field =>
    Array.isArray(renderFlags) && renderFlags.length > 0
      ? !!renderFlags.find(flag => field.flags && field.flags[flag])
      : field
  );

  return (
    <Observer>
      {() => (
        <View>
          <Observer>
            {() =>
              (!masterFieldHidden && !isEmpty(visibleForm) && (
                <ICollapsibleTitle field={masterField} collapsed={collapsed} />
              )) ||
              null
            }
          </Observer>
          <Observer>
            {() => (
              <Collapsible collapsed={collapsed}>
                <Observer>
                  {() =>
                    set.map(field => (
                      <Observer key={field.name}>
                        {() => {
                          fieldProps.showTitle = false;
                          return (
                            <IFormField
                              form={form}
                              field={field}
                              renderFlags={renderFlags}
                              editFlags={editFlags}
                              showDisplayName={showDisplayName}
                              {...fieldProps}
                            />
                          );
                        }}
                      </Observer>
                    ))
                  }
                </Observer>
              </Collapsible>
            )}
          </Observer>
          {!masterFieldHidden && !isEmpty(visibleForm) && (
            <Divider style={styles.divider} />
          )}
        </View>
      )}
    </Observer>
  );
});

const IFormField = observer(props => {
  const {
    form,
    field,
    renderFlags,
    editFlags,
    showDivider,
    showLabel,
    showTitle,
    enabledGetter,
    valueGetter,
    avatarTitleGetter,
    avatarColorIdGetter,
    nonEditModeHideNoValue,
    showAdditionalCheckbox,
    additionalCheckedIcon,
    additionalUncheckedIcon,
    additionalCheckboxTitle,
    additionalCheckboxDisabled,
    additionalCheckboxChecked,
    onAdditionalCheckboxPress,
    onInputChange,
    onCheckboxChange,
    onPickerChange,
    onDateTimeChange,
    onDateTimeOpen,
    onDateTimeClose,
    onAvatarPickerChange,
    onAvatarRemoval,
    onEndEditing,
    onBlur,
    onSubmitEditing,
    refSetter
  } = props;

  // renderFlags, editFlags
  const shouldRender =
    Array.isArray(renderFlags) && renderFlags.length > 0
      ? typeof renderFlags[0] === "boolean" && !!renderFlags[0]
        ? true
        : !!renderFlags.find(flag => field.flags && field.flags[flag])
      : true;
  const enabled = field.disabled
    ? false
    : enabledGetter(field)
    ? Array.isArray(editFlags)
      ? editFlags.length > 0 &&
        !!editFlags.find(flag => field.flags && field.flags[flag])
      : true
    : false;

  setTimeout(() => (field._disabled = !enabled));

  // dependOn, dependOnValue, dependOnFalsy
  const dependency = form.find(f => f.name === field["dependOn"]);
  const dependencyHide =
    dependency &&
    (field["dependOnFalsy"]
      ? dependency.value
      : field["dependOnValue"]
      ? field["dependOnValue"] !== dependency.value
      : !dependency.value);

  if (dependencyHide) {
    field.value && setTimeout(() => (field.value = undefined));
    return null;
  }

  const dependent = form.find(f => f["dependOn"] === field.name);

  if (field.type === "picker") {
    if ((dependent && dependent.type === "picker") || dependency) {
      const filterOptionsByDependent = () => {
        const dependentOptions =
          dependent && (dependent.allOptions || dependent.options);
        !field.allOptions && (field.allOptions = toJS(field.options));
        field.options = field.allOptions.filter(
          o =>
            dependent["dependOnValue"] ||
            (dependentOptions &&
              dependentOptions.some(
                d => d.dependOnValue && d.dependOnValue === o.name
              ))
        );
      };
      const filterOptionsByDependency = () => {
        !field.allOptions && (field.allOptions = toJS(field.options));
        field.options = field.allOptions.filter(
          o => !o.dependOnValue || dependency.value === o.dependOnValue
        );
        if (!field.options.find(o => o.name === field.value))
          field.value = field.options[0] && field.options[0].name;
      };
      setTimeout(() => {
        dependent && filterOptionsByDependent();
        setTimeout(() => {
          dependency && filterOptionsByDependency();
        }, 0);
      }, 0);
    }
  }

  // renderValueModifier
  // let renderValueModifier;
  // if (field["renderValueModifier"]) {
  //   renderValueModifier =
  //     Array.isArray(field["renderValueModifier"].flags) &&
  //     field["renderValueModifier"].flags.length > 0
  //       ? Array.isArray(renderFlags) && renderFlags.length > 0
  //         ? renderFlags.some(f =>
  //             field["renderValueModifier"].flags.includes(f)
  //           )
  //           ? new Function(
  //               "value",
  //               `return ${field["renderValueModifier"].function}`
  //             )
  //           : null
  //         : null
  //       : new Function(
  //           "value",
  //           `return ${field["renderValueModifier"].function}`
  //         );
  // }
  const renderValueModifier = formService.getFieldRenderValueModifier(
    field,
    renderFlags
  );

  // linkedFields, linkedField modifier
  const linkage = field["linkedFields"];
  let linkedValue;

  if (linkage) {
    if (linkage.name) {
      if (Array.isArray(linkage.name) && linkage.modifier) {
        const linkedFieldValues = form
          .map(f => linkage.name.includes(f.name) && f.value)
          .filter(Boolean);
        linkedValue = new Function(
          linkage.name.join(","),
          `return ${linkage.modifier}`
        )(...linkedFieldValues);
      } else if (typeof linkage.name === "string") {
        const linkedField = form.find(f => f.name === linkage.name) || {};
        linkedValue = linkage.modifier
          ? new Function(linkage.name, `return ${linkage.modifier}`)(
              linkedField.value
            )
          : linkedField.value;
      }
    }
  }

  if (linkedValue) setTimeout(() => (field.value = linkedValue));

  // Visibility hidden
  const hidden =
    field.hidden || (nonEditModeHideNoValue && !enabled && !field.value);

  // Input right icon
  let inputRightIcon;
  if (typeof props.inputRightIcon === "function")
    inputRightIcon = props.inputRightIcon(field);

  return shouldRender ? (
    field.name !== "displayName" || props.showDisplayName ? (
      <Observer key={field.name}>
        {() => (
          <FormField
            field={field}
            showLabel={showLabel}
            showTitle={showTitle}
            enabled={enabled}
            hidden={hidden}
            inputValue={valueGetter && valueGetter(field)}
            inputValueRenderModifier={renderValueModifier}
            inputRightIcon={inputRightIcon}
            avatarTitle={avatarTitleGetter && avatarTitleGetter(field)}
            avatarColorId={avatarColorIdGetter && avatarColorIdGetter(field)}
            fieldStyle={props.fieldStyle}
            fieldLabelStyle={props.fieldLabelStyle}
            pickerStyle={props.pickerStyle}
            pickerLabelStyle={props.pickerLabelStyle}
            checkboxStyle={props.checkboxStyle}
            bottomDivider={showDivider || field.bottomDivider}
            errorMessage={field.errorMessage}
            showAdditionalCheckbox={
              showAdditionalCheckbox && showAdditionalCheckbox(field)
            }
            additionalCheckedIcon={additionalCheckedIcon}
            additionalUncheckedIcon={additionalUncheckedIcon}
            additionalCheckboxTitle={additionalCheckboxTitle}
            additionalCheckboxDisabled={
              additionalCheckboxDisabled && additionalCheckboxDisabled(field)
            }
            additionalCheckboxChecked={
              additionalCheckboxChecked && additionalCheckboxChecked(field)
            }
            onAdditionalCheckboxPress={
              onAdditionalCheckboxPress &&
              (e => onAdditionalCheckboxPress(e, field))
            }
            onInputChange={onInputChange && (e => onInputChange(e, field))}
            onCheckboxChange={
              onCheckboxChange && (() => onCheckboxChange(field))
            }
            onPickerChange={
              onPickerChange && (value => onPickerChange(value, field))
            }
            onDateTimeChange={
              onDateTimeChange && (value => onDateTimeChange(value, field))
            }
            onDateTimeOpen={
              onDateTimeOpen && (event => onDateTimeOpen(event, field))
            }
            onDateTimeClose={
              onDateTimeClose && (event => onDateTimeClose(event, field))
            }
            onAvatarPickerChange={
              onAvatarPickerChange && (e => onAvatarPickerChange(e, field))
            }
            onAvatarRemoval={onAvatarRemoval && (() => onAvatarRemoval(field))}
            onEndEditing={onEndEditing && (() => onEndEditing(form, field))}
            onBlur={onBlur && (() => onBlur(form, field))}
            onSubmitEditing={
              onSubmitEditing && (() => onSubmitEditing(form, field))
            }
            refSetter={refSetter && (f => refSetter(form, field, f))}
          />
        )}
      </Observer>
    ) : null
  ) : null;
});

export const FormView = observer(props => {
  const {
    form,
    fieldProps,
    renderFlags,
    editFlags,
    styles,
    anchored,
    anchorDisabled,
    showDisplayName,
    expandAll
  } = props;

  let anchoredFields = [];

  return (
    <View
      style={{
        ...styles.style,
        ...props.containerStyle
      }}
    >
      {Array.isArray(form) && (
        <Observer>
          {() =>
            form.map((field, i) => (
              <Observer key={field.name}>
                {() => {
                  if (field.type === "collapsibleAnchor" && !anchorDisabled) {
                    let anchoring = false;
                    let subForm = [];

                    expandAll && setTimeout(() => (field.expanded = true));

                    for (let f of form) {
                      if (f.name === field.startField) anchoring = true;
                      if (anchoring) {
                        subForm.push(f);
                        anchoredFields.push(f.name);
                      }
                      if (f.name === field.endField) anchoring = false;
                    }
                    if (anchoring) {
                      // Anchoring failed.
                      anchoredFields = [];
                      return null;
                    }
                    return (
                      <Observer key={field.name}>
                        {() => (
                          <ICollapsibleAnchorSet
                            field={field}
                            form={subForm}
                            renderFlags={renderFlags}
                            editFlags={editFlags}
                            fieldProps={fieldProps}
                            expandAll={expandAll}
                            styles={styles}
                          />
                        )}
                      </Observer>
                    );
                  }

                  if (anchoredFields.includes(field.name) && !anchored)
                    return null;

                  if (field.set) {
                    const masterField = field;

                    expandAll &&
                      setTimeout(() => (masterField.expanded = true));

                    return field.title ? (
                      <Observer key={field.set}>
                        {() => (
                          <IFormSet
                            form={form}
                            masterField={masterField}
                            renderFlags={renderFlags}
                            editFlags={editFlags}
                            fieldProps={fieldProps}
                            showDisplayName={showDisplayName}
                            styles={styles}
                          />
                        )}
                      </Observer>
                    ) : null;
                  }

                  return (
                    <Observer key={field.name}>
                      {() => (
                        <IFormField
                          form={form}
                          field={field}
                          renderFlags={renderFlags}
                          editFlags={editFlags}
                          showDisplayName={showDisplayName}
                          styles={styles}
                          {...fieldProps}
                        />
                      )}
                    </Observer>
                  );
                }}
              </Observer>
            ))
          }
        </Observer>
      )}
    </View>
  );
});

// @observer
// class NewFormField extends React.Component {
//   constructor(props) {
//     super(props);
//
//   }
// }
//
// export { NewFormField };
