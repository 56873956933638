import React from "react";
import { RegistrationController } from "./controller";
import { RegistrationScreen } from "./view";
import { styles } from "./styles";
import { UIText } from "../../../config/lang-config";

export class Registration extends React.Component {
  static navigationOptions = {
    title: `New Account | ${UIText.title}`
  };

  controller = new RegistrationController(this.props);

  render() {
    return (
      <RegistrationScreen
        {...this.props}
        styles={styles}
        controller={this.controller}
      />
    );
  }
}
