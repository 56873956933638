import { capitalize } from "../../../../utils/helpers";
import UIText from "../../../../config/languages/en-CA";
import { getVariousOnboardingData } from "./caregiver-onboarding";
import { AdminReferenceResponseDetail } from "../../components/AdminReferenceResponseDetail";
import { AdminBackgroundCheckFiles } from "../../components/AdminBackgroundCheckFiles";
import { AdminQuestionnaireResponses } from "../../components/AdminQuestionnaireResponses";

export const AdminCaregiverOnboardingForm = (
  onboarding,
  extraFields,
  profile,
  showError
) => {
  const {
    isMcbHosted,
    applicationDate,
    questionnaireCompleteDate,
    questionnaireScorePercent,
    questionnaireResponseLink,
    questionnaireResponses,
    referencesContactDate,
    referencesResponseStatus,
    completionDate
  } = getVariousOnboardingData(onboarding);

  const currentScreeningStatusOption = `${capitalize(
    onboarding["screeningStatus"]
  )} (Current)`;

  return {
    metadata: [
      {
        name: "updateScreeningStatus",
        placeholder: "Screening status",
        value: currentScreeningStatusOption,
        type: "picker",
        readonly:
          onboarding["screeningStatus"] === "completed" ||
          onboarding["screeningStatus"] === "approved" ||
          onboarding["screeningStatus"] === "cancelled",
        options: [
          currentScreeningStatusOption,

          "Candidate unsuitable",
          "Candidate disinterested",
          "Candidate failed reference checks",

          "Cancelled"
        ]
          .filter(Boolean)
          .filter(s => s.toLowerCase() !== onboarding["screeningStatus"])
      },
      [
        "contact created",
        "questionnaire sent",
        "questionnaire not completed"
      ].includes(onboarding["screeningStatus"]) && {
        name: "resendQuestionnaireLink",
        type: "linkHeavy",
        readonly: true,
        placeholder: "Re-send questionnaire email",
        onPress: "adminResendCandidateEmail",
        profileId: profile.id,
        value: true
      },
      onboarding["screeningStatus"].match(
        /completed|approved|profile\srequested/g
      ) && {
        name: "resendProfileLink",
        type: "linkHeavy",
        readonly: true,
        placeholder: "Re-send profile completion link",
        onPress: "adminResendCandidateEmail",
        profileId: profile.id,
        isPointerPro: !!onboarding["questionnaireContactId"],
        value: true
      },
      {
        name: "applicationDate",
        type: "input",
        readonly: true,
        placeholder: UIText.adminCentreCardFirstContactDate,
        value: applicationDate
          ? new Date(applicationDate).toLocaleString()
          : null
      },
      {
        name: "questionnaireCompleteDate",
        type: "input",
        readonly: true,
        placeholder: UIText.adminCentreCardQuestionnaireCompleteDate,
        value: questionnaireCompleteDate
          ? new Date(questionnaireCompleteDate.date).toLocaleString()
          : null
      },
      {
        name: "questionnaireScorePercent",
        type: "input",
        readonly: true,
        placeholder: UIText.adminCentreCardQuestionnaireScorePercent,
        value:
          questionnaireCompleteDate && questionnaireScorePercent
            ? questionnaireScorePercent
            : null
      },
      isMcbHosted && {
        name: "questionnaireResponses",
        type: "custom",
        readonly: true,
        placeholder: UIText.adminCentreCardQuestionnaireResponses,
        value: AdminQuestionnaireResponses({
          profile,
          questionnaireResponses,
          showError
        })
      },
      {
        name: "backgroundCheckFiles",
        type: "custom",
        readonly: true,
        placeholder: UIText.adminCentreCardBackgroundCheckedFiles,
        value: AdminBackgroundCheckFiles({
          onboarding,
          showError
        })
      },
      questionnaireCompleteDate && {
        name: "questionnaireResponseLink",
        type: "linkHeavy",
        readonly: true,
        placeholder: "Questionnaire response link (Survey Anyplace)",
        value: questionnaireResponseLink
      },
      {
        name: "referencesContactDate",
        type: "input",
        readonly: true,
        placeholder: UIText.adminCentreCardJobReferenceContactDate,
        value: referencesContactDate
          ? new Date(referencesContactDate.date).toLocaleString()
          : null
      },
      referencesContactDate && {
        name: "referencesResponseStatus",
        type: "input",
        readonly: true,
        placeholder: UIText.adminCentreCardJobReferenceResponseCount,
        value: referencesResponseStatus ? referencesResponseStatus : null
      },
      referencesContactDate && {
        name: "referencesResponseDetail",
        type: "custom",
        readonly: true,
        placeholder: UIText.adminCentreCardJobReferenceResponseDetail,
        value: AdminReferenceResponseDetail({
          onboarding,
          profile,
          showError
        })
      },
      {
        name: "approvedForMarketplaceDate",
        type: "input",
        readonly: true,
        placeholder: UIText.adminCentreCardApprovedForMarketplaceDate,
        value: completionDate
          ? new Date(completionDate.date).toLocaleString()
          : null
      },
      ...extraFields
    ].filter(Boolean)
  };
};
