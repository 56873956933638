import React from "react";
import { ProfileScreen } from "./view";
import { ProfileController } from "./controller";
import { styles } from "./styles";

export class Profile extends React.Component {
  controller = new ProfileController(this.props);

  render() {
    return (
      <ProfileScreen
        {...this.props}
        styles={styles}
        controller={this.controller}
      />
    );
  }
}
