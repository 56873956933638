import { View } from "react-native";
import { Icon, Text } from "react-native-elements";
import React from "react";
import { observer } from "mobx-react";
import { theme } from "../../config/style-configs/theme";
import { TouchableOpacityIOSFix } from "../TouchableOpacityIOSFix";

export const CollapsibleAccordionTitleView = observer(props => (
  <TouchableOpacityIOSFix onPress={props.onTitlePress}>
    <View
      style={{
        ...props.styles.wrapper,
        ...props.style
      }}
    >
      <Icon
        containerStyle={
          props.bold && {
            borderWidth: 2,
            borderRadius: theme.FONT_SIZE_LARGE,
            borderColor: theme.color
          }
        }
        name={props.collapsed ? "expand-more" : "expand-less"}
        size={props.bold ? theme.FONT_SIZE_LARGE : theme.FONT_SIZE_XLARGE}
        color={props.styles.titleText.color}
      />
      <Text
        style={{
          ...props.styles.titleText,
          fontWeight: props.bold ? "600" : "400"
        }}
      >
        {props.title}
      </Text>
    </View>
  </TouchableOpacityIOSFix>
));
