import React from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { Divider, Icon } from "react-native-elements";
import { observer, Observer } from "mobx-react";
import { UIText } from "../../config/lang-config";
import { ThemedListItem } from "../ThemedListItem";
import { HeaderSearchPanel } from "../HeaderSearchPanel";
import { isEmpty } from "../../utils/helpers";
import { theme } from "../../config/style-configs/theme";
import Highlighter from "react-native-highlight-words";
import { topicTypeIds } from "../../config/variable-config";
import { TouchableOpacityIOSFix } from "../TouchableOpacityIOSFix";

@observer
class TopicSearchBarView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  get searchIcon() {
    if (this.controller.loading) {
      return <ActivityIndicator size={30} color={theme.color} />;
    }
    if (this.controller.searchLoading) {
      return "more-horiz";
    }
    return null;
  }

  getResults = results => {
    if (isEmpty(results) || !this.controller.visible) return null;

    return (
      <Observer>
        {() =>
          results.map(type => (
            <View key={type.name}>
              <View style={this.props.styles.resultTypeTitleContainer}>
                <Text style={this.props.styles.resultTypeTitle}>
                  {type.name}
                </Text>
              </View>
              {this.getResultItem(type.topics)}
            </View>
          ))
        }
      </Observer>
    );
  };

  getResultItem = items =>
    items.map((t, i) => (
      <Observer key={t.id || i}>
        {() => (
          <TouchableOpacityIOSFix
            onPress={() => this.controller.handleSearchResultPress(t)}
          >
            <>
              <ThemedListItem
                showHoverHighlight
                leftIcon={
                  <Icon
                    name={this.getResultItemIcon(t)}
                    size={24}
                    color="#666"
                  />
                }
                title={this.getResultTitle(t)}
                subtitle={
                  t.typeId === topicTypeIds.groupMessaging ||
                  t.typeId === topicTypeIds.groupChatLobby ||
                  t.typeId === topicTypeIds.messaging
                    ? t["lastPublicMessageTime"]
                      ? UIText.inboxLastMessageAt(
                          new Date(
                            t["lastPublicMessageTimeLong"]
                          ).toLocaleString()
                        )
                      : UIText.inboxNoLastActivity
                    : t.updateTime
                    ? `${UIText.updatedAt}: ${new Date(
                        t.updateTime
                      ).toLocaleString()}`
                    : ""
                }
                appendix={this.getResultMatchDetails(t)}
              />
              <Divider />
            </>
          </TouchableOpacityIOSFix>
        )}
      </Observer>
    ));

  getResultTitle = topic => (
    <Observer>
      {() => {
        const match = topic.matches.find(m => m.type === "description") || {};

        const title = `${match.text || topic.description} ${
          topic.parentName ? `(${topic.parentName})` : ""
        }`;

        if (isEmpty(match))
          return <Text style={this.props.styles.titleText}>{title}</Text>;

        return (
          <Highlighter
            style={this.props.styles.titleText}
            highlightStyle={this.props.styles.highlightedText}
            searchWords={match.highlight}
            textToHighlight={title}
          />
        );
      }}
    </Observer>
  );

  getResultItemIcon = topic =>
    topic.typeId === topicTypeIds.messaging ||
    topic.typeId === topicTypeIds.groupMessaging ||
    topic.typeId === topicTypeIds.groupChatLobby
      ? topic["lastPublicMessageTime"]
        ? "chat"
        : "chat-bubble"
      : "assignment";

  getResultMatchDetails = topic => {
    const matches =
      topic.matches && topic.matches.filter(m => m.type === "typeClass");
    if (isEmpty(matches)) return null;

    return (
      <View style={this.props.styles.resultDetailContainer}>
        {matches.map((m, i) => (
          <Observer key={m.placeholder || m.text || i}>
            {() => (
              <>
                <View style={this.props.styles.detailContainer}>
                  <Text style={this.props.styles.detailPlaceholder}>
                    {m.placeholder || ""}
                  </Text>
                  <Highlighter
                    style={this.props.styles.detailText}
                    highlightStyle={this.props.styles.highlightedText}
                    searchWords={m.highlight}
                    textToHighlight={m.text}
                  />
                </View>
                {i !== matches.length - 1 && <Divider />}
              </>
            )}
          </Observer>
        ))}
      </View>
    );
  };

  render() {
    return (
      <HeaderSearchPanel
        visible={this.controller.visible}
        searchFieldIcon={this.searchIcon}
        searchFieldPlaceholder={UIText.groupTopicsSearch}
        searchFieldValue={this.controller.searchFieldValue}
        onSearchFieldChange={this.controller.onSearchFieldChange}
        resultComponent={this.getResults(
          this.controller.searchResultsByTopicType
        )}
        hasResults={this.controller.hasResults}
        onBackdropPress={this.controller.dismiss}
        clearInput={this.controller.clearInput}
      />
    );
  }
}

export { TopicSearchBarView };
