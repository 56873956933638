import { theme } from "../../config/style-configs/theme";

export const styles = {
  style: {
    backgroundColor: "#fff",
    flexDirection: "row"
  },
  title: {
    color: theme.color,
    paddingLeft: 10,
    fontSize: theme.FONT_SIZE_MEDIUM
  }
};
