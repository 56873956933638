import { apiService } from '../../../cdm/services/api-service';
import { endpointConfig } from '../../../config/api-config';
import { capitalize, evalStringBoolean, safeParseJSON } from '../../../utils/helpers';
import { stateController } from '../../../cdm/controllers/state-controller';
import { clientController } from '../../../cdm/controllers/client-controller';
import { UIText } from '../../../config/lang-config';
import { mcbEndpointConfig } from '../../../custom/mcb/config/api-config';

export class VerificationController {
  constructor(props) {
    this.props = props;

    this.verificationHash = stateController.verificationHash;

    this.verificationUser =
      this.props.navigation.getParam("user") ||
      this.props.navigation.getParam("email");
    this.verificationIsChangeEmail = this.props.navigation.getParam(
      "changeEmail"
    );
    this.verificationIsChangeEmailRevert = this.props.navigation.getParam(
      "changeEmailRevert"
    );
    this.verificationIsOnboarding = this.props.navigation.getParam(
      "completeProfile"
    );

    if (this.verificationIsChangeEmail) {
      this.verificationIsChangeEmail = evalStringBoolean(
        this.verificationIsChangeEmail
      );
    }

    if (this.verificationIsChangeEmailRevert) {
      this.verificationIsChangeEmailRevert = evalStringBoolean(
        this.verificationIsChangeEmailRevert
      );
    }

    this._execVerification();
  }

  componentDidMount() {
    stateController.currentScreen = "Verification";
  }

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.verificationFailure,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _showSuccess = () => {
    stateController.dismissPopup().then(() =>
      stateController.showPopup({
        title: capitalize(UIText.success),
        content: UIText.verificationSuccess,
        leftButtonText: UIText.generalConfirm,
        dismissOnBackPress: true
      })
    );
  };

  _execVerification = () => {
    if (
      !this.verificationHash ||
      !this.verificationUser ||
      stateController.verificationLock
    ) {
      return this._navigateLogin();
    }

    clientController.execLogout();

    stateController
      .showPopup({
        title: this.verificationIsChangeEmail
          ? UIText.changeEmail
          : this.verificationIsChangeEmailRevert
          ? UIText.changeEmailRevert
          : this.verificationIsOnboarding
          ? UIText.caregiverCompleteProfileLogin
          : capitalize(UIText.verification),
        content: UIText.pleaseWait
      })
      .then(
        this.verificationIsOnboarding
          ? this._getVerificationResultOnboardingAutoLogin
          : this._getVerificationResult
      );
  };

  _getVerificationResult = async () => {
    return await apiService
      .async("POST", {
        endpoint:
          endpointConfig[
            this.verificationIsChangeEmail
              ? "verify_email_change"
              : this.verificationIsChangeEmailRevert
              ? "revert_email_change_exec"
              : "verify"
          ],
        data: {
          token: this.verificationHash,
          identifier: this.verificationUser
        }
      })
      .then(response => {
        return response.data.pass
          ? [response.data.pass, null, response.data.user]
          : [false, { message: UIText.verificationExpiry }];
      })
      .then(([pass, err, user]) => {
        pass ? this._showSuccess() : this._showError(err);
        this._navigateLogin(user);
      })
      .catch(err => {
        console.error(err);
        return [false, { message: UIText.verificationExpiry }];
      });
  };

  _getVerificationResultOnboardingAutoLogin = async () => {
    return await apiService
      .async("POST", {
        endpoint: mcbEndpointConfig.caregiver_onboard_complete_profile,
        data: {
          token: this.verificationHash,
          identifier: this.verificationUser
        }
      })
      .then(response => {
        stateController.base64form = safeParseJSON(atob(response.data)) || {};
        stateController.base64form.email = this.verificationUser;
        stateController.autoSignIn = true;
        stateController.onboardingSignIn = true;
        Object.freeze(stateController.onboardingSignIn);
        return this._navigateLogin();
      })
      .catch(err => {
        console.error(err);
        this._navigateLogin();
        return this._showError({
          message: UIText.caregiverCompleteProfileLoginFailure
        });
      });
  };

  _navigateLogin = user => {
    stateController.verificationLock = true;
    if (user) {
      stateController.base64form = {
        email: user["user_name"] || user.email
      };
    }
    Object.freeze(stateController.verificationLock);
    return this.props.navigation.navigate("Login");
  };
}
