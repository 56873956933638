import React from 'react';
import { AvatarHistoryPickerView } from './view';
import { AvatarHistoryPickerController } from './controller';
import { styles } from './styles';

export class AvatarHistoryPicker extends React.Component {
  controller = new AvatarHistoryPickerController(this.props);

  render() {
    return (
      <AvatarHistoryPickerView
        {...this.props}
        styles={styles}
        controller={this.controller}
      />
    );
  }
}
