import { clientController } from "../../../cdm/controllers/client-controller";
import { action, computed, observable } from "mobx";
import { initSetup } from "../../../custom/mcb/config/setup/init-setup";
import { stateController } from "../../../cdm/controllers/state-controller";
import { UIText } from "../../../config/lang-config";
import { capitalize, isEmpty } from "../../../utils/helpers";
import { formService } from "../../../cdm/services/form-service";

export class SetupController {
  @observable loading = true;
  @observable currentSetupKey = 0; // Variable max
  @observable currentSetupPage = 0; // Max fixed 3
  @observable setup = initSetup(stateController.initSetupMode);
  @observable setupStore = {};
  @observable nextDisabled = false;

  @computed get client() {
    return clientController.client;
  }

  @computed get setups() {
    return this.setup.setupList;
  }

  @computed get currentSetup() {
    if (typeof this.selectedSetups[this.currentSetupKey] === "function") {
      return this.selectedSetups[this.currentSetupKey]();
    }
    if (typeof this.selectedSetups[this.currentSetupKey] === "object") {
      return this.selectedSetups[this.currentSetupKey];
    }
    return {};
  }

  @computed get currentForm() {
    return this.currentSetup.form;
  }

  @computed get selectedSetups() {
    return this.setups.filter(setup => setup.checked);
  }

  @computed get nextVisible() {
    return (
      this.selectedSetups.length > 0 &&
      (this.currentSetupPage === 0 ||
        (this.currentSetupPage === 1 &&
          this.currentSetupKey <= this.selectedSetups.length)) // + 1
    );
  }

  @computed get backVisible() {
    return !this.currentSetup.backDisabled;
  }

  @computed get backIcon() {
    return this.currentSetupPage === 0 ||
      (this.setup.skipSelect && this.currentSetupPage === 1)
      ? this.setup.rootBack && this.setup.rootBack.icon
      : null;
  }

  @computed get showFieldTitle() {
    return this.setup.showFieldTitle;
  }

  constructor(props) {
    this.props = props;
    if (!Array.isArray(this.setups))
      return this.props.navigation.navigate("Root");

    stateController.currentScreen = "Setup";

    this.methodProps = {
      navigation: this.props.navigation,
      resetSetup: this.resetSetup
    };

    setTimeout(this.resetSetup);
  }

  _setupLoading = () => {
    stateController.showPopup({
      title: this.currentSetup.title || capitalize(UIText.setup),
      content: UIText.pleaseWait
    });
  };

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: this.currentSetup.title || capitalize(UIText.setup),
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true,
      contentAlign: err.contentAlign
    });
  };

  _getForms = setups => {
    if (!Array.isArray(setups)) return;
    for (let setup of setups) {
      if (typeof setup === "function") {
        if (isEmpty(this.currentSetup) || this.currentSetup.form.length === 0)
          return setTimeout(this.resetSetup, 500);
      } else {
        if (isEmpty(setup) || setup.form.length === 0)
          return setTimeout(this.resetSetup, 500);
      }
    }
    return (this.loading = false);
  };

  next = () => {
    const advance = () => {
      if (
        this.currentSetupPage === 0 ||
        this.currentSetupKey === this.selectedSetups.length - 1
      ) {
        if (
          this.setup.skipFinish &&
          this.currentSetupKey === this.selectedSetups.length - 1
        ) {
          return this.finishSetup();
        }
        this.currentSetupPage++;
      } else {
        this.currentSetupKey++;
      }
      if (this.currentSetup.additionalFormProcessors) {
        for (const process of this.currentSetup.additionalFormProcessors) {
          process(this.currentForm);
        }
      }
      this.scrollToTop();
    };

    this.nextDisabled = true;

    setTimeout(() => {
      const setupPage = this.currentSetupPage === 1 && this.currentSetup;
      if (setupPage && setupPage.onSubmit) {
        return this.onFormSubmit(setupPage)
          .then(advance)
          .catch(err => {
            this._showError(err);
            console.warn(err);
            this.scrollToErrorField();
          })
          .finally(() => (this.nextDisabled = false));
      }
      this.nextDisabled = false;
      return advance();
    });
  };

  back = () => {
    if (
      this.currentSetupPage === 0 ||
      (this.setup.skipSelect && this.currentSetupKey === 0)
    ) {
      this.setup.rootBack.onPress &&
        this.setup.rootBack.onPress(this.methodProps);
      return true;
    }
    if (this.currentSetupPage === 2 || this.currentSetupKey === 0) {
      this.currentSetupPage--;
      this.scrollToTop();
      return true;
    }
    if (this.currentSetupKey <= this.selectedSetups.length - 1) {
      this.currentSetupKey--;
      this.scrollToTop();
      return true;
    }
  };

  selectSetup = key => {
    this.setups.find(s => s.key === key).checked = true;
  };

  deselectSetup = key => {
    this.setups.find(s => s.key === key).checked = false;
  };

  skipSelect = () => {
    for (let setup of this.setups) {
      setup.checked = true;
    }
    this.currentSetupPage = 1;
  };

  onFieldChange = action((field, event) => {
    if (!event) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      field.value = event.nativeEvent.text;
  });

  onAvatarPickerChange = action((file, field) =>
    formService.onAvatarPickerChange(
      file,
      field,
      this.client.user.defaultMember.id,
      this.currentSetup.title || capitalize(UIText.setup)
    )
  );

  onFieldBlur = action((field, event) => {
    const setupPage = this.currentSetup;
    if (setupPage && setupPage.onBlur) {
      setupPage.onBlur(field);
    }
  });

  onLinkPress = (link, event) => {
    console.log(link);
  };

  onFormSubmit = async setupPage => {
    this._setupLoading();
    if (setupPage.validate) {
      return setupPage
        .validate(setupPage.form)
        .then(form => setupPage.onSubmit(form, this.setupStore))
        .then(stateController.dismissPopup);
    }
    return setupPage
      .onSubmit(setupPage.form, this.setupStore)
      .then(stateController.dismissPopup);
  };

  getAvatarTitle = formService.getAvatarTitle;

  getAvatarColorId = () => "";

  // getAvatarColorId = formService.getAvatarColorId;

  scrollToErrorField = () => {
    const errorField = this.currentForm.find(f => !!f.errorMessage);
    const errorFieldName = errorField && errorField.name;

    if (!errorFieldName) return;

    return this.scrollToField && this.scrollToField(errorFieldName);
  };

  resetSetup = () => {
    console.log("reset");
    this.loading = true;
    this.setupStore = observable({});
    this.currentSetupPage = this.currentSetupKey = 0;
    this.setup = initSetup(stateController.initSetupMode);
    this._getForms(this.setups);
    if (this.setup.skipSelect && this.currentSetupPage === 0) this.skipSelect();
  };

  finishSetup = () => {
    // this.resetSetup();
    if (this.setup.onFinishSetup) {
      return this.setup.onFinishSetup(this.methodProps);
    }
    this.props.navigation.navigate("Root"); //WIP
  };

  setScrollPosCallback = method => (this.scrollTo = method);

  setScrollToTopCallback = method => (this.scrollToTop = method);

  setScrollToFieldCallback = method => (this.scrollToField = method);

  helpFocusNextField = (field, formFields, form) =>
    formService.helpFocusNextField(field, formFields, form, this.next);
}
