import { autorun, computed, observable, toJS } from "mobx";
import { stateController } from "../../../cdm/controllers/state-controller";
import { UIText } from "../../../config/lang-config";
import { mcbEndpointConfig } from "../../../custom/mcb/config/api-config";
import { apiService } from "../../../cdm/services/api-service";
import { fileService } from "../../../cdm/services/file-service";
import { capitalize, isEmpty } from "../../../utils/helpers";
import { serverConfig } from "../../../config/api-config";

export class CaregiverUploadController {
  @observable loading = true;
  @observable submitting = false;
  @observable submitted = false;

  @observable onboarding;
  @observable onboardingUpload;
  @observable profileData = {};

  @observable files = [];
  @observable filePreviews = {};
  @observable dropInFrame = false;
  @observable dropInTarget = false;
  fileTypes = ["PDF", "DOCX", "DOC", "JPG", "JPEG", "PNG"];

  @computed get token() {
    return stateController.viewCaregiverUploadState.token;
  }
  @computed get onboardingId() {
    return stateController.viewCaregiverUploadState.onboardingId;
  }
  @computed get mediaTypes() {
    return fileService.getMediaTypes(this.fileTypes);
  }
  @computed get buttonDisabled() {
    return isEmpty(this.files) || this.submitting || this.submitted;
  }

  componentDidMount() {
    stateController.currentScreen = "CaregiverUpload";
  }

  componentWillUnmount() {
    stateController.clearCaregiverUploadState();
  }

  constructor(props) {
    this.props = props;
    this._initialize();
    autorun(this.autoGenPreview);
  }

  _showError = (err, kickout) => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.title,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: () =>
        stateController
          .dismissPopup()
          .then(() => kickout && this.props.navigation.navigate("Login"))
    });
  };

  _initialize = () => this.validateOnboardingUpload();

  validateOnboardingUpload = async () => {
    this.loading = true;
    const initError = () =>
      stateController.showPopup({
        title: UIText.title,
        content: UIText.caregiverUploadInvalidLink,
        leftButtonText: UIText.generalConfirm,
        leftButtonPress: () =>
          stateController
            .dismissPopup()
            .then(() => this.props.navigation.navigate("Login"))
      });
    if (!this.onboardingId || !this.token) return initError();
    return apiService
      .async("POST", {
        endpoint: mcbEndpointConfig.caregiver_upload_validate_data,
        data: {
          onboardingId: this.onboardingId,
          token: this.token
        }
      })
      .then(response => response.data)
      .then(
        ({ onboarding, onboardingUpload, validationResult, profileData }) => {
          const valid = (validationResult || [])[0];
          if (!valid) return initError();
          this.onboarding = onboarding;
          this.onboardingUpload = onboardingUpload;
          this.profileData = profileData;
          this.submitted = onboardingUpload.uploadStatus === "uploaded";
          return (this.loading = false);
        }
      );
  };

  setDropInFrame = () => (this.dropInFrame = true);

  unsetDropInFrame = () => (this.dropInFrame = false);

  setDropInTarget = () => (this.dropInTarget = true);

  unsetDropInTarget = () => (this.dropInTarget = false);

  handleFileChange = (file, event) => {
    this.unsetDropInFrame();
    this.unsetDropInTarget();
    const files = Array.from(file);
    if (Array.isArray(files) && files.length > 0) {
      for (const file of files) {
        if (!this.mediaTypes.includes(file.type)) continue;
        !this.files.includes(file) && this.files.push(file);
      }
    } else if (!isEmpty(file)) {
      !this.files.includes(file) && this.files.push(file);
    }
  };

  handleRemoveFile = file =>
    this.files.includes(file) && this.files.remove(file);

  autoGenPreview = () => {
    this.files.forEach(file => {
      if (this.filePreviews[file.name]) return;
      if (!file.type.match(/image/g)) return;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.filePreviews[file.name] = fileReader.result;
      });
      fileReader.readAsDataURL(file);
    });
  };

  execSubmit = async () => {
    this.submitting = true;
    const data = new FormData();
    data.append("onboardingId", this.onboardingId);
    data.append("token", this.token);
    toJS(this.files).forEach(file => data.append("file", file, file.name));
    return stateController
      .showPopup({
        title: UIText.title,
        content: UIText.pleaseWait
      })
      .then(() =>
        apiService.async("POST", {
          headers: serverConfig.defaultHeaders,
          endpoint: mcbEndpointConfig.caregiver_upload,
          data
        })
      )
      .then(stateController.dismissPopup)
      .then(() =>
        stateController.showPopup({
          title: capitalize(UIText.success),
          content: UIText.caregiverInfoReqSuccess,
          leftButtonText: UIText.generalConfirm,
          leftButtonPress: stateController.dismissPopup,
          dismissOnBackPress: true
        })
      )
      .then(() => (this.submitted = true))
      .catch(this._showError)
      .finally(() => (this.submitting = false));
  };
}
