import { styles } from "../styles";
import { computed, decorate } from "mobx";
import { responsive } from "../../../config/style-configs/responsive";

export const extraStyles = {
  container: {
    flexDirection: "row",
    alignItems: "flex-start",
    padding: 20,
    paddingTop: 0
  },
  get header() {
    return {
      minHeight: responsive.deviceDimension.isDesktop ? 150 : 100,
      maxHeight: responsive.deviceDimension.isDesktop ? 150 : 100
    };
  },
  split: {
    flex: 1,
    width: "100%",
    alignItems: "center"
  },
  get calendlyContainer() {
    return {
      flex: 1,
      width: "100%",
      minHeight: responsive.deviceDimension.height * 0.8
    };
  },
  calendlyFrame: {
    flex: 1
  }
};

decorate(styles, {
  calendlyContainer: computed
});
export { styles };
