import React from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import { Observer, observer } from "mobx-react";
import { theme } from "../../../../config/style-configs/theme";
import { getDisplayNameEng, isEmpty } from "../../../../utils/helpers";
import { ListRefreshButtonWeb } from "../../../../components/ListRefreshButtonWeb";
import { Button, CheckBox, Text } from "react-native-elements";
import { UIText } from "../../../../config/lang-config";
import { FormField } from "../../../../components/FormField";
import { RatingReviewSmallCard } from "../RatingReviewSmallCard";
import { RatingReviewInteractiveStar } from "../RatingReviewInteractiveStar";
import { observable } from "mobx";
import { getCurrentStarIndex } from "../RatingReviewInteractiveStar/view";
import Icon from "react-native-vector-icons/MaterialIcons";
import { ThemedButton } from "../../../../components/ThemedButton";

@observer
class AdminRatingReviewPageView extends React.Component {
  @observable adminRatingStarHovers = [false, false, false, false, false];

  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentWillUnmount() {
    this.controller.componentWillUnmount();
  }

  renderRatingReviewsByCareCircle = () => {
    return (
      <View style={this.props.styles.scrollViewContent}>
        <View style={this.props.styles.buttonContainer}>
          <Button
            containerStyle={this.props.styles.buttonTitleContainer}
            type="clear"
            title={UIText.adminCentreRatingReviewGivenBy(
              getDisplayNameEng(this.controller.group.profile),
              this.controller.group.groupTypeName
            )}
            titleStyle={this.props.styles.buttonTitle}
            onPress={() => {}}
          />
          <ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />
        </View>
        <FormField
          field={this.controller.targetMemberPickerField}
          onPickerChange={this.controller.onTargetMemberChange}
        />
        <View style={this.props.styles.ratingReviewContainer}>
          {this.controller.ratingLoading ? (
            <View style={this.props.styles.loading}>
              <ActivityIndicator size="large" color={theme.color} />
            </View>
          ) : (
            <>
              {this.renderAdminRating(this.controller.targetMemberAdminRating)}
              {this.renderTestimonials(
                this.controller.targetMemberTestimonials
              )}
              {this.renderRatingReviews(
                this.controller.targetMemberRatingReviews
              )}
            </>
          )}
        </View>
      </View>
    );
  };

  renderRatingReviewsByRoleUser = () => {
    return (
      <View style={this.props.styles.scrollViewContent}>
        <View style={this.props.styles.buttonContainer}>
          <Button
            containerStyle={this.props.styles.buttonTitleContainer}
            type="clear"
            title={UIText.adminCentreRatingReviewOf(
              getDisplayNameEng(this.controller.group.profile),
              this.controller.group.groupTypeName
            )}
            titleStyle={this.props.styles.buttonTitle}
            onPress={() => {}}
          />
          <ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />
        </View>
        <Text
          style={{
            ...this.props.styles.inputTitle,
            padding: 10
          }}
        >
          {UIText.adminCentreRatingReviewTargetMember}
        </Text>
        <View style={this.props.styles.ratingReviewContainer}>
          {this.controller.ratingLoading ? (
            <View style={this.props.styles.loading}>
              <ActivityIndicator size="large" color={theme.color} />
            </View>
          ) : (
            <>
              {this.renderAdminRating(this.controller.targetMemberAdminRating)}
              {this.renderTestimonials(
                this.controller.targetMemberTestimonials
              )}
              {this.renderRatingReviews(
                this.controller.targetMemberRatingReviews
              )}
            </>
          )}
        </View>
      </View>
    );
  };

  renderStar = (index, ratingReview, size) => (
    <RatingReviewInteractiveStar
      key={index}
      index={index}
      size={size}
      ratingReview={ratingReview}
      hoverState={this.adminRatingStarHovers}
      setHoverState={(index, isHover) =>
        (this.adminRatingStarHovers[index] = isHover)
      }
      onRatingChange={this.controller.onAdminRatingChange}
    />
  );

  renderIsPublishedCheckbox = (isPublished, setIsPublish) => (
    <CheckBox
      containerStyle={this.props.styles.adminRatingIsPublishedCheckbox}
      title={UIText.ratingReviewIsPublished}
      checkedIcon={<Icon name="check-box" size={24} color="black" />}
      uncheckedIcon={
        <Icon name="check-box-outline-blank" size={24} color="black" />
      }
      checked={!!isPublished}
      checkedColor={theme.color}
      onPress={setIsPublish}
    />
  );

  renderAdminRating = adminRating => {
    if (isEmpty(adminRating)) return null;
    return (
      <Observer>
        {() => (
          <View style={this.props.styles.adminRating}>
            <View style={this.props.styles.adminRatingToolbar}>
              <Text style={this.props.styles.inputTitle}>
                {UIText.adminCentreSetAdminRating}:
              </Text>
              {this.renderIsPublishedCheckbox(
                adminRating.isPublished,
                this.controller.onSetAdminRatingIsPublished
              )}
            </View>
            <View style={this.props.styles.starRatings}>
              {Array.from(new Array(5)).map((n, i) =>
                this.renderStar(i, this.controller.targetMemberAdminRating)
              )}
            </View>
            {(!!this.controller.targetMemberAdminRating.rating ||
              !isEmpty(this.adminRatingStarHovers.filter(Boolean))) && (
              <Text style={this.props.styles.starRatingDescription}>
                "
                {
                  UIText.ratingReviewRatingDescriptions[
                    getCurrentStarIndex(
                      this.controller.targetMemberAdminRating,
                      this.adminRatingStarHovers
                    )
                  ]
                }
                "
              </Text>
            )}
          </View>
        )}
      </Observer>
    );
  };

  renderTestimonials = testimonials => {
    if (isEmpty(this.controller.targetMember)) return null;
    return (
      <View style={this.props.styles.testimonials}>
        <View style={this.props.styles.testimonialToolbar}>
          <Text style={this.props.styles.inputTitle}>
            {UIText.testimonials}:
          </Text>
          <Button
            icon={<Icon size={20} name="add" color={theme.color} />}
            type="clear"
            onPress={this.controller.onAddTestimonial}
          />
        </View>
        {isEmpty(testimonials) && (
          <View style={this.props.styles.buttonContainer}>
            <View style={this.props.styles.buttonReminder}>
              <Text style={this.props.styles.errorMessage}>
                {UIText.adminCentreNoTestimonials}
              </Text>
            </View>
          </View>
        )}
        {testimonials.map(testimonial => (
          <Observer key={testimonial.id}>
            {() => (
              <View style={this.props.styles.testimonial}>
                {testimonial.createTime ? (
                  <View style={this.props.styles.testimonialContent}>
                    <View style={this.props.styles.testimonialToolbar}>
                      <Text style={this.props.styles.testimonialTitle}>
                        {testimonial.name}:
                      </Text>
                      {this.renderIsPublishedCheckbox(
                        testimonial.isPublished,
                        () =>
                          this.controller.onSetTestimonialIsPublished(
                            testimonial.id
                          )
                      )}
                    </View>
                    <Text style={this.props.styles.testimonialContentText}>
                      {testimonial.review}
                    </Text>
                    <Text style={this.props.styles.testimonialDate}>
                      {UIText.ratingReviewSubmissionDate}&nbsp;
                      {new Date(
                        testimonial.submissionDateLong
                      ).toLocaleString()}
                    </Text>
                  </View>
                ) : (
                  <>
                    <FormField
                      fieldStyle={this.props.styles.testimonialInput}
                      field={this.controller.makeTestimonialNameField(
                        testimonial
                      )}
                      inputValue={testimonial.name}
                      onInputChange={e =>
                        this.controller.onTestimonialNameChange(
                          e,
                          testimonial.id
                        )
                      }
                    />
                    <FormField
                      fieldStyle={this.props.styles.testimonialInput}
                      field={this.controller.makeTestimonialField(testimonial)}
                      inputValue={testimonial.review}
                      onInputChange={e =>
                        this.controller.onTestimonialChange(e, testimonial.id)
                      }
                    />
                    <View style={this.props.styles.testimonialButton}>
                      <ThemedButton
                        title={UIText.generalCancel}
                        onPress={() =>
                          this.controller.onTestimonialRemove(testimonial.id)
                        }
                      />
                      <ThemedButton
                        title={UIText.generalSubmit}
                        loading={
                          this.controller.testimonialsLoading[testimonial.id]
                        }
                        disabled={
                          this.controller.testimonialsLoading[testimonial.id] ||
                          isEmpty(testimonial.review) ||
                          isEmpty(testimonial.name)
                        }
                        onPress={() =>
                          this.controller.onTestimonialSave(testimonial.id)
                        }
                      />
                    </View>
                  </>
                )}
              </View>
            )}
          </Observer>
        ))}
      </View>
    );
  };

  renderRatingReviews = ratingReviews => {
    if (isEmpty(this.controller.targetMember)) return null;
    return (
      <View style={this.props.styles.ratingReviews}>
        <Text style={this.props.styles.inputTitle}>
          {UIText.ratingReviews}:
        </Text>
        {isEmpty(ratingReviews) && (
          <View style={this.props.styles.buttonContainer}>
            <View style={this.props.styles.buttonReminder}>
              <Text style={this.props.styles.errorMessage}>
                {UIText.adminCentreNoRatingReviews}
              </Text>
            </View>
          </View>
        )}
        {(ratingReviews || []).map(ratingReview => (
          <Observer key={ratingReview.id}>
            {() => (
              <RatingReviewSmallCard
                showPrivateReview
                showReviewerGroupName={!this.controller.viewByCareCircle}
                ratingReview={ratingReview}
                onSetIsPublishedLoading={
                  this.controller.ratingReviewsLoading[ratingReview.id]
                }
                onSetIsPublished={this.controller.onSetRatingIsPublished}
              />
            )}
          </Observer>
        ))}
      </View>
    );
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        {this.controller.loading ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          <ScrollView
            ref={ref => (this.scrollView = ref)}
            contentContainerStyle={this.props.styles.scrollView}
          >
            {this.controller.viewByCareCircle
              ? this.renderRatingReviewsByCareCircle()
              : this.renderRatingReviewsByRoleUser()}
          </ScrollView>
        )}
      </View>
    );
  }
}

export { AdminRatingReviewPageView };
