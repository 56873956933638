import { common } from "../../../../config/style-configs/common";
import { computed, decorate } from "mobx";
import { responsive } from "../../../../config/style-configs/responsive";

export const styles = {
  style: {
    flex: 1
  },
  heading: {
    height: "auto",
    padding: 20,
    alignItems: "flex-start"
  },
  headingIcons: {
    position: "absolute",
    top: 10,
    right: 15,
    zIndex: 3
  },
  pickerContainer: common.headingPickerContainer,
  pickerLabelContainer: common.headingPickerLabelContainer,
  get pickerLabel() {
    return {
      ...common.headingPickerLabel,
      ...responsive.styles.headingPickerLabel
    };
  },
  picker: common.headingPicker,
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  scrollViewCard: common.scrollViewCard,
  card: common.card,
  cardContent: common.cardChildrenCompact,
  cardContentLarge: common.cardChildrenTextLarge,
  cardContentMedium: common.cardChildrenTextMedium,
  cardContentSmall: common.cardChildrenTextSmall,
  errorMessage: common.containerErrorMessage,
  loading: common.loading,
  buttonContainer: common.listButtonContainer,
  buttonReminder: common.listButtonReminderContainer,
  button: {}
};

decorate(styles, {
  pickerLabel: computed
});
