import { apiController } from "../../../../cdm/controllers/api-controller";
import {
  contextReject,
  getDisplayNameEng,
  isEmpty,
  isEqual,
  safeParseJSON
} from "../../../../utils/helpers";
import { toJS } from "mobx";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { apiService } from "../../../../cdm/services/api-service";
import { mcbEndpointConfig } from "../../config/api-config";
import { topicTypeIds } from "../../../../config/variable-config";
import { paginationService } from "../../../../cdm/services/pagination-service";
import { FCFilter } from "../../utils/helper";
import vendorOnboarding from "./vendor-onboarding";
import caregiverOnboarding from "./caregiver-onboarding";
import { formService } from "../../../../cdm/services/form-service";
import { endpointConfig, serverConfig } from "../../../../config/api-config";
import { theme } from "../../../../config/style-configs/theme";

export const adminCentreInjectLegacyRecruitForm = vendorOnboarding;
export const adminCentreInjectNewRecruitForm = caregiverOnboarding;

export const adminCentreSortByDate = (groupTypeId, sortBy) =>
  setTimeout(
    () =>
      (stateController.viewAdminCentreState.sortBy[groupTypeId] =
        sortBy === "dateAscend"
          ? "dateDescend"
          : sortBy === "dateDescend"
          ? "dateAscend"
          : "dateAscend")
  );

export const adminCentreSortByAlpha = (groupTypeId, sortBy) =>
  setTimeout(
    () =>
      (stateController.viewAdminCentreState.sortBy[groupTypeId] =
        sortBy === "alphaAscend"
          ? "alphaDescend"
          : sortBy === "alphaDescend"
          ? "alphaAscend"
          : "alphaAscend")
  );

export const adminCentreSortCards = (a, b, sortBy) => {
  const dateA = a.group && new Date(a.group.createTime).getTime();
  const dateB = b.group && new Date(b.group.createTime).getTime();

  const nameA = a.profile && getDisplayNameEng(a.profile).toLowerCase();
  const nameB = b.profile && getDisplayNameEng(b.profile).toLowerCase();

  return sortBy === "dateAscend"
    ? -(dateA - dateB)
    : sortBy === "dateDescend"
    ? dateA - dateB
    : sortBy === "alphaAscend"
    ? nameA > nameB
      ? 1
      : -1
    : sortBy === "alphaDescend"
    ? nameA < nameB
      ? 1
      : -1
    : 0;
};

export const adminCentreGetAllGroups = async (
  groupTypeId,
  resetPagingStatus
) => {
  return apiService
    .async("POST", {
      endpoint: mcbEndpointConfig.get_all_admin_centre_groups,
      data: {
        groupTypeId: groupTypeId,
        startTime: new Date("2019/06/01").getTime()
      }
    })
    .then(response => {
      const dataArray = response.data;
      resetPagingStatus();
      return dataArray || [];
    })
    .then(data => [data, groupTypeId]);
};

export const adminCentreGetNextGroups = (
  groupTypeId,
  pagingStatus,
  pageSortBy,
  pagingSize,
  isDesc,
  isCaregiver,
  isApprovedCaregiver,
  setPagingStatus
) =>
  paginationService
    .query("POST", {
      endpoint:
        pagingStatus.next ||
        mcbEndpointConfig.get_all_admin_centre_groups_paged(
          pageSortBy,
          "",
          pagingSize,
          !isDesc
        ),
      data: {
        groupTypeId,
        startTime: new Date("2019/06/01").getTime(),
        ...(isCaregiver && { isApprovedCaregiver })
      }
    })
    .then(response => {
      const dataArray = (response.data || {}).data;
      const links = (response.data || {}).links;
      setPagingStatus(links && links.next);
      return dataArray || [];
    })
    .then(data => [data, groupTypeId]);

export const adminCentreProcessGroups = (data, groupTypeId) => {
  if (isEmpty(data)) return [[], groupTypeId];
  data = data
    .map(c => ({
      user: apiController.parser.parseUser(c),
      group: {
        ...apiController.parser.parseGroup(c),
        subscription: {
          ...apiController.parser.parseSubscription(
            c["planSubscriptionResponseDTO"]
          )
        }
      },
      member: apiController.parser.parseMember(c),
      profile: apiController.parser.parseProfile(c["profileResponseDTO"]),
      topic: c.topic || {},
      onboarding: c.onboarding || {}
    }))
    .filter(FCFilter); // Felix domain;

  return [data, groupTypeId];
};

export const adminCentreGetOnboardingTopics = (groups, groupTypeId) =>
  apiController
    .getTopicsByTypeId(topicTypeIds.onboarding, false, false, true)
    .then(async topics => {
      const applyTopic = topic => {
        if (!topic) return;
        topic = apiController.parser.parseTopic(topic);
        const { data } = topic;
        const topicData = safeParseJSON(data);
        if (!topicData) return;
        topic.data = topicData;
        const profileId = topicData.profileId;
        const card = groups.find(card => card.profile.id === profileId);
        if (!card) return;
        return (card.topic = toJS(topic));
      };
      await Promise.all(topics.map(applyTopic)).catch(contextReject);
      return [groups, groupTypeId];
    });

export const adminCentreGetNewOnboardingData = async ([
  groups,
  groupTypeIds
]) => {
  const applyData = async card => {
    const { profile } = card;
    if (isEmpty(profile)) return;
    return apiController
      .getAdminOnboardingDataForProfile(profile.id)
      .then(onboarding => (card.onboarding = onboarding));
  };
  await Promise.all(groups.map(applyData)).catch(contextReject);
  return [groups, groupTypeIds];
};

export const adminCentreOnboardingFormAutoFieldControl = (
  form,
  monitor,
  onboardingDataCopy
) => {
  const approvalCheckbox = form.find(
    field => field.name === "approvalOfCandidateConfirmedByReference"
  );
  const updateScreeningStatus = form.find(
    field => field.name === "updateScreeningStatus"
  );
  if (!approvalCheckbox || !updateScreeningStatus || approvalCheckbox.readonly)
    return;
  const changed = Object.keys(monitor.formData).filter(
    key => !isEqual(monitor.formData[key], onboardingDataCopy[key])
  );
  approvalCheckbox.hidden = changed.includes("updateScreeningStatus");
  if (changed.includes("updateScreeningStatus")) {
    approvalCheckbox.value = false;
    approvalCheckbox.hidden = true;
  } else {
    approvalCheckbox.hidden = false;
  }
  updateScreeningStatus.readonly =
    updateScreeningStatus.value.match(/Completed/g) ||
    updateScreeningStatus.value.match(/Profile\srequested/g) ||
    !!approvalCheckbox.value;
};

export const adminCentreOnboardingHandleProfileChange = async (
  card,
  monitor,
  changedFormData,
  onboardingDataCopy,
  onboardingProfileData,
  refreshProfile
) => {
  const profileScreenProfile = stateController.viewProfileState.getProfile();
  const profileScreenChanged = stateController.isProfileScreenModified();
  const profileFieldDataKeys = changedFormData.filter(key =>
    onboardingProfileData.hasOwnProperty(key)
  );
  if (!isEmpty(profileFieldDataKeys) || profileScreenChanged) {
    await refreshProfile();
    const profileScreenData = formService.disassembleFormData(
      profileScreenProfile,
      {
        displayName: true
      }
    );
    const data = {
      ...card.profile.data,
      ...profileScreenData
    };
    for (const key of profileFieldDataKeys) {
      data[key] = monitor.formData[key];
    }
    await apiService
      .async("PATCH", {
        endpoint: endpointConfig.profile_by_id(card.profile.id),
        data: { data: JSON.stringify(data) }
      })
      .catch(contextReject);
  }
  return Promise.resolve();
};

export const adminCentreOnboardingHandleSaveButton = (
  screenId,
  saveButtonState,
  monitor,
  onboardingDataCopy,
  execSave
) => {
  // console.log(1, isEqual(monitor.formData, onboardingDataCopy));
  // console.log(2, !stateController.isProfileScreenModified());
  if (saveButtonState.loading) return;
  if (
    isEqual(monitor.formData, onboardingDataCopy) &&
    !stateController.isProfileScreenModified()
  )
    return;
  stateController.viewProfileState.rightButtonOverride[screenId].loading = true;
  const doneLoading = () =>
    (stateController.viewProfileState.rightButtonOverride[
      screenId
    ].loading = false);
  const save = () => execSave().finally(doneLoading);
  return setTimeout(save);
};

export const adminCentreCheckDirectProfileEntry = async (
  param,
  groupTypeId,
  getAllGroups,
  pressCard
) => {
  await getAllGroups().catch(contextReject);
  const cards = stateController.viewAdminCentreState.cards[groupTypeId];
  const card = cards.find(c => c.profile && c.profile.id === Number(param));
  if (isEmpty(card)) return;
  return pressCard(null, card);
};

export const renderJobReferenceResponseQuestions = async profileData => {
  return apiService
    .async("POST", {
      data: profileData,
      endpoint: mcbEndpointConfig.caregiver_job_reference_response_questions,
      headers: serverConfig.defaultHeaders
    })
    .then(response => response.data || []);
};

export const renderQuestionnaireResponseQuestions = async () => {
  return apiService
    .async("GET", {
      endpoint: mcbEndpointConfig.get_questionnaire_text_questions,
      headers: serverConfig.defaultHeaders
    })
    .then(response => response.data || []);
};

export const adminCentrePrepProfileScreen = (
  card,
  screenId,
  groupTypeId,
  navigateBack
) => {
  // apiService.deepRenew = true;
  stateController.viewProfileState.autoEdit = true;
  stateController.viewProfileState.backButtonOverride[screenId] = {
    includeEdit: true,
    icon: "arrow-back",
    handlePress: () => {
      // apiService.deepRenew = false;
      navigateBack && navigateBack();
    }
  };
  stateController.viewProfileState.titleOverride[screenId] = getDisplayNameEng(
    card.profile.data
  );
};

export const renderResponsesHtml = (
  questions,
  responses
) => `<div style="box-sizing: border-box; padding: 15px; white-space: pre-wrap; font-size: ${
  theme.FONT_SIZE_MEDIUM
}px; font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif">
${questions
  .map(
    (question, i) =>
      `<span style="display: block; color: ${
        theme.color
      }; margin-bottom: 5px">${question.text}</span><span>${
        typeof responses[i] === "string"
          ? responses[i]
          : (responses[i] || {}).text || ""
      }</span>`
  )
  .join("<br /><br />")}
</div>`;
