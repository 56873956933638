import { common } from "../../config/style-configs/common";
import { theme } from "../../config/style-configs/theme";
import { responsive } from "../../config/style-configs/responsive";
import { computed, decorate } from "mobx";

const disabledColor = "#999";

export const styles = {
  style: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  additionalCheckboxContainer: {
    flexShrink: 0,
    alignItems: "center"
  },
  additionalCheckbox: {
    paddingVertical: 0,
    paddingHorizontal: 10,
    margin: 0,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "none",
    borderWidth: 0
  },
  additionalCheckboxTitle: {
    marginHorizontal: 5,
    fontSize: theme.FONT_SIZE_MEDIUM,
    fontWeight: "600",
    color: theme.color
  },
  get avatarButtonTitle() {
    return {
      marginLeft: 5,
      color: theme.color,
      fontSize: responsive.styles.formAvatarButtonTitleFontSize
    };
  },
  avatarContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 10
  },
  avatar: {},
  avatarHistory: {
    flex: 1,
    flexWrap: "wrap",
    marginLeft: 25
  },
  field: {
    ...common.formField,
    flex: 1
  },
  fieldLabel: {
    width: "100%",
    color: theme.color,
    fontSize: theme.FONT_SIZE_MEDIUM,
    marginHorizontal: 10,
    marginBottom: 10
  },
  input: common.input,
  picker: common.picker,
  pickerLabel: common.pickerLabel,
  pickerErrorMessage: {
    fontSize: theme.FONT_SIZE_TINY,
    color: "#ff190c",
    marginHorizontal: 12,
    marginVertical: 5
  },
  checkbox: {
    margin: 0,
    padding: 5
  },
  checkboxDisabled: {
    backgroundColor: "none",
    borderColor: "#ffffff00"
  },
  checkboxDisabledText: {
    color: disabledColor
  },
  button: {},
  divider: {
    width: "100%",
    marginTop: 20
  },
  link: {
    marginHorizontal: 10,
    ...common.linkBlue
  },
  linkHeavy: {
    marginHorizontal: 10,
    fontWeight: "600",
    fontSize: theme.FONT_SIZE_MEDIUM,
    ...common.linkBlue
  },
  dateTime: {
    maxWidth: 300
  },
  disabledColor
};

decorate(styles, {
  avatarButtonTitle: computed
});
