import { clientController } from "../../../../../../cdm/controllers/client-controller";
import { stateController } from "../../../../../../cdm/controllers/state-controller";
import { getUTCOffsetMilli, isEmpty } from "../../../../../../utils/helpers";
import {
  updateTopicActorMembers,
  updateTopicTime
} from "../../../../../../cdm/lib/topic-utilities";
import { apiService } from "../../../../../../cdm/services/api-service";
import { endpointConfig } from "../../../../../../config/api-config";
import { observable } from "mobx";
import { issueTimeAssigneeForm } from "./form";
import { mcbEndpointConfig } from "../../../api-config";
import { UIText } from "../../../../../../config/lang-config";

const sendIssueReceiverNotification = async (groupId, topicId) => {
  return stateController
    .showPopup({
      title: "New Issue",
      content: UIText.pleaseWait
    })
    .then(() =>
      apiService.async("GET", {
        endpoint: mcbEndpointConfig.send_issue_notification(groupId, topicId)
      })
    );
};

export const issueTimeAssigneeSetup = () => {
  const group = clientController.findGroupById(stateController.viewGroupId);
  const timezone = ((group.profile || {}).data || {}).timezone;
  const title = "New Issue";

  return {
    title: title,
    heading: "Issue options",
    checkboxTitle: title,
    form: observable(issueTimeAssigneeForm(group)),
    onSubmit: async (form, store) => {
      if (!form) {
        return Promise.reject({ message: "Please review your entries." });
      }

      const topic = store.topic;
      const errorMessage = "Previous step was unsuccessful.";
      if (isEmpty(topic))
        return Promise.reject(`${errorMessage}[issue_topic_non_exist]`);

      const assigneeMemberIds = form
        .filter(field => field.set === "assignee" && field.value)
        .map(field => field.name);

      let startTime = form.find(field => field.name === "startTime");
      let endTime = form.find(field => field.name === "endTime");
      startTime =
        startTime &&
        startTime.value &&
        new Date(
          new Date(startTime.value).getTime() -
            getUTCOffsetMilli(timezone, new Date(startTime.value))
        );
      endTime =
        endTime &&
        endTime.value &&
        new Date(
          new Date(endTime.value).getTime() -
            getUTCOffsetMilli(timezone, new Date(endTime.value))
        );

      return apiService
        .async("POST", {
          endpoint: endpointConfig.create_topic,
          data: {
            currentGroupId: group.id,
            topic
          }
        })
        .then(async response => {
          const topic = response.data;
          if (!topic)
            return Promise.reject({
              message:
                "Something happened and setup was not successful. [Empty topic data]"
            });

          const topicId = topic.id;

          if (assigneeMemberIds.length > 0) {
            await updateTopicActorMembers(
              group,
              topic,
              assigneeMemberIds,
              "assigned"
            ).catch(Promise.reject);
          }

          if (startTime || endTime) {
            await updateTopicTime(topic, startTime, endTime).catch(
              Promise.reject
            );
          }

          return topicId;
        })
        .then(topicId => sendIssueReceiverNotification(group.id, topicId))
        .catch(Promise.reject);
    }
  };
};
