import React from "react";
import { HeaderView } from "./view";
import { HeaderController } from "./controller";
import { styles } from "./styles";

export class Header extends React.Component {
  controller = new HeaderController(this.props);

  render() {
    return (
      <HeaderView
        {...this.props}
        styles={styles}
        controller={this.controller}
      />
    );
  }
}
