import { styles } from "../styles";

export const extraStyles = {
  heading: {
    ...styles.questionHeader,
    textAlign: "center"
  },
  questionHeader: {
    ...styles.questionHeader,
    width: "100%",
    textAlign: "left"
  },
  get questionFields() {
    return {
      ...styles.questions,
      alignItems: "flex-start"
    };
  },
  button: {
    ...styles.button,
    marginBottom: 50
  }
};

export { styles };
