import React from "react";
import { View } from "react-native";
import { observer } from "mobx-react";
import { Badge, Button, Header, Icon } from "react-native-elements";
import { theme } from "../../config/style-configs/theme";
import { responsive } from "../../config/style-configs/responsive";

@observer
class HeaderView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  render() {
    this.rightComponentNativeLoading =
      this.props.rightIconLoading ||
      (!this.props.rightIcon &&
        !this.props.rightTitle &&
        this.controller.activity);
    this.rightComponentDedicatedLoading =
      this.controller.activity &&
      (!!this.props.rightIcon || !!this.props.rightTitle) &&
      !this.props.rightIconLoading;

    this.leftComponent =
      this.props.leftComponent ||
      (!this.controller.desktop &&
        this.props.leftIcon &&
        !this.controller.hideHamburger && (
          <View>
            <Button
              type="clear"
              icon={
                <Icon
                  name={this.props.leftIcon}
                  color={theme.color}
                  size={25}
                />
              }
              loading={this.props.leftIconLoading}
              loadingProps={{ color: theme.color }}
              containerStyle={this.props.styles.edgeComponent}
              onPress={
                this.props.handleLeftPress || this.controller.handleLeftPress
              }
            />
            {!this.props.handleLeftPress &&
              this.props.leftIcon === "menu" &&
              this.controller.hasUnread && (
                <Badge
                  status="error"
                  containerStyle={this.props.styles.leftIconBadge}
                  badgeStyle={this.props.styles.leftIconBadgeStyle}
                  onPress={this.controller.handleLeftPress}
                />
              )}
          </View>
        ));

    this.centerComponentVisible =
      responsive.deviceDimension.isDesktop || !this.props.rightComponentLong;
    this.centerComponent =
      this.centerComponentVisible &&
      (this.props.centerComponent || {
        icon: this.props.icon,
        text: this.props.title,
        // TODO: Center Component right now is not occupying all the available flex, need to fix.
        style: this.props.styles.centerComponentText
      });

    this.rightComponent = this.props.rightComponent || (
      <View style={this.props.styles.rightComponent}>
        {this.rightComponentDedicatedLoading && (
          <Button
            type="solid"
            loading={true}
            loadingProps={{ color: theme.color }}
            containerStyle={this.props.styles.edgeComponent}
            buttonStyle={{ backgroundColor: "#fff" }}
          />
        )}
        {this.props.handleSearchPress && !this.rightIconLoading && (
          <Button
            type="clear"
            icon={<Icon name="search" color={theme.color} size={25} />}
            onPress={this.props.handleSearchPress}
            containerStyle={this.props.styles.edgeComponent}
          />
        )}
        {!!this.props.rightIconBadgeCount && !this.props.rightIconLoading && (
          <Badge
            status="error"
            containerStyle={this.props.styles.rightIconBadge}
            value={this.props.rightIconBadgeCount}
            onPress={
              this.props.handleRightPress || this.controller.handleRightPress
            }
          />
        )}
        {(this.props.rightTitle ||
          this.props.rightIcon ||
          this.rightComponentNativeLoading) && (
          <Button
            type="clear"
            icon={
              this.props.rightIcon && (
                <Icon
                  name={this.props.rightIcon}
                  color={theme.color}
                  size={25}
                />
              )
            }
            title={this.props.rightTitle || null}
            titleStyle={this.props.styles.iconTitle}
            loading={this.rightComponentNativeLoading}
            loadingProps={{ color: theme.color }}
            containerStyle={this.props.styles.edgeComponent}
            onPress={
              !this.props.rightIconLoading
                ? this.props.handleRightPress ||
                  this.controller.handleRightPress
                : () => {}
            }
          />
        )}
      </View>
    );

    this.placement =
      responsive.deviceDimension.isDesktop && this.props.rightComponentLong
        ? "left"
        : this.props.placement;

    return (
      <Header
        placement={this.placement}
        leftComponent={this.leftComponent}
        centerComponent={this.centerComponent}
        rightComponent={this.rightComponent}
        containerStyle={this.props.styles.container}
        centerContainerStyle={this.props.rightComponentLong && { flex: 0 }}
        rightContainerStyle={this.props.rightComponentLong && { flex: 10 }}
      />
    );
  }
}

export { HeaderView };
