import { formService, TypeClassTemplate } from '../../../../../../cdm/services/form-service';
import { groupTypeIds, typeClassIds } from '../../../../../../config/variable-config';

let template = new TypeClassTemplate(typeClassIds.caregiverProfile);

export const cleanerForm = member => {
  if (!template || !template.form) return null;

  for (let field of template.form) {
    if (field.name === "firstName") {
      field.value = member.profile.data.firstName;
    }
    if (field.name === "lastName") {
      field.value = member.profile.data.lastName;
    }
    if (field.name === "email") {
      field.value = member.profile.data.email;
    }
    if (field.name === "phone") {
      field.value = member.profile.data.phone;
    }
    if (field.name === "requiredHourlyRate") {
      field.required = true;
    }
  }

  formService.hideFieldsByGroupTypeId(template.form, groupTypeIds.cleaner);
  return template.form;
};
