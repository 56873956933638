import { theme } from "../../config/style-configs/theme";

export const styles = {
  // style: {
  //   position: "absolute",
  //   width: "100%",
  //   height: "100%",
  //   backgroundColor: "rgba(0,0,0, 0.6)",
  //   left: 0,
  //   top: 0,
  //   zIndex: 10,
  //   marginTop: 43
  // },
  // wrapper: {
  //   flex: 1,
  //   width: "100%",
  //   maxWidth: 300,
  //   backgroundColor: "white",
  //   boxShadow: "0 15px 15px #333"
  // },
  // backdrop: {
  //   position: "absolute",
  //   height: "100%",
  //   width: "100%",
  //   zIndex: -1
  // }
  style: {
    overscrollBehavior: "contain",
    sidebar: {
      width: theme.sidebarWidth,
      backgroundColor: "white"
    }
  }
};
