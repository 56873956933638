import { Observer, observer } from "mobx-react";
import React from "react";
import { ActivityIndicator, ScrollView, Text, View } from "react-native";
import { Divider } from "react-native-elements";
import { Header } from "../../../components/Header";
import { UIText } from "../../../config/lang-config";
import { ThemedButton } from "../../../components/ThemedButton";
import { stripeElementsConfig } from "../../../config/api-config";
import Icon from "react-native-vector-icons/MaterialIcons";
import { theme } from "../../../config/style-configs/theme";
import { capitalize, getDisplayNameEng, isEmpty } from "../../../utils/helpers";
import { FormField } from "../../../components/FormField";
import { env, planMarketingNumber } from "../../../config/variable-config";
import { PriceBreakdownTable } from "../../../components/PriceBreakdownTable";
import { AndroidBackHandler } from "react-navigation-backhandler";
import { AnimatedHeaderButton } from "../../../components/AnimatedHeaderButton";
import { TouchableOpacityIOSFix } from "../../../components/TouchableOpacityIOSFix";
import { PlanPaymentForm } from "../../../components/PlanPaymentForm";

@observer
class PlanScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
    this.stripe = stripeElementsConfig;
    this.stripeUrl = `${this.stripe.https ? "https" : "http"}://${
      this.stripe.hostname
    }${this.stripe.port !== 443 ? ":" + this.stripe.port : ""}`;
    this.billingFormFields = [];
    this.planScrolled = false;
    this.controller.setScrollToTop(this.scrollToTop);
    this.controller.setBillingFormFocus(this.billingFormFocus);
  }

  getPlanIntervalString = plan => capitalize(plan.interval);

  getCurrentSubIsPromo = () =>
    !!this.controller.currentGroupSubscription.promoId;

  getCurrentPlanRemainingDays = plan =>
    !isEmpty(this.controller.currentGroupSubscription) &&
    !isEmpty(this.controller.currentGroupSubscription.plan) &&
    (this.getCurrentSubIsPromo() ||
      this.controller.currentGroupSubscription["planStatus"] === "trialing") &&
    this.controller.currentGroupSubscription.plan.id === plan.id &&
    this.controller.currentGroupSubscription.plan["remainingDays"];

  scrollToTop = () =>
    this.scrollView && this.scrollView.scrollTo({ y: 0, animated: false });

  scrollToNext = page => {
    if (page === "plan") this.planScrolled = true;
    return this.scrollView && setTimeout(this.scrollView.scrollToEnd);
  };

  billingFormFocus = () =>
    this.billingFormFields[0] && this.billingFormFields[0].focus();

  renderCurrentPage = () =>
    this.controller.currentPage === "plan"
      ? this.renderPlanPage(this.controller.plans)
      : this.controller.currentPage === "payment"
      ? this.renderPaymentPage()
      : this.controller.currentPage === "confirm"
      ? this.renderConfirmPage()
      : null;

  renderPlanPage = plans => (
    <View>
      {/*ProvState*/}
      {this.controller.groupId ? (
        <View>
          <FormField
            field={{
              type: "input",
              placeholder: `${capitalize(
                this.controller.currentGroup.groupTypeName
              )}:`,
              showLabel: true
            }}
            inputValue={getDisplayNameEng(this.controller.profile) || ""}
            enabled={false}
            fieldStyle={this.props.styles.field}
            fieldLabelStyle={this.props.styles.fieldLabel}
          />
          <FormField
            field={{
              type: "input",
              placeholder: `${UIText.planResidentialProvince}:`,
              showLabel: true
            }}
            inputValue={this.controller.inputResidentialProvince}
            inputRightIcon={
              this.controller.isSelfOwner &&
              !this.controller.currentGroupIsScratchpad && (
                <Text
                  style={this.props.styles.planChangeProvinceText}
                  onPress={this.controller.handleProvinceChangePress}
                >
                  {UIText.generalChange}
                </Text>
              )
            }
            inputRightIconContainerStyle={this.props.styles.planChangeProvince}
            enabled={false}
            fieldStyle={this.props.styles.field}
            fieldLabelStyle={this.props.styles.fieldLabel}
          />
        </View>
      ) : (
        <FormField
          field={{
            type: "picker",
            name: "province",
            placeholder: `${UIText.planResidentialProvince}:`,
            value: this.controller.pickerResidentialProvince,
            required: true,
            options: this.controller.pickerResidentialProvinceOptions,
            showLabel: true
          }}
          enabled={true}
          onPickerChange={value => {
            this.controller.onProvinceChange(value);
            this.controller.planChanged && this.scrollToNext();
          }}
          fieldStyle={this.props.styles.field}
          pickerLabelStyle={this.props.styles.pickerLabel}
        />
      )}
      {/*ProvState*/}

      {/*Promo code*/}
      <Observer>
        {() =>
          this.controller.isSelfOwner ? (
            <FormField
              field={{
                type: "input",
                name: "promo",
                placeholder: `${UIText.planPromoCode}:`,
                showLabel: true
              }}
              enabled={true}
              inputValue={this.controller.promoCodeValue}
              inputRightIcon={
                typeof this.controller.validPromoCode === "boolean" ? (
                  !!this.controller.validPromoCode ? (
                    <Icon
                      name="check"
                      size={24}
                      color={theme.generalAcceptColor}
                    />
                  ) : (
                    <Icon
                      name="report-problem"
                      size={24}
                      color={theme.generalDenyColor}
                      onPress={this.controller.onPromoCodeErrorPress}
                    />
                  )
                ) : null
              }
              onInputChange={this.controller.onPromoCodeChange}
              onEndEditing={this.controller.checkPromoCode}
              onBlur={this.controller.checkPromoCode}
              fieldStyle={this.props.styles.field}
              fieldLabelStyle={this.props.styles.fieldLabel}
            />
          ) : null
        }
      </Observer>
      {/*Promo code */}

      {/*Plans*/}
      <View style={this.props.styles.planListLabel}>
        {this.renderGenericFieldLabel(`${UIText.planAvailablePlans}:`)}
      </View>
      {plans.map(plan => {
        return (
          <View key={plan.id}>
            {this.renderPlanCategoryDescription(plan)}
            <TouchableOpacityIOSFix
              disabled={!this.controller.isSelfOwner}
              onPress={() => {
                this.controller
                  .handlePlanPress(plan)
                  .then(
                    () =>
                      this.controller.planChanged &&
                      !this.planScrolled &&
                      this.scrollToNext("plan")
                  );
              }}
            >
              {this.renderPlanItem(plan)}
            </TouchableOpacityIOSFix>
          </View>
        );
      })}
      {env !== "prod" && (
        <View>
          <View style={this.props.styles.planCatDescriptionContainer}>
            <View style={this.props.styles.planCatSectionDividerContainer}>
              <Divider style={this.props.styles.planCatSectionDivider} />
            </View>
            <Text style={this.props.styles.planCatDescription}>
              WooCommerce Test
            </Text>
          </View>
          <TouchableOpacityIOSFix
            onPress={this.controller.modBillingFormForWpMode}
          >
            {this.renderPlanItem({
              productName: "Caregiver fee (Test)",
              displayName: " ",
              description: "Test WooCommerce Order",
              amountStr: " "
            })}
          </TouchableOpacityIOSFix>
        </View>
      )}
      {/*Plans*/}

      {/*Footer notes*/}
      <View style={this.props.styles.footerNoteContainer}>
        <Text style={this.props.styles.footerNote}>
          {UIText.planLearnMore1}
          <Text
            onPress={this.controller.handleMarketingNumberPress}
            style={{
              ...this.props.styles.footerNote,
              ...this.props.styles.footerNoteLink
            }}
          >
            {planMarketingNumber}
          </Text>
          {UIText.planLearnMore2}
          <Text
            onPress={this.controller.handleMarketingContactFormPress}
            style={{
              ...this.props.styles.footerNote,
              ...this.props.styles.footerNoteLink
            }}
          >
            {UIText.planLearnMoreContactForm}
          </Text>
          {"."}
        </Text>
      </View>
      {/*Footer notes*/}
    </View>
  );

  renderPaymentPage = () => (
    <PlanPaymentForm
      paymentUpdateIntro={UIText.planChangePaymentIntro}
      enabledGetter={field => this.controller.ccComplete && !field.disabled}
      valueGetter={field =>
        field.value ? field.value : this.controller.ccComplete ? "" : "—"
      }
      onInputChange={(e, field) => {
        this.controller.onFieldInputChange(field, e);
        this.controller.validBilling && this.scrollToNext("payment");
      }}
      onCheckboxChange={field => {
        field.value = !field.value;
      }}
      onPickerChange={(value, field) => {
        field.value = value;
        this.controller.validBilling && this.scrollToNext("payment");
      }}
      onDateTimeChange={(value, field) => {
        field.value = value;
      }}
      onSubmitEditing={(form, field) => {
        this.controller.helpFocusNextField(field, this.billingFormFields, form);
      }}
      refSetter={(form, field, f) => {
        this.billingFormFields[form.indexOf(field)] = f;
      }}
      isPaymentUpdate={this.controller.isPaymentUpdate}
      // isWpWcMode={this.controller.isWpWcMode}
      stripeUrl={this.stripeUrl}
      handleCardChange={this.controller.handleCardChange}
      createTokenCallback={this.controller.createTokenCallback}
      billingForm={this.controller.billingForm}
    />
  );

  renderConfirmPage = () => (
    <View>
      <View style={this.props.styles.topSection}>
        <Text style={this.props.styles.heading}>
          {UIText.planConfirmDetail}
        </Text>
      </View>

      <View>
        {/*Plan item*/}
        {this.renderGenericFieldLabel(`${UIText.planSelectedPlan}:`)}
        {this.renderPlanItem(this.controller.selectedPlan, true)}
        {/* true === hide radio */}
        <View style={{ paddingBottom: 10 }} />
        <FormField
          field={{
            type: "input",
            placeholder: `${UIText.planPaymentMethod}:`,
            showLabel: true
          }}
          inputValue={UIText.planCreditCard(this.controller.last4)}
          enabled={false}
          fieldStyle={this.props.styles.field}
          fieldLabelStyle={this.props.styles.fieldLabel}
        />
        {/*Plan item*/}
        {/*Promo code*/}
        {!isEmpty(this.controller.promoData) ? (
          <View style={{ paddingVertical: 10 }}>
            <FormField
              field={{
                type: "input",
                placeholder: UIText.planPromoCode,
                showLabel: true
              }}
              enabled={false}
              inputValue={this.controller.promoCodeValue}
              fieldStyle={this.props.styles.field}
              fieldLabelStyle={this.props.styles.fieldLabel}
            />
            <View style={this.props.styles.planTrial}>
              <Text style={this.props.styles.planTrialText}>
                {this.controller.promoData.description}
              </Text>
              {this.controller.promoNoCharge ? (
                <Text style={this.props.styles.planTrialText}>
                  {UIText.planPromoDeferCharge(
                    this.controller.promoData["trialIntervalCount"],
                    this.controller.promoData["trialInterval"]
                  )}
                </Text>
              ) : null}
            </View>
          </View>
        ) : null}
        {/*Promo code*/}
        {/*Billing details*/}
        {this.renderGenericFieldLabel(UIText.planBillingAddress)}
        <View style={this.props.styles.billingDetailConfirm}>
          {this.controller.billingForm.map(field =>
            field.value ? (
              <Text
                key={field.name}
                style={this.props.styles.billingDetailConfirmText}
              >
                {field.value}
              </Text>
            ) : null
          )}
          {this.controller.postalCode ? (
            <Text style={this.props.styles.billingDetailConfirmText}>
              {this.controller.postalCode}
            </Text>
          ) : null}
        </View>
        {/*Billing details*/}
        {/*Breakdown table*/}
        {this.renderGenericFieldLabel(`${UIText.planPriceBreakdown}:`)}
        {this.controller.priceBreakdown ? (
          <PriceBreakdownTable breakdown={this.controller.priceBreakdown} />
        ) : null}
        {/*Breakdown table*/}
      </View>
    </View>
  );

  renderPlanItem = (plan, noChoice) => (
    <Observer>
      {() => (
        <View>
          <View
            style={{
              ...this.props.styles.planItem,
              ...(plan.checked &&
                !noChoice &&
                this.props.styles.planItemSelected),
              ...(plan.id === this.controller.currentGroupSubscription.planId &&
                !noChoice &&
                this.props.styles.planItemCurrent),
              ...(this.getCurrentPlanRemainingDays(plan) && { marginBottom: 5 })
            }}
          >
            <View style={this.props.styles.planItemDetail}>
              {!noChoice && (
                <View style={this.props.styles.planItemCheckbox}>
                  {plan.checked ? (
                    <Icon
                      name="radio-button-checked"
                      size={24}
                      color={theme.color}
                    />
                  ) : (
                    <Icon
                      name="radio-button-unchecked"
                      size={24}
                      color="#333"
                    />
                  )}
                </View>
              )}
              <View style={this.props.styles.planItemText}>
                <View
                  style={{
                    ...this.props.styles.planItemTextItem,
                    minWidth: 240
                  }}
                >
                  <View style={this.props.styles.planItemTitle}>
                    <Text style={this.props.styles.planItemTitleText}>
                      {plan.productName}
                    </Text>
                  </View>
                  <View style={this.props.styles.planItemNickname}>
                    <Text style={this.props.styles.planItemNicknameText}>
                      {(plan.amountStr && plan.displayName) ||
                      this.controller.promoCodeValue
                        ? plan.nickname
                        : plan.nicknameTrial}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    ...this.props.styles.planItemTextItem,
                    minWidth: 130,
                    flex: 1,
                    paddingVertical: 5
                  }}
                >
                  <View style={this.props.styles.planItemDescription}>
                    <Text style={this.props.styles.planItemDescriptionText}>
                      {`${plan.description}${
                        (plan.amountStr && plan.displayName) ||
                        this.controller.promoCodeValue
                          ? ""
                          : UIText.planTrialFirstMonthFreeAppendix
                      }`}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {(plan.amountStr && plan.displayName) ||
            this.controller.promoCodeValue ? (
              plan.isVisible ? (
                <View style={this.props.styles.planItemPrice}>
                  <Text style={this.props.styles.planItemPriceText}>
                    {`${plan.amountStr}/`}
                  </Text>
                  <Text style={this.props.styles.planItemIntervalText}>
                    {this.getPlanIntervalString(plan)}
                  </Text>
                </View>
              ) : null
            ) : (
              <View style={this.props.styles.planItemPrice}>
                <Text style={this.props.styles.planItemPriceText}>
                  {capitalize(UIText.planFreePrice)}
                </Text>
              </View>
            )}
          </View>
          {(this.getCurrentPlanRemainingDays(plan) ||
            (!plan.displayName && plan.checked)) && (
            <View style={this.props.styles.planTrial}>
              {this.getCurrentPlanRemainingDays(plan) ? (
                <Text style={this.props.styles.planTrialText}>
                  {`${UIText.planTrialPromoRemaining(
                    this.getCurrentSubIsPromo()
                  )}: ${UIText.planTrialRemaining(
                    this.getCurrentPlanRemainingDays(plan).toString()
                  )}`}
                </Text>
              ) : null}
              {!plan.displayName &&
              plan.checked &&
              !this.controller.promoCodeValue ? (
                <Text style={this.props.styles.planTrialText}>
                  {UIText.planTrialReminder}
                </Text>
              ) : null}
            </View>
          )}
        </View>
      )}
    </Observer>
  );

  renderPlanCategoryDescription = plan => {
    const category = this.controller.planCategories.find(
      c => c.displayPlanId === plan.id
    );
    if (!category) return null;
    return (
      <View style={this.props.styles.planCatDescriptionContainer}>
        {category.showDivider && (
          <View style={this.props.styles.planCatSectionDividerContainer}>
            <Divider style={this.props.styles.planCatSectionDivider} />
          </View>
        )}
        <Text style={this.props.styles.planCatDescription}>
          {category.description}
        </Text>
      </View>
    );
  };

  renderGenericFieldLabel = text => (
    <FormField
      field={{
        type: "text",
        value: text
      }}
      fieldStyle={this.props.styles.field}
      fieldLabelStyle={this.props.styles.fieldLabel}
    />
  );

  render() {
    return (
      <View style={this.props.styles.style}>
        <Header
          title={UIText.planBillingPlan}
          leftIcon="arrow-back"
          rightComponent={
            this.controller.currentPage === "plan" &&
            this.controller.userIsSubscriptionOwner && (
              <AnimatedHeaderButton
                icon="payment"
                visible={this.controller.updatePaymentFullyVisible}
                title={UIText.planChangePayment}
                onPress={this.controller.handleUpdatePaymentPress}
              />
            )
          }
          rightComponentLong={
            this.controller.currentPage === "plan" &&
            this.controller.userIsSubscriptionOwner
          }
          handleLeftPress={this.controller.handleBackPress}
          navigation={this.props.navigation}
        />
        <ScrollView
          ref={view => (this.scrollView = view)}
          contentContainerStyle={this.props.styles.scrollView}
        >
          {this.controller.loading ? (
            <View style={this.props.styles.loading}>
              <ActivityIndicator size="large" color={theme.color} />
            </View>
          ) : (
            <View style={this.props.styles.scrollViewContent}>
              {this.renderCurrentPage()}
              <View style={this.props.styles.buttonContainer}>
                <ThemedButton
                  visible={
                    this.controller.planChanged ||
                    this.controller.isPaymentUpdate
                  }
                  title={
                    this.controller.setupMode
                      ? UIText.generalNext
                      : this.controller.currentPage === "confirm"
                      ? UIText.generalSubmit
                      : this.controller.currentPage === "payment"
                      ? this.controller.isPaymentUpdate
                        ? UIText.generalSubmit
                        : UIText.generalNext
                      : UIText.generalNext
                  }
                  loading={this.controller.submitting}
                  disabled={
                    !this.controller.isSelfOwner ||
                    this.controller.submitting ||
                    (this.controller.currentPage === "payment" &&
                      !this.controller.validBilling)
                  }
                  onPress={this.controller.handleNextPress}
                />
              </View>
            </View>
          )}
        </ScrollView>

        <AndroidBackHandler onBackPress={this.controller.handleBackPress} />
      </View>
    );
  }
}

export { PlanScreen };
