import React from "react";
import { observer, Observer } from "mobx-react";
import {
  Animated,
  Image,
  Linking,
  Picker,
  Platform,
  ScrollView,
  TextInput,
  TouchableWithoutFeedback,
  View
} from "react-native";
import { Button, CheckBox, Divider, Icon, Text } from "react-native-elements";
import { ThemedInput } from "../ThemedInput";
import { theme } from "../../config/style-configs/theme";
import { DatePicker } from "../DatePicker";
import { CircleCharIcon } from "../CircleCharIcon";
import { HtmlComponent } from "../HtmlComponent";
import { ImageCropperWeb } from "../ImageCropperWeb";
import { Form } from "../Form";
import { formService } from "../../cdm/services/form-service";
import { computedFn } from "mobx-utils";
import { action } from "mobx";

// import { AndroidBackHandler } from "react-navigation-backhandler";

@observer
class ThemedPopupView extends React.Component {
  viewOpacity = new Animated.Value(0);
  viewZIndex = new Animated.Value(-1);
  duration = theme.popupFadeDuration;

  constructor(props) {
    super(props);
    this.fieldProps = computedFn(formFields => ({
      showLabel: false,
      showTitle: this.formShowTitle,
      enabledGetter: computedFn(field => field.name !== "email"),
      valueGetter: computedFn(field => field.value || ""),
      onInputChange: action((e, field) => {
        field.value = e.nativeEvent.text;
      }),
      onCheckboxChange: action(field => {
        field.value = !field.value;
      }),
      onPickerChange: action((value, field) => {
        field.value = value;
      }),
      onDateTimeChange: action((value, field) => {
        field.value = value;
      }),
      onSubmitEditing: action((form, field) => {
        formService.helpFocusNextField(
          field,
          formFields,
          form,
          (!this.rightButtonDisabled && this.rightButtonPress) || (() => {})
        );
      }),
      refSetter: (form, field, f) => {
        formFields[form.indexOf(field)] = f;
      },
      fieldStyle: this.formStyles.field,
      fieldLabelStyle: this.formStyles.fieldLabel,
      pickerStyle: this.formStyles.picker,
      pickerLabelStyle: this.formStyles.pickerLabel,
      checkboxStyle: this.formStyles.checkbox
    }));
  }

  animate(visible) {
    Animated.parallel([
      Animated.timing(this.viewOpacity, {
        toValue: visible ? 1 : 0,
        duration: this.duration
      }),
      Animated.timing(this.viewZIndex, {
        toValue: visible ? 3 : -1,
        duration: this.duration
      })
    ]).start();
  }

  renderInput = (input, i) => (
    <Observer key={input.title || i}>
      {() => (
        <View style={this.props.styles.inputContainer}>
          {input.heading ? (
            <View style={this.props.styles.heading}>
              {this.renderTextContent(input.heading)}
            </View>
          ) : null}
          {input.title ? (
            <Text style={this.props.styles.inputTitle}>{input.title}</Text>
          ) : null}
          <ThemedInput
            placeholder={input.placeholder}
            inputStyle={this.props.styles.input}
            keyboardType={input.keyboardType}
            value={this.props.popupState.inputValue[input.title] || ""}
            returnKeyType="go"
            editable={!input.disabled}
            errorMessage={input.errorMessage}
            secureTextEntry={input.secureTextEntry}
            onChange={e => {
              this.props.popupState.inputValue[input.title] =
                e.nativeEvent.text;
              input.onChange && input.onChange(e);
            }}
            onEndEditing={input.onEndEditing}
            onBlur={input.onBlur}
            onSubmitEditing={
              (!this.rightButtonDisabled && this.rightButtonPress) || (() => {})
            }
            // Multiline props
            maxLength={input.maxLength}
            multiline={input.multiline}
            autoLineHeight={
              input.autoLineHeight && input.autoLineHeight.toString()
            }
            heightOverride={input.heightOverride}
          />
          {input.footer ? (
            typeof input.footer === "string" ? (
              <Text style={this.props.styles.footer}>{input.footer}</Text>
            ) : (
              input.footer
            )
          ) : null}
        </View>
      )}
    </Observer>
  );

  renderPicker = picker => (
    <Observer key={picker.title}>
      {() => (
        <View style={this.props.styles.content}>
          {picker.heading ? (
            <View style={this.props.styles.heading}>
              {this.renderTextContent(picker.heading)}
            </View>
          ) : null}
          {picker.title ? (
            <Text style={this.props.styles.inputTitle}>{picker.title}</Text>
          ) : null}
          <Picker
            selectedValue={picker.selected && picker.selected.name}
            style={this.props.styles.picker}
            onValueChange={picker.onChange} //WIP
          >
            {picker.options.map(option => {
              return (
                <Picker.Item
                  key={option.name}
                  label={option.placeholder}
                  value={option.name}
                />
              );
            })}
          </Picker>
          {picker.footer ? (
            typeof picker.footer === "string" ? (
              <Text style={this.props.styles.footer}>{picker.footer}</Text>
            ) : (
              picker.footer
            )
          ) : null}
        </View>
      )}
    </Observer>
  );

  renderMultiSelect = multiSelect => (
    <Observer>
      {() => (
        <View style={this.props.styles.content}>
          {multiSelect.heading ? (
            <View style={this.props.styles.heading}>
              {this.renderTextContent(multiSelect.heading)}
            </View>
          ) : null}
          {multiSelect.title ? (
            <Text style={this.props.styles.inputTitle}>
              {multiSelect.title}
            </Text>
          ) : null}
          {multiSelect.options.map(o => (
            <CheckBox
              containerStyle={{
                opacity: o.disabled ? 0.5 : 1
              }}
              key={o.value || o.placeholder}
              title={o.placeholder}
              checkedIcon={<Icon name="check-box" size={24} color="black" />}
              uncheckedIcon={
                <Icon name="check-box-outline-blank" size={24} color="black" />
              }
              checked={o.checked}
              checkedColor={theme.color}
              disabled={o.disabled}
              onPress={() => this.multiSelect.onCheckboxChange(o)}
            />
          ))}
          {multiSelect.footer ? (
            typeof multiSelect.footer === "string" ? (
              <Text style={this.props.styles.footer}>{multiSelect.footer}</Text>
            ) : (
              multiSelect.footer
            )
          ) : null}
        </View>
      )}
    </Observer>
  );

  renderDatePicker = datePicker => (
    <Observer key={datePicker.title}>
      {() => (
        <View style={this.props.styles.content}>
          {datePicker.heading ? (
            <View style={this.props.styles.heading}>
              {this.renderTextContent(datePicker.heading)}
            </View>
          ) : null}
          {datePicker.title && (
            <Text style={this.props.styles.inputTitle}>{datePicker.title}</Text>
          )}
          <DatePicker {...datePicker} useMui />
          {datePicker.footer ? (
            typeof datePicker.footer === "string" ? (
              <Text style={this.props.styles.footer}>{datePicker.footer}</Text>
            ) : (
              datePicker.footer
            )
          ) : null}
        </View>
      )}
    </Observer>
  );

  renderTextContent = content => (
    <Observer>
      {() => (
        <>
          <Text
            style={{
              ...this.props.styles.contentText,
              ...(this.contentAlign && { textAlign: this.contentAlign })
            }}
          >
            {(typeof content === "string" && content.toString()) ||
              (typeof content === "object" &&
                content.text &&
                content.text.toString()) ||
              ""}
            {typeof content === "object" && content.link && (
              <Text
                onPress={() => {
                  Platform.OS === "web"
                    ? window.open(content.link)
                    : Linking.openURL(content.link);
                }}
                style={this.props.styles.contentLink}
              >
                {content.link}
              </Text>
            )}
          </Text>
        </>
      )}
    </Observer>
  );

  renderButton = button => (
    <Observer key={button.title}>
      {() => (
        <View style={this.props.styles.buttonSetItem}>
          {button.topDivider && (
            <Divider style={this.props.styles.buttonSetDivider} />
          )}
          <Button
            buttonStyle={{
              ...this.props.styles.buttonStyle,
              ...this.props.styles.buttonSetButton,
              justifyContent:
                button.align === "center" ? "center" : "flex-start"
            }}
            icon={
              button.icon && typeof button.icon === "string" ? (
                <Icon
                  containerStyle={{ marginRight: 5 }}
                  name={button.icon}
                  type="material-community"
                  size={24}
                  color={theme.color}
                />
              ) : typeof button.icon === "object" ? (
                button.icon.circleChar ? (
                  <CircleCharIcon {...button.icon} />
                ) : (
                  button.icon
                )
              ) : null
            }
            disabled={button.disabled}
            type="clear"
            title={button.title}
            titleStyle={{
              ...this.props.styles.buttonText,
              textAlign: button.align || "left"
            }}
            onPress={button.onPress || (() => {})}
          />
          {(button.showDivider || button.bottomDivider) && (
            <Divider style={this.props.styles.buttonSetDivider} />
          )}
        </View>
      )}
    </Observer>
  );

  renderForm = form => {
    let formFields = [];
    return (
      <Observer>
        {() => (
          <View style={this.props.styles.content}>
            <Form form={form} fieldProps={this.fieldProps(formFields)} />
          </View>
        )}
      </Observer>
    );
  };

  renderPopupContent = () => {
    if (this.input) {
      return (
        <View style={this.props.styles.content}>
          {this.renderInput(this.input)}
        </View>
      );
    }
    if (this.inputSet) {
      if (typeof this.inputSet === "function") {
        return (
          <View style={this.props.styles.content}>
            {this.inputSet().map(this.renderInput)}
          </View>
        );
      } else {
        return (
          <View style={this.props.styles.content}>
            {this.inputSet.map(this.renderInput)}
          </View>
        );
      }
    }
    if (this.picker) {
      return this.renderPicker(this.picker);
    }
    if (this.pickerSet) {
      if (typeof this.pickerSet === "function") {
        return this.pickerSet().map(this.renderPicker);
      } else {
        return this.pickerSet.map(this.renderPicker);
      }
    }
    if (this.datePicker) {
      return this.renderDatePicker(this.datePicker);
    }
    if (this.datePickerSet) {
      if (typeof this.datePickerSet === "function") {
        return this.datePickerSet().map(this.renderDatePicker);
      } else {
        return this.datePickerSet.map(this.renderDatePicker);
      }
    }
    if (this.multiSelect) {
      return this.renderMultiSelect(this.multiSelect);
    }
    if (this.form) {
      return this.renderForm(this.form);
    }
    if (this.contentHtml) {
      return (
        <View style={this.props.styles.contentHtml}>
          <HtmlComponent html={this.contentHtml} />
        </View>
      );
    }
    if (this.content) {
      return (
        <View style={this.props.styles.content}>
          {this.renderTextContent(this.content, this.contentAlign)}
        </View>
      );
    }
    return <View />;
  };

  renderPopupButtons = () => (
    <Observer>
      {() =>
        !this.buttonSet ? (
          <View style={this.props.styles.buttons}>
            {this.hasLeftButton && (
              <Button
                containerStyle={{
                  ...this.props.styles.button,
                  ...(!this.hasRightButton && { width: "100%" })
                }}
                buttonStyle={this.props.styles.buttonStyle}
                type="clear"
                title={this.leftButtonText}
                titleStyle={{
                  ...this.props.styles.buttonText,
                  ...(this.leftButtonDisabled && { color: "#666" })
                }}
                disabled={this.leftButtonDisabled}
                onPress={this.leftButtonPress}
              />
            )}

            {this.hasRightButton && (
              <Button
                containerStyle={this.props.styles.button}
                buttonStyle={this.props.styles.buttonStyle}
                type="clear"
                title={this.rightButtonText}
                titleStyle={{
                  ...this.props.styles.buttonText,
                  ...(this.rightButtonDisabled && { color: "#666" })
                }}
                disabled={this.rightButtonDisabled}
                onPress={this.rightButtonPress}
              />
            )}
          </View>
        ) : (
          <View style={this.props.styles.buttonSet}>
            {this.buttonSet.map(this.renderButton)}
          </View>
        )
      }
    </Observer>
  );

  renderKeyboardListener = () => (
    <View style={{ position: "absolute", maxWidth: 0, maxHeight: 0 }}>
      <TextInput onKeyPress={console.log} />
    </View>
  );

  render() {
    const visible = this.props.popupState.isVisible;

    this.image = this.props.popupState.image || this.props.image;
    this.cropper = this.props.popupState.cropper || this.props.cropper;

    this.title = this.props.popupState.title || this.props.title;
    this.content = this.props.popupState.content || this.props.content;
    this.contentHtml =
      this.props.popupState.contentHtml || this.props.contentHtml;
    this.contentAlign =
      this.props.popupState.contentAlign || this.props.contentAlign;

    this.leftButtonPress =
      this.props.popupState.leftButtonPress || this.props.leftButtonPress;
    this.leftButtonText =
      this.props.popupState.leftButtonText || this.props.leftButtonText;
    this.leftButtonDisabled =
      this.props.popupState.leftButtonDisabled || this.props.leftButtonDisabled;
    this.rightButtonPress =
      this.props.popupState.rightButtonPress || this.props.rightButtonPress;
    this.rightButtonText =
      this.props.popupState.rightButtonText || this.props.rightButtonText;
    this.rightButtonDisabled =
      this.props.popupState.rightButtonDisabled ||
      this.props.rightButtonDisabled;
    this.onBackdropPress =
      this.props.popupState.onBackdropPress || this.props.onBackdropPress;
    this.backdropColor =
      this.props.popupState.backdropColor || this.props.backdropColor;

    this.hasLeftButton = !!this.leftButtonPress && !!this.leftButtonText;
    this.hasRightButton = !!this.rightButtonText && !!this.rightButtonPress;
    this.hasButtons = this.hasLeftButton || this.hasRightButton;
    // this.contentButton = this.hasButtons && { marginBottom: 50 };

    this.input = this.props.popupState.input;
    this.inputSet = this.props.popupState.inputSet;
    this.picker = this.props.popupState.picker;
    this.pickerSet = this.props.popupState.pickerSet;
    this.datePicker = this.props.popupState.datePicker;
    this.datePickerSet = this.props.popupState.datePickerSet;
    this.buttonSet = this.props.popupState.buttonSet;
    this.multiSelect = this.props.popupState.multiSelect;
    this.form = this.props.popupState.form;
    this.formStyles = this.props.popupState.formStyles;
    this.formShowTitle = this.props.popupState.formShowTitle;
    this.formShowLabel = this.props.popupState.formShowLabel;

    this.component = () => (
      <Observer>
        {() => (
          <View style={this.props.styles.component}>
            <View style={this.props.styles.header}>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={this.props.styles.headerText}
              >
                {this.title}
              </Text>
            </View>

            <ScrollView>{this.renderPopupContent()}</ScrollView>

            {this.renderPopupButtons()}
            {Platform.OS === "web" && this.renderKeyboardListener()}
          </View>
        )}
      </Observer>
    );

    this.lightBox = () => (
      <Observer>
        {() => (
          <TouchableWithoutFeedback onPress={this.onBackdropPress}>
            <Image
              style={this.props.styles.image}
              source={this.image}
              resizeMode="contain"
              resizeMethod="scale"
            />
          </TouchableWithoutFeedback>
        )}
      </Observer>
    );

    this.imageCropper = () => (
      <Observer>
        {() => (
          <View
            style={{
              ...this.props.styles.component,
              ...{ width: "50%", height: "50%" }
            }}
          >
            <View style={this.props.styles.header}>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={this.props.styles.headerText}
              >
                {this.title}
              </Text>
            </View>

            {Platform.OS === "web" ? (
              <ImageCropperWeb
                source={this.cropper.source}
                cropSize={this.cropper.cropSize}
                cropShape={this.cropper.cropShape}
                showGrid={this.cropper.showGrid}
                dataType={this.cropper.dataType}
                minZoom={this.cropper.minZoom}
                maxZoom={this.cropper.maxZoom}
                aspect={this.cropper.aspect}
                // setDimension={this.setCropperDimension}
                onCropChange={this.cropper.onCropChange}
                onCropComplete={this.cropper.onCropComplete}
              />
            ) : null}

            {this.renderPopupButtons()}
            {Platform.OS === "web" && this.renderKeyboardListener()}
          </View>
        )}
      </Observer>
    );

    setTimeout(() => this.animate(visible));

    return (
      <Animated.View
        style={{
          ...this.props.styles.webOverlay,
          ...(this.backdropColor && { backgroundColor: this.backdropColor }),
          zIndex: this.viewZIndex,
          opacity: this.viewOpacity
        }}
      >
        <TouchableWithoutFeedback onPress={this.onBackdropPress}>
          <View style={this.props.styles.webBackdrop} />
        </TouchableWithoutFeedback>

        {this.image
          ? this.lightBox()
          : this.cropper
          ? this.imageCropper()
          : this.component()}

        {/*<AndroidBackHandler*/}
        {/*onBackPress={this.props.onPopupBackButton}*/}
        {/*/>*/}
      </Animated.View>
    );
  }
}

// Common component text options:
// title = Colored, like label, with component.
// placeholder = Supported on some component, text within component itself.
// heading = Black, wordy, like text, on top of component.
// footer = Component or string, at the bottom of component.

export { ThemedPopupView };
