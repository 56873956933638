import React from "react";
import { Animated } from "react-native";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { Button, Icon } from "react-native-elements";
import { responsive } from "../../config/style-configs/responsive";
import { theme } from "../../config/style-configs/theme";

@observer
class AnimatedHeaderButtonView extends React.Component {
  @observable bounceValue;

  constructor(props) {
    super(props);
    this.hiddenValue = responsive.deviceDimension.width;
    this.bounceValue = new Animated.Value(this.hiddenValue);
  }

  setWidth = event => {
    const { layout } = event.nativeEvent;
    const { width } = layout;
    if (!this.width) {
      this.width = width;
      this.hiddenValue = width - 40;
    }
  };

  animate = visible =>
    Animated.spring(this.bounceValue, {
      toValue: visible ? 0 : this.hiddenValue,
      velocity: 3,
      tension: 2,
      friction: 8
    }).start();

  render() {
    setTimeout(() => this.animate(this.props.visible));

    return (
      <Animated.View
        onLayout={this.setWidth}
        style={{
          ...this.props.styles.style,
          ...{ transform: [{ translateX: this.bounceValue }] }
        }}
      >
        <Button
          type="clear"
          icon={
            this.props.icon && (
              <Icon
                name={this.props.icon}
                type={this.props.iconType || "material"}
                color={theme.color}
                size={25}
              />
            )
          }
          title={this.props.title || null}
          titleStyle={this.props.styles.title}
          loading={this.props.loading}
          loadingProps={{ color: theme.color }}
          onPress={this.props.onPress}
        />
      </Animated.View>
    );

    // return (
    //   <Animated.View
    //     style={{
    //       ...this.props.styles.style,
    //       ...{ transform: [{ translateY: this.bounceValue }] }
    //     }}
    //   >
    //     {this.props.children}
    //   </Animated.View>
    // );
  }
}

export { AnimatedHeaderButtonView };
