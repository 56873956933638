import React from "react";
import { Image, ScrollView, View } from "react-native";
import { Button, CheckBox, Text } from "react-native-elements";
import { observer } from "mobx-react";
import { theme } from "../../../config/style-configs/theme";
import { capitalize, isEmpty } from "../../../utils/helpers";
import { ThemedInput } from "../../../components/ThemedInput";
import Icon from "react-native-vector-icons/MaterialIcons";
import { UIText } from "../../../config/lang-config";
import { ThemedButton } from "../../../components/ThemedButton";

@observer
class ResetScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
    this.input = {};
    this.rendered = false;
  }

  componentDidMount() {
    return this.controller.componentDidMount();
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  renderRevealButton = field => (
    <Button
      containerStyle={this.props.styles.revealButton}
      type="clear"
      icon={<Icon size={24} color="#999" name="visibility" />}
      onPressIn={() => this.controller.reveal(field)}
      onPressOut={() => this.controller.reveal(field)}
      onPress={() => {}}
    />
  );

  renderOldPasswordField = () => (
    <View style={this.props.styles.fields}>
      <ThemedInput
        ref={input => {
          this.input.oldPassword = input;
        }}
        placeholder={capitalize(UIText.oldPassword)}
        leftIcon={<Icon name="lock" size={24} color="black" />}
        rightIcon={this.renderRevealButton("oldPassword")}
        returnKeyType="next"
        secureTextEntry={this.controller.visible.oldPassword}
        errorMessage={this.controller.error.oldPassword}
        onChange={e => {
          this.controller.updateFields("oldPassword", e);
          this.controller.validatePassword("oldPassword", true);
        }}
        onEndEditing={e => {
          this.controller.updateFields("oldPassword", e);
          this.controller.validatePassword("oldPassword");
        }}
        onBlur={e => {
          this.input.oldPassword.props.onEndEditing(e);
        }}
        onSubmitEditing={() => {
          this.input.password
            ? this.input.password.focus()
            : this.controller.execResetPassword();
        }}
      />
    </View>
  );

  renderSubmitButton = () => (
    <ThemedButton
      style={this.props.styles.button}
      disabled={this.controller.buttonDisabled || this.controller.resetting}
      title={UIText.generalSubmit}
      loading={this.controller.resetting}
      onPress={this.controller.execResetPassword}
    />
  );

  renderFields = () => {
    if (this.controller.resetRequest) {
      return (
        <View style={this.props.styles.container}>
          <View style={this.props.styles.questions}>
            <Text style={this.props.styles.questionHeader}>
              {UIText.resetPassword}
            </Text>
          </View>
          <View style={this.props.styles.fields}>
            <ThemedInput
              ref={input => {
                this.input.resetRequest = input;
              }}
              placeholder={UIText.loginFieldUsername}
              leftIcon={<Icon name="person" size={24} color="black" />}
              returnKeyType="go"
              errorMessage={this.controller.error.resetRequest}
              onChange={e => {
                this.controller.updateFields("resetRequest", e);
                this.controller.validateFields();
              }}
              onEndEditing={e => {
                this.controller.updateFields("resetRequest", e);
                this.controller.validateFields();
              }}
              onBlur={e => {
                this.input.resetRequest.props.onEndEditing(e);
              }}
              onSubmitEditing={this.controller.requestResetEmail}
            />
          </View>
          <ThemedButton
            style={this.props.styles.button}
            disabled={
              this.controller.buttonDisabled || this.controller.resetting
            }
            title={UIText.generalSubmit}
            loading={this.controller.resetting}
            onPress={this.controller.requestResetEmail}
          />

          <View style={this.props.styles.links}>
            <Text
              style={this.props.styles.link}
              onPress={e => {
                this.controller.handleLinkPress("login", e);
              }}
            >
              {UIText.resetKnowPassword}
            </Text>
          </View>
        </View>
      );
    } else {
      return (
        <View style={this.props.styles.container}>
          {this.controller.needTerms ? (
            <View style={this.props.styles.container}>
              <View style={this.props.styles.questions}>
                <Text style={this.props.styles.questionHeader}>
                  {UIText.resetAgreeTerms}
                </Text>
              </View>
              <View
                style={{
                  ...this.props.styles.agreement,
                  marginVertical: 0
                }}
              >
                <CheckBox
                  containerStyle={this.props.styles.checkbox}
                  checkedIcon={
                    <Icon name="check-box" size={24} color="black" />
                  }
                  uncheckedIcon={
                    <Icon
                      name="check-box-outline-blank"
                      size={24}
                      color="black"
                    />
                  }
                  checked={this.controller.agreeTnc}
                  checkedColor={theme.color}
                  onPress={this.controller.handleCheckboxChange}
                />
                <View style={this.props.styles.checkboxLabel}>
                  <Text
                    style={this.props.styles.checkboxLabelText}
                    onPress={this.controller.handleCheckboxChange}
                  >
                    I have read and agree to
                  </Text>
                  <Text
                    style={this.props.styles.checkboxLabelLink}
                    onPress={this.controller.openAgreement}
                  >
                    Terms and Conditions.
                  </Text>
                </View>
              </View>

              {!this.controller.needReset &&
              !this.controller.resetToken &&
              !this.controller.resetUser ? (
                <View style={this.props.extraStyles.resetContainer}>
                  {this.renderOldPasswordField()}
                </View>
              ) : null}
            </View>
          ) : null}
          {this.controller.needReset ||
          (this.controller.resetToken && this.controller.resetUser) ? (
            <View style={this.props.extraStyles.resetContainer}>
              {this.controller.needTerms && !this.controller.onboarding ? (
                <View style={this.props.extraStyles.resetContainer}>
                  <View style={this.props.styles.questions}>
                    <Text style={this.props.styles.questionHeader}>
                      {UIText.resetChangeEmail}
                    </Text>
                  </View>
                  <View style={this.props.styles.fields}>
                    <ThemedInput
                      ref={input => {
                        this.input.newEmail = input;
                      }}
                      placeholder={UIText.resetChangeEmailPlaceholder}
                      leftIcon={<Icon name="email" size={24} color="black" />}
                      returnKeyType="next"
                      errorMessage={this.controller.error.newEmail}
                      onChange={e => {
                        this.controller.updateFields("newEmail", e);
                        this.controller.validateNewEmail("Email address", true);
                      }}
                      onEndEditing={e => {
                        this.controller.updateFields("newEmail", e);
                        this.controller.validateNewEmail("Email address");
                      }}
                      onBlur={e => {
                        this.input.newEmail.props.onEndEditing(e);
                      }}
                      onSubmitEditing={() => {
                        this.input.oldPassword &&
                          this.input.oldPassword.focus();
                      }}
                    />
                  </View>
                </View>
              ) : null}

              <View style={this.props.styles.questions}>
                <Text style={this.props.styles.questionHeader}>
                  {UIText.resetPassword}
                </Text>
              </View>

              {this.controller.needOldPassword && this.renderOldPasswordField()}

              <View style={this.props.styles.fields}>
                <ThemedInput
                  ref={input => {
                    this.input.password = input;
                  }}
                  placeholder={UIText.resetPasswordNewPassword}
                  leftIcon={<Icon name="repeat" size={24} color="black" />}
                  rightIcon={this.renderRevealButton("password")}
                  returnKeyType="next"
                  secureTextEntry={this.controller.visible.password}
                  errorMessage={this.controller.error.password}
                  onChange={e => {
                    this.controller.updateFields("password", e);
                    this.controller.validatePassword("password", true);
                  }}
                  onEndEditing={e => {
                    this.controller.updateFields("password", e);
                    this.controller.validatePassword("password");
                  }}
                  onBlur={e => {
                    this.input.password.props.onEndEditing(e);
                  }}
                  onSubmitEditing={() => {
                    this.input.repeat.focus();
                  }}
                />
              </View>

              <View style={this.props.styles.fields}>
                <ThemedInput
                  // containerStyle={this.props.styles.halfLength}
                  ref={input => {
                    this.input.repeat = input;
                  }}
                  placeholder={UIText.registrationFieldNames.repeat}
                  leftIcon={<Icon name="repeat-one" size={24} color="black" />}
                  rightIcon={this.renderRevealButton("repeat")}
                  returnKeyType="next"
                  secureTextEntry={this.controller.visible.repeat}
                  errorMessage={this.controller.error.repeat}
                  onChange={e => {
                    this.controller.updateFields("repeat", e);
                    this.controller.validatePassword("repeat", true);
                  }}
                  onEndEditing={e => {
                    this.controller.updateFields("repeat", e);
                    this.controller.validatePassword("repeat");
                  }}
                  onBlur={e => {
                    this.input.repeat.props.onEndEditing(e);
                  }}
                  onSubmitEditing={this.controller.execResetPassword}
                />
              </View>
            </View>
          ) : null}

          {this.renderSubmitButton()}

          <View style={this.props.styles.links}>
            <Text
              style={this.props.styles.link}
              onPress={e => {
                this.controller.handleLinkPress("login", e);
              }}
            >
              {UIText.resetGotoLogin}
            </Text>
          </View>
        </View>
      );
    }
  };

  render() {
    if (!isEmpty(this.controller.form) && !this.rendered) {
      this.rendered = true;
      setTimeout(() => {
        for (let input in this.input) {
          if (this.input.hasOwnProperty(input))
            this.input[input].props.defaultValue && this.input[input].focus();
        }
      });
    }
    const short =
      this.controller.resetRequest ||
      (this.controller.needTerms &&
        !this.controller.needReset &&
        !this.controller.resetToken &&
        !this.controller.resetUser);

    return (
      <View style={this.props.styles.style}>
        <ScrollView
          style={this.props.styles.scrollView}
          contentContainerStyle={{
            ...this.props.styles.scrollView,
            justifyContent: short ? "center" : "flex-start"
          }}
        >
          <View style={this.props.styles.header}>
            <Image
              source={theme.loginLogo}
              style={this.props.styles.headerLogo}
            />
          </View>
          {!this.controller.loading && this.renderFields()}
        </ScrollView>
      </View>
    );
  }
}

export { ResetScreen };
