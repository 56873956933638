import { Observer, observer } from "mobx-react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import {
  daysToMilli,
  formatFixedDigit,
  getDayNameEng,
  getMonthNameEng,
  getUTCOffsetMilli,
  getWeekNumber,
  hoursToMilli,
  isEqual,
  preventDefaultStopProp,
  randomString,
  yyyymmdd
} from "../../utils/helpers";
import React from "react";
import { computed, observable, toJS } from "mobx";
import { theme } from "../../config/style-configs/theme";
import { responsive } from "../../config/style-configs/responsive";
import { Icon } from "react-native-elements";
import moment from "moment-timezone";

@observer
class WeekViewCalendarView extends React.Component {
  daysToShow = 7;
  @observable markedBlocks = [];
  @observable hoveredBlocks = {};
  @observable currentDate;
  @observable timezone;

  @computed get yearString() {
    return this.currentDate.getFullYear();
  }
  @computed get weekString() {
    return `Week ${getWeekNumber(this.currentDate)[1]} of ${
      getWeekNumber(this.currentDate)[0]
    }`;
  }

  @computed get dayColumns() {
    const today = new Date(this.currentDate);
    const sun = new Date(today.getTime() - daysToMilli(today.getDay()));
    const days = [];
    for (let i = 0; i < this.daysToShow; i++) {
      days.push(new Date(sun.getTime() + daysToMilli(i)));
    }
    return days;
  }

  @computed get timeSlots() {
    const slots = [];
    for (let i = 0; i < 12; i++) {
      const hour = i === 0 ? 12 : i;
      slots.push({
        hour: i,
        display: `${formatFixedDigit(hour, 1)}a`
      });
    }
    for (let i = 0; i < 12; i++) {
      const hour = i === 0 ? 12 : i;
      slots.push({
        hour: i + 12,
        display: `${formatFixedDigit(hour, 1)}p`
      });
    }
    return slots;
  }

  constructor(props) {
    super(props);
    this.currentDate = this.props.currentDate;
    this.timezone = this.props.timezone;
  }

  componentDidUpdate(prevProps) {
    const { currentDate } = this.props;
    if (currentDate) this.currentDate = currentDate;
  }

  timezoneOffset(date) {
    return getUTCOffsetMilli(this.timezone, date);
  }

  getMarkedDates = (day, slot) => {
    const dateString = yyyymmdd(day);

    const markedDates = [...toJS(this.props.markedDates)]
      .map(date => ({
        ...date,
        start: moment(new Date(date.start))
          .tz(this.timezone)
          .toDate()
          .getTime(),
        end: moment(new Date(date.end))
          .tz(this.timezone)
          .toDate()
          .getTime()
      }))
      .filter(date => yyyymmdd(new Date(date.start)) === dateString)
      .sort((a, b) => {
        const aDuration = a.end - a.start;
        const bDuration = b.end - b.start;
        return aDuration > bDuration;
      });
    const nonOverlaps = [...markedDates];
    const overlaps = [];

    for (const a of markedDates) {
      for (const b of markedDates) {
        if (isEqual(a, b)) continue;
        const overlapped =
          (a.start >= b.start && a.start < b.end) ||
          (a.end >= b.start && a.end <= b.end) ||
          (b.start >= a.start && b.start <= a.end) ||
          (b.end >= a.start && b.end <= a.end);
        if (overlapped) {
          !overlaps.includes(a) && overlaps.push(a);
          !overlaps.includes(b) && overlaps.push(b);
          nonOverlaps.includes(a) &&
            nonOverlaps.splice(nonOverlaps.indexOf(a), 1);
          nonOverlaps.includes(b) &&
            nonOverlaps.splice(nonOverlaps.indexOf(b), 1);
        }
      }
    }

    if (slot.hour === 0) {
      return this.getOverlappedMarkedBlocks(day, slot, overlaps);
    } else {
      return this.getIndependentMarkedBlocks(day, slot, nonOverlaps);
    }
  };

  getOverlappedMarkedBlocks = (day, slot, marks) => {
    if (slot.hour !== 0) return [];
    const time = day.setHours(slot.hour, 0, 0, 0);
    const dayEnd = day.setHours(23, 59, 59, 0);
    const dateString = yyyymmdd(day);
    const markedDates = [...toJS(marks)];
    for (const m of markedDates) {
      const startDateString = yyyymmdd(new Date(m.start));

      m.duration = m.end - m.start;

      m.isMarked = startDateString === dateString;
      if (!m.isMarked) continue;

      if (m.isMarked) {
        if (m.end && m.end - dayEnd > hoursToMilli(2)) {
          m.duration = dayEnd - m.start + hoursToMilli(2);
          m.overTime = true;
        }
        m.id = randomString();
        m.height = (m.duration / hoursToMilli(1)) * 50;
        m.startOffset = ((m.start - time) / hoursToMilli(1)) * 50;
      }
    }

    return markedDates
      .filter(m => m.isMarked)
      .sort((a, b) => b.duration - a.duration);
  };

  getIndependentMarkedBlocks = (day, slot, marks) => {
    const time = day.setHours(slot.hour, 0, 0, 0);
    const markedDates = [...toJS(marks)];
    for (const m of markedDates) {
      const normalizedStart = new Date(m.start).setMinutes(0, 0, 0);
      const normalizedEnd = new Date(m.end).setMinutes(0, 0, 0);

      m.duration = m.end - m.start;
      if (m.duration > daysToMilli(1)) continue;

      m.inMarked =
        !isNaN(normalizedStart) &&
        time >= normalizedStart &&
        !isNaN(normalizedEnd) &&
        time <= normalizedEnd;
      m.isMarked = m.inMarked && time === normalizedStart;

      if (m.isMarked) {
        m.id = randomString();
        m.height = (m.duration / hoursToMilli(1)) * 50;
        m.startOffset = ((m.start - normalizedStart) / hoursToMilli(1)) * 50;
      }
    }
    return markedDates
      .filter(m => m.isMarked)
      .sort((a, b) => b.duration - a.duration);
  };

  getCustomBlockStyles = (day, slot) => {
    if (!Array.isArray(this.props.markedBlocks)) return {};
    const dateString = yyyymmdd(day, "-");
    const markedBlock = this.props.markedBlocks.find(
      b => b.date === dateString && (b.hour === slot.hour || !!b.allDay)
    );
    return (markedBlock && markedBlock.customStyles) || {};
  };

  getDisabledBlock = (day, slot) => {
    if (!Array.isArray(this.props.markedBlocks)) return false;
    const dateString = yyyymmdd(day, "-");
    return this.props.markedBlocks.some(
      b =>
        b.disabled &&
        b.date === dateString &&
        (b.hour === slot.hour || !!b.allDay)
    );
  };

  getHoveredBlock = (day, slot) => {
    const block = day.setHours(slot.hour, 0, 0, 0);
    return this.hoveredBlocks[block];
  };

  getNoHoverBlock = (day, slot) => {
    if (!Array.isArray(this.props.markedBlocks)) return false;
    const dateString = yyyymmdd(day, "-");
    const markedBlock =
      this.props.markedBlocks.find(
        b => b.date === dateString && (b.hour === slot.hour || !!b.allDay)
      ) || {};
    return typeof markedBlock.noHover === "boolean"
      ? markedBlock.noHover
      : this.props.noHover;
  };

  onHoverBlockEnter = (event, day, slot) => {
    const block = day.setHours(slot.hour, 0, 0, 0);
    this.hoveredBlocks[block] = true;
  };

  onHoverBlockLeave = (event, day, slot) => {
    const block = day.setHours(slot.hour, 0, 0, 0);
    this.hoveredBlocks[block] = false;
  };

  clearHoveredMarkBlock = (event, mark) => {
    const day = new Date(mark.start);
    const slot = this.timeSlots.find(s => s.hour === day.getHours());
    this.onHoverBlockLeave(event, day, slot);
    this.onHoverBlockLeave(event, day, { hour: 0 });
  };

  handleLeftArrowPress = event => {
    preventDefaultStopProp(event);
    const newDate = new Date(this.currentDate.getTime() - daysToMilli(7));
    this.currentDate = newDate;

    const { onWeekChange } = this.props;
    if (!onWeekChange) return;
    return onWeekChange(newDate);
  };

  handleRightArrowPress = event => {
    preventDefaultStopProp(event);
    const newDate = new Date(this.currentDate.getTime() + daysToMilli(7));
    this.currentDate = newDate;

    const { onWeekChange } = this.props;
    if (!onWeekChange) return;
    return onWeekChange(newDate);
  };

  handleMarkPress = (event, mark) => {
    preventDefaultStopProp(event);
    return mark.onPress && mark.onPress(event);
  };

  handleBlockPress = (event, day, slot) => {
    preventDefaultStopProp(event);
    const { onBlockPress } = this.props;
    if (!onBlockPress) return;
    const date = new Date(day.setHours(slot.hour, 0, 0, 0));
    return onBlockPress(date);
  };

  renderStrip = dayColumns => (
    <Observer>
      {() =>
        dayColumns.map((day, i) => (
          <View key={i} style={this.props.styles.dateColumn}>
            <View
              style={{ ...this.props.styles.cell, ...this.props.styles.date }}
            >
              <Text style={this.props.styles.dateText} numberOfLines={1}>
                {getDayNameEng(day)}
              </Text>
              <Text style={this.props.styles.dateText} numberOfLines={1}>
                {responsive.deviceDimension.isDesktop
                  ? `${getMonthNameEng(day)} `
                  : `${day.getMonth() + 1}-`}
                <Text style={this.props.styles.dateText} numberOfLines={1}>
                  {day.getDate()}
                </Text>
              </Text>
            </View>
          </View>
        ))
      }
    </Observer>
  );

  renderTimeColumn = timeSlots => (
    <Observer>
      {() =>
        timeSlots.map(slot => (
          <View
            key={slot.hour}
            style={{
              ...this.props.styles.cell,
              ...this.props.styles.timeSlot
            }}
          >
            <Text style={this.props.styles.text} numberOfLines={1}>
              {slot.display}
            </Text>
          </View>
        ))
      }
    </Observer>
  );

  renderDayColumns = dayColumns => (
    <Observer>
      {() =>
        dayColumns.map((day, i) => (
          <View key={i} style={this.props.styles.dateColumn}>
            <Observer>
              {() =>
                this.timeSlots.map(slot => {
                  const markedDates = this.getMarkedDates(day, slot);
                  return (
                    <TouchableOpacity
                      activeOpacity={1}
                      onMouseLeave={e => this.onHoverBlockLeave(e, day, slot)}
                      onMouseEnter={e => this.onHoverBlockEnter(e, day, slot)}
                      key={slot.hour}
                      style={{
                        ...this.props.styles.cell,
                        ...this.props.styles.dateCell,
                        ...(this.props.customStyles &&
                          this.props.customStyles.cell),
                        ...(markedDates.some(m => m.isMarked) && {
                          zIndex: 1
                        }),
                        ...this.getCustomBlockStyles(day, slot),
                        ...(!this.getNoHoverBlock(day, slot) &&
                          this.getHoveredBlock(day, slot) && {
                            backgroundColor: "#f5f5f5"
                          })
                      }}
                      onPress={
                        !this.getDisabledBlock(day, slot) &&
                        (e => this.handleBlockPress(e, day, slot))
                      }
                    >
                      {this.renderMarkedDates(
                        markedDates.length > 0 && markedDates
                      )}
                    </TouchableOpacity>
                  );
                })
              }
            </Observer>
          </View>
        ))
      }
    </Observer>
  );

  renderMarkedDates = markedDates => (
    <Observer>
      {() =>
        markedDates &&
        markedDates.map(mark => (
          <TouchableOpacity
            onMouseEnter={e => this.clearHoveredMarkBlock(e, mark)}
            style={{
              ...this.props.styles.mark,
              backgroundColor: mark.color || theme.color,
              minHeight: mark.height,
              maxHeight: mark.height,
              top: mark.startOffset
            }}
            key={mark.id || mark.eventName || mark.start + mark.end}
            onPress={e => this.handleMarkPress(e, mark)}
          >
            <Text
              numberOfLines={1}
              style={{
                ...this.props.styles.text,
                ...this.props.styles.markText,
                color: mark.textColor || "#fff"
              }}
            >
              {mark.eventName || "marked"}
            </Text>
            {mark.overTime && <Icon name="more-horiz" size={15} color="#fff" />}
          </TouchableOpacity>
        ))
      }
    </Observer>
  );

  render() {
    return (
      <View style={this.props.styles.container}>
        <View style={this.props.styles.style}>
          <TouchableOpacity
            style={{
              ...this.props.styles.cell,
              ...this.props.styles.arrow
            }}
            onPress={this.handleLeftArrowPress}
          >
            <Icon name="chevron-left" color={theme.color} size={30} />
          </TouchableOpacity>
          <View style={this.props.styles.header}>
            <Text style={this.props.styles.headerText}>
              {this.weekString.toUpperCase()}
            </Text>
          </View>
          <TouchableOpacity
            style={{
              ...this.props.styles.cell,
              ...this.props.styles.arrow
            }}
            onPress={this.handleRightArrowPress}
          >
            <Icon name="chevron-right" color={theme.color} size={30} />
          </TouchableOpacity>
        </View>
        <View style={this.props.styles.style}>
          <View style={this.props.styles.dateColumn}>
            <View
              style={{
                ...this.props.styles.cell,
                ...this.props.styles.timeSlot
              }}
            >
              <Text style={this.props.styles.text} numberOfLines={1}>
                {this.yearString}
              </Text>
            </View>
          </View>
          {this.renderStrip(this.dayColumns)}
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={this.props.styles.style}
        >
          <View style={this.props.styles.dateColumn}>
            {this.renderTimeColumn(this.timeSlots)}
          </View>
          {this.renderDayColumns(this.dayColumns)}
        </ScrollView>
      </View>
    );
  }
}

export { WeekViewCalendarView };
