import {
  convertDateToTimeZone,
  daysToMilli,
  hoursToMilli
} from "../../../utils/helpers";

export const timesheetTopicsToPayrollPeriods = (timesheetTopics, timezone) => {
  if (!Array.isArray(timesheetTopics)) return [];
  let periods = [];

  // const getCareCircleDateFromUTCString = (dateString, timezone) =>
  //   new Date(
  //     moment(dateString)
  //       .tz(timezone)
  //       .format("YYYY-MM-DD HH:mm:ss")
  //   );

  const getPlaceholderStart = milli => {
    milli.setDate(milli.getDate() + 6 - milli.getDay());
    return milli.toDateString();
  };

  const getPlaceholder = milli => milli.toDateString();

  for (let i = 0; i < timesheetTopics.length; i++) {
    const id = timesheetTopics[i].id;
    const start = convertDateToTimeZone(timesheetTopics[i].startTime, timezone);
    const end = convertDateToTimeZone(timesheetTopics[i].endTime, timezone);

    periods.push({
      id,
      start,
      end,
      placeholder: `${getPlaceholderStart(start)} –  ${getPlaceholder(end)}`,
      timesheetId: timesheetTopics[i].id
    });
  }
  return periods;
};

const startingDate = 1562371200000,
  interval = daysToMilli(14);
export const getPayrollSummaryPeriods = options => {
  options = options || {};
  const { offsetEndToDayEnd } = options;
  let periods = [],
    i = 0;
  const today = new Date().getTime();
  const getPlaceholder = milli =>
    new Date(new Date(milli).toUTCString().replace("GMT", "")).toDateString();

  while (!periods[0] || periods[periods.length - 1].end < today) {
    const start = startingDate + interval * i;
    const end = start + interval - 1000;
    periods.push({
      start,
      end: offsetEndToDayEnd ? end + hoursToMilli(24 - 17) - 1000 : end,
      placeholder: `${getPlaceholder(start)} –  ${getPlaceholder(end)}`
    });
    i++;
  }
  return periods.slice(-20);
};

// export const payrollSummaryPeriods = [
//   {
//     start: 1562371200000,
//     end: 1563494400000,
//     placeholder: "Sat. Jul 06, 2019 – Fri. Jul 19, 2019"
//   },
//   {
//     start: 1563580800000,
//     end: 1564704000000,
//     placeholder: "Sat. Jul 20, 2019 – Fri. Aug 02, 2019"
//   },
//   {
//     start: 1564790400000,
//     end: 1565913600000,
//     placeholder: "Sat. Aug 03, 2019 – Fri. Aug 16, 2019"
//   },
//   {
//     start: 1566000000000,
//     end: 1567123200000,
//     placeholder: "Sat. Aug 17, 2019 – Fri. Aug 30, 2019"
//   },
//   {
//     start: 1567209600000,
//     end: 1568419199000,
//     placeholder: "Sat. Aug 31, 2019 – Fri. Sep 13, 2019"
//   },
//   {
//     start: 1568419200000,
//     end: 1569628799000,
//     placeholder: "Sat. Sep 14, 2019 – Fri. Sep 27, 2019"
//   },
//   {
//     start: 1569628800000,
//     end: 1570838399000,
//     placeholder: "Sat. Sep 28, 2019 – Fri. Oct 11, 2019"
//   },
//   {
//     start: 1570838400000,
//     end: 1572047999000,
//     placeholder: "Sat. Oct 12, 2019 – Fri. Oct 25, 2019"
//   },
//   {
//     start: 1572048000000,
//     end: 1573257599000,
//     placeholder: "Sat. Oct 26, 2019 – Fri. Nov 08, 2019"
//   },
//   {
//     start: 1573257600000,
//     end: 1574467199000,
//     placeholder: "Sat. Nov 09, 2019 – Fri. Nov 22, 2019"
//   },
//   {
//     start: 1574467200000,
//     end: 1575676799000,
//     placeholder: "Sat. Nov 23, 2019 – Fri. Dec 06, 2019"
//   },
//   {
//     start: 1575676800000,
//     end: 1576886399000,
//     placeholder: "Sat. Dec 07, 2019 – Fri. Dec 20, 2019"
//   }
// ];
