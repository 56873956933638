import { styles } from "../styles";

export const extraStyles = {
  questionHeader: {
    ...styles.questionHeader,
    textAlign: "center",
    color: "#333"
  }
};

export { styles };
