import React from "react";
import { action, computed, observable, runInAction, toJS } from "mobx";
import { observer } from "mobx-react";
import { View } from "react-native";
import { ThemedButton } from "../ThemedButton/index";
import { stateController } from "../../cdm/controllers/state-controller";
import { UIText } from "../../config/lang-config";
import { capitalize } from "../../utils/helpers";

@observer
class ListFilterView extends React.Component {
  constructor(props) {
    super(props);
    setTimeout(runInAction(this.setDefault), 50);
  }

  @observable loading = false;

  @observable categories = [];
  @observable hasFilter;

  criterionAll = {
    name: "",
    placeholder: capitalize(UIText.filterAll)
  };

  @computed get pickerSet() {
    return this.categories.map(category => ({
      options: this.getCriteriaOptions(category),
      title: capitalize(category.name),
      selected: this.getSelectedCriterion(category),
      onChange: criterion => (category.selected = criterion)
    }));
  }

  @action
  setDefault = () => {
    for (let c of this.props.categories) {
      if (!c.selected) {
        c.selected = c.default || "";
      } else {
        this.hasFilter = c.selected !== c.default;
      }
    }
    this.props.filterSetter && this.props.filterSetter(this.props.categories);
  };

  toPickerItem = ct => ({
    name: ct,
    placeholder: typeof ct === "string" && capitalize(ct)
  });

  getCategories = async () => {
    this.loading = true;
    this.categories = toJS(this.props.categories);

    for (let item of this.props.data) {
      for (let c of this.categories) {
        !Array.isArray(c.criteria) && (c.criteria = []);
        const criterion = c.classifier(item);
        !c.criteria.find(ct => ct === criterion) && c.criteria.push(criterion);
        if (!c.selected) c.selected = c.default || "";
      }
    }

    return Promise.resolve();
  };

  getCriteriaOptions = category => {
    if (!Array.isArray(category.criteria)) {
      return [this.criterionAll];
    }

    let criteria = [...category.criteria];
    if (category.sort) criteria = criteria.sort(category.sort);

    return [
      !category.default && this.criterionAll, // No default then add "All" as first element.

      ...((category.alwaysShownCriteria &&
        category.alwaysShownCriteria.map(this.toPickerItem)) ||
        []), // Add all the specified alwaysShown elements.

      ...criteria
        .filter(
          ct =>
            !category.alwaysShownCriteria ||
            !category.alwaysShownCriteria.includes(ct)
        ) // Filter out the alwaysShown ones, then add the rest.
        .map(this.toPickerItem)
    ].filter(Boolean);
  };

  getSelectedCriterion = category => {
    return { name: category.selected };
  };

  showFilterPopup = () => {
    this.getCategories()
      .then(() => (this.loading = false))
      .then(() => {
        stateController.showPopup({
          // title: capitalize(UIText.filterShowOnly),
          title: UIText.filterTitle(this.props.title),
          leftButtonText: UIText.generalReset,
          leftButtonPress: () => {
            this.clearFilter();
            this.applyFilter();
            this.hasFilter = false;
          },
          rightButtonText: UIText.generalApply,
          rightButtonPress: this.applyFilter,
          dismissOnBackPress: true,
          pickerSet: () => this.pickerSet
        });
      });
  };

  clearFilter = () => {
    for (let category of this.categories) {
      category.selected = category.default || "";
    }
  };

  applyFilter = () => {
    this.hasFilter = true;
    this.props.filterSetter && this.props.filterSetter(toJS(this.categories));
    return stateController.dismissPopup();
  };

  render() {
    return (
      <View
        style={{
          ...this.props.styles.style,
          ...this.props.style
        }}
      >
        <ThemedButton
          width={45}
          height={40}
          icon={this.hasFilter ? "filter" : "filter-outline"}
          iconType="material-community"
          iconSize={22}
          loading={this.loading}
          onPress={this.showFilterPopup}
        />
      </View>
    );
  }
}

export { ListFilterView };
