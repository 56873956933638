import { clientController } from "../../../../../../cdm/controllers/client-controller";
import { householdForm } from "./form";
import {
  groupTypeIds,
  groupTypeRoleIds
} from "../../../../../../config/variable-config";
import { formService } from "../../../../../../cdm/services/form-service";
import {
  createGroupProfile,
  createSelfMember
} from "../../../../lib/group-utilities-mcb";
import { isEmpty } from "../../../../../../utils/helpers";
import { apiService } from "../../../../../../cdm/services/api-service";
import { mcbEndpointConfig } from "../../../api-config";
import { getGroupRoles } from "../../../../../../cdm/lib/group-utilities";
import { UIText } from "../../../../../../config/lang-config";
import { autorun, observable } from "mobx";
import { typeClassLibrary } from "../../../../../../cdm/lib/typeClass-utilities";

const notifyHouseholdGroupCreation = async group => {
  if (isEmpty(group))
    return Promise.reject({
      message: "Cannot find group data. [notifyHouseholdGroupCreation]"
    });

  const selfMember =
    Array.isArray(group.members) &&
    group.members.find(m => m.userId === clientController.userId);

  if (!selfMember) return;

  const selfMemberProfileData =
    (selfMember.profile && selfMember.profile.data) ||
    (clientController.defaultMember.profile &&
      clientController.defaultMember.profile.data);

  const groupProfileRequestDTO = {
    data:
      group.profile && group.profile.data && JSON.stringify(group.profile.data)
  };
  const personalMemberProfileRequestDTO = {
    data: JSON.stringify(selfMemberProfileData)
  };

  const data = {
    careCircleGroupProfileRequestDTO: groupProfileRequestDTO,
    personalMemberProfileRequestDTO,
    groupId: group.id,
    groupTypeId: group.typeId,
    userId: clientController.userId,
    ifCheckCreatedTime: 1 // Default check minute after create for households.
  };

  return apiService
    .async("POST", {
      endpoint: mcbEndpointConfig.notify_care_circle_creation,
      data
    })
    .then(response => {
      console.log(response);
    })
    .catch(err => {
      console.error(err);
    });
};

export const householdSetup = () => {
  const setup = {
    key: "householdSetup",
    title: "Household",
    heading: "Tell us more about your needs",
    checkboxTitle:
      "I want access to undefined Tools to help ensure that the undefined is getting the care they require.",
    form: observable(householdForm()),
    onBlur: field => {
      field.errorMessage = "";
      if (field.required && !field.value)
        field.errorMessage = `Enter a ${(
          field.label || field.placeholder
        ).toLowerCase()}`;
    },
    onSubmit: async ([form, errorFields], store) => {
      // Group creation
      if (!form || !isEmpty(errorFields)) {
        return Promise.reject({
          message: UIText.checkEntriesWithFields(errorFields),
          contentAlign: "left"
        });
      }
      const roleTypeId = groupTypeRoleIds.primaryMember;
      form = form.filter(f => f.name !== "role");
      const profileDetail = formService.disassembleFormData(form, {
        displayName: true
      });
      let group = {
        typeId: groupTypeIds.household,
        owner: clientController.userId,
        parentId: null
      };
      return createGroupProfile(group, profileDetail, roleTypeId, store)
        .then(getGroupRoles)
        .then(createSelfMember)
        .then(([group, profileDetail, roles]) => group.id)
        .then(groupId => (group.id = groupId))
        .then(clientController.getUserGroupsAndMembers)
        .then(() => clientController.findGroupById(group.id))
        .then(notifyHouseholdGroupCreation)
        .finally(dispose);

      // return Promise.resolve()
    },
    validate: async form => {
      const pass = formService.validateRequired(form);
      if (!pass) {
        const errorFields = form.filter(f => !!f.errorMessage);
        return [false, errorFields];
      }
      return [form];
    }
  };
  const dispose = autorun(() => {
    typeClassLibrary.autoSelectEnglish(setup.form);
  });
  return setup;
};
