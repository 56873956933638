import { theme } from "../../../../config/style-configs/theme";

export const styles = {
  style: {
    flex: 1
  },
  heading: {
    fontSize: theme.FONT_SIZE_LARGE,
    width: "100%",
    textAlign: "center",
    color: theme.color,
    padding: 16
  }
};
