import React from "react";
import { Animated } from "react-native";
import { observer } from "mobx-react";
import { observable } from "mobx";

@observer
class AnimatedBottomBannerView extends React.Component {
  @observable bounceValue;

  constructor(props) {
    super(props);
    this.hiddenValue = (this.props.height || 100) + 20;
    this.bounceValue = new Animated.Value(this.hiddenValue);
  }

  animate = visible =>
    Animated.spring(this.bounceValue, {
      toValue: visible ? 0 : this.hiddenValue,
      velocity: 3,
      tension: 2,
      friction: 8
    }).start();

  render() {
    setTimeout(() => this.animate(this.props.visible));

    return (
      <Animated.View
        style={{
          ...this.props.styles.style,
          ...{ transform: [{ translateY: this.bounceValue }] }
        }}
      >
        {this.props.children}
      </Animated.View>
    );
  }
}

export { AnimatedBottomBannerView };
