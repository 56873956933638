import { common } from "../../../../config/style-configs/common";

export const styles = {
  card: common.card,
  cardContent: common.cardChildrenCompact,
  cardContentLarge: common.cardChildrenTextLarge,
  cardContentMedium: {
    ...common.cardChildrenTextMedium,
    fontWeight: "normal"
  },
  cardContentSmall: common.cardChildrenTextSmall
};
