import { theme } from "../../config/style-configs/theme";

const input = {
  width: "100%",
  minHeight: 40,
  fontSize: theme.FONT_SIZE_MEDIUM,
  paddingHorizontal: 5
};

export const styles = {
  style: {
    width: "100%",
    paddingHorizontal: 20,
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  input,
  inputWeb: {
    ...input,
    outlineStyle: "none"
  }
};

export const mCBDayTimePickerStyles = `
    .MuiPickerDTTabs-tabs,
    .MuiPickersToolbar-toolbar,
    .MuiPickersClockPointer-pointer,
    .MuiPickersClockPointer-noPoint,
    .MuiPickersClock-pin,
    .MuiPickersDay-daySelected {
      background-color: rgb(84, 56, 132);
    }
    .MuiPickersClockPointer-thumb {
      border-color: rgb(84, 56, 132)
    }
    .MuiButton-textPrimary,
    .MuiPickersDay-current {
      color: rgb(241, 95, 34);
    }
    .PrivateTabIndicator-colorSecondary-3 {
      background-color: rgb(241, 95, 34);
    }
    .MuiTypography-h4 {
      font-size: 1.6rem;
    }
    .MuiTypography-h3 {
      font-size: 2.6rem;
    }
    .MuiTypography-h2 {
      font-size: 3.2rem;
    }
    .MuiPickersToolbar-toolbar {
      height: 85px;
    }
    .MuiGrid-align-items-xs-flex-end {
      align-items: center;
    }
  `;
