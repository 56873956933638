import { common } from "../../../../config/style-configs/common";

export const styles = {
  style: {
    flex: 1
  },
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  scrollViewCard: common.scrollViewCard,
  loading: common.loading,
  errorMessage: common.containerErrorMessage,
  card: common.card,
  cardContent: common.cardChildrenCompact,
  cardContentLarge: common.cardChildrenTextLarge,
  cardContentMedium: {
    ...common.cardChildrenTextMedium,
    fontWeight: "normal"
  },
  cardContentMediumBold: {
    ...common.cardChildrenTextMedium,
    fontWeight: "600"
  },
  cardContentMediumBreakLine: {
    ...common.cardChildrenTextMedium,
    fontWeight: "normal",
    marginBottom: 10
  },
  cardContentSmall: common.cardChildrenTextSmall
};
