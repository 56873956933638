import { theme } from "../../config/style-configs/theme";

export const styles = {
  container: {
    backgroundColor: "#fff",
    boxShadow: "rgba(170, 170, 170, 0.5) 0px -1px 9px 0px",
    zIndex: 10
  },
  edgeComponent: {
    // paddingLeft: 5,
    // paddingRight: 5
  },
  centerComponentText: {
    fontSize: theme.FONT_SIZE_LARGE,
    color: theme.color,
    fontWeight: "600"
  },
  leftComponent: {},
  leftIconBadge: {
    position: "absolute",
    right: 7,
    top: 10,
    zIndex: 2
  },
  leftIconBadgeStyle: {
    minWidth: 10,
    minHeight: 10,
    borderRadius: 5
  },
  activityContainer: {
    justifyContent: "center",
    alignItems: "flex-start",
    paddingBottom: 2,
    width: 25
  },
  rightComponent: {
    flexDirection: "row"
  },
  rightIconBadge: {
    position: "absolute",
    top: 3,
    right: 3,
    zIndex: 1
  },
  iconTitle: {
    color: theme.color,
    fontSize: theme.FONT_SIZE_MEDIUM,
    fontWeight: "600"
  }
};
