import React from "react";
import { Text, View } from "react-native";
import { GroupHeading } from "../../../../components/GroupHeading/index";
import { TabHeader } from "../../../../components/TabHeader/index";
import { observer } from "mobx-react";
import { CaregiverEEList } from "../CaregiverEEList/index";
import { CareReceiverShiftList } from "../CareReceiverShiftList/index";
import { CareReceiverToDoList } from "../CareReceiverToDoList/index";
import { CareReceiverSmryList } from "../CareReceiverSmryList/index";
import { UIText } from "../../../../config/lang-config";
import { ApiTestList } from "../../../../components/ApiTestList";
import { CareReceiverReportList } from "../CareReceiverReportList";
import { CareReceiverIssueList } from "../CareReceiverIssueList";
import { MCBMessageList } from "../MCBMessageList";
import { MCBProactiveChatList } from "../MCBProactiveChatList";

const ComingSoon = observer(props => (
  <View
    style={{
      padding: 10
    }}
  >
    <Text style={props.styles.errorMessage}>{UIText.comingSoon}</Text>
  </View>
));

@observer
class GroupMainPageView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  getTabComponent = tabKey => {
    if (tabKey === "test") {
      return <ApiTestList />;
    }
    if (tabKey === "summary") {
      return <CareReceiverSmryList />;
    }
    if (tabKey === "employment") {
      return <CaregiverEEList />;
    }
    if (tabKey === "shift") {
      return <CareReceiverShiftList />;
    }
    if (tabKey === "task") {
      return <CareReceiverToDoList />;
    }
    if (tabKey === "issue") {
      return <CareReceiverIssueList />;
    }
    if (tabKey === "report") {
      return <CareReceiverReportList />;
    }
    if (tabKey === "mcbMessage" || tabKey === "groupMessage") {
      return <MCBMessageList />;
    }
    if (tabKey === "proactiveChats") {
      return <MCBProactiveChatList />;
    }
    return <ComingSoon styles={this.props.styles} />;
  };

  render() {
    this.pageTabs = this.controller.topTabs.filter(Boolean);

    return (
      <View style={this.props.styles.style}>
        <GroupHeading
          group={this.props.group}
          profile={this.controller.profileData}
          avatar={this.controller.groupAvatar}
          showAppointment={this.props.headerShowAppointment}
          showBilling={this.props.headerShowBilling}
          showEdit={this.props.headerShowEdit}
          groupTypeName={this.controller.groupTypeName}
          timezoneName={this.controller.timezoneName}
          handleHeadingPress={this.controller.handleHeadingPress}
          handleBillingPress={this.controller.handleBillingPress}
          handleAppointmentPress={this.controller.handleAppointmentPress}
        />

        {/*// TODO: This tab needs to be swippable*/}
        <TabHeader
          tabs={this.pageTabs}
          currentKey={this.controller.currentTabKey}
        />

        {this.getTabComponent(this.controller.currentTabKey)}
      </View>
    );
  }
}

export { GroupMainPageView };
