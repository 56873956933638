import React from "react";
import { View } from "react-native";
import { Header } from "../../../components/Header";
import { observer } from "mobx-react";
import { GroupFinderPage } from "../../../custom/mcb/components/GroupFinderPage";

const link =
  "https://calendly.com/mycarebase/interview?primary_color=f15f22&name=Caregiver10%20User&phone=7788888888&email=caregiver10%40jateruy.com";
const CalendlyEmbedHtml = `<html>
<head>
</head>
<body>
  <div class="calendly-inline-widget" style="min-width:320px;height:580px;" data-url="${link}">
    <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
    <script>
    </script>
  </div>
</body>
</html>`;

@observer
class TestScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentDidMount() {
    // const head = document.querySelector("head");
    // const script = document.createElement("script");
    // script.setAttribute(
    //   "src",
    //   "https://assets.calendly.com/assets/external/widget.js"
    // );
    // head.appendChild(script);
    // function isCalendlyEvent(e) {
    //   return e.data.event && e.data.event.indexOf("calendly") === 0;
    // }
    // window.addEventListener("message", function(e) {
    //   if (isCalendlyEvent(e)) {
    //     console.log(e.data);
    //   }
    // });
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <Header
          title={this.props.navigation.state.key}
          leftIcon="menu"
          navigation={this.props.navigation}
        />
        <View style={{ flex: 1 }}>
          {/*<CaregiverAvailabilityForm*/}
          {/*  profileForm={[*/}
          {/*    {*/}
          {/*      name: "availability",*/}
          {/*      value: [*/}
          {/*        {*/}
          {/*          end: "23:59:00",*/}
          {/*          name: "",*/}
          {/*          start: "10:08:00",*/}
          {/*          // location: 0,*/}
          {/*          dayOfWeek: 0,*/}
          {/*          maxCommuteMinutes: 30*/}
          {/*        },*/}
          {/*        {*/}
          {/*          end: "23:11:00",*/}
          {/*          name: "",*/}
          {/*          start: "20:11:00",*/}
          {/*          location: 0,*/}
          {/*          dayOfWeek: 2,*/}
          {/*          maxCommuteMinutes: 30*/}
          {/*        }*/}
          {/*      ]*/}
          {/*    },*/}
          {/*    {*/}
          {/*      name: "availabilityLocations",*/}
          {/*      value: [*/}
          {/*        {*/}
          {/*          name: "Home",*/}
          {/*          location: {*/}
          {/*            lat: 49.195113,*/}
          {/*            lng: -123.1788482,*/}
          {/*            city: "Richmond",*/}
          {/*            country: "Canada",*/}
          {/*            placeId: "ChIJm6MnhjQLhlQRhIA0hqzMaLo",*/}
          {/*            street1: "3211 Grant McConachie Way",*/}
          {/*            street2: "",*/}
          {/*            provState: "British Columbia",*/}
          {/*            postalCode: "V7B 0A4",*/}
          {/*            formattedAddress:*/}
          {/*              "3211 Grant McConachie Way, Richmond, BC V7B 0A4, Canada"*/}
          {/*          }*/}
          {/*        }*/}
          {/*      ]*/}
          {/*    }*/}
          {/*  ]}*/}
          {/*/>*/}
          <GroupFinderPage />
        </View>
      </View>
    );
  }
}

export { TestScreen };
