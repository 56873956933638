import { observer } from "mobx-react";
import React from "react";
import { View } from "react-native";
import { Header } from "../../../components/Header";

@observer
class WizardScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  render() {
    return (
      <View style={this.props.styles.style}>
        <Header
          title="Edit Profile"
          leftIcon={this.controller.showLogout ? "exit-to-app" : "arrow-back"}
          handleLeftPress={this.controller.handleBackPress}
          navigation={this.props.navigation}
        />
        <View style={this.props.styles.frameContainer}>
          <iframe
            style={this.props.styles.frame}
            src={this.controller.frameUrl}
          />
        </View>
      </View>
    );
  }
}

export { WizardScreen };
