import React from "react";
import { CaregiverJobReferenceController } from "./controller";
import { CaregiverJobReferenceView } from "./view";
import { extraStyles, styles } from "./styles";

export class CaregiverJobReference extends React.Component {
  static navigationOptions = {
    title: `myCareBase Job Reference Form`
  };

  controller = new CaregiverJobReferenceController(this.props);

  render() {
    return (
      <CaregiverJobReferenceView
        {...this.props}
        styles={styles}
        extraStyles={extraStyles}
        controller={this.controller}
      />
    );
  }
}
