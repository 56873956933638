import { theme } from "../../config/style-configs/theme";

export const styles = {
  style: {
    flexDirection: "column",
    alignSelf: "flex-end",
    width: "100%",
    backgroundColor: "#fff"
  },
  top: {
    alignSelf: "center",
    width: "80%",
    maxWidth: 600,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 40
  },
  bottom: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  chatInputContainer: {
    flex: 9,
    paddingRight: 0
  },
  chatInput: {
    padding: 0,
    marginTop: 15,
    marginBottom: 15,
    borderBottomWidth: 0
  },
  inputHeightCalculator: {
    position: "absolute",
    width: "100%",
    maxHeight: 250,
    overflowY: "auto",
    paddingLeft: 10,
    paddingRight: 45,
    visibility: "hidden",
    opacity: 0,
    transform: [{ translateY: "500%" }]
    /*  For debug  */
    // backgroundColor: "#ff66c0",
    // top: -400
  },
  inputHeightCalculatorText: { fontSize: theme.FONT_SIZE_MEDIUM },
  chatSend: {
    padding: 5,
    minWidth: 45,
    maxWidth: 45
  }
};
