import React from "react";
import { MCBMessageListView } from "./view";
import { MCBMessageListController } from "./controller";
import { styles } from "./styles";

export class MCBMessageList extends React.Component {
  controller = new MCBMessageListController(this.props);

  render() {
    return (
      <MCBMessageListView
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
