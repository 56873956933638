import React from "react";
import { TopicTaskListController } from "./controller";
import { TopicTaskListView } from "./view";
import { styles } from "./styles";

export class TopicTaskList extends React.Component {
  render() {
    const controller = new TopicTaskListController(this.props);

    return (
      <TopicTaskListView
        controller={controller}
        {...this.props}
        styles={styles}
      />
    );
  }
}
