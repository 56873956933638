import { Linking, Platform } from "react-native";
import { clientController } from "../../../cdm/controllers/client-controller";
import { stateController } from "../../../cdm/controllers/state-controller";
import { computed, observable } from "mobx";
import { UIText } from "../../../config/lang-config";
import {
  capitalize,
  isEmpty,
  isEqual,
  popupCenter,
  preventDefaultStopProp,
  randomString
} from "../../../utils/helpers";
import { apiController } from "../../../cdm/controllers/api-controller";
import {
  billingEnabledGroupTypeIds,
  env,
  placeholderPickerValue,
  planMarketingContactUrl,
  planMarketingNumber,
  planMarketingUrl,
  wpAddPaymentMethodUri,
  wpPaymentMethodUri
} from "../../../config/variable-config";
import { formService } from "../../../cdm/services/form-service";
import {
  checkGroupSubscription,
  getUserWCPaymentSources,
  getWpAutoLoginLink,
  getWpWcBillingDetail,
  submitPaymentChange,
  submitPaymentPlanChange,
  updateWpWcBillingDetail
} from "../../../cdm/lib/group-utilities";
import { getCountryNameByISO } from "../../../utils/country-code";
import { paidCaregiverFeatureRestricted } from "../../../custom/mcb/lib/group-utilities-mcb";
import { apiService } from "../../../cdm/services/api-service";
import { getBillingForm } from "../../../custom/mcb/config/billing_form";
import { endpointConfig } from "../../../config/api-config";

export class PlanController {
  @observable loading = true;
  @observable submitting = false;

  @observable updatePaymentFullyVisible = false;

  @observable ccComplete = false;
  @observable validPromoCode = null;
  promoCodeError = {};

  @observable isPaymentUpdate = false;

  @observable wpOrderSubmitTest = false; // TODO: Temporary test
  @observable wpWcBillingDetail = {};
  @observable wpWcPaymentSources = [];

  @observable pickerResidentialProvinceOptions = [
    {
      name: "",
      placeholder: "Please select..."
    },
    {
      name: "BC",
      placeholder: "British Columbia"
    },
    {
      name: "ON",
      placeholder: "Ontario"
    }
  ];

  @observable billingForm = getBillingForm();

  @observable priceBreakdown = {};

  @computed get isVisitor() {
    return clientController.isVisitor;
  }
  @computed get user() {
    return clientController.client.user || {};
  }
  @computed get setupMode() {
    return stateController.viewPlanState.setupMode;
  }
  @computed get currentPage() {
    return stateController.viewPlanState.currentPage;
  }
  @computed get groupId() {
    return !this.setupMode && stateController.viewGroupId;
  }
  @computed get currentGroup() {
    return clientController.findGroupById(this.groupId);
  }
  @computed get currentGroupSubscription() {
    return this.currentGroup.subscription || {};
  }
  @computed get currentGrouSubscriptionIsPAYG() {
    return env !== "prod" && !(this.currentGroupSubscription.plan || {}).amount;
  }
  @computed get userIsSubscriptionOwner() {
    return this.currentGroupSubscription.userId === clientController.userId;
  }
  @computed get currentGroupIsScratchpad() {
    return clientController.scratchpadId === this.groupId;
  }
  @computed get profile() {
    return (this.currentGroup.profile && this.currentGroup.profile.data) || {};
  }
  @computed get selfMember() {
    return (
      (Array.isArray(this.currentGroup.members) &&
        this.currentGroup.members.find(m => m.userId === this.user.id)) ||
      {}
    );
  }
  @computed get isSelfOwner() {
    return this.currentGroup.owner === this.user.id;
  }
  @computed get plans() {
    return stateController.viewPlanState.plans || [];
  }
  @computed get planCategories() {
    let categories = [];
    for (let plan of this.plans) {
      !categories.find(c => c.id === plan.categoryId) &&
        categories.push({
          ...plan.category,
          displayPlanId: plan.id,
          showDivider: categories.length > 0
        });
    }
    return categories.sort((a, b) => a.id - b.id);
  }
  @computed get selectedPlan() {
    return this.plans.find(p => p.checked) || {};
  }
  @computed get planChanged() {
    return (
      !isEmpty(this.selectedPlan) &&
      this.currentGroupSubscription.planId !== this.selectedPlan.id &&
      !!(this.residentialProvince || this.pickerResidentialProvince) &&
      this.pickerResidentialProvince !== placeholderPickerValue.toString() &&
      (!this.promoCodeValue || (this.promoCodeValue && this.validPromoCode))
    );
  }
  @computed get billingFormData() {
    return stateController.viewPlanState.billingFormData || {};
  }
  @computed get validBilling() {
    return (
      formService.validateRequired(this.billingForm, true) &&
      (this.isWpWcMode ? !isEmpty(this.wpWcPaymentSources) : this.ccComplete)
    ); // true === don't show error message.
  }
  @computed get ccToken() {
    return stateController.viewPlanState.ccToken || {};
  }
  @computed get last4() {
    return this.ccToken.card && this.ccToken.card.last4;
  }
  @computed get postalCode() {
    return stateController.viewPlanState.postalCode || "";
  }
  @computed get residentialProvince() {
    return this.profile.provinces;
  }
  @computed get inputResidentialProvince() {
    const province = this.residentialProvince;
    const option = this.pickerResidentialProvinceOptions.find(
      o => o.name === province
    );
    return option ? option.placeholder : " ";
  }
  @computed get pickerResidentialProvince() {
    return stateController.viewPlanState.residentialProvince;
  }
  @computed get promoCodeValue() {
    return (
      stateController.viewPlanState.promoCode &&
      stateController.viewPlanState.promoCode.toUpperCase()
    );
  }
  @computed get promoData() {
    return stateController.viewPlanState.promoData;
  }
  @computed get preSelectedPlanId() {
    return this.user.planId;
  }
  @computed get prePromoCode() {
    return this.user.promoCode;
  }
  @computed get promoNoCharge() {
    return (
      this.selectedPlan.amount &&
      !isEmpty(this.promoData) &&
      !isEmpty(this.priceBreakdown) &&
      this.priceBreakdown.total + this.priceBreakdown.discount === 0
    );
  }

  @computed get wpAutoOpenLink() {
    return this.props.navigation.getParam("wpUri");
  }
  @computed get isWpWcMode() {
    return false;
    // return env !== "prod" && !this.selectedPlan.amount;
  }
  @computed get wpWcBillingFormData() {
    return {
      addr1: this.wpWcBillingDetail["address_1"],
      addr2: this.wpWcBillingDetail["address_2"],
      city: this.wpWcBillingDetail.city,
      prov: this.profile["provinces"],
      country: "CA",
      postalCode: this.wpWcBillingDetail["postcode"],
      phone: this.wpWcBillingDetail.phone
    };
  }

  constructor(props) {
    this.props = props;
    stateController.currentScreen = "Plan";

    setTimeout(this._initialize);

    // TODO WIP
    // apiController
    //   .getUserSubscriptionPayment(clientController.userId)
    //   .then(result => {
    //     console.log(safeParseJSON(result.detail));
    //   });
  }

  _initialize = () =>
    this._reset()
      .then(this._loadAllData)
      .then(this._getGroupSubscription)
      .then(() => (this.loading = false))
      .catch(err => this._showError(err, true))
      .finally(() => {
        setTimeout(() => (this.updatePaymentFullyVisible = true));
      });

  _reset = async () => {
    if (this.setupMode) return Promise.resolve();
    stateController.clearPlanState();
    this.billingForm = getBillingForm();
    return Promise.resolve();
  };

  _loadAllData = () =>
    Promise.all(
      [
        this._getGroup(),
        this._getPlans()
        // env !== "prod" &&
        //   this.getWpWcPaymentSource().then(
        //     this.checkPAYGGroupWpWcPaymentMethods
        //   )
      ].filter(Boolean)
    );

  _showError = (err, kickout, promoCodeError) => {
    console.warn(err);
    return stateController.showPopup({
      title: UIText.planBillingPlan,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      contentAlign: promoCodeError && "left",
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: () =>
        stateController.dismissPopup().then(() => {
          if (kickout) {
            this._reset().then(() => {
              this.props.navigation.navigate("Group");
            });
          }
          if (
            err.response &&
            err.response.status === 400 &&
            this.currentPage === "confirm"
          ) {
            stateController.viewPlanState.currentPage = "payment";
            this.scrollToTop();
            this.ccComplete = false;
          }
        }),
      leftButtonText: promoCodeError && UIText.planClearPromoCode,
      leftButtonPress: promoCodeError && this.clearPromoCode
    });
  };

  _getGroup = async () => {
    const groupIdOverride = Number(this.props.navigation.getParam("group"));
    if (groupIdOverride && clientController.loginState && !this.setupMode) {
      stateController.viewGroupId = groupIdOverride;
    }

    // If is visitor account or currentGroup not exist or not a billing enabled type of group, back to Root.
    if (
      this.isVisitor ||
      ((isEmpty(this.currentGroup) ||
        !billingEnabledGroupTypeIds.includes(this.currentGroup.typeId)) &&
        !this.setupMode)
    ) {
      if (this.wpAutoOpenLink) await this.execWpAutoLoginLink();
      stateController.viewGroupId = 0;
      return this.props.navigation.navigate("Root");
    }

    // If it's paid caregiver accessing the page, back out.
    if (paidCaregiverFeatureRestricted(this.currentGroup, this.selfMember)) {
      return stateController
        .showPopup({
          title: UIText.planBillingPlan,
          content: UIText.planNotAccessible,
          leftButtonText: UIText.generalConfirm
        })
        .then(this.handleBackPress);
    }

    if (this.groupId && !this.setupMode)
      stateController.viewGroupState.subscriptionChecked[this.groupId] = true;

    if (this.wpAutoOpenLink) this.execWpAutoLoginLink();

    return Promise.resolve();
  };

  _getPlans = async () => {
    if (this.setupMode && this.currentPage === "confirm")
      return this._restoreSetupMode();

    return apiController.getSubscriptionPlans(this.groupId).then(plans => {
      plans = this.sortPlans(plans);
      stateController.viewPlanState.plans = plans;

      const visibleGroups = clientController.findVisibleGroups();

      if (visibleGroups.length <= 1 && isEmpty(this.currentGroupSubscription)) {
        this.preSelectedPlanId &&
          this.handlePlanPress({ id: this.preSelectedPlanId });
        if (this.prePromoCode) {
          stateController.viewPlanState.promoCode = this.prePromoCode;
          return this.checkPromoCode();
        }
      }
    });
  };

  _getGroupSubscription = async () => {
    return await checkGroupSubscription(this.groupId).then(subscription => {
      const plan = this.plans.find(pl => pl.id === subscription.planId);
      if (plan) this.handlePlanPress(plan, true);
    });
  };

  _restoreSetupMode = async () => {
    return this.getPriceBreakdown().then(() => {
      for (let data in this.billingFormData) {
        if (this.billingFormData.hasOwnProperty(data)) {
          const field = this.billingForm.find(f => f.name === data);
          if (field) field.value = this.billingFormData[data];
        }
      }
    });
  };

  sortPlans = plans =>
    plans.sort((a, b) => {
      const catIdA = a.categoryId;
      const catIdB = b.categoryId;

      if (catIdA === catIdB) return a.id - b.id;

      return catIdA - catIdB;
    });

  handlePlanPress = async (plan, noPromoCheck) => {
    if (!plan) return;

    for (let pl of this.plans) {
      pl.checked = plan.id === pl.id;
    }

    if (!noPromoCheck) {
      return this.checkPromoCode();
    }

    return Promise.resolve();
  };

  handleCardChange = event => {
    const { value, complete } = event;

    stateController.viewPlanState.postalCode =
      (value && value.postalCode) || "";

    this.ccComplete = !!complete;

    if (this.ccComplete) {
      this.getCardInfo();
      this.billingFormFocus();
    } else {
      const prov = this.billingForm.find(f => f.name === "prov");
      prov.placeholder = "Province/State";
      prov.value = "";
      this.billingForm.find(f => f.name === "country").value = "";
    }

    stateController.viewPlanState.ccToken = null;
  };

  handleBackPress = event => {
    if (this.currentPage === "plan") {
      if (this.setupMode) {
        // if (stateController.initSetupMode === 3) {
        if (stateController.initSetupMode === "prePlanPromo") {
          // clientController.execLogout(true, true);
          /* First time setup scenario, so go to scratchpad if back is pressed. */
          stateController.viewGroupId = -1;
          return this.props.navigation.navigate("Root");
        } else {
          stateController.initSetupMode = "addGroup";
          this.props.navigation.navigate("Setup");
        }
      } else {
        this._reset().then(() => {
          const id = stateController.viewGroupId;
          return this.props.navigation.navigate("Group", { group: id });
        });
      }
    } else if (this.currentPage === "payment") {
      if (this.isWpWcMode) this._reset().catch(console.warn);
      stateController.viewPlanState.currentPage = "plan";
      this.isPaymentUpdate = false;
      this.scrollToTop();
    } else if (this.currentPage === "confirm") {
      stateController.viewPlanState.currentPage = "payment";
      this.scrollToTop();
      if (!this.isWpWcMode) this.ccComplete = false;
    }
    return true;
  };

  handleNextPress = event => {
    if (this.currentPage === "plan") {
      stateController.viewPlanState.currentPage = "payment";
      this.scrollToTop();
      this.ccComplete = false;
      if (this.isWpWcMode) this.modBillingFormForWpMode();
    } else if (this.currentPage === "payment") {
      if (!this.validBilling) return;

      const advance = () =>
        stateController
          .showPopup({
            title: UIText.planBillingAddress,
            content: UIText.pleaseWait
          })
          .then(this.storeBilling)
          .then(
            () =>
              this.isWpWcMode &&
              !isEqual(this.billingFormData, this.wpWcBillingFormData) &&
              updateWpWcBillingDetail(this.billingFormData)
          )
          .then(this.paymentOrPlanUpdate);

      if (this.isWpWcMode) {
        if (this.wpOrderSubmitTest)
          return this.execWooCommerceOrderTestSubmit();

        return stateController
          .showPopup({
            title: UIText.planPaymentMethods,
            content: UIText.pleaseWait
          })
          .then(this.getWpWcPaymentSource)
          .then(stateController.dismissPopup)
          .then(() =>
            this.validBilling ? advance() : this.popupRequestWpWcPaymentMethod()
          )
          .catch(this._showError)
          .finally(() => (this.submitting = false));
      }

      this.getToken()
        .then(token => (stateController.viewPlanState.ccToken = token))
        .then(advance)
        .catch(this._showError)
        .finally(() => (this.submitting = false));
    } else {
      if (this.setupMode) {
        this.props.navigation.navigate("Setup");
        stateController.viewPlanState.setupMode = false;
      } else {
        // if (this.isWpWcMode) {
        //   return stateController.showPopup({
        //     title: UIText.planBillingPlan,
        //     content: UIText.comingSoon,
        //     leftButtonText: UIText.generalConfirm
        //   });
        // }

        this.submitPaymentPlanChange()
          .then(stateController.dismissPopup)
          .then(this._getGroupSubscription)
          .then(() => {
            if (this.currentGroupIsScratchpad)
              clientController.setScratchpadId(0); // Group is no longer scratchpad.
          })
          .then(() =>
            stateController.showPopup({
              title: capitalize(UIText.success),
              content: UIText.planChangePlanSuccess,
              leftButtonText: UIText.generalConfirm,
              leftButtonPress: () =>
                stateController.dismissPopup().then(() =>
                  this.props.navigation.navigate("Group", {
                    group: this.groupId
                  })
                )
            })
          )
          .catch(this._showError)
          .finally(() => (this.submitting = false));
      }
    }
  };

  handleMarketingLinkPress = event => {
    preventDefaultStopProp(event);
    if (Platform.OS === "web") {
      return window.open(planMarketingUrl);
    } else {
      return Linking.openURL(planMarketingUrl);
    }
  };

  handleMarketingNumberPress = event => {
    preventDefaultStopProp(event);
    if (Platform.OS === "web") {
      return window.open(`tel:${planMarketingNumber}`);
    } else {
      return Linking.openURL(`tel:${planMarketingNumber}`);
    }
  };

  handleMarketingContactFormPress = event => {
    preventDefaultStopProp(event);
    if (Platform.OS === "web") {
      return window.open(planMarketingContactUrl);
    } else {
      return Linking.openURL(planMarketingContactUrl);
    }
  };

  handleProvinceChangePress = event => {
    preventDefaultStopProp(event);

    const screenId = randomString();
    stateController.viewProfileState.backButtonOverride[screenId] = {
      handlePress: () =>
        this.props.navigation.navigate("Plan", { group: this.groupId })
    };
    stateController.viewProfileState.fieldToScrollAt[screenId] = "provinces";

    this.props.navigation.navigate("Profile", {
      group: this.groupId,
      profile: this.currentGroup.profileId,
      screenId
    });
  };

  handleUpdatePaymentPress = event => {
    preventDefaultStopProp(event);
    if (this.currentGrouSubscriptionIsPAYG) {
      return this.openWpPaymentPageInChildWindow();
    }
    this.isPaymentUpdate = true;
    this.resetBillingForm();
    return this._reset().then(() => {
      stateController.viewPlanState.currentPage = "payment";
      return this.scrollToTop();
    });
  };

  checkPromoCode = async event => {
    this.validPromoCode = null;
    if (!this.selectedPlan.id) return;
    if (this.currentGroupSubscription.planId === this.selectedPlan.id) return;

    return apiController
      .getPlanPromoCodeValidity(
        this.groupId,
        this.selectedPlan.id,
        this.promoCodeValue
      )
      .then(result => {
        if (!isEmpty(result)) {
          this.validPromoCode = true;
          stateController.viewPlanState.promoData = { ...result };
        }
      })
      .catch(err => {
        this.validPromoCode = false;
        stateController.viewPlanState.promoData = {};
        const errorData = err.response && err.response.data;
        const previousErrorData =
          this.promoCodeError.response && this.promoCodeError.response.data;
        if (
          isEmpty(previousErrorData) ||
          previousErrorData.type !== errorData.type
        )
          this.handlePromoCodeError(err);
        this.promoCodeError = err;
      });
  };

  handlePromoCodeError = err => {
    if (!err || err.response.status !== 406) {
      return this._showError(err, null, true);
    }

    let error;

    const { type } = err.response.data;

    if (type === "notFound") {
      error = {
        message: UIText.planPromoNotFound(this.promoCodeValue)
      };
    }
    if (type === "exceeded") {
      error = {
        message: UIText.planPromoExceededUsage(this.promoCodeValue)
      };
    }
    if (type === "expired") {
      error = {
        message: UIText.planPromoExpired(this.promoCodeValue)
      };
    }
    if (type === "notApplicable") {
      const { planIdList } = err.response.data;
      if (!Array.isArray(planIdList)) return this._showError(err, null, true);

      const suitablePlanString = this.plans
        .map(
          p =>
            planIdList.includes(p.id.toString()) &&
            `${p.productName} - ${p.nickname}`
        )
        .filter(Boolean)
        .join("\n");

      error = {
        message: UIText.planPromoNotApplicable(
          this.promoCodeValue,
          suitablePlanString
        )
      };
    }

    return this._showError(error, null, true);

    // this._showError(err, null, true);
  };

  getCardInfo = () => {
    this.getToken(true).then(token => {
      const cardInfo = token && token.card;

      if (cardInfo) {
        const isUS = cardInfo.country === "US";
        this.billingForm.find(f => f.name === "prov").placeholder = isUS
          ? UIText.planBillingState
          : UIText.planBillingProvince;
        this.billingForm.find(
          f => f.name === "country"
        ).value = getCountryNameByISO(cardInfo.country);
        const provState = this.billingForm.find(f => f.name === "prov");
        if (provState) provState.value = cardInfo["address_state"] || "";
        const postalCode = this.billingForm.find(f => f.name === "postalCode");
        if (postalCode) postalCode.value = cardInfo["address_zip"] || "";
      }
    });
  };

  getToken = async noSubmit => {
    this.submitting = !noSubmit;
    let card = {};
    this.createToken && (await this.createToken(card));
    const { token, error } = card;

    if (error) return Promise.reject({ message: error && error.message });
    if (token) return token;
    if (!token && !error)
      return Promise.reject({ message: UIText.planInvalidCard });
  };

  storeBilling = async () => {
    stateController.viewPlanState.billingFormData = formService.disassembleFormData(
      this.billingForm
    );
    if (this.postalCode)
      stateController.viewPlanState.billingFormData.postalCode = this.postalCode;
  };

  resetBillingForm = () => {
    for (let field of this.billingForm) {
      field.value && delete field.value;
    }
  };

  paymentOrPlanUpdate = () => {
    if (this.isPaymentUpdate)
      return this.submitPaymentChange()
        .then(stateController.dismissPopup)
        .then(() =>
          stateController.showPopup({
            title: capitalize(UIText.success),
            content: UIText.planChangePaymentSuccess,
            leftButtonText: UIText.generalConfirm,
            leftButtonPress: () =>
              stateController.dismissPopup().then(() =>
                this.props.navigation.navigate("Group", {
                  group: this.groupId
                })
              )
          })
        );

    return this.getPriceBreakdown()
      .then(stateController.dismissPopup)
      .then(() => (stateController.viewPlanState.currentPage = "confirm"))
      .then(this.scrollToTop);
  };

  getPriceBreakdown = async () => {
    const planId = this.selectedPlan.id;

    const territory =
      this.residentialProvince || this.pickerResidentialProvince;

    return apiController
      .getPlanPriceBreakdown(
        planId,
        territory,
        !this.selectedPlan.displayName,
        this.promoCodeValue
      )
      .then(result => {
        // console.log(result);
        this.priceBreakdown = isEmpty(result)
          ? this.selectedPlan.interval === "hour"
            ? { payAsYouGo: true }
            : null
          : result;
      })
      .catch(this._showError);
  };

  submitPaymentPlanChange = async () => {
    this.submitting = true;

    const payment = {
      userId: clientController.userId,
      paymentSource: this.ccToken.id
    };
    const planSubscription = {
      planId: this.selectedPlan.id,
      groupId: this.currentGroup.id,
      chargeDiscountFactor: this.selectedPlan.chargeDiscountFactor
    };
    const trial = !this.selectedPlan.displayName && !this.validPromoCode;
    const customer = this.billingFormData;
    const promoCode = this.promoCodeValue;

    return stateController
      .showPopup({
        title: UIText.planBillingPlan,
        content: UIText.pleaseWait
      })
      .then(() =>
        submitPaymentPlanChange({
          planSubscription,
          payment,
          customer,
          trial,
          promoCode
        })
      );
  };

  submitPaymentChange = async () => {
    this.submitting = true;

    const payment = {
      userId: clientController.userId,
      paymentSource: this.ccToken.id
    };
    const planSubscription = {
      groupId: this.currentGroup.id
    };
    const customer = this.billingFormData;

    return stateController
      .showPopup({
        title: UIText.planBillingPlan,
        content: UIText.pleaseWait
      })
      .then(() =>
        submitPaymentChange({
          planSubscription,
          payment,
          customer
        })
      );
  };

  onProvinceChange = value => {
    stateController.viewPlanState.residentialProvince = value;
  };

  onFieldInputChange = (field, event) => {
    if (!event) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      field.value = event.nativeEvent.text;
  };

  onFieldBlur = (field, event) => {
    field.errorMessage = "";
    if (field.required && !field.value)
      field.errorMessage = `${UIText.entryRequiring} ${(
        field.label || field.placeholder
      ).toLowerCase()}.`;
  };

  onPromoCodeChange = event => {
    if (!event) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string") {
      this.validPromoCode = null;
      this.promoCodeError = {};
      stateController.viewPlanState.promoCode = event.nativeEvent.text;
    }
  };

  onPromoCodeErrorPress = event => {
    preventDefaultStopProp(event);
    this.promoCodeError && this.handlePromoCodeError(this.promoCodeError);
  };

  clearPromoCode = event => {
    stateController.dismissPopup().then(() => {
      this.validPromoCode = null;
      stateController.viewPlanState.promoCode = "";
    });
  };

  createTokenCallback = method => (this.createToken = method);

  setScrollToTop = method => (this.scrollToTop = method);

  setBillingFormFocus = method => (this.billingFormFocus = method);

  helpFocusNextField = (field, formFields, form) =>
    formService.helpFocusNextField(
      field,
      formFields,
      form,
      this.handleNextPress
    );

  /**
   * WP WooCommerce Stripe integrations
   */
  execWpAutoLoginLink = () =>
    getWpAutoLoginLink(this.wpAutoOpenLink)
      .then(link => {
        if (Platform.OS === "web") {
          return window.open(link, "_blank");
        } else {
          return Linking.openURL(link);
        }
      })
      .then(stateController.dismissPopup)
      .catch(this._showError);

  getWpWcPaymentSource = async () => {
    return getUserWCPaymentSources().then(
      sources => (this.wpWcPaymentSources = sources)
    );
  };

  getWpWcBillingDetail = async () =>
    getWpWcBillingDetail().then(billing => (this.wpWcBillingDetail = billing));

  checkPAYGGroupWpWcPaymentMethods = () => {
    if (
      this.currentPage === "plan" &&
      this.currentGrouSubscriptionIsPAYG &&
      isEmpty(this.wpWcPaymentSources)
    ) {
      return this.popupRequestWpWcPaymentMethod(true);
    }
  };

  popupRequestWpWcPaymentMethod = blockedAccess =>
    stateController.showPopup({
      title: UIText.planMissingPaymentMethod,
      content: UIText.planRequestAddDefaultSource,
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: () =>
        this.openWpPaymentPageInChildWindow(blockedAccess),
      leftButtonText: blockedAccess && UIText.generalCancel,
      leftButtonPress:
        blockedAccess &&
        (() => stateController.dismissPopup().then(this.handleBackPress))
    });

  openWpPaymentPageInChildWindow = async blockedContinuation => {
    const cWindow = popupCenter("/?strict_loading=1", "", 900, 600);
    return stateController
      .showPopup({
        title: UIText.planPaymentMethods,
        content: UIText.pleaseWait
      })
      .then(() =>
        getWpAutoLoginLink(
          isEmpty(this.wpWcPaymentSources)
            ? wpAddPaymentMethodUri
            : wpPaymentMethodUri
        )
      )
      .then(link => {
        cWindow.location.href = link;
        const checkClosed = async () => {
          if (cWindow.closed) return;
          if (!clientController.loginState) return;
          await this.getWpWcPaymentSource();
          return checkClosed();
        };
        return checkClosed();
      })
      .then(stateController.dismissPopup)
      .then(() => {
        if (blockedContinuation && isEmpty(this.wpWcPaymentSources)) {
          return this.popupRequestWpWcPaymentMethod(blockedContinuation);
        }
      })
      .catch(this._showError);
  };

  modBillingFormForWpMode = event => {
    if (event) {
      this.wpOrderSubmitTest = true;
      this.handleNextPress();
    }
    return stateController
      .showPopup({
        title: UIText.planBillingPlan,
        content: UIText.pleaseWait
      })
      .then(this.getWpWcBillingDetail)
      .then(() => {
        this.ccComplete = true;
        this.billingForm = this.billingForm.filter(f => f.name !== "name");
        const cityField = this.billingForm.find(f => f.name === "city");
        cityField.value = this.wpWcBillingDetail.city;
        const provField = this.billingForm.find(f => f.name === "prov");
        provField.required = true;
        provField.disabled = true;
        const countryField = this.billingForm.find(f => f.name === "country");
        countryField.required = true;
        countryField.disabled = true;
        if (!this.billingForm.some(f => f.name === "postalCode")) {
          const postalCodeField = {
            name: "postalCode",
            type: "input",
            placeholder: "Postal code",
            required: true
          };
          this.billingForm.splice(
            this.billingForm.length - 2,
            0,
            postalCodeField
          );
        }
        return this.populateWpWcBillingForm();
      })
      .then(stateController.dismissPopup);
  };

  populateWpWcBillingForm = () => {
    for (const field of this.billingForm) {
      const { name } = field;
      if (this.wpWcBillingFormData[name])
        field.value = this.wpWcBillingFormData[name];
    }
  };

  // TODO: Temporary test submit order
  execWooCommerceOrderTestSubmit = async () => {
    this.storeBilling();
    const data = {
      ...this.billingFormData,
      productId: 612 // TODO: Temporary hard code
    };
    return stateController
      .showPopup({
        title: UIText.planBillingPlan,
        content: UIText.pleaseWait
      })
      .then(() =>
        apiService.async("POST", {
          endpoint: endpointConfig.create_new_order,
          data
        })
      )
      .then(stateController.dismissPopup)
      .then(() =>
        stateController.showPopup({
          title: UIText.planBillingPlan,
          content: "Order created",
          leftButtonText: UIText.generalConfirm,
          leftButtonPress: () =>
            stateController.dismissPopup().then(this._reset)
        })
      )
      .catch(this._showError);
  };
}
