import { observable } from "mobx";
import { taskTemplateForm, taskTemplatePickerForm } from "./form";
import { formService } from "../../../../../../cdm/services/form-service";
import { topicTypeIds } from "../../../../../../config/variable-config";
import { apiService } from "../../../../../../cdm/services/api-service";
import { endpointConfig } from "../../../../../../config/api-config";
import { stateController } from "../../../../../../cdm/controllers/state-controller";
import { clientController } from "../../../../../../cdm/controllers/client-controller";
import { isEmpty } from "../../../../../../utils/helpers";
import { UIText } from "../../../../../../config/lang-config";

export const template = observable({
  id: 1
});

export const taskTemplatePickerSetup = () => {
  const todo = stateController.initSetupMode === "addTodo";
  const title = todo ? "New To-do" : "New Task";
  return {
    title,
    heading: `Set up a ${todo ? "To-do" : "Task"}`,
    checkboxTitle: title,
    form: taskTemplatePickerForm(),
    onSubmit: async (form, store) => {
      template.id = Number(form[0].value);
      const selected = form[0].options.find(o => o.name === template.id);
      template.title = selected && selected.placeholder;

      store.originalTitle = selected && selected.placeholder;
    }
  };
};

export const taskTemplateSetup = () => {
  const todo = stateController.initSetupMode === "addTodo";
  const title = todo ? "New To-do" : "New Task";
  return {
    title,
    heading: template.title || "Set up a Task",
    checkboxTitle: title,
    form: observable(taskTemplateForm(template)),
    renderFlags: ["setupRender"],
    editFlags: ["setupEdit"],
    onBlur: field => {
      field.errorMessage = "";
      if (field.required && !field.value && !field._disabled)
        field.errorMessage = `Enter a ${(
          field.label || field.placeholder
        ).toLowerCase()}`;
    },
    onSubmit: async ([form, errorFields], store) => {
      if (!form || !isEmpty(errorFields)) {
        return Promise.reject({
          message: UIText.checkEntriesWithFields(errorFields),
          contentAlign: "left"
        });
      }
      let data = formService.disassembleFormData(form);
      if (store.originalTitle) data.originalTitle = store.originalTitle;
      const description = data.title;
      data = JSON.stringify(data);

      const group = clientController.findGroupById(stateController.viewGroupId);
      if (isEmpty(group))
        return Promise.reject({
          message: `Unknown group creating ${todo ? "To-do" : "Task template"}.`
        });

      const selfMember = group.members.find(
        m => m.userId === clientController.userId
      );
      if (isEmpty(selfMember))
        return Promise.reject({
          message: `Unknown member creating ${
            todo ? "To-do" : "Task template"
          }.`
        });

      const topic = {
        creatorMemberId: selfMember.id,
        typeId: topicTypeIds.task,
        isTemplate: todo ? 0 : 1,
        isParentTemplate: todo ? 0 : 1,
        onCalendar: 0,
        isCompleted: 0,
        isDataLocked: 0,
        isLocked: 0,
        description,
        groupId: group.id,
        parentId: !todo
          ? stateController.viewGroupState.shiftTemplateId[group.id]
          : "",
        data,
        typeClassId: template.id,
        typeClassVersion: 1 // Default for now.
      };

      return apiService
        .async("POST", {
          endpoint: endpointConfig.create_topic,
          data: {
            currentGroupId: group.id,
            topic
          }
        })
        .then(response => {
          const topic = response.data;
          if (!topic)
            return Promise.reject({
              message:
                "Something happened and setup was not successful. [Empty topic data]"
            });

          template.title = topic.description;
          store.topic = topic;
        });
    },
    validate: async form => {
      const pass = formService.validateRequired(form);
      if (!pass) {
        const errorFields = form.filter(f => !!f.errorMessage);
        return [false, errorFields];
      }
      return [form];
    }
  };
};
