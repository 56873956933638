import { stateController } from '../../../cdm/controllers/state-controller';
import { computed, observable } from 'mobx';
import { UIText } from '../../../config/lang-config';
import { capitalize, preventDefaultStopProp } from '../../../utils/helpers';
import { apiService } from '../../../cdm/services/api-service';
import { endpointConfig } from '../../../config/api-config';
import { apiController } from '../../../cdm/controllers/api-controller';
import { invitationStatus } from '../../../config/variable-config';

export class InvitationRegisterController {
  @observable loading = true;

  @computed get invitationId() {
    return stateController.viewInvitationRegState.id;
  }
  @computed get invitationHash() {
    return stateController.viewInvitationRegState.hash;
  }
  @computed get inviteeEmail() {
    return stateController.viewInvitationRegState.inviteeEmail;
  }
  @computed get invitationData() {
    return stateController.viewInvitationRegState.data;
  }
  @computed get invitationLoginToken() {
    return stateController.viewInvitationRegState.loginToken;
  }
  @computed get invitationLoginUser() {
    return stateController.viewInvitationRegState.loginUser;
  }

  @computed get invitationAccepting() {
    return (
      this.invitationId &&
      this.invitationHash &&
      this.inviteeEmail &&
      !this.invitationLoginToken &&
      !this.invitationLoginUser
    );
  }
  @computed get invitationLoginVerifying() {
    return (
      this.invitationId &&
      this.invitationLoginToken &&
      this.invitationLoginUser &&
      !this.invitationHash &&
      !this.inviteeEmail
    );
  }

  constructor(props) {
    this.props = props;
    stateController.currentScreen = "InvitationRegister";
    this._initialize()
      .catch(this._showError)
      .finally(() => (this.loading = false));
  }

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: capitalize(UIText.invitation),
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: () =>
        stateController
          .dismissPopup()
          .then(() => this.props.navigation.navigate("Login"))
    });
  };

  _initialize = async () => {
    if (this.invitationAccepting) {
      return this._getInvitation();
    }
    if (this.invitationLoginVerifying) {
      return this._validateInvitationLogin();
    }
    return this.props.navigation.navigate("Login");
  };

  _getInvitation = async () => {
    const checkInvitation = async invitation => {
      if (
        !invitation ||
        !invitation.email ||
        invitation.email !== this.inviteeEmail
      ) {
        return Promise.reject({
          message: UIText.invitationInvalidInvitation
        });
      }

      const inviteeUser = invitation["invitee_user"] || {};
      const usernameString =
        inviteeUser.username || inviteeUser.email || invitation.email || "";

      const status = Number(invitation["invitation_status"]);

      let exist;

      if (status === 0) {
        // Store invitation data to further usage in registration or login.
        stateController.viewInvitationRegState.data = invitation;
        await apiController
          .checkExist("email", this.inviteeEmail)
          .then(result => (exist = result.exist));
      }

      if (
        exist ||
        status === invitationStatus.pendingAccept ||
        status === invitationStatus.completed ||
        status === invitationStatus.cancelled
      ) {
        return stateController
          .showPopup({
            title: capitalize(UIText.invitation),
            content:
              status === invitationStatus.pendingAccept || exist
                ? UIText.invitationPendingAcceptLogin
                : status === invitationStatus.completed
                ? UIText.invitationAlreadyAccepted
                : status === invitationStatus.cancelled
                ? UIText.invitationCancelled
                : UIText.invitationInvalidInvitation,
            leftButtonText: UIText.generalConfirm
          })
          .then(!exist && stateController.clearInvitationRegState)
          .then(() => this.toLoginWithUsername(usernameString));
      }

      // return (stateController.viewInvitationRegState.data = invitation);
    };

    return apiService
      .async("GET", {
        endpoint: endpointConfig.get_invitation(
          this.invitationId,
          this.invitationHash
        )
      })
      .then(response => response.data)
      .then(checkInvitation);
  };

  _validateInvitationLogin = async () => {
    const data = {
      identifier: this.invitationLoginUser,
      token: this.invitationLoginToken,
      invitationId: this.invitationId
    };

    return apiService
      .async("POST", {
        endpoint: endpointConfig.invitation_login_verify,
        data
      })
      .then(response => {
        const result = response && response.data;
        const { pass, accepted, user } = result || {};
        if (!pass)
          return Promise.reject({
            message: UIText.invitationLoginVerifyFailure
          });
        return [pass, accepted, user];
      })
      .then(([pass, accepted, user]) => {
        if (pass) {
          return stateController
            .showPopup({
              title: capitalize(UIText.success),
              content: accepted
                ? UIText.invitationAlreadyAccepted
                : UIText.invitationLoginVerifySuccess,
              leftButtonText: UIText.generalConfirm
            })
            .then(stateController.clearInvitationRegState)
            .then(() =>
              this.toLoginWithUsername(user ? user.username || user.email : "")
            );
        }
      });
  };

  handleRegistrationPress = event => {
    preventDefaultStopProp(event);
    stateController.base64form = {
      firstName: this.invitationData["first_name"],
      lastName: this.invitationData["last_name"],
      email: this.invitationData.email,
      roleIdList: [3]
    };
    return this.props.navigation.navigate(
      "Registration",
      event && { invitationId: this.invitationId }
    );
  };

  handleTakeoverPress = event => {
    preventDefaultStopProp(event);
    return this.props.navigation.navigate(
      "Login",
      event && { invitationId: this.invitationId }
    );
  };

  handleCancelPress = event => {
    preventDefaultStopProp(event);
    stateController.clearInvitationRegState();
    return this.props.navigation.navigate("Login");
  };

  toLoginWithUsername = fieldString => {
    stateController.base64form = {
      email: fieldString
    };
    return this.handleTakeoverPress();
  };
}
