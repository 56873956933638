import React, { useEffect, useState } from "react";
import { Platform, View } from "react-native";
import { createElement } from "react-native-web";
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  getFakeDateForTimeString24,
  getISOStringLocal,
  getTimeString24Local
} from "../../utils/helpers";
import { mCBDayTimePickerStyles } from "./styles";

const dateTimeValueNotChanged = (prevProps, nextProps) => {
  return prevProps.defaultValue === nextProps.defaultValue;
};

const DatePickerView = React.memo(props => {
  let datePicker;

  const [defaultValue, setDefaultValue] = useState(props.defaultValue);
  useEffect(() => setDefaultValue(props.defaultValue), [props.defaultValue]);

  const getMuiDatetimeValue = () => {
    return (
      defaultValue &&
      (props.mode === "time"
        ? defaultValue
        : new Date(defaultValue).toLocaleString())
    );
  };
  const [muiDatetimeValue, setMuiDatetimeValue] = useState(
    getMuiDatetimeValue()
  );
  useEffect(() => setMuiDatetimeValue(getMuiDatetimeValue()), [defaultValue]);

  const [muiPickerOpen, setMuiPickerOpen] = useState(false);
  const handleOpen = event => {
    setMuiPickerOpen(true);
    if (typeof props.onOpen === "function") props.onOpen(event);
  };
  const handleClose = event => {
    setMuiPickerOpen(false);
    if (typeof props.onClose === "function") props.onClose(event);
  };

  const isFirefox = navigator.userAgent.includes("Firefox");
  const useMui = isFirefox || props.useMui;

  if (Platform.OS === "web") {
    const onChange = e => {
      const value = e.target.value;
      return props.onChange && value && props.onChange(value);
    };

    if (useMui) {
      const wrapMuiEvent = value => {
        let result;
        if (props.mode !== "time") {
          result = getISOStringLocal(
            new Date(new Date(new Date(value).setSeconds(0)).setMilliseconds(0))
          );
        } else {
          result = getTimeString24Local(value.toDate());
          setMuiDatetimeValue(getTimeString24Local(value.toDate(), true));
        }
        return props.onChange && result && props.onChange(result);
      };

      datePicker = (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {props.mode === "time" ? (
            <TimePicker
              value={getFakeDateForTimeString24(muiDatetimeValue)}
              minDate={props.min}
              maxDate={props.max}
              onChange={wrapMuiEvent}
              open={muiPickerOpen}
              onOpen={handleOpen}
              onClose={handleClose}
              TextFieldComponent={() => null}
            />
          ) : (
            <DateTimePicker
              value={defaultValue}
              minDate={props.min}
              maxDate={props.max}
              onChange={wrapMuiEvent}
              open={muiPickerOpen}
              onOpen={handleOpen}
              onClose={handleClose}
              TextFieldComponent={() => null}
            />
          )}
          {createElement("input", {
            style: props.styles.inputWeb,
            name: props.title,
            type: "text",
            defaultValue: muiDatetimeValue,
            placeholder: props.placeholder,
            readOnly: props.disabled,
            onClick: () => !props.disabled && handleOpen()
          })}
          <style
            dangerouslySetInnerHTML={{
              __html: mCBDayTimePickerStyles
            }}
          />
        </MuiPickersUtilsProvider>
      );
    } else {
      datePicker = createElement("input", {
        style: props.styles.inputWeb,
        name: props.title,
        type: props.mode || "datetime-local",
        defaultValue: props.defaultValue,
        onBlur: onChange,
        min: props.min,
        max: props.max,
        placeholder: props.placeholder,
        readOnly: props.disabled
      });
    }
  } else {
  }

  const HomogeneousDatePicker = () => datePicker;

  return (
    <View
      style={{
        ...props.styles.style,
        ...props.style
      }}
    >
      <HomogeneousDatePicker />
    </View>
  );
}, dateTimeValueNotChanged);

export { DatePickerView };
