import { theme } from "../../config/style-configs/theme";

const borderColor = "#aaa";
const borderWidth = 1;
const borderStyle = "solid";

export const styles = {
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 800
  },
  style: {
    flexDirection: "row"
  },
  header: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  arrow: {
    borderRightWidth: 0,
    borderBottomWidth: 0,
    flex: 1 / 6
  },
  dateColumn: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  date: {
    backgroundColor: theme.color,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  timeSlot: {
    backgroundColor: "#ececec"
  },
  cell: {
    flex: 1,
    width: "100%",
    minHeight: 50,
    maxHeight: 50,
    justifyContent: "center",
    alignItems: "center",
    borderRightWidth: borderWidth,
    borderRightColor: borderColor,
    borderRightStyle: borderStyle,
    borderBottomWidth: borderWidth,
    borderBottomColor: borderColor,
    borderBottomStyle: borderStyle
  },
  dateCell: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    zIndex: -1
  },
  mark: {
    flex: 1,
    height: "100%",
    marginRight: 5,
    padding: 5,
    borderRadius: 3,
    boxShadow: "0 4px 16px rgba(0,0,0,.12)"
  },
  text: {
    fontWeight: "600",
    color: "#333",
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  headerText: {
    fontSize: theme.FONT_SIZE_LARGE,
    fontWeight: "600",
    color: "#666"
  },
  dateText: {
    color: "#fff",
    fontSize: theme.FONT_SIZE_MEDIUM,
    fontWeight: "600"
  },
  markText: {
    flex: 1
  }
};
