import { styles } from "../styles";
import { theme } from "../../../config/style-configs/theme";

export const extraStyles = {
  heading: {
    ...styles.questionHeader,
    textAlign: "center"
  },
  questionHeader: {
    ...styles.questionHeader,
    width: "100%",
    textAlign: "left"
  },
  get questionFields() {
    return {
      ...styles.questions,
      alignItems: "flex-start"
    };
  },
  button: {
    ...styles.button,
    marginBottom: 50
  },
  fileDrop: {
    margin: 20,
    borderWidth: 2,
    borderRadius: theme.FONT_SIZE_LARGE,
    borderStyle: "dashed",
    cursor: "pointer"
  },
  fileDropText: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    color: theme.color
  },
  fileDropFormats: {
    fontSize: theme.FONT_SIZE_SMALL
  },
  fileItem: {
    minWidth: 300,
    maxWidth: 300,
    boxShadow: "0 4px 16px rgba(0,0,0,.12)",
    marginBottom: 20
  }
};

export { styles };
