import {
  groupTypeActorIds,
  groupTypeIds,
  groupTypeRoleIds,
  memberStatus,
  topicTypeIds
} from "../../config/variable-config";
import { action, autorun, observable } from "mobx";
import {
  abbvLastNameDisplayNameEng,
  capitalize,
  getDatetimeInputValueFromDate,
  getDisplayNameEng,
  getISOStringTimeZone,
  getTimeString24Timezone,
  getTimeZoneLongName,
  getUTCOffsetMilli,
  isEmpty
} from "../../utils/helpers";
import { stateController } from "../controllers/state-controller";
import { UIText } from "../../config/lang-config";
import { apiService } from "../services/api-service";
import { endpointConfig } from "../../config/api-config";
import { apiController } from "../controllers/api-controller";
import { clientController } from "../controllers/client-controller";
import { ShiftTaskController } from "../../custom/mcb/lib/shift-task-controller";

export const editTopicMenu = (topic, flags, methods, textOverride, icons) => {
  if (isEmpty(topic) || isEmpty(methods) || isEmpty(flags)) {
    return stateController.showPopup({
      title: UIText.actionMenu,
      content: UIText.noActionAvailable,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  }

  textOverride = textOverride || {};
  icons = icons || {};

  const topicTypeName = textOverride.topicTypeName || topic.topicTypeName;

  const buttonSet = [
    flags.includes("description") &&
      methods.editTopicName && {
        title:
          textOverride.editTopicName || UIText.topicEditName(topicTypeName),
        icon: icons.editTopicName,
        onPress: () =>
          stateController.dismissPopup().then(methods.editTopicName)
      },
    flags.includes("startEnd") &&
      methods.editTopicStartEndTime && {
        title:
          textOverride.editTopicStartEndTime ||
          UIText.topicEditStartEndTime(topicTypeName),
        icon: icons.editTopicStartEndTime,
        onPress: () =>
          stateController.dismissPopup().then(methods.editTopicStartEndTime)
      },
    flags.includes("assignee") &&
      methods.editTopicAssignee && {
        title:
          textOverride.editTopicAssignee ||
          UIText.topicEditAssignee(topicTypeName),
        icon: icons.editTopicAssignee,
        onPress: () =>
          stateController.dismissPopup().then(methods.editTopicAssignee)
      },
    flags.includes("correctedEndTime") &&
      methods.editTopicCorrectedEndTime && {
        title:
          textOverride.editTopicCorrectedEndTime ||
          UIText.topicEditCorrectedEndTime(topicTypeName),
        icon: icons.editTopicCorrectedEndTime,
        onPress: () =>
          stateController.dismissPopup().then(methods.editTopicCorrectedEndTime)
      },
    flags.includes("delete") &&
      methods.deleteTopic && {
        title:
          textOverride.deleteTopic || UIText.generalDeleteItem(topicTypeName),
        icon: icons.deleteTopic,
        onPress: () => stateController.dismissPopup().then(methods.deleteTopic),
        topDivider: true
      },
    {
      title: UIText.generalCancel,
      topDivider: true,
      onPress: stateController.dismissPopup
    }
  ].filter(Boolean);

  if (Array.isArray(methods.custom) && methods.custom.length > 0) {
    for (let method of methods.custom) {
      buttonSet.splice(method.index || buttonSet.length, 0, {
        title: method.title || "",
        onPress: () => stateController.dismissPopup().then(method.onPress),
        topDivider: method.topDivider
      });
    }
  }

  return stateController.showPopup({
    title: capitalize(`${topicTypeName}`),
    buttonSet,
    dismissOnBackPress: true
  });
};

export const editTopicDescription = async (
  topic,
  topicTypeName,
  titleOverride
) => {
  let listName;

  topicTypeName = topicTypeName || topic.topicTypeName;

  return new Promise((resolve, reject) => {
    stateController.showPopup({
      title: titleOverride || UIText.topicEditName(`${topicTypeName}`),
      leftButtonText: UIText.generalCancel,
      leftButtonPress: () => stateController.dismissPopup().then(resolve),
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: () => {
        stateController.dismissPopup().then(() => {
          if (!listName) return;

          return apiService
            .async("PATCH", {
              endpoint: endpointConfig.topic_by_id(topic.id),
              data: { description: listName }
            })
            .catch(reject)
            .finally(() => resolve(true));
        });
      },
      rightButtonDisabled: true,
      input: {
        placeholder: UIText.topicEditNamePlaceholder(`${topicTypeName}`),
        title: `${capitalize(topicTypeName)}: ${topic.description}`,
        defaultValue: topic.description,
        onChange: e => {
          listName = e.nativeEvent.text;
          stateController.popup.rightButtonDisabled = isEmpty(listName);
        }
      }
    });
  });
};

export const editTopicAssignee = async (
  group,
  topic,
  actorName,
  options,
  topicTypeName,
  titleOverride
) => {
  options = options || {};

  topicTypeName = topicTypeName || topic.topicTypeName;

  const { members, assigneeMemberIds } = getTopicAssigneeMembers(group, topic);

  for (let member of members) {
    if (!member.profile || !member.profile.data) {
      await apiController
        .getProfileById(member.profileId)
        .then(clientController.updateProfile);
    }
  }

  const selections = observable(
    members.map(m => ({
      placeholder: getDisplayNameEng(m.profile.data),
      value: m.id,
      checked: assigneeMemberIds.includes(Number(m.id))
    }))
  );

  return new Promise((resolve, reject) => {
    const rightButtonDisabled = () =>
      !options.allowNoAssignee &&
      selections.filter(o => !!o.checked).length === 0;

    stateController.showPopup({
      title:
        titleOverride ||
        UIText.topicAssignAssignee(`${topicTypeName.toLowerCase()}`),
      leftButtonText: UIText.generalCancel,
      leftButtonPress: () => stateController.dismissPopup().then(resolve),
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: () => {
        const assigned = selections
          .map(o => !!o.checked && o.value)
          .filter(Boolean);

        stateController
          .dismissPopup()
          .then(
            async () =>
              await updateTopicActorMembers(
                group,
                topic,
                assigned,
                actorName,
                topicTypeName,
                titleOverride
              )
          )
          .catch(reject)
          .finally(() => {
            return resolve(true);
          });
      },
      rightButtonDisabled: true,
      multiSelect: {
        title: UIText.topicPickAssignee(topic.description),
        onCheckboxChange: member => {
          member.checked = !member.checked;
          stateController.popup.rightButtonDisabled = rightButtonDisabled();
        },
        options: selections
      }
    });
  });
};

export const getTopicAssigneeMembers = (group, topic) => {
  const withoutPendingCareReceiver = member =>
    member.roleList[0] &&
    member.roleList[0].groupTypeRoleId !== groupTypeRoleIds.careReceiver &&
    Number(member.status) !== memberStatus.invited;
  const members = group.members.filter(withoutPendingCareReceiver);
  const assigneeMemberIds =
    (
      (topic &&
        topic.actors.find(
          a =>
            a["groupTypeActorId"] ===
            groupTypeActorIds[groupTypeIds.careReceiver].assigned
        )) ||
      {}
    ).memberIdList || [];

  return {
    members,
    assigneeMemberIds
  };
};

export const updateTopicActorMembers = async (
  group,
  topic,
  memberIdList,
  actorName,
  topicTypeName,
  titleOverride
) => {
  const data = {
    memberIdList
  };

  topicTypeName = topicTypeName || topic.topicTypeName;

  return stateController
    .showPopup({
      title:
        titleOverride || topicTypeName
          ? UIText.topicAssignAssignee(`${topicTypeName}`)
          : UIText.topicAssigning,
      content: UIText.pleaseWait
    })
    .then(() =>
      apiService.async("PUT", {
        endpoint: endpointConfig.topic_member_actor(
          group.id,
          topic.id,
          actorName
        ),
        data
      })
    )
    .then(stateController.dismissPopup);
};

export const editTopicStartEndTime = async (
  topic,
  options,
  topicTypeName,
  titleOverride,
  timezone
) => {
  options = options || {};

  topicTypeName = topicTypeName || topic.topicTypeName;

  const getTopicTimes = topic => {
    if (options.noDate) {
      const startTime = topic.startTime && new Date(topic.startTime);
      const endTime = topic.endTime && new Date(topic.endTime);

      return {
        startTime:
          startTime && getTimeString24Timezone(startTime, true, timezone),
        endTime: endTime && getTimeString24Timezone(endTime, true, timezone)
      };
    } else {
      return {
        startTime:
          topic.startTime &&
          getISOStringTimeZone(new Date(topic.startTime), timezone),
        endTime:
          topic.endTime &&
          getISOStringTimeZone(new Date(topic.endTime), timezone)
      };
    }
  };

  let startTime = getTopicTimes(topic).startTime,
    endTime = getTopicTimes(topic).endTime,
    today = getISOStringTimeZone(
      new Date(new Date().setUTCHours(0, 0, 0, 0)),
      timezone
    );

  return new Promise((resolve, reject) => {
    stateController.showPopup({
      title: titleOverride || UIText.topicEditStartEndTime(`${topicTypeName}`),
      leftButtonText: UIText.generalCancel,
      leftButtonPress: () => stateController.dismissPopup().then(resolve),
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: () => {
        stateController
          .dismissPopup()
          .then(
            async () =>
              await updateTopicTime(
                topic,
                startTime,
                endTime,
                null,
                options.noDate
              )
          )
          .then(update => {
            if (!update)
              return editTopicStartEndTime(
                topic,
                options,
                topicTypeName,
                titleOverride,
                timezone
              );
            return update;
          })
          .then(resolve)
          .catch(reject);
      },
      rightButtonDisabled: true,
      datePickerSet: [
        !options.noStart && {
          mode: options.noDate ? "time" : "datetime-local",
          title: `${UIText.topicStartTime} (${getTimeZoneLongName(
            timezone,
            true
          )})`,
          placeholder: UIText.topicStartTime,
          defaultValue: startTime,
          min: today,
          onChange: value => {
            const starTimeDate = new Date(`2000-01-01T${value}Z`);
            startTime =
              starTimeDate.getTime() -
              getUTCOffsetMilli(timezone, starTimeDate);
            stateController.popup.rightButtonDisabled =
              isEmpty(startTime) || isEmpty(endTime);
          }
        },
        !options.noEnd && {
          mode: options.noDate ? "time" : "datetime-local",
          title: `${UIText.topicEndTime} (${getTimeZoneLongName(
            timezone,
            true
          )})`,
          placeholder: UIText.topicEndTime,
          defaultValue: endTime,
          min: today,
          onChange: value => {
            const endTimeDate = new Date(`2000-01-01T${value}Z`);
            endTime =
              endTimeDate.getTime() - getUTCOffsetMilli(timezone, endTimeDate);
            stateController.popup.rightButtonDisabled =
              (!options.noStart && isEmpty(startTime)) ||
              (!options.noEnd && isEmpty(endTime));
          }
        }
      ].filter(Boolean)
    });
  });
};

export const updateTopicTime = async (
  topic,
  startTime,
  endTime,
  topicTypeName,
  noDate
) => {
  topicTypeName = topicTypeName || topic.topicTypeName;

  startTime = startTime && new Date(startTime).getTime();
  endTime = endTime && new Date(endTime).getTime();

  const nowTime = new Date().getTime();

  return stateController
    .showPopup({
      title: UIText.updating,
      content: UIText.pleaseWait
    })
    .then(
      new Promise((resolve, reject) => {
        if (endTime < startTime && !noDate) {
          return stateController.dismissPopup().then(() =>
            stateController.showPopup({
              title: UIText.topicEditStartEndTime(`${topicTypeName}`),
              content: UIText.topicEditStartEndInvalidInverted,
              leftButtonText: UIText.generalConfirm,
              leftButtonPress: () =>
                stateController.dismissPopup().then(resolve)
            })
          );
        }

        if (!noDate && endTime < nowTime) {
          return stateController.dismissPopup().then(() =>
            stateController.showPopup({
              title: UIText.topicEditStartEndTime(`${topicTypeName}`),
              content: UIText.topicEditStartEndInvalidEnd,
              leftButtonText: UIText.generalConfirm,
              leftButtonPress: () =>
                stateController.dismissPopup().then(resolve)
            })
          );
        }

        apiService
          .async("PATCH", {
            endpoint: endpointConfig.topic_by_id(topic.id),
            data: {
              startTime,
              endTime
            }
          })
          .then(stateController.dismissPopup)
          .then(() => resolve(true))
          .catch(reject);
      })
    );
};

export const editTopicCorrectedEndTime = async (
  topic,
  topicTypeName,
  titleOverride,
  timezone
) => {
  topicTypeName = topicTypeName || topic.topicTypeName;

  // const value = observable({
  //   hours:
  //     topic.correctedEndTime &&
  //     topic.execStartTime &&
  //     (
  //       Math.ceil(
  //         (Math.abs(
  //           new Date(topic.correctedEndTime) - new Date(topic.execStartTime)
  //         ) /
  //           36e5) *
  //           100
  //       ) / 100
  //     ).toFixed(2)
  // });

  const form = observable([
    {
      name: "execStartTime",
      type: "dateTime",
      placeholder: UIText.shiftClockedInShifts,
      // min,
      // max,
      value: getDatetimeInputValueFromDate(
        new Date(topic.execStartTime),
        timezone
      )
    },
    {
      name: "execEndTime",
      type: "dateTime",
      placeholder: UIText.shiftClockedOutShifts,
      // min,
      // max,
      value: getDatetimeInputValueFromDate(
        new Date(topic.correctedEndTime || topic.execEndTime),
        timezone
      )
    }
  ]);

  const dispose = autorun(() => {
    const { start, end } = ShiftTaskController.extractClockInOutFieldsValue(
      form[0],
      form[1],
      timezone
    );
    stateController.popup.rightButtonDisabled =
      start === topic.execStartTime && end === topic.execEndTime;
  });

  return new Promise((resolve, reject) => {
    stateController.showPopup({
      title:
        titleOverride || UIText.topicEditCorrectedEndTime(`${topicTypeName}`),
      leftButtonText: UIText.generalCancel,
      leftButtonPress: () => stateController.dismissPopup().then(resolve),
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: () => {
        stateController
          .dismissPopup()
          .then(async () => updateTopicCorrectedEndTime(topic, form, timezone))
          .then(update => {
            if (!update)
              return editTopicCorrectedEndTime(
                topic,
                topicTypeName,
                titleOverride,
                timezone
              );
            return update;
          })
          .then(dispose)
          .then(resolve)
          .catch(reject);
      },
      rightButtonDisabled: true,
      formShowTitle: true,
      form
    });
  });
};

export const updateTopicCorrectedEndTime = async (topic, form, timezone) => {
  if (isEmpty(topic) || isEmpty(form)) return;
  const { start, end } = ShiftTaskController.extractClockInOutFieldsValue(
    form[0],
    form[1],
    timezone
  );
  if (!start || !end) return;
  if (start === topic.execStartTime && end === topic.execEndTime) return;

  if (start > end) {
    return new Promise(resolve =>
      stateController.showPopup({
        title: UIText.shiftSubmitShift,
        content: UIText.shiftCalendarAddShiftClockedDateInverted,
        leftButtonText: UIText.generalConfirm,
        leftButtonPress: () =>
          stateController.dismissPopup().then(() => resolve(null))
      })
    );
  }

  return stateController
    .showPopup({
      title: UIText.updating,
      content: UIText.pleaseWait
    })
    .then(() =>
      apiService.async("PATCH", {
        endpoint: endpointConfig.topic_by_id(topic.id),
        data: {
          execStartTime: start || undefined,
          execEndTime: end || undefined
        }
      })
    )
    .then(() => apiController.getTopicById(topic.id))
    .then(clientController.updateTopic)
    .then(stateController.dismissPopup)
    .then(() => true);
};

export const deleteTopic = async (
  topic,
  denyMessage,
  topicTypeName,
  titleOverride
) => {
  topicTypeName = topicTypeName || topic.topicTypeName;

  if (topic.isCompleted) {
    return stateController.showPopup({
      title: titleOverride || UIText.generalDeleteItem(topicTypeName),
      content: denyMessage || UIText.topicDeleteDeny(topic.description),
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  } else {
    return new Promise((resolve, reject) => {
      stateController.showPopup({
        title: UIText.generalDeleteItem(topicTypeName),
        content: UIText.topicDeleteConfirm(topic.description),
        leftButtonText: UIText.generalNo,
        leftButtonPress: () => stateController.dismissPopup().then(resolve),
        rightButtonText: UIText.generalYes,
        rightButtonPress: () => {
          stateController.dismissPopup().then(() => {
            return apiController
              .deleteTopic(topic.id)
              .then(() => {
                return clientController.removeTopic(topic.id);
              })
              .then(stateController.dismissPopup)
              .catch(reject)
              .finally(() => resolve(true));
          });
        }
      });
    });
  }
};

export const getTopicCompletionState = topic => {
  if (!topic.execStartTime && !topic.execEndTime) return "new";
  if (topic.execStartTime && !topic.execEndTime) return "clocked-in";
  if (topic.execStartTime && topic.execEndTime && topic.isCompleted)
    return "clocked-out";
};

export const getTopicDescription = topic =>
  topic.description
    ? topic.typeId === topicTypeIds.candidate
      ? abbvLastNameDisplayNameEng(topic.description)
      : topic.description
    : " ";

export const getTopicCompletionMember = (topic, includeNew, members) => {
  if (getTopicCompletionState(topic) === "new" && !includeNew) return null;
  const assigned =
    (Array.isArray(topic.actors) &&
      topic.actors.find(
        a =>
          a["groupTypeActorId"] ===
          groupTypeActorIds[groupTypeIds.careReceiver].assigned
      )) ||
    {};
  members = members || topic.members;
  return (
    Array.isArray(members) &&
    members.find(
      m => m.id === (assigned.memberIdList && assigned.memberIdList[0])
    )
  );
};

export const getTopicAssignedMembers = topic => {
  const assigned =
    (Array.isArray(topic.actors) &&
      topic.actors.find(
        a =>
          a["groupTypeActorId"] ===
          groupTypeActorIds[groupTypeIds.careReceiver].assigned
      )) ||
    {};
  return (
    (Array.isArray(topic.members) &&
      assigned.memberIdList &&
      assigned.memberIdList.map(id => topic.members.find(m => m.id === id))) ||
    []
  );
};

export const getTopicCreatorMember = topic => {
  if (!topic.members) return null;

  return topic.members.find(m => m.id === topic.creatorMemberId);
};

export const lockTopic = async topic => {
  if (isEmpty(topic)) return;

  return apiService
    .async("GET", {
      endpoint: endpointConfig.lock_topic(topic.id)
    })
    .then(console.log);
};

export const topicSummaryForm = (topic, timeZone) =>
  [
    {
      name: "title",
      placeholder: "Title",
      flags: {
        general: true
      },
      value: getTopicDescription(topic)
    },
    {
      name: "type",
      placeholder: "Type",
      flags: {
        general: true
      },
      value: topic.topicTypeName || ""
    },
    {
      name: "startTime",
      placeholder: "Start Time",
      flags: {
        todo: true
      },
      value:
        topic.startTime &&
        new Date(topic.startTime).toLocaleString("en-CA", {
          timeZone
        })
    },
    {
      name: "endTime",
      placeholder: "End Time",
      flags: {
        todo: true
      },
      value:
        topic.endTime &&
        new Date(topic.endTime).toLocaleString("en-CA", {
          timeZone
        })
    },
    {
      name: "startTimeShift",
      placeholder: "Start Time",
      flags: {
        shift: true
      },
      value:
        topic.startTime &&
        getTimeString24Timezone(new Date(topic.startTime), true, timeZone)
    },
    {
      name: "endTimeShift",
      placeholder: "End Time",
      flags: {
        shift: true
      },
      value:
        topic.endTime &&
        getTimeString24Timezone(new Date(topic.endTime), true, timeZone)
    },
    {
      name: "endTimeIssue",
      placeholder: UIText.issueDueDate,
      flags: {
        issue: true
      },
      value:
        topic.endTime &&
        new Date(topic.endTime).toLocaleString("en-CA", {
          timeZone
        })
    },
    {
      name: "assigneeMembers",
      placeholder: UIText.assignedTo,
      flags: {
        issue: true,
        shift: true
      },
      multiline: true,
      autoLineHeight: true,
      // value: getDisplayNameEng((getTopicCompletionMember(topic, true) || {}).profile)
      value: (
        topic.memberNameList ||
        getTopicAssignedMembers(topic).map(m => getDisplayNameEng(m.profile))
      ).join("\n")
    },
    {
      name: "clockedInMemberIssue",
      placeholder: UIText.issueAcceptedBy,
      flags: {
        issue: true
      },
      value:
        getTopicCompletionState(topic) === "clocked-in" &&
        getDisplayNameEng((getTopicCompletionMember(topic) || {}).profile)
    },
    {
      name: "completed",
      placeholder: "Completed",
      flags: {
        task: true,
        todo: true
      },
      value: topic.isCompleted ? UIText.generalYes : UIText.generalNo
    },
    {
      name: "completedIssue",
      placeholder: UIText.issuesResolvedIssue,
      flags: {
        issue: true
      },
      value: topic.isCompleted ? UIText.generalYes : UIText.generalNo
    },
    {
      name: "completionTime",
      placeholder: "Completion Time",
      flags: {
        task: true,
        todo: true
      },
      value:
        topic.execEndTime &&
        new Date(topic.execEndTime).toLocaleString("en-CA", {
          timeZone
        })
    },
    {
      name: "completionTimeIssue",
      placeholder: UIText.issueResolvedDate,
      flags: {
        issue: true
      },
      value:
        topic.execEndTime &&
        new Date(topic.execEndTime).toLocaleString("en-CA", {
          timeZone
        })
    },
    {
      name: "clockInOut",
      placeholder: "Status",
      flags: {
        shift: true
      },
      value: capitalize(getTopicCompletionState(topic))
    },
    {
      name: "clockedInMemberShift",
      placeholder: "Clocked-in by",
      flags: {
        shift: true
      },
      value: getDisplayNameEng((getTopicCompletionMember(topic) || {}).profile)
    },
    {
      name: "clockedOutMemberIssue",
      placeholder: UIText.issueResolvedBy,
      flags: {
        issue: true
      },
      value:
        getTopicCompletionState(topic) === "clocked-out" &&
        getDisplayNameEng((getTopicCompletionMember(topic) || {}).profile)
    },
    {
      name: "clockedInTime",
      placeholder: "Clocked-in time",
      flags: {
        shift: true
      },
      value:
        topic.execStartTime &&
        new Date(topic.execStartTime).toLocaleString("en-CA", {
          timeZone
        })
    },
    {
      name: "clockedOutTime",
      placeholder: "Clocked-out time",
      flags: {
        shift: true
      },
      value: topic.correctedEndTime
        ? new Date(topic.correctedEndTime).toLocaleString("en-CA", {
            timeZone
          })
        : topic.execEndTime
        ? new Date(topic.execEndTime).toLocaleString("en-CA", {
            timeZone
          })
        : null
    },
    {
      name: "clockedHoursShift",
      placeholder: "Clocked hours",
      flags: {
        shift: true
      },
      value:
        topic.execEndTime &&
        topic.execStartTime &&
        (() => {
          // const endTime = convertDateToTimeZone(
          //   topic.execEndTime,
          //   timeZone
          // ).getTime();
          // const correction = convertDateToTimeZone(
          //   topic.execStartTime,
          //   timeZone
          // ).getTime();
          const endTime = new Date(topic.execEndTime).getTime();
          const correction = new Date(topic.execStartTime).getTime();
          const hour = (endTime - correction) / 36e5;
          return (Math.ceil(hour * 100) / 100).toFixed(2);
        })()
    },
    // {
    //   name: "correctedHoursShift",
    //   placeholder: "Corrected hours",
    //   flags: {
    //     shift: true
    //   },
    //   value:
    //     topic.correctedEndTime &&
    //     topic.execStartTime &&
    //     (() => {
    //       const endTime = new Date(topic.correctedEndTime).getTime();
    //       const correction = new Date(topic.execStartTime).getTime();
    //       const hour = (endTime - correction) / 36e5;
    //       return (Math.ceil(hour * 100) / 100).toFixed(2);
    //     })()
    // },
    !topic.isLocked &&
      topic.execEndTime && {
        name: "correctedHoursReminder",
        flags: {
          shift: true
        },
        multiline: true,
        autoLineHeight: true,
        maxLength: 220,
        value: UIText.topicEditCorrectedEndTimeReminder
      },
    {
      name: "createdBy",
      placeholder: "Created by",
      flags: {
        general: true
      },
      value:
        topic.members &&
        getDisplayNameEng((getTopicCreatorMember(topic) || {}).profile)
    },
    {
      name: "createdAt",
      placeholder: "Created",
      flags: {
        general: true
      },
      value:
        topic.createTime &&
        new Date(topic.createTime).toLocaleString("en-CA", {
          timeZone
        })
    },
    {
      name: "updatedAt",
      placeholder: "Updated",
      flags: {
        general: true
      },
      value:
        topic.updateTime &&
        new Date(topic.updateTime).toLocaleString("en-CA", {
          timeZone
        })
    },
    {
      name: "subTopicsUpdatedAt",
      placeholder: "Last Task Update",
      flags: {
        shift: true
      },
      value: getTopicLastSubTopicUpdateTime(topic, timeZone)
    },
    {
      name: "timeZoneName",
      placeholder: "All dates and times are shown in the time zone",
      flags: {
        general: true
      },
      value: getTimeZoneLongName(timeZone, true)
    }
  ]
    .filter(Boolean)
    .map(f => ({
      ...f,
      type: "input",
      disabled: true
    }));

export const isSystemUIHiddenTopic = topic =>
  topic.typeId === topicTypeIds.shortlist ||
  topic.typeId === topicTypeIds.timesheet ||
  (topic.typeId === topicTypeIds.shift && topic.isTemplate);

export const getTopicLastSubTopicUpdateTime = (shift, timeZone) => {
  const childUpdateTime = clientController
    .findSubTopicByParentId(shift.id)
    .map(t => t.updateTime)
    .filter(Boolean)
    .sort((a, b) => b - a)[0];
  return (
    childUpdateTime &&
    new Date(childUpdateTime).toLocaleString("en-CA", {
      timeZone
    })
  );
};

export const autoCompleteTopicData = action((topic, targetArray) => {
  if (!isEmpty(topic.actors)) return;
  if (!clientController.loginState) return;
  const partial = targetArray.find(t => t.id === topic.id);
  const cached = clientController.findTopicById(topic.id);
  if (!isEmpty(cached) && !isEmpty(cached.members) && !isEmpty(cached.actors)) {
    return Object.assign(partial, cached);
  }
  return apiController.getTopicById(topic.id).then(
    action(topic => {
      clientController.updateTopic(topic, topic.id);
      return Object.assign(partial, topic);
    })
  );
});
