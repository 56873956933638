import { stateController } from "../../../../cdm/controllers/state-controller";
import NavigationService from "../../../../utils/navigation-service";
import { computed, observable } from "mobx";
import { clientController } from "../../../../cdm/controllers/client-controller";
import {
  env,
  groupTypeIds,
  RoleGroupGroupTypeIds,
  serviceRecipientGroupTypeIds,
  topicTypeIds
} from "../../../../config/variable-config";
import { UIText } from "../../../../config/lang-config";
import { isDevAdmin } from "../../utils/helper";
import { fileService } from "../../../../cdm/services/file-service";
import {
  getGroupTimezoneName,
  subscriptionPopup
} from "../../../../cdm/lib/group-utilities";
import { capitalize, isEmpty } from "../../../../utils/helpers";
import {
  isServiceProviderGroup,
  paidCaregiverFeatureRestricted
} from "../../lib/group-utilities-mcb";
import { apiController } from "../../../../cdm/controllers/api-controller";

export class GroupMainPageController {
  @observable hasMCBMessages = false;

  get topTabs() {
    let tabs = [];
    if (
      serviceRecipientGroupTypeIds.includes(this.group.typeId) ||
      this.group.typeId === groupTypeIds.myCareBaseStaff
    ) {
      tabs = [
        isDevAdmin(clientController.client.user) &&
          env !== "prod" && {
            key: "test",
            name: "API TEST"
          },
        !this.isMCBGroup &&
          !paidCaregiverFeatureRestricted(this.group, this.selfMember) &&
          this.isCareReceiverGroup && {
            key: "summary",
            name: capitalize(UIText.summary)
            // this.controller.myRole.groupTypeRoleId === 3
            //   ? "Dashboard"
            //   : "Shifts",
          },
        // this.currentPlan.isCareEnabled && {
        !this.isMCBGroup &&
          this.isCareReceiverGroup && {
            key: "shift",
            name: UIText.shiftMakerShifts,
            help: UIText.shiftListHelp,
            disabled: !this.currentPlan.isCareEnabled,
            disabledBySub: !this.currentPlan.isCareEnabled
          },
        // this.currentPlan.isCareEnabled && {
        this.isMCBGroup && {
          key: "groupMessage",
          name: UIText.groupAdminGroupMessages
        },
        this.isMCBGroup && {
          key: "proactiveChats",
          name: UIText.groupAdminProactiveChats
        },
        {
          key: "issue",
          name: capitalize(UIText.issues)
        },
        !paidCaregiverFeatureRestricted(this.group, this.selfMember) &&
          !this.isMCBGroup &&
          this.isCareReceiverGroup && {
            key: "report",
            name: capitalize(UIText.reports),
            disabled: !this.currentPlan.isCareEnabled,
            disabledBySub: !this.currentPlan.isCareEnabled
          }
        // this.currentPlan.isCareEnabled && {
        // {
        //   key: "note",
        //   name: capitalize(UIText.notes),
        //   onTabPress: this.handleTabChange
        // }
        // {
        //   key: "task",
        //   name: "To do",
        //   onTabPress: this.handleTabChange
        // },
      ];
    }
    if (RoleGroupGroupTypeIds.includes(this.group.typeId)) {
      tabs = [
        {
          key: "employment",
          name: "Employment opportunities"
        }
      ];
    }
    !this.isMCBGroup &&
      this.hasMCBMessages &&
      tabs.push({
        key: "mcbMessage",
        name: UIText.groupMCBMessages
      });
    serviceRecipientGroupTypeIds.includes(this.group.typeId) &&
      this.isCareReceiverGroup &&
      paidCaregiverFeatureRestricted(this.group, this.selfMember) &&
      tabs.push({
        key: "summary",
        name: capitalize(UIText.summary)
      });
    return tabs.filter(Boolean).map(t => ({
      ...t,
      onTabPress: this.handleTabChange
    }));
  }

  @computed get groupId() {
    return stateController.viewGroupId;
  }
  @computed get group() {
    return clientController.findGroupById(this.groupId) || {};
  }
  @computed get groupTypeName() {
    return isServiceProviderGroup(this.group)
      ? UIText.groupServiceProviderName(this.group.groupTypeName)
      : this.group.groupTypeName;
  }
  @computed get isMCBGroup() {
    return this.group.typeId === groupTypeIds.myCareBaseStaff;
  }
  @computed get isCareReceiverGroup() {
    return this.group.typeId === groupTypeIds.careReceiver;
  }
  @computed get members() {
    return this.group.members || [];
  }
  @computed get selfMember() {
    return this.members.find(m => m.userId === clientController.userId) || {};
  }
  @computed get profileData() {
    return (this.group.profile && this.group.profile.data) || {};
  }
  @computed get groupAvatar() {
    return fileService.getProfileAvatarUri(
      this.profileData.avatar,
      this.groupId,
      "group"
    );
  }
  @computed get defaultTabKey() {
    return RoleGroupGroupTypeIds.includes(this.group.typeId)
      ? "employment"
      : this.isMCBGroup
      ? "groupMessage"
      : this.isCareReceiverGroup
      ? paidCaregiverFeatureRestricted(this.group, this.selfMember)
        ? this.currentPlan.isCareEnabled
          ? "shift"
          : "issue"
        : "summary"
      : "issue";
  }
  @computed get currentTabKey() {
    if (!stateController.viewGroupState.topTabs["main"][this.groupId])
      stateController.viewGroupState.topTabs["main"][
        this.groupId
      ] = this.defaultTabKey;
    return stateController.viewGroupState.topTabs["main"][this.groupId];
  }
  @computed get myRole() {
    return this.props.getMyRole && this.props.getMyRole();
  }
  @computed get subscription() {
    return this.group.subscription || this.group.wpSubscription || {};
  }
  @computed get currentPlan() {
    return this.subscription.plan || {};
  }
  @computed get timezoneName() {
    return (
      serviceRecipientGroupTypeIds.includes(this.group.typeId) &&
      getGroupTimezoneName(this.group)
    );
  }

  constructor(props) {
    this.props = props;
    this.handleHeadingPress = props.handleHeadingPress;
    this.handleBillingPress = props.handleBillingPress;
    this.handleAppointmentPress = props.handleAppointmentPress;
    this._detectAutoTab();
    this._checkMCBMessages();
  }

  _detectAutoTab = () => {
    const tabs = this.topTabs.map(tab => tab && tab.key).filter(Boolean);
    const tab = this.props["autoTab"];
    tab &&
      tabs.includes(tab) &&
      this.handleTabChange({ key: tab }) &&
      this.props.clearAutoTabs &&
      this.props.clearAutoTabs("main");
  };

  _checkMCBMessages = () =>
    apiController
      .getGroupTopicsByTypeId(this.groupId, topicTypeIds.mCBBroadcasts)
      .then(topics => (this.hasMCBMessages = !isEmpty(topics)));

  handleTabChange = (tab, event) => {
    if (tab.disabled) {
      return tab.disabledBySub && subscriptionPopup(null, tab.name);
    }
    stateController.viewGroupState.topTabs["main"][this.groupId] = tab.key;
    return true;
  };

  handleTopicItemPress = (id, event) => {
    stateController.viewTopicId = id;
    NavigationService.navigate("Topic", { topic: id });
  };
}
