import React from "react";
import { ActivityIndicator, View } from "react-native";
import { Header } from "../../../components/Header";
import { observer } from "mobx-react";
import { GroupMemberPage } from "../../../custom/mcb/components/GroupMemberPage";
import { GroupMainPage } from "../../../custom/mcb/components/GroupMainPage";
import { ThemedBottomNav } from "../../../components/ThemedBottomNav";
import { GroupMarketPage } from "../../../custom/mcb/components/GroupMarketPage";
import { GroupShiftPage } from "../../../custom/mcb/components/GroupShiftPage";
import { theme } from "../../../config/style-configs/theme";
import { capitalize, getDisplayNameEng, isEmpty } from "../../../utils/helpers";
import { UIText } from "../../../config/lang-config";
import { responsive } from "../../../config/style-configs/responsive";
import { TopicSearchBar } from "../../../components/TopicSearchBar";
import { GroupFinderPage } from "../../../custom/mcb/components/GroupFinderPage";
import { Button, CheckBox, Icon } from "react-native-elements";

@observer
class GroupScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentDidMount() {
    return this.controller.componentDidMount();
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  getPageComponent = tab => {
    if (tab === "dashboard") {
      return (
        <GroupMainPage
          group={this.controller.group}
          getMyRole={() => this.controller.myRole}
          autoTab={this.controller.autoTopTabs.main}
          clearAutoTabs={this.controller.clearAutoTopTabs}
          headerShowAppointment={this.controller.headerShowAppointment}
          headerShowBilling={this.controller.headerShowBilling}
          headerShowEdit={this.controller.headerShowEdit}
          handleHeadingPress={this.controller.handleHeadingPress}
          handleBillingPress={this.controller.handleBillingPress}
          handleAppointmentPress={this.controller.handleAppointmentPress}
        />
      );
    }
    if (tab === "shift") {
      return (
        <GroupShiftPage
          autoTab={this.controller.autoTopTabs.shift}
          clearAutoTabs={this.controller.clearAutoTopTabs}
          handleBottomTabChange={this.controller.handleBottomTabChange}
        />
      );
    }
    if (tab === "members") {
      return (
        <GroupMemberPage
          group={this.controller.group}
          getMyRole={() => this.controller.myRole}
          headerShowAppointment={this.controller.headerShowAppointment}
          headerShowBilling={this.controller.headerShowBilling}
          headerShowEdit={this.controller.headerShowEdit}
          getGroupMembers={this.controller.getGroupMembers}
          handleHeadingPress={this.controller.handleHeadingPress}
          handleBillingPress={this.controller.handleBillingPress}
          handleAppointmentPress={this.controller.handleAppointmentPress}
        />
      );
    }
    if (tab === "marketplace") {
      return (
        <GroupMarketPage
          autoTab={this.controller.autoTopTabs.market}
          clearAutoTabs={this.controller.clearAutoTopTabs}
          handleBillingPress={this.controller.handleBillingPress}
        />
      );
    }
    if (tab === "finder") {
      if (
        this.controller.isMCBGroup &&
        this.controller.mCBGroupUseLegacyMarketplace
      ) {
        return (
          <GroupMarketPage
            autoTab={this.controller.autoTopTabs.market}
            clearAutoTabs={this.controller.clearAutoTopTabs}
            handleBillingPress={this.controller.handleBillingPress}
          />
        );
      }
      return <GroupFinderPage />;
    }
  };

  getMCBGroupMarketSwitch = () => {
    if (
      !this.controller.isMCBGroup ||
      this.controller.currentBottomTab !== "finder"
    )
      return null;
    return (
      <View style={this.props.styles.mCBGroupHeaderButtons}>
        <CheckBox
          containerStyle={this.props.styles.additionalCheckbox}
          center
          title="CAF"
          titleProps={{
            style: {
              ...this.props.styles.additionalCheckboxTitle,
              color: this.controller.mCBGroupUseLegacyMarketplace
                ? "#666"
                : theme.color
            }
          }}
          iconRight
          checkedIcon={
            <Icon
              name={"toggle-switch"}
              type={"material-community"}
              size={40}
              color={theme.color}
            />
          }
          uncheckedIcon={
            <Icon
              name={"toggle-switch-off"}
              type={"material-community"}
              size={40}
              color="#666"
            />
          }
          checked={!this.controller.mCBGroupUseLegacyMarketplace}
          onPress={this.controller.toggleMCBGroupLegacyMarketplace}
        />

        <Button
          type="clear"
          icon={<Icon name="search" color={theme.color} size={25} />}
          onPress={
            !this.controller.isScratchpad &&
            !this.controller.isRoleGroup &&
            this.controller.handleSearchPress
          }
        />
      </View>
    );
  };

  render() {
    const chatProhibit =
      this.controller.isRoleGroup ||
      this.controller.currentBottomTab === "marketplace" ||
      this.controller.currentBottomTab === "finder";

    return (
      <View style={this.props.styles.style}>
        <View style={this.props.styles.style}>
          <Header
            title={
              this.controller.isScratchpad
                ? responsive.deviceDimension.isNarrow
                  ? `${UIText.titleShort} ${capitalize(UIText.marketplace)}`
                  : `${UIText.title} ${capitalize(UIText.marketplace)}`
                : isEmpty(this.controller.profile)
                ? responsive.deviceDimension.isNarrow
                  ? UIText.titleShort
                  : UIText.title
                : !responsive.deviceDimension.isDesktop
                ? this.controller.isMCBGroup
                  ? chatProhibit
                    ? UIText.groupMCBShort
                    : getDisplayNameEng(this.controller.profile)
                  : getDisplayNameEng(this.controller.profile)
                : getDisplayNameEng(this.controller.profile)
            }
            leftIcon={this.controller.headerShowLeftIcon}
            handleLeftPress={
              this.controller.isVisitor && this.controller.execLogout
            }
            rightIcon={
              !chatProhibit &&
              // !isEmpty(this.controller.subscription) &&
              (this.controller.chatTopic["lastPublicMessageTime"]
                ? "chat"
                : "chat-bubble")
            }
            rightIconLoading={!chatProhibit && this.controller.chatLoading}
            rightIconBadgeCount={!chatProhibit && this.controller.messagesCount}
            rightComponent={this.getMCBGroupMarketSwitch()}
            handleRightPress={!chatProhibit && this.controller.openChat}
            handleSearchPress={
              !this.controller.isScratchpad &&
              !this.controller.isRoleGroup &&
              this.controller.handleSearchPress
            }
            navigation={this.props.navigation}
          />

          <TopicSearchBar />

          {!this.controller.subscriptionReady || this.controller.loading ? (
            <View style={this.props.styles.loading}>
              <ActivityIndicator size="large" color={theme.color} />
            </View>
          ) : (
            this.getPageComponent(this.controller.currentBottomTab)
          )}
        </View>

        {this.controller.subscriptionReady && !this.controller.isVisitor && (
          <ThemedBottomNav
            activeTab={this.controller.currentBottomTab}
            onTabPress={this.controller.handleBottomTabChange}
            tabs={this.controller.bottomTabs}
          />
        )}
      </View>
    );
  }
}

export { GroupScreen };
