import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { View } from "react-native";
import { CardElement, injectStripe } from "react-stripe-elements";
import { theme } from "../../config/style-configs/theme";

@observer
class StripeElementsViewWeb extends React.Component {
  @observable tokenLoading = false;
  @observable cardOk = false;
  @observable cardError = false;

  constructor(props) {
    super(props);
    if (this.props.createTokenCallback) {
      this.props.createTokenCallback(object => this.tokenCallback(object));
    }
  }

  change = event => {
    // console.log(event);
    this.cardOk = false;
    this.cardError = false;
    this.props.handleCardChange && this.props.handleCardChange(event);
  };

  tokenCallback = async object => {
    let result = await this.props.stripe.createToken({ name: "Name" });
    const { token, error } = result;
    object.token = token;
    object.error = error;
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        <View style={this.props.styles.card}>
          <CardElement
            style={{ base: { fontSize: `${theme.FONT_SIZE_MEDIUM}px` } }}
            onChange={this.change}
          />
        </View>
      </View>
    );
  }
}

export default injectStripe(StripeElementsViewWeb);
