import { computed, observable, toJS } from "mobx";
import { stateController } from "../../../cdm/controllers/state-controller";
import { clientController } from "../../../cdm/controllers/client-controller";
import { UIText } from "../../../config/lang-config";
import { msgService } from "../../../cdm/services/messaging-service";
import { isEmpty, preventDefaultStopProp } from "../../../utils/helpers";
import { Platform } from "react-native";
import { isSnSOrAdmin } from "../../../custom/mcb/utils/helper";
import {
  isCcSubscriptionTopic,
  isGroupSubscriptionMsgEnabled
} from "../../../custom/mcb/lib/group-utilities-mcb";
import { checkGroupSubscription } from "../../../cdm/lib/group-utilities";

export const AllPageEnabler = observable.box(false);

if (Platform.OS === "web") {
  window.enableInboxAll = code => {
    if (code === "1234qwer") AllPageEnabler.set(true);
  };
}

export class InboxController {
  @observable loading = true;

  @computed get syncing() {
    return msgService.syncingUnreadData;
  }
  @computed get bottomTabs() {
    return [
      {
        key: "unread",
        icon: "mail",
        label: UIText.inboxUnread
      },
      (isSnSOrAdmin(clientController.client.user) ||
        !!AllPageEnabler.get()) && {
        key: "all",
        icon: "inbox",
        label: UIText.inboxAll
      }
    ].filter(Boolean);
  }
  @computed get currentBottomTab() {
    return stateController.viewInboxState.currentBottomTab;
  }
  @computed get collapsedUnreadGroups() {
    return stateController.viewInboxState.collapsedUnreadGroups;
  }
  @computed get collapsedAllGroups() {
    return stateController.viewInboxState.collapsedAllGroups;
  }
  @computed get isVisitor() {
    return clientController.isVisitor;
  }

  @computed get unreadGroups() {
    return toJS(msgService.unreadCountData)
      .filter(
        g =>
          !isEmpty(
            g.topics.filter(t => t.unreadCount && Number(t.unreadCount) > 0)
          )
      )
      .map(g => {
        const group = toJS(clientController.findGroupById(g.id));
        group.collapsed = this.collapsedUnreadGroups[group.id];
        const topics = g.topics
          .map(t => {
            const topic = clientController.findTopicById(t.id);
            return isEmpty(topic) ? t : { ...topic, ...t };
          })
          .filter(t => t.unreadCount && Number(t.unreadCount) > 0)
          .sort(this.sortTopics);
        return {
          ...group,
          topics
        };
      });
  }
  @computed get allGroups() {
    return toJS(msgService.unreadCountData)
      .filter(g => Array.isArray(g.topics) && g.topics.length > 0)
      .map(g => {
        const group = toJS(clientController.findGroupById(g.id));
        group.collapsed = this.collapsedAllGroups[group.id];
        const topics = g.topics
          .map(t => {
            const topic = clientController.findTopicById(t.id);
            return isEmpty(topic) ? t : { ...topic, ...t };
          })
          .sort(this.sortTopics);
        return {
          ...group,
          topics
        };
      });
  }
  @computed get allTopics() {
    return this.allGroups.map(g => g.topics).flat();
  }

  constructor(props) {
    this.props = props;
    this.previousScreen =
      stateController.currentScreen !== "Chat" && stateController.currentScreen;

    stateController.currentScreen = "Inbox";

    setTimeout(this._initialize);
  }

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.inbox,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _initialize = async force => {
    if (this.isVisitor) return this.props.navigation.navigate("Root");

    return msgService
      .syncUnreadCount({ async: !force })
      .catch(this._showError)
      .finally(() => {
        this.loading = false;
      });
  };

  sortTopics = (a, b) => {
    a["lastPublicMessageTimeLong"] = a["lastPublicMessageTimeLong"] || 0;
    b["lastPublicMessageTimeLong"] = b["lastPublicMessageTimeLong"] || 0;

    return -(a["lastPublicMessageTimeLong"] - b["lastPublicMessageTimeLong"]);
  };

  handleBackPress = event => {
    this.props.navigation.navigate(this.previousScreen || "Group");
    return true;
  };

  handleBottomTabChange = tab => {
    stateController.viewInboxState.currentBottomTab = tab.key;
  };

  handleGroupTitlePress = (group, type, event) => {
    preventDefaultStopProp(event);
    stateController.viewInboxState[type][group.id] = !this[type][group.id];
  };

  handleTopicItemPress = async (group, topic, event) => {
    preventDefaultStopProp(event);

    if (isCcSubscriptionTopic(group, topic)) {
      if (!group.subscription) {
        await checkGroupSubscription(group.id)
          .then(() => (group = clientController.findGroupById(group.id)))
          .catch(err => Promise.reject(err));
      }
      if (!isGroupSubscriptionMsgEnabled(group)) {
        return stateController.showPopup({
          title: UIText.inbox,
          content: {
            text: UIText.marketSubNoChat
            // text: `${UIText.marketSubNoChat} ${UIText.marketPlanInfo}`,
            // link: planMarketingUrl
          },
          contentAlign: "left",
          leftButtonText: UIText.generalCancel,
          rightButtonText: UIText.planSelectAPlan,
          rightButtonPress: () =>
            stateController.dismissPopup().then(() => {
              stateController.viewPlanState.setupMode = false;
              this.props.navigation.navigate("Plan", { group: group.id });
            })
        });
      }
    }

    stateController.viewTopicId = topic.id;
    if (Platform.OS === "web") {
      this.props.navigation.navigate("Chat", { topic: topic.id });
    } else {
      this.props.navigation.push("Chat", { topic: topic.id });
    }
  };

  onRefresh = () => {
    if (this.syncing) return;

    this.loading = true;
    return this._initialize(true);
  };

  collapseAll = () => {
    const collapsibleGroupType =
      this.currentBottomTab === "unread" ? "unreadGroups" : "allGroups";
    const collapsibleState =
      this.currentBottomTab === "unread"
        ? "collapsedUnreadGroups"
        : "collapsedAllGroups";

    for (let group of this[collapsibleGroupType]) {
      stateController.viewInboxState[collapsibleState][group.id] = true;
    }
  };

  uncollapseAll = () => {
    const collapsibleState =
      this.currentBottomTab === "unread"
        ? "collapsedUnreadGroups"
        : "collapsedAllGroups";

    stateController.viewInboxState[collapsibleState] = {};
  };
}
