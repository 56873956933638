import { theme } from "../../config/style-configs/theme";

const backgroundColor = "#f6f6f6";

export const styles = {
  style: {
    flexDirection: "row",
    alignItems: "flex-end",
    height: 55,
    overflow: "hidden"
  },
  tabWrapper: {
    flexDirection: "column",
    flex: 1,
    height: 45,
    backgroundColor,
    alignItems: "center"
  },
  tabWrapperSelected: {
    zIndex: 100
  },
  tab: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    borderBottomWidth: 2,
    borderColor: "#eee",
    backgroundColor,
    boxShadow: "inset #aaa 0px -8px 13px -14px",
    padding: 8
    // zIndex: 0
  },
  tabSelected: {
    backgroundColor: "#fff",
    borderBottomWidth: 0,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    boxShadow: "rgba(170, 170, 170, 0.5) 0px -1px 9px 0px"
  },
  tabText: {
    flex: 1
  },
  tabHelp: {
    position: "absolute",
    right: "6%",
    zIndex: 2
  },
  text: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    textAlign: "center"
  },
  divider: {
    width: "100%"
  },
  moreTabWrapper: {
    width: 40,
    alignItems: "center",
    backgroundColor,
    borderBottomWidth: 2,
    boxShadow: "inset #aaa 0px -8px 13px -14px",
    borderColor: "#eee"
  },
  moreTabWrapperSelected: {}
};
