import { theme } from "../../../config/style-configs/theme";
import { common } from "../../../config/style-configs/common";

const chatHeadingText = {
  textAlign: "left",
  fontSize: theme.FONT_SIZE_LARGE - 1,
  fontWeight: "600",
  color: "#444"
};

export const styles = {
  style: {
    flex: 1,
    backgroundColor: "#FAFAFA"
  },
  chatHeading: {
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 70,
    paddingLeft: 15,
    paddingRight: 15
  },
  chatHeadingTitle: {
    // flex: 0.6,
    flex: 1,
    justifyContent: "center"
  },
  chatHeadingGroup: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 1
  },
  chatHeadingText: chatHeadingText,
  chatHeadingTextGroupType: {
    ...chatHeadingText,
    color: theme.color
  },
  chatHeadingAvatars: {
    flex: 0.4,
    flexDirection: "row",
    justifyContent: "flex-end",
    maxWidth: 120,
    overflow: "hidden"
  },
  avatar: {
    marginLeft: 10
  },
  chatMessageContainer: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#FAFAFA"
  },
  loading: {
    ...common.loading,
    flex: 0.2,
    alignItems: "flex-start"
  },
  errorMessage: {
    margin: 10,
    alignSelf: "center",
    fontSize: theme.FONT_SIZE_MEDIUM,
    color: "#666"
  },
  endMessage: {
    margin: 15,
    alignSelf: "center",
    textAlign: "center",
    fontSize: theme.FONT_SIZE_SMALL,
    color: "#999"
  },
  systemMessage: {
    margin: 10,
    alignSelf: "center",
    textAlign: "center",
    fontSize: theme.FONT_SIZE_SMALL,
    color: "#444"
  }
};
