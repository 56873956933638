import { clientController } from "../../../cdm/controllers/client-controller";
import { computed, observable } from "mobx";
import { stateController } from "../../../cdm/controllers/state-controller";
import { UIText } from "../../../config/lang-config";
import { apiService } from "../../../cdm/services/api-service";
import { endpointConfig } from "../../../config/api-config";
import {
  preventDefaultStopProp,
  validateEmail,
  validatePassword
} from "../../../utils/helpers";

export class ResetController {
  @observable loading = true;
  @observable buttonDisabled = true;
  @observable resetting = false;
  @observable agreeTnc = false;
  @observable visible = {
    oldPassword: true,
    password: true,
    repeat: true
  };

  // Validation error messages
  @observable error = {
    resetRequest: "",
    newEmail: "",
    oldPassword: "",
    password: "",
    repeat: "",
    anyPassword: false
  };

  @observable resetHash = "";

  fieldData = {};

  requestRequired = ["resetRequest"];

  @computed get resetRequired() {
    return [
      this.needOldPassword && "oldPassword",
      (this.needReset || this.resetToken) && "password",
      (this.needReset || this.resetToken) && "repeat"
    ].filter(Boolean);
  }
  @computed get needOldPassword() {
    return (
      !this.onboarding &&
      !this.resetHash &&
      !!clientController.client &&
      !!clientController.client.oauth &&
      clientController.client.oauth["access_token"]
    );
  }
  @computed get needTerms() {
    return stateController.viewResetState.terms;
  }
  @computed get needReset() {
    return stateController.viewResetState.reset;
  }
  @computed get resetRequest() {
    return stateController.viewResetState.resetRequest;
  }
  @computed get resetToken() {
    return stateController.viewResetState.resetToken;
  }
  @computed get resetUser() {
    return stateController.viewResetState.resetUser;
  }
  @computed get continueUrl() {
    return stateController.viewResetState.continueUrl;
  }
  @computed get onboarding() {
    return stateController.onboardingSignIn;
  }

  constructor(props) {
    this.props = props;
    this._initialize();
  }

  componentDidMount = () => {
    stateController.currentScreen = "Reset";
  };

  componentWillUnmount = () => {
    stateController.clearResetState();
  };

  _showError = (err, kickout) => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.resetPasswordFailure,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: () =>
        stateController
          .dismissPopup()
          .then(() => kickout && this.props.navigation.navigate("Login")),
      dismissOnBackPress: true
    });
  };

  _initialize = () => {
    if (this.resetToken && this.resetUser) {
      this._getResetHash()
        .then(hash => {
          if (hash) {
            this.resetHash = hash;
            this.loading = false;
          }
        })
        .catch(console.warn)
        .finally(() => {
          if (!this.resetHash)
            return stateController.showPopup({
              title: UIText.resetPasswordGeneral,
              content: UIText.resetInvalidLink,
              leftButtonText: UIText.generalConfirm,
              leftButtonPress: () =>
                stateController
                  .dismissPopup()
                  .then(() => this.props.navigation.navigate("Login"))
            });
        });
    } else {
      this.loading = false;
    }

    setTimeout(() => {
      if (
        !this.resetRequest &&
        !this.resetToken &&
        !this.needTerms &&
        !this.needReset
      )
        return this.props.navigation.navigate("Login");
    }, 1000);
  };

  _getResetHash = async () => {
    return apiService
      .async("POST", {
        endpoint: endpointConfig.reset_pass_verify,
        data: {
          identifier: this.resetUser,
          token: this.resetToken
        }
      })
      .then(response => {
        const hash = response && response.data;
        return hash || "";
      });
  };

  updateFields = (field, event) => {
    if (!event) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      this.fieldData[field] = event.nativeEvent.text;
  };

  validateNewEmail = (field, noError) => {
    this.error["newEmail"] = "";
    if (
      this.fieldData.newEmail &&
      !validateEmail(this.fieldData.newEmail) &&
      !noError
    )
      this.error["newEmail"] = `${UIText.entryRequiringValid} ${field}.`;
    this.validateFields();
  };

  validatePassword = (field, noError) => {
    validatePassword(this.fieldData, field, this.error, noError, UIText);
    return this.validateFields();
  };

  validateFields = () => {
    let disabled = false;
    for (let field in this.error) {
      if (!!this.error[field]) disabled = true;
    }
    if (this.resetRequest) {
      for (let field in this.requestRequired) {
        let data = this.fieldData[this.requestRequired[field]];
        if (!data || data === "" || data === undefined) disabled = true;
      }
    }
    if (
      this.needReset ||
      this.needTerms ||
      (this.resetToken && this.resetUser)
    ) {
      for (let field in this.resetRequired) {
        let data = this.fieldData[this.resetRequired[field]];
        if (!data || data === "" || data === undefined) disabled = true;
      }
    }
    if (this.needTerms) {
      if (!this.agreeTnc) disabled = true;
    }
    this.buttonDisabled = disabled;
  };

  requestResetEmail = async () => {
    this.validateFields();
    if (this.buttonDisabled) return;

    this.resetting = true;
    return (
      clientController
        .isMaintenance()
        .then(() =>
          stateController.showPopup({
            title: UIText.resetPasswordGeneral,
            content: UIText.pleaseWait
          })
        )
        .then(() =>
          apiService.async("POST", {
            endpoint: endpointConfig.reset_pass_request,
            data: { identifier: this.fieldData.resetRequest }
          })
        )
        .catch(err => {
          const status = err.response.status;
          if (status >= 300 && status < 400) {
            const redirectUrl = err.response.data;
            return (window.location.href = redirectUrl);
          }
        })
        // .then(console.log)
        .then(stateController.dismissPopup)
        .finally(() => {
          this.resetting = false;
          return stateController.showPopup({
            title: UIText.resetPasswordGeneral,
            content: UIText.resetRequestSent,
            leftButtonText: UIText.generalConfirm,
            leftButtonPress: () =>
              stateController
                .dismissPopup()
                .then(() => this.props.navigation.navigate("Login"))
          });
        })
    );
  };

  execResetPassword = async () => {
    this.validateFields();
    if (this.buttonDisabled) return;

    this.resetting = true;
    const data = {
      hash: this.resetHash,
      newPass: this.fieldData.password,
      agreeTnc: this.agreeTnc,
      onboarding: this.onboarding
    };
    const oauth = clientController.client.oauth;
    if (
      (this.needOldPassword || this.onboarding) &&
      oauth["access_token"] &&
      !this.resetHash &&
      (this.fieldData.oldPassword || this.onboarding)
    ) {
      apiService.resetOAuthData();
      data.authToken = oauth["access_token"];
      data.password = this.fieldData.oldPassword;
    }

    const resetPopup = () =>
      stateController.showPopup({
        title: UIText.resetPassword,
        content: UIText.resetPasswordSuccessful,
        leftButtonText: UIText.generalConfirm,
        leftButtonPress: () =>
          stateController.dismissPopup().then(() => {
            this.continueUrl
              ? (window.location.href = this.continueUrl)
              : this.props.navigation.navigate("Login");
          })
      });

    const tncContinue = () =>
      stateController
        .showPopup({
          title: UIText.loginProgress,
          content: UIText.pleaseWait
        })
        .then(() => apiService.updateOAuthData(oauth))
        .then(clientController.initializeClient)
        .then(stateController.dismissPopup);

    return (
      stateController
        .showPopup({
          title: UIText.resetPasswordGeneral,
          content: UIText.pleaseWait
        })
        .then(() =>
          apiService.async("POST", {
            endpoint: endpointConfig.reset_pass_exec,
            data,
            noKickOut: true
          })
        )
        // .then(console.log)
        .then(this.fieldData.newEmail && this.execEmailUpdate)
        .then(stateController.dismissPopup)
        .then(
          (this.needReset && !this.onboarding) || this.resetToken
            ? resetPopup
            : tncContinue
        )
        .catch(this._showError)
        .finally(() => (this.resetting = false))
    );
  };

  execEmailUpdate = async () => {
    const oauth = clientController.client.oauth;
    if (!oauth["access_token"]) return;

    const data = {
      authToken: oauth["access_token"],
      email: this.fieldData.newEmail
    };

    return apiService.async("POST", {
      endpoint: endpointConfig.reset_email_all,
      data
    });
  };

  handleLinkPress = (link, event) => {
    preventDefaultStopProp(event);
    if (link === "login") this.props.navigation.navigate("Login");
  };

  handleCheckboxChange = event => {
    this.agreeTnc = !this.agreeTnc;
    this.validateFields();
  };

  openAgreement = () => {
    return clientController.openAgreement();
  };

  reveal = fieldName => {
    this.visible[fieldName] = !this.visible[fieldName];
  };

  execLogout = () => clientController.execLogout();
}
