import React from "react";
import { TouchableWithoutFeedback, View } from "react-native";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";
import { Text } from "react-native-elements";
import { theme } from "../../config/style-configs/theme";
import Icon from "react-native-vector-icons/MaterialIcons";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger
} from "react-native-popup-menu";
import { Tooltip } from "../Tooltip";
import { responsive } from "../../config/style-configs/responsive";

@observer
class TabHeaderView extends React.Component {
  @observable overflowVisible = false;

  tabLimitLength = 150;

  @computed get tabLimit() {
    return Math.round(responsive.deviceDimension.width / this.tabLimitLength);
  }

  _hightlightSelected = (tab, e) => {
    const method = tab && tab.onTabPress;
    return !!method && method(tab, e);
  };

  _getOverflowMenuOptions = tabs =>
    tabs.map(tab => {
      const active = tab.key === this.props.currentKey;
      return (
        <MenuOption
          key={tab.key}
          onSelect={e => {
            this._hightlightSelected(tab, e);
          }}
          text={tab.name}
          customStyles={{
            optionWrapper: {
              backgroundColor: active && theme.color,
              paddingLeft: 10,
              justifyContent: "center",
              height: this.props.styles.style.height
            },
            optionText: {
              fontSize: theme.FONT_SIZE_MEDIUM,
              color: active
                ? "#fff"
                : tab.disabled
                ? // ? "#ccc"
                  theme.lightColor
                : "#000"
            }
          }}
        />
      );
    });

  getTabsHeaderItems = tabs => {
    const activeTabIndex = tabs.findIndex(t => t.key === this.props.currentKey);
    if (tabs.length > this.tabLimit) {
      tabs = tabs.slice(0, this.tabLimit);
    }
    return tabs.map((tab, i) => {
      const active = activeTabIndex === i;
      const textStyle = active && {
        fontWeight: "600",
        color: theme.color
      };
      const disabledTextStyle = tab.disabled && {
        // color: "#ccc"
        color: theme.lightColor
      };

      return (
        <TouchableWithoutFeedback
          key={tab.key}
          onPress={e => {
            this._hightlightSelected(tab, e);
          }}
        >
          <View
            style={{
              ...this.props.styles.tabWrapper,
              ...(active && this.props.styles.tabWrapperSelected)
            }}
          >
            <View
              style={{
                ...this.props.styles.tab,
                ...(active && this.props.styles.tabSelected)
              }}
            >
              {!tab.disabled && tab.help ? (
                <View style={this.props.styles.tabHelp}>
                  <Tooltip content={tab.help} />
                </View>
              ) : null}
              <View style={this.props.styles.tabText}>
                <Text
                  style={{
                    ...textStyle,
                    ...disabledTextStyle,
                    ...this.props.styles.text
                  }}
                >
                  {tab.name}
                </Text>
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      );
    });
  };

  getOverflowItems = tabs => {
    if (tabs.length > this.tabLimit) {
      tabs = tabs.slice(this.tabLimit);
      const active = tabs.some(t => t.key === this.props.currentKey);

      return (
        <View
          style={{
            ...this.props.styles.moreTabWrapper,
            ...(active && this.props.styles.tabSelected)
          }}
        >
          <Menu>
            <MenuTrigger
              customStyles={{
                triggerOuterWrapper: {
                  height: this.props.styles.tabWrapper.height - 2,
                  justifyContent: "center"
                },
                triggerWrapper: {
                  padding: 10
                }
              }}
              children={
                <Icon
                  name="more-vert"
                  size={24}
                  color={active ? theme.color : "#333"}
                />
              }
            />
            <MenuOptions
              customStyles={{
                optionsContainer: {
                  borderRadius: 5,
                  overflow: "hidden"
                }
              }}
            >
              {this._getOverflowMenuOptions(tabs)}
            </MenuOptions>
          </Menu>
        </View>
      );
    }
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        {this.getTabsHeaderItems(this.props.tabs)}
        {this.getOverflowItems(this.props.tabs)}
      </View>
    );
  }
}

export { TabHeaderView };
