import React from "react";
import { observer } from "mobx-react";
import { Text, View } from "react-native";
import { UIText } from "../../../../config/lang-config";
import { responsive } from "../../../../config/style-configs/responsive";

@observer
class AdminInterviewBookingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  render() {
    return (
      <View style={this.props.styles.style}>
        <Text style={this.props.styles.heading}>
          {UIText.adminBookingFor(this.controller.caregiverName)}
        </Text>
        {this.controller.calendlyReady && (
          <div
            className="calendly-inline-widget"
            style={{
              minWidth: "300px",
              height: `${responsive.deviceDimension.height - 60}px`
            }}
            data-url={this.controller.calendlyUrl}
          />
        )}
      </View>
    );
  }
}

export { AdminInterviewBookingScreen };
