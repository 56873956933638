import React from "react";
import {
  ActivityIndicator,
  RefreshControl,
  ScrollView,
  Text,
  View
} from "react-native";
import { observer } from "mobx-react";
import { theme } from "../../../../config/style-configs/theme";
import { UIText } from "../../../../config/lang-config";
import { Calendar } from "react-native-calendars";
import { Icon } from "react-native-elements";
import { ThemedButton } from "../../../../components/ThemedButton/index";
import { WeekViewCalendar } from "../../../../components/WeekViewCalendar";
import { Tooltip } from "../../../../components/Tooltip";

@observer
class ShiftCalendarView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  renderCalendarArrows = dir => (
    <Icon name={`chevron-${dir}`} size={30} color={theme.color} />
  );

  renderButtons = () => (
    <View style={this.props.styles.buttonContainer}>
      <View style={this.props.styles.calendarButtonContainer}>
        <ThemedButton
          style={{ marginBottom: 10 }}
          title={UIText.shiftCalendarBackToList}
          onPress={this.controller.handleShiftListPress}
        />
        {this.controller.dateChanged && (
          <ThemedButton
            title={UIText.shiftCalendarToday}
            icon="today"
            onPress={this.controller.handleTodayPress}
          />
        )}
      </View>
      <View
        style={{
          ...this.props.styles.calendarButtonContainer,
          alignItems: "center"
        }}
      >
        <ThemedButton
          title={UIText.shiftCalendarMonthView}
          icon="calendar-blank"
          iconType="material-community"
          onPress={e => this.controller.handleModeSwitchPress(e, "month")}
        />
        <ThemedButton
          title={UIText.shiftCalendarWeekView}
          icon="view-week"
          onPress={e => this.controller.handleModeSwitchPress(e, "week")}
        />
        <Tooltip onPress={this.controller.showHelp} />
      </View>
    </View>
  );

  renderMonthView = () => (
    <ScrollView
      contentContainerStyle={this.props.styles.scrollView}
      refreshControl={
        <RefreshControl
          refreshing={this.controller.loading}
          onRefresh={this.controller.onRefresh}
        />
      }
    >
      <View style={this.props.styles.scrollViewContent}>
        {this.renderButtons()}
        <View style={this.props.styles.buttonReminder}>
          <Text style={this.props.styles.errorMessage}>
            {UIText.shiftCalendarIntroductionMonth}
          </Text>
        </View>
        <View style={this.props.styles.calendarContainer}>
          <Calendar
            current={this.controller.calendarCurrent}
            style={this.props.styles.calendar}
            theme={{
              todayTextColor: theme.color,
              selectedDayTextColor: theme.color,
              monthTextColor: "#666",
              dayTextColor: "#ccc",
              textDisabledColor: "#000",
              textDayFontSize: theme.FONT_SIZE_LARGE,
              textMonthFontSize: theme.FONT_SIZE_LARGE,
              textMonthFontWeight: "600"
            }}
            hideExtraDays
            markedDates={this.controller.markedDatesMonth}
            markingType="multi-dot" //multi-dot
            onDayPress={this.controller.onDayPress}
            monthFormat={"MMMM yyyy"}
            onMonthChange={this.controller.onMonthChange}
            renderArrow={this.renderCalendarArrows}
            onPressArrowLeft={substractMonth => substractMonth()}
            onPressArrowRight={addMonth => addMonth()}
          />
        </View>
      </View>
    </ScrollView>
  );

  renderWeekView = () => (
    <View
      style={{
        ...this.props.styles.style,
        ...this.props.styles.scrollViewContent
      }}
    >
      {this.renderButtons()}
      <View style={this.props.styles.buttonReminder}>
        <Text style={this.props.styles.errorMessage}>
          {UIText.shiftCalendarIntroductionWeek}
        </Text>
      </View>
      <View style={this.props.styles.weekView}>
        <WeekViewCalendar
          currentDate={this.controller.weekCurrent}
          timezone={this.controller.timezone}
          markedDates={this.controller.markedDatesWeek}
          noHover={true}
          customStyles={{
            cell: {
              backgroundColor: "#eee"
            }
          }}
          markedBlocks={this.controller.markedBlocksWeek}
          onWeekChange={this.controller.onWeekChange}
          onBlockPress={this.controller.onWeekBlockPress}
        />
      </View>
    </View>
  );

  render() {
    return (
      <View style={this.props.styles.style}>
        {this.controller.loading ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : this.controller.mode === "month" ? (
          this.renderMonthView()
        ) : (
          this.renderWeekView()
        )}
      </View>
    );
  }
}

export { ShiftCalendarView };
