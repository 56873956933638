import React from "react";
import {
  ActivityIndicator,
  Platform,
  RefreshControl,
  ScrollView,
  View
} from "react-native";
import { observer, Observer } from "mobx-react";
import { theme } from "../../../../config/style-configs/theme";
import { ThemedButton } from "../../../../components/ThemedButton/index";
import { UIText } from "../../../../config/lang-config";
import { TopicCard } from "../../../../components/TopicCard";
import { capitalize, safeParseJSON } from "../../../../utils/helpers";
import { Text } from "react-native-elements";
import { ListFilter } from "../../../../components/ListFilter";
import { ListFilterIndicator } from "../../../../components/ListFilterIndicator";
import { ListRefreshButtonWeb } from "../../../../components/ListRefreshButtonWeb";

@observer
class CareReceiverToDoListView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  renderTopics = topics => {
    if (!Array.isArray(topics) || topics.length === 0)
      return (
        <Text style={this.props.styles.errorMessage}>
          {UIText.groupNoTodoToShow}
        </Text>
      );

    topics = this.controller.sortTasks(topics);

    return (
      <View style={this.props.styles.scrollViewCard}>
        {topics.map(topic => this.renderTopic(topic))}
      </View>
    );
  };

  renderTopic = topic => {
    const data = (topic.data && safeParseJSON(topic.data)) || {};
    const startTime =
      topic.startTime && new Date(topic.startTime).toLocaleString();
    const endTime = topic.endTime && new Date(topic.endTime).toLocaleString();
    const completionState = topic["isCompleted"]
      ? UIText.topicCompleted
      : UIText.topicNotCompleted;

    const children = (
      <View style={this.props.styles.cardContent}>
        <Text
          style={{
            ...this.props.styles.cardContentMedium,
            fontWeight: "600"
          }}
        >
          {capitalize(data.taskType)}
        </Text>
        <Text style={this.props.styles.cardContentMedium}>
          {capitalize(completionState)}
        </Text>
        <Text style={this.props.styles.cardContentSmall}>
          {`${UIText.topicStartTime}: ${startTime || UIText.topicTimeNotSet}\n`}
          {`${UIText.topicEndTime}: ${endTime || UIText.topicTimeNotSet}`}
        </Text>
      </View>
    );

    return (
      <Observer key={topic.id}>
        {() => (
          <View style={this.props.styles.card}>
            <TopicCard
              compact={true}
              title={topic.description || data.title || ""}
              icon="clipboard-text-outline"
              iconType="material-community"
              showMenuIcon={true}
              menuPending={topic.pending}
              onMenuPress={e => this.controller.editTopic(e, topic)}
              onPress={e => this.controller.handleTopicPress(topic)}
              children={children}
            />
          </View>
        )}
      </Observer>
    );
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        {(this.controller.loading || this.controller.refreshing) &&
        Platform.OS === "web" ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          <ScrollView
            contentContainerStyle={this.props.styles.scrollView}
            refreshControl={
              <RefreshControl
                refreshing={
                  this.controller.refreshing || this.controller.loading
                }
                onRefresh={this.controller.onRefresh}
              />
            }
          >
            <View style={this.props.styles.scrollViewContent}>
              <View style={this.props.styles.buttonContainer}>
                <ThemedButton
                  title={UIText.groupAddTodo}
                  onPress={this.controller.addTodo}
                />
                <ListFilter
                  data={this.controller.topics}
                  categories={this.controller.filterCategories}
                  filterSetter={this.controller.setFilter}
                />
                <ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />
              </View>
              <ListFilterIndicator
                length={this.controller.filteredTopics.length}
                categories={this.controller.filterCategories}
              />
              {this.renderTopics(this.controller.filteredTopics)}
            </View>
          </ScrollView>
        )}
      </View>
    );
  }
}

export { CareReceiverToDoListView };
