import { Observer, observer } from "mobx-react";
import React from "react";
import { ActivityIndicator, ScrollView, Text, View } from "react-native";
import { Header } from "../../../components/Header";
import { UIText } from "../../../config/lang-config";
import { theme } from "../../../config/style-configs/theme";
import { AndroidBackHandler } from "react-navigation-backhandler";
import { wordPressService } from "../../../cdm/services/wordpress-service";
import { capitalize, getDisplayNameEng, isEmpty } from "../../../utils/helpers";
import { FormField } from "../../../components/FormField";
import { ThemedButton } from "../../../components/ThemedButton";
import { PlanPaymentForm } from "../../../components/PlanPaymentForm";
import { planMarketingNumber } from "../../../config/variable-config";
import { ListRefreshButtonWeb } from "../../../components/ListRefreshButtonWeb";

@observer
class BillingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
    this.billingFormFields = [];
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  renderWelcome = () => (
    <Observer>
      {() => (
        <View style={this.props.styles.scrollViewContent}>
          <Observer>
            {() => (
              <FormField
                field={{
                  type: "input",
                  placeholder: `${capitalize(
                    this.controller.currentGroup.groupTypeName
                  )}:`,
                  showLabel: true
                }}
                inputValue={getDisplayNameEng(this.controller.profile) || ""}
                enabled={false}
                fieldStyle={this.props.styles.field}
                fieldLabelStyle={this.props.styles.fieldLabel}
              />
            )}
          </Observer>

          {/*Billing account*/}
          <Observer>
            {() => (
              <>
                <FormField
                  field={{
                    type: "input",
                    placeholder: `${UIText.subscriptionBillingAccount}:`,
                    showLabel: true
                  }}
                  inputValue={UIText.subscriptionChangeBillingInfo}
                  enabled={false}
                  fieldStyle={this.props.styles.field}
                  fieldLabelStyle={this.props.styles.fieldLabel}
                />
                <View style={this.props.styles.listButtonContainer}>
                  <ThemedButton
                    style={this.props.styles.button}
                    title={UIText.subscriptionChangeAddress}
                    onPress={this.controller.handleUpdateBillingAddressPress}
                  />
                  <ThemedButton
                    style={this.props.styles.button}
                    title={UIText.planChangePayment}
                    onPress={this.controller.handleUpdatePaymentPress}
                  />
                </View>
              </>
            )}
          </Observer>
          {/*Billing account*/}

          {/*Subscription entry*/}
          <Observer>
            {() => (
              <>
                <FormField
                  field={{
                    type: "input",
                    placeholder: `${UIText.subscriptionCurrent}:`,
                    showLabel: true
                  }}
                  inputValue={
                    UIText.subscriptionDisplay(
                      this.controller.currentSubscription
                    ) || UIText.subscriptionNoActive
                  }
                  enabled={false}
                  fieldStyle={this.props.styles.field}
                  fieldLabelStyle={this.props.styles.fieldLabel}
                />
                <View style={this.props.styles.listButtonContainer}>
                  <ThemedButton
                    style={this.props.styles.button}
                    title={
                      isEmpty(this.controller.currentSubscription)
                        ? UIText.subscriptionAdd
                        : UIText.subscriptionChange
                    }
                    onPress={this.controller.handleSubscriptionPress}
                  />
                </View>
              </>
            )}
          </Observer>
          <Observer>
            {() =>
              !isEmpty(this.controller.currentSubscription) && (
                <FormField
                  field={{
                    type: "input",
                    placeholder: `${UIText.subscriptionStatus}:`,
                    showLabel: true
                  }}
                  inputValue={
                    this.controller.currentSubscription.status === "active"
                      ? UIText.subscriptionActive(
                          this.controller.currentSubscription.renewalDate
                        )
                      : this.controller.currentSubscription.status ===
                        "setToCancel"
                      ? UIText.subscriptionSetToCancel(
                          this.controller.currentSubscription.setToCancelDate
                        )
                      : this.controller.currentSubscription.status === "paused"
                      ? UIText.subscriptionPaused
                      : UIText.subscriptionExpired
                  }
                  enabled={false}
                  fieldStyle={this.props.styles.field}
                  fieldLabelStyle={this.props.styles.fieldLabel}
                />
              )
            }
          </Observer>
          {/*Subscription entry*/}

          {/*Footer notes*/}
          <View style={this.props.styles.footerNoteContainer}>
            <Text style={this.props.styles.footerNote}>
              {UIText.subscriptionLearnMore1}
              <Text
                onPress={this.controller.handleMarketingNumberPress}
                style={{
                  ...this.props.styles.footerNote,
                  ...this.props.styles.footerNoteLink
                }}
              >
                {planMarketingNumber}
              </Text>
              {UIText.planLearnMore2}
              <Text
                onPress={this.controller.handleMarketingContactFormPress}
                style={{
                  ...this.props.styles.footerNote,
                  ...this.props.styles.footerNoteLink
                }}
              >
                {UIText.planLearnMoreContactForm}
              </Text>
              {"."}
            </Text>
          </View>
          {/*Footer notes*/}
        </View>
      )}
    </Observer>
  );

  renderWpFrame = () => (
    <View style={this.props.styles.frameContainer}>
      <View style={this.props.styles.navButtonContainer}>
        <ThemedButton
          width={45}
          height={40}
          iconSize={22}
          icon="arrow-back"
          disabled={!wordPressService.frameBackable}
          onPress={wordPressService.onFrameWindowBack}
        />
        <ThemedButton
          width={45}
          height={40}
          iconSize={22}
          icon="arrow-forward"
          disabled={!wordPressService.frameForwardable}
          onPress={wordPressService.onFrameWindowForward}
        />
        <ListRefreshButtonWeb
          onRefresh={wordPressService.onRefreshFrame}
          loading={wordPressService.wpFrameTransient}
        />
      </View>
      <iframe
        style={this.props.styles.frame}
        ref={wordPressService.wpFrameRef}
        className="wpPaymentFrame"
        src={wordPressService.wpUrl}
        title="wpPaymentFrame"
      />
    </View>
  );

  renderBillingForm = () => (
    <View style={this.props.styles.scrollViewContent}>
      <PlanPaymentForm
        paymentUpdateIntro={UIText.subscriptionChangeBillingIntro}
        enabledGetter={() => true}
        valueGetter={field => (field.value ? field.value : "")}
        onInputChange={(e, field) => {
          this.controller.onFieldInputChange(field, e);
        }}
        onCheckboxChange={field => {
          field.value = !field.value;
        }}
        onPickerChange={(value, field) => {
          field.value = value;
        }}
        onDateTimeChange={(value, field) => {
          field.value = value;
        }}
        onSubmitEditing={(form, field) => {
          this.controller.helpFocusNextField(
            field,
            this.billingFormFields,
            form
          );
        }}
        refSetter={(form, field, f) => {
          this.billingFormFields[form.indexOf(field)] = f;
        }}
        isPaymentUpdate
        isWpWcMode
        billingForm={this.controller.billingForm}
      />

      <View style={this.props.styles.buttonContainer}>
        <ThemedButton
          visible
          title={UIText.generalSubmit}
          loading={this.controller.submitting}
          disabled={
            this.controller.submitting ||
            !this.controller.wpWcBillingFormChanged ||
            !this.controller.validBilling
          }
          onPress={this.controller.handleBillingFormSubmit}
        />
      </View>
    </View>
  );

  render() {
    return (
      <View style={this.props.styles.style}>
        <Header
          title={
            this.controller.currentPage === "wordpress"
              ? this.controller.isWpPaymentWindow
                ? UIText.planChangePayment
                : UIText.subscriptionChange
              : UIText.planBillingPlan
          }
          leftIcon="arrow-back"
          handleLeftPress={this.controller.handleBackPress}
          navigation={this.props.navigation}
        />
        <ScrollView
          ref={view => (this.scrollView = view)}
          contentContainerStyle={this.props.styles.scrollView}
        >
          {this.controller.loading ? (
            <View style={this.props.styles.loading}>
              <ActivityIndicator size="large" color={theme.color} />
            </View>
          ) : this.controller.currentPage === "welcome" ? (
            this.renderWelcome()
          ) : this.controller.currentPage === "wordpress" ? (
            this.renderWpFrame()
          ) : this.controller.currentPage === "billing" ? (
            this.renderBillingForm()
          ) : null}
        </ScrollView>

        <AndroidBackHandler onBackPress={this.controller.handleBackPress} />
      </View>
    );
  }
}

export { BillingScreen };
