import { common } from "../../config/style-configs/common";

export const styles = {
  style: {
    flex: 1
  },
  card: {
    paddingVertical: 10
  },
  buttonContainer: {
    ...common.listButtonContainer,
    paddingHorizontal: 0
  }
};
