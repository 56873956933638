import { common } from "../../../../config/style-configs/common";
import { theme } from "../../../../config/style-configs/theme";

export const styles = {
  style: {
    flex: 1
  },
  summarySections: {
    width: "100%",
    maxWidth: 385
  },
  scrollView: common.scrollView,
  scrollViewContent: {
    ...common.scrollViewContent,
    // alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    flexDirection: "row"
  },
  scrollViewCard: common.scrollViewCard,
  card: common.card,
  cardCompactContent: {
    minHeight: 110
  },
  cardContent: common.cardChildrenCompact,
  cardContentText: {
    paddingLeft: 8
  },
  cardContentXLarge: common.cardChildrenTextXLarge,
  cardContentLarge: common.cardChildrenTextLarge,
  cardContentMedium: common.cardChildrenTextMedium,
  cardContentSmall: common.cardChildrenTextSmall,
  cardAvatarContainer: {
    minWidth: 70,
    minHeight: 70,
    borderRadius: 70,
    alignItems: "center",
    justifyContent: "center"
  },
  cardAvatarAppendix: {
    paddingTop: 5,
    color: "#666",
    fontSize: theme.FONT_SIZE_SMALL,
    fontWeight: "600"
  },
  cardAvatarText: {
    fontSize: theme.FONT_SIZE_XXLARGE + 2,
    fontWeight: "600",
    color: "#fff"
  },
  divider: {
    marginVertical: 5
  },
  loading: common.loading,
  buttonContainer: common.listButtonContainer,
  errorMessage: common.containerErrorMessage
};
