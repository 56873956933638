import { theme } from "../../../../config/style-configs/theme";

export const styles = {
  style: {
    height: "35px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  samples: {
    color: "#333",
    fontSize: theme.FONT_SIZE_SMALL
  }
};
