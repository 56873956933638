import { groupTypeIds } from "../../../config/variable-config";

export const registrationIntents = [
  {
    groupTypeRoleId: 1,
    intentText: "I'm Family Relations."
  },
  {
    groupTypeRoleId: 2,
    intentText: "I'm Caregiver Relations."
  },
  {
    enabled: true,
    groupTypeRoleId: 3,
    // intentText: "I'm a family caregiver."
    intentText: "I'm a family member of the care recipient"
  },
  {
    groupTypeRoleId: 4,
    intentText: "I'm a family caregiver." // Secondary
  },
  {
    groupTypeRoleId: 5,
    intentText: "I'm care receiver" // Not likely...
  },
  {
    groupTypeRoleId: 9,
    intentText: "I'm a Paid Caregiver."
  },
  {
    groupTypeRoleId: 7,
    intentText: "I'm Health Care Provider, Practitioner."
  },
  {
    groupTypeRoleId: 8,
    intentText: "I'm a Lawyer"
  },
  {
    groupTypeRoleId: "other",
    // intentText: "I'm a Service Provider."
    intentText:
      "I’m a Service Provider for services other than professional caregiving"
  }
];

export const serviceProviderGroupTypeField = {
  flags: {
    caregiver: true
  },
  name: "groupTypeId",
  type: "picker",
  placeholder: "Service category",
  value: groupTypeIds.handyman,
  options: [
    {
      name: groupTypeIds.handyman,
      placeholder: "Handyman"
    },
    {
      name: groupTypeIds.cleaner,
      placeholder: "Cleaning"
    },
    {
      name: groupTypeIds.transportation,
      placeholder: "Transportation"
    }
  ].filter(Boolean)
};

export const careCircleIntentTypeRoleId = [3, 4, 6, 7, 8, 11, 12];
