import React from "react";
import { observer } from "mobx-react";
import { View } from "react-native";

@observer
class HtmlComponentView extends React.Component {
  render() {
    return (
      <View style={this.props.styles.style}>
        {/*<WebView*/}
        {/*style={this.props.styles.style}*/}
        {/*source={{ html: this.props.html }}*/}
        {/*originWhitelist={["*"]}*/}
        {/*/>*/}
        {this.props.url ? (
          <iframe src={this.props.url} />
        ) : this.props.useFrame ? (
          <iframe
            style={{
              ...this.props.styles.webFrame,
              ...this.props.style
            }}
            srcDoc={this.props.html}
          />
        ) : (
          <div
            style={this.props.styles.webDiv}
            dangerouslySetInnerHTML={{ __html: this.props.html }}
          />
        )}
      </View>
    );
  }
}

export { HtmlComponentView };
