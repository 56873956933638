import { theme } from "../../config/style-configs/theme";

export const styles = {
  activeColor: theme.color,
  disabledColor: theme.lightColor,
  inActiveColor: "#666",
  iconSize: 24,
  divider: {
    backgroundColor: theme.bottomBarDividerColor
  },
  barColor: theme.bottomBarColor,
  rippleDrop: theme.bottomBarRippleDrop,
  rippleSize: 0
};
