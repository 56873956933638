import React from "react";
import { CaregiverPostInterviewController } from "./controller";
import { CaregiverPostInterviewView } from "./view";
import { extraStyles, styles } from "./styles";

export class CaregiverPostInterview extends React.Component {
  static navigationOptions = {
    title: `Caregiver Application`
  };

  controller = new CaregiverPostInterviewController(this.props);

  render() {
    return (
      <CaregiverPostInterviewView
        {...this.props}
        styles={styles}
        extraStyles={extraStyles}
        controller={this.controller}
      />
    );
  }
}
