// Stores filter states, depending on components' persistent filter needs.
import { observable, toJS } from "mobx";
import { UIText } from "../../config/lang-config";
import { isEmpty } from "../../utils/helpers";
import { env } from "../../config/variable-config";
import { getTopicCompletionState } from "../lib/topic-utilities";
import { clientController } from "./client-controller";

const AdminCentreCaregiverFilterTemplate = isCaregiver =>
  [
    isCaregiver && {
      name: "Province",
      classifier: c => {
        const profile = (c.profile && c.profile.data) || {};
        const availabilityLocations = profile.availabilityLocations || [];
        if (!availabilityLocations[0]) return "Unknown";
        return (
          ((availabilityLocations[0].location || {}).address || {}).state ||
          "Unknown"
        );
        // return profile.ableTerritories00
        //   ? profile.ableTerritories01
        //     ? "Toronto and Vancouver"
        //     : "Greater Toronto only"
        //   : profile.ableTerritories01
        //   ? "Greater Vancouver only"
        //   : "No territory checked";
      }
    },
    !isCaregiver && {
      name: "Territories",
      classifier: c => {
        const profile = (c.profile && c.profile.data) || {};
        return profile.ableTerritories00
          ? profile.ableTerritories01
            ? "Toronto and Vancouver"
            : "Greater Toronto only"
          : profile.ableTerritories01
          ? "Greater Vancouver only"
          : "No territory checked";
      }
    },
    {
      name: "Candidate screening process",
      classifier: c =>
        !isEmpty(c.topic)
          ? "Interview screening"
          : !isEmpty(c.onboarding)
          ? "Questionnaire screening"
          : "Legacy screening"
    },
    {
      name: "Screening status",
      classifier: c => {
        const topic = (c.topic && c.topic.data) || {};
        const onboarding = c.onboarding || {};
        return (
          topic.screeningStatus ||
          onboarding.screeningStatus ||
          "(Screening status not applicable)"
        );
      }
    },
    {
      name: "Accepted terms",
      classifier: c => {
        const user = c.user || {};
        return !user["termsAgreed"]
          ? UIText.generalNo
          : !isEmpty(user)
          ? UIText.generalYes
          : "Unknown";
      },
      alwaysShownCriteria: [UIText.generalYes, UIText.generalNo]
    }
  ].filter(Boolean);

class FilterController {
  @observable AdminCentreCaregiverFilter = [
    ...AdminCentreCaregiverFilterTemplate(true)
  ];

  @observable AdminCentreCareCircleFilter = [
    {
      name: "Territory",
      classifier: c => {
        const profile = c.profile || {};
        const value = this.getFormPickerPlaceholderValue(
          profile.data,
          profile.typeClassId,
          "territories"
        );
        return !!profile.data["territories"]
          ? !!value
            ? value
            : "Unknown"
          : "Unknown";
      }
    },
    {
      name: "City",
      classifier: c => {
        const profile = c.profile || {};
        const value = this.getFormPickerPlaceholderValue(
          profile.data,
          profile.typeClassId,
          "municipalities"
        );
        return !!profile.data["municipalities"]
          ? !!value
            ? value
            : "Unknown"
          : "Unknown";
      }
    },
    {
      name: "Valid plan/subscription",
      classifier: c =>
        c.group &&
        (isEmpty(c.group.subscription) && isEmpty(c.group.wpSubscription))
          ? UIText.generalNo
          : UIText.generalYes
    }
  ];

  @observable AdminCentreProviderFilter = [
    ...AdminCentreCaregiverFilterTemplate(false)
  ];

  CareReceiverShiftTitleCategory = `${UIText.shiftMakerShiftTemplate}/${
    UIText.shiftCalendarAddShiftTitle
  }`;
  CareReceiverShiftListFilterProto = [
    {
      name: "Date",
      classifier: t =>
        t.execEndTime
          ? new Date(t.execEndTime).toDateString()
          : t.execStartTime
          ? new Date(t.execStartTime).toDateString()
          : t.startTime
          ? new Date(t.startTime).toDateString()
          : "No date",
      sort: (dateA, dateB) => {
        if (dateA === "No date" || dateB === "No date") return -1;
        return new Date(dateA).getTime() - new Date(dateB).getTime();
      }
    },
    {
      name: `${UIText.shiftClockedInShifts}/${UIText.shiftClockedOutShifts}`,
      classifier: t => getTopicCompletionState(t)
    },
    {
      name: this.CareReceiverShiftTitleCategory,
      classifier: t => t.description
    },
    {
      name: UIText.shiftIsSimpleShifts,
      classifier: t => (t.onCalendar ? UIText.generalNo : UIText.generalYes)
    }
  ];
  @observable CareReceiverShiftListFilters = {}; // groupId is key.

  constructor() {
    clientController.setFilterController(this);
  }

  registerGetPickerPlaceholderValue = method =>
    (this.getFormPickerPlaceholderValue = method);

  applyFilterCategories = (array, filterCategories) => {
    for (let category of filterCategories) {
      if (!category.selected) continue;
      array = array.filter(t => category.classifier(t) === category.selected);
    }
    return array;
  };

  filterSetter = (filterCategories, newCategories) => {
    for (let category of newCategories) {
      const targetCategory = filterCategories.find(
        c => c.name === category.name
      );
      if (targetCategory) targetCategory.selected = category.selected;
    }
  };

  reset = () => {
    const dummy = new FilterController();
    for (let props in this) {
      if (this.hasOwnProperty(props)) {
        const prop = toJS(dummy)[props];
        typeof prop !== "function" && (this[props] = prop);
      }
    }
  };
}

const filterController = new FilterController();

// For development;
if (window && env !== "prod") window.filterController = filterController;

export { filterController };
