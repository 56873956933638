import { AppRegistry } from "react-native";
import { RootContainer } from "./boot";
import { FontLoader } from "./utils/font-loader";
import { StripeLoader } from "./utils/stripe-loader";
import { EnvAdjustmentLoader } from "./utils/env-adjustment-loader";
import { PolyfillMSEdgeToBlobMethod } from "./utils/edge-toblob-polyfill";

FontLoader();
StripeLoader();
EnvAdjustmentLoader();
PolyfillMSEdgeToBlobMethod();

AppRegistry.registerComponent("Root", () => RootContainer);

AppRegistry.runApplication("Root", {
  rootTag: document.getElementById("root")
});
