import React from "react";
import { ActivityIndicator, ScrollView, Text, View } from "react-native";
import { Button, Divider, Icon } from "react-native-elements";
import { observer, Observer } from "mobx-react";
import { isEmpty } from "../../../../utils/helpers";
import { theme } from "../../../../config/style-configs/theme";
import { UIText } from "../../../../config/lang-config";
import { ListRefreshButtonWeb } from "../../../../components/ListRefreshButtonWeb";
import { ThemedButton } from "../../../../components/ThemedButton";
import { FormField } from "../../../../components/FormField";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger
} from "react-native-popup-menu";
import { common } from "../../../../config/style-configs/common";
import { Form } from "../../../../components/Form";

@observer
class AdminTerritoryPageView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  getMunicipalityMenuOptions = municipality => {
    const options = this.controller.getMunicipalityMenuOptions(municipality);
    return options.map((o, i) => (
      <Observer key={o.key}>
        {() => (
          <>
            <MenuOption
              onSelect={o.onSelect}
              text={o.placeholder}
              customStyles={{
                optionWrapper: common.menuOptionWrapper,
                optionText: common.menuOptionText
              }}
            />
            {i === 0 && <Divider />}
          </>
        )}
      </Observer>
    ));
  };

  renderMunicipalities = municipalities => {
    if (isEmpty(municipalities)) return null;

    return (
      <View style={this.props.styles.municipalitiesContainer}>
        {municipalities.map(m => (
          <Observer key={m.name}>
            {() => (
              <View style={this.props.styles.municipalityItem}>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={this.props.styles.municipalityItemText}
                >
                  {m.placeholder || m.name}
                </Text>
                <Menu>
                  <MenuTrigger
                    customStyles={{
                      triggerOuterWrapper: common.menuTriggerContainer
                    }}
                    children={<Icon name="more-vert" size={24} />}
                  />
                  <MenuOptions
                    customStyles={{
                      optionsContainer: common.menuOptionContainer
                    }}
                  >
                    {this.getMunicipalityMenuOptions(m)}
                  </MenuOptions>
                </Menu>
              </View>
            )}
          </Observer>
        ))}
      </View>
    );
  };

  renderProvinceCheckboxes = () => {
    const { provincesForm, onToggleProvince } = this.controller;
    if (isEmpty(provincesForm)) return null;
    const fieldProps = {
      showLabel: true,
      showTitle: false,
      enabledGetter: () => true,
      valueGetter: field => field.value,
      onCheckboxChange: field => onToggleProvince(field)
    };
    return <Form form={provincesForm} fieldProps={fieldProps} />;
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        {this.controller.loading ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          <ScrollView contentContainerStyle={this.props.styles.scrollView}>
            <View style={this.props.styles.scrollViewContent}>
              <View style={this.props.styles.buttonContainer}>
                <Button
                  type="clear"
                  title={UIText.adminCentreCareCircleProvinces}
                  titleStyle={this.props.styles.buttonTitle}
                  onPress={() => {}}
                />
                <ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />
              </View>
              <View style={{ marginBottom: 20 }}>
                {this.renderProvinceCheckboxes()}
              </View>
              <Divider style={{ marginBottom: 20 }} />
              <View style={this.props.styles.buttonContainer}>
                <Text style={this.props.styles.buttonTitle}>
                  {UIText.adminCentreLegacyTerritoriesTitle}
                </Text>
              </View>
              <FormField
                field={this.controller.provincePickerField}
                onPickerChange={this.controller.onProvinceChange}
              />
              {this.controller.selectedProvince ? (
                <FormField
                  field={this.controller.territoryPickerField}
                  onPickerChange={this.controller.onTerritoryChange}
                />
              ) : null}
              {this.controller.selectedTerritory ? (
                <View style={this.props.styles.muniContainer}>
                  <FormField
                    field={this.controller.municipalityLabelField}
                    enabled={false}
                    inputValue={this.controller.municipalityLabelField.value}
                    fieldStyle={this.props.styles.municipalityLabelField}
                  />
                  <ThemedButton
                    style={this.props.styles.button}
                    icon="add"
                    title={UIText.adminCentreTerritoryAddMunicipality}
                    onPress={this.controller.handleAddMunicipalityPress}
                  />
                  {this.renderMunicipalities(this.controller.municipalities)}
                </View>
              ) : null}
            </View>
          </ScrollView>
        )}
      </View>
    );
  }
}

export { AdminTerritoryPageView };
