import { getTopicAssigneeMembers } from "../../../../../../../cdm/lib/topic-utilities";
import { getDisplayNameEng } from "../../../../../../../utils/helpers";
import { UIText } from "../../../../../../../config/lang-config";
import { template } from "../setup";

export const todoTimeAssigneeForm = group => {
  let form = [];
  const timezone = ((group.profile || {}).data || {}).timezone;

  const { members, assigneeMemberIds } = getTopicAssigneeMembers(group);

  const selections = members.map(m => ({
    type: "checkbox",
    set: "assignee",
    name: m.id,
    placeholder: getDisplayNameEng(m.profile.data),
    value: assigneeMemberIds.includes(Number(m.id))
  }));

  selections[0].expanded = true;
  selections[0].title = UIText.topicPickAssignee(template.title);

  //date picker
  const today = getISOStringTimeZone(
    new Date(new Date().setHours(0, 0, 0, 0)),
    timezone
  );
  const startDate = {
    set: "time",
    title: "To-do start/end time",
    expanded: true,
    name: "startTime",
    type: "dateTime",
    mode: "datetime-local",
    placeholder: UIText.topicStartTime,
    min: today
  };
  const endDate = {
    set: "time",
    name: "endTime",
    type: "dateTime",
    mode: "datetime-local",
    placeholder: UIText.topicEndTime,
    min: today
  };

  form.push(...selections, startDate, endDate);

  return form;
};
