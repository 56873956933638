import React from "react";
import { RootScreen } from "./view";
import { RootController } from "./controller";
import { styles } from "./styles";

// The root screen will be the navigation determinator when first arrives after sign in.
export class Root extends React.Component {
  static navigationOptions = {
    title: "Root"
  };

  controller = new RootController(this.props);

  render() {
    return (
      <RootScreen
        {...this.props}
        styles={styles}
        controller={this.controller}
      />
    );
  }
}
