import React from "react";
import { CareReceiverIssueListView } from "./view";
import { CareReceiverIssueListController } from "./controller";
import { styles } from "./styles";

export class CareReceiverIssueList extends React.Component {
  controller = new CareReceiverIssueListController(this.props);

  render() {
    return (
      <CareReceiverIssueListView
        {...this.props}
        controller={this.controller}
        styles={styles}
      />
    );
  }
}
