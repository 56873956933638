import React from "react";
import { Platform, View } from "react-native";
import { Button, Icon } from "react-native-elements";

export const ThemedButtonView = React.forwardRef((props, ref) => {
  const visible = typeof props.visible !== "boolean" || !!props.visible;

  const icon =
    typeof props.icon === "string" ? (
      <Icon
        containerStyle={{
          ...props.styles.iconContainer,
          ...(props.title && { paddingRight: 5 })
        }}
        size={props.iconSize || props.styles.iconSize}
        type={props.iconType || "material"}
        color="#fff"
        name={props.icon}
      />
    ) : (
      props.icon
    );

  const iconRight = props.iconRight && (
    <Icon
      size={props.iconRightSize || props.styles.iconSize}
      color="#fff"
      name={props.iconRight}
    />
  );

  const dimension = {
    minWidth: props.width || 100,
    minHeight: props.height || 42
  };

  const onPress = e => {
    Platform.OS === "web" && e.preventDefault();
    return props.onPress && props.onPress(e);
  };

  return visible ? (
    <View style={{ ...props.styles.style, ...props.style }}>
      <Button
        ref={ref}
        buttonStyle={{
          ...props.styles.button,
          ...props.buttonStyle,
          ...dimension
        }}
        containerStyle={{
          ...props.styles.buttonContainer,
          ...props.containerStyle
        }}
        disabledStyle={props.styles.disabled}
        disabledTitleStyle={props.styles.disabledTitle}
        icon={icon}
        iconRight={iconRight}
        iconContainerStyle={props.styles.iconContainer}
        loading={props.loading}
        loadingStyle={props.styles.loading}
        raised
        disabled={props.disabled}
        title={props.title || ""}
        titleStyle={props.styles.title}
        onPress={onPress}
      />
    </View>
  ) : (
    <View />
  );
});
