import React from "react";
import { AdminProviderGroupPageView } from "./view";
import { styles } from "./styles";
import { AdminProviderGroupPageController } from "./controller";

export class AdminProviderGroupPage extends React.Component {
  render() {
    const controller = new AdminProviderGroupPageController(this.props);

    return (
      <AdminProviderGroupPageView
        controller={controller}
        {...this.props}
        styles={styles}
      />
    );
  }
}
