import { computed, observable } from 'mobx';
import { clientController } from '../../cdm/controllers/client-controller';
import { apiController } from '../../cdm/controllers/api-controller';
import { fileService } from '../../cdm/services/file-service';

export class AvatarHistoryPickerController {
  @observable avatars = [];
  limit = 4;

  @computed get user() {
    return clientController.client.user || {};
  }

  constructor(props) {
    this.props = props;
    this._initialize();
  }

  _initialize = () => {
    if (clientController.gotEmbeddedModes && clientController.isSyncMode)
      return;
    return this._getUserAvatars();
  };

  _getUserAvatars = async () => {
    return apiController
      .getUserAvatars(this.user.id)
      .then(avatars => {
        if (avatars.length === 0) return;

        avatars = avatars
          .sort(a => a.id)
          .slice(-(this.limit + 1))
          .reverse();

        for (let avatar of avatars) {
          fileService.preloadProfileAvatar(avatar.id);
        }

        this.avatars = avatars;
      })
      .catch(console.warn);
  };
}
