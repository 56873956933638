import { apiService } from "../../../../../../cdm/services/api-service";
import { endpointConfig } from "../../../../../../config/api-config";
import { clientController } from "../../../../../../cdm/controllers/client-controller";
import { observable, toJS } from "mobx";
import { acceptInviteForm } from "./form";
import {
  asyncPause,
  getDisplayNameEng,
  isEmpty,
  validateEmail
} from "../../../../../../utils/helpers";
import {
  memberStatus,
  typeClassIds
} from "../../../../../../config/variable-config";
import { formService } from "../../../../../../cdm/services/form-service";
import { UIText } from "../../../../../../config/lang-config";
import { careCircleNegotiatedPriceBlurHandler } from "../../../../lib/group-utilities-mcb";

const updateMemberProfile = async (invitation, profileDetail, isOffer) => {
  const member = toJS(invitation.invitedMember);
  const memberData = {
    id: member.id,
    userId: clientController.userId,
    groupId: member.groupId,
    email: member.email,
    phone: member.phone,
    profileId: member.profileId,
    status: memberStatus.normal
  };
  const postData = isOffer
    ? { ...memberData }
    : {
        member: memberData,
        profile: {
          id: member.profileId,
          userId: clientController.userId,
          data: JSON.stringify(profileDetail),
          typeClassId: typeClassIds.familyMemberProfile,
          typeClassVersion: 1,
          type: 2 // Member only profile
        }
        // roleIdList: [invitation.invitedRole.id]
      };

  return apiService.async("PUT", {
    endpoint: isOffer
      ? endpointConfig.member_by_id(member.id)
      : endpointConfig.update_member_profile(member.id),
    data: postData
  });
};

const eraseIntentRole = async () => {
  // const intent_role = JSON.stringify([0]);
  return apiService.async("GET", {
    endpoint: endpointConfig.erase_intent_role
  });
};

const updateInvitation = async invitationId => {
  const invitationStatus = 2; // Accepted invitation;
  const acceptTime = new Date().getTime();
  return apiService.async("PATCH", {
    endpoint: endpointConfig.invitation_by_id(invitationId),
    data: { invitationStatus, acceptTime }
  });
};

const notifyGroup = async invitation => {
  const { invitedMember, invitedRole, invitationData } = invitation;
  const customRoleDescription = ((invitedMember.profile || {}).data || {})
    .customRoleDescription;
  const roleDescription = customRoleDescription || invitedRole.description;
  const { invitorMemberId, type } = invitationData;
  const isOffer = type === "hire";
  const data = {
    memberId: invitedMember.id,
    roleDescription,
    invitorMemberId,
    isOffer
  };
  setTimeout(() =>
    apiService
      .async("POST", {
        endpoint: endpointConfig.notify_group_invitation_acceptance,
        data
      })
      .catch(console.error)
  );
  return Promise.resolve();
};

// Setup function.
export const acceptInviteSetup = (
  invitation,
  isOffer,
  roleDescription,
  invitedGroup
) => {
  const setup = {
    key: "acceptInviteSetup",
    title: isOffer ? `New Employment Offer` : `New Invitation`,
    heading: isOffer
      ? `Acceptance`
      : `Complete your profile as a ${roleDescription ||
          invitation.invitedRole.description}.`,
    checkboxTitle: `Accept ${
      isOffer ? "offer" : "invitation"
    } and set up my profile.`,
    form: observable(acceptInviteForm(invitation, isOffer)),
    onBlur: field => {
      field.errorMessage = "";
      if (field.required && !field.value)
        field.errorMessage = `Enter a ${(
          field.label || field.placeholder
        ).toLowerCase()}`;
      if (field.name === "email") {
        field.errorMessage = validateEmail(field.value)
          ? ""
          : (field.value || "").match(/\+/)
          ? UIText.registrationEmailNoPlusSign
          : "Enter a valid email address.";
      }
      careCircleNegotiatedPriceBlurHandler(setup.form, field);
    },
    onSubmit: async ([form, errorFields]) => {
      await asyncPause(100); // Wait for form actions to finish.
      if (!form || !isEmpty(errorFields)) {
        return Promise.reject({
          message: UIText.checkEntriesWithFields(errorFields),
          contentAlign: "left"
        });
      }
      let profileDetail = {};
      if (!isOffer) {
        form.map(field => {
          if (field.type !== "link" && field.type !== "text") {
            profileDetail[field.name] = field.value;
          }
          return undefined;
        });
        profileDetail.displayName = getDisplayNameEng(profileDetail);
      }
      return updateMemberProfile(invitation, profileDetail, isOffer)
        .then(eraseIntentRole)
        .then(() => updateInvitation(invitation.invitationData.id))
        .then(() => notifyGroup(invitation))
        .then(clientController.getUserGroupsAndMembers);
    },
    validate: async form => {
      let pass = formService.validateRequired(form);
      if (!pass) {
        const errorFields = form.filter(f => !!f.errorMessage);
        return [false, errorFields];
      }
      for (let field of form) {
        if (field.name === "email") {
          pass = validateEmail(field.value);
          if (!pass) {
            field.errorMessage = (field.value || "").match(/\+/)
              ? UIText.registrationEmailNoPlusSign
              : "Enter a valid email address.";
            return [false, [field]];
          }
        }
      }
      return [form];
    }
  };
  return setup;
};
