import { common } from "../../../config/style-configs/common";
import { theme } from "../../../config/style-configs/theme";

export const styles = {
  style: {
    flex: 1
  },
  loading: common.loading,
  mCBGroupHeaderButtons: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  additionalCheckbox: {
    paddingVertical: 0,
    paddingHorizontal: 10,
    margin: 0,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "none",
    borderWidth: 0
  },
  additionalCheckboxTitle: {
    marginHorizontal: 5,
    fontSize: theme.FONT_SIZE_MEDIUM,
    fontWeight: "600"
  }
};
