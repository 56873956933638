import { computed, observable, toJS } from "mobx";
import { clientController } from "../../../../cdm/controllers/client-controller";
import { stateController } from "../../../../cdm/controllers/state-controller";
import {
  serviceRecipientGroupTypeIds,
  topicTypeIds
} from "../../../../config/variable-config";
import { apiController } from "../../../../cdm/controllers/api-controller";
import NavigationService from "../../../../utils/navigation-service";
import {
  capitalize,
  getBrowserTimeZoneName,
  isEmpty,
  preventDefaultStopProp
} from "../../../../utils/helpers";
import { UIText } from "../../../../config/lang-config";
import {
  autoCompleteTopicData,
  deleteTopic,
  editTopicAssignee,
  editTopicDescription,
  editTopicMenu,
  editTopicStartEndTime
} from "../../../../cdm/lib/topic-utilities";
import { ShiftTaskController } from "../../lib/shift-task-controller";
import { getTopicSummaryEditFlags } from "../../config/flags_calculator";
import { filterController } from "../../../../cdm/controllers/filter-controller";
import { txService } from "../../../../cdm/services/tx-service";

export class CareReceiverIssueListController {
  disposers = [];

  @observable refreshing = false;
  @observable loaded = false;

  @observable topics = [];

  topicTypeId = topicTypeIds.issue;

  topicFilter = t =>
    t.typeId === this.topicTypeId && !t.isTemplate && !t.isParentTemplate;

  @computed get loading() {
    return !this.loaded;
  }
  // Current view group.
  @computed get group() {
    return clientController.findGroupById(stateController.viewGroupId);
  }
  @computed get timezone() {
    if (!serviceRecipientGroupTypeIds.includes(this.group.typeId))
      return getBrowserTimeZoneName();
    return (
      ((this.group.profile || {}).data || {}).timezone ||
      getBrowserTimeZoneName()
    );
  }
  @computed get member() {
    return (
      this.group.members.find(m => m.userId === clientController.userId) || {}
    );
  }
  // @computed get topics() {
  //   return clientController.findTopics(this.topicFilter);
  // }
  @computed get filteredTopics() {
    let topics = this.topics;
    return filterController.applyFilterCategories(
      topics,
      this.filterCategories
    );
  }
  @computed get collapsible() {
    if (!stateController.viewGroupState.collapsible["allIssues"][this.group.id])
      stateController.viewGroupState.collapsible["allIssues"][
        this.group.id
      ] = {};
    return stateController.viewGroupState.collapsible["allIssues"][
      this.group.id
    ];
  }

  // Filter functions
  @observable filterCategories = [
    {
      name: "Resolution",
      classifier: t =>
        t.isCompleted ? UIText.issuesResolvedIssue : UIText.issuesOpenIssue,
      alwaysShownCriteria: [UIText.issuesOpenIssue, UIText.issuesResolvedIssue]
    }
  ];
  setFilter = filterCategories =>
    filterController.filterSetter(this.filterCategories, filterCategories);
  // Filter functions

  constructor(props) {
    this.props = props;
    this._initialize();
  }

  componentWillUnmount() {
    Array.isArray(this.disposers) &&
      this.disposers.map(disposer => disposer && disposer());
    txService.removeLogEventListener("CareReceiverIssueListListener");
  }

  _showError = err => {
    console.warn(err);
    return stateController.showPopup({
      title: capitalize(UIText.group),
      content: (err && err.message) || err,
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: stateController.dismissPopup
    });
  };

  _initialize = () => {
    this._getTopics()
      .then(() => this.topics.map(t => (t.pending = false)))
      .catch(this._showError)
      .finally(() => {
        this.loaded = true;
        this._registerSSETopicListener();
      });
  };

  _registerSSETopicListener = () => {
    const name = "CareReceiverIssueListListener";
    const listener = async data => {
      if (isEmpty(data) || !data.id || data.entity !== "Topic") return;
      const { id } = data;
      return apiController
        .getTopicById(id)
        .then(topic => this._handleSSETopic(topic, id))
        .then(this.removeDuplicates);
    };
    return txService.addLogEventListener(name, listener);
  };

  _getTopics = async () =>
    apiController
      .getTopicsByGroupId(this.group.id, true)
      .then(topics => topics.filter(this.topicFilter))
      .then(topics => (this.topics = topics))
      .then(this.removeDuplicates);

  _handleSSETopic = (topic, id) => {
    if (!(topic.groupIdList || []).includes(this.group.id)) return;
    const oldTopic = this.topics.find(t => t.id === id);
    if (!!oldTopic && isEmpty(topic)) return this.topics.remove(oldTopic);
    if (!this.topicFilter(topic)) return;
    if (!oldTopic) return this.topics.push(topic);
    return Object.assign(oldTopic, topic);
  };

  removeDuplicates = () => {
    const topics = toJS(this.topics);
    const uniqueIdList = Array.from(new Set(topics.map(topic => topic.id)));
    this.topics = uniqueIdList
      .map(id => topics.find(topic => topic.id === id))
      .filter(Boolean);
    return this.topics;
  };

  checkCompleteTopicData = topic => autoCompleteTopicData(topic, this.topics);

  addIssue = () => {
    stateController.initSetupMode = "addIssue";
    return NavigationService.navigate("Setup");
  };

  getTopicSummaryEditFlags = topic => {
    if (!topic.actors) return [];
    const selfActors = topic.actors.filter(a =>
      a.memberIdList.includes(this.member.id)
    );
    const selfRoles = this.member.roleList || [];

    let flags = [];
    for (let actor of selfActors) {
      for (let role of selfRoles) {
        flags.push(
          ...getTopicSummaryEditFlags(
            topic,
            actor.groupTypeActorId,
            role.groupTypeRoleId,
            this.group
          )
        );
      }
    }

    return flags;
  };

  editTopic = (event, topic) => {
    preventDefaultStopProp(event);

    if (this.loading || topic.pending) return;

    const flags = this.getTopicSummaryEditFlags(topic);

    return editTopicMenu(
      topic,
      flags,
      {
        editTopicName: () => this.editTopicName(topic),
        editTopicAssignee: () => this.editTopicAssignee(topic),
        editTopicStartEndTime: () => this.editTopicStartEndTime(topic),
        deleteTopic: () => this.handleTopicDelete(topic)
      },
      {
        editTopicStartEndTime: UIText.issueEditStartEndTime,
        editTopicAssignee: UIText.issueEditAssignee
      }
    );
  };

  editTopicName = async topic => {
    topic.pending = true;
    return editTopicDescription(topic)
      .then(update => update && this.updateTopic(topic))
      .catch(this._showError)
      .finally(() => {
        topic.pending = false;
      });
  };

  editTopicAssignee = topic => {
    topic.pending = true;
    return editTopicAssignee(
      this.group,
      topic,
      "assigned",
      null,
      null,
      UIText.issueEditAssignee
    )
      .then(update => update && this.updateTopic(topic))
      .catch(this._showError)
      .finally(() => {
        topic.pending = false;
      });
  };

  editTopicStartEndTime = topic => {
    topic.pending = true;
    return editTopicStartEndTime(
      topic,
      { noStart: true },
      null,
      UIText.issueEditStartEndTime,
      this.timezone
    )
      .then(update => update && this.updateTopic(topic))
      .catch(this._showError)
      .finally(() => {
        topic.pending = false;
      });
  };

  handleTopicDelete = topic => {
    topic.pending = true;
    return deleteTopic(topic)
      .then(update => update && this.updateTopic(topic))
      .catch(this._showError)
      .finally(() => {
        topic.pending = false;
      });
  };

  handleTopicPress = topic => {
    const id = topic.id;

    stateController.viewTopicId = id;
    NavigationService.navigate("Topic", { topic: id });
  };

  handleCollapse = name => {
    stateController.viewGroupState.collapsible["allIssues"][this.group.id][
      name
    ] = !this.collapsible[name];
  };

  sortIssues = topics => new ShiftTaskController().sortShifts(topics);

  onRefresh = () => {
    this.refreshing = true;
    return this._getTopics()
      .catch(this._showError)
      .finally(() => (this.refreshing = false));
  };

  updateTopic = async topic =>
    topic &&
    apiController.getTopicById(topic.id).then(clientController.updateTopic);
}
