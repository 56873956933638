import { Icon, Text } from "react-native-elements";
import React from "react";
import { theme } from "../../../../config/style-configs/theme";
import { observer } from "mobx-react";
import ActivityIndicator from "react-native-web/dist/exports/ActivityIndicator";
import { View } from "react-native";
import { TouchableOpacityIOSFix } from "../../../../components/TouchableOpacityIOSFix";
import workspacePremium from "../../assets/workspacePremium";
import { achievementColors } from "../../../../config/variable-config";

const renderStar = (index, filled, halfFill) => (
  <Icon
    type="material-community"
    key={index}
    name={halfFill ? "star-half" : "star"}
    size={20}
    color={filled ? theme.ratingStarColor : "#ccc"}
  />
);

const RatingReviewMarketStarsView = observer(
  ({ styles, loading, samples, rating, achievement, onPress }) => {
    const rounded = rating
      ? Number((Math.round(rating * 2) / 2).toFixed(1))
      : 0;
    const component = (
      <View style={styles.style}>
        {loading ? (
          <ActivityIndicator size="small" color={theme.color} />
        ) : (
          <>
            {achievement && achievement.achievementLevel && (
              <svg
                viewBox="0 0 24 24"
                dangerouslySetInnerHTML={{ __html: workspacePremium }}
                style={{
                  width: "35px",
                  height: "35px",
                  margin: "0 5px",
                  fill: achievementColors[achievement.achievementLevel]
                }}
              />
            )}
            {Array.from(new Array(5)).map((d, i) => {
              if (i === Math.floor(rounded) && !Number.isInteger(rounded)) {
                return renderStar(i, true, true);
              } else {
                return rounded - 1 >= i
                  ? renderStar(i, true, false)
                  : renderStar(i, false);
              }
            })}
            &nbsp;
            <Text styles={styles.samples}>({samples || 0})</Text>
          </>
        )}
      </View>
    );
    return onPress ? (
      <TouchableOpacityIOSFix onPress={onPress}>
        {component}
      </TouchableOpacityIOSFix>
    ) : (
      component
    );
  }
);

export { RatingReviewMarketStarsView };
