import React from "react";
import { PanResponder, Platform, View } from "react-native";
import { Button, Card, Divider, Icon, Text } from "react-native-elements";
import { Avatar } from "../Avatar";
import { Observer, observer } from "mobx-react";
import { theme } from "../../config/style-configs/theme";
import { getRGBFromStr, preventDefaultStopProp } from "../../utils/helpers";
import { TouchableOpacityIOSFix } from "../TouchableOpacityIOSFix";

@observer
class TopicCardView extends React.Component {
  constructor(props) {
    super(props);
    this.avatarProps = {
      size: props.compact ? 70 : 100,
      rounded: true
    };
    this.isWeb = Platform.OS === "web";
    this.buttonDriftThreshold = 2;
    this._panResponder = this.isWeb
      ? PanResponder.create({
          // Ask to be the responder:
          onStartShouldSetPanResponder: (evt, gestureState) => true,
          onStartShouldSetPanResponderCapture: (evt, gestureState) => false,
          onMoveShouldSetPanResponder: (evt, gestureState) => {
            const { dx, dy } = gestureState;
            return (
              dx > this.buttonDriftThreshold ||
              dx < -this.buttonDriftThreshold ||
              dy > this.buttonDriftThreshold ||
              dy < -this.buttonDriftThreshold
            );
          },
          onMoveShouldSetPanResponderCapture: (evt, gestureState) => false, // Problem
          onPanResponderTerminationRequest: (evt, gestureState) => true,
          onShouldBlockNativeResponder: (evt, gestureState) => false
        })
      : {};
  }

  renderContent = () => (
    <Observer>
      {() => (
        <View
          style={{
            ...this.props.styles.content,
            ...this.props.contentStyle
          }}
        >
          <View style={this.props.styles.avatarContainer}>
            {!this.props.hideAvatar ? (
              this.props.avatarComponent ? (
                this.props.avatarComponent
              ) : (
                <Avatar {...this.avatarProps} />
              )
            ) : null}
            {this.props.avatarAppendix && (
              <View>{this.props.avatarAppendix}</View>
            )}
          </View>
          {this.renderChildren()}
        </View>
      )}
    </Observer>
  );

  renderCompactContent = () => (
    <Observer>
      {() => (
        <View
          style={{
            ...this.props.styles.compactContent,
            ...this.props.contentStyle
          }}
        >
          <View style={this.props.styles.avatarContainerCompact}>
            {!this.props.hideAvatar ? (
              this.props.avatarComponent ? (
                this.props.avatarComponent
              ) : (
                <Avatar {...this.avatarProps} />
              )
            ) : null}
            {this.props.avatarAppendix && (
              <View>{this.props.avatarAppendix}</View>
            )}
          </View>
          {this.renderChildren()}
        </View>
      )}
    </Observer>
  );

  renderChildren = compact => (
    <Observer>
      {() => (
        <>
          {this.props.children ? (
            this.props.children
          ) : (
            <View
              style={{
                ...this.props.styles.cardChildren,
                alignItems: compact ? "flex-start" : "center"
              }}
            >
              <Text
                style={this.props.styles.text}
                numberOfLines={compact ? 4 : 8}
                ellipsizeMode="tail"
              >
                {this.props.text || ""}
              </Text>
            </View>
          )}
        </>
      )}
    </Observer>
  );

  renderCustomIcons = icons =>
    icons.map((icon, i) => (
      <Observer key={icon.key}>
        {() => (
          <View
            style={{
              ...this.props.styles.titleChild,
              ...this.props.styles.titleIcon,
              ...icon.styles
              // ...(!this.props.showMenuIcon &&
              // !this.props.onCheckPress &&
              // icons.length === 1 && { alignItems: "flex-end" })
            }}
          >
            <Button
              buttonStyle={this.props.styles.checkbox}
              containerStyle={this.props.styles.checkbox}
              type="clear"
              loading={icon.loading}
              loadingProps={{ color: theme.color }}
              icon={
                icon.name ? (
                  <Icon
                    name={icon.name}
                    type={icon.type || "material-community"}
                    size={this.props.styles.checkboxSize}
                    color={icon.color || "#666"}
                  />
                ) : icon.component ? (
                  icon.component
                ) : null
              }
              onPress={icon.onPress || (() => {})}
            />
          </View>
        )}
      </Observer>
    ));

  onPress = event => {
    preventDefaultStopProp(event);
    return this.props.onPress && this.props.onPress(event);
  };

  render() {
    if (this.props.icon) {
      this.avatarProps.icon = this.props.icon && { name: this.props.icon };
      if (this.props.iconType) this.avatarProps.icon.type = this.props.iconType;
      this.avatarProps.backgroundColor =
        this.props.iconBackgroundColor || "#bdbdbd";
    } else {
      this.avatarProps.title =
        (this.props.avatarTitle && this.props.avatarTitle[0]) ||
        (this.props.title && this.props.title[0]);
      this.avatarProps.backgroundColor = getRGBFromStr(
        (this.props.avatarTitle || this.props.title) + this.props.titleId
      );
      this.avatarProps.source = { uri: this.props.image };
    }

    const checkedIcon = (
      <Icon
        type="material-community"
        name={this.props.checkedIcon || "heart"}
        size={this.props.styles.checkboxSize}
        color={this.props.checkedIconColor || theme.color}
        containerStyle={
          this.props.checkedIconCursor &&
          Platform.OS === "web" && {
            cursor: this.props.checkedIconCursor
          }
        }
      />
    );
    const uncheckedIcon = (
      <Icon
        type="material-community"
        name={this.props.uncheckedIcon || "heart-outline"}
        size={this.props.styles.checkboxSize}
        color={this.props.uncheckedIconColor || "#666"}
        containerStyle={
          this.props.uncheckedIconCursor &&
          Platform.OS === "web" && {
            cursor: this.props.uncheckedIconCursor
          }
        }
      />
    );

    const title = (
      <View style={this.props.styles.titleWrapper}>
        <View style={this.props.styles.titleContent}>
          <View
            style={{
              ...this.props.styles.titleChild,
              ...this.props.styles.titleTextContainer
            }}
          >
            <Text
              style={this.props.styles.titleText}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {this.props.title}
            </Text>
          </View>

          {this.props.customIcons &&
            this.renderCustomIcons(this.props.customIcons)}

          {this.props.onCheckPress && (
            <View
              style={{
                ...this.props.styles.titleChild,
                ...this.props.styles.titleIcon,
                ...(!this.props.customIcons &&
                  !this.props.showMenuIcon && { alignItems: "flex-end" })
              }}
            >
              <Button
                buttonStyle={this.props.styles.checkbox}
                containerStyle={this.props.styles.checkbox}
                type="clear"
                icon={this.props.checked ? checkedIcon : uncheckedIcon}
                loading={this.props.pending}
                loadingProps={{ color: theme.color }}
                disabled={this.props.pending}
                onPress={this.props.onCheckPress}
              />
            </View>
          )}

          {this.props.showMenuIcon && this.props.onMenuPress && (
            <View
              style={{
                ...this.props.styles.titleChild,
                ...this.props.styles.titleIcon,
                ...{ alignItems: "flex-end" }
              }}
            >
              <Button
                buttonStyle={this.props.styles.checkbox}
                containerStyle={this.props.styles.checkbox}
                type="clear"
                icon={
                  <Icon
                    // name="more-vert"
                    name="playlist-play"
                    size={this.props.styles.menuIconSize}
                    color={this.props.menuIconColor || "#666"}
                  />
                }
                loading={this.props.menuPending}
                loadingProps={{ color: theme.color }}
                disabled={this.props.menuPending}
                onPress={this.props.onMenuPress}
              />
            </View>
          )}
        </View>
        <Divider style={this.props.styles.titleDivider} />
      </View>
    );

    const card = (
      <Card
        containerStyle={{
          ...this.props.styles.card,
          ...this.props.style
        }}
        dividerStyle={this.props.styles.divider}
        featuredSubtitle={this.props.featuredSubtitle}
        featuredTitle={this.props.featuredTitle}
        title={title}
      >
        {this.props.compact
          ? this.renderCompactContent()
          : this.renderContent()}
      </Card>
    );

    return (
      <View style={this.props.styles.style} {...this._panResponder.panHandlers}>
        <TouchableOpacityIOSFix onPress={this.onPress}>
          {card}
        </TouchableOpacityIOSFix>
      </View>
    );
  }
}

export { TopicCardView };
