import React from "react";
import {
  ActivityIndicator,
  Picker,
  Platform,
  RefreshControl,
  ScrollView,
  View
} from "react-native";
import { Button, Text } from "react-native-elements";
import { Observer, observer } from "mobx-react";
import { TabHeader } from "../../../../components/TabHeader/index";
import { UIText } from "../../../../config/lang-config";
import { ThemedButton } from "../../../../components/ThemedButton/index";
import { theme } from "../../../../config/style-configs/theme";
import { TopicCard } from "../../../../components/TopicCard/index";
import { capitalize, safeParseJSON } from "../../../../utils/helpers";
import { ShiftCard } from "../ShiftCard";
import { ListFilter } from "../../../../components/ListFilter";
import {
  getTopicAssignedMembers,
  getTopicCompletionState
} from "../../../../cdm/lib/topic-utilities";
import { ListFilterIndicator } from "../../../../components/ListFilterIndicator";
import { Tooltip } from "../../../../components/Tooltip";
import { CircleCharIcon } from "../../../../components/CircleCharIcon";
import { ListRefreshButtonWeb } from "../../../../components/ListRefreshButtonWeb";
import VizSensor from "react-visibility-sensor";

@observer
class GroupShiftPageView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentWillUnmount() {
    this.controller.componentWillUnmount();
  }

  renderComponent = tabKey => {
    const isNative = Platform.OS !== "web";
    if (tabKey === "task_templates") {
      return this.controller.loading || this.controller.taskTemplateLoading ? (
        <View style={this.props.styles.loading}>
          {!isNative && <ActivityIndicator size="large" color={theme.color} />}
        </View>
      ) : (
        this.renderTaskTemplates()
      );
    }
    if (tabKey === "shifts") {
      return this.controller.loading || this.controller.shiftInstanceLoading ? (
        <View style={this.props.styles.loading}>
          {!isNative && <ActivityIndicator size="large" color={theme.color} />}
        </View>
      ) : (
        this.renderShifts()
      );
    }
  };

  renderTaskTemplates = () => (
    <View style={this.props.styles.scrollViewContent}>
      <View style={this.props.styles.buttonContainer}>
        <ListFilter
          data={this.controller.currentShiftTemplateTaskTemplates}
          categories={this.controller.taskTemplatesFilterCategories}
          filterSetter={this.controller.setTaskTemplateFilter}
        />
        <ThemedButton
          icon={
            <CircleCharIcon outline={true} color="#fff" size={19} value={2} />
          }
          style={this.props.styles.button}
          title={UIText.shiftMakerNewTaskTemplate}
          onPress={this.controller.addTaskTemplate}
        />
        <ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />
      </View>
      <ListFilterIndicator
        length={
          this.controller.currentShiftTemplateFilteredTaskTemplates.length
        }
        categories={this.controller.taskTemplatesFilterCategories}
      />
      <View style={this.props.styles.buttonReminder}>
        <Text style={this.props.styles.errorMessage}>
          {UIText.shiftMakerActivateReminder1}
        </Text>
      </View>
      {!this.controller.currentShiftTemplateActive && (
        <View style={this.props.styles.buttonReminder}>
          <Text style={this.props.styles.errorMessage}>
            {UIText.shiftMakerActivateReminder2}
          </Text>
        </View>
      )}
      <View style={this.props.styles.scrollViewCard}>
        {this.renderTaskCards(
          this.controller.currentShiftTemplateFilteredTaskTemplates
        )}
      </View>
    </View>
  );

  renderShifts = () => (
    <View style={this.props.styles.scrollViewContent}>
      <View style={this.props.styles.buttonContainer}>
        <ListFilter
          data={this.controller.currentShiftTemplateShiftInstances}
          categories={this.controller.shiftInstanceFilterCategories}
          filterSetter={this.controller.setShiftInstanceFilter}
        />
        <ThemedButton
          style={this.props.styles.button}
          title={UIText.shiftMakerAllScheduledShifts}
          onPress={this.controller.handleAllSchedulePress}
        />
        <Button
          type="clear"
          loading={this.controller.shiftTemplateActivating}
          loadingProps={{ color: theme.color }}
          onPress={() => {}}
        />
        <ListRefreshButtonWeb onRefresh={() => this.controller.onRefresh()} />
      </View>
      <ListFilterIndicator
        length={this.controller.currentShiftTemplateFilteredInstances.length}
        categories={this.controller.shiftInstanceFilterCategories}
      />
      <View style={this.props.styles.scrollViewCard}>
        {this.renderShiftCards(
          this.controller.currentShiftTemplateFilteredInstances
        )}
      </View>
    </View>
  );

  renderTaskCards = tasks => {
    if (!Array.isArray(tasks) || tasks.length === 0) {
      return (
        <Text style={this.props.styles.errorMessage}>
          {UIText.shiftMakerNoTaskToShow}
        </Text>
      );
    }

    tasks = this.controller.sortTasks(tasks);

    return tasks.map(task => {
      const data = (task.data && safeParseJSON(task.data)) || {};
      const showMenuIcon =
        this.controller.getTaskTemplateSummaryEditFlags(task).length > 0;

      const children = (
        <View style={this.props.styles.cardContent}>
          <Text style={this.props.styles.cardContentMedium}>
            {capitalize(data.taskType)}
          </Text>
          {data.originalTitle && (
            <Text style={this.props.styles.cardContentSmall}>
              {`${UIText.shiftMakerTaskOriginalTitle}:\n${data.originalTitle}`}
            </Text>
          )}
        </View>
      );

      return (
        <Observer key={task.id}>
          {() => (
            <View style={this.props.styles.card}>
              <TopicCard
                compact={true}
                title={task.description || data.title || ""}
                icon="clipboard-text-outline"
                iconType="material-community"
                showMenuIcon={showMenuIcon}
                menuPending={task.pending}
                onMenuPress={e => this.controller.editTaskTemplate(e, task)}
                onPress={e => this.controller.handleTaskTemplatePress(task)}
                children={children}
              />
            </View>
          )}
        </Observer>
      );
    });
  };

  renderShiftCards = shifts => {
    if (!Array.isArray(shifts) || shifts.length === 0) {
      return (
        <Text style={this.props.styles.errorMessage}>
          {UIText.shiftMakerNoShiftsToShow}
        </Text>
      );
    }

    shifts = this.controller.sortShifts(shifts);

    return shifts.map(shift => {
      const clockState = getTopicCompletionState(shift);
      const couldClockInOut = this.controller.getShiftClockCapability(shift);
      const clockers = getTopicAssignedMembers(shift);

      const shiftCard = (
        <ShiftCard
          shift={shift}
          clockState={clockState}
          clockers={clockers}
          couldClockInOut={couldClockInOut}
          onClockPress={() => this.controller.handleClockInClockOut(shift)}
          onPress={e => this.controller.handleShiftInstancePress(shift)}
          timezone={this.controller.timezone}
        />
      );

      return (
        <Observer key={shift.id}>
          {() => (
            <View style={this.props.styles.card}>
              {Platform.OS === "web" ? (
                <VizSensor
                  partialVisibility
                  onChange={visible =>
                    visible && this.controller.checkCompleteShiftData(shift)
                  }
                >
                  {shiftCard}
                </VizSensor>
              ) : (
                shiftCard
              )}
            </View>
          )}
        </Observer>
      );
    });
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        <View style={this.props.styles.heading}>
          <View style={this.props.styles.headingIcons}>
            <Tooltip
              title={capitalize(UIText.shiftMaker)}
              content={UIText.shiftMakerHelp(
                this.controller.group["groupTypeName"]
              )}
              showHelpCallback={this.controller.showHelpCallback}
            />
          </View>
          <View
            style={{
              ...this.props.styles.pickerLabelContainer,
              borderBottomWidth: 1,
              borderColor: theme.color
            }}
          >
            <CircleCharIcon
              onPress={this.controller.showHelp}
              outline={true}
              value={1}
              size={20}
            />
            <Text
              onPress={this.controller.showHelp}
              style={this.props.styles.pickerLabel}
            >
              {UIText.shiftMakerShiftTemplatePicker}
            </Text>
          </View>
          <View style={this.props.styles.pickerContainer}>
            <Picker
              selectedValue={this.controller.currentShiftTemplateId}
              style={this.props.styles.picker}
              onValueChange={this.controller.handleShiftTemplateChange}
            >
              {this.controller.shiftTemplateListItems.map((list, i) => (
                <Picker.Item key={i} label={list.label} value={list.value} />
              ))}
            </Picker>
            <ThemedButton
              style={{ marginRight: 0 }}
              width={95}
              // height={30}
              icon="playlist-play"
              title="Actions"
              loading={this.controller.loading || this.controller.editLoading}
              onPress={this.controller.editShiftTemplate}
            />
            {/*<ThemedButton*/}
            {/*style={{ marginRight: 0 }}*/}
            {/*width={45}*/}
            {/*height={30}*/}
            {/*icon={*/}
            {/*this.controller.currentShiftTemplateActive*/}
            {/*? "pause"*/}
            {/*: "play-arrow"*/}
            {/*}*/}
            {/*loading={*/}
            {/*this.controller.loading ||*/}
            {/*this.controller.shiftTemplateActivating*/}
            {/*}*/}
            {/*loadingProps={{ color: "#fff" }}*/}
            {/*onPress={() => this.controller.setShiftTemplateActive()}*/}
            {/*/>*/}
          </View>
        </View>
        <TabHeader
          tabs={this.controller.topTabs}
          currentKey={this.controller.currentTab}
        />
        <ScrollView
          ref={ref => (this.scrollView = ref)}
          scrollEventThrottle={100}
          onScroll={this.controller.onScroll}
          contentContainerStyle={this.props.styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={
                this.controller.loading ||
                this.controller.editLoading ||
                this.controller.taskTemplateLoading ||
                this.controller.shiftInstanceLoading ||
                this.controller.shiftTemplateActivating
              }
              onRefresh={this.controller.onRefresh}
            />
          }
        >
          {this.controller.errorMessage ? (
            <View style={this.props.styles.scrollViewContent}>
              <Text style={this.props.styles.errorMessage}>
                {this.controller.errorMessage}
              </Text>
            </View>
          ) : (
            this.renderComponent(this.controller.currentTab)
          )}
        </ScrollView>
      </View>
    );
  }
}

export { GroupShiftPageView };
