import { apiController } from "../../../cdm/controllers/api-controller";
import { clientController } from "../../../cdm/controllers/client-controller";
import { stateController } from "../../../cdm/controllers/state-controller";
import { action, autorun, computed, observable, toJS } from "mobx";
import { apiService } from "../../../cdm/services/api-service";
import { formService } from "../../../cdm/services/form-service";
import {
  capitalize,
  evalStringBoolean,
  isEmpty,
  isModified
} from "../../../utils/helpers";
import { UIText } from "../../../config/lang-config";
import { getProfileRenderFlags } from "../../../custom/mcb/config/flags_calculator";
import { isSnSOrAdmin } from "../../../custom/mcb/utils/helper";
import { groupTypeIds, typeClassIds } from "../../../config/variable-config";
import { fileService } from "../../../cdm/services/file-service";
import {
  isServiceGroup,
  isServiceProviderGroup
} from "../../../custom/mcb/lib/group-utilities-mcb";
import { typeClassLibrary } from "../../../cdm/lib/typeClass-utilities";
import { computedFn } from "mobx-utils";
import { mcbEndpointConfig } from "../../../custom/mcb/config/api-config";

export class ProfileController {
  @observable loaded = false;
  @observable editing = false;
  @observable saving = false;
  @observable intermediateLoading = false;

  @observable scrollPos;

  @observable typeClassId = 0;
  @observable profile = [];
  profileCopy = {};

  scrolledToField = false;

  @observable adminCentreType;
  @observable isAdmin;

  @computed get user() {
    return clientController.client.user || {};
  }
  @computed get screenId() {
    return this.props.navigation.getParam("screenId");
  }
  @computed get topButton() {
    const button = stateController.viewProfileState.topButton[this.screenId];
    return !isEmpty(button) && button;
  }
  @computed get bottomButton() {
    const button = stateController.viewProfileState.bottomButton[this.screenId];
    return !isEmpty(button) && button;
  }
  @computed get titleOverride() {
    return stateController.viewProfileState.titleOverride[this.screenId];
  }
  @computed get backButtonOverride() {
    const override =
      stateController.viewProfileState.backButtonOverride[this.screenId];
    return !isEmpty(override) && override;
  }
  @computed get rightButtonOverride() {
    const override =
      stateController.viewProfileState.rightButtonOverride[this.screenId];
    return !isEmpty(override) && override;
  }
  @computed get additionalForm() {
    return stateController.viewProfileState.additionalForm[this.screenId] || {};
  }
  @computed get fieldToScrollAt() {
    return stateController.viewProfileState.fieldToScrollAt[this.screenId];
  }
  @computed get loading() {
    return !this.loaded || this.saving;
  }

  // ==
  @computed get profileId() {
    return Number(this.props.navigation.getParam("profile"));
  }
  @computed get profileData() {
    return formService.disassembleFormData(this.profile, {
      displayName: true
      // requiredHourlyRate: true
    });
  }
  @computed get renderFlags() {
    if (
      Array.isArray(
        stateController.viewProfileState.renderFlags[this.screenId]
      ) &&
      stateController.viewProfileState.renderFlags[this.screenId].length > 0
    )
      return stateController.viewProfileState.renderFlags[this.screenId];

    const roleList = this.selfMember.roleList || [];
    const typeRoleId = roleList[0] ? roleList[0].groupTypeRoleId : 0;
    return getProfileRenderFlags(this.typeClassId, typeRoleId);
  }
  @computed get editFlags() {
    return (
      Array.isArray(
        stateController.viewProfileState.editFlags[this.screenId]
      ) &&
      stateController.viewProfileState.editFlags[this.screenId].length > 0 &&
      stateController.viewProfileState.editFlags[this.screenId]
    );
  }

  // ==
  @computed get defaultGroupId() {
    return (this.user.defaultMember || {}).groupId;
  }
  @computed get currentGroup() {
    return (
      clientController.findGroupById(
        stateController.viewGroupId || this.defaultGroupId
      ) || {}
    );
    // TODO: Intelligently detect self group member.
  }
  @computed get currentGroupMembers() {
    return this.currentGroup.members || [];
  }
  @computed get selfMember() {
    return (
      this.currentGroupMembers.find(
        m => m.userId === clientController.userId
      ) || {}
    );
  }

  @computed get member() {
    const members = clientController.findMembers(
      m => m.profileId === this.profileId,
      "group" // Limit search scope to group members so that topic member profiles are read only.
    );
    return !!members[0] && members[0];
  }
  @computed get group() {
    const groups = clientController.findGroups(
      group => group.profileId === this.profileId
    );
    return !!groups[0] && groups[0];
  }
  @computed get entity() {
    return this.group || this.member;
  }

  // ==
  @computed get readOnly() {
    return (
      (isEmpty(this.entity) ||
        stateController.viewProfileState.readOnly[this.screenId]) &&
      !(
        isSnSOrAdmin(this.user) &&
        Array.isArray(this.editFlags) &&
        this.editFlags.includes("mcb")
      ) // Admin flag enables edit.
    );
  }
  @computed get nonEditModeHideNoValue() {
    return stateController.viewProfileState.nonEditModeHideNoValue[
      this.screenId
    ];
  }
  @computed get allowEmailEdit() {
    return stateController.viewProfileState.allowEmailEdit[this.screenId];
  }

  @computed get isDefaultGroup() {
    return this.defaultGroupId === this.group.id;
  }

  @computed get showDisplayName() {
    return isServiceProviderGroup(this.group);
  }

  @computed get additionalProfileProcessors() {
    return (
      stateController.viewProfileState.additionalProfileProcessors[
        this.screenId
      ] || []
    );
  }
  @computed get additionalProfileValidators() {
    return (
      stateController.viewProfileState.additionalProfileValidators[
        this.screenId
      ] || []
    );
  }
  @computed get profilePostSaveProcessors() {
    return (
      stateController.viewProfileState.profilePostSaveProcessors[
        this.screenId
      ] || []
    );
  }

  @computed get fieldBlurHandlers() {
    return (
      stateController.viewProfileState.fieldBlurHandlers[this.screenId] || []
    );
  }

  @computed get screenStates() {
    return {
      // Add screen states for processors to consume.
      loaded: this.loaded,
      loading: this.loading,
      saving: this.saving,
      editing: this.editing,
      intermediateLoading: this.intermediateLoading,
      scrollPos: this.scrollPos
    };
  }

  constructor(props) {
    this.props = props;
    setTimeout(this._initialize);
    autorun(
      () => (stateController.viewProfileState.screenStates = this.screenStates)
    );
  }

  componentWillUnmount() {
    stateController.clearProfileState(this.screenId);
  }

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.profileSaving,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true,
      contentAlign: err.contentAlign
    });
  };

  _initialize = async () => {
    stateController.currentScreen = "Profile";
    stateController.viewProfileState.refresh = () => this.onRefresh();
    stateController.viewProfileState.getProfile = () => toJS(this.profile);
    stateController.viewProfileState.getProfileCopy = () => this.profileCopy;
    stateController.viewProfileState.setField = (fieldName, value) =>
      this.setFieldByName(fieldName, value);

    return this._loadProfile()
      .then(this._loadForm)
      .finally(() => {
        this.adminCentreType = Number(this.props.navigation.getParam("type"));
        this.isAdmin =
          evalStringBoolean(this.props.navigation.getParam("admin")) &&
          !!Object.keys(groupTypeIds).some(
            key => groupTypeIds[key] === this.adminCentreType
          );
        if (this.renderFlags.length === 0 || this.profile.length === 0) {
          if (this.isAdmin) {
            const isServiceProvider = isServiceProviderGroup({
              typeId: this.adminCentreType
            });
            return this.props.navigation.navigate("AdminCentre", {
              type: isServiceProvider ? "providers" : this.adminCentreType,
              profile: this.profileId,
              ...(isServiceProvider && { serviceType: this.adminCentreType })
            });
          }
          return this.handleBackPress();
        }
        if (this.typeClassId === typeClassIds.personalProfile) {
          if (this.member.userId !== clientController.userId)
            return this.handleBackPress();
        }
        this.setGroupTypeFieldHide();
        this.execAdditionalProcessors();
        this.detectAutoEdit();
        this.profileCopy = toJS(this.profile);
        this.loaded = true;
      });
  };

  _loadProfile = async force => {
    await apiController
      .getProfileById(this.profileId)
      .then(profile => {
        this.profile = profile;
        return profile;
      })
      .then(clientController.updateProfile);

    return toJS(this.profile);
  };

  _loadForm = async profile => {
    const loadForm = async form => {
      this.profile = formService.assembleFormData(form, profile.data, {
        override: true
      });

      return Promise.resolve();
    };

    this.typeClassId = profile.typeClassId;
    return formService
      .findFormClassByIdAsync(
        (this.typeClassId || this.typeClassId === 0) &&
          this.typeClassId.toString()
      )
      .then(loadForm);
  };

  _reloadProfile = async quiet => {
    if (!quiet) this.loaded = false;
    let expandMap;
    if (!isEmpty(this.profile)) {
      expandMap = toJS(this.profile.map(f => f.expanded));
    }
    if (!quiet) this.profile = [];
    return this._initialize()
      .then(() =>
        this.profile.forEach((field, i) => (field.expanded = expandMap[i]))
      )
      .then(() => {
        this.scrollTo({ y: this.scrollPos || 0, animated: false });
      });
  };

  detectAutoEdit = () => {
    if (stateController.viewProfileState.autoEdit) {
      !this.editing && this.edit();
      stateController.viewProfileState.autoEdit = false;
    }
  };

  setGroupTypeFieldHide = () => {
    autorun(
      () =>
        !this.loading &&
        !this.intermediateLoading &&
        typeClassLibrary.autoSelectEnglish(this.profile)
    );
    // autorun(
    //   () =>
    //     !this.loading &&
    //     !this.intermediateLoading &&
    //     typeClassLibrary.autoHideEditAvailabilityButton(
    //       this.profile,
    //       this.currentGroup.typeId
    //     )
    // );
    autorun(
      () =>
        !this.loading &&
        !this.intermediateLoading &&
        typeClassLibrary.autoHideOldIntroductionField(this.profile)
    );
    autorun(
      () =>
        !this.loading &&
        !this.intermediateLoading &&
        typeClassLibrary.caregiverNicknameModifier(
          this.profile,
          this.typeClassId,
          this.currentGroup.typeId || this.adminCentreType
        )
    );
    autorun(
      () =>
        !this.loading &&
        !this.intermediateLoading &&
        typeClassLibrary.shouldDisableDefaultProfileNameEditing(
          this.profile,
          this.typeClassId
        )
    );

    if (this.isAdmin) {
      return formService.hideFieldsByGroupTypeId(
        this.profile,
        this.adminCentreType
      );
    }
    if (
      !isServiceGroup(this.currentGroup) ||
      this.profileId !== this.currentGroup.profileId
    )
      return;

    // Additional condition for those who has customRoleDescription and viewing Care Circle profile
    if (this.currentGroup.typeId === groupTypeIds.careReceiver) {
      const customRoleDescription = ((this.selfMember.profile || {}).data || {})
        .customRoleDescription;
      if (customRoleDescription && customRoleDescription !== "Paid Caregiver") {
        formService.hideFieldsByGroupTypeId(
          this.profile,
          groupTypeIds.household
        );
        return;
      }
    }

    if (isServiceProviderGroup(this.currentGroup)) {
      autorun(
        () =>
          !this.loading &&
          !this.intermediateLoading &&
          typeClassLibrary.providerFieldMandatoryAssist(
            this.profile,
            this.currentGroup.typeId
          )
      );
    }

    formService.hideFieldsByGroupTypeId(this.profile, this.currentGroup.typeId);
  };

  execAdditionalProcessors = async () => {
    for (const method of this.additionalProfileProcessors) {
      method(this.profile, this.screenStates);
    }
  };

  execPostSaveProcessors = async () => {
    for (const method of this.profilePostSaveProcessors) {
      method(this.profile);
    }
  };

  handleBackPress = event => {
    if (this.backButtonOverride.handlePress) {
      return this.backButtonOverride.handlePress(event);
    }

    if (!stateController.viewGroupId) {
      stateController.viewGroupId = this.group.id || this.member.groupId || 0;
    }
    this.props.navigation.navigate("Group", {
      group: stateController.viewGroupId,
      editProfile: undefined
    });
    return true;
  };

  handleEmailChange = () => {};

  edit = () => {
    const onEdit = async () => {
      // Still loading! Slow down!!!
      if (this.loading) return;
      if (this.intermediateLoading) return;
      if (this.profile.length === 0) return;

      // No permission
      if (this.readOnly) return;

      if (!this.editing) {
        this.intermediateLoading = true;
        await this._reloadProfile(true);
        this.intermediateLoading = false;
        this.profileCopy = toJS(this.profile);
        return (this.editing = !this.editing);
      }

      try {
        typeClassLibrary.mandatoryAbleMunicipalityValidate(
          this.profile,
          this.currentGroup.typeId === groupTypeIds.caregiver || this.isAdmin
        );
      } catch (e) {
        return this._showError(e);
      }

      return this.validate(this.profile)
        .then(([pass, errorFields]) => {
          if (!pass) {
            !isEmpty(errorFields) &&
              this.scrollToField &&
              this.scrollToField(errorFields[0].name);
            const errorFieldMessages = errorFields[0].errorMessage;
            return Promise.reject({
              message: UIText.checkEntriesWithFields(
                errorFields,
                errorFieldMessages
              ),
              contentAlign: "left"
            });
          }
          return isModified(toJS(this.profile), this.profileCopy, "value");
        })
        .then(this.saveProfile)
        .then(() => (this.editing = !this.editing))
        .catch(this._showError);
    };
    setTimeout(onEdit);
  };

  cancelEdit = () => {
    this.editing = false;
    for (const field of this.profile) {
      if (field.type === "custom") continue;
      const originalField = this.profileCopy.find(f => f.name === field.name);
      if (!originalField) continue;
      field.value = originalField.value;
    }
    // this.profile = this.profileCopy;
    return true;
  };

  saveProfile = async modified => {
    if (!modified) return;
    this.saving = true;

    const data = JSON.stringify(this.profileData);

    return apiService
      .async("PATCH", {
        endpoint: mcbEndpointConfig.update_profile_with_audit(this.profileId),
        data: { data }
      })
      .then(this._reloadProfile)
      .then(fileService.resetDefaultAvatarChecks)
      .then(this.execPostSaveProcessors)
      .finally(() => (this.saving = false));
  };

  validate = async form => {
    const isAdminEditing = (this.editFlags || []).includes("mcb");
    const pass = formService.validateRequired(form);
    const additionalPass =
      isEmpty(this.additionalProfileValidators) ||
      this.additionalProfileValidators.every(validate => validate(form));
    if ((!pass || !additionalPass) && !isAdminEditing) {
      const errorFields = form.filter(f => !!f.errorMessage);
      return [false, errorFields];
    }
    return [form];
  };

  getAvatarTitle = formService.getAvatarTitle;

  getAvatarColorId = computedFn(form =>
    formService.getAvatarColorId(form, this.profileId)
  );

  onAvatarPickerChange = action((file, field) =>
    formService.onAvatarPickerChange(
      file,
      field,
      this.selfMember.id,
      capitalize(UIText.profile)
    )
  );

  onFieldBlur = action((form, field) => {
    for (const method of this.fieldBlurHandlers) {
      method(form, field);
    }
  });

  onRefresh = () => {
    return this._reloadProfile();
  };

  onScroll = event => {
    this.scrollPos = event.nativeEvent.contentOffset.y;
  };

  checkScrollToField = () => {
    if (this.fieldToScrollAt && !this.scrolledToField) {
      this.scrollToField && this.scrollToField(this.fieldToScrollAt);
      this.scrolledToField = true;
    }
  };

  setScrollPosCallback = method => (this.scrollTo = method);

  setScrollToFieldCallback = method => (this.scrollToField = method);

  helpFocusNextField = (field, formFields, form) =>
    formService.helpFocusNextField(field, formFields, form);

  setFieldByName = action((fieldName, value) => {
    const field = (this.profile || []).find(f => f.name === fieldName);
    if (isEmpty(field)) return;
    return (field.value = value);
  });
}
