import { clientController } from "../../../../../../cdm/controllers/client-controller";
import { cleanerSetup } from "./setup";
import { stateController } from "../../../../../../cdm/controllers/state-controller";

export const cleanerGroup = isFirstTime => ({
  preSetup: clientController.getUserGroupsAndMembers,
  rootBack: {
    icon: isFirstTime ? "exit-to-app" : null,
    onPress: isFirstTime
      ? () => clientController.execLogout(true, true)
      : // ? props => {
        //     stateController.viewGroupId = -1;
        //     props.navigation.navigate("Root");
        //   }
        props => {
          stateController.initSetupMode = "addGroup";
          props.resetSetup();
        }
  },
  skipSelect: true,
  initialTitle: "Cleaning Service Profile Setup",
  initialHeading: `${
    clientController.defaultMember.profile.data.displayName
  }, let's setup your Cleaning Service profile.`,
  finishTitle: "Setup Complete",
  setupList: [
    {
      ...cleanerSetup()
    }
  ],
  onFinishSetup: props => {
    isFirstTime
      ? props.navigation.navigate("Root")
      : (() => {
          const groups = clientController.findVisibleGroups();
          if (groups.length > 0)
            stateController.viewGroupId = groups.sort(
              (a, b) => b.id - a.id
            )[0].id;
          props.navigation.navigate("Group");
        })();
  },
  finishHeading: isFirstTime && "Thank you for completing the initial setup."
});
