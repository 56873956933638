import { styles } from "../styles";
import { theme } from "../../../config/style-configs/theme";

export const extraStyles = {
  starRatings: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingVertical: 20
  },
  starRatingDescription: {
    width: "100%",
    textAlign: "center",
    fontSize: theme.FONT_SIZE_MEDIUM,
    color: theme.generalNormalColor
  }
};

export const ratingReviewStyles = extraStyles;

export { styles };
