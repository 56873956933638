import { stateController } from "../../../../cdm/controllers/state-controller";
import NavigationService from "../../../../utils/navigation-service";
import { action, autorun, computed, observable, reaction, toJS } from "mobx";
import { clientController } from "../../../../cdm/controllers/client-controller";
import {
  groupTypeIds,
  groupTypeRoleIds,
  planMarketingNumber,
  planMarketingUrl,
  serviceProviderGroupTypeIds,
  topicTypeIds,
  typeClassIds
} from "../../../../config/variable-config";
import { apiService } from "../../../../cdm/services/api-service";
import { endpointConfig } from "../../../../config/api-config";
import { apiController } from "../../../../cdm/controllers/api-controller";
import {
  abbvLastNameDisplayNameEng,
  arrayFlat,
  capitalize,
  evalStringBoolean,
  getDisplayNameEng,
  isEmpty,
  isModified,
  preventDefaultStopProp,
  randomString,
  safeParseJSON,
  whenFulfill
} from "../../../../utils/helpers";
import { formService } from "../../../../cdm/services/form-service";
import { UIText } from "../../../../config/lang-config";
import {
  getProfileRenderFlags,
  getTopicSummaryEditFlags
} from "../../config/flags_calculator";
import {
  deleteTopic,
  editTopicDescription,
  editTopicMenu
} from "../../../../cdm/lib/topic-utilities";
import {
  addBookingLinkToProfile,
  addRatingToProfile,
  getRatingAggregatedScoreAndSamples,
  isGroupSubscriptionMsgEnabled
} from "../../lib/group-utilities-mcb";
import { filterController } from "../../../../cdm/controllers/filter-controller";
import { mcbEndpointConfig } from "../../config/api-config";
import { Linking, Platform } from "react-native";
import { fileService } from "../../../../cdm/services/file-service";
import { handleGroupBottomTabChange } from "../../../../cdm/lib/group-utilities";
import { isSnSOrAdmin } from "../../utils/helper";
import { responsive } from "../../../../config/style-configs/responsive";
import { typeClassLibrary } from "../../../../cdm/lib/typeClass-utilities";
import { embeddedService } from "../../../../cdm/services/embedded-service";
import {
  adminCentreGetNewOnboardingData,
  adminCentreGetOnboardingTopics,
  adminCentreInjectLegacyRecruitForm,
  adminCentreInjectNewRecruitForm,
  adminCentrePrepProfileScreen
} from "../../lib/admin-centre-utilities";
import { computedFn } from "mobx-utils";

export class GroupMarketPageController {
  // Prevent checkbox clicking while loading
  @observable loading = true;
  // Loading for tab page insert only.
  @observable searching = false;
  // Loading for criteria profile.
  @observable profileLoading = false;
  // Loading for edit shortlist button.
  @observable editLoading = false;
  // Loading for all card checkboxes.
  @observable checkboxLoading = false;
  // Loading for add all to shortlist button.
  @observable addingAllToShortlist = false;
  // Loading for rating review
  @observable ratingLoadingUserIds = [];
  // Loading for caregiver achievements
  @observable achievementLoadingGroupIds = [];

  // For network error.
  @observable errorMessage = "";
  retryThreshold = 3;
  retryCount = 0;

  // Limit shortlist creation loop.
  createSLTry = 0;
  createSLThreshold = 3;

  // Cards
  @observable caregiverGroups = [];
  @observable candidateGroups = [];
  candidateGroupsDebounce;

  // Ratings
  @observable aggregatedRatings = [];
  ratingLoadingDebounce;

  // Achievements
  @observable achievements = [];
  achievementLoadingDebounce;

  @observable visibleGroupIds = {};

  // Default to "New Shortlist" option.
  newOption = {
    value: "new",
    label: `<${UIText.topicNewTopic(UIText.marketShortlist)}>`
  };

  // Monitor top tab changes
  defaultTab = "criteria";
  prevTab;

  get topTabs() {
    return [
      {
        key: "criteria",
        name: UIText.marketTabCriteria,
        help: UIText.marketHelpCriteria(this.caregiverGroupType.typeName)
      },
      {
        key: "search",
        name: UIText.marketTabSearch
      },
      !this.isVisitor && {
        key: "candidates",
        name: UIText.marketTabCandidates
      }
    ]
      .filter(Boolean)
      .map(t => ({
        ...t,
        onTabPress: this.handleTabChange
      }));
  }

  // isScratchpad
  @computed get isScratchpad() {
    return (
      !!this.group.groupName && this.group.groupName.match(/{scratchpad}/g)
    );
  }
  // isVisitor user
  @computed get isVisitor() {
    return clientController.isVisitor;
  }
  // isMCBGroup Marketplace
  @computed get isMCBGroup() {
    return this.group.typeId === groupTypeIds.myCareBaseStaff;
  }

  // Top tab.
  @computed get currentTab() {
    if (!stateController.viewGroupState.topTabs["market"][this.group.id])
      stateController.viewGroupState.topTabs["market"][
        this.group.id
      ] = this.defaultTab;
    if (!this.prevTab) this.prevTab = this.defaultTab;
    return stateController.viewGroupState.topTabs["market"][this.group.id];
  }
  // Control scrolling memory
  @computed get scrollPos() {
    if (!stateController.viewGroupState.marketScrollPos[this.group.id])
      stateController.viewGroupState.marketScrollPos[this.group.id] = {};
    return stateController.viewGroupState.marketScrollPos[this.group.id];
  }
  // Current id of shortlist topic, id is the key of dropdown items.
  // @observable currentShortlistId = 0;
  @computed get currentShortlistId() {
    return stateController.viewGroupState.marketShortlistId[this.group.id];
  }
  // Disable reset and save if form is clean.
  @computed get criteriaDirty() {
    return stateController.viewGroupState.marketCriteriaDirty[this.group.id];
  }
  @computed get criteriaSimplified() {
    if (
      typeof stateController.viewGroupState.marketCriteriaSimplified[
        this.group.id
      ] !== "boolean" &&
      this.isScratchpad
    )
      stateController.viewGroupState.marketCriteriaSimplified[
        this.group.id
      ] = true;

    return stateController.viewGroupState.marketCriteriaSimplified[
      this.group.id
    ];
  }
  @computed get profile() {
    return stateController.viewGroupState.marketCriteria[this.group.id] || [];
  }
  @computed get shouldRenderProfile() {
    const renderFlags = getProfileRenderFlags(
      typeClassIds.careReceiverProfile,
      groupTypeRoleIds.caregiver
    ); // search === true
    return (
      (Array.isArray(this.profile) &&
        this.profile.filter(
          f =>
            renderFlags.find(flag => f.flags && f.flags[flag]) &&
            f.type !== "collapsibleAnchor"
        )) ||
      []
    );
  }
  @computed get simplifiedRenderProfile() {
    return this.shouldRenderProfile.filter(
      f =>
        f.name === "provinces" ||
        f.name === "territories" ||
        f.name === "municipalities" ||
        f.name === "timezone"
    );
  }
  @computed get profileData() {
    return formService.disassembleFormData(this.profile, { displayName: true });
  }
  @computed get shouldRenderProfileData() {
    return formService.disassembleFormData(this.shouldRenderProfile, {
      displayName: true
    });
  }
  @computed get simplifiedRenderProfileData() {
    return formService.disassembleFormData(this.simplifiedRenderProfile, {
      displayName: true
    });
  }
  @computed get profileJSONData() {
    return JSON.stringify(this.profileData);
  }
  @computed get shouldRenderProfileJSONData() {
    return JSON.stringify(this.shouldRenderProfileData);
  }
  @computed get simplifiedRenderProfileJSONData() {
    return JSON.stringify(this.simplifiedRenderProfileData);
  }

  // Show or hide mandatory criteria toggles.
  @computed get hideMandatoryCriteriaToggles() {
    return stateController.viewGroupState.marketHideMandatoryToggles[
      this.group.id
    ];
  }
  @computed get mandatoryCriteriaFieldNames() {
    if (!stateController.viewGroupState.marketMandatoryCriteria[this.group.id])
      stateController.viewGroupState.marketMandatoryCriteria[
        this.group.id
      ] = {};
    return stateController.viewGroupState.marketMandatoryCriteria[
      this.group.id
    ];
  }
  @computed get mandatoryCriteriaFields() {
    return this.shouldRenderProfile.filter(field =>
      this.fieldIsMandatory(field)
    );
  }

  // Current user.
  @computed get user() {
    return clientController.client.user;
  }
  // Current view group.
  @computed get group() {
    return clientController.findGroupById(stateController.viewGroupId);
  }
  //
  // Member myself in current group.
  @computed get member() {
    return (
      this.group.members.find(m => m.userId === clientController.userId) || {}
    );
  }
  @computed get selfRoles() {
    return this.member.roleList || [];
  }
  @computed get selfActors() {
    return this.currentShortlistTopic.actors.filter(a =>
      a.memberIdList.includes(this.member.id)
    );
  }
  @computed get summaryEditFlags() {
    let flags = [];
    for (let actor of this.selfActors) {
      for (let role of this.selfRoles) {
        flags.push(
          ...getTopicSummaryEditFlags(
            this.currentShortlistTopic,
            actor.groupTypeActorId,
            role.groupTypeRoleId,
            this.group
          )
        );
      }
    }
    return flags.filter(Boolean);
  }

  @computed get shortlistTopics() {
    return clientController.findTopics(
      t =>
        t.typeId === topicTypeIds.shortlist &&
        t["groupIdList"].includes(this.group.id)
    );
  }
  @computed get shortlistItems() {
    const topics = this.shortlistTopics;

    return [
      this.newOption,
      ...topics.map(topic => ({
        value: topic.id,
        label: topic.description
      }))
    ];
  }
  // Current Shortlist topic.
  @computed get currentShortlistTopic() {
    return clientController.findTopicById(this.currentShortlistId);
  }
  // Current filtered search result groups.
  @computed get searchMandatoryOnly() {
    return stateController.viewGroupState.marketSearchMandatory[this.group.id];
  }
  @computed get mandatoryCaregiverGroups() {
    return this.caregiverGroups.filter(c => {
      const profile = c.profile && c.profile.data;
      return !this.mandatoryCriteriaFields.some(mc => {
        const match =
          mc.match ||
          (mc.options &&
            (() => {
              const option = mc.options.find(o => o.name === mc.value);
              return option && option.match;
            })());
        return (
          match &&
          match.some(m => profile[m.key] !== evalStringBoolean(m.value))
        );
      });
    });
  }
  @computed get filteredCaregiverGroups() {
    let template = this.searchMandatoryOnly
      ? this.mandatoryCaregiverGroups
      : this.caregiverGroups;
    return filterController.applyFilterCategories(
      template,
      this.caregiverFilterCategories
    );
  }
  // Current Shortlist candidate topics
  @computed get currentCandidateTopics() {
    return clientController.findSubTopicByParentId(this.currentShortlistId);
  }
  @computed get currentFilteredCandidateTopics() {
    let template = this.currentCandidateTopics;
    return filterController.applyFilterCategories(
      template,
      this.candidateFilterCategories
    );
  }
  // All candidate topics across all shortlists
  @computed get candidateTopics() {
    return clientController.findTopics(
      t =>
        t.typeId === topicTypeIds.candidate &&
        t["groupIdList"].includes(this.group.id)
    );
  }
  // Aggregated caregiver groups pool
  @computed get combinedCaregiverGroups() {
    return [...this.candidateGroups, ...this.caregiverGroups].filter(Boolean);
  }
  @computed get combinedCaregiverGroupIds() {
    return Array.from(
      new Set(this.combinedCaregiverGroups.map(group => group.id))
    );
  }
  @computed get isSubscriptionMsgEnabled() {
    return isGroupSubscriptionMsgEnabled(this.group);
  }

  // GroupType data.
  @computed get careReceiverGroupType() {
    return clientController.findGroupTypeById(groupTypeIds.careReceiver);
  }
  @computed get caregiverGroupType() {
    return clientController.findGroupTypeById(groupTypeIds.caregiver);
  }

  // Visitor TNC Banner
  @computed get tncBannerVisible() {
    return clientController.client.visitorTncBannerVisible;
  }

  // Admin broadcast dialog input text
  @computed get adminBroadcastMsg() {
    return stateController.viewGroupState.marketAdminBroadcastMsg[
      this.group.id
    ];
  }

  // Filter functions
  @observable caregiverFilterCategories = [
    {
      name: "In shortlist",
      classifier: g =>
        this.getCandidateChecked(g) ? UIText.generalYes : UIText.generalNo,
      alwaysShownCriteria: [UIText.generalYes, UIText.generalNo]
    },
    {
      name: "Active/Archived",
      classifier: g => {
        return this.getCandidateChecked(g)
          ? this.currentCandidateTopics.find(t =>
              (t.groupIdList || []).includes(g.id)
            ).isCompleted
            ? "Archived"
            : "Active"
          : "Active";
      },
      alwaysShownCriteria: ["Active", "Archived"],
      default: "Active"
    },
    isSnSOrAdmin(this.user) && {
      name: "Caregiver approval",
      classifier: g => {
        const profile = (g.profile && g.profile.data) || {};
        return profile["approvalOfCandidateConfirmedByReference"]
          ? UIText.adminCentreCardCaregiverApproved(true)
          : UIText.adminCentreCardCaregiverApproved(false);
      }
      // default: UIText.adminCentreCardCaregiverApproved(true)
    }
  ].filter(Boolean);
  setCaregiverFilter = filterCategories =>
    filterController.filterSetter(
      this.caregiverFilterCategories,
      filterCategories
    );

  @observable candidateFilterCategories = [
    {
      name: "Active/Archived",
      classifier: t => (t.isCompleted ? "Archived" : "Active"),
      alwaysShownCriteria: ["Active", "Archived"],
      default: "Active"
    }
  ];
  setCandidateFilter = filterCategories =>
    filterController.filterSetter(
      this.candidateFilterCategories,
      filterCategories
    );

  constructor(props) {
    this.props = props;
    this.handleBillingPress = props.handleBillingPress;

    this.disposers = [
      autorun(() => this._getShortlistTopicAuto()),
      autorun(() => this._getCandidatesTopicsAuto()),
      autorun(() => this._getCandidatesGroupsAuto()),
      // reaction(
      //   () => this.visibleGroupIds,
      //   () => this._getRatingReviewAuto()
      // ),
      reaction(() => this.currentTab, this.onTopTabChange),
      reaction(() => this.profileData, this._autoSelectEnglish)
    ];

    this._detectAutoTab();
    this._registerEmbeddedListeners();

    setTimeout(() =>
      this._loadMarketplace()
        .then(
          () =>
            this.isVisitor &&
            typeof this.tncBannerVisible !== "boolean" &&
            setTimeout(clientController.showVisitorTncBanner)
        )
        .then(() => {
          if (
            this.shortlistTopics.length > 0 &&
            isEmpty(this.currentShortlistTopic)
          ) {
            this.handleShortlistChange(this.shortlistTopics[0].id);
          }
        })
    );
  }

  componentWillUnmount() {
    Array.isArray(this.disposers) &&
      this.disposers.map(disposer => disposer && disposer());
  }

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.marketplace,
      content:
        (err.response &&
          (err.response.status === 304
            ? UIText.marketTopicRemoveDeny
            : JSON.stringify(err.response.data).replace(/"/g, ""))) ||
        err.message,
      leftButtonText: UIText.generalConfirm
    });
  };

  _isLoading = () => {
    return setTimeout(() => (this.loading = true), 0);
  };

  _doneLoading = () => {
    return setTimeout(() => (this.loading = false), 0);
  };

  _detectAutoTab = () => {
    const tabs = this.topTabs.map(tab => tab && tab.key).filter(Boolean);
    const tab = this.props["autoTab"];
    tab &&
      tabs.includes(tab) &&
      this.handleTabChange({ key: tab }) &&
      this.props.clearAutoTabs &&
      this.props.clearAutoTabs("market");
  };

  _loadMarketplace = async () => {
    this._isLoading();
    return (
      this._getShortlists()
        .then(this._reloadProfile)
        .then(this._getCaregivers)
        // .then(this._getCandidatesGroups)
        .then(this._doneLoading)
        .catch(this._retry)
    );
  };

  _retry = err => {
    if (this.retryCount > this.retryThreshold) {
      this._doneLoading();
      return (this.errorMessage = JSON.stringify(
        (err && err.response && err.response.data) || err.message
      ));
    }

    this.retryCount++;
    return this._loadMarketplace();
  };

  _autoSelectEnglish = (value, reaction) =>
    typeClassLibrary.autoSelectEnglish(this.shouldRenderProfile);

  _getShortlists = async () => {
    return apiController
      .getGroupTopicsByTypeId(this.group.id, topicTypeIds.shortlist)
      .then(topics => {
        for (let topic of topics) {
          clientController.updateTopic(topic);
        }

        if (this.shortlistTopics.length === 0) {
          this.createSLTry++;
          return this.createShortlist();
        } else {
          this.createSLTry = 0;
        }

        return Promise.resolve();
      });
  };

  _reloadProfile = async () => {
    return isModified(this.profileData, toJS(this.group.profile.data)).then(
      async modified => {
        if (modified && !this.criteriaDirty)
          return await this._loadProfile(true);
        return Promise.resolve();
      }
    );
  };

  _loadProfile = async noApi => {
    this.profileLoading = true;

    !noApi &&
      (await apiController
        .getProfileById(this.group.profileId)
        .then(clientController.updateProfile));

    let profile = toJS(this.group.profile);
    return formService
      .findFormClassByIdAsync(profile.typeClassId)
      .then(form => {
        profile = formService.assembleFormData(form, profile.data, {
          override: true
        });
        if (!this.isMCBGroup) {
          formService.hideFieldsByGroupTypeId(profile, this.group.typeId);
        } else {
          this.restoreTerritorialFieldsForMCBGroup(profile);
        }
        this.sendEmbeddedFormReady();
        return (stateController.viewGroupState.marketCriteria[
          this.group.id
        ] = profile); // this.profile
      })
      .catch(this._showError)
      .finally(() => {
        this.setCriteriaDirty(false);
        this.profileLoading = false;
      });
  };

  _getCaregivers = async () => {
    this.searching = true;

    const groupId = this.group.id;
    const data = this.profileJSONData;
    const typeClassId = this.group.profile.typeClassId;
    const isAdmin = isSnSOrAdmin(this.user) ? 1 : 0;

    if (data === "{}") return (this.searching = false);

    return await apiController
      .searchMarketplaceGroups({
        groupId,
        data,
        typeClassId,
        isAdmin,
        groupTypeIds: arrayFlat([
          (this.group.typeId === groupTypeIds.careReceiver ||
            this.isMCBGroup) &&
            groupTypeIds.caregiver,
          this.group.typeId === groupTypeIds.household &&
            serviceProviderGroupTypeIds
        ]).filter(Boolean)
      })
      .then(
        action(async groups => {
          const fixProfile = group =>
            isEmpty(group.profile) &&
            apiController.getProfileById(group.profileId).then(
              action(profile => {
                group.profile = profile;
              })
            );
          this.caregiverGroups = groups;
          await Promise.all(this.caregiverGroups.map(fixProfile)).catch(
            console.warn
          );
          return Promise.resolve();
        })
      )
      .then(() =>
        whenFulfill(
          () =>
            isEmpty(fileService.getAvatarQueue) &&
            isEmpty(fileService.defaultAvatarQueue) &&
            isEmpty(fileService.avatarLoading) &&
            isEmpty(fileService.avatarBlobLoading)
        )
      )
      .finally(() => (this.searching = false));
  };

  _getShortlistTopicAuto = async () => {
    return apiController
      .getTopicById(this.currentShortlistId)
      .then(clientController.updateTopic)
      .catch(this._showError);
  };

  _getCandidatesTopicsAuto = async quietly => {
    this.checkboxLoading = !quietly;
    return apiController
      .getGroupTopicsByTypeId(this.group.id, topicTypeIds.candidate)
      .then(topics => {
        // return apiController.getSubTopicsByParent(topicId).then(subTopics => {
        clientController
          .findSubTopicByParentId(this.currentShortlistId)
          .map(t => clientController.removeTopic(t.id));

        for (let topic of topics) {
          clientController.updateTopic(topic);
        }
        this.checkboxLoading = false;
        return Promise.resolve();
      })
      .catch(this._showError);
  };

  _getCandidatesGroupsAuto = async () => {
    clearTimeout(this.candidateGroupsDebounce);
    this.candidateGroupsDebounce = setTimeout(() => {
      Promise.all(
        this.currentCandidateTopics.map(async topic => {
          const groupId = topic.groupIdList.find(id => id !== this.group.id);
          const group = this.combinedCaregiverGroups.find(
            g => g.id === groupId
          );

          if (groupId && !group) {
            await apiController.getGroupById(groupId).then(
              action(async group => {
                if (isEmpty(group.profile)) {
                  await apiController.getProfileById(group.profileId).then(
                    action(profile => {
                      group.profile = profile;
                    })
                  );
                }
                this.candidateGroups.push(group);
              })
            );
          }
        })
      );
    }, 500);
  };

  _getRatingReviewAuto = async () => {
    clearTimeout(this.ratingLoadingDebounce);
    this.ratingLoadingDebounce = setTimeout(() => {
      const userIdList = this.combinedCaregiverGroups
        .map(group => !!this.visibleGroupIds[group.id] && group.owner)
        .filter(Boolean)
        .filter(
          userId =>
            !this.aggregatedRatings.some(rating => rating.userId === userId) &&
            !this.ratingLoadingUserIds.includes(userId)
        );
      if (isEmpty(userIdList)) return;
      this.ratingLoadingUserIds.concat(userIdList);
      const data = {
        userIdList,
        showUnpublished: false
      };
      return apiService
        .async("POST", {
          endpoint: endpointConfig.batch_get_aggregated_rating,
          data
        })
        .then(response => response.data || [])
        .then(
          action(ratings =>
            ratings.forEach(
              action(rating => {
                this.ratingLoadingUserIds.remove(rating.userId);
                const existing = this.aggregatedRatings.find(
                  r => r.userId === rating.userId
                );
                if (existing) return Object.assign(existing, rating);
                return this.aggregatedRatings.push(rating);
              })
            )
          )
        );
    }, 500);
  };

  _getAchievementAuto = async () => {
    clearTimeout(this.achievementLoadingDebounce);
    this.achievementLoadingDebounce = setTimeout(() => {
      const groupIdList = this.combinedCaregiverGroups
        .map(group => !!this.visibleGroupIds[group.id] && group.id)
        .filter(Boolean)
        .filter(
          groupId =>
            !this.achievements.some(
              achievement => achievement.caregiverGroupId === groupId
            ) && !this.achievementLoadingGroupIds.includes(groupId)
        );
      if (isEmpty(groupIdList)) return;
      this.achievementLoadingGroupIds.concat(groupIdList);
      const data = groupIdList;
      return apiService
        .async("POST", {
          endpoint: mcbEndpointConfig.batch_get_caregiver_achievement,
          data
        })
        .then(response => response.data || [])
        .then(
          action(achievements =>
            achievements.forEach(
              action(achievement => {
                this.achievementLoadingGroupIds.remove(
                  achievement.caregiverGroupId
                );
                const existing = this.achievements.find(
                  a => a.caregiverGroupId === achievement.caregiverGroupId
                );
                if (existing) return Object.assign(existing, achievement);
                return this.achievements.push(achievement);
              })
            )
          )
        );
    }, 500);
  };

  onTopTabChange = value => {
    if (this.prevTab === "criteria") {
      if (value === "search") this.onSearchResultFocus(true);
    }
    if (this.prevTab === "search") {
      // ...
    }
    if (this.prevTab === "candidates") {
      // ...
    }
    if (this.prevTab !== value) this.prevTab = value;
  };

  onSearchResultFocus = refresh => {
    if (refresh) {
      if (this.isScratchpad) {
        setTimeout(this.updateScratchpadSearched);
      }
      this.aggregatedRatings.clear();
      this.achievements.clear();
      return (
        this._getCaregivers()
          // .then(this.sendEmbeddedFormClass)
          .then(this.sendEmbeddedProfileData)
          .then(this.sendEmbeddedShortlistTopic)
          .then(this.sendEmbeddedSearchResults)
          .catch(this._retry)
      );
    }
  };

  onCandidateFocus = () => {
    // return this._getCandidatesGroups().catch(this._retry);
  };

  getCandidateChecked = group => {
    let checked;
    for (let topic of this.currentCandidateTopics) {
      if (group.id && topic["groupIdList"].includes(group.id)) checked = true;
    }
    return checked;
  };

  getCaregiverHourlyRate = profile => {
    if (!profile) return null;
    const formatResult = value =>
      `$${Number(value).toFixed(2)}/${UIText.marketHour}`;
    const parseComputedRate = () => {
      const myRequiredHourlyRate =
        profile.myRequiredHourlyRate && Number(profile.myRequiredHourlyRate);
      if (!myRequiredHourlyRate) return null;
      const caregiverTypeClass = formService.findFormClassById(
        typeClassIds.caregiverProfile
      );
      const fields = safeParseJSON(caregiverTypeClass.metadata, true);
      if (isEmpty(fields)) return null;
      const field = fields.find(f => f.name === "publishedHourlyRate");
      if (!field) return null;
      const linkage = field.linkedFields;
      if (linkage) {
        if (linkage.name) {
          if (Array.isArray(linkage.name) && linkage.modifier) {
            return new Function(
              linkage.name.join(","),
              `return ${linkage.modifier}`
            )(myRequiredHourlyRate);
          } else if (typeof linkage.name === "string") {
            return linkage.modifier
              ? new Function(linkage.name, `return ${linkage.modifier}`)(
                  myRequiredHourlyRate
                )
              : myRequiredHourlyRate;
          }
        }
      }
    };
    let profileRate = profile.publishedHourlyRate || profile.requiredHourlyRate;
    profileRate = profileRate && !isNaN(profileRate) && profileRate;
    if (!profileRate) profileRate = parseComputedRate();
    return profileRate && formatResult(profileRate);
  };

  isRatingLoading = computedFn(profile => {
    const group = this.combinedCaregiverGroups.find(
      g => g.profileId === profile.id
    );
    const userId = group && group.owner;
    if (!userId) return false;
    return (
      this.ratingLoadingUserIds.includes(userId) ||
      !this.aggregatedRatings.some(r => r.userId === userId)
    );
  });

  isAchievementLoading = computedFn(profile => {
    const group = this.combinedCaregiverGroups.find(
      g => g.profileId === profile.id
    );
    const groupId = group && group.id;
    if (!groupId) return false;
    return (
      this.achievementLoadingGroupIds.includes(groupId) ||
      !this.achievements.some(a => a.caregiverGroupid === groupId)
    );
  });

  findGroupRatingScore = computedFn(profile => {
    const group = this.combinedCaregiverGroups.find(
      g => g.profileId === profile.id
    );
    if (!group) return { samples: 0, rating: 0 };
    const aggregatedRatingReview = this.aggregatedRatings.find(
      r => r.userId === group.owner
    );
    return getRatingAggregatedScoreAndSamples(aggregatedRatingReview);
  });

  findGroupAchievement = computedFn(group => {
    if (!group) return null;
    return this.achievements.find(a => a.caregiverGroupId === group.id);
  });

  createShortlist = async description => {
    if (description && description.toLowerCase() === "website") {
      return stateController.showPopup({
        title: capitalize(UIText.marketplace),
        content: UIText.marketShortlistNameReserved,
        leftButtonText: UIText.generalConfirm,
        dismissOnBackPress: true
      });
    }

    if (this.createSLTry > this.createSLThreshold) {
      return stateController
        .showPopup({
          title: capitalize(UIText.marketplace),
          content: UIText.marketNotAvailable,
          leftButtonText: UIText.generalConfirm,
          leftButtonPress: () =>
            stateController.dismissPopup().then(this.navigateBackPreviousTab)
        })
        .then(() => (this.createSLTry = 0));
    }

    const topic = {
      creatorMemberId: this.member.id,
      typeId: topicTypeIds.shortlist,
      description: description || UIText.marketDefaultShortlistName,
      groupId: this.group.id,
      onCalendar: 0,
      isTemplate: 0,
      isParentTemplate: 0,
      isCompleted: 0,
      isDataLocked: 0,
      isLocked: 0,
      typeClassId: typeClassIds.shortlistTopic,
      typeClassVersion: 1 // Default for now.
    };

    return apiService
      .async("POST", {
        endpoint: endpointConfig.create_topic,
        data: {
          currentGroupId: this.group.id,
          caregiverGroupId: null,
          topic
        }
      })
      .then(this._getShortlists);
  };

  editShortlist = event => {
    preventDefaultStopProp(event);

    if (
      this.loading ||
      this.editLoading ||
      this.currentShortlistId === "new" ||
      !this.currentShortlistId
    )
      return;

    return editTopicMenu(this.currentShortlistTopic, this.summaryEditFlags, {
      editTopicName: this.editShortlistName,
      deleteTopic: this.deleteShortlist
    });
  };

  editShortlistName = () => {
    this.editLoading = true;
    return editTopicDescription(this.currentShortlistTopic)
      .then(update => update && this._getShortlists())
      .catch(this._showError)
      .finally(() => (this.editLoading = false));
  };

  deleteShortlist = async () => {
    this.editLoading = true;
    return deleteTopic(this.currentShortlistTopic)
      .then(update => update && this._getShortlists())
      .catch(err => {
        let denyMessage;
        if (err.response.status === 304) {
          denyMessage = UIText.marketShortlistDeleteDeny;
          return this._showError({ message: denyMessage });
        }
        return this._showError(err);
      })
      .finally(() => (this.editLoading = false));
  };

  addAllToShortlist = event => {
    const addedPopup = () =>
      stateController.showPopup({
        title: UIText.marketplace,
        content: UIText.marketAddedAllToShortlist(
          this.currentShortlistTopic.description,
          this.isSubscriptionMsgEnabled
        ),
        leftButtonText: UIText.generalConfirm,
        leftButtonPress: () =>
          stateController
            .dismissPopup()
            .then(() => this.handleTabChange({ key: "candidates" })),
        dismissOnBackPress: true
      });

    this.addingAllToShortlist = true;

    return Promise.all(
      this.caregiverGroups
        .map(
          group =>
            !this.getCandidateChecked(group) &&
            this.addToShortlist(group.id, true)
        )
        .filter(Boolean)
    )
      .then(addedPopup)
      .catch(this._showError)
      .finally(() => (this.addingAllToShortlist = false));
  };

  addToShortlist = async (groupId, quietly) => {
    const group = this.caregiverGroups.find(g => g.id === groupId);
    group.inShortlistPending = true;

    const caregiverProfile = toJS(group.profile && group.profile.data) || {};
    const careCircleProfile = toJS(this.shouldRenderProfileData);
    let cP = {},
      ccP = {};

    for (let field in caregiverProfile) {
      caregiverProfile.hasOwnProperty(field) &&
        (cP[`caregiver${capitalize(field)}`] = caregiverProfile[field]);
    }
    for (let field in careCircleProfile) {
      careCircleProfile.hasOwnProperty(field) &&
        (ccP[`careCircle${capitalize(field)}`] = careCircleProfile[field]);
    }

    if (group.rank) ccP["rank"] = group.rank.toString();
    if (group.total) ccP["score"] = group.total.toString();

    const data = {
      ...cP,
      ...ccP
    };

    const topic = {
      creatorMemberId: this.member.id,
      typeId: topicTypeIds.candidate,
      description: abbvLastNameDisplayNameEng(
        getDisplayNameEng(caregiverProfile)
      ),
      parentId: this.currentShortlistId,
      isTemplate: 0,
      isParentTemplate: 0,
      typeClassId: typeClassIds.candidateTopic,
      typeClassVersion: 2, // Default for now.
      data: JSON.stringify(data)
    };

    const addedPopup = () =>
      stateController.showPopup({
        title: UIText.marketplace,
        content: UIText.marketAddedToShortlist(
          abbvLastNameDisplayNameEng(getDisplayNameEng(caregiverProfile)),
          this.currentShortlistTopic.description,
          this.isSubscriptionMsgEnabled
        ),
        leftButtonText: UIText.generalConfirm,
        dismissOnBackPress: true
      });

    return apiService
      .async("POST", {
        endpoint: endpointConfig.create_topic,
        data: {
          currentGroupId: this.group.id,
          otherGroupIdList: [groupId],
          topic
        }
      })
      .then(response => this._getCandidatesTopicsAuto(true)) // quietly get.
      .then(!quietly && addedPopup)
      .catch(this._showError)
      .finally(() => (group.inShortlistPending = false));
  };

  removeFromShortlist = (topicId, group, archive) => {
    if (group) {
      group.inShortlistPending = true;
    } else {
      this.checkboxLoading = true;
    }

    const updateTopic = async () =>
      apiController.getTopicById(topicId).then(clientController.updateTopic);

    const removedPopup = () =>
      stateController.showPopup({
        title: UIText.marketplace,
        content: UIText[
          // archive ? "marketArchivedFromShortlist" : "marketRemovedFromShortlist"
          "marketRemovedFromShortlist"
        ](
          abbvLastNameDisplayNameEng(getDisplayNameEng(group.profile)),
          this.currentShortlistTopic.description
        ),
        leftButtonText: UIText.generalConfirm,
        dismissOnBackPress: true
      });

    const execRemoveArchive = async () => {
      if (archive) {
        return apiService
          .async("PATCH", {
            endpoint: endpointConfig.topic_by_id(topicId),
            data: {
              execEndTime: new Date().getTime(),
              isCompleted: 1
            }
          })
          .then(updateTopic);
      } else {
        return apiController
          .deleteTopic(topicId)
          .then(() => clientController.removeTopic(topicId));
      }
    };

    return execRemoveArchive()
      .then(removedPopup)
      .catch(this._showError)
      .finally(() => {
        if (group) {
          group.inShortlistPending = false;
        } else {
          this.checkboxLoading = false;
        }
      });
  };

  handleCheckboxChange = group => {
    return this.getCandidateChecked(group)
      ? (() => {
          // Need to find that lovely topic id first before removal.
          const topic = this.currentCandidateTopics.find(t =>
            t["groupIdList"].includes(group.id)
          );
          topic && this.handleTopicRemove(topic.id, group);
        })()
      : (() => {
          let duplication = false;
          const dupCandidtateTopic = this.candidateTopics.find(t =>
            t["groupIdList"].includes(group.id)
          );
          if (dupCandidtateTopic)
            duplication = this.shortlistItems.find(
              i => i.value === dupCandidtateTopic.parentId
            );
          // console.log(duplication);
          if (duplication) {
            return stateController.showPopup({
              title: capitalize(UIText.marketplace),
              content: UIText.marketCandidateAlreadyInShortlist,
              buttonSet: [
                {
                  title: UIText.marketCandidateDuplicateShow,
                  onPress: () => {
                    stateController
                      .dismissPopup()
                      .then(() => this.handleCardPress(group));
                  }
                },
                {
                  title: UIText.marketCandidateDuplicateAdd,
                  onPress: () => {
                    stateController
                      .dismissPopup()
                      .then(() => this.addToShortlist(group.id));
                  },
                  showDivider: true
                },
                {
                  title: UIText.marketCandidateDuplicateNoAdd,
                  onPress: stateController.dismissPopup
                }
              ],
              dismissOnBackPress: true
            });
          }
          this.addToShortlist(group.id);
        })();
  };

  handleTopicRemove = (topicId, group) => {
    const topic = this.currentCandidateTopics.find(t => t.id === topicId);
    if (!topic) return;
    // if (topic.lastPublicMessageTime) {
    return stateController.showPopup({
      title: capitalize(UIText.marketplace),
      content: UIText.marketTopicArchiveRemove(
        false, // isArchive wording
        abbvLastNameDisplayNameEng(getDisplayNameEng(group.profile)),
        this.currentShortlistTopic.description
      ),
      leftButtonText: UIText.generalNo,
      rightButtonText: UIText.generalYes,
      rightButtonPress: () =>
        stateController
          .dismissPopup()
          .then(() =>
            this.removeFromShortlist(
              topic.id,
              group,
              !!topic.lastPublicMessageTime
            )
          )
    });
    // }
    // this.removeFromShortlist(topic.id, group);
  };

  handleTabChange = tab => {
    stateController.viewGroupState.topTabs["market"][this.group.id] = tab.key;
    return true;
  };

  handleCardPress = async group => {
    // Treat double clicking on the checkbox.
    if (this.checkboxLoading || group.inShortlistPending) return;

    const profileId = group.profileId;
    const profile = group.profile;
    const candidateTopic = this.currentCandidateTopics.find(t =>
      (t.groupIdList || []).includes(group.id)
    );

    const screenId = randomString();
    const button =
      !this.isVisitor &&
      observable({
        text: candidateTopic
          ? UIText.marketAlreadyInShortlist
          : UIText.marketAddToShortlist,
        disabled: !!candidateTopic,
        handlePress: () => {
          button.loading = true;
          button.disabled = true;
          this.addToShortlist(group.id).then(() => {
            stateController.viewGroupState.topTabs["market"][this.group.id] =
              "candidates";
            NavigationService.navigate("Group", { group: this.group.id });
          });
        }
      });

    stateController.viewProfileState.topButton[
      screenId
    ] = stateController.viewProfileState.bottomButton[screenId] = button;

    stateController.viewProfileState.titleOverride[
      screenId
    ] = abbvLastNameDisplayNameEng(getDisplayNameEng(profile));

    stateController.viewProfileState.nonEditModeHideNoValue[screenId] = true;

    stateController.viewProfileState.additionalProfileProcessors[screenId] = [
      addRatingToProfile(
        group,
        this.findGroupRatingScore(group.profile),
        this.findGroupAchievement(group)
      ),
      addBookingLinkToProfile(this.group.id, profileId)
    ];

    if (candidateTopic && !this.isMCBGroup) {
      stateController.viewProfileState.rightButtonOverride[screenId] = {
        icon: candidateTopic["lastPublicMessageTime"] ? "chat" : "chat-bubble",
        handlePress: () => {
          if (this.isScratchpad || !this.isSubscriptionMsgEnabled) {
            return this.subscriptionPopup("chat");
          }
          stateController.viewTopicId = candidateTopic.id;
          NavigationService.navigate("Chat", { topic: candidateTopic.id });
        }
      };
    }

    if (isSnSOrAdmin(this.user)) {
      const unapproved =
        profile.data &&
        !profile.data["approvalOfCandidateConfirmedByReference"];
      if (unapproved || this.isMCBGroup) {
        stateController.viewProfileState.renderFlags[screenId] = [
          "caregiver",
          "marketplace",
          "search",
          "general",
          "mcb"
        ];
        stateController.viewProfileState.nonEditModeHideNoValue[
          screenId
        ] = false;
        if (this.isMCBGroup) {
          stateController.viewProfileState.editFlags[screenId] =
            stateController.viewProfileState.renderFlags[screenId];
        }
      }
      // Admin recruit data injection
      if (this.isMCBGroup) {
        await stateController.showPopup({
          title: UIText.adminCentre,
          content: UIText.pleaseWait
        });
        const cards = observable([
          {
            group,
            profile: group.profile
          }
        ]);
        adminCentrePrepProfileScreen(
          cards[0],
          screenId,
          groupTypeIds.caregiver,
          () => NavigationService.navigate("Group", { group: this.group.id })
        );
        await adminCentreGetOnboardingTopics(cards, groupTypeIds.caregiver)
          .then(adminCentreGetNewOnboardingData)
          .then(stateController.dismissPopup)
          .catch(this._showError);
        const legacyOnboardingTopic = cards[0].topic;
        const onboardingData = cards[0].onboarding;
        if (legacyOnboardingTopic) {
          setTimeout(() =>
            adminCentreInjectLegacyRecruitForm(
              screenId,
              cards[0],
              false,
              this._showError
            )
          );
        } else if (onboardingData) {
          setTimeout(() =>
            adminCentreInjectNewRecruitForm(
              screenId,
              cards[0],
              false,
              this._showError
            )
          );
        } else {
          // Explicit show fields for legacy onboarding.
          stateController.viewProfileState.renderFlags[screenId].push(
            "legacyOnboarding"
          );
          stateController.viewProfileState.editFlags[screenId].push(
            "legacyOnboarding"
          );
        }
      }
    }

    // stateController.viewProfileState.renderFlags[screenId] = ["marketplace"];

    NavigationService.navigate("Profile", {
      group: this.group.id,
      profile: profileId,
      admin: this.isMCBGroup,
      screenId
    });
  };

  handleCandidatePress = (topicId, group) => {
    const topic = this.currentCandidateTopics.find(t => t.id === topicId);
    if (isEmpty(topic)) return;

    // if (!isEmpty(group)) {
    //   const screenId = randomString();
    //   stateController.viewProfileState.rightButtonOverride[screenId] = {
    //     icon: topic.lastPublicMessageTime ? "chat" : "chat-bubble",
    //     handlePress: () =>
    //       NavigationService.navigate("Chat", { topic: topicId })
    //   };
    //   stateController.viewProfileState.renderFlags[screenId] = ["marketplace"];
    //   NavigationService.navigate("Profile", {
    //     profile: group.profileId,
    //     screenId
    //   });
    // }
    stateController.viewTopicId = topic.id;
    stateController.viewTopicState.additionalFormProcessors = [
      addRatingToProfile(group, this.findGroupRatingScore(group.profile)),
      addBookingLinkToProfile(this.group.id, group.profileId)
    ];
    NavigationService.navigate("Topic", { topic: topic.id });
  };

  handleShortlistChange = (value, i) => {
    if (value === "new") {
      if (this.loading || this.editLoading) return;

      let listName;

      return stateController.showPopup({
        title: UIText.topicNewTopic(UIText.marketShortlist.toLowerCase()),
        leftButtonText: UIText.generalCancel,
        leftButtonPress: stateController.dismissPopup,
        rightButtonText: UIText.generalConfirm,
        rightButtonPress: () => {
          stateController.dismissPopup().then(() => {
            if (!listName) return;
            this._isLoading();

            return this.createShortlist(listName)
              .then(() => {
                return this.handleShortlistChange(
                  toJS(this.shortlistItems).reverse()[0].value
                );
              })
              .finally(this._doneLoading);
          });
        },
        rightButtonDisabled: true,
        input: {
          placeholder: UIText.topicNewTopicInput(UIText.marketShortlist),
          onChange: e => {
            listName = e.nativeEvent.text;
            stateController.popup.rightButtonDisabled = isEmpty(listName);
          }
        }
      });
    }

    // this.currentShortlistId = Number(value);
    stateController.viewGroupState.marketShortlistId[this.group.id] = Number(
      value
    );
  };

  handleCriteriaSimplifiedPress = event => {
    preventDefaultStopProp(event);

    stateController.viewGroupState.marketCriteriaSimplified[
      this.group.id
    ] = !this.criteriaSimplified;
  };

  handleMandatoryCriteriaPress = () => {
    const toggleVisibility = () =>
      (stateController.viewGroupState.marketHideMandatoryToggles[
        this.group.id
      ] = visible.checked);

    const visible = observable({
      placeholder: UIText.marketHideMandatorySwitches,
      checked: this.hideMandatoryCriteriaToggles
    });

    return stateController.showPopup({
      title: UIText.tooltipHelp(UIText.marketMandatoryCriteria),
      leftButtonText: UIText.generalConfirm,
      leftButtonPress: () =>
        stateController.dismissPopup().then(toggleVisibility),
      contentAlign: "left",
      multiSelect: {
        onCheckboxChange: visible => (visible.checked = !visible.checked),
        options: [visible],
        title: UIText.marketMandatorySwitches,
        heading: UIText.marketMandatoryCriteriaHelp
      }
    });
  };

  toggleMandatoryField = (event, field) => {
    if (field.type === "checkbox" && !field.value && !!event) {
      this.setCriteriaDirty(true);
      field.value = true;
    }
    stateController.viewGroupState.marketMandatoryCriteria[this.group.id][
      field.name
    ] = !this.fieldIsMandatory(field);
  };

  toggleSearchMandatory = () =>
    (stateController.viewGroupState.marketSearchMandatory[this.group.id] = !this
      .searchMandatoryOnly);

  syncMandatoryToggleField = field =>
    !field.value &&
    this.fieldIsMandatory(field) &&
    this.toggleMandatoryField(null, field);

  resetCriteria = () => {
    stateController.viewGroupState.marketMandatoryCriteria[this.group.id] = {};
    return this._loadProfile(true);
  };

  saveCriteria = event => {
    if (this.isScratchpad) {
      return this.subscriptionPopup("save");
    }

    return this.saveProfile();
  };

  saveProfile = async displayName => {
    this.profileLoading = true;
    let data = toJS(this.profileJSONData);

    if (displayName) {
      const profile = safeParseJSON(data);
      profile.displayName = displayName;
      data = JSON.stringify(profile);
    }

    return apiService
      .async("PATCH", {
        endpoint: endpointConfig.profile_by_id(this.group.profileId),
        data: { data }
      })
      .then(() => false)
      .then(this._loadProfile)
      .catch(this._showError);
  };

  updateScratchpadSearched = async () => {
    const appendSearchedToGroupName = async () => {
      const groupName = this.group["groupName"];

      if (!groupName || groupName.match(/{scratchpad}{searched}/g)) return;

      const data = {
        groupName: groupName.replace(/{scratchpad}/g, "{scratchpad}{searched}")
      };
      return apiService.async("PATCH", {
        endpoint: endpointConfig.group_by_id_wo_member(this.group.id),
        data
      });
    };

    console.log(this.criteriaDirty);
    // return (async () => this.criteriaDirty && this.saveProfile())()
    return this.saveProfile()
      .then(appendSearchedToGroupName)
      .then(() => apiController.getGroupById(this.group.id))
      .then(clientController.updateGroup)
      .catch(this._showError);
  };

  setCriteriaDirty = state =>
    (stateController.viewGroupState.marketCriteriaDirty[this.group.id] = state);

  fieldShouldShowMandatory = computedFn(
    field =>
      !this.hideMandatoryCriteriaToggles &&
      (field.flags && !!field.flags["searchMandatory"])
  );

  fieldIsMandatory = computedFn(
    field =>
      !!this.mandatoryCriteriaFieldNames[field.name] ||
      (field.flags && !!field.flags["searchAlwaysMandatory"] && !!field.value)
  );

  fieldIsMandatoryDisabled = computedFn(
    field => field.flags && !!field.flags["searchAlwaysMandatory"]
  );

  openChat = topicId => {
    if (this.isScratchpad || !this.isSubscriptionMsgEnabled) {
      return this.subscriptionPopup("chat");
    }
    stateController.viewTopicId = topicId;
    NavigationService.navigate("Chat", { topic: topicId });
  };

  hire = (groupId, topicId) => {
    const group = this.combinedCaregiverGroups.find(g => g.id === groupId);
    if (isEmpty(group)) return;

    if (this.isScratchpad) {
      return this.subscriptionPopup("hire_scratchpad");
    }

    if (isEmpty(this.group.subscription) || !this.isSubscriptionMsgEnabled) {
      return this.subscriptionPopup("hire_empty_sub");
    }

    const displayName = abbvLastNameDisplayNameEng(
      getDisplayNameEng(group.profile)
    );
    const currentGroupName = `${
      this.group["groupTypeName"]
    } ${getDisplayNameEng(this.group.profile)}`;

    const execHire = data => {
      const roles = Array.isArray(this.group.roles) && this.group.roles;
      const paidCaregiverRole =
        roles.find(r => r.groupTypeRoleId === groupTypeRoleIds.paidCaregiver) ||
        {};

      data = data || {
        groupId: this.group.id,
        invitorMemberId: this.member.id,
        caregiverGroupId: groupId,
        candidateTopicId: topicId,
        roleIdList: [paidCaregiverRole.id]
      };

      const isReinstate = !!data.reinstate;

      return (
        stateController
          .showPopup({
            title: capitalize(UIText.marketHire),
            content: UIText.pleaseWait
          })
          .then(() =>
            apiService
              .async("POST", {
                endpoint: mcbEndpointConfig.invite_hire(this.group.id),
                data
              })
              .catch(err => {
                const isArchived =
                  err && err.response && err.response.status === 406;
                if (isArchived) {
                  const member =
                    err.response.data &&
                    apiController.parser.parseMember(err.response.data);
                  setTimeout(() => handleArchived(data, member));
                  return Promise.reject({});
                }

                return Promise.reject(err);
              })
          )
          // .then(console.log)
          .then(stateController.dismissPopup)
          .then(() =>
            stateController.showPopup({
              title: capitalize(UIText.marketHire),
              content: isReinstate
                ? UIText.invitationReinstated(displayName)
                : UIText.marketHireSent(displayName),
              leftButtonText: UIText.generalConfirm
            })
          )
          .catch(err => {
            if (err.response && err.response.data) {
              if (err.response.data["hired"])
                return stateController.showPopup({
                  title: capitalize(UIText.marketHire),
                  content: UIText.marketAlreadyHired(displayName),
                  leftButtonText: UIText.generalConfirm
                });
            }
            return this._showError(err);
          })
      );
    };

    const handleArchived = async (data, member) => {
      member = member || {};
      const originalName = getDisplayNameEng(member.profile || member);
      const isHired =
        member.profile &&
        member.profile.typeClassId === typeClassIds.paidCaregiverProfile;
      const reHire = type => {
        data[type] = true;
        return stateController
          .dismissPopup()
          .then(() =>
            stateController.showPopup({
              title: capitalize(UIText.marketHire),
              content: UIText.pleaseWait
            })
          )
          .then(() => execHire(data));
      };

      return stateController.dismissPopup().then(() =>
        stateController.showPopup({
          title: capitalize(UIText.marketHire),
          content: UIText.invitationPreviouslyHired(
            currentGroupName,
            originalName,
            displayName,
            isHired
          ),
          contentAlign: "left",
          buttonSet: [
            {
              title: UIText.invitationReinstate,
              onPress: () => reHire("reinstate")
              // disabled: true
            },
            {
              title: isHired
                ? UIText.invitationHireNew
                : UIText.invitationInviteNew,
              onPress: () => reHire("forceCreateNew"),
              showDivider: true
            },
            {
              title: UIText.generalCancel,
              onPress: stateController.dismissPopup
            }
          ].map(b => (b.align = "center") && b)
        })
      );
    };

    return stateController.showPopup({
      title: capitalize(UIText.marketHire),
      content: UIText.marketConfirmHire(displayName),
      leftButtonText: UIText.generalNo,
      leftButtonPress: stateController.dismissPopup,
      rightButtonText: UIText.generalYes,
      rightButtonPress: () => stateController.dismissPopup().then(execHire)
    });
  };

  subscriptionPopup = type => {
    if (this.isScratchpad && type === "save") {
      return this.convertFullGroup(true);
    }
    return stateController.showPopup({
      title: capitalize(UIText.marketplace),
      content: {
        text:
          type === "save"
            ? UIText.marketSubNoSave(this.careReceiverGroupType.typeName)
            : type === "hire_scratchpad"
            ? UIText.marketSubNoHire(this.careReceiverGroupType.typeName)
            : type === "hire_empty_sub"
            ? UIText.marketSubNoSubNoHire
            : UIText.marketSubNoChat
        // text:
        //   type === "save"
        //     ? `${UIText.marketSubNoSave(this.careReceiverGroupType.typeName)} ${
        //         UIText.marketPlanInfo
        //       }`
        //     : type === "hire_scratchpad"
        //     ? `${UIText.marketSubNoHire(this.careReceiverGroupType.typeName)} ${
        //         UIText.marketPlanInfo
        //       }`
        //     : type === "hire_empty_sub"
        //     ? `${UIText.marketSubNoSubNoHire} ${UIText.marketPlanInfo}`
        //     : `${UIText.marketSubNoChat} ${UIText.marketPlanInfo}`,
        // link: planMarketingUrl
      },
      contentAlign: "left",
      leftButtonText: UIText.generalCancel,
      rightButtonText: UIText.planSelectAPlan,
      rightButtonPress: () =>
        stateController.dismissPopup().then(() => {
          if (this.isScratchpad) {
            this.convertFullGroup();
          } else {
            this.handleBillingPress();
          }
        })
    });
  };

  convertFullGroup = noSub => {
    if (noSub) {
      return stateController
        .showPopup({
          title: UIText.marketplace,
          content: UIText.pleaseWait
        })
        .then(() => this.saveProfile())
        .then(stateController.dismissPopup)
        .then(() => {
          stateController.initSetupMode = "convertScratchpad";
          return NavigationService.navigate("Setup");
        });
    }

    let groupName =
      getDisplayNameEng(this.group.profile) !==
        [UIText.title, capitalize(UIText.marketplace)].join(" ") &&
      getDisplayNameEng(this.group.profile);

    stateController.showPopup({
      title: UIText.marketConvertGroupName(this.careReceiverGroupType.typeName),
      leftButtonText: UIText.generalCancel,
      rightButtonText: UIText.generalConfirm,
      rightButtonPress: () =>
        stateController
          .dismissPopup()
          .then(() =>
            stateController.showPopup({
              title: UIText.marketplace,
              content: UIText.pleaseWait
            })
          )
          .then(() => this.saveProfile(groupName))
          .then(stateController.dismissPopup)
          .then(this.handleBillingPress),
      rightButtonDisabled: isEmpty(groupName),
      input: {
        placeholder: UIText.marketConvertGroupNamePlaceholder,
        defaultValue: groupName,
        onChange: e => {
          groupName = e.nativeEvent.text;
          stateController.popup.rightButtonDisabled = isEmpty(groupName);
        }
      }
    });
  };

  handleAdminBroadcastPress = event => {
    const sendBroadcast = async () => {
      const targetGroupIdList = this.currentCandidateTopics
        .map(t => {
          const group =
            this.combinedCaregiverGroups.find(g =>
              t["groupIdList"].includes(g.id)
            ) || {};
          return group && group.id;
        })
        .filter(Boolean);
      return stateController
        .dismissPopup()
        .then(() =>
          stateController.showPopup({
            title: UIText.marketAdminBroadcast,
            content: UIText.pleaseWait
          })
        )
        .then(() =>
          apiService.async("POST", {
            endpoint: mcbEndpointConfig.admin_prep_broadcast,
            data: {
              targetGroupIdList,
              groupId: this.group.id,
              senderMemberId: this.member.id,
              topicTypeId: topicTypeIds.mCBBroadcasts
            }
          })
        )
        .then(response => {
          console.log(response);
          const threadIdList = Array.isArray(response.data) && response.data;
          if (!threadIdList)
            return Promise.reject("No topics created for broadcast.");
          return threadIdList;
        })
        .then(threadIdList =>
          apiService.async("POST", {
            endpoint: mcbEndpointConfig.admin_send_broadcast,
            data: {
              threadIdList,
              senderMemberId: this.member.id,
              message: this.adminBroadcastMsg
            }
          })
        )
        .then(console.log)
        .then(clear)
        .then(() =>
          stateController.showPopup({
            title: UIText.marketAdminBroadcast,
            content: UIText.marketAdminBroadcastSuccess(
              this.currentShortlistTopic.description
            ),
            leftButtonText: UIText.generalConfirm
          })
        )
        .catch(this._showError);
    };

    const clear = () => {
      stateController.viewGroupState.marketAdminBroadcastMsg[this.group.id] =
        "";
      return stateController.dismissPopup();
    };

    return stateController.showPopup({
      title: UIText.marketAdminBroadcast,
      contentAlign: "left",
      input: {
        placeholder: UIText.marketAdminBroadcastPlaceholder,
        title: UIText.marketAdminBroadcastMsg,
        heading: {
          text: UIText.marketAdminBroadcastHeading
        },
        value: this.adminBroadcastMsg,
        multiline: true,
        // maxLength: 1500,
        heightOverride: responsive.deviceDimension.height / 4.5,
        onChange: event => {
          if (event.nativeEvent && typeof event.nativeEvent.text === "string") {
            stateController.viewGroupState.marketAdminBroadcastMsg[
              this.group.id
            ] = event.nativeEvent.text;
          }
          const button = stateController.popup.buttonSet.find(
            b => b.title === UIText.generalSend
          );
          if (button) button.disabled = isEmpty(this.adminBroadcastMsg);
        }
      },
      buttonSet: [
        {
          title: UIText.generalMinimize,
          onPress: stateController.dismissPopup
        },
        {
          title: UIText.generalSend,
          disabled: isEmpty(this.adminBroadcastMsg),
          onPress: sendBroadcast,
          bottomDivider: true
        },
        {
          title: UIText.generalCancel,
          onPress: clear
        }
      ].map(b => (b.align = "center") && b),
      dismissOnBackPress: true
    });
  };

  openPlanMarketingUrl = () => {
    Platform.OS === "web"
      ? window.open(planMarketingUrl)
      : Linking.openURL(planMarketingUrl);
  };

  openMarketCompanyNumber = () => {
    Platform.OS === "web"
      ? window.open(`tel:${planMarketingNumber}`)
      : Linking.openURL(`tel:${planMarketingNumber}`);
  };

  openAgreement = () => {
    return clientController.openAgreement();
  };

  dismissVisitorTncBanner = () => clientController.dismissVisitorTncBanner();

  onRefresh = () => {
    if (this.currentTab === "criteria") {
      return this.resetCriteria();
    }
    if (this.currentTab === "search") {
      return this.onSearchResultFocus(true);
    }
    if (this.currentTab === "candidates") {
      return this._getCandidatesTopicsAuto();
    }
    return this._loadMarketplace();
  };

  onScroll = event => {
    stateController.viewGroupState.marketScrollPos[this.group.id][
      this.currentTab
    ] = event.nativeEvent.contentOffset.y;
  };

  navigateBackPreviousTab = () => {
    setTimeout(() =>
      handleGroupBottomTabChange(
        this.group.id,
        stateController.viewGroupState.previousBottomTabs[this.group.id] ||
          "dashboard"
      )
    );
  };

  helpFocusNextField = (field, formFields, form) =>
    formService.helpFocusNextField(field, formFields, form, () =>
      this.handleTabChange({ key: "search" })
    );

  // Embedded mode functions
  _registerEmbeddedListeners = () => {
    embeddedService.addEventListener(
      "provinceChange",
      this.updateProvinceField
    );
    embeddedService.addEventListener(
      "territoryChange",
      this.updateTerritoryField
    );
    embeddedService.addEventListener("cityChange", this.updateCityField);
    embeddedService.addEventListener("searchFocus", () => {
      if (this.currentTab === "search") this.onSearchResultFocus(true);
      this.handleTabChange({ key: "search" });
    });
    embeddedService.addEventListener("criteriaFocus", () =>
      this.handleTabChange({ key: "criteria" })
    );
    embeddedService.addEventListener(
      "moreCriteria",
      () =>
        (stateController.viewGroupState.marketCriteriaSimplified[
          this.group.id
        ] = false)
    );
    embeddedService.addEventListener(
      "viewShortlistTopic",
      this.sendEmbeddedShortlistTopic
    );
    embeddedService.addEventListener("marketProfile", () =>
      this.sendEmbeddedFormClass(this.profile)
    );
  };

  sendEmbeddedFormClass = profile => {
    if (!embeddedService.isEmbedded) return;
    if (!embeddedService.initialized) {
      return setTimeout(this.sendEmbeddedFormClass, 500);
    }
    profile = profile || toJS(this.profile);
    return embeddedService.postMessage({ profile });
  };

  sendEmbeddedFormReady = () => {
    if (!embeddedService.isEmbedded) return;
    if (!embeddedService.initialized) {
      return setTimeout(this.sendEmbeddedFormClass, 500);
    }
    return embeddedService.postMessage({ marketplaceReady: true });
  };

  sendEmbeddedProfileData = () => {
    if (!embeddedService.isEmbedded) return;
    if (!embeddedService.initialized) {
      return setTimeout(this.sendEmbeddedProfileData, 500);
    }
    embeddedService.postMessage({
      profileData: toJS(this.profileData)
    });
  };

  sendEmbeddedShortlistTopic = () => {
    if (!embeddedService.isEmbedded) return;
    if (!embeddedService.initialized) {
      return setTimeout(this.sendEmbeddedShortlistTopic, 500);
    }
    embeddedService.postMessage({
      viewShortlistTopic: toJS(this.currentShortlistTopic)
    });
  };

  sendEmbeddedSearchResults = () => {
    if (!embeddedService.isEmbedded) return;
    if (!embeddedService.initialized) {
      return setTimeout(this.sendEmbeddedSearchResults, 500);
    }
    embeddedService.postMessage({
      searchResults: toJS(this.caregiverGroups)
    });
  };

  updateProvinceField = value => {
    const provinces = this.profile.find(field => field.name === "provinces");
    return provinces && (provinces.value = value);
  };

  updateTerritoryField = value => {
    const territories = this.profile.find(
      field => field.name === "territories"
    );
    return territories && (territories.value = value);
  };

  updateCityField = value => {
    const municipalities = this.profile.find(
      field => field.name === "municipalities"
    );
    return municipalities && (municipalities.value = value);
  };

  setVisible = groupId => {
    this.visibleGroupIds[groupId] = true;
    this._getRatingReviewAuto().catch(console.error);
    this._getAchievementAuto().catch(console.error);
  };

  restoreTerritorialFieldsForMCBGroup = profile => {
    for (const field of profile) {
      if (!["territories", "municipalities"].includes(field.name)) continue;
      field.hidden = false;
      if (field.name === "territories") {
        field.dependOn = "provinces";
      }
    }
  };
}
