import { clientController } from "../../../cdm/controllers/client-controller";
import { autorun, computed, observable, toJS } from "mobx";
import { apiService } from "../../../cdm/services/api-service";
import { endpointConfig, serverConfig } from "../../../config/api-config";
import {
  capitalize,
  isEmpty,
  serializeObject,
  validateEmail,
  validatePassword,
  validateUsername
} from "../../../utils/helpers";
import { stateController } from "../../../cdm/controllers/state-controller";
import { UIText } from "../../../config/lang-config";
import { apiController } from "../../../cdm/controllers/api-controller";
import publicIp from "react-native-public-ip";
import { mcbEndpointConfig } from "../../../custom/mcb/config/api-config";
import { formService } from "../../../cdm/services/form-service";
import {
  groupTypeRoleIds,
  serviceProviderGroupTypeIds
} from "../../../config/variable-config";
import { serviceProviderGroupTypeField } from "../../../custom/mcb/config/registration_intents";
import { matchServiceGroupTypeRoleByGroupTypeId } from "../../../custom/mcb/lib/group-utilities-mcb";
import { typeClassLibrary } from "../../../cdm/lib/typeClass-utilities";
import { Linking, Platform } from "react-native";

export class RegistrationController {
  @observable buttonDisabled = true;
  @observable registering = false;
  @observable currentSelectedValue = 1;
  @observable agreeChecked = false;
  @observable intentsChecked = [groupTypeRoleIds.primaryFamilyCaregiver];
  @observable visible = {
    password: true,
    repeat: true
  };
  @observable caregiverForm = [];

  // Validation error messages
  @observable error = {
    username: "",
    email: "",
    phone: undefined,
    password: "",
    repeat: "",
    anyPassword: false,
    firstName: "",
    lastName: ""
  };

  // Auto populate form fields.
  @computed get form() {
    return stateController.base64form;
  }
  @computed get autoUsername() {
    return this.form.username;
  }
  @computed get autoEmail() {
    return this.form.email;
  }
  @computed get autoPhone() {
    return this.form.phone;
  }
  @computed get autoFirstName() {
    return this.form.firstName;
  }
  @computed get autoLastName() {
    return this.form.lastName;
  }
  @computed get autoIntent() {
    return this.form.roleIdList;
  }
  @computed get autoIntentShowCheckbox() {
    return this.form.roleIdListShowCheckbox;
  }
  @computed get autoPlanId() {
    return this.form.planId;
  }
  @computed get autoPromoCode() {
    return this.form.promoCode;
  }

  @computed get hasOtherServiceProvider() {
    return this.intentsChecked.includes("other");
  }
  @computed get caregiverFormData() {
    return (
      formService.validateRequired(this.caregiverForm, true) &&
      formService.disassembleFormData(this.caregiverForm)
    );
  }

  fieldData = {};

  required = [
    "username",
    "password",
    "repeat",
    "email",
    "firstName",
    "lastName"
  ];

  constructor(props) {
    this.props = props;
    if (Array.isArray(this.autoIntent) && this.autoIntent.length > 0) {
      this.intentsChecked.push(...this.autoIntent);
    }
    this.invitationData = stateController.viewInvitationRegState.data;
    this.loadCaregiverForm().catch(this._showError);
  }

  componentDidMount() {
    stateController.currentScreen = "Registration";
  }

  _registerLoading = () => {
    this.registering = true;
    stateController.showPopup({
      title: UIText.registrationProgress,
      content: UIText.pleaseWait
    });
  };

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.registrationFailure,
      content:
        (err.response && JSON.stringify(err.response.data)) || err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _showSuccess = () => {
    stateController.dismissPopup().then(() =>
      stateController.showPopup({
        title: capitalize(UIText.success),
        content: UIText.registrationSuccess,
        leftButtonText: UIText.generalConfirm,
        dismissOnBackPress: true
      })
    );
  };

  loadCaregiverForm = async () =>
    apiService
      .async("GET", {
        endpoint: mcbEndpointConfig.get_wrapper_caregiver_fields,
        headers: serverConfig.defaultHeaders
      })
      .then(response => {
        const fields = response.data || [];
        if (isEmpty(fields)) return;
        return (this.caregiverForm = [
          serviceProviderGroupTypeField,
          ...fields.filter(
            field =>
              field.type !== "avatar" &&
              !Object.keys(this.error).some(f => field.name.match(f)) &&
              field.groupTypeIds &&
              serviceProviderGroupTypeIds.some(id =>
                field.groupTypeIds.includes(id)
              )
          )
        ]);
      })
      .then(() =>
        autorun(() => typeClassLibrary.autoSelectEnglish(this.caregiverForm))
      )
      .then(() =>
        autorun(() =>
          typeClassLibrary.providerFieldMandatoryAssist(this.caregiverForm)
        )
      )
      .then(() =>
        autorun(() =>
          typeClassLibrary.groupTypeIdDevalueFieldsAssist(this.caregiverForm)
        )
      );

  updateFields = (field, event) => {
    if (!event) return;
    if (event.nativeEvent && typeof event.nativeEvent.text === "string")
      this.fieldData[field] = event.nativeEvent.text;
    this.validateFields();
  };

  validateRequired = field => {
    const fieldData = this.fieldData;

    const value = fieldData[field];

    if (!value) {
      return (this.error[field] = `${UIText.entryRequiring} ${field}.`);
    }

    this.error[field] = "";

    if (field === "username" || field === "email") {
      if (field === "email") {
        if (!validateEmail(value)) {
          const isPlusSignError = (value || "").match(/\+/);
          return (this.error[field] = isPlusSignError
            ? UIText.registrationEmailNoPlusSign
            : `${UIText.entryRequiringValid} ${field}.`);
        }
      }

      if (field === "username") {
        if (!validateUsername(value))
          return (this.error[field] = UIText.usernameRequirement);
      }

      clientController.execLogout();

      apiController.checkExist(field, value).then(result => {
        this.error[field] = result.exist
          ? `${capitalize(UIText.registrationUniqueFields[field])} ${
              UIText.registrationUniqueExists
            }`
          : result.error
          ? (this.error[field] = result.error)
          : "";
      });
    }
  };

  validatePassword = (field, noError) => {
    validatePassword(this.fieldData, field, this.error, noError, UIText);
    return this.validateFields();
  };

  validateFields = () => {
    let disabled = false;
    for (let field in this.error) {
      if (!!this.error[field]) disabled = true;
    }
    for (let field in this.required) {
      let data = this.fieldData[this.required[field]];
      if (!data || data === "" || data === undefined) disabled = true;
    }
    if (!this.agreeChecked) disabled = true;
    if (this.intentsChecked.length <= 0) disabled = true;
    if (
      this.intentsChecked.includes("other") &&
      isEmpty(this.caregiverFormData)
    )
      disabled = true;
    this.buttonDisabled = disabled;
  };

  execRegister = async event => {
    if (this.buttonDisabled) return;

    // Fail safe logout.
    clientController.execLogout();

    if (this.hasOtherServiceProvider) {
      try {
        typeClassLibrary.mandatoryAbleMunicipalityValidate(this.caregiverForm);
      } catch (e) {
        this._showError(e);
        return (this.buttonDisabled = false);
      }
    }

    this.validateFields();
    this._registerLoading();

    this.fieldData.intents = JSON.stringify(
      toJS(this.intentsChecked).filter(i => i !== "other")
    );

    this.autoPlanId && (this.fieldData.planId = this.autoPlanId);
    this.autoPromoCode &&
      (this.fieldData.promoCode = this.autoPromoCode.toUpperCase());

    if (!isEmpty(this.invitationData)) {
      this.fieldData["invitationId"] = this.invitationData.id;
      this.fieldData["invitationHash"] = this.invitationData["invitation_hash"];
    }

    await publicIp()
      .then(async ip => await (this.fieldData.ipAddress = ip))
      .catch(console.warn);

    this.fieldData.deviceId = clientController.deviceId;

    // console.log("execRegister");
    // console.log(this.fieldData);

    const normalSignUp = async () =>
      apiService.async("POST", {
        endpoint: endpointConfig.register,
        data: serializeObject(this.fieldData)
      });

    const serviceProviderSignUp = async () => {
      const groupTypeId = Number(this.caregiverFormData.groupTypeId);
      const profileData = { ...this.caregiverFormData, groupTypeId: undefined };
      this.fieldData.intents = JSON.stringify(
        [matchServiceGroupTypeRoleByGroupTypeId(groupTypeId)].filter(Boolean)
      );
      return apiService.async("POST", {
        endpoint: mcbEndpointConfig.provider_signup_no_vet,
        data: {
          user: this.fieldData,
          profile: profileData,
          groupTypeId
        }
      });
    };

    return clientController
      .isMaintenance()
      .then(this.hasOtherServiceProvider ? serviceProviderSignUp : normalSignUp)
      .then(this.postRegister)
      .catch(this._showError)
      .finally(() => (this.registering = false));
  };

  postRegister = response => {
    // console.log(response);
    this._showSuccess();
    this.props.navigation.navigate("Login");
  };

  // Page UI functions.
  handleLinkPress = (link, event) => {
    if (link === "login") this.props.navigation.navigate("Login");
  };

  handleCheckboxChange = event => {
    this.agreeChecked = !this.agreeChecked;
    this.validateFields();
  };

  handleIntentChange = (event, item) => {
    // const checked = this.intentsChecked.includes(item.groupTypeRoleId);
    // checked
    //   ? (this.intentsChecked = this.intentsChecked.filter(
    //       checked => checked !== item.groupTypeRoleId
    //     ))
    //   : this.intentsChecked.push(item.groupTypeRoleId);

    // Allow only one intent to be selected at a time.
    this.intentsChecked = [item.groupTypeRoleId];
    this.validateFields();
    // if (item.groupTypeRoleId === "other") {
    //   if (!checked) this.intentsChecked = ["other"];
    // } else {
    //   if (!checked)
    //     this.intentsChecked = this.intentsChecked.filter(i => i !== "other");
    // }
  };

  openAgreement = () => {
    return clientController.openAgreement();
  };

  reveal = fieldName => {
    this.visible[fieldName] = !this.visible[fieldName];
  };

  helpFocusNextField = (field, formFields, form) =>
    formService.helpFocusNextField(field, formFields, form, this.execRegister);

  onCaregiverFormChange = (e, field) => {
    if (!e) return;
    if (e.nativeEvent && typeof e.nativeEvent.text === "string")
      field.value = e.nativeEvent.text;
    this.validateFields();
  };

  onCaregiverFormBlur = field => {
    field.errorMessage = "";
    if (field.required && !field.value)
      field.errorMessage = `Enter a ${(
        field.label || field.placeholder
      ).toLowerCase()}`;
  };

  handleCaregiverLink = () => {
    const url = "/caregiver";
    if (Platform.OS === "web") {
      window.location.pathname = url;
    } else {
      return Linking.openURL(url);
    }
  };
}
