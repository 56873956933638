import React from "react";
import { Text, View } from "react-native";
import { observer } from "mobx-react";

export const TextIconView = observer(props => {
  return (
    <View
      style={{
        ...props.styles.style,
        ...props.style
      }}
    >
      <View
        style={{
          ...props.styles.background,
          ...(props.backgroundColor && {
            backgroundColor: props.backgroundColor
          })
        }}
      >
        <Text
          style={{
            ...props.styles.text,
            ...props.textStyle
          }}
        >
          {props.value || ""}
        </Text>
      </View>
    </View>
  );
});
