import { theme } from "../../../../config/style-configs/theme";
import { common } from "../../../../config/style-configs/common";
import { responsive } from "../../../../config/style-configs/responsive";
import { computed, decorate } from "mobx";

export const styles = {
  style: {
    flex: 1
  },
  heading: {
    height: "auto",
    padding: 20
  },
  pickerContainer: common.headingPickerContainer,
  get pickerLabel() {
    return {
      ...common.headingPickerLabel,
      ...responsive.styles.headingPickerLabel
    };
  },
  picker: common.headingPicker,
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  scrollViewCard: common.scrollViewCard,
  card: common.card,
  cardText: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    marginVertical: 15
  },
  cardHourlyRate: {
    // fontSize: theme.FONT_SIZE_LARGE,
    fontSize: theme.FONT_SIZE_MEDIUM + 1,
    fontWeight: "600",
    color: "#333",
    textAlign: "center"
  },
  cardAdminUnapprovedLabel: {
    fontSize: theme.FONT_SIZE_SMALL,
    color: "#fff",
    backgroundColor: "#aaa",
    fontWeight: "600",
    marginVertical: 15,
    padding: 5,
    textAlign: "center"
  },
  errorMessage: common.containerErrorMessage,
  loading: common.loading,
  buttonContainer: common.listButtonContainer,
  buttonReminder: common.listButtonReminderContainer,
  criteriaButtonContainer: {
    ...common.listButtonContainer,
    flex: 1,
    padding: 5
  },
  criteriaButtonContainerTop: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  criteriaButton: {
    margin: 5
  },
  mandatoryCriteriaLinkContainer: {
    // flexDirection: "row",
    flexShrink: 0,
    paddingTop: 5,
    paddingRight: 5,
    justifyContent: "flex-start",
    alignItems: "center"
  },
  mandatoryCriteriaLink: {
    color: theme.color,
    fontSize: theme.FONT_SIZE_MEDIUM,
    fontWeight: "600",
    textAlign: "center"
  },
  mandatoryCriteriaTooltip: {
    marginTop: 2
    // position: "absolute",
    // bottom: "-60%",
    // left: "50%",
    // zIndex: 100,
    // transform: [{ translateX: "-50%" }, { translateY: "-50%" }]
  },
  searchMandatoryContainer: {
    flexDirection: "row",
    maxHeight: 42,
    flex: 1,
    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: "center"
  },
  searchMandatoryToggle: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 10,
    padding: 0
  },
  searchMandatoryText: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    fontWeight: "600",
    color: theme.color,
    marginRight: 10
  },
  searchSimplifiedCriteriaText: {
    ...common.link,
    fontSize: theme.FONT_SIZE_MEDIUM,
    margin: 10
  },
  linkBlue: common.linkBlue,
  tncBanner: {
    flex: 1,
    padding: 20
  },
  tncBannerText: {
    fontSize: theme.FONT_SIZE_MEDIUM
  },
  tncBannerButton: {
    marginTop: 10,
    alignSelf: "flex-end"
  }
};

decorate(styles, {
  pickerLabel: computed
});
