import { stateController } from "../controllers/state-controller";
import {
  capitalize,
  getDisplayNameEng,
  getTimeZoneLongName,
  isEmpty,
  preventDefaultStopProp
} from "../../utils/helpers";
import { UIText } from "../../config/lang-config";
import { apiController } from "../controllers/api-controller";
import { apiService } from "../services/api-service";
import { endpointConfig } from "../../config/api-config";
import { clientController } from "../controllers/client-controller";
import NavigationService from "../../utils/navigation-service";

export const popupPlanSubscriptionDetail = (group, subscription) => {
  const getSubStatus = subscription => {
    const status = subscription["planStatus"];
    return status === "trialing"
      ? !!subscription["promoId"]
        ? UIText.planTrialPromoRemaining(true)
        : capitalize(status)
      : capitalize(status);
  };

  const plan = subscription.plan || {};
  return stateController.showPopup({
    title: getDisplayNameEng(group.profile),
    inputSet: [
      {
        title: UIText.planPlanName,
        placeholder: `${UIText.generalNo} ${UIText.planPlanName.toLowerCase()}`,
        value: plan["productName"]
      },
      {
        title: UIText.planInterval,
        placeholder: "⁠—",
        value: plan["nickname"] && plan["nickname"].replace(/\|.*/g, "")
      },
      {
        title: UIText.planPromoCode,
        placeholder: `${
          UIText.generalNo
        } ${UIText.planPromoCode.toLowerCase()}`,
        value: !isEmpty(subscription.promo) && subscription.promo.code
      },
      {
        title: UIText.planStartDate,
        placeholder: "⁠—",
        value:
          subscription.createTime &&
          new Date(subscription.createTime).toLocaleString()
      },
      {
        title: UIText.planSubStatus,
        placeholder: "⁠—",
        value: isEmpty(subscription)
          ? `${UIText.generalNo} ${UIText.planSubscription.toLowerCase()}`
          : getSubStatus(subscription)
      },
      (!!subscription["promoId"] ||
        subscription["planStatus"] === "trialing") && {
        title: UIText.planTrialPromoRemaining(!!subscription["promoId"]),
        placeholder: "⁠—",
        value: `${
          subscription.plan["remainingDays"]
            ? UIText.planTrialRemaining(
                subscription.plan["remainingDays"].toString()
              )
            : ""
        }`
      }
    ]
      .filter(Boolean)
      .map(input => (input.disabled = true) && input),
    leftButtonText: UIText.generalConfirm,
    dismissOnBackPress: true
  });
};

export const getGroupRoles = async ([group, profileDetail, selfRoleTypeId]) => {
  return await apiController.getGroupRolesByGroupId(group.id).then(roles => {
    if (isEmpty(roles)) {
      return Promise.reject({
        message:
          "Something happened and setup was not successful. [Empty roles array]"
      });
    }

    return [group, profileDetail, roles, selfRoleTypeId];
  });
};

export const updateGroupProfile = async (profileId, json) => {
  return apiService.async("PATCH", {
    endpoint: endpointConfig.profile_by_id(profileId),
    data: { data: json }
  });
};

export const submitPaymentPlanChange = async ({
  planSubscription,
  payment,
  customer,
  trial,
  promoCode
}) => {
  if (isEmpty(planSubscription) || isEmpty(payment) || isEmpty(customer))
    return Promise.resolve();

  const groupId = planSubscription.groupId;

  return apiService
    .async("POST", {
      endpoint: endpointConfig.create_payment_plan,
      data: {
        payment,
        planSubscription,
        trial,
        customer,
        promoCode
      }
    })
    .then(() => checkGroupSubscription(groupId));
};

export const submitPaymentChange = async ({
  planSubscription,
  payment,
  customer
}) => {
  if (isEmpty(planSubscription) || isEmpty(payment) || isEmpty(customer))
    return Promise.resolve();

  return apiService.async("POST", {
    endpoint: endpointConfig.update_payment,
    data: {
      planSubscription,
      payment,
      customer
    }
  });
};

export const getUserWCPaymentSources = async () =>
  apiService
    .async("GET", {
      endpoint: endpointConfig.wc_payment_methods
    })
    .then(response => response.data);

export const getWpAutoLoginLink = async redirectUrl =>
  stateController
    .showPopup({
      title: UIText.planBillingPlan,
      content: UIText.pleaseWait
    })
    .then(() =>
      apiService.async("GET", {
        endpoint: endpointConfig.wp_auto_login_link(redirectUrl)
      })
    )
    .then(response => response && response.data);

export const getWpWcBillingDetail = async () =>
  apiService
    .async("GET", {
      endpoint: endpointConfig.wc_billing_detail
    })
    .then(response => response.data);

export const updateWpWcBillingDetail = async data =>
  apiService.async("POST", {
    endpoint: endpointConfig.wc_billing_detail,
    data
  });

export const subscriptionPopup = (typeName, toolName) => {
  const handleBillingPress = event => {
    preventDefaultStopProp(event);

    stateController.viewPlanState.setupMode = false;
    const id = stateController.viewGroupId;
    NavigationService.navigate("Plan", {
      group: id
    });
  };
  return stateController.showPopup({
    title: capitalize(UIText.group),
    content: {
      text: UIText.planNoFeature(typeName, toolName)
      // text: `${UIText.planNoFeature(typeName, toolName)} ${
      //   UIText.marketPlanInfo
      // }`,
      // link: planMarketingUrl
    },
    contentAlign: "left",
    leftButtonText: UIText.planSubscribeNow,
    rightButtonText: UIText.generalConfirm,
    leftButtonPress: () =>
      stateController.dismissPopup().then(handleBillingPress)
  });
};

export const handleGroupBottomTabChange = (groupId, page) => {
  stateController.viewGroupState.bottomTabs[groupId] = page;
};

export const checkGroupSubscription = groupId =>
  apiController.getSubscriptionByGroupId(groupId).then(subscription => {
    clientController.updateSubscription(subscription, groupId);
    return subscription;
  });

export const getGroupTimezoneName = group => {
  const profile = group.profile;
  if (isEmpty(profile)) return UIText.groupUnknownTimeZone;
  return getTimeZoneLongName((profile.data || {}).timezone, true);
};
