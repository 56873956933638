import { common } from "../../../../config/style-configs/common";
import { theme } from "../../../../config/style-configs/theme";
import { ratingReviewStyles } from "../../../../screens/auth/RatingReviewScreen/styles";

export const styles = {
  style: {
    flex: 1
  },
  loading: common.loading,
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  buttonContainer: common.listButtonContainer,
  buttonReminder: common.listButtonReminderContainer,
  errorMessage: common.containerErrorMessage,
  buttonTitleContainer: common.buttonTitleContainer,
  buttonTitle: common.buttonTitle,
  inputTitle: {
    color: theme.color,
    fontSize: theme.FONT_SIZE_MEDIUM,
    marginLeft: 5
  },
  ratingReviewContainer: {
    padding: 10,
    maxWidth: 600,
    marginTop: 15
  },
  ratingReviews: {},
  adminRating: {
    marginBottom: 20,
    paddingBottom: 20
  },
  adminRatingToolbar: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  adminRatingIsPublishedCheckbox: {
    padding: 2,
    marginLeft: 0,
    marginRight: 0,
    margin: 0
  },
  testimonials: {
    marginBottom: 20
  },
  testimonialToolbar: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10
  },
  testimonial: {
    marginVertical: 15,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: theme.color,
    padding: 10
  },
  testimonialInput: {
    width: "100%"
  },
  testimonialButton: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center"
  },
  get testimonialTitle() {
    return {
      ...styles.inputTitle,
      fontWeight: "600"
    };
  },
  testimonialContent: {
    flexDirection: "column"
  },
  get testimonialContentText() {
    return {
      ...styles.inputTitle,
      color: "#333",
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 10
    };
  },
  get testimonialDate() {
    return {
      ...styles.inputTitle,
      fontSize: theme.FONT_SIZE_SMALL,
      marginBottom: 0
    };
  },
  starRatings: ratingReviewStyles.starRatings,
  starRatingDescription: ratingReviewStyles.starRatingDescription
};
