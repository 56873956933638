export const styles = {
  style: {
    display: "flex",
    minHeight: 40,
    alignItems: "center"
  },
  placeholder: {
    fontWeight: "600",
    textDecorationLine: "underline"
  }
};
