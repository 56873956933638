import React from "react";
import { Image, ScrollView, View } from "react-native";
import { Observer, observer } from "mobx-react";
import { theme } from "../../../config/style-configs/theme";
import { ThemedInput } from "../../../components/ThemedInput";
import { UIText } from "../../../config/lang-config";
import Icon from "react-native-vector-icons/MaterialIcons";
import { CheckBox, Divider, Text } from "react-native-elements";
import { ThemedButton } from "../../../components/ThemedButton";

@observer
class CaregiverPostInterviewView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentDidMount() {
    return this.controller.componentDidMount();
  }

  renderReferenceField = (reference, i, references) => {
    let next;
    return (
      <Observer key={reference.id}>
        {() => (
          <View style={this.props.styles.fields}>
            <View style={this.props.styles.fields}>
              <View style={this.props.extraStyles.referenceHeader}>
                <Text style={this.props.styles.inputTitle}>
                  {UIText.caregiverReference} {i + 1}
                </Text>
                <Icon
                  name="close"
                  size={24}
                  color={theme.color}
                  onPress={e => this.controller.removeReference(reference.id)}
                />
              </View>
              <ThemedInput
                placeholder={UIText.caregiverReferenceContactName}
                returnKeyType="next"
                value={reference.name}
                onChange={e => {
                  this.controller.updateReference(reference.id, "name", e);
                }}
                onSubmitEditing={() => {
                  next.focus();
                }}
              />
            </View>
            <View style={this.props.styles.fields}>
              <ThemedInput
                ref={ref => (next = ref)}
                placeholder={UIText.caregiverReferenceContactInfoPlaceholder}
                returnKeyType="next"
                value={reference.contact}
                onChange={e => {
                  this.controller.updateReference(reference.id, "contact", e);
                }}
              />
            </View>
            {i !== references.length - 1 && (
              <Divider style={this.props.styles.divider} />
            )}
          </View>
        )}
      </Observer>
    );
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        <ScrollView
          style={this.props.styles.scrollView}
          contentContainerStyle={this.props.styles.container}
        >
          <View style={this.props.styles.header}>
            <Image
              source={theme.loginLogo}
              style={this.props.styles.headerLogo}
            />
          </View>

          {!this.controller.loading && (
            <>
              <View style={this.props.styles.questions}>
                <Text style={this.props.styles.questionHeader}>
                  {UIText.caregiverAddReference}
                </Text>
              </View>

              {this.controller.references.map(this.renderReferenceField)}
              <View style={this.props.styles.container}>
                <ThemedButton
                  style={this.props.styles.button}
                  icon="add"
                  title={UIText.caregiverAddReferenceItem}
                  iconSize={22}
                  width={50}
                  onPress={this.controller.addReference}
                />
              </View>

              <View
                style={{
                  ...this.props.extraStyles.agreement,
                  marginTop: 30
                }}
              >
                <CheckBox
                  containerStyle={this.props.styles.checkbox}
                  checkedIcon={
                    <Icon
                      name="radio-button-checked"
                      size={24}
                      color={theme.color}
                    />
                  }
                  uncheckedIcon={
                    <Icon
                      name="radio-button-unchecked"
                      size={24}
                      color="black"
                    />
                  }
                  checked={this.controller.emailedChecked}
                  checkedColor={theme.color}
                  onPress={this.controller.handleEmailedCheckboxChange}
                />
                <View style={this.props.extraStyles.checkboxLabel}>
                  <Text
                    style={this.props.styles.checkboxLabelText}
                    onPress={this.controller.handleEmailedCheckboxChange}
                  >
                    I have emailed a recent criminal background check to{" "}
                    <Text
                      style={this.props.styles.checkboxLabelLink}
                      onPress={this.controller.openEmail}
                    >
                      stephanie@mycarebase.com
                    </Text>
                    .
                  </Text>
                </View>
              </View>

              <View style={this.props.extraStyles.regTypeDividerContainer}>
                <Divider style={this.props.styles.regTypeDivider} />
                <Text style={this.props.styles.regTypeDividerText}>
                  {UIText.invitationRegDivider}
                </Text>
                <Divider style={this.props.styles.regTypeDivider} />
              </View>

              <View style={this.props.extraStyles.agreement}>
                <CheckBox
                  containerStyle={this.props.styles.checkbox}
                  checkedIcon={
                    <Icon
                      name="radio-button-checked"
                      size={24}
                      color={theme.color}
                    />
                  }
                  uncheckedIcon={
                    <Icon
                      name="radio-button-unchecked"
                      size={24}
                      color="black"
                    />
                  }
                  checked={this.controller.backgroundChecked}
                  checkedColor={theme.color}
                  onPress={this.controller.handleBackgroundCheckboxChange}
                />
                <View style={this.props.extraStyles.checkboxLabel}>
                  <Text
                    style={this.props.styles.checkboxLabelText}
                    onPress={this.controller.handleBackgroundCheckboxChange}
                  >
                    I have completed a criminal background check through{" "}
                    <Text
                      style={this.props.styles.checkboxLabelLink}
                      onPress={this.controller.openBackCheck}
                    >
                      Sterling Backcheck
                    </Text>
                    .
                  </Text>
                </View>
              </View>

              <View style={this.props.styles.questions}>
                <ThemedButton
                  style={this.props.styles.button}
                  disabled={
                    this.controller.buttonDisabled || this.controller.submitting
                  }
                  title={UIText.generalSubmit}
                  loading={this.controller.submitting}
                  onPress={this.controller.execSubmit}
                />
              </View>
            </>
          )}
        </ScrollView>
      </View>
    );
  }
}

export { CaregiverPostInterviewView };
