import React from "react";
import { ChatScreen } from "./view";
import { ChatController } from "./controller";
import { styles } from "./styles";

export class Chat extends React.Component {
  static navigationOptions = {
    title: "Chat"
  };

  controller = new ChatController(this.props);

  render() {
    return (
      <ChatScreen
        {...this.props}
        styles={styles}
        controller={this.controller}
      />
    );
  }
}
