import { computed, observable } from "mobx";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { UIText } from "../../../../config/lang-config";
import { clientController } from "../../../../cdm/controllers/client-controller";
import { apiController } from "../../../../cdm/controllers/api-controller";
import { apiService } from "../../../../cdm/services/api-service";
import { mcbEndpointConfig } from "../../config/api-config";
import {
  evalStringBoolean,
  isEmpty,
  minutesToMilli
} from "../../../../utils/helpers";
import {
  env,
  groupTypeRoleIds,
  serviceRecipientGroupTypeIds
} from "../../../../config/variable-config";
import { isSnSOrAdmin } from "../../utils/helper";
import { lockTopic } from "../../../../cdm/lib/topic-utilities";
import { paidCaregiverFeatureRestricted } from "../../lib/group-utilities-mcb";

export class ShiftSummaryController {
  @observable lastScreen;

  @observable buttonReady = false;
  @observable loading = true;
  @observable approveLoading = false;
  @observable disapproveLoading = false;

  @observable reportData = {};
  @observable reportHtml = "";
  @observable approvalGiven = false;
  @observable hasHourlyRate = false;
  // @observable isApproved = false;

  @computed get user() {
    return clientController.client.user || {};
  }
  @computed get isVisitor() {
    return clientController.isVisitor;
  }
  @computed get isAdmin() {
    return this.lastScreen === "AdminCentre" || isSnSOrAdmin(this.user);
  }
  @computed get groupId() {
    return Number(
      this.props.navigation.getParam("group") || stateController.viewGroupId
    );
  }
  @computed get group() {
    return clientController.findGroupById(this.groupId);
  }
  @computed get selfMember() {
    return (
      (Array.isArray(this.group.members) &&
        this.group.members.find(m => m.userId === clientController.userId)) ||
      {}
    );
  }
  @computed get myRole() {
    const roles = (Array.isArray(this.group.roles) && this.group.roles) || [];
    return (
      roles.find(r => {
        return (
          Array.isArray(r.members) &&
          r.members.find(m => m.id === this.selfMember.id)
        );
      }) || {}
    );
  }
  @computed get caregiverId() {
    return Number(this.props.navigation.getParam("caregiver"));
  }
  @computed get caregiverMember() {
    return clientController.findMemberById(this.caregiverId);
  }

  // Viewing report of myself's
  @computed get isSelf() {
    return this.caregiverId === this.selfMember.id;
  }
  @computed get startDate() {
    return Number(this.props.navigation.getParam("startDate"));
  }
  @computed get endDate() {
    return Number(this.props.navigation.getParam("endDate"));
  }
  @computed get timesheetId() {
    return Number(this.props.navigation.getParam("timesheetId"));
  }
  @computed get approvalDateAvailable() {
    return (
      new Date(this.endDate).getTime() + minutesToMilli(5) <
      new Date().getTime()
    );
  }
  @computed get approvalAvailable() {
    return (
      !this.isSelf &&
      !isEmpty(this.group) &&
      this.timesheetId !== -1 &&
      (env.match(/dev/g) || // TODO: This is for testing, to be enabled.
      env.match(/test/g) || // TODO: This is for testing, to be enabled.
        this.approvalDateAvailable) &&
      this.hasHourlyRate &&
      this.caregiverMember.roleList.some(
        r => r.groupTypeRoleId === groupTypeRoleIds.paidCaregiver
      )
    );
  }
  @computed get disapproveDate() {
    return (
      this.reportDataTopics[0] &&
      this.reportDataTopics[0]["longDisapprovedTime"]
    );
  }
  @computed get disapprovalAvailable() {
    return !this.approvalGiven && !this.disapproveDate;
  }

  @computed get reportRequestData() {
    return {
      groupId: this.groupId,
      caregiverId: this.caregiverId,
      startDate: this.startDate,
      endDate: this.endDate,
      timesheetId: this.timesheetId
    };
  }

  @computed get reportDataTopics() {
    return this.reportData.detail || [];
  }

  constructor(props) {
    this.props = props;
    this.lastScreen = stateController.currentScreen;
    stateController.currentScreen = "ShiftSummary";

    this._initialize();
  }

  componentWillUnmount = () => {
    this.disposer && this.disposer();
  };

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: UIText.reportCompletedShift,
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _initialize = async () => {
    const validParams =
      this.groupId &&
      this.caregiverId &&
      ((this.startDate && this.endDate) || this.timesheetId);
    if (!validParams) return this.handleBackPress();

    this.loading = true;

    return this._getGroups()
      .then(this._getReportData)
      .then(this._calculateApprovalState)
      .then(this._getHtmlContent)
      .catch(this._showError)
      .finally(() => (this.loading = false));
  };

  _getGroups = async () => {
    if (!stateController.viewGroupId && clientController.loginState)
      stateController.viewGroupId = Number(
        this.props.navigation.getParam("group")
      );

    if (
      (this.isVisitor ||
        isEmpty(this.group) ||
        !serviceRecipientGroupTypeIds.includes(this.group.typeId) ||
        paidCaregiverFeatureRestricted(this.group, this.selfMember)) &&
      !this.isAdmin
    ) {
      stateController.viewGroupId = 0;
      return this.handleBackPress();
    }

    return Promise.resolve();
  };

  _getReportData = async () => {
    return apiController
      .getReportCompletedShiftSummary(this.reportRequestData)
      .then(reportData => {
        this.reportData = reportData;
        return reportData;
      });
  };

  _calculateApprovalState = async data => {
    let shifts = (Array.isArray(data.detail) && data.detail) || [];

    shifts = shifts.map(shift => {
      shift["isApproved"] = evalStringBoolean(shift["isApproved"]);
      return shift;
    });

    this.approvalGiven =
      // !shifts.some(shift => shift["isApproved"] === "unset") && // There should not be any "unset".
      // !shifts.some(
      //   shift =>
      //     (!!shift["isApproved"] && shifts.some(s => !s["isApproved"])) || // There should not be any approved and disapproved at the same time.
      //     (!shift["isApproved"] && shifts.some(s => !!s["isApproved"])) // There should not be any disapproved and approved at the same time.
      // ) &&
      // !shifts.some(shift => !shift["isApproved"]); // If not approved then still light up the buttons.
      shifts.filter(
        shift =>
          typeof shift["isApproved"] === "boolean" && !!shift["isApproved"]
      ).length === shifts.length;

    this.hasHourlyRate = shifts.some(shift => !!shift.hourlyRate);

    return data;
  };

  _getHtmlContent = async data => {
    // data.timezone = defaultLocalizedTimezoneName;
    return apiController
      .getParsedHtmlTemplate("shiftSummary", data)
      .then(html => (this.reportHtml = html))
      .then(() => (this.buttonReady = true));
  };

  handleBackPress = event => {
    return this.props.navigation.navigate(this.lastScreen || "Group");
  };

  handleApprovalPress = event => {
    const data = {
      ...this.reportRequestData,
      isApproved: true
    };

    this.approveLoading = true;
    return this.submitApproval(data)
      .then(() =>
        this.reportDataTopics
          .map(t => {
            t.id = t.topicId;
            return t;
          })
          .map(lockTopic)
      )
      .catch(this._showError)
      .finally(() => (this.approveLoading = false));
  };

  handleDisapprovalPress = event => {
    const data = {
      ...this.reportRequestData,
      isApproved: false
    };

    this.disapproveLoading = true;
    return this.submitApproval(data)
      .catch(this._showError)
      .finally(() => (this.disapproveLoading = false));
  };

  submitApproval = data => {
    this.buttonReady = false;

    const { isApproved } = data;

    const execApproval = async () =>
      stateController
        .showPopup({
          title: UIText.reportCompletedShift,
          content: UIText.pleaseWait
        })
        .then(() =>
          apiService.async("POST", {
            endpoint: mcbEndpointConfig.shift_summary_approval_disapproval,
            data
          })
        )
        .then(console.log)
        .then(this._initialize)
        .then(stateController.dismissPopup);

    return new Promise((resolve, reject) => {
      stateController.showPopup({
        title: UIText.reportCompletedShift,
        content: UIText.reportCompletedShiftConfirm(isApproved),
        leftButtonText: UIText.generalNo,
        leftButtonPress: () => stateController.dismissPopup().then(resolve),
        rightButtonText: UIText.generalYes,
        rightButtonPress: () =>
          stateController
            .dismissPopup()
            .then(execApproval)
            .then(resolve)
            .catch(reject),
        onBackdropPress: () => stateController.dismissPopup().then(resolve)
      });
    }).finally(() => (this.buttonReady = true));
  };

  onRefresh = () => {
    return this._initialize();
  };

  onScroll = event => {};
}
