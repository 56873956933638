import { common } from "../../../../config/style-configs/common";

export const styles = {
  style: {
    flex: 1
  },
  groupRoleDetails: {
    alignItems: "flex-start",
    paddingHorizontal: 15,
    paddingVertical: 20
  },
  groupRoleHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingHorizontal: 5,
    marginBottom: 10
  },
  buttonContainerHelp: common.listButtonContainerWithHelp,
  groupRoleHeaderButton: {},
  groupRoleRefreshButton: common.listRefreshButton,
  loading: common.loading
};
