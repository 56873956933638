import { common } from "../../config/style-configs/common";
import { theme } from "../../config/style-configs/theme";

export const styles = {
  style: {
    marginBottom: 5,
    width: "100%"
  },
  listItem: {
    paddingVertical: 10,
    paddingHorizontal: 10
  },
  heading: common.collapsibleTitleWrapper,
  headingText: common.collapsibleTitleText,
  rightContentContainer: { alignItems: "center" },
  rightTitleContainer: {
    maxWidth: 100,
    minWidth: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center"
  },
  rightTitle: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    color: "#666"
  },
  buttonSize: theme.FONT_SIZE_XLARGE,
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
    // maxWidth: 80
  },
  button: {
    width: 40,
    height: 40
  }
};
