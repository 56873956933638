import React from "react";
import {
  ActivityIndicator,
  Platform,
  RefreshControl,
  ScrollView,
  View
} from "react-native";
import { observer, Observer } from "mobx-react";
import { theme } from "../../../../config/style-configs/theme";
import { TopicCard } from "../../../../components/TopicCard/index";
import { UIText } from "../../../../config/lang-config";
import { Text } from "react-native-elements";
import { getCcTopicContactString } from "../../utils/helper";
import { ListRefreshButtonWeb } from "../../../../components/ListRefreshButtonWeb";
import { fileService } from "../../../../cdm/services/file-service";

@observer
class CaregiverEEListView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  renderTopics = topics => (
    <View style={this.props.styles.scrollViewCard}>
      {topics.map(topic => this.renderTopic(topic))}
    </View>
  );

  renderTopic = topic => {
    // const displayName = getDisplayNameEng(profile);
    const cCContactString = getCcTopicContactString(topic);
    const icons = [
      {
        key: "message",
        name: !!topic.lastPublicMessageTime ? "message-text" : "message",
        styles: { paddingTop: 4 },
        onPress: () => this.controller.openChat(topic.id)
      },
      {
        key: "profile",
        name: "account-card-details",
        onPress: () => this.controller.handleCheckboxPress(topic, group)
      }
    ];

    const group = topic.group;
    const profile = topic.gProfile;

    const children = (
      <View style={this.props.styles.cardContent}>
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={this.props.styles.cardContentMedium}
        >
          {group["groupTypeName"]}
        </Text>
        <Text
          numberOfLines={topic.isCompleted ? 1 : 2}
          ellipsizeMode="tail"
          style={{
            ...this.props.styles.cardContentMedium,
            fontWeight: "normal"
          }}
        >
          {topic.isCompleted
            ? UIText.groupCaregiverEETopicArchived
            : UIText.inboxLastMessageAt(
                `\n${new Date(topic["lastPublicMessageTime"]).toLocaleString()}`
              )}
        </Text>
      </View>
    );

    return (
      <Observer key={topic.id}>
        {() => (
          <View style={this.props.styles.card}>
            <TopicCard
              compact={true}
              // title={displayName}
              title={`Contact: ${cCContactString}`}
              avatarTitle={cCContactString}
              titleId={group.profileId}
              image={fileService.getProfileAvatarUri(
                profile.avatar,
                group.id,
                "group"
              )}
              pending={topic.loading}
              customIcons={icons}
              onPress={() => this.controller.handleTopicPress(topic)}
            >
              {children}
            </TopicCard>
          </View>
        )}
      </Observer>
    );
  };

  render() {
    return (
      <View style={this.props.styles.style}>
        {(this.controller.loading || this.controller.refreshing) &&
        Platform.OS === "web" ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          <ScrollView
            contentContainerStyle={this.props.styles.scrollView}
            refreshControl={
              <RefreshControl
                refreshing={
                  this.controller.refreshing || this.controller.loading
                }
                onRefresh={this.controller.onRefresh}
              />
            }
          >
            <View style={this.props.styles.scrollViewContent}>
              <View style={this.props.styles.buttonContainer}>
                {!this.controller.loading && (
                  <ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />
                )}
              </View>
              {this.controller.shouldRenderTopics.length > 0 ? (
                this.renderTopics(this.controller.shouldRenderTopics)
              ) : (
                <Text style={this.props.styles.errorMessage}>
                  {UIText.groupCaregiverEENoTopic}
                </Text>
              )}
            </View>
          </ScrollView>
        )}
      </View>
    );
  }
}

export { CaregiverEEListView };
