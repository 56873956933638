import React from "react";
import {
  ActivityIndicator,
  Platform,
  RefreshControl,
  ScrollView,
  View
} from "react-native";
import { observer, Observer } from "mobx-react";
import { theme } from "../../../../config/style-configs/theme";
import { ShiftCard } from "../ShiftCard";
import { ListFilter } from "../../../../components/ListFilter";
import { Button, Icon, Text } from "react-native-elements";
import { UIText } from "../../../../config/lang-config";
import {
  getTopicAssignedMembers,
  getTopicCompletionState
} from "../../../../cdm/lib/topic-utilities";
import { ListFilterIndicator } from "../../../../components/ListFilterIndicator";
import { CollapsibleAccordionTitle } from "../../../../components/CollapsibleAccordionTitle";
import Collapsible from "react-native-collapsible";
import { ShiftCalendar } from "../ShiftCalendar";
import VizSensor from "react-visibility-sensor";

@observer
class CareReceiverShiftListView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  componentWillUnmount() {
    return this.controller.componentWillUnmount();
  }

  renderTopics = topics => {
    if (!Array.isArray(topics) || topics.length === 0) {
      return (
        <Text style={this.props.styles.errorMessage}>
          {UIText.groupNoShiftToShow}
        </Text>
      );
    }

    let newTopics = topics.filter(t => getTopicCompletionState(t) === "new");
    let clockedInTopics = topics.filter(
      t => getTopicCompletionState(t) === "clocked-in"
    );
    let clockedOutTopics = topics.filter(
      t => getTopicCompletionState(t) === "clocked-out"
    );

    newTopics = newTopics.length > 0 && this.controller.sortShifts(newTopics);
    clockedInTopics =
      clockedInTopics.length > 0 && this.controller.sortShifts(clockedInTopics);
    clockedOutTopics =
      clockedOutTopics.length > 0 &&
      this.controller.sortShifts(clockedOutTopics);

    return (
      <View style={this.props.styles.scrollViewCollapsible}>
        {clockedInTopics &&
          this.renderTopicGroups(
            clockedInTopics,
            UIText.shiftPartiallyComplete
          )}
        {newTopics && this.renderTopicGroups(newTopics, UIText.shiftNewShifts)}
        {clockedOutTopics &&
          this.renderTopicGroups(clockedOutTopics, UIText.shiftCompleted)}
      </View>
    );
  };

  renderTopicGroups = (topics, type) => {
    return (
      <Observer>
        {() => (
          <View style={this.props.styles.scrollViewCollapsibleCards}>
            <CollapsibleAccordionTitle
              title={type}
              onTitlePress={() => this.controller.handleCollapse(type)}
              collapsed={this.controller.collapsible[type]}
            />
            <Collapsible
              style={this.props.styles.scrollViewCard}
              collapsed={!!this.controller.collapsible[type]}
            >
              {topics.map(topic => this.renderTopic(topic))}
            </Collapsible>
          </View>
        )}
      </Observer>
    );
  };

  renderTopic = topic => {
    const clockState = getTopicCompletionState(topic);
    const couldClockInOut = this.controller.getShiftClockCapability(topic);
    const clockers = getTopicAssignedMembers(topic);

    return (
      <Observer key={topic.id}>
        {() => {
          const shiftCard = (
            <ShiftCard
              timezone={this.controller.timezone}
              shift={topic}
              clockState={clockState}
              clockers={clockers}
              couldClockInOut={couldClockInOut}
              onClockPress={e => this.controller.handleClockInClockOut(topic)}
              onPress={e => this.controller.handleTopicPress(topic)}
            />
          );
          return (
            <View style={this.props.styles.card}>
              {Platform.OS === "web" ? (
                <VizSensor
                  partialVisibility
                  onChange={visible =>
                    visible && this.controller.checkCompleteShiftData(topic)
                  }
                >
                  {shiftCard}
                </VizSensor>
              ) : (
                shiftCard
              )}
            </View>
          );
        }}
      </Observer>
    );
  };

  renderShiftList = () => (
    <Observer>
      {() => (
        <View style={this.props.styles.style}>
          {(this.controller.loading || this.controller.refreshing) &&
          Platform.OS === "web" ? (
            <View style={this.props.styles.loading}>
              <ActivityIndicator size="large" color={theme.color} />
            </View>
          ) : (
            <ScrollView
              contentContainerStyle={this.props.styles.scrollView}
              refreshControl={
                <RefreshControl
                  refreshing={
                    this.controller.refreshing || this.controller.loading
                  }
                  onRefresh={this.controller.onRefresh}
                />
              }
            >
              <View style={this.props.styles.scrollViewContent}>
                <View style={this.props.styles.buttonContainer}>
                  <ListFilter
                    data={this.controller.topics}
                    categories={this.controller.filterCategories}
                    selectedCriterion={this.controller.filterCriterion}
                    filterSetter={this.controller.setFilter}
                  />
                  {/*<ThemedButton*/}
                  {/*title={UIText.shiftCalendarEntryButton}*/}
                  {/*onPress={this.controller.handleShiftCalendarPress}*/}
                  {/*/>*/}
                  <Button
                    icon={
                      <Icon
                        size={24}
                        name="calendar-blank"
                        color={theme.color}
                        type="material-community"
                      />
                    }
                    type="clear"
                    title={UIText.shiftCalendarEntryButton}
                    titleStyle={this.props.styles.buttonTitle}
                    onPress={this.controller.handleShiftCalendarPress}
                  />
                  {/*<ListRefreshButtonWeb onRefresh={this.controller.onRefresh} />*/}
                </View>
                <ListFilterIndicator
                  length={this.controller.filteredTopics.length}
                  categories={this.controller.filterCategories}
                />
                <View style={this.props.styles.buttonReminder}>
                  <Text style={this.props.styles.errorMessage}>
                    {UIText.shiftMakerCorrectEndTimeReminder}
                  </Text>
                </View>
                {this.renderTopics(this.controller.filteredTopics)}
              </View>
            </ScrollView>
          )}
        </View>
      )}
    </Observer>
  );

  renderCalendar = () => (
    <ShiftCalendar
      getTopics={() => this.controller.topics}
      onRefresh={this.controller.onRefresh}
    />
  );

  render() {
    return this.controller.mode === "shifts"
      ? this.renderShiftList()
      : this.controller.mode === "calendar"
      ? this.renderCalendar()
      : null;
  }
}

export { CareReceiverShiftListView };
