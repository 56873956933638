import { getDisplayNameEng, isEmpty } from "../../../utils/helpers";
import { env } from "../../../config/variable-config";
import { endpointConfig } from "../../../config/api-config";
import { Linking, Platform } from "react-native";

export const getCcTopicContactString = topic => {
  const memberId = topic.creatorMemberId;
  const member =
    Array.isArray(topic.members) && topic.members.find(m => m.id === memberId);
  const profile = member && member.profile && member.profile.data;
  if (isEmpty(profile)) return "";
  return getDisplayNameEng(profile);
};

export const isSnSOrAdmin = user => user && user.isSa;

// export const isSnSOrAdmin = user => user &&
// (user.userName === "steve" ||
//   user.userName === "lucy" ||
//   user.userName === "felix" ||
//   user.userName === "happy" ||
//   user.userName === "happy5" ||
//   user.userName === "happy8" ||
//   user.userName === "happy10" ||
//   user.userName === "leonli" ||
//   user.userName === "liang" ||
//   user.userName === "stephanie");

export const isDevAdmin = user =>
  user &&
  (user.userName === "felix" ||
    user.userName === "happy" ||
    user.userName === "happy5" ||
    user.userName === "happy8" ||
    user.userName === "happy10" ||
    user.userName === "leonli" ||
    user.userName === "urmandke18" ||
    user.userName === "urmandke25" ||
    user.userName === "liang" ||
    user.isSa);

export const is3FreePromo = promo =>
  !isEmpty(promo) && promo.id >= 19 && promo.id <= 33;

export const FCFilter = g =>
  env !== "prod" ||
  (g.member &&
    g.member.email &&
    !g.member.email.match(/(kefelan.com|kitsilanofc.com)/g));

export const openCAF = () => {
  const url = `https://${endpointConfig.wp_hostname}/finder`;
  if (Platform.OS === "web") {
    return window.open(url);
  } else {
    return Linking.openURL(url);
  }
};

export const minimizeString = input =>
  input
    ? input
        .toLowerCase()
        .replace(/[^a-z0-9]/gi, "")
        .trim()
    : "";
