import React from "react";
import { CareReceiverSmryListView } from "./view";
import { CareReceiverSmryListController } from "./controller";
import { styles } from "./styles";

export class CareReceiverSmryList extends React.Component {
  render() {
    return (
      <CareReceiverSmryListView
        {...this.props}
        controller={new CareReceiverSmryListController(this.props)}
        styles={styles}
      />
    );
  }
}
