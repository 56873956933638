import React from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import { AndroidBackHandler } from "react-navigation-backhandler";
import { CheckBox, Text } from "react-native-elements";
import { Header } from "../../../components/Header";
import Icon from "react-native-vector-icons/MaterialIcons";
import { Observer, observer } from "mobx-react";
import { theme } from "../../../config/style-configs/theme";
import { ThemedButton } from "../../../components/ThemedButton";
import { UIText } from "../../../config/lang-config";
import { Form } from "../../../components/Form";
import { findNodeHandle } from "react-native-web";
import { computedFn } from "mobx-utils";
import { action, computed } from "mobx";

@observer
class SetupScreen extends React.Component {
  @computed get fieldProps() {
    return {
      showLabel: this.controller.showFieldTitle,
      showTitle: true,
      enabledGetter: computedFn(field => !field.disabled),
      valueGetter: computedFn(field =>
        field.value
          ? field.value
          : this.controller.showFieldTitle
          ? field._disabled
            ? "—"
            : ""
          : ""
      ),
      avatarTitleGetter: computedFn(field =>
        this.controller.getAvatarTitle(this.controller.currentForm)
      ),
      avatarColorIdGetter: computedFn(field =>
        this.controller.getAvatarColorId(this.controller.currentForm)
      ),
      onInputChange: action((e, field) => {
        this.controller.onFieldChange(field, e);
      }),
      onCheckboxChange: action(field => {
        field.value = !field.value;
      }),
      onPickerChange: action((value, field) => {
        field.value = value;
      }),
      onDateTimeChange: action((value, field) => {
        field.value = value;
      }),
      onAvatarPickerChange: this.controller.onAvatarPickerChange,
      onAvatarRemoval: action(field => {
        field.value = null;
      }),
      onEndEditing: action((form, field) => {
        this.controller.onFieldBlur(field);
      }),
      onBlur: action((form, field) => {
        this.controller.onFieldBlur(field);
      }),
      onSubmitEditing: action((form, field) => {
        this.controller.helpFocusNextField(field, this.formFields, form);
      }),
      refSetter: (form, field, f) => {
        f && (f.name = field.name);
        this.formFields[form.indexOf(field)] = f;
      },
      fieldStyle: this.props.styles.field,
      fieldLabelStyle: this.props.styles.fieldLabel,
      pickerStyle: this.props.styles.picker,
      checkboxStyle: this.props.styles.checkbox
    };
  }

  constructor(props) {
    super(props);
    this.controller = props.controller;
    this.formFields = [];
    this.controller.setScrollPosCallback(this.scrollTo);
    this.controller.setScrollToTopCallback(this.scrollToTop);
    this.controller.setScrollToFieldCallback(this.scrollToField);
  }

  scrollTo = args => this.scrollView.scrollTo(args);

  scrollToTop = () =>
    this.scrollView && this.scrollTo({ y: 0, animated: false });

  scrollToField = fieldName => {
    const field = this.formFields.find(f => f && f.name === fieldName);
    const fieldElm = findNodeHandle(field);
    if (fieldElm)
      fieldElm.scrollIntoView({
        behavior: "smooth"
      });
    // if (field && typeof field.measure === "function")
    //   field.measure((fx, fy, w, h, px, py) => {
    //     this.scrollTo({ y: py - 90 });
    //   });
  };

  // Checkboxes
  renderSetupOptions = setups => {
    return setups.map((setup, i) => (
      <CheckBox
        containerStyle={this.props.styles.setupSelections}
        key={i}
        title={setup.checkboxTitle}
        checkedIcon={<Icon name="check-box" size={24} color="black" />}
        uncheckedIcon={
          <Icon name="check-box-outline-blank" size={24} color="black" />
        }
        checked={setup.checked}
        checkedColor={theme.color}
        onPress={() => {
          setup.checked = !setup.checked;
          if (setup.require && setup.checked) {
            this.controller.selectSetup(setup.require);
          }
          const shouldDeselect = this.controller.setups.find(
            s => s.require === setup.key
          );
          if (shouldDeselect) {
            this.controller.deselectSetup(shouldDeselect.key);
          }
        }}
      />
    ));
  };

  // Checkbox page footer
  renderSetupFooter = setup =>
    setup.footerNote ? (
      <View style={this.props.styles.footerNoteContainer}>
        <Text style={this.props.styles.footerNote}>{setup.footerNote}</Text>
      </View>
    ) : null;

  // Page objects
  getSetupPage = setup => {
    return setup
      ? [
          {
            title: setup.title,
            heading: setup.heading,
            component: this.renderSetupForm()
          }
        ]
      : [];
  };

  renderSetupForm = () => (
    <Observer>
      {() => (
        <Form
          containerStyle={this.props.styles.form}
          form={this.controller.currentForm}
          renderFlags={this.controller.currentSetup.renderFlags}
          editFlags={this.controller.currentSetup.editFlags}
          fieldProps={this.fieldProps}
          expandAll
          anchorDisabled
        />
      )}
    </Observer>
  );

  // Page views
  renderSetupPage = page => (
    <View style={this.props.styles.style}>
      <Header
        title={page.title}
        leftIcon={
          this.controller.currentSetupPage < this.pages.length - 1 &&
          this.controller.backVisible &&
          (this.controller.backIcon || "arrow-back")
        }
        handleLeftPress={this.controller.back}
        // leftComponent={
        //   this.controller.currentSetupPage < this.pages.length - 1 &&
        //   this.controller.backVisible ? (
        //     <Button
        //       type="clear"
        //       icon={
        //         <Icon
        //           name={this.controller.backIcon || "arrow-back"}
        //           color="#fff"
        //           size={25}
        //         />
        //       }
        //       onPress={this.controller.back}
        //     />
        //   ) : null
        // }
      />

      <ScrollView
        ref={scrollView => (this.scrollView = scrollView)}
        style={this.props.styles.setupScrollView}
        contentContainerStyle={this.props.styles.setupComponent}
        // onContentSizeChange={this.scrollToTop}
      >
        <View style={this.props.styles.topSection}>
          <Text style={this.props.styles.heading}>{page.heading}</Text>
        </View>
        {page.component}
        <View style={this.props.styles.button}>
          <ThemedButton
            visible={this.controller.nextVisible}
            title={UIText.generalNext}
            disabled={this.controller.nextDisabled}
            onPress={this.controller.next}
          />
        </View>
      </ScrollView>
    </View>
  );

  render() {
    if (!Array.isArray(this.controller.setups)) {
      return <View />;
    }

    this.pages = [
      // First page.
      {
        title: this.controller.setup.initialTitle,
        heading: this.controller.setup.initialHeading,
        component: (
          <View>
            {this.renderSetupOptions(this.controller.setups)}
            {this.renderSetupFooter(this.controller.setup)}
          </View>
        )
      },

      ...this.getSetupPage(this.controller.currentSetup),

      // Finish page.
      {
        title: this.controller.setup.finishTitle || UIText.setupComplete,
        heading:
          this.controller.setup.finishHeading || UIText.setupGeneralFinishMsg,
        component: (
          <View style={this.props.styles.button}>
            <ThemedButton
              title={UIText.setupFinish}
              onPress={this.controller.finishSetup}
            />
          </View>
        )
      }
    ];

    return (
      <View style={this.props.styles.style}>
        {this.controller.loading ? (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
          </View>
        ) : (
          this.renderSetupPage(this.pages[this.controller.currentSetupPage])
        )}

        <AndroidBackHandler onBackPress={this.controller.back} />
      </View>
    );
  }
}

export { SetupScreen };
