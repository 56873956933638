import { Root } from "../../screens/app/RootScreen";
import { GroupTopic } from "../../navigators/app/GroupTopicNavigator";
import { Setup } from "../../screens/app/SetupScreen";
import { Test } from "../../screens/app/TestScreen";
import { Inbox } from "../../screens/app/InboxScreen";
import { AdminCentre } from "../../custom/mcb/screens/AdminCentreScreen";
import { AdminInterviewBooking } from "../../custom/mcb/screens/AdminInterviewBookingScreen";

export const routeConfig = {
  Root,
  Group: {
    screen: GroupTopic,
    path: "Group"
  },
  Setup,
  Test,
  Inbox,
  AdminCentre,
  AdminInterviewBooking
};

export const webNavigatorConfig = {
  // initialRouteName: "Group"
};
