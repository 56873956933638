import { theme } from "../../config/style-configs/theme";
import { common } from "../../config/style-configs/common";

export const styles = {
  buttonReminder: common.listButtonReminderContainer,
  errorMessage: {
    ...common.containerErrorMessage,
    textAlign: "start"
  },
  field: { paddingVertical: 10 },
  fieldLabel: {
    marginBottom: 0,
    fontSize: theme.FONT_SIZE_LARGE
  },
  stripeElements: {
    padding: 10,
    marginBottom: 10,
    maxWidth: 600
  }
};
