import { common } from "../../../../config/style-configs/common";

export const styles = {
  style: {
    flex: 1
  },
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  scrollViewCard: common.scrollViewCard,
  loading: common.loading,
  buttonContainer: {
    ...common.listButtonContainer,
    justifyContent: "space-between"
  },
  buttonReminder: {
    ...common.listButtonReminderContainer,
    paddingTop: 10
  },
  errorMessage: common.containerErrorMessage,
  listRefreshButton: common.listRefreshButton,
  calendarButtonContainer: {
    minWidth: 220,
    flexDirection: "row"
  },
  calendarContainer: {
    alignItems: "center",
    justifyContent: "center"
  },
  calendar: {
    width: "100%",
    maxWidth: 500
  },
  weekView: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
    marginBottom: 10
  }
};
