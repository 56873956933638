import React from "react";
import { AdminTerritoryPageController } from "./controller";
import { AdminTerritoryPageView } from "./view";
import { styles } from "./styles";

export class AdminTerritoryPage extends React.Component {
  render() {
    const controller = new AdminTerritoryPageController(this.props);

    return (
      <AdminTerritoryPageView
        controller={controller}
        {...this.props}
        styles={styles}
      />
    );
  }
}
