import { common } from "../../../config/style-configs/common";
import { theme } from "../../../config/style-configs/theme";

export const styles = {
  style: {
    flex: 1
  },
  scrollView: common.scrollView,
  scrollViewContent: common.scrollViewContent,
  field: {
    padding: 10,
    justifyContent: "center",
    maxWidth: 1000
  },
  formButtonColumn: {
    flexDirection: "row"
  },
  form: {
    flex: 1
  },
  refreshButton: {
    flexGrow: 0,
    flexShrink: 0
  },
  // fieldLabel: {
  //   marginLeft: 0
  // },
  // pickerLabel: {
  //   marginLeft: 0
  // },
  picker: {
    width: "100%",
    maxWidth: "60%"
  },
  loading: common.loading,
  buttonContainer: {
    ...common.listButtonContainer,
    padding: 5
  },
  button: {
    margin: 5
  },
  versionContainer: {
    paddingHorizontal: 10,
    paddingVertical: 20
  },
  versionInfo: {
    color: "#666",
    fontSize: theme.FONT_SIZE_SMALL
  },
  additionalFormHeading: {
    fontSize: theme.FONT_SIZE_MEDIUM,
    color: theme.color,
    fontWeight: "600",
    marginBottom: 10
  }
};
