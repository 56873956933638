import React from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { observer } from "mobx-react";
import { theme } from "../../../../config/style-configs/theme";
import { wordPressService } from "../../../../cdm/services/wordpress-service";
import { UIText } from "../../../../config/lang-config";

@observer
class GroupFinderPageView extends React.Component {
  constructor(props) {
    super(props);
    this.controller = props.controller;
  }

  render() {
    return (
      <View style={this.props.styles.style}>
        {wordPressService.wpFrameLoading && (
          <View style={this.props.styles.loading}>
            <ActivityIndicator size="large" color={theme.color} />
            <Text style={this.props.styles.loadingText}>
              {UIText.marketLoadingCAF}
            </Text>
          </View>
        )}
        <View style={this.props.styles.frameContainer}>
          <iframe
            style={{
              display: wordPressService.wpFrameLoading ? "none" : undefined,
              ...this.props.styles.frame
            }}
            ref={wordPressService.wpFrameRef}
            src={this.controller.finderUrl}
          />
        </View>
      </View>
    );
  }
}

export { GroupFinderPageView };
