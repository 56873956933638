import { Dimensions, Platform } from "react-native";
import { computed, observable } from "mobx";
import { theme } from "./theme";

class ResponsiveStyles {
  @observable deviceDimension = {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
    isDesktop: Dimensions.get("window").width >= 800,
    isNarrow: Dimensions.get("window").width <= 370
  };

  @computed get styles() {
    return {
      headingPickerLabel: {
        fontSize: this.deviceDimension.isNarrow
          ? theme.FONT_SIZE_MEDIUM
          : theme.FONT_SIZE_LARGE
      },
      scrollViewCard: {
        justifyContent: this.deviceDimension.isDesktop ? "flex-start" : "center"
      },
      formAvatarSize: this.deviceDimension.isDesktop ? 120 : 95,
      formAvatarButtonTitleFontSize: this.deviceDimension.isDesktop
        ? theme.FONT_SIZE_LARGE
        : theme.FONT_SIZE_MEDIUM,
      lightBoxImage: {
        width: this.deviceDimension.isDesktop ? 800 : 300,
        height: this.deviceDimension.isDesktop ? 900 : 400
      },
      headerSearchPanelInputContainer: {
        minHeight: this.deviceDimension.isDesktop ? 60 : 50
      },
      headerSearchPanelInput: {
        fontSize: this.deviceDimension.isDesktop
          ? theme.FONT_SIZE_XLARGE
          : theme.FONT_SIZE_LARGE
      },
      headerSearchPanelIconSize: this.deviceDimension.isDesktop ? 40 : 30
    };
  }

  get isDesktopOS() {
    return (
      Platform.OS === "web" &&
      (window.navigator.userAgent.match(/Windows/g) ||
        window.navigator.userAgent.match(/Mac OS X/g))
    );
  }

  constructor() {
    Dimensions.addEventListener("change", ({ window, screen }) => {
      this.deviceDimension.width = window.width;
      this.deviceDimension.height = window.height;
      this.deviceDimension.isDesktop = window.width >= 800;
      this.deviceDimension.isNarrow = window.width <= 370;
    });
  }

  applyIOSSafariKeyboardFix = () => {
    if (window.navigator.userAgent.match(/iPhone/g)) {
      document.body.style.position = "fixed";
      document.body.style.bottom = 0;
      if (window.visualViewport) {
        window.visualViewport.addEventListener(
          "resize",
          this.resizeVisualViewport
        );
      }
    }
  };

  removeIOSSafariKeyboardFix = () => {
    document.body.style.position = null;
    document.body.style.bottom = null;
    document.body.style.height = null;
    window.visualViewport &&
      window.visualViewport.removeEventListener(
        "resize",
        this.resizeVisualViewport
      );
  };

  resizeVisualViewport = event => {
    const newHeight = event.target && event.target.height;
    if (newHeight)
      setTimeout(() => (document.body.style.height = `${newHeight}px`));
  };
}

export const responsive = new ResponsiveStyles();
