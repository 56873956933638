import { stateController } from '../../cdm/controllers/state-controller';
import { Platform } from 'react-native';
import { computed } from 'mobx';
import { msgService } from '../../cdm/services/messaging-service';
import { txService } from '../../cdm/services/tx-service';
import { clientController } from '../../cdm/controllers/client-controller';
import { apiService } from '../../cdm/services/api-service';

export class HeaderController {
  constructor(props) {
    this.props = props;
  }

  @computed get desktop() {
    // return stateController.isDesktop;
    return false;
  }

  @computed get hasUnread() {
    return msgService.hasUnread;
  }

  @computed get activity() {
    return !!txService.hasActivity || apiService.pending > 0;
  }

  @computed get hideHamburger() {
    return this.props.leftIcon === "menu" && clientController.isVisitor;
  }

  handleLeftPress = event => {
    if (Platform.OS !== "web") {
      this.props.navigation.toggleDrawer();
    } else {
      stateController.showDrawer = !stateController.showDrawer;
    }
  };

  handleRightPress = event => {};
}
