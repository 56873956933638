import { env } from "../../config/variable-config";
import { apiService } from "./api-service";
import { isEmpty } from "../../utils/helpers";

class PaginationService {
  cached = {};

  query = async (method, config) => {
    const { endpoint } = config;
    if (this.cached[endpoint]) {
      const response = this.cached[endpoint];
      this.cacheNext(method, config, response);
      return response;
    }
    return apiService.async(method, config).then(response => {
      this.cacheNext(method, config, response);
      return response;
    });
  };

  queryAndCache = async (method, config, endpoint) => {
    if (!isEmpty(this.cached[endpoint])) return;
    return (this.cached[endpoint] = await apiService
      .async(method, {
        ...config,
        endpoint
      })
      .catch(console.warn));
  };

  cacheNext = async (method, config, response) => {
    const links = (response.data || {}).links;
    if (links && links.next)
      setTimeout(() => this.queryAndCache(method, config, links.next), 250);
  };
}

const paginationService = new PaginationService();

// For development;
if (window && env !== "prod") window.paginationService = paginationService;

export { paginationService };
