import { autorun, observable } from 'mobx';
import { apiController } from '../../../../../../cdm/controllers/api-controller';
import { topicTypeIds } from '../../../../../../config/variable-config';
import { TypeClassTemplate } from '../../../../../../cdm/services/form-service';
import { clientController } from '../../../../../../cdm/controllers/client-controller';
import { isEmpty } from '../../../../../../utils/helpers';

let picker = observable({
  name: "taskTemplate",
  type: "picker",
  placeholder: "Choose a Task template:",
  options: []
});

let taskTemplates = [];

const dispose = autorun(() => {
  if (
    !!clientController &&
    clientController.initialized &&
    clientController.loginState
  ) {
    apiController
      .getTopicsByTypeId(topicTypeIds.task, true, false) // isTemplate = true, isParentTemplate = false;
      .then(topics => {
        topics = topics.filter(t => !!t.description && !!t.typeClassId);

        picker.options = topics.map(t => ({
          placeholder: t.description,
          name: t.typeClassId
        }));

        taskTemplates = topics.map(t => ({
          topicId: t.typeClassId,
          description: t.description,
          typeClassId: t.typeClassId,
          typeClass: new TypeClassTemplate(t.typeClassId)
        }));
        dispose();
      });
  }
});

export const taskTemplatePickerForm = () => {
  if (picker.options.length === 0) return [];

  picker.value = picker.options[0].name;
  return [picker];
};

export const taskTemplateForm = tmplt => {
  const { id } = tmplt;
  const template = taskTemplates.find(t => t.typeClassId === id);
  if (!template || isEmpty(template.typeClass.form)) return [];

  const titleField = {
    name: "title",
    type: "input",
    required: true,
    placeholder: "Title",
    flags: {
      executionEdit: false,
      executionRender: false,
      setupEdit: true,
      setupRender: true
    },
    value: template.description || ""
  };

  const form = [...template.typeClass.form];
  if (!form.find(f => f.name === "title")) form.unshift(titleField);

  for (let field of form) {
    if (field.name === "title" && !field.value && template.description)
      field.value = template.description;
  }
  return form;
};
